import { Component, OnInit } from '@angular/core';
import { AFKARENA } from 'src/app/data/afkarena.data';

@Component({
  selector: 'app-caudo',
  templateUrl: './caudo.component.html',
  styleUrls: ['./caudo.component.scss']
})
export class CaudoComponent implements OnInit {

  CAUDO = AFKARENA.CAUDONHACPOPTHOCA
  items: any
  MAX: any
  nameSearch: any
  constructor() { }

  ngOnInit(): void {
    this.items = this.CAUDO;
    this.MAX = this.items.length;
  }

  onSearch(){
    this.items = [];
    for(var i = 0; i < this.CAUDO.length; i++){
      var item = this.CAUDO[i];
      if(this.nameSearch && this.nameSearch != ''){
        if(item.title.toLowerCase().indexOf(this.nameSearch.toLowerCase()) > -1 || item.result.toLowerCase().indexOf(this.nameSearch.toLowerCase()) > -1){
          this.items.push(item);
        }
      }
    }
    this.MAX = this.items.length;
  }

}
