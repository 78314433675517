<app-header></app-header>

<div class="main-banner {{data.Settings.banner[0].className}} jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="main-banner-content">
            <h1>Find The Best Places To Live</h1>

            <div class="main-search-wrap">
                <form>
                    <div class="row">
                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label><i class='bx bxs-keyboard'></i></label>
                                <input type="text" placeholder="Whar are you looking for?">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="form-group">
                                <label><a routerLink="/"><i class='bx bx-current-location'></i></a></label>
                                <input type="text" placeholder="Location">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="form-group">
                                <label><i class='bx bx-slider'></i></label>
                                <select>
                                    <option>All Categories</option>
                                    <option *ngFor="let item of data.SubCategory">{{item.name}}</option>
                                    <option>Other</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="main-search-btn">
                        <button type="submit">Search <i class='bx bx-search-alt'></i></button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="main-categories-box">
        <ul>
            <li *ngFor="let item of data.SubCategory | slice:0:5">
                <a routerLink='/listing' [queryParams]="{subcategories: item.name}">
                    <span class="icon">
                        <i [class]='item.icon'></i>
                    </span>
                    {{item.name}}
                </a>
            </li>
        </ul>
    </div>
</div>

<section class="listing-area extra-pt pb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Discover</span>
            <h2>The Latest Listing</h2>
        </div>
    </div>
    
    <div class="container-fluid">
        <div class="listing-slides owl-carousel owl-theme">
            <div class="single-listing-box" *ngFor="let item of data.Latest">
                <a [routerLink]="['/listing/' + item._id]" class="listing-image">
                    <img [src]="item.img" alt="image">
                </a>

                <div class="listing-badge">Open Now</div>

                <div class="listing-content">
                    <div class="content">
                        <div class="author">
                            <img src="assets/img/default/admin.jpg" alt="image">
                            <span>Andy Nguyễn</span>
                        </div>

                        <h3><a [routerLink]="['/listing/' + item._id]">{{item.name && item.name != '' ? item.name : "???"}}</a></h3>
                        <span class="location"><i class='bx bx-map'></i> {{item.address && item.address != '' ? item.address : "???"}}</span>

                        <div class="rating">
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <span class="rating-count">2 reviews</span>
                        </div>
                    </div>

                    <div class="footer-content">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="listing-option-list">
                                <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                            </div>

                            <div class="price-level">
                                <span data-bs-toggle="tooltip" data-placement="top" title="Ultra Hight">
                                    <strong>$</strong>
                                    <strong>$</strong>
                                    <strong>$</strong>
                                    <strong>$</strong>
                                </span>
                            </div>

                            <div class="listing-category">
                                <a routerLink="/" data-bs-toggle="tooltip" data-placement="top" title="Category">
                                    <i [class]='item.subCategory_Icon'></i>
                                    {{item.subCategory && item.subCategory != '' ? item.subCategory : "???"}}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="destinations-area pb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Browse</span>
            <h2>Popular Destinations</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-12 col-md-12">
                <div class="single-destinations-box">
                    <img [src]="data.Destinations[0].img" alt="image">

                    <div class="content">
                        <h3>{{data.Destinations[0].name}}</h3>
                        <div class="rating">
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                        </div>
                    </div>

                    <span class="listing-count">125 Listings</span>

                    <a routerLink="/listing" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-8 col-sm-12 col-md-12">
                <div class="single-destinations-box">
                    <img [src]="data.Destinations[1].img" alt="image">

                    <div class="content">
                        <h3>{{data.Destinations[1].name}}</h3>
                        <div class="rating">
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                        </div>
                    </div>

                    <span class="listing-count">50 Listings</span>

                    <a routerLink="/listing" class="link-btn"></a>
                </div>

                <div class="row">
                    <div class="col-lg-6 col-sm-12 col-md-6">
                        <div class="single-destinations-box">
                            <img [src]="data.Destinations[1].items[0].img" alt="image">
        
                            <div class="content">
                                <h3>{{data.Destinations[1].items[0].name}}</h3>
                                <div class="rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                            </div>
        
                            <span class="listing-count">15 Listings</span>
        
                            <a routerLink="/listing" class="link-btn"></a>
                        </div>
                    </div>
        
                    <div class="col-lg-6 col-sm-12 col-md-6">
                        <div class="single-destinations-box">
                            <img [src]="data.Destinations[1].items[1].img" alt="image">
        
                            <div class="content">
                                <h3>{{data.Destinations[1].items[1].name}}</h3>
                                <div class="rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                            </div>
        
                            <span class="listing-count">78 Listings</span>
        
                            <a routerLink="/listing" class="link-btn"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="destinations-btn-box">
            <a routerLink="/listing" class="default-btn">View All Destinations</a>
        </div>
    </div>
</section>

<app-process></app-process>

<section class="listing-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Discover</span>
            <h2>Most Popular Places</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-12 col-md-6" *ngFor="let item of data.MostPopularPlaces">
                <app-single-item [item]="item"></app-single-item>
            </div>
        </div>
    </div>
</section>

<app-video [settings]="data.Settings.banner"></app-video>

<app-feedback [feedback]="data.Feedback"></app-feedback>

<app-app-download [appDownload]="data.AppDownload"></app-app-download>