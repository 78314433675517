import { Component, OnInit } from '@angular/core';
import { ReBookingsService } from 'src/app/services/bookings.service';

@Component({
  selector: 'app-admin-booking',
  templateUrl: './admin-booking.component.html',
  styleUrls: ['./admin-booking.component.scss']
})
export class AdminBookingComponent implements OnInit {

  items: any
  isLoading = false
  constructor(private api: ReBookingsService) { }

  ngOnInit(): void {
    this.fetch()
  }

  fetch(){
    this.isLoading = true;
    this.api.SEARCH({}).subscribe((res:any)=> {
      this.items = res.results;
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
    })
  }

}
