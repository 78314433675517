export const TSONLINENPC_PART3 = [
    {
        "idmap": 11421,
        "ten_map": "Lang Ta Son",
        "thanh": "Thanh Châu"
    },
    {
        "idmap": 11422,
        "ten_map": "Duong Lang Ta Son",
        "thanh": "Thanh Châu"
    },
    {
        "idmap": 11506,
        "ten_map": "Son Dong Thai Son 6"
    },
    {
        "idmap": 11507,
        "ten_map": "Son Dong Thai Son 7"
    },
    {
        "idmap": 11512,
        "ten_map": "Son Dong Thai Son 10",
        "thanh": "Thanh Châu"
    },
    {
        "idmap": 11513,
        "ten_map": "Son Dong Thai Son 11",
        "thanh": "Thanh Châu"
    },
    {
        "idmap": 11514,
        "ten_map": "Son Dong Thai Son 12",
        "thanh": "Thanh Châu"
    },
    {
        "idmap": 11534,
        "ten_map": "Dong Quy Than 4",
        "thanh": "Thanh Châu"
    },
    {
        "idmap": 11539,
        "ten_map": "Dong Quy Than 9"
    },
    {
        "idmap": 12076,
        "ten_map": "Thanh Dai Huyen 6"
    },
    {
        "idmap": 12086,
        "ten_map": "Thanh Chan Dinh 6"
    },
    {
        "idmap": 12097,
        "ten_map": "Bac Thang Khong Tuoc3"
    },
    {
        "idmap": 12161,
        "ten_map": "Diep Thanh Thanh Mon"
    },
    {
        "idmap": 12827,
        "thanh": "U Châu"
    },
    {
        "idmap": 12827,
        "thanh": "U Châu"
    },
    {
        "idmap": 12827,
        "thanh": "U Châu"
    },
    {
        "idmap": 12827,
        "thanh": "U Châu"
    },
    {
        "idmap": 12827,
        "thanh": "U Châu"
    },
    {
        "idmap": 12827,
        "thanh": "U Châu"
    },
    {
        "idmap": 12828,
        "thanh": "U Châu"
    },
    {
        "idmap": 12828,
        "thanh": "U Châu"
    },
    {
        "idmap": 12828,
        "thanh": "U Châu"
    },
    {
        "idmap": 12828,
        "thanh": "U Châu"
    },
    {
        "idmap": 12828,
        "thanh": "U Châu"
    },
    {
        "idmap": 12828,
        "thanh": "U Châu"
    },
    {
        "idmap": 12828,
        "thanh": "U Châu"
    },
    {
        "idmap": 12828,
        "thanh": "U Châu"
    },
    {
        "idmap": 12828,
        "thanh": "U Châu"
    },
    {
        "idmap": 12829,
        "thanh": "U Châu"
    },
    {
        "idmap": 12829,
        "thanh": "U Châu"
    },
    {
        "idmap": 12829,
        "thanh": "U Châu"
    },
    {
        "idmap": 12829,
        "thanh": "U Châu"
    },
    {
        "idmap": 12829,
        "thanh": "U Châu"
    },
    {
        "idmap": 12829,
        "thanh": "U Châu"
    },
    {
        "idmap": 12829,
        "thanh": "U Châu"
    },
    {
        "idmap": 12829,
        "thanh": "U Châu"
    },
    {
        "idmap": 12830,
        "ten_map": "Rung Lau Song 6",
        "thanh": "U Châu"
    },
    {
        "idmap": 12830,
        "ten_map": "Rung Lau Song 6",
        "thanh": "U Châu"
    },
    {
        "idmap": 12830,
        "ten_map": "Rung Lau Song 6",
        "thanh": "U Châu"
    },
    {
        "idmap": 12830,
        "ten_map": "Rung Lau Song 6",
        "thanh": "U Châu"
    },
    {
        "idmap": 12830,
        "ten_map": "Rung Lau Song 6",
        "thanh": "U Châu"
    },
    {
        "idmap": 12830,
        "ten_map": "Rung Lau Song 6",
        "thanh": "U Châu"
    },
    {
        "idmap": 12830,
        "ten_map": "Rung Lau Song 6",
        "thanh": "U Châu"
    },
    {
        "idmap": 12830,
        "ten_map": "Rung Lau Song 6",
        "thanh": "U Châu"
    },
    {
        "idmap": 12830,
        "ten_map": "Rung Lau Song 6",
        "thanh": "U Châu"
    },
    {
        "idmap": 12831,
        "ten_map": "Rung Nai Huynh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12831,
        "ten_map": "Rung Nai Huynh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12831,
        "ten_map": "Rung Nai Huynh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12831,
        "ten_map": "Rung Nai Huynh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12831,
        "ten_map": "Rung Nai Huynh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12831,
        "ten_map": "Rung Nai Huynh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12831,
        "ten_map": "Rung Nai Huynh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12831,
        "ten_map": "Rung Nai Huynh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12831,
        "ten_map": "Rung Nai Huynh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12831,
        "ten_map": "Rung Nai Huynh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12831,
        "ten_map": "Rung Nai Huynh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12831,
        "ten_map": "Rung Nai Huynh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12831,
        "ten_map": "Rung Nai Huynh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12831,
        "ten_map": "Rung Nai Huynh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12831,
        "ten_map": "Rung Nai Huynh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12831,
        "ten_map": "Rung Nai Huynh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12831,
        "ten_map": "Rung Nai Huynh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12831,
        "ten_map": "Rung Nai Huynh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12831,
        "ten_map": "Rung Nai Huynh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12831,
        "ten_map": "Rung Nai Huynh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12831,
        "ten_map": "Rung Nai Huynh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12831,
        "ten_map": "Rung Nai Huynh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12841,
        "ten_map": "Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12841,
        "ten_map": "Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12841,
        "ten_map": "Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12841,
        "ten_map": "Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12841,
        "ten_map": "Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12841,
        "ten_map": "Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12841,
        "ten_map": "Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12841,
        "ten_map": "Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12841,
        "ten_map": "Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12841,
        "ten_map": "Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12841,
        "ten_map": "Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12841,
        "ten_map": "Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12841,
        "ten_map": "Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12841,
        "ten_map": "Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12841,
        "ten_map": "Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12841,
        "ten_map": "Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12841,
        "ten_map": "Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12841,
        "ten_map": "Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12841,
        "ten_map": "Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12841,
        "ten_map": "Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12841,
        "ten_map": "Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12841,
        "ten_map": "Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12841,
        "ten_map": "Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12842,
        "ten_map": "Doc Nui Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12842,
        "ten_map": "Doc Nui Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12842,
        "ten_map": "Doc Nui Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12842,
        "ten_map": "Doc Nui Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12842,
        "ten_map": "Doc Nui Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12842,
        "ten_map": "Doc Nui Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12842,
        "ten_map": "Doc Nui Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12842,
        "ten_map": "Doc Nui Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12842,
        "ten_map": "Doc Nui Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12842,
        "ten_map": "Doc Nui Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12842,
        "ten_map": "Doc Nui Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12842,
        "ten_map": "Doc Nui Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12842,
        "ten_map": "Doc Nui Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12842,
        "ten_map": "Doc Nui Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12842,
        "ten_map": "Doc Nui Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12842,
        "ten_map": "Doc Nui Rung Tay Son",
        "thanh": "U Châu"
    },
    {
        "idmap": 12851,
        "ten_map": "Trai Le Duong",
        "thanh": "U Châu"
    },
    {
        "idmap": 12851,
        "ten_map": "Trai Le Duong",
        "thanh": "U Châu"
    },
    {
        "idmap": 12851,
        "ten_map": "Trai Le Duong",
        "thanh": "U Châu"
    },
    {
        "idmap": 12851,
        "ten_map": "Trai Le Duong",
        "thanh": "U Châu"
    },
    {
        "idmap": 12851,
        "ten_map": "Trai Le Duong",
        "thanh": "U Châu"
    },
    {
        "idmap": 12851,
        "ten_map": "Trai Le Duong",
        "thanh": "U Châu"
    },
    {
        "idmap": 12851,
        "ten_map": "Trai Le Duong",
        "thanh": "U Châu"
    },
    {
        "idmap": 12851,
        "ten_map": "Trai Le Duong",
        "thanh": "U Châu"
    },
    {
        "idmap": 12852,
        "ten_map": "Rung Le Duong 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12852,
        "ten_map": "Rung Le Duong 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12852,
        "ten_map": "Rung Le Duong 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12852,
        "ten_map": "Rung Le Duong 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12852,
        "ten_map": "Rung Le Duong 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12852,
        "ten_map": "Rung Le Duong 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12852,
        "ten_map": "Rung Le Duong 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12852,
        "ten_map": "Rung Le Duong 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12852,
        "ten_map": "Rung Le Duong 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12852,
        "ten_map": "Rung Le Duong 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12852,
        "ten_map": "Rung Le Duong 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12852,
        "ten_map": "Rung Le Duong 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12853,
        "ten_map": "Rung Le Duong 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12853,
        "ten_map": "Rung Le Duong 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12853,
        "ten_map": "Rung Le Duong 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12853,
        "ten_map": "Rung Le Duong 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12853,
        "ten_map": "Rung Le Duong 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12853,
        "ten_map": "Rung Le Duong 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12853,
        "ten_map": "Rung Le Duong 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12853,
        "ten_map": "Rung Le Duong 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12853,
        "ten_map": "Rung Le Duong 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12853,
        "ten_map": "Rung Le Duong 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12853,
        "ten_map": "Rung Le Duong 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12853,
        "ten_map": "Rung Le Duong 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12853,
        "ten_map": "Rung Le Duong 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12853,
        "ten_map": "Rung Le Duong 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12853,
        "ten_map": "Rung Le Duong 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12853,
        "ten_map": "Rung Le Duong 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12853,
        "ten_map": "Rung Le Duong 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12853,
        "ten_map": "Rung Le Duong 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12853,
        "ten_map": "Rung Le Duong 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12853,
        "ten_map": "Rung Le Duong 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12854,
        "ten_map": "Trai Vien Thuong Le Duong",
        "thanh": "U Châu"
    },
    {
        "idmap": 12854,
        "ten_map": "Trai Vien Thuong Le Duong",
        "thanh": "U Châu"
    },
    {
        "idmap": 12854,
        "ten_map": "Trai Vien Thuong Le Duong",
        "thanh": "U Châu"
    },
    {
        "idmap": 12854,
        "ten_map": "Trai Vien Thuong Le Duong",
        "thanh": "U Châu"
    },
    {
        "idmap": 12855,
        "ten_map": "Trai Tao Phi Le Duong",
        "thanh": "U Châu"
    },
    {
        "idmap": 12855,
        "ten_map": "Trai Tao Phi Le Duong",
        "thanh": "U Châu"
    },
    {
        "idmap": 12861,
        "ten_map": "Rung Trieu Ca 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12861,
        "ten_map": "Rung Trieu Ca 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12861,
        "ten_map": "Rung Trieu Ca 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12861,
        "ten_map": "Rung Trieu Ca 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12861,
        "ten_map": "Rung Trieu Ca 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12861,
        "ten_map": "Rung Trieu Ca 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12861,
        "ten_map": "Rung Trieu Ca 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12861,
        "ten_map": "Rung Trieu Ca 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12861,
        "ten_map": "Rung Trieu Ca 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12861,
        "ten_map": "Rung Trieu Ca 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12861,
        "ten_map": "Rung Trieu Ca 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12861,
        "ten_map": "Rung Trieu Ca 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12861,
        "ten_map": "Rung Trieu Ca 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12861,
        "ten_map": "Rung Trieu Ca 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12861,
        "ten_map": "Rung Trieu Ca 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12861,
        "ten_map": "Rung Trieu Ca 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12861,
        "ten_map": "Rung Trieu Ca 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12861,
        "ten_map": "Rung Trieu Ca 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12861,
        "ten_map": "Rung Trieu Ca 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12861,
        "ten_map": "Rung Trieu Ca 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12861,
        "ten_map": "Rung Trieu Ca 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12861,
        "ten_map": "Rung Trieu Ca 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12862,
        "ten_map": "Rung Trieu Ca 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12862,
        "ten_map": "Rung Trieu Ca 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12862,
        "ten_map": "Rung Trieu Ca 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12862,
        "ten_map": "Rung Trieu Ca 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12862,
        "ten_map": "Rung Trieu Ca 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12862,
        "ten_map": "Rung Trieu Ca 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12862,
        "ten_map": "Rung Trieu Ca 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12862,
        "ten_map": "Rung Trieu Ca 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12871,
        "ten_map": "Rung Moc Da",
        "thanh": "U Châu"
    },
    {
        "idmap": 12871,
        "ten_map": "Rung Moc Da",
        "thanh": "U Châu"
    },
    {
        "idmap": 12871,
        "ten_map": "Rung Moc Da",
        "thanh": "U Châu"
    },
    {
        "idmap": 12871,
        "ten_map": "Rung Moc Da",
        "thanh": "U Châu"
    },
    {
        "idmap": 12871,
        "ten_map": "Rung Moc Da",
        "thanh": "U Châu"
    },
    {
        "idmap": 12871,
        "ten_map": "Rung Moc Da",
        "thanh": "U Châu"
    },
    {
        "idmap": 12871,
        "ten_map": "Rung Moc Da",
        "thanh": "U Châu"
    },
    {
        "idmap": 12871,
        "ten_map": "Rung Moc Da",
        "thanh": "U Châu"
    },
    {
        "idmap": 12871,
        "ten_map": "Rung Moc Da",
        "thanh": "U Châu"
    },
    {
        "idmap": 12871,
        "ten_map": "Rung Moc Da",
        "thanh": "U Châu"
    },
    {
        "idmap": 12871,
        "ten_map": "Rung Moc Da",
        "thanh": "U Châu"
    },
    {
        "idmap": 12871,
        "ten_map": "Rung Moc Da",
        "thanh": "U Châu"
    },
    {
        "idmap": 12871,
        "ten_map": "Rung Moc Da",
        "thanh": "U Châu"
    },
    {
        "idmap": 12871,
        "ten_map": "Rung Moc Da",
        "thanh": "U Châu"
    },
    {
        "idmap": 12871,
        "ten_map": "Rung Moc Da",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12881,
        "ten_map": "Rung Ha Noi 1",
        "thanh": "U Châu"
    },
    {
        "idmap": 12882,
        "ten_map": "Rung Ha Noi 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12882,
        "ten_map": "Rung Ha Noi 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12882,
        "ten_map": "Rung Ha Noi 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12882,
        "ten_map": "Rung Ha Noi 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12882,
        "ten_map": "Rung Ha Noi 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12882,
        "ten_map": "Rung Ha Noi 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12882,
        "ten_map": "Rung Ha Noi 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12882,
        "ten_map": "Rung Ha Noi 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12882,
        "ten_map": "Rung Ha Noi 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12882,
        "ten_map": "Rung Ha Noi 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12882,
        "ten_map": "Rung Ha Noi 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12882,
        "ten_map": "Rung Ha Noi 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12882,
        "ten_map": "Rung Ha Noi 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12882,
        "ten_map": "Rung Ha Noi 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12882,
        "ten_map": "Rung Ha Noi 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12882,
        "ten_map": "Rung Ha Noi 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12882,
        "ten_map": "Rung Ha Noi 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12882,
        "ten_map": "Rung Ha Noi 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12882,
        "ten_map": "Rung Ha Noi 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12882,
        "ten_map": "Rung Ha Noi 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12882,
        "ten_map": "Rung Ha Noi 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12882,
        "ten_map": "Rung Ha Noi 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12882,
        "ten_map": "Rung Ha Noi 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12882,
        "ten_map": "Rung Ha Noi 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12882,
        "ten_map": "Rung Ha Noi 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12882,
        "ten_map": "Rung Ha Noi 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12882,
        "ten_map": "Rung Ha Noi 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12882,
        "ten_map": "Rung Ha Noi 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12882,
        "ten_map": "Rung Ha Noi 2",
        "thanh": "U Châu"
    },
    {
        "idmap": 12891,
        "ten_map": "Rung Cu Loc",
        "thanh": "U Châu"
    },
    {
        "idmap": 12891,
        "ten_map": "Rung Cu Loc",
        "thanh": "U Châu"
    },
    {
        "idmap": 12891,
        "ten_map": "Rung Cu Loc",
        "thanh": "U Châu"
    },
    {
        "idmap": 12891,
        "ten_map": "Rung Cu Loc",
        "thanh": "U Châu"
    },
    {
        "idmap": 12891,
        "ten_map": "Rung Cu Loc",
        "thanh": "U Châu"
    },
    {
        "idmap": 12891,
        "ten_map": "Rung Cu Loc",
        "thanh": "U Châu"
    },
    {
        "idmap": 12891,
        "ten_map": "Rung Cu Loc",
        "thanh": "U Châu"
    },
    {
        "idmap": 12891,
        "ten_map": "Rung Cu Loc",
        "thanh": "U Châu"
    },
    {
        "idmap": 12891,
        "ten_map": "Rung Cu Loc",
        "thanh": "U Châu"
    },
    {
        "idmap": 12891,
        "ten_map": "Rung Cu Loc",
        "thanh": "U Châu"
    },
    {
        "idmap": 12891,
        "ten_map": "Rung Cu Loc",
        "thanh": "U Châu"
    },
    {
        "idmap": 12891,
        "ten_map": "Rung Cu Loc",
        "thanh": "U Châu"
    },
    {
        "idmap": 12891,
        "ten_map": "Rung Cu Loc",
        "thanh": "U Châu"
    },
    {
        "idmap": 12901,
        "ten_map": "Tu luyen Guild",
        "thanh": "U Châu"
    },
    {
        "idmap": 12901,
        "ten_map": "Tu luyen Guild",
        "thanh": "U Châu"
    },
    {
        "idmap": 12901,
        "ten_map": "Tu luyen Guild",
        "thanh": "U Châu"
    },
    {
        "idmap": 12901,
        "ten_map": "Tu luyen Guild",
        "thanh": "U Châu"
    },
    {
        "idmap": 12901,
        "ten_map": "Tu luyen Guild",
        "thanh": "U Châu"
    },
    {
        "idmap": 12901,
        "ten_map": "Tu luyen Guild",
        "thanh": "U Châu"
    },
    {
        "idmap": 12901,
        "ten_map": "Tu luyen Guild",
        "thanh": "U Châu"
    },
    {
        "idmap": 12901,
        "ten_map": "Tu luyen Guild",
        "thanh": "U Châu"
    },
    {
        "idmap": 12901,
        "ten_map": "Tu luyen Guild",
        "thanh": "U Châu"
    },
    {
        "idmap": 12901,
        "ten_map": "Tu luyen Guild",
        "thanh": "U Châu"
    },
    {
        "idmap": 12901,
        "ten_map": "Tu luyen Guild",
        "thanh": "U Châu"
    },
    {
        "idmap": 12901,
        "ten_map": "Tu luyen Guild",
        "thanh": "U Châu"
    },
    {
        "idmap": 12901,
        "ten_map": "Tu luyen Guild",
        "thanh": "U Châu"
    },
    {
        "idmap": 12901,
        "ten_map": "Tu luyen Guild",
        "thanh": "U Châu"
    },
    {
        "idmap": 12911,
        "ten_map": "Le Duong Ket Hon",
        "thanh": "U Châu"
    },
    {
        "idmap": 12911,
        "ten_map": "Le Duong Ket Hon",
        "thanh": "U Châu"
    },
    {
        "idmap": 12911,
        "ten_map": "Le Duong Ket Hon",
        "thanh": "U Châu"
    },
    {
        "idmap": 12911,
        "ten_map": "Le Duong Ket Hon",
        "thanh": "U Châu"
    },
    {
        "idmap": 12911,
        "ten_map": "Le Duong Ket Hon",
        "thanh": "U Châu"
    },
    {
        "idmap": 12921,
        "ten_map": "Dong Tuoc dai Nhi Kieu",
        "thanh": "U Châu"
    },
    {
        "idmap": 12921,
        "ten_map": "Dong Tuoc dai Nhi Kieu",
        "thanh": "U Châu"
    },
    {
        "idmap": 12921,
        "ten_map": "Dong Tuoc dai Nhi Kieu",
        "thanh": "U Châu"
    },
    {
        "idmap": 12922,
        "ten_map": "Tang 1 Nhi Kieu",
        "thanh": "U Châu"
    },
    {
        "idmap": 12922,
        "ten_map": "Tang 1 Nhi Kieu",
        "thanh": "U Châu"
    },
    {
        "idmap": 12922,
        "ten_map": "Tang 1 Nhi Kieu",
        "thanh": "U Châu"
    },
    {
        "idmap": 12922,
        "ten_map": "Tang 1 Nhi Kieu",
        "thanh": "U Châu"
    },
    {
        "idmap": 12922,
        "ten_map": "Tang 1 Nhi Kieu",
        "thanh": "U Châu"
    },
    {
        "idmap": 12923,
        "thanh": "U Châu"
    },
    {
        "idmap": 12923,
        "thanh": "U Châu"
    },
    {
        "idmap": 12923,
        "thanh": "U Châu"
    },
    {
        "idmap": 12923,
        "thanh": "U Châu"
    },
    {
        "idmap": 12923,
        "thanh": "U Châu"
    },
    {
        "idmap": 12923,
        "thanh": "U Châu"
    },
    {
        "idmap": 12924,
        "thanh": "U Châu"
    },
    {
        "idmap": 12924,
        "thanh": "U Châu"
    },
    {
        "idmap": 12924,
        "thanh": "U Châu"
    },
    {
        "idmap": 12924,
        "thanh": "U Châu"
    },
    {
        "idmap": 12925,
        "thanh": "U Châu"
    },
    {
        "idmap": 12925,
        "thanh": "U Châu"
    },
    {
        "idmap": 12925,
        "thanh": "U Châu"
    },
    {
        "idmap": 12925,
        "thanh": "U Châu"
    },
    {
        "idmap": 12926,
        "thanh": "U Châu"
    },
    {
        "idmap": 12926,
        "thanh": "U Châu"
    },
    {
        "idmap": 12926,
        "thanh": "U Châu"
    },
    {
        "idmap": 12926,
        "thanh": "U Châu"
    },
    {
        "idmap": 12927,
        "thanh": "U Châu"
    },
    {
        "idmap": 12927,
        "thanh": "U Châu"
    },
    {
        "idmap": 12927,
        "thanh": "U Châu"
    },
    {
        "idmap": 12927,
        "thanh": "U Châu"
    },
    {
        "idmap": 12928,
        "thanh": "U Châu"
    },
    {
        "idmap": 12928,
        "thanh": "U Châu"
    },
    {
        "idmap": 12928,
        "thanh": "U Châu"
    },
    {
        "idmap": 12928,
        "thanh": "U Châu"
    },
    {
        "idmap": 12929,
        "thanh": "U Châu"
    },
    {
        "idmap": 12929,
        "thanh": "U Châu"
    },
    {
        "idmap": 12929,
        "thanh": "U Châu"
    },
    {
        "idmap": 12929,
        "thanh": "U Châu"
    },
    {
        "idmap": 12929,
        "thanh": "U Châu"
    },
    {
        "idmap": 12930,
        "thanh": "U Châu"
    },
    {
        "idmap": 12930,
        "thanh": "U Châu"
    },
    {
        "idmap": 12930,
        "thanh": "U Châu"
    },
    {
        "idmap": 12930,
        "thanh": "U Châu"
    },
    {
        "idmap": 12931,
        "thanh": "U Châu"
    },
    {
        "idmap": 12931,
        "thanh": "U Châu"
    },
    {
        "idmap": 12931,
        "thanh": "U Châu"
    },
    {
        "idmap": 12931,
        "thanh": "U Châu"
    },
    {
        "idmap": 12932,
        "thanh": "U Châu"
    },
    {
        "idmap": 12932,
        "thanh": "U Châu"
    },
    {
        "idmap": 12932,
        "thanh": "U Châu"
    },
    {
        "idmap": 12932,
        "thanh": "U Châu"
    },
    {
        "idmap": 12933,
        "thanh": "U Châu"
    },
    {
        "idmap": 12933,
        "thanh": "U Châu"
    },
    {
        "idmap": 12933,
        "thanh": "U Châu"
    },
    {
        "idmap": 12933,
        "thanh": "U Châu"
    },
    {
        "idmap": 12934,
        "thanh": "U Châu"
    },
    {
        "idmap": 12934,
        "thanh": "U Châu"
    },
    {
        "idmap": 12934,
        "thanh": "U Châu"
    },
    {
        "idmap": 12934,
        "thanh": "U Châu"
    },
    {
        "idmap": 12934,
        "thanh": "U Châu"
    },
    {
        "idmap": 12934,
        "thanh": "U Châu"
    },
    {
        "idmap": 12934,
        "thanh": "U Châu"
    },
    {
        "idmap": 12934,
        "thanh": "U Châu"
    },
    {
        "idmap": 12935,
        "thanh": "U Châu"
    },
    {
        "idmap": 12935,
        "thanh": "U Châu"
    },
    {
        "idmap": 12935,
        "thanh": "U Châu"
    },
    {
        "idmap": 12935,
        "thanh": "U Châu"
    },
    {
        "idmap": 12936,
        "thanh": "U Châu"
    },
    {
        "idmap": 12936,
        "thanh": "U Châu"
    },
    {
        "idmap": 12936,
        "thanh": "U Châu"
    },
    {
        "idmap": 12936,
        "thanh": "U Châu"
    },
    {
        "idmap": 12937,
        "thanh": "U Châu"
    },
    {
        "idmap": 12937,
        "thanh": "U Châu"
    },
    {
        "idmap": 12937,
        "thanh": "U Châu"
    },
    {
        "idmap": 12937,
        "thanh": "U Châu"
    },
    {
        "idmap": 12938,
        "thanh": "U Châu"
    },
    {
        "idmap": 12938,
        "thanh": "U Châu"
    },
    {
        "idmap": 12938,
        "thanh": "U Châu"
    },
    {
        "idmap": 12938,
        "thanh": "U Châu"
    },
    {
        "idmap": 12939,
        "ten_map": "Tang 18 Nhi Kieu",
        "thanh": "U Châu"
    },
    {
        "idmap": 12939,
        "ten_map": "Tang 18 Nhi Kieu",
        "thanh": "U Châu"
    },
    {
        "idmap": 12939,
        "ten_map": "Tang 18 Nhi Kieu",
        "thanh": "U Châu"
    },
    {
        "idmap": 12939,
        "ten_map": "Tang 18 Nhi Kieu",
        "thanh": "U Châu"
    },
    {
        "idmap": 12941,
        "ten_map": "Tang 19 Nhi Kieu",
        "thanh": "U Châu"
    },
    {
        "idmap": 12942,
        "thanh": "U Châu"
    },
    {
        "idmap": 12942,
        "thanh": "U Châu"
    },
    {
        "idmap": 12942,
        "thanh": "U Châu"
    },
    {
        "idmap": 12942,
        "thanh": "U Châu"
    },
    {
        "idmap": 12942,
        "thanh": "U Châu"
    },
    {
        "idmap": 12942,
        "thanh": "U Châu"
    },
    {
        "idmap": 12942,
        "thanh": "U Châu"
    },
    {
        "idmap": 12942,
        "thanh": "U Châu"
    },
    {
        "idmap": 12942,
        "thanh": "U Châu"
    },
    {
        "idmap": 12943,
        "ten_map": "Tang 21 Nhi Kieu",
        "thanh": "U Châu"
    },
    {
        "idmap": 12943,
        "ten_map": "Tang 21 Nhi Kieu",
        "thanh": "U Châu"
    },
    {
        "idmap": 12943,
        "ten_map": "Tang 21 Nhi Kieu",
        "thanh": "U Châu"
    },
    {
        "idmap": 12943,
        "ten_map": "Tang 21 Nhi Kieu",
        "thanh": "U Châu"
    },
    {
        "idmap": 12943,
        "ten_map": "Tang 21 Nhi Kieu",
        "thanh": "U Châu"
    },
    {
        "idmap": 12943,
        "ten_map": "Tang 21 Nhi Kieu",
        "thanh": "U Châu"
    },
    {
        "idmap": 12990,
        "ten_map": "Vo Dai Thi Dau",
        "thanh": "U Châu"
    },
    {
        "idmap": 12990,
        "ten_map": "Vo Dai Thi Dau",
        "thanh": "U Châu"
    },
    {
        "idmap": 12990,
        "ten_map": "Vo Dai Thi Dau",
        "thanh": "U Châu"
    },
    {
        "idmap": 12990,
        "ten_map": "Vo Dai Thi Dau",
        "thanh": "U Châu"
    },
    {
        "idmap": 12990,
        "ten_map": "Vo Dai Thi Dau",
        "thanh": "U Châu"
    },
    {
        "idmap": 12990,
        "ten_map": "Vo Dai Thi Dau",
        "thanh": "U Châu"
    },
    {
        "idmap": 12990,
        "ten_map": "Vo Dai Thi Dau",
        "thanh": "U Châu"
    },
    {
        "idmap": 12990,
        "ten_map": "Vo Dai Thi Dau",
        "thanh": "U Châu"
    },
    {
        "idmap": 12990,
        "ten_map": "Vo Dai Thi Dau",
        "thanh": "U Châu"
    },
    {
        "idmap": 12990,
        "ten_map": "Vo Dai Thi Dau",
        "thanh": "U Châu"
    },
    {
        "idmap": 12990,
        "ten_map": "Vo Dai Thi Dau",
        "thanh": "U Châu"
    },
    {
        "idmap": 12991,
        "ten_map": "Dau Dai Dau Vo",
        "thanh": "U Châu"
    },
    {
        "idmap": 12991,
        "ten_map": "Dau Dai Dau Vo",
        "thanh": "U Châu"
    },
    {
        "idmap": 12991,
        "ten_map": "Dau Dai Dau Vo",
        "thanh": "U Châu"
    },
    {
        "idmap": 12991,
        "ten_map": "Dau Dai Dau Vo",
        "thanh": "U Châu"
    },
    {
        "idmap": 12991,
        "ten_map": "Dau Dai Dau Vo",
        "thanh": "U Châu"
    },
    {
        "idmap": 12991,
        "ten_map": "Dau Dai Dau Vo",
        "thanh": "U Châu"
    },
    {
        "idmap": 12991,
        "ten_map": "Dau Dai Dau Vo",
        "thanh": "U Châu"
    },
    {
        "idmap": 12991,
        "ten_map": "Dau Dai Dau Vo",
        "thanh": "U Châu"
    },
    {
        "idmap": 12991,
        "ten_map": "Dau Dai Dau Vo",
        "thanh": "U Châu"
    },
    {
        "idmap": 12991,
        "ten_map": "Dau Dai Dau Vo",
        "thanh": "U Châu"
    },
    {
        "idmap": 12991,
        "ten_map": "Dau Dai Dau Vo",
        "thanh": "U Châu"
    },
    {
        "idmap": 12991,
        "ten_map": "Dau Dai Dau Vo",
        "thanh": "U Châu"
    },
    {
        "idmap": 12991,
        "ten_map": "Dau Dai Dau Vo",
        "thanh": "U Châu"
    },
    {
        "idmap": 12991,
        "ten_map": "Dau Dai Dau Vo",
        "thanh": "U Châu"
    },
    {
        "idmap": 12991,
        "ten_map": "Dau Dai Dau Vo",
        "thanh": "U Châu"
    },
    {
        "idmap": 12991,
        "ten_map": "Dau Dai Dau Vo",
        "thanh": "U Châu"
    },
    {
        "idmap": 12991,
        "ten_map": "Dau Dai Dau Vo",
        "thanh": "U Châu"
    },
    {
        "idmap": 12991,
        "ten_map": "Dau Dai Dau Vo",
        "thanh": "U Châu"
    },
    {
        "idmap": 12991,
        "ten_map": "Dau Dai Dau Vo",
        "thanh": "U Châu"
    },
    {
        "idmap": 12992,
        "ten_map": "HuongDungBinh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12992,
        "ten_map": "HuongDungBinh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12992,
        "ten_map": "HuongDungBinh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12992,
        "ten_map": "HuongDungBinh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12992,
        "ten_map": "HuongDungBinh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12992,
        "ten_map": "HuongDungBinh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12992,
        "ten_map": "HuongDungBinh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12992,
        "ten_map": "HuongDungBinh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12992,
        "ten_map": "HuongDungBinh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12992,
        "ten_map": "HuongDungBinh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12992,
        "ten_map": "HuongDungBinh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12992,
        "ten_map": "HuongDungBinh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12992,
        "ten_map": "HuongDungBinh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12992,
        "ten_map": "HuongDungBinh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12992,
        "ten_map": "HuongDungBinh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12992,
        "ten_map": "HuongDungBinh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12992,
        "ten_map": "HuongDungBinh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12992,
        "ten_map": "HuongDungBinh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12992,
        "ten_map": "HuongDungBinh",
        "thanh": "U Châu"
    },
    {
        "idmap": 12992,
        "ten_map": "HuongDungBinh",
        "thanh": "U Châu"
    },
    {
        "idmap": 13000,
        "ten_map": "Trung Nguyen",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13001,
        "ten_map": "Thanh Mon Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13001,
        "ten_map": "Thanh Mon Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13001,
        "ten_map": "Thanh Mon Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13001,
        "ten_map": "Thanh Mon Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13001,
        "ten_map": "Thanh Mon Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13001,
        "ten_map": "Thanh Mon Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13001,
        "ten_map": "Thanh Mon Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13001,
        "ten_map": "Thanh Mon Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13001,
        "ten_map": "Thanh Mon Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13001,
        "ten_map": "Thanh Mon Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13001,
        "ten_map": "Thanh Mon Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13001,
        "ten_map": "Thanh Mon Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13001,
        "ten_map": "Thanh Mon Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13001,
        "ten_map": "Thanh Mon Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13001,
        "ten_map": "Thanh Mon Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13001,
        "ten_map": "Thanh Mon Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13001,
        "ten_map": "Thanh Mon Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13001,
        "ten_map": "Thanh Mon Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13001,
        "ten_map": "Thanh Mon Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13001,
        "ten_map": "Thanh Mon Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13001,
        "ten_map": "Thanh Mon Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13001,
        "ten_map": "Thanh Mon Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13001,
        "ten_map": "Thanh Mon Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13001,
        "ten_map": "Thanh Mon Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13001,
        "ten_map": "Thanh Mon Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13002,
        "ten_map": "Dai Do Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13002,
        "ten_map": "Dai Do Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13002,
        "ten_map": "Dai Do Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13002,
        "ten_map": "Dai Do Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13003,
        "ten_map": "Thanh Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13003,
        "ten_map": "Thanh Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13003,
        "ten_map": "Thanh Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13003,
        "ten_map": "Thanh Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13003,
        "ten_map": "Thanh Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13003,
        "ten_map": "Thanh Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13003,
        "ten_map": "Thanh Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13003,
        "ten_map": "Thanh Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13003,
        "ten_map": "Thanh Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13003,
        "ten_map": "Thanh Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13003,
        "ten_map": "Thanh Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13003,
        "ten_map": "Thanh Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13004,
        "ten_map": "QuangTruongLacDuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13004,
        "ten_map": "QuangTruongLacDuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13004,
        "ten_map": "QuangTruongLacDuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13004,
        "ten_map": "QuangTruongLacDuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13004,
        "ten_map": "QuangTruongLacDuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13004,
        "ten_map": "QuangTruongLacDuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13004,
        "ten_map": "QuangTruongLacDuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13004,
        "ten_map": "QuangTruongLacDuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13004,
        "ten_map": "QuangTruongLacDuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13004,
        "ten_map": "QuangTruongLacDuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13004,
        "ten_map": "QuangTruongLacDuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13005,
        "ten_map": "Hoa Vien Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13005,
        "ten_map": "Hoa Vien Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13005,
        "ten_map": "Hoa Vien Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13005,
        "ten_map": "Hoa Vien Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13005,
        "ten_map": "Hoa Vien Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13005,
        "ten_map": "Hoa Vien Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13005,
        "ten_map": "Hoa Vien Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13005,
        "ten_map": "Hoa Vien Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13011,
        "ten_map": "Thanh Mon Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13011,
        "ten_map": "Thanh Mon Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13011,
        "ten_map": "Thanh Mon Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13011,
        "ten_map": "Thanh Mon Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13011,
        "ten_map": "Thanh Mon Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13011,
        "ten_map": "Thanh Mon Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13011,
        "ten_map": "Thanh Mon Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13011,
        "ten_map": "Thanh Mon Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13011,
        "ten_map": "Thanh Mon Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13011,
        "ten_map": "Thanh Mon Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13011,
        "ten_map": "Thanh Mon Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13011,
        "ten_map": "Thanh Mon Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13011,
        "ten_map": "Thanh Mon Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13011,
        "ten_map": "Thanh Mon Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13011,
        "ten_map": "Thanh Mon Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13011,
        "ten_map": "Thanh Mon Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13011,
        "ten_map": "Thanh Mon Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13011,
        "ten_map": "Thanh Mon Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13011,
        "ten_map": "Thanh Mon Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13011,
        "ten_map": "Thanh Mon Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13011,
        "ten_map": "Thanh Mon Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13011,
        "ten_map": "Thanh Mon Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13011,
        "ten_map": "Thanh Mon Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13011,
        "ten_map": "Thanh Mon Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13011,
        "ten_map": "Thanh Mon Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13011,
        "ten_map": "Thanh Mon Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13011,
        "ten_map": "Thanh Mon Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13011,
        "ten_map": "Thanh Mon Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13011,
        "ten_map": "Thanh Mon Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13011,
        "ten_map": "Thanh Mon Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13012,
        "ten_map": "Dai Do Hua Xuong 1",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13012,
        "ten_map": "Dai Do Hua Xuong 1",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13012,
        "ten_map": "Dai Do Hua Xuong 1",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13013,
        "ten_map": "Dai Do Hua Xuong 2",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13013,
        "ten_map": "Dai Do Hua Xuong 2",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13013,
        "ten_map": "Dai Do Hua Xuong 2",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13013,
        "ten_map": "Dai Do Hua Xuong 2",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13013,
        "ten_map": "Dai Do Hua Xuong 2",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13013,
        "ten_map": "Dai Do Hua Xuong 2",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13013,
        "ten_map": "Dai Do Hua Xuong 2",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13013,
        "ten_map": "Dai Do Hua Xuong 2",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13013,
        "ten_map": "Dai Do Hua Xuong 2",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13014,
        "ten_map": "Diem Binh Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13014,
        "ten_map": "Diem Binh Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13014,
        "ten_map": "Diem Binh Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13014,
        "ten_map": "Diem Binh Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13014,
        "ten_map": "Diem Binh Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13014,
        "ten_map": "Diem Binh Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13014,
        "ten_map": "Diem Binh Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13014,
        "ten_map": "Diem Binh Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13014,
        "ten_map": "Diem Binh Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13014,
        "ten_map": "Diem Binh Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13014,
        "ten_map": "Diem Binh Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13014,
        "ten_map": "Diem Binh Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13014,
        "ten_map": "Diem Binh Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13014,
        "ten_map": "Diem Binh Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13014,
        "ten_map": "Diem Binh Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13014,
        "ten_map": "Diem Binh Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13014,
        "ten_map": "Diem Binh Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13014,
        "ten_map": "Diem Binh Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13014,
        "ten_map": "Diem Binh Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13014,
        "ten_map": "Diem Binh Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13014,
        "ten_map": "Diem Binh Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13014,
        "ten_map": "Diem Binh Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13014,
        "ten_map": "Diem Binh Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13014,
        "ten_map": "Diem Binh Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13014,
        "ten_map": "Diem Binh Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13014,
        "ten_map": "Diem Binh Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13014,
        "ten_map": "Diem Binh Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13014,
        "ten_map": "Diem Binh Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13014,
        "ten_map": "Diem Binh Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13014,
        "ten_map": "Diem Binh Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13014,
        "ten_map": "Diem Binh Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13014,
        "ten_map": "Diem Binh Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13014,
        "ten_map": "Diem Binh Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13014,
        "ten_map": "Diem Binh Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13014,
        "ten_map": "Diem Binh Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13015,
        "ten_map": "Dai Do Hua Xuong 3",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13015,
        "ten_map": "Dai Do Hua Xuong 3",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13015,
        "ten_map": "Dai Do Hua Xuong 3",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13015,
        "ten_map": "Dai Do Hua Xuong 3",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13015,
        "ten_map": "Dai Do Hua Xuong 3",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13015,
        "ten_map": "Dai Do Hua Xuong 3",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13015,
        "ten_map": "Dai Do Hua Xuong 3",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13015,
        "ten_map": "Dai Do Hua Xuong 3",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13015,
        "ten_map": "Dai Do Hua Xuong 3",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13016,
        "ten_map": "Duong Ham Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13016,
        "ten_map": "Duong Ham Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13017,
        "ten_map": "Vuon Hoa Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13017,
        "ten_map": "Vuon Hoa Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13017,
        "ten_map": "Vuon Hoa Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13017,
        "ten_map": "Vuon Hoa Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13017,
        "ten_map": "Vuon Hoa Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13017,
        "ten_map": "Vuon Hoa Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13017,
        "ten_map": "Vuon Hoa Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13017,
        "ten_map": "Vuon Hoa Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13017,
        "ten_map": "Vuon Hoa Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13017,
        "ten_map": "Vuon Hoa Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13017,
        "ten_map": "Vuon Hoa Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13017,
        "ten_map": "Vuon Hoa Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13018,
        "ten_map": "Hem nho Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13018,
        "ten_map": "Hem nho Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13018,
        "ten_map": "Hem nho Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13018,
        "ten_map": "Hem nho Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idnpc": 14351,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Viên Nhân",
        "level": 68,
        "hp": 464,
        "sp": 271,
        "hpx": 24,
        "spx": 13,
        "int": 19,
        "atk": 33,
        "def": 22,
        "agi": 39,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14388,
        "idmap": 12011,
        "ten_map": "Thanh Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Dương Xẩu",
        "level": 68,
        "hp": 482,
        "sp": 271,
        "hpx": 27,
        "spx": 13,
        "int": 21,
        "atk": 44,
        "def": 22,
        "agi": 24,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14455,
        "idmap": 12013,
        "ten_map": "Dia Nguyet KhVang",
        "thanh": "U Châu",
        "ten_npc": "Hoắc Nô",
        "level": 68,
        "hp": 470,
        "sp": 271,
        "hpx": 31,
        "spx": 13,
        "int": 9,
        "atk": 41,
        "def": 25,
        "agi": 32,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14474,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Trần Tựu",
        "level": 68,
        "hp": 430,
        "sp": 311,
        "hpx": 24,
        "spx": 20,
        "int": 9,
        "atk": 36,
        "def": 37,
        "agi": 25,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14629,
        "idmap": 12062,
        "ten_map": "Dai Do Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Nhậm Kì",
        "level": 68,
        "hp": 390,
        "sp": 294,
        "hpx": 17,
        "spx": 17,
        "int": 12,
        "atk": 41,
        "def": 23,
        "agi": 42,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14631,
        "idmap": 12062,
        "ten_map": "Dai Do Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Trương Trụ",
        "level": 68,
        "hp": 550,
        "sp": 282,
        "hpx": 45,
        "spx": 15,
        "int": 45,
        "atk": 7,
        "def": 15,
        "agi": 25,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14668,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Vương Kháng",
        "level": 68,
        "hp": 578,
        "sp": 265,
        "hpx": 32,
        "spx": 12,
        "int": 48,
        "atk": 17,
        "def": 22,
        "agi": 35,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15435,
        "idmap": 12409,
        "thanh": "U Châu",
        "ten_npc": "Thạch Di",
        "level": 68,
        "hp": 652,
        "sp": 299,
        "hpx": 27,
        "spx": 18,
        "int": 43,
        "atk": 13,
        "def": 13,
        "agi": 28,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17075,
        "idmap": 12523,
        "thanh": "U Châu",
        "ten_npc": "Hắt Giáp Sĩ",
        "level": 68,
        "hp": 429,
        "sp": 183,
        "hpx": 28,
        "spx": 6,
        "int": 5,
        "atk": 37,
        "def": 26,
        "agi": 33,
        "item1": 26125,
        "item2": 26124,
        "item3": 26121,
        "item4": 43009,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17104,
        "idmap": 12532,
        "thanh": "U Châu",
        "ten_npc": "Diệu Võ Quân",
        "level": 68,
        "hp": 414,
        "sp": 248,
        "hpx": 27,
        "spx": 17,
        "int": 31,
        "atk": 18,
        "def": 23,
        "agi": 25,
        "item1": 27024,
        "item2": 26167,
        "item3": 26011,
        "item4": 0,
        "item5": 50001,
        "item6": 0
    },
    {
        "idnpc": 17144,
        "idmap": 12546,
        "thanh": "U Châu",
        "ten_npc": "Thủ Kích Binh",
        "level": 68,
        "hp": 414,
        "sp": 242,
        "hpx": 27,
        "spx": 16,
        "int": 7,
        "atk": 25,
        "def": 28,
        "agi": 32,
        "item1": 32022,
        "item2": 27021,
        "item3": 26122,
        "item4": 43080,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17187,
        "idmap": 12568,
        "thanh": "U Châu",
        "ten_npc": "Trường An Binh",
        "level": 68,
        "hp": 488,
        "sp": 194,
        "hpx": 40,
        "spx": 8,
        "int": 5,
        "atk": 31,
        "def": 26,
        "agi": 31,
        "item1": 27027,
        "item2": 26168,
        "item3": 26087,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17316,
        "idmap": 12701,
        "ten_map": "Doanh Trai Huong Dung",
        "thanh": "U Châu",
        "ten_npc": "Đao Phủ Thủ",
        "level": 68,
        "hp": 414,
        "sp": 240,
        "hpx": 27,
        "spx": 16,
        "int": 2,
        "atk": 44,
        "def": 25,
        "agi": 21,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17379,
        "idmap": 12802,
        "ten_map": "Rung Cay ngoai o 1",
        "thanh": "U Châu",
        "ten_npc": "Dã Nhân",
        "level": 68,
        "hp": 414,
        "sp": 241,
        "hpx": 27,
        "spx": 16,
        "int": 6,
        "atk": 32,
        "def": 30,
        "agi": 24,
        "item1": 26060,
        "item2": 26010,
        "item3": 26060,
        "item4": 34005,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10099,
        "idmap": 10816,
        "ten_npc": "Bạch Hiểu",
        "level": 69,
        "hp": 429,
        "sp": 279,
        "hpx": 23,
        "spx": 14,
        "int": 11,
        "atk": 45,
        "def": 28,
        "agi": 32,
        "item1": 27057,
        "item2": 27039,
        "item3": 27007,
        "item4": 0,
        "item5": 49001,
        "item6": 47018
    },
    {
        "idnpc": 10108,
        "idmap": 10818,
        "ten_npc": "Ngũ Lục",
        "level": 69,
        "hp": 455,
        "sp": 273,
        "hpx": 28,
        "spx": 13,
        "int": 13,
        "atk": 33,
        "def": 35,
        "agi": 31,
        "item1": 26115,
        "item2": 27038,
        "item3": 27008,
        "item4": 0,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 10236,
        "idmap": 10951,
        "ten_npc": "Dương Tòng",
        "level": 69,
        "hp": 463,
        "sp": 250,
        "hpx": 29,
        "spx": 9,
        "int": 41,
        "atk": 17,
        "def": 32,
        "agi": 25,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47349
    },
    {
        "idnpc": 11013,
        "idmap": 10965,
        "ten_npc": "Chu Linh",
        "level": 69,
        "hp": 455,
        "sp": 227,
        "hpx": 28,
        "spx": 5,
        "int": 13,
        "atk": 45,
        "def": 33,
        "agi": 29,
        "item1": 27032,
        "item2": 26059,
        "item3": 26060,
        "item4": 0,
        "item5": 0,
        "item6": 47274
    },
    {
        "idnpc": 11070,
        "idmap": 10986,
        "ten_map": "Nghia Trang Van Thang",
        "ten_npc": "Tần Kỳ",
        "level": 69,
        "hp": 455,
        "sp": 238,
        "hpx": 28,
        "spx": 7,
        "int": 22,
        "atk": 40,
        "def": 29,
        "agi": 27,
        "item1": 27033,
        "item2": 26160,
        "item3": 26060,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11114,
        "idmap": 10995,
        "ten_npc": "Trương Dĩ",
        "level": 69,
        "hp": 490,
        "sp": 293,
        "hpx": 28,
        "spx": 16,
        "int": 7,
        "atk": 42,
        "def": 27,
        "agi": 31,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11133,
        "idmap": 11000,
        "ten_map": "Thanh Chau",
        "thanh": "Thanh Châu",
        "ten_npc": "Tôn Tử Tài",
        "level": 69,
        "hp": 434,
        "sp": 290,
        "hpx": 18,
        "spx": 16,
        "int": 47,
        "atk": 43,
        "def": 11,
        "agi": 23,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12076,
        "idmap": 11264,
        "thanh": "Thanh Châu",
        "ten_npc": "Đặng Phương",
        "level": 69,
        "hp": 503,
        "sp": 313,
        "hpx": 24,
        "spx": 20,
        "int": 7,
        "atk": 47,
        "def": 19,
        "agi": 39,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12137,
        "idmap": 11431,
        "ten_map": "Rung Thai Son",
        "thanh": "Thanh Châu",
        "ten_npc": "Bàng Nhu",
        "level": 69,
        "hp": 573,
        "sp": 313,
        "hpx": 36,
        "spx": 20,
        "int": 46,
        "atk": 13,
        "def": 20,
        "agi": 18,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13023,
        "idmap": 11504,
        "ten_map": "Son Dong Thai Son 4",
        "thanh": "Thanh Châu",
        "ten_npc": "Trình Bỉnh",
        "level": 69,
        "hp": 490,
        "sp": 304,
        "hpx": 28,
        "spx": 18,
        "int": 45,
        "atk": 21,
        "def": 22,
        "agi": 19,
        "item1": 26100,
        "item2": 26035,
        "item3": 26158,
        "item4": 0,
        "item5": 0,
        "item6": 47239
    },
    {
        "idnpc": 13098,
        "idmap": 11542,
        "ten_map": "Dong Quy Than 12",
        "thanh": "Thanh Châu",
        "ten_npc": "Tống Định",
        "level": 69,
        "hp": 515,
        "sp": 261,
        "hpx": 32,
        "spx": 11,
        "int": 11,
        "atk": 44,
        "def": 41,
        "agi": 13,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13099,
        "idmap": 11542,
        "ten_map": "Dong Quy Than 12",
        "thanh": "Thanh Châu",
        "ten_npc": "Từ Cố",
        "level": 69,
        "hp": 498,
        "sp": 250,
        "hpx": 29,
        "spx": 9,
        "int": 13,
        "atk": 46,
        "def": 33,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13136,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Thôi Vũ",
        "level": 69,
        "hp": 593,
        "sp": 219,
        "hpx": 28,
        "spx": 12,
        "int": 23,
        "atk": 53,
        "def": 13,
        "agi": 23,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14050,
        "idmap": 11569,
        "thanh": "Thanh Châu",
        "ten_npc": "Vương Khang",
        "level": 69,
        "hp": 455,
        "sp": 279,
        "hpx": 28,
        "spx": 14,
        "int": 21,
        "atk": 22,
        "def": 30,
        "agi": 38,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14197,
        "idmap": 11811,
        "ten_map": "Rung Don Khuu",
        "thanh": "Thanh Châu",
        "ten_npc": "Địch Bỉ",
        "level": 69,
        "hp": 455,
        "sp": 279,
        "hpx": 28,
        "spx": 14,
        "int": 15,
        "atk": 33,
        "def": 30,
        "agi": 33,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14293,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Lữ Do",
        "level": 69,
        "hp": 490,
        "sp": 279,
        "hpx": 28,
        "spx": 14,
        "int": 16,
        "atk": 38,
        "def": 23,
        "agi": 34,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14380,
        "idmap": 12007,
        "ten_map": "K.San Vui Lai",
        "thanh": "U Châu",
        "ten_npc": "Trương Nhã",
        "level": 69,
        "hp": 490,
        "sp": 295,
        "hpx": 28,
        "spx": 17,
        "int": 14,
        "atk": 38,
        "def": 28,
        "agi": 29,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14459,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Trương Thành",
        "level": 69,
        "hp": 434,
        "sp": 319,
        "hpx": 24,
        "spx": 21,
        "int": 5,
        "atk": 38,
        "def": 24,
        "agi": 40,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14566,
        "idmap": 12031,
        "ten_map": "Thon Lau Song",
        "thanh": "U Châu",
        "ten_npc": "Khu Cảnh",
        "level": 69,
        "hp": 486,
        "sp": 261,
        "hpx": 33,
        "spx": 11,
        "int": 8,
        "atk": 44,
        "def": 40,
        "agi": 16,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14630,
        "idmap": 12062,
        "ten_map": "Dai Do Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Tào Hóa",
        "level": 69,
        "hp": 446,
        "sp": 267,
        "hpx": 26,
        "spx": 12,
        "int": 13,
        "atk": 43,
        "def": 38,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17077,
        "idmap": 12523,
        "thanh": "U Châu",
        "ten_npc": "Xích Giáp Sĩ",
        "level": 69,
        "hp": 431,
        "sp": 213,
        "hpx": 28,
        "spx": 11,
        "int": 7,
        "atk": 26,
        "def": 29,
        "agi": 35,
        "item1": 27042,
        "item2": 27062,
        "item3": 32021,
        "item4": 35007,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17102,
        "idmap": 12530,
        "thanh": "U Châu",
        "ten_npc": "Huyền Võ Quân",
        "level": 69,
        "hp": 421,
        "sp": 248,
        "hpx": 28,
        "spx": 17,
        "int": 20,
        "atk": 32,
        "def": 23,
        "agi": 23,
        "item1": 26083,
        "item2": 26086,
        "item3": 40005,
        "item4": 0,
        "item5": 50001,
        "item6": 0
    },
    {
        "idnpc": 17105,
        "idmap": 12532,
        "thanh": "U Châu",
        "ten_npc": "Chấn Võ Quân",
        "level": 69,
        "hp": 421,
        "sp": 249,
        "hpx": 28,
        "spx": 17,
        "int": 21,
        "atk": 20,
        "def": 23,
        "agi": 34,
        "item1": 26167,
        "item2": 27024,
        "item3": 34005,
        "item4": 0,
        "item5": 50001,
        "item6": 0
    },
    {
        "idnpc": 17107,
        "idmap": 12532,
        "thanh": "U Châu",
        "ten_npc": "Liệt Võ Quân",
        "level": 69,
        "hp": 421,
        "sp": 253,
        "hpx": 28,
        "spx": 18,
        "int": 35,
        "atk": 13,
        "def": 21,
        "agi": 29,
        "item1": 26166,
        "item2": 27031,
        "item3": 41006,
        "item4": 33006,
        "item5": 50001,
        "item6": 0
    },
    {
        "idnpc": 17158,
        "idmap": 12556,
        "thanh": "U Châu",
        "ten_npc": "Ngưu Huyền Tử",
        "level": 69,
        "hp": 481,
        "sp": 250,
        "hpx": 38,
        "spx": 17,
        "int": 27,
        "atk": 17,
        "def": 25,
        "agi": 19,
        "item1": 26095,
        "item2": 26116,
        "item3": 26069,
        "item4": 50002,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17188,
        "idmap": 12569,
        "thanh": "U Châu",
        "ten_npc": "Tây Lương Binh",
        "level": 69,
        "hp": 498,
        "sp": 179,
        "hpx": 41,
        "spx": 5,
        "int": 3,
        "atk": 36,
        "def": 29,
        "agi": 29,
        "item1": 27033,
        "item2": 26083,
        "item3": 26007,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17317,
        "idmap": 12701,
        "ten_map": "Doanh Trai Huong Dung",
        "thanh": "U Châu",
        "ten_npc": "Đao Phủ Thủ",
        "level": 69,
        "hp": 421,
        "sp": 243,
        "hpx": 28,
        "spx": 16,
        "int": 3,
        "atk": 43,
        "def": 26,
        "agi": 20,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10007,
        "idmap": 10801,
        "ten_npc": "Mã.N.Nghĩa",
        "level": 70,
        "hp": 474,
        "sp": 275,
        "hpx": 24,
        "spx": 13,
        "int": 12,
        "atk": 49,
        "def": 33,
        "agi": 34,
        "item1": 26034,
        "item2": 26036,
        "item3": 27005,
        "item4": 0,
        "item5": 49001,
        "item6": 47099
    },
    {
        "idnpc": 10098,
        "idmap": 10816,
        "ten_npc": "Dư Độc",
        "level": 70,
        "hp": 451,
        "sp": 269,
        "hpx": 20,
        "spx": 12,
        "int": 12,
        "atk": 53,
        "def": 36,
        "agi": 32,
        "item1": 26016,
        "item2": 27018,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47007
    },
    {
        "idnpc": 10109,
        "idmap": 10818,
        "ten_npc": "Đại Hồng",
        "level": 70,
        "hp": 468,
        "sp": 275,
        "hpx": 23,
        "spx": 13,
        "int": 10,
        "atk": 49,
        "def": 38,
        "agi": 32,
        "item1": 26115,
        "item2": 27043,
        "item3": 26018,
        "item4": 0,
        "item5": 49001,
        "item6": 47009
    },
    {
        "idnpc": 10160,
        "idmap": 10868,
        "ten_npc": "Tiểu.Ch.Tướng",
        "level": 70,
        "hp": 502,
        "sp": 197,
        "hpx": 35,
        "spx": 8,
        "int": 10,
        "atk": 34,
        "def": 32,
        "agi": 26,
        "item1": 26159,
        "item2": 27048,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10170,
        "idmap": 10902,
        "ten_npc": "Đ.Châu Tướng",
        "level": 70,
        "hp": 433,
        "sp": 248,
        "hpx": 23,
        "spx": 17,
        "int": 8,
        "atk": 30,
        "def": 40,
        "agi": 27,
        "item1": 26170,
        "item2": 26050,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10207,
        "idmap": 10915,
        "ten_map": "Khach san nguoi moi",
        "ten_npc": "SơnBinh KhVàng",
        "level": 70,
        "hp": 462,
        "sp": 250,
        "hpx": 28,
        "spx": 17,
        "int": 16,
        "atk": 26,
        "def": 34,
        "agi": 24,
        "item1": 26169,
        "item2": 26169,
        "item3": 26167,
        "item4": 46018,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10208,
        "idmap": 10915,
        "ten_map": "Khach san nguoi moi",
        "ten_npc": "Dũng Sĩ KhVàng",
        "level": 70,
        "hp": 462,
        "sp": 244,
        "hpx": 28,
        "spx": 16,
        "int": 18,
        "atk": 32,
        "def": 26,
        "agi": 25,
        "item1": 27003,
        "item2": 26167,
        "item3": 27003,
        "item4": 46016,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11125,
        "idmap": 10997,
        "ten_npc": "Yến Minh",
        "level": 70,
        "hp": 451,
        "sp": 298,
        "hpx": 14,
        "spx": 17,
        "int": 13,
        "atk": 62,
        "def": 39,
        "agi": 17,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11128,
        "idmap": 10997,
        "ten_npc": "Hà Hầu Kiệt",
        "level": 70,
        "hp": 462,
        "sp": 297,
        "hpx": 22,
        "spx": 17,
        "int": 9,
        "atk": 57,
        "def": 23,
        "agi": 34,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11130,
        "idmap": 10997,
        "ten_npc": "Khang kỳ",
        "level": 70,
        "hp": 532,
        "sp": 252,
        "hpx": 28,
        "spx": 9,
        "int": 10,
        "atk": 49,
        "def": 35,
        "agi": 31,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12112,
        "idmap": 11401,
        "ten_map": "Dinh Mat Vi Son",
        "thanh": "Thanh Châu",
        "ten_npc": "Ngô Nãng",
        "level": 70,
        "hp": 520,
        "sp": 293,
        "hpx": 26,
        "spx": 16,
        "int": 21,
        "atk": 49,
        "def": 33,
        "agi": 17,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13004,
        "idmap": 11502,
        "ten_map": "Son Dong Thai Son 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Chu Trị",
        "level": 70,
        "hp": 503,
        "sp": 293,
        "hpx": 23,
        "spx": 16,
        "int": 22,
        "atk": 41,
        "def": 34,
        "agi": 29,
        "item1": 27032,
        "item2": 26029,
        "item3": 26090,
        "item4": 0,
        "item5": 0,
        "item6": 47369
    },
    {
        "idnpc": 13067,
        "idmap": 11527,
        "ten_map": "Son Dong Thai Son 25",
        "thanh": "Thanh Châu",
        "ten_npc": "Đinh Phan",
        "level": 70,
        "hp": 578,
        "sp": 258,
        "hpx": 36,
        "spx": 10,
        "int": 8,
        "atk": 48,
        "def": 37,
        "agi": 24,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13071,
        "idmap": 11529,
        "ten_map": "Son Dong Thai Son 27",
        "thanh": "Thanh Châu",
        "ten_npc": "Phùng Tắc",
        "level": 70,
        "hp": 509,
        "sp": 327,
        "hpx": 24,
        "spx": 22,
        "int": 10,
        "atk": 44,
        "def": 25,
        "agi": 38,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13135,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Đàm Hùng",
        "level": 70,
        "hp": 573,
        "sp": 249,
        "hpx": 23,
        "spx": 17,
        "int": 13,
        "atk": 58,
        "def": 18,
        "agi": 28,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14190,
        "idmap": 11811,
        "ten_map": "Rung Don Khuu",
        "thanh": "Thanh Châu",
        "ten_npc": "Tướng TrụThiên",
        "level": 70,
        "hp": 509,
        "sp": 209,
        "hpx": 24,
        "spx": 10,
        "int": 12,
        "atk": 47,
        "def": 38,
        "agi": 34,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14234,
        "idmap": 11831,
        "ten_map": "Lang Ta Phi Lam 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Công Tôn Độc",
        "level": 70,
        "hp": 526,
        "sp": 310,
        "hpx": 27,
        "spx": 19,
        "int": 8,
        "atk": 47,
        "def": 36,
        "agi": 26,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14272,
        "idmap": 12000,
        "ten_map": "U Chau",
        "thanh": "U Châu",
        "ten_npc": "Mạnh Đại",
        "level": 70,
        "hp": 532,
        "sp": 299,
        "hpx": 28,
        "spx": 17,
        "int": 16,
        "atk": 48,
        "def": 26,
        "agi": 30,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14310,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Ngô Tư",
        "level": 70,
        "hp": 532,
        "sp": 298,
        "hpx": 28,
        "spx": 17,
        "int": 12,
        "atk": 45,
        "def": 24,
        "agi": 39,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14359,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Lưu Diều",
        "level": 70,
        "hp": 952,
        "sp": 308,
        "hpx": 28,
        "spx": 19,
        "int": 46,
        "atk": 25,
        "def": 30,
        "agi": 21,
        "item1": 26053,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idmap": 13019,
        "ten_map": "Duong sau Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13019,
        "ten_map": "Duong sau Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13019,
        "ten_map": "Duong sau Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13019,
        "ten_map": "Duong sau Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13019,
        "ten_map": "Duong sau Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13019,
        "ten_map": "Duong sau Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13019,
        "ten_map": "Duong sau Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13021,
        "ten_map": "Uyen Thanh Thanh Mon",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13021,
        "ten_map": "Uyen Thanh Thanh Mon",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13021,
        "ten_map": "Uyen Thanh Thanh Mon",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13021,
        "ten_map": "Uyen Thanh Thanh Mon",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13022,
        "ten_map": "Dai Do uyen Thanh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13022,
        "ten_map": "Dai Do uyen Thanh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13022,
        "ten_map": "Dai Do uyen Thanh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13022,
        "ten_map": "Dai Do uyen Thanh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13022,
        "ten_map": "Dai Do uyen Thanh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13022,
        "ten_map": "Dai Do uyen Thanh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13022,
        "ten_map": "Dai Do uyen Thanh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13022,
        "ten_map": "Dai Do uyen Thanh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13022,
        "ten_map": "Dai Do uyen Thanh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13022,
        "ten_map": "Dai Do uyen Thanh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13022,
        "ten_map": "Dai Do uyen Thanh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13022,
        "ten_map": "Dai Do uyen Thanh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13022,
        "ten_map": "Dai Do uyen Thanh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13022,
        "ten_map": "Dai Do uyen Thanh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13022,
        "ten_map": "Dai Do uyen Thanh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13022,
        "ten_map": "Dai Do uyen Thanh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13022,
        "ten_map": "Dai Do uyen Thanh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13022,
        "ten_map": "Dai Do uyen Thanh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13022,
        "ten_map": "Dai Do uyen Thanh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13023,
        "ten_map": "Cua Dong Thien Doc",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13023,
        "ten_map": "Cua Dong Thien Doc",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13023,
        "ten_map": "Cua Dong Thien Doc",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13023,
        "ten_map": "Cua Dong Thien Doc",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13023,
        "ten_map": "Cua Dong Thien Doc",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13023,
        "ten_map": "Cua Dong Thien Doc",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13023,
        "ten_map": "Cua Dong Thien Doc",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13031,
        "ten_map": "Tran Luu Thanh Mon",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13031,
        "ten_map": "Tran Luu Thanh Mon",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13031,
        "ten_map": "Tran Luu Thanh Mon",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13031,
        "ten_map": "Tran Luu Thanh Mon",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13031,
        "ten_map": "Tran Luu Thanh Mon",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13031,
        "ten_map": "Tran Luu Thanh Mon",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13031,
        "ten_map": "Tran Luu Thanh Mon",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13032,
        "ten_map": "Dai Do Tran Luu 1",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13032,
        "ten_map": "Dai Do Tran Luu 1",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13032,
        "ten_map": "Dai Do Tran Luu 1",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13032,
        "ten_map": "Dai Do Tran Luu 1",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13032,
        "ten_map": "Dai Do Tran Luu 1",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13032,
        "ten_map": "Dai Do Tran Luu 1",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13032,
        "ten_map": "Dai Do Tran Luu 1",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13033,
        "ten_map": "Dai Do Tran Luu 2",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13033,
        "ten_map": "Dai Do Tran Luu 2",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13033,
        "ten_map": "Dai Do Tran Luu 2",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13041,
        "ten_map": "Pham Nam Thanh Mon",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13041,
        "ten_map": "Pham Nam Thanh Mon",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13041,
        "ten_map": "Pham Nam Thanh Mon",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13041,
        "ten_map": "Pham Nam Thanh Mon",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13041,
        "ten_map": "Pham Nam Thanh Mon",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13041,
        "ten_map": "Pham Nam Thanh Mon",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13042,
        "ten_map": "Dai Do Pham Nam",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13042,
        "ten_map": "Dai Do Pham Nam",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13042,
        "ten_map": "Dai Do Pham Nam",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13042,
        "ten_map": "Dai Do Pham Nam",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13043,
        "ten_map": "Mo Khoang Pham Nam",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13043,
        "ten_map": "Mo Khoang Pham Nam",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13043,
        "ten_map": "Mo Khoang Pham Nam",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13043,
        "ten_map": "Mo Khoang Pham Nam",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13043,
        "ten_map": "Mo Khoang Pham Nam",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13043,
        "ten_map": "Mo Khoang Pham Nam",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13043,
        "ten_map": "Mo Khoang Pham Nam",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13043,
        "ten_map": "Mo Khoang Pham Nam",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13051,
        "ten_map": "Thon Dinh Chau",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13051,
        "ten_map": "Thon Dinh Chau",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13051,
        "ten_map": "Thon Dinh Chau",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13051,
        "ten_map": "Thon Dinh Chau",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13051,
        "ten_map": "Thon Dinh Chau",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13051,
        "ten_map": "Thon Dinh Chau",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13051,
        "ten_map": "Thon Dinh Chau",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13051,
        "ten_map": "Thon Dinh Chau",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13051,
        "ten_map": "Thon Dinh Chau",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13051,
        "ten_map": "Thon Dinh Chau",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13051,
        "ten_map": "Thon Dinh Chau",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13051,
        "ten_map": "Thon Dinh Chau",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13051,
        "ten_map": "Thon Dinh Chau",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13051,
        "ten_map": "Thon Dinh Chau",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13051,
        "ten_map": "Thon Dinh Chau",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13061,
        "ten_map": "Cat Ba O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13061,
        "ten_map": "Cat Ba O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13061,
        "ten_map": "Cat Ba O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13061,
        "ten_map": "Cat Ba O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13061,
        "ten_map": "Cat Ba O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13061,
        "ten_map": "Cat Ba O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13061,
        "ten_map": "Cat Ba O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13061,
        "ten_map": "Cat Ba O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13061,
        "ten_map": "Cat Ba O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13061,
        "ten_map": "Cat Ba O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13061,
        "ten_map": "Cat Ba O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13061,
        "ten_map": "Cat Ba O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13061,
        "ten_map": "Cat Ba O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13061,
        "ten_map": "Cat Ba O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13061,
        "ten_map": "Cat Ba O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13061,
        "ten_map": "Cat Ba O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13061,
        "ten_map": "Cat Ba O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13061,
        "ten_map": "Cat Ba O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13061,
        "ten_map": "Cat Ba O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13061,
        "ten_map": "Cat Ba O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13061,
        "ten_map": "Cat Ba O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13061,
        "ten_map": "Cat Ba O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13071,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13101,
        "ten_map": "N.Thai Ap Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13101,
        "ten_map": "N.Thai Ap Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13102,
        "ten_map": "N.Ha Tien Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13102,
        "ten_map": "N.Ha Tien Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13103,
        "ten_map": "N.Quach Gia Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13103,
        "ten_map": "N.Quach Gia Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13104,
        "ten_map": "N.Tao Thao Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13104,
        "ten_map": "N.Tao Thao Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13104,
        "ten_map": "N.Tao Thao Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13104,
        "ten_map": "N.Tao Thao Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13104,
        "ten_map": "N.Tao Thao Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13104,
        "ten_map": "N.Tao Thao Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13104,
        "ten_map": "N.Tao Thao Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13104,
        "ten_map": "N.Tao Thao Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13104,
        "ten_map": "N.Tao Thao Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13104,
        "ten_map": "N.Tao Thao Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13104,
        "ten_map": "N.Tao Thao Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13104,
        "ten_map": "N.Tao Thao Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13105,
        "ten_map": "N.Truong Tu uyen Thanh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13105,
        "ten_map": "N.Truong Tu uyen Thanh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13105,
        "ten_map": "N.Truong Tu uyen Thanh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13105,
        "ten_map": "N.Truong Tu uyen Thanh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13106,
        "ten_map": "N.Ly Vinh Pham Nam",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13106,
        "ten_map": "N.Ly Vinh Pham Nam",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13106,
        "ten_map": "N.Ly Vinh Pham Nam",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13107,
        "ten_map": "N.Tuan Uc Ho Vinh Xuyen",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13107,
        "ten_map": "N.Tuan Uc Ho Vinh Xuyen",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13108,
        "ten_map": "N.bac Tran Vinh Xuyen",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13108,
        "ten_map": "N.bac Tran Vinh Xuyen",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13108,
        "ten_map": "N.bac Tran Vinh Xuyen",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13108,
        "ten_map": "N.bac Tran Vinh Xuyen",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13108,
        "ten_map": "N.bac Tran Vinh Xuyen",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13108,
        "ten_map": "N.bac Tran Vinh Xuyen",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13108,
        "ten_map": "N.bac Tran Vinh Xuyen",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13109,
        "ten_map": "N.Hua Chu Ba Cat O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13109,
        "ten_map": "N.Hua Chu Ba Cat O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13109,
        "ten_map": "N.Hua Chu Ba Cat O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13109,
        "ten_map": "N.Hua Chu Ba Cat O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13110,
        "ten_map": "N.Tu Ma Y Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13110,
        "ten_map": "N.Tu Ma Y Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13110,
        "ten_map": "N.Tu Ma Y Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13110,
        "ten_map": "N.Tu Ma Y Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13110,
        "ten_map": "N.Tu Ma Y Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13111,
        "ten_map": "N.Tao Nhu Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13112,
        "ten_map": "N.TrXuan Hoa Tran Luu",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13113,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13113,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13113,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13113,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13114,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13114,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13114,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13114,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13114,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13114,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13114,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13114,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13114,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13114,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13114,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13114,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13115,
        "ten_map": "Duong Tu gia",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13115,
        "ten_map": "Duong Tu gia",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13131,
        "ten_map": "N.Duong Buu Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13131,
        "ten_map": "N.Duong Buu Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13132,
        "ten_map": "N.Man Sung Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13132,
        "ten_map": "N.Man Sung Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13133,
        "ten_map": "N.Tao An Dan Uyen Thanh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13133,
        "ten_map": "N.Tao An Dan Uyen Thanh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13133,
        "ten_map": "N.Tao An Dan Uyen Thanh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13133,
        "ten_map": "N.Tao An Dan Uyen Thanh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13133,
        "ten_map": "N.Tao An Dan Uyen Thanh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13133,
        "ten_map": "N.Tao An Dan Uyen Thanh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13134,
        "ten_map": "N.Truong Mao Tran Luu",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13134,
        "ten_map": "N.Truong Mao Tran Luu",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13135,
        "ten_map": "N.Ma Tran Vinh Xuyen",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13135,
        "ten_map": "N.Ma Tran Vinh Xuyen",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13136,
        "ten_map": "N.Don Kinh Ba Cat O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13136,
        "ten_map": "N.Don Kinh Ba Cat O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13136,
        "ten_map": "N.Don Kinh Ba Cat O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13136,
        "ten_map": "N.Don Kinh Ba Cat O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13137,
        "ten_map": "N.Thai Van Co Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13137,
        "ten_map": "N.Thai Van Co Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13138,
        "ten_map": "N.Ha Ngau Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13138,
        "ten_map": "N.Ha Ngau Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13139,
        "ten_map": "N.Ha Hau Lam Ba Cat O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13139,
        "ten_map": "N.Ha Hau Lam Ba Cat O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13139,
        "ten_map": "N.Ha Hau Lam Ba Cat O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13140,
        "ten_map": "N.Hoang Khoi Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13141,
        "ten_map": "N.Du Cam Tran Luu",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13142,
        "ten_map": "N.Truong Che Tran Luu",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13142,
        "ten_map": "N.Truong Che Tran Luu",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13143,
        "ten_map": "N.Kim Ve Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13143,
        "ten_map": "N.Kim Ve Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13143,
        "ten_map": "N.Kim Ve Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13143,
        "ten_map": "N.Kim Ve Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13143,
        "ten_map": "N.Kim Ve Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13151,
        "ten_map": "Phong Hoang De Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13151,
        "ten_map": "Phong Hoang De Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13151,
        "ten_map": "Phong Hoang De Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13151,
        "ten_map": "Phong Hoang De Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13151,
        "ten_map": "Phong Hoang De Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13151,
        "ten_map": "Phong Hoang De Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13151,
        "ten_map": "Phong Hoang De Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13151,
        "ten_map": "Phong Hoang De Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13151,
        "ten_map": "Phong Hoang De Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13171,
        "ten_map": "N.Luu Ban Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13171,
        "ten_map": "N.Luu Ban Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13171,
        "ten_map": "N.Luu Ban Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13171,
        "ten_map": "N.Luu Ban Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13171,
        "ten_map": "N.Luu Ban Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13172,
        "ten_map": "N.Dong Thua Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13172,
        "ten_map": "N.Dong Thua Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13172,
        "ten_map": "N.Dong Thua Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13172,
        "ten_map": "N.Dong Thua Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13173,
        "ten_map": "N.Quan Vu Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13173,
        "ten_map": "N.Quan Vu Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13173,
        "ten_map": "N.Quan Vu Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13173,
        "ten_map": "N.Quan Vu Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13174,
        "ten_map": "N.Ong Lao Tran Uyen Thanh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13174,
        "ten_map": "N.Ong Lao Tran Uyen Thanh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13175,
        "ten_map": "N.Dien Vi Tran Luu",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13175,
        "ten_map": "N.Dien Vi Tran Luu",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13176,
        "ten_map": "N.Ba Lao Manh Pham Nam",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13176,
        "ten_map": "N.Ba Lao Manh Pham Nam",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13176,
        "ten_map": "N.Ba Lao Manh Pham Nam",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13177,
        "ten_map": "N.Quach Gia Ho Vinh Xuyen",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13177,
        "ten_map": "N.Quach Gia Ho Vinh Xuyen",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13178,
        "ten_map": "N.Dong Chieu Vinh Xuyen",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13178,
        "ten_map": "N.Dong Chieu Vinh Xuyen",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13178,
        "ten_map": "N.Dong Chieu Vinh Xuyen",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13179,
        "ten_map": "N.Gia Cat Huyen Ba Cat O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13179,
        "ten_map": "N.Gia Cat Huyen Ba Cat O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13180,
        "ten_map": "N.Quan Binh Ba Cat O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13180,
        "ten_map": "N.Quan Binh Ba Cat O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13180,
        "ten_map": "N.Quan Binh Ba Cat O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13180,
        "ten_map": "N.Quan Binh Ba Cat O",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13181,
        "ten_map": "N.Ngu Phu Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13181,
        "ten_map": "N.Ngu Phu Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13182,
        "ten_map": "N.Lo Truc Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13182,
        "ten_map": "N.Lo Truc Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13183,
        "ten_map": "N.Tu Lao phu Nhan VXuyen",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13183,
        "ten_map": "N.Tu Lao phu Nhan VXuyen",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13184,
        "ten_map": "N.Truong Tung Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13184,
        "ten_map": "N.Truong Tung Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13185,
        "ten_map": "N.Thoi Diem Tran Luu",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13201,
        "ten_map": "Lo Ren Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13202,
        "ten_map": "Lo Ren Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13203,
        "ten_map": "Lo Ren Tran Luu",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13221,
        "ten_map": "Hieu Thuoc Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13222,
        "ten_map": "Tiem Thuoc Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13223,
        "ten_map": "Hieu Thuoc Uyen Thanh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13224,
        "ten_map": "Hieu Thuoc Pham Nam",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13241,
        "ten_map": "Nha Tro Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13241,
        "ten_map": "Nha Tro Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13241,
        "ten_map": "Nha Tro Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13241,
        "ten_map": "Nha Tro Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13241,
        "ten_map": "Nha Tro Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13241,
        "ten_map": "Nha Tro Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13241,
        "ten_map": "Nha Tro Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13241,
        "ten_map": "Nha Tro Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13242,
        "ten_map": "Nha Tro Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13242,
        "ten_map": "Nha Tro Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13242,
        "ten_map": "Nha Tro Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13242,
        "ten_map": "Nha Tro Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13242,
        "ten_map": "Nha Tro Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13242,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13243,
        "ten_map": "Nha Tro Uyen Thanh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13244,
        "ten_map": "Nha Tro Tran Luu",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13245,
        "ten_map": "Nha Tro Pham Nam",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13246,
        "ten_map": "Nha Tro Vinh Xuyen",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13261,
        "ten_map": "Tien Trang Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13262,
        "ten_map": "Tien Trang Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13263,
        "ten_map": "Tien Trang Tran Luu",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13281,
        "ten_map": "Quan Tra Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13282,
        "ten_map": "Quan Tra Vinh Xuyen",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13283,
        "ten_map": "Tiem Tra Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13284,
        "ten_map": "Tiem Tra Tran Luu",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13301,
        "ten_map": "Quan Phu Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13302,
        "ten_map": "Hoang Cung Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13303,
        "ten_map": "Quan Phu Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13304,
        "ten_map": "Quan Phu Tran Luu",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13305,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13306,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13307,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13308,
        "ten_map": "Hoang cung Hua Xuong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13401,
        "ten_map": "Ham Cuc Quan",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13402,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13403,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13404,
        "ten_map": "Dai Do Kinh Dao",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13405,
        "ten_map": "Ham Cuc Quan",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13406,
        "ten_map": "Thong Do Ham Cuc 1",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13407,
        "ten_map": "Thong Do Ham Cuc 2",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13411,
        "ten_map": "Vo Quan",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13412,
        "ten_map": "Thong Do Vo Quan 1",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13413,
        "ten_map": "Vo Quan",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13414,
        "ten_map": "Thong Do Vo Quan 2",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13421,
        "ten_map": "Pham Thuy Quan",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13422,
        "ten_map": "Thong Do Pham Thuy Quan 1",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13423,
        "ten_map": "Pham Thuy Quan Dinh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13424,
        "ten_map": "Q.D Tao Hong Pham Thuy",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13425,
        "ten_map": "Quan Dinh Pham Thuy",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13426,
        "ten_map": "Q.D Tu Hoang Pham Thuy",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13427,
        "ten_map": "Pham Thuy Quan",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13428,
        "ten_map": "Thong Do Pham Thuy Quan 2",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13431,
        "ten_map": "Ho Lao Quan",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13432,
        "ten_map": "Thong Do Ho Lao Quan 1",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13433,
        "ten_map": "Dai Do Lac Thuy",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13434,
        "ten_map": "Cau Lac Thuy",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13435,
        "ten_map": "Hoang Linh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13436,
        "ten_map": "Rung Ho Lao",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13437,
        "ten_map": "Thong Do Ho Lao Quan 2",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13438,
        "ten_map": "Thong Do Ho Lao Quan 3",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13441,
        "ten_map": "Cung Do Trai Mon",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13442,
        "ten_map": "Cung Do Dai Trai",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13443,
        "ten_map": "Cung Do Quan Dinh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13444,
        "ten_map": "Q.Dinh Luu-Quan-Truong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13445,
        "ten_map": "Q.Dinh Ton Sach",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13446,
        "ten_map": "Q.Dinh Tao Thao",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13447,
        "ten_map": "Dai Dinh Vien Thieu",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13448,
        "ten_map": "Q.Dinh Kieu Mao",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13449,
        "ten_map": "Q.Dinh Luu Dai",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13451,
        "ten_map": "Chan Nui Ngoa Nguu",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13452,
        "ten_map": "Duong HamDo Ngoa Nguu",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13453,
        "ten_map": "Eo Nui Ngoa Nguu",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13454,
        "ten_map": "Son Trai Ngoa Nguu",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13455,
        "ten_map": "Q.Dinh Chau Sang Ngoa Nguu",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13456,
        "ten_map": "Q.Dinh Trieu Van Ngoa Nguu",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13457,
        "ten_map": "Q.D Truong Manh Thanh Ngoa Nguu",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13461,
        "ten_map": "Nui Hoang Nguyen",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13462,
        "ten_map": "Cau Treo Hoan Nguyen",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13463,
        "ten_map": "Dinh Nui Hoang Nguyen",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13464,
        "ten_map": "Hem Nui Hoan Nguyen",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13465,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13471,
        "ten_map": "Cung Do Tao Dinh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13472,
        "ten_map": "O Trao Luong Dinh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13473,
        "ten_map": "Cung Do Nguyen Trai",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13474,
        "ten_map": "Dai Dinh Tao Thao",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13475,
        "ten_map": "Quan Dinh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13476,
        "ten_map": "Dinh Binh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13477,
        "ten_map": "Dinh Binh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13478,
        "ten_map": "Quan Dinh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13479,
        "ten_map": "Dinh Binh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13480,
        "ten_map": "Dinh Binh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13481,
        "ten_map": "Quan Dinh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13482,
        "ten_map": "Dinh Nguyen Trieu",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13483,
        "ten_map": "Chien Truong Cung Do 1",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13484,
        "ten_map": "Chien Truong Cung Do 2",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13491,
        "ten_map": "Ben Manh Tan",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13501,
        "ten_map": "Son Dong Hua Xuong 1",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13502,
        "ten_map": "Son Dong Hua Xuong 2",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13503,
        "ten_map": "Son Dong Hua Xuong 3",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13504,
        "ten_map": "Son Dong Hua Xuong 4",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13505,
        "ten_map": "Son Dong Hua Xuong 5",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13506,
        "ten_map": "Son Dong Hua Xuong 6",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13507,
        "ten_map": "Son Dong Hua Xuong 7",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13511,
        "ten_map": "Dong Thien Doc 1",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13512,
        "ten_map": "Dong Thien Doc 2",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13513,
        "ten_map": "Dong Thien Doc 3",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13514,
        "ten_map": "Dong Thien Doc 4",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13515,
        "ten_map": "Dong Thien Doc 5",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13516,
        "ten_map": "Dong Thien Doc 6",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13517,
        "ten_map": "Dong Thien Doc 7",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13518,
        "ten_map": "Dong Thien Doc 8",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13519,
        "ten_map": "Dong Thien Doc 9",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13520,
        "ten_map": "Dong Thien Doc 10",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13521,
        "ten_map": "Dong Thien Doc 11",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13522,
        "ten_map": "Dong Thien Doc 12",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13523,
        "ten_map": "Dong Thien Doc 13",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13524,
        "ten_map": "Dong Thien Doc 14",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13525,
        "ten_map": "Dong Thien Doc 15",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13526,
        "ten_map": "Dong Thien Doc 16",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13527,
        "ten_map": "Dong Thien Doc 17",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13528,
        "ten_map": "Dong Thien Doc 18",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13541,
        "ten_map": "Mo Khoang Pham Nam 1",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13542,
        "ten_map": "Mo Khoang Pham Nam 2",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13543,
        "ten_map": "Mo Khoang Pham Nam 3",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13544,
        "ten_map": "Mo Khoang Pham Nam 4",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13545,
        "ten_map": "Mo Khoang Pham Nam 5",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13546,
        "ten_map": "Mo Khoang Pham Nam 6",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13547,
        "ten_map": "Mo Khoang Pham Nam 7",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13548,
        "ten_map": "Mo Khoang Pham Nam 8",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13549,
        "ten_map": "Mo Khoang Pham Nam 9",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13550,
        "ten_map": "Mo Khoang Pham Nam 10",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13551,
        "ten_map": "Mo Khoang Pham Nam 11",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13552,
        "ten_map": "Mo Khoang Pham Nam 12",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13553,
        "ten_map": "Mo Khoang Pham Nam 13",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13554,
        "ten_map": "Mo Khoang Pham Nam 14",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13561,
        "ten_map": "Hoang Linh Nguyet Dong 1",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13562,
        "ten_map": "Hoang Linh Nguyet Dong 2",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13563,
        "ten_map": "Hoang Linh Nguyet Dong 3",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13564,
        "ten_map": "Hoang Linh Nguyet Dong 4",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13565,
        "ten_map": "Hoang Linh Nguyet Dong 5",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13566,
        "ten_map": "Hoang Linh Nguyet Dong 6",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13571,
        "ten_map": "Son Dong Ho Lao 1",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13572,
        "ten_map": "Son Dong Ho Lao 2",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13573,
        "ten_map": "Son Dong Ho Lao 3",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13574,
        "ten_map": "Son Dong Ho Lao 4",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13575,
        "ten_map": "Son Dong Ho Lao 5",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13576,
        "ten_map": "Son Dong Ho Lao 6",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13577,
        "ten_map": "Son Dong Ho Lao 7",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13578,
        "ten_map": "Son Dong Ho Lao 8",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13579,
        "ten_map": "Son Dong Ho Lao 9",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13580,
        "ten_map": "Son Dong Ho Lao 10",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13581,
        "ten_map": "Son Dong Ho Lao 11",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13582,
        "ten_map": "Son Dong Ho Lao 12",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13583,
        "ten_map": "Son Dong Ho Lao 13",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13584,
        "ten_map": "Son Dong Ho Lao 14",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13585,
        "ten_map": "Son Dong Ho Lao 15",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13586,
        "ten_map": "Son Dong Ho Lao 16",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13587,
        "ten_map": "Son Dong Ho Lao 17",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13588,
        "ten_map": "Son Dong Ho Lao 18",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13801,
        "ten_map": "Rung Vinh Duong 1",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13802,
        "ten_map": "Rung Vinh Duong 2",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13803,
        "ten_map": "Trai Ton Kien Vinh Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13804,
        "ten_map": "Rung Vinh Duong 3",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13806,
        "ten_map": "Dong Thien Doc",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13811,
        "ten_map": "Rung Tran Luu 1",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13812,
        "ten_map": "Rung Tran Luu 2",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13821,
        "ten_map": "Rung Pham Nam 1",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13822,
        "ten_map": "Rung Pham Nam 2",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13831,
        "ten_map": "Rung Truc Duong 1",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13832,
        "ten_map": "Rung Truc Duong 2",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13841,
        "ten_map": "Rung Nhuong Son 1",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13842,
        "ten_map": "Nguyet Dong 1",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13843,
        "ten_map": "Nguyet Dong 2",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13844,
        "ten_map": "Rung Nhuong Son 2",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13851,
        "ten_map": "Rung Vinh Xuyen 1",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13852,
        "ten_map": "Rung Vinh Xuyen 2",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13853,
        "ten_map": "Man Phong Dinh Trai",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13854,
        "ten_map": "Rung Truc Vinh Xuyen",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13855,
        "ten_map": "Nha Tranh Vinh Xuyen",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13856,
        "ten_map": "Nha Thi Ma Chinh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13857,
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13861,
        "ten_map": "Rung Vu am",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13871,
        "ten_map": "Rung Duong Thanh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13872,
        "ten_map": "Q.Dinh Duong Thanh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13873,
        "ten_map": "Rung Duong Thanh",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13881,
        "ten_map": "Rung Ham Cuc",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13882,
        "ten_map": "Dinh Trieu Dung Ham Cuc",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13883,
        "ten_map": "Dinh Tu Hoang Ham Cuc",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13884,
        "ten_map": "Rung Ham Cuc",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13885,
        "ten_map": "Rung Kinh Trieu 1",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13886,
        "ten_map": "Rung Kinh Trieu 1",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13891,
        "ten_map": "Rung Va Quan 1",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13892,
        "ten_map": "Rung Va Quan 2",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13893,
        "ten_map": "Rung Va Quan 3",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 13990,
        "ten_map": "Dau Dai Lac Duong",
        "thanh": "Việt Châu"
    },
    {
        "idmap": 14000,
        "ten_map": "Quan Trung",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14001,
        "ten_map": "Cua Thanh Truong An",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14002,
        "ten_map": "Dai Do Truong An",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14003,
        "ten_map": "Duong Pho Truong An",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14011,
        "ten_map": "Hiep Vu Tuy Lac",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14101,
        "ten_map": "N.Dieu Thuyen Truong An",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14102,
        "ten_map": "Pho Ho",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14103,
        "ten_map": "N.Ha Hau Uyen Truong An",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14131,
        "ten_map": "N.Doc Cu Lao Bac Truong An",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14132,
        "ten_map": "N.Trieu Nga Cao Dong",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14171,
        "ten_map": "N.Ly Mong Truong An",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14172,
        "ten_map": "N.Luu Lu Cao Dong",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14173,
        "ten_map": "N.Vuong Doan Cao Dong",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14174,
        "ten_map": "N.Bao Thanh Cao Dong",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14201,
        "ten_map": "Lo Ren Truong An",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14221,
        "ten_map": "Hieu Thuoc Truong An",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14241,
        "ten_map": "Nha Tro Truong An",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14261,
        "ten_map": "Tien Trang Truong An",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14281,
        "ten_map": "Quan Tra Truong An",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14301,
        "ten_map": "N.Han Tuc Truong An",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14401,
        "ten_map": "Dong Quan 1",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14402,
        "ten_map": "Thong Do Dong Quan 1",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14403,
        "ten_map": "Dong Quan 2",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14404,
        "ten_map": "Thong Do Dong Quan 2",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14411,
        "ten_map": "Tan Quan 1",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14412,
        "ten_map": "Thong Do Tan Quan 1",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14413,
        "ten_map": "Tan Quan 2",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14414,
        "ten_map": "Thong Do Tan Quan 2",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14421,
        "ten_map": "Eo Nui Chung Nam 1",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14422,
        "ten_map": "Eo Nui Chung Nam 2",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14423,
        "ten_map": "Eo Nui Chung Nam 3",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14424,
        "ten_map": "Eo Nui Chung Nam 4",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14425,
        "ten_map": "Nha Tranh Chung Nam",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14431,
        "ten_map": "Tan Cung Hoang Ha",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14441,
        "ten_map": "Ben Bo Phan",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14501,
        "ten_map": "S.Dong Thanh Bac 1",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14502,
        "ten_map": "S.Dong Thanh Bac 2",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14503,
        "ten_map": "S.Dong Thanh Bac 3",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14504,
        "ten_map": "S.Dong Thanh Bac 4",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14505,
        "ten_map": "S.Dong Thanh Bac 5",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14506,
        "ten_map": "S.Dong Thanh Bac 6",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14507,
        "ten_map": "S.Dong Thanh Bac 7",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14508,
        "ten_map": "S.Dong Thanh Bac 8",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14509,
        "ten_map": "S.Dong Thanh Bac 9",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14510,
        "ten_map": "S.Dong Thanh Bac 10",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14511,
        "ten_map": "S.Dong Thanh Bac 11",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14521,
        "ten_map": "S.Dong Thanh Bac 12",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14522,
        "ten_map": "S.Dong Thanh Bac 13",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14523,
        "ten_map": "S.Dong Thanh Bac 14",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14524,
        "ten_map": "S.Dong Thanh Bac 15",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14525,
        "ten_map": "S.Dong Thanh Bac 16",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14526,
        "ten_map": "S.Dong Thanh Bac 17",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14527,
        "ten_map": "S.Dong Thanh Bac 18",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14528,
        "ten_map": "S.Dong Thanh Bac 19",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14529,
        "ten_map": "S.Dong Thanh Bac 20",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14530,
        "ten_map": "S.Dong Thanh Bac 21",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14531,
        "ten_map": "S.Dong Thanh Bac 22",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14532,
        "ten_map": "S.Dong Thanh Bac 23",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14533,
        "ten_map": "S.Dong Thanh Bac 24",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14534,
        "ten_map": "S.Dong Thanh Bac 25",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14535,
        "ten_map": "S.Dong Thanh Bac 26",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14536,
        "ten_map": "S.Dong Thanh Bac 27",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14537,
        "ten_map": "S.Dong Thanh Bac 28",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14538,
        "ten_map": "S.Dong Thanh Bac 29",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14541,
        "ten_map": "Mo Khoang Lam Dien 1",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14542,
        "ten_map": "Mo Khoang Lam Dien 2",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14543,
        "ten_map": "Mo Khoang Lam Dien 3",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14544,
        "ten_map": "Mo Khoang Lam Dien 4",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14545,
        "ten_map": "Mo Khoang Lam Dien 5",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14551,
        "ten_map": "Son Dong Cao Dong 1",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14552,
        "ten_map": "Son Dong Cao Dong 2",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14553,
        "ten_map": "Son Dong Cao Dong 3",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14554,
        "ten_map": "Son Dong Cao Dong 4",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14555,
        "ten_map": "Son Dong Cao Dong 5",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14556,
        "ten_map": "Son Dong Cao Dong 6",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14557,
        "ten_map": "Son Dong Cao Dong 7",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14558,
        "ten_map": "Son Dong Cao Dong 8",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14559,
        "ten_map": "Son Dong Cao Dong 9",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14560,
        "ten_map": "Son Dong Cao Dong 10",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14561,
        "ten_map": "Son Dong Cao Dong 11",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14562,
        "ten_map": "Son Dong Cao Dong 12",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14563,
        "ten_map": "Son Dong Cao Dong 13",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14564,
        "ten_map": "Son Dong Cao Dong 14",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14565,
        "ten_map": "Son Dong Cao Dong 15",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14566,
        "ten_map": "Son Dong Cao Dong 16",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14571,
        "ten_map": "Tan Lang 1",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14572,
        "ten_map": "Tan Lang 2",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14573,
        "ten_map": "Tan Lang 3",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14574,
        "ten_map": "Tan Lang 4",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14575,
        "ten_map": "Tan Lang 5",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14576,
        "ten_map": "Tan Lang 6",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14577,
        "ten_map": "Tan Lang 7",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14578,
        "ten_map": "Tan Lang 8",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14579,
        "ten_map": "Tan Lang 9",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14580,
        "ten_map": "Tan Lang 10",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14581,
        "ten_map": "Tan Lang 11",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14582,
        "ten_map": "Tan Lang 12",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14583,
        "ten_map": "Tan Lang 13",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14584,
        "ten_map": "Tan Lang 14",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14585,
        "ten_map": "Tan Lang 15",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14586,
        "ten_map": "Tan Lang 16",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14587,
        "ten_map": "Tan Lang 17",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14588,
        "ten_map": "Tan Lang 18",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14589,
        "ten_map": "Tan Lang 19",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14590,
        "ten_map": "Tan Lang 20",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14591,
        "ten_map": "Tan Lang 21",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14592,
        "ten_map": "Tan Lang 22",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14593,
        "ten_map": "Tan Lang 23",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14594,
        "ten_map": "Tan Lang 24",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14595,
        "ten_map": "Tan Lang 25",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14596,
        "ten_map": "Tan Lang 26",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14597,
        "ten_map": "Tan Lang 27",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14598,
        "ten_map": "Tan Lang 28",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14599,
        "ten_map": "Tan Lang 29",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14600,
        "ten_map": "Tan Lang 30",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14601,
        "ten_map": "Tan Lang 31",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14602,
        "ten_map": "Tan Lang 32",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14603,
        "ten_map": "Tan Lang 33",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14604,
        "ten_map": "Tan Lang 34",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14605,
        "ten_map": "Tan Lang 35",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14606,
        "ten_map": "Tan Lang 36",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14607,
        "ten_map": "Tan Lang 37",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14608,
        "ten_map": "Tan Lang 38",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14609,
        "ten_map": "Tan Lang 39",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14610,
        "ten_map": "Tan Lang 40",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14611,
        "ten_map": "Tan Lang 41",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14612,
        "ten_map": "Tan Lang 42",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14613,
        "ten_map": "Tan Lang 43",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14614,
        "ten_map": "Tan Lang 44",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14615,
        "ten_map": "Mat dao Hoang Lang 1",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14616,
        "ten_map": "Mat dao Hoang Lang 2",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14617,
        "ten_map": "Mat dao Hoang Lang 3",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14618,
        "ten_map": "Mat dao Hoang Lang 4",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14619,
        "ten_map": "Duong ham lang Tan 1",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14620,
        "ten_map": "Duong ham lang Tan 2",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14621,
        "ten_map": "Duong ham lang Tan 3",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14622,
        "ten_map": "Duong ham lang Tan 4",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14623,
        "ten_map": "Duong ham lang Tan 5",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14624,
        "ten_map": "Duong ham lang Tan 6",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14625,
        "ten_map": "Duong ham lang Tan 7",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14626,
        "ten_map": "Duong ham lang Tan 8",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14627,
        "ten_map": "Duong ham lang Tan 9",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14628,
        "ten_map": "Duong ham lang Tan 10",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14629,
        "ten_map": "Duong ham lang Tan 11",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14630,
        "ten_map": "Duong ham lang Tan 12",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14631,
        "ten_map": "H.Mong Diem Duong ham lang Tan 13",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14632,
        "ten_map": "Duong ham lang Tan 14",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14633,
        "ten_map": "Duong ham lang Tan 15",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14634,
        "ten_map": "Duong ham lang Tan 16",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14635,
        "ten_map": "Duong ham lang Tan 17",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14636,
        "ten_map": "Duong ham lang Tan 18",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14637,
        "ten_map": "Duong ham lang Tan 19",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14638,
        "ten_map": "Duong ham lang Tan 20",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14638,
        "ten_map": "Duong ham lang Tan 21",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14640,
        "ten_map": "Duong ham lang Tan 22",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14641,
        "ten_map": "Duong ham lang Tan 23",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14642,
        "ten_map": "Duong ham lang Tan 24",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14643,
        "ten_map": "Duong ham lang Tan 25",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14644,
        "ten_map": "Duong ham lang Tan 26",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14645,
        "ten_map": "Duong ham lang Tan 27",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14646,
        "ten_map": "Duong ham lang Tan 28",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14647,
        "ten_map": "Duong ham lang Tan 29",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14648,
        "ten_map": "Duong ham lang Tan 30",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14649,
        "ten_map": "Duong ham lang Tan 31",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14650,
        "ten_map": "Duong ham lang Tan 32",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14651,
        "ten_map": "Duong ham lang Tan 33",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14652,
        "ten_map": "Duong ham lang Tan 34",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14653,
        "ten_map": "Duong ham lang Tan 35",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14654,
        "ten_map": "Duong ham lang Tan 36",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14655,
        "ten_map": "Duong ham lang Tan 37",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14656,
        "ten_map": "Duong ham lang Tan 38",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14657,
        "ten_map": "Duong ham lang Tan 39",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14658,
        "ten_map": "Duong ham lang Tan 40",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14659,
        "ten_map": "Duong ham lang Tan 41",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14660,
        "ten_map": "Duong ham lang Tan 42",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14661,
        "ten_map": "Duong ham lang Tan 43",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14662,
        "ten_map": "Duong ham lang Tan 44",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14663,
        "ten_map": "Duong ham lang Tan 45",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14664,
        "ten_map": "Duong ham lang Tan 46",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14665,
        "ten_map": "Duong ham lang Tan 47",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14666,
        "ten_map": "Duong ham lang Tan 48",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14667,
        "ten_map": "Duong ham lang Tan 49",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14668,
        "ten_map": "Duong ham lang Tan 50",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14669,
        "ten_map": "Duong ham lang Tan 51",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14670,
        "ten_map": "Duong ham lang Tan 52",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14671,
        "ten_map": "Duong ham lang Tan 53",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14672,
        "ten_map": "Duong ham lang Tan 54",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14673,
        "ten_map": "Duong ham lang Tan 55",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14674,
        "ten_map": "Duong ham lang Tan 56",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14675,
        "ten_map": "Duong ham lang Tan 57",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14676,
        "ten_map": "Duong ham lang Tan 58",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14677,
        "ten_map": "Duong ham lang Tan 59",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14678,
        "ten_map": "Duong ham lang Tan 60",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14679,
        "ten_map": "Duong ham lang Tan 61",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14680,
        "ten_map": "Duong ham lang Tan 62",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14801,
        "ten_map": "Rung Bac Dia",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14802,
        "ten_map": "Tho Thanh Bac Dia",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14803,
        "ten_map": "B.T TaoThao Ba cDia",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14804,
        "ten_map": "B.Truong Bac Dia",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14805,
        "ten_map": "Rung Tan Phong",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14811,
        "ten_map": "Hoa Son",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14812,
        "ten_map": "Rung Hoa Son 1",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14813,
        "ten_map": "Rung Hoa Son 2",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14814,
        "ten_map": "Rung Hoa Son 3",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14815,
        "ten_map": "Nha Tranh Hoa Son",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14816,
        "ten_map": "Son Dinh Hoa Son",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14821,
        "ten_map": "Hem Nui Tu Ngu 1",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14822,
        "ten_map": "Hem Nui Tu Ngu 2",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14823,
        "ten_map": "Hem Nui Tu Ngu 3",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14824,
        "ten_map": "Hem Nui Tu Ngu 4",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14825,
        "ten_map": "Hem Nui Tu Ngu 5",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14831,
        "ten_map": "Hem Nui Ta Cuc 1",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14832,
        "ten_map": "Hem Nui Ta Cuc 2",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14833,
        "ten_map": "Hem Nui Ta Cuc 3",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14834,
        "ten_map": "Q.Dinh Ta Cuc",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14835,
        "ten_map": "Hem Nui Ta Cuc 4",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14836,
        "ten_map": "Hem Nui Ta Cuc 5",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14837,
        "ten_map": "Hem Nui Ta Cuc 6",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14838,
        "ten_map": "Hem Nui Ta Cuc 7",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14841,
        "ten_map": "Tran Thuong Trai Mon",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14842,
        "ten_map": "Rung Tran Thuong 1",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14843,
        "ten_map": "Rung Tran Thuong 2",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14844,
        "ten_map": "Dinh Dong Trac Tran Thuong",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14845,
        "ten_map": "T.Ta Linh Tran Thuong",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14846,
        "ten_map": "Rung Tran Thuong 3",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14847,
        "ten_map": "Trai Binh Tran Thuong",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14851,
        "ten_map": "Rung Tan Linh 1",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14852,
        "ten_map": "Rung Tan Linh 2",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14861,
        "ten_map": "Rung Tan Quan 1",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14862,
        "ten_map": "Rung Tan Quan 2",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14871,
        "ten_map": "Rung Tan Phong 1",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14872,
        "ten_map": "Rung Tan Phong 2",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14873,
        "ten_map": "Q.Truong Ma Sieu Tan Phong",
        "thanh": "Quan Trung"
    },
    {
        "idmap": 15000,
        "ten_map": "Tu Chau",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15001,
        "ten_map": "Thanh Mon Tu Chau",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15002,
        "ten_map": "Dai Do Tu Chau 1",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15003,
        "ten_map": "Dai Do Tu Chau 2",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15011,
        "ten_map": "Ha Phi Thanh Mon",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15012,
        "ten_map": "Dai Do Ha Phi 1",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15013,
        "ten_map": "Ha Phi Bac Mon",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15014,
        "ten_map": "Dai Do Ha Phi 2",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15021,
        "ten_map": "Tho Xuan Thanh Mon",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15022,
        "ten_map": "Dai Do Tho Xuan",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15023,
        "ten_map": "Rung Tho Xuan",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15024,
        "ten_map": "Dai Do Tho Xuan",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15031,
        "ten_map": "Tieu Phi Thon",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15101,
        "ten_map": "N.Tran Giai Ha Phi",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15102,
        "ten_map": "N.To Thuong Tieu Phi Thon",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15103,
        "ten_map": "N.Luu Bi Tu Chau",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15104,
        "ten_map": "N.Cam-My Phu Nhan Ha Phi",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15131,
        "ten_map": "N.Ton Cang Tu Chau",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15132,
        "ten_map": "N.Dien An Nghi Ha Phi",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15133,
        "ten_map": "N.Ba Lao Van Tho Xuan",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15134,
        "ten_map": "N.A Long Tieu Phi Thon",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15135,
        "ten_map": "N.Tong The Lam Tu Chau",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15136,
        "ten_map": "N.Tuan Uc Tho Xuan",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15171,
        "ten_map": "N.Do Binh Tu Chau",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15172,
        "ten_map": "N.Lac Tien Ha Phi",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15173,
        "ten_map": "N.Tieu Do Tho Xuan",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15174,
        "ten_map": "N.Ma Xieu Tieu Phi Thon",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15201,
        "ten_map": "Lo Ren Ha Phi",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15202,
        "ten_map": "Lo Ren Tho Xuan",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15221,
        "ten_map": "Tiem Thuoc Tu Chau",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15241,
        "ten_map": "Nha Tro Tu Chau",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15242,
        "ten_map": "Nha Tro Ha Phi",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15243,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15261,
        "ten_map": "Tien Trang Tu Chau",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15262,
        "ten_map": "Tien Trang Tho Xuan",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15301,
        "ten_map": "Quan Phu Tu Chau",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15302,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15303,
        "ten_map": "Phu Khuyet Tuyen Tieu Phi",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15304,
        "ten_map": "Quan De Lu Bo",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15305,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15306,
        "ten_map": "Phu Luu Bi Tieu Bai",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15307,
        "ten_map": "Ha Phi Quan Phu",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15401,
        "ten_map": "Tu Quan 1",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15402,
        "ten_map": "Tu Quan Thong Do 1",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15403,
        "ten_map": "Tu Quan 2",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15404,
        "ten_map": "Tu Quan Thong Do 2",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15411,
        "ten_map": "Mang Thanh Son 1",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15412,
        "ten_map": "Mang Thanh Son 2",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15413,
        "ten_map": "Mang Thanh Son 3",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15414,
        "ten_map": "D.Trai Dai Hong",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15415,
        "ten_map": "Mang Thanh Son 4",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15421,
        "ten_map": "Thien Tru Son 1",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15422,
        "ten_map": "Thien Tru Son 2",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15423,
        "ten_map": "Thien Tru Son 3",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15424,
        "ten_map": "Thien Tru Son 4",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15431,
        "ten_map": "Hiep Thach Son 1",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15432,
        "ten_map": "Hiep Thach Son 2",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15433,
        "ten_map": "Dinh Nui Hiep Thach Son 1",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15434,
        "ten_map": "N.Tien Nhan Hiep ThacSon",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15435,
        "ten_map": "Dinh Nui Hiep Thach Son 2",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15436,
        "ten_map": "N.Tuong Cang Hiep Thach Son",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15441,
        "ten_map": "Y Nhi Thuy Trai 1",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15442,
        "ten_map": "Y Nhi Thuy Trai 2",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15443,
        "ten_map": "T.Vien Thuat y Nhi",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15444,
        "ten_map": "T.Truong Lieu Y Nhi",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15445,
        "ten_map": "Y Nhi Thuy Trai 3",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15451,
        "ten_map": "Nai Thuan O 1",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15452,
        "ten_map": "Trai Hop Phi TQ 1",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15453,
        "ten_map": "Trai Hop Phi TQ 2",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15454,
        "ten_map": "Trai Hop Phi TQ 3",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15455,
        "ten_map": "Rung Nai Thuan phai",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15456,
        "ten_map": "Rung Nai Thuan trai",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15457,
        "ten_map": "Trai Hop Phi TQ 4",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15458,
        "ten_map": "Trai Lang Thong Ha Phi",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15459,
        "ten_map": "Trai Cam Ninh Ha Phi",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15460,
        "ten_map": "Trai Ton Quyen Ha Phi",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15462,
        "ten_map": "Tao Doanh Nhu Tu",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15463,
        "ten_map": "Dam Nhu Tu",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15471,
        "ten_map": "Rung Thach Dinh",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15472,
        "ten_map": "Nha Nho Dong Hai",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15481,
        "ten_map": "Phe Thuyen Thai Ho",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15491,
        "ten_map": "Nguyen Khau Ben Lo",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15492,
        "ten_map": "Trai Hoan Thanh 1",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15493,
        "ten_map": "Trai Hoan Thanh 2",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15494,
        "ten_map": "Trai linh Hoan Thanh",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15501,
        "ten_map": "S.Dong Khoa Chau 1",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15502,
        "ten_map": "S.Dong Khoa Chau 2",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15503,
        "ten_map": "S.Dong Khoa Chau 3",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15504,
        "ten_map": "S.Dong Khoa Chau 4",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15505,
        "ten_map": "S.Dong Khoa Chau 5",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15506,
        "ten_map": "S.Dong Khoa Chau 6",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15507,
        "ten_map": "S.Dong Khoa Chau 7",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15508,
        "ten_map": "S.Dong Khoa Chau 8",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15509,
        "ten_map": "S.Dong Khoa Chau 9",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15510,
        "ten_map": "S.Dong Khoa Chau 10",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15511,
        "ten_map": "S.Dong Khoa Chau 11",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15512,
        "ten_map": "S.Dong Khoa Chau 12",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15513,
        "ten_map": "S.Dong Khoa Chau 13",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15514,
        "ten_map": "S.Dong Khoa Chau 14",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15515,
        "ten_map": "S.Dong Khoa Chau 15",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15516,
        "ten_map": "S.Dong Khoa Chau 16",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15517,
        "ten_map": "S.Dong Khoa Chau 17",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15518,
        "ten_map": "S.Dong Khoa Chau 18",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15521,
        "ten_map": "Ha Phi Tho Son Me Cung 1",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15522,
        "ten_map": "Ha Phi Tho Son Me Cung 2",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15523,
        "ten_map": "Ha Phi Tho Son Me Cung 3",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15524,
        "ten_map": "Ha Phi Tho Son Me Cung 4",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15525,
        "ten_map": "Ha Phi Tho Son Me Cung 5",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15526,
        "ten_map": "Ha Phi Tho Son Me Cung 6",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15527,
        "ten_map": "Ha Phi Tho Son Me Cung 7",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15528,
        "ten_map": "Ha Phi Tho Son Me Cung 8",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15529,
        "ten_map": "Ha Phi Tho Son Me Cung 9",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15541,
        "ten_map": "Y Nhi Thuy Dong 1",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15542,
        "ten_map": "Y Nhi Thuy Dong 2",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15543,
        "ten_map": "Y Nhi Thuy Dong 3",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15544,
        "ten_map": "Y Nhi Thuy Dong 4",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15545,
        "ten_map": "Y Nhi Thuy Dong 5",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15546,
        "ten_map": "Y Nhi Thuy Dong 6",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15547,
        "ten_map": "Y Nhi Thuy Dong 7",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15548,
        "ten_map": "Y Nhi Thuy Dong 8",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15549,
        "ten_map": "Y Nhi Thuy Dong 9",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15550,
        "ten_map": "Y Nhi Thuy Dong 10",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15551,
        "ten_map": "Y Nhi Thuy Dong 11",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15552,
        "ten_map": "Y Nhi Thuy Dong 12",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15553,
        "ten_map": "Y Nhi Thuy Dong 13",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15554,
        "ten_map": "Y Nhi Thuy Dong 14",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15555,
        "ten_map": "Y Nhi Thuy Dong 15",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15561,
        "ten_map": "Son Dong Tho Xuan 1",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15562,
        "ten_map": "Son Dong Tho Xuan 2",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15563,
        "ten_map": "Son Dong Tho Xuan 3",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15564,
        "ten_map": "Son Dong Tho Xuan 4",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15565,
        "ten_map": "Son Dong Tho Xuan 5",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15566,
        "ten_map": "Son Dong Tho Xuan 6",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15567,
        "ten_map": "Son Dong Tho Xuan 7",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15568,
        "ten_map": "Son Dong Tho Xuan 8",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15569,
        "ten_map": "Son Dong Tho Xuan 9",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15570,
        "ten_map": "Son Dong Tho Xuan 10",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15571,
        "ten_map": "Son Dong Tho Xuan 11",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15581,
        "ten_map": "D.Da Nai Thuan phai",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "ten_map": "D.Da Nai Thuan trai",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15591,
        "ten_map": "S.Dong Thanh Duc",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15701,
        "ten_map": "Trai Hop Phi TT 1",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15702,
        "ten_map": "Trai Hop Phi TT 2",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15703,
        "ten_map": "Binh Truong Hop Phi",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15704,
        "ten_map": "B.Truong Truong Lieu Hop Phi",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15705,
        "ten_map": "B.Truong Vo Chau Hop Phi",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15706,
        "ten_map": "B.Truong Tao Thao Hop Phi",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15711,
        "ten_map": "Sao Ho Tu Chau",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15801,
        "ten_map": "Quang Linh Trai Mon",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15802,
        "ten_map": "Rung Quang Linh 1",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15803,
        "ten_map": "Quang Linh Thuy Dong",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15804,
        "ten_map": "Trai Luu Bi Quang Linh",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15805,
        "ten_map": "Rung Quang Linh 2",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15811,
        "ten_map": "Rung Ha Phi 1",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15812,
        "ten_map": "Rung Ha Phi 2",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15821,
        "ten_map": "Rung Tuong Huyen 1",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15822,
        "ten_map": "Rung Tuong Huyen 2",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15823,
        "ten_map": "Rung Tuong Huyen 3",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15824,
        "ten_map": "Rung Tuong Huyen 4",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15831,
        "ten_map": "Rung Chuan Am 1",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15832,
        "ten_map": "Rung Chuan Am 2",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15841,
        "ten_map": "Thuy Trai Lo Giang 1",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15842,
        "ten_map": "Thuy Trai Lo Giang 2",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15843,
        "ten_map": "Rung Lo Giang",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15844,
        "ten_map": "Lo Giang Trai 1",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15845,
        "ten_map": "Trai Cam Ninh Lo Giang",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15846,
        "ten_map": "Trai Chu Du Lo Giang",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15847,
        "ten_map": "Trai Ton Sach Lo Giang",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15848,
        "ten_map": "Rung Lo Giang",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15851,
        "ten_map": "Rung Thach Dinh 1",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15852,
        "ten_map": "Rung Thach Dinh 2",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15853,
        "ten_map": "Rung Thach Dinh 3",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15861,
        "ten_map": "Rung Ha Thai 1",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15862,
        "ten_map": "Rung Ha Thai 2",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15863,
        "ten_map": "Quan Truong Lu Bo",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15871,
        "ten_map": "Thanh Duc Trieu Trac",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15881,
        "ten_map": "Rung Lam Hoai",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15891,
        "ten_map": "Trai Tao Phi",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15892,
        "ten_map": "Cau Cang Giang Bac 1",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15893,
        "ten_map": "Chien truong Truong Giang 1",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15894,
        "ten_map": "Trai Tu Thinh Giang Bac 1",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15895,
        "ten_map": "Truong Tao Phi",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15896,
        "ten_map": "Thuyen rong Tao Phi",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15901,
        "ten_map": "Tu luyen Guild",
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15902,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 16000,
        "ten_map": "Nhi Chau",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16001,
        "ten_map": "Thon Khai Dat Cach Lan",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16101,
        "ten_map": "N.Lo Cat Khai Dat Cach Lan",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16131,
        "ten_map": "N.Bac Ha Khai Dat Cach Lan",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16132,
        "ten_map": "Pho Ho Khai Dat Cach Lan",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16401,
        "ten_map": "Duong Nui Nhi Chau 1",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16402,
        "ten_map": "Duong Nui Nhi Chau 2",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16411,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16412,
        "ten_map": "Dinh Nui My Luan",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16501,
        "ten_map": "Nhi Chau Son Dong 1",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16502,
        "ten_map": "Nhi Chau Son Dong 2",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16503,
        "ten_map": "Nhi Chau Son Dong 3",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16504,
        "ten_map": "Nhi Chau Son Dong 4",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16505,
        "ten_map": "Nhi Chau Son Dong 5",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16506,
        "ten_map": "Nhi Chau Son Dong 6",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16507,
        "ten_map": "Nhi Chau Son Dong 7",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16508,
        "ten_map": "Nhi Chau Son Dong 8",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16509,
        "ten_map": "Nhi Chau Son Dong 9",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16510,
        "ten_map": "Nhi Chau Son Dong 10",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16511,
        "ten_map": "Nhi Chau Son Dong 11",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16512,
        "ten_map": "Nhi Chau Son Dong 12",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16521,
        "ten_map": "Dai Don Hoa Son Dong 1",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16522,
        "ten_map": "Dai Don Hoa Son Dong 2",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16523,
        "ten_map": "Dai Don Hoa Son Dong 3",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16524,
        "ten_map": "Dai Don Hoa Son Dong 4",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16525,
        "ten_map": "Dai Don Hoa Son Dong 5",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16526,
        "ten_map": "Dai Don Hoa Son Dong 6",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16527,
        "ten_map": "Dai Don Hoa Son Dong 7",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16531,
        "ten_map": "Mo Khoang Tay Lai Nha 1",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16532,
        "ten_map": "Mo Khoang Tay Lai Nha 2",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16533,
        "ten_map": "Mo Khoang Tay Lai Nha 3",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16534,
        "ten_map": "Mo Khoang Tay Lai Nha 4",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16535,
        "ten_map": "Mo Khoang Tay Lai Nha 5",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16536,
        "ten_map": "Mo Khoang Tay Lai Nha 6",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16537,
        "ten_map": "Mo Khoang Tay Lai Nha 7",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16538,
        "ten_map": "Mo Khoang Tay Lai Nha 8",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16539,
        "ten_map": "Mo Khoang Tay Lai Nha 9",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16801,
        "ten_map": "Rung Phi Nam 1",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16802,
        "ten_map": "Rung Phi Nam 2",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16811,
        "ten_map": "Rung Tay Lai Nha",
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 17000,
        "ten_map": "Dong Hai"
    },
    {
        "idmap": 18000,
        "ten_map": "Duong Chau",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "ten_map": "Cong Thanh Kien Nghiep",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18002,
        "ten_map": "Kien Nghiep Do 2",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18003,
        "ten_map": "Kien Nghiep Do 3",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18011,
        "ten_map": "Thanh Mon Ngo Quan",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18012,
        "ten_map": "Dai Do Ngo Quan 1",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18013,
        "ten_map": "Dai Do Ngo Quan 2",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18021,
        "ten_map": "Hoi Mao",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18022,
        "ten_map": "Dai Do Hoi Mao",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18031,
        "ten_map": "Thon Linh La",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18041,
        "ten_map": "Thon Dong Tri",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18051,
        "ten_map": "Thon Vinh Linh",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18061,
        "ten_map": "Bo Lac Kien An",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18071,
        "ten_map": "Bo Lac Thuong Hieu",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18081,
        "ten_map": "Bo Lac Kinh Huyen",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18101,
        "ten_map": "N.Ton.T.Huong Kien Nghiep",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18102,
        "ten_map": "N.Truong Chieu Kien Nghiep",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18103,
        "ten_map": "N.Du Phan Hoi Mao",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18104,
        "ten_map": "N.Dai Kieu Linh La",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18105,
        "ten_map": "N.KieuQuocLao Linh La",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18106,
        "ten_map": "N.Thuong Thang Dong Tri",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18107,
        "ten_map": "N.Cam Ninh Vinh Linh",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18108,
        "ten_map": "N.Hong Minh Kien An",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18109,
        "ten_map": "N.Thai Su Tu Kien An",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18110,
        "ten_map": "N.Chau Lam Thuogn Hieu",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18111,
        "ten_map": "N.Ton Quyen Thuong Hieu",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18112,
        "ten_map": "N.Ton Duc Kinh Huyen",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18113,
        "ten_map": "N.Chu Du Kien Nghiep",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18114,
        "ten_map": "N.Trieu Van Kien Nghiep",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18115,
        "ten_map": "N.Ngo QUoc Thai Kien Nghiep",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18116,
        "ten_map": "Dai Do Kien Nghiep",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18117,
        "ten_map": "N.Ho Tong Ngo Quan",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18118,
        "ten_map": "N.Bang Thong Ngo Quan",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18131,
        "ten_map": "N.Tu Thinh kien Nghiep",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18132,
        "ten_map": "N.Kieu Quoc Lao Kien Nghiep",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18133,
        "ten_map": "N.Han Dang Ngo Quan",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18134,
        "ten_map": "N.Ha Te Hoi Mao",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18135,
        "ten_map": "N.Dong Trac Linh La",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18136,
        "ten_map": "N.Co Dung Linh La",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18137,
        "ten_map": "N.Tran Doan Dong Tri",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18138,
        "ten_map": "N.Hai Dao Vuong Vinh Linh",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18139,
        "ten_map": "N.Hoa Dang Kien An",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18140,
        "ten_map": "N.Dong Phung Kien An",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18141,
        "ten_map": "N.Ngo Mien Thuong Hieu",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18142,
        "ten_map": "N.Nghe Nhi Thuong Hieu",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18143,
        "ten_map": "N.Lao Hoc Cuu Kinh Huyen",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18144,
        "ten_map": "N.Truong Thua Ngo Quan",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18171,
        "ten_map": "N.Tuong Kham Kien Nghiep",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18172,
        "ten_map": "N.Pham Tien Sinh Ngo Quan",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18173,
        "ten_map": "N.Ton Thinh Hoi Mao",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18174,
        "ten_map": "N.Quan Trach Linh La",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18175,
        "ten_map": "N.Thoi Tuyet Linh La",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18176,
        "ten_map": "N.Vuong Lang Dong Tri",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18177,
        "ten_map": "N.Nu Nhac Su Vinh Linh",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18178,
        "ten_map": "N.Tran Bai Vinh Linh",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18179,
        "ten_map": "N.Tu Lao Dau Kien An",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18180,
        "ten_map": "N.Uyen Du Kien An",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18181,
        "ten_map": "N.Tiet Chung Kien An",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18182,
        "ten_map": "N.Tran Boc Thuong Hieu",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18183,
        "ten_map": "N.Tho Moc Hua Thuong Hieu",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18184,
        "ten_map": "N.Trinh Binh Thuong Hieu",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18185,
        "ten_map": "N.Hoa Da Kinh Huyen",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18186,
        "ten_map": "N.Bo Ma Kinh Huyen",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18187,
        "ten_map": "N.Dang Dang Kien Nghiep",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18188,
        "ten_map": "N.Truong Nhung Ngo Quan",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18189,
        "ten_map": "N.Ton Kieu Ngo Quan",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18201,
        "ten_map": "Lo Ren Kien Nghiep",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18202,
        "ten_map": "Lo Ren Ngo Quan",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18203,
        "ten_map": "Lo Ren Linh La",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18221,
        "ten_map": "Tiem Thuoc Kien Nghiep",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18222,
        "ten_map": "Tiem Thuoc Hoi Mao",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18241,
        "ten_map": "Nha Tro Kien Nghiep",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18242,
        "ten_map": "Nha Tro Ngo Quan",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18243,
        "ten_map": "Nha Tro Hoi Mao",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18261,
        "ten_map": "Tien Trang Kien Nghiep",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18262,
        "ten_map": "Tien Trang Hoi Mao",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18301,
        "ten_map": "Quan Phu Kien Nghiep",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18302,
        "ten_map": "Quan Phu Ngo Quan",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18303,
        "ten_map": "Quan Phu Hoi Mao",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18304,
        "ten_map": "Le Duong Thanh Hon Kien Nghiep",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18305,
        "ten_map": "Anh Hung Quan",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18401,
        "ten_map": "Chan Nui Bac Cu",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18402,
        "ten_map": "Eo Nui Bac Cu",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18403,
        "ten_map": "Cam Lo Tu",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18404,
        "ten_map": "Cam Lo Tu noi",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18405,
        "ten_map": "Dinh Nui Bac Cu",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18411,
        "ten_map": "Dinh mat Tieu Duong",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18421,
        "ten_map": "Chan nui Tay Trac",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18422,
        "ten_map": "Eo Nui Tay Trac 1",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18423,
        "ten_map": "Eo Nui Tay Trac 2",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18424,
        "ten_map": "Eo Nui Tay Trac 3",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18432,
        "ten_map": "Eo Nui Kien An",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18433,
        "ten_map": "Dinh Nui Kien An",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18434,
        "ten_map": "Than Moc Son Viet",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18435,
        "ten_map": "Cau Treo Kien An",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18436,
        "ten_map": "Chan Nui Kien An",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18441,
        "ten_map": "Chan nui Thuong Hieu",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18442,
        "ten_map": "Rung Thuong Hieu",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18443,
        "ten_map": "ThacNuocThuongHieu",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18444,
        "ten_map": "Dinh Nui Thuong Hieu",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18451,
        "ten_map": "Chan Nui Kinh Huyen",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18452,
        "ten_map": "Eo Nui Kinh Huyen",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18453,
        "ten_map": "Dinh Nui Kinh Huyen",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18454,
        "ten_map": "Vuc Nui Kinh Huyen",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18455,
        "ten_map": "Rung Kinh Huyen",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18461,
        "ten_map": "Chan Nui Lam Lich",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18462,
        "ten_map": "Dinh Nui Lam Lich",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18501,
        "ten_map": "Thai Ho Thuy Dong 1",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18502,
        "ten_map": "Thai Ho Thuy Dong 2",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18503,
        "ten_map": "Thai Ho Thuy Dong 3",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18504,
        "ten_map": "Thai Ho Thuy Dong 4",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18505,
        "ten_map": "Thai Ho Thuy Dong 5",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18506,
        "ten_map": "Thai Ho Thuy Dong 6",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18511,
        "ten_map": "Du Khang Son Dong 1",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18512,
        "ten_map": "Du Khang Son Dong 2",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18513,
        "ten_map": "Du Khang Son Dong 3",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18514,
        "ten_map": "Du Khang Son Dong 4",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18515,
        "ten_map": "Du Khang Son Dong 5",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18516,
        "ten_map": "Du Khang Son Dong 6",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18517,
        "ten_map": "Du Khang Son Dong 7",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18518,
        "ten_map": "Du Khang Son Dong 8",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18519,
        "ten_map": "Du Khang Son Dong 9",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18521,
        "ten_map": "Son Dong An Lac 1",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18522,
        "ten_map": "Son Dong An Lac 2",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18523,
        "ten_map": "Son Dong An Lac 3",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18524,
        "ten_map": "Son Dong An Lac 4",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18525,
        "ten_map": "Son Dong An Lac 5",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18526,
        "ten_map": "Son Dong An Lac 6",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18527,
        "ten_map": "Son Dong An Lac 7",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18528,
        "ten_map": "Son Dong An Lac 8",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18529,
        "ten_map": "Son Dong An Lac 9",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18530,
        "ten_map": "Son Dong An Lac 10",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18531,
        "ten_map": "Son Dong An Lac 11",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18541,
        "ten_map": "Mo Khoang Hoi Mao 1",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18542,
        "ten_map": "Mo Khoang Hoi Mao 2",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18543,
        "ten_map": "Mo Khoang Hoi Mao 3",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18544,
        "ten_map": "Mo Khoang Hoi Mao 4",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18545,
        "ten_map": "Mo Khoang Hoi Mao 5",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18546,
        "ten_map": "Mo Khoang Hoi Mao 6",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18547,
        "ten_map": "Mo Khoang Hoi Mao 7",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18701,
        "ten_map": "Trai Tao Phi Giang Dong 2",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18702,
        "ten_map": "Cau cang Giang Bac 2",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18703,
        "ten_map": "Chien truong Truong Giang 2",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18704,
        "ten_map": "Trai Tu Thinh Giang Dong 2",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18704,
        "ten_map": "Thuyen rong Tao Phi 2",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18706,
        "ten_map": "Truong Tu Thinh",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18801,
        "ten_map": "Rung Nguu Chu",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18802,
        "ten_map": "T.Luu Dieu Nguu Chu",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18803,
        "ten_map": "Rung Nguu Chu",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18804,
        "ten_map": "Duong Chau",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18805,
        "ten_map": "Ben Cang Phan Duong",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18806,
        "ten_map": "Phan Duong Trai Mon",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18807,
        "ten_map": "Phan Duong Trai",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18808,
        "ten_map": "T.Lang Thong Phan Duong",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18809,
        "ten_map": "T.Ton Quyen Phan Duong",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18810,
        "ten_map": "T.Ton Sach Phan Duong",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18811,
        "ten_map": "R.Lam Tuyen 1",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18812,
        "ten_map": "R.Lam Tuyen 2",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18814,
        "ten_map": "Tu Song Trai 1",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18815,
        "ten_map": "Dinh Chu Du Tu Song",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18816,
        "ten_map": "Tu Song Trai 2",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18817,
        "ten_map": "Dinh Lo Tuc Tu Song",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18818,
        "ten_map": "Tu Song Trai 3",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18819,
        "ten_map": "Ben Do Sai Tang",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18820,
        "ten_map": "Tu Song Trai 5",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18821,
        "ten_map": "Bang Tich Trieu Trac 1",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18822,
        "ten_map": "Bang Tich Trieu Trac 2",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18823,
        "ten_map": "Bang Tich Trieu Trac 3",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18824,
        "ten_map": "Bang Tich Trieu Trac 4",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18826,
        "ten_map": "Du Chuong Trai 1",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18827,
        "ten_map": "T.Truong Nhung Du Chuong Trai",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18828,
        "ten_map": "Du Chuong Trai 2",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18831,
        "ten_map": "R.Dan Duong 1",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18832,
        "ten_map": "R.Dan Duong 2",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18836,
        "ten_map": "Rung An Lac",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18837,
        "ten_map": "Trai Ba Duong 1",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18838,
        "ten_map": "Trai Ba Duong 2",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18841,
        "ten_map": "O Trinh Trieu Trac 1",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18842,
        "ten_map": "O Trinh Trieu Trac 2",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18843,
        "ten_map": "O Trinh Trieu Trac 3",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18844,
        "ten_map": "O Trinh Trieu Trac 4",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18846,
        "ten_map": "Rung Vinh Khang",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18851,
        "ten_map": "Bai bien muoi man - Duong Chau",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18856,
        "ten_map": "Rung Quang Linh Duong Chau",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18861,
        "ten_map": "Du Thieu Trieu Trac 1",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18862,
        "ten_map": "Du Thieu Trieu Trac 2",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18863,
        "ten_map": "T.Toan Nhu Du Thieu",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18864,
        "ten_map": "Du Thieu Trieu Trac",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18871,
        "ten_map": "Rung Kien Nghiep",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18881,
        "ten_map": "Rung Dong Quan",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18891,
        "ten_map": "Rung Hoi Mao 1",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18892,
        "ten_map": "Rung Hoi Mao 2",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18990,
        "ten_map": "Loi Dai Kien Nghiep",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18991,
        "ten_map": "Rung Lu Son 1",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18992,
        "ten_map": "Rung Lu Son 2",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18993,
        "ten_map": "Nha Lu Son",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18994,
        "ten_map": "Hang Ho Lu Son",
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 19000,
        "ten_map": "Lieu Dong",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19001,
        "ten_map": "Thanh Mon Lan Binh",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19002,
        "ten_map": "Dai Lo Lan Binh",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19011,
        "ten_map": "Vong Binh Thon",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19021,
        "ten_map": "O Hanh Bo Lac",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19031,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19032,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19033,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19034,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19035,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19036,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19101,
        "ten_map": "N.C Ton Khang Lan Binh",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19131,
        "ten_map": "N.Vien Thuong Lan Binh",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19132,
        "ten_map": "N.Binh Nguyen Vong Binh",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19133,
        "ten_map": "N.Vien Thuong Lan Binh",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19171,
        "ten_map": "N.Chau Mau Lan Binh",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19172,
        "ten_map": "N.Quang Ninh Vong Binh",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19173,
        "ten_map": "N.Hoa Am Vong Binh",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19174,
        "ten_map": "N.Luong Mau Vong Binh",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19175,
        "ten_map": "N.Thac Tan O Hang",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19176,
        "ten_map": "N.ToBoc Dien O Hang",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19177,
        "ten_map": "N.Lau Ban O Hang",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19178,
        "ten_map": "N.Pho Phu Lu O Hang",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19179,
        "ten_map": "N.Truong Ma Ma O Hang",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19201,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19221,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19241,
        "ten_map": "Nha Tro Lan Binh",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19242,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19261,
        "ten_map": "Tien Trang Lan Binh",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19262,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19263,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19301,
        "ten_map": "Quan Phu Lan Binh",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19302,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19303,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19401,
        "ten_map": "Thien Tri",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19411,
        "ten_map": "Dinh nui Bach Lang 1",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19412,
        "ten_map": "Dinh nui Bach Lang 2",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19413,
        "ten_map": "Dinh nui Bach Lang 3",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19501,
        "ten_map": "Tuyet Dia Lieu Dong 1",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19502,
        "ten_map": "Tuyet Dia Lieu Dong 2",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19503,
        "ten_map": "Tuyet Dia Lieu Dong 3",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19504,
        "ten_map": "Tuyet Dia Lieu Dong 4",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19505,
        "ten_map": "Tuyet Dia Lieu Dong 5",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19506,
        "ten_map": "Tuyet Dia Lieu Dong 6",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19507,
        "ten_map": "Tuyet Dia Lieu Dong 7",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19508,
        "ten_map": "Tuyet Dia Lieu Dong 8",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19509,
        "ten_map": "Tuyet Dia Lieu Dong 9",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19511,
        "ten_map": "Tuyet Dia Lieu Thanh 1",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19512,
        "ten_map": "Tuyet Dia Lieu Thanh 2",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19513,
        "ten_map": "Tuyet Dia Lieu Thanh 3",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19521,
        "ten_map": "S.Dong Bach Lang 1",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19522,
        "ten_map": "S.Dong Bach Lang 2",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19523,
        "ten_map": "S.Dong Bach Lang 3",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19524,
        "ten_map": "S.Dong Bach Lang 4",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19525,
        "ten_map": "S.Dong Bach Lang 5",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19526,
        "ten_map": "S.Dong Bach Lang 6",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19527,
        "ten_map": "S.Dong Bach Lang 7",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19528,
        "ten_map": "S.Dong Bach Lang 8",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19529,
        "ten_map": "S.Dong Bach Lang 9",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19530,
        "ten_map": "S.Dong Bach Lang 10",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19531,
        "ten_map": "Tuyet Dia Bac Phong 1",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19532,
        "ten_map": "Tuyet Dia Bac Phong 2",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19533,
        "ten_map": "Tuyet Dia Bac Phong 3",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19534,
        "ten_map": "Tuyet Dia Bac Phong 4",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19535,
        "ten_map": "Tuyet Dia Bac Phong 5",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19541,
        "ten_map": "Mo Khoang Phu Du 1",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19542,
        "ten_map": "Mo Khoang Phu Du 2",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19543,
        "ten_map": "Mo Khoang Phu Du 3",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19544,
        "ten_map": "Mo Khoang Phu Du 4",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19545,
        "ten_map": "Mo Khoang Phu Du 5",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19546,
        "ten_map": "Mo Khoang Phu Du 6",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19547,
        "ten_map": "Mo Khoang Phu Du 7",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19548,
        "ten_map": "Mo Khoang Phu Du 8",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19549,
        "ten_map": "Mo Khoang Phu Du 9",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19550,
        "ten_map": "Mo Khoang Phu Du 10",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19551,
        "ten_map": "Mo Khoang Phu Du 11",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19801,
        "ten_map": "Tuyet Dia Thuong Le 1",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19802,
        "ten_map": "Tuyet Dia Thuong Le 2",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19811,
        "ten_map": "Tuyet Dia Lieu Thuy 3",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19812,
        "ten_map": "Tuyet Dia Lieu Thuy 4",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19813,
        "ten_map": "Lieu Thuy Dinh",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19821,
        "ten_map": "Tuyet Dia Tan Thuong 1",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19822,
        "ten_map": "Tuyet Dia Tan Thuong 2",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19831,
        "ten_map": "Tuyet Dia Bac Phong 1",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19832,
        "ten_map": "Tuyet Dia Bac Phong 2",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19851,
        "ten_map": "Tuyet Dia Bach Lan 1",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19852,
        "ten_map": "Tuyet Dia Bach Lan 2",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19861,
        "ten_map": "Tuyet Dia Truong Bach",
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 20000,
        "ten_map": "Binh Chau",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20001,
        "ten_map": "Thuong Dang Thanh Mon",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20002,
        "ten_map": "Dai Do Thuong Dang 1",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20003,
        "ten_map": "Dai Do Thuong Dang 2",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20011,
        "ten_map": "Ha Dong Thanh Mon",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20012,
        "ten_map": "Dai Do Ha Dong",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20021,
        "ten_map": "Thon Giai Luong",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20031,
        "ten_map": "Bo Lac Nam Hung No",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20101,
        "ten_map": "N.Thoi Diem Thuong Dang",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20102,
        "ten_map": "N.Tao Thong Giai Luong",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20131,
        "ten_map": "N.Truong Hung Giai Luong",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20132,
        "ten_map": "N.Gia Luc Thuong Dang",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20133,
        "ten_map": "N.Lao Tieu Phu Giai Luong",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20171,
        "ten_map": "N.Luong Tap Ha Dong",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20172,
        "ten_map": "N.Huynh Tieu Nhi Ha Dong",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20173,
        "ten_map": "N.Quan Ngan Binh Giai Luong",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20174,
        "ten_map": "T.Ta Hien Vuong Hung No",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20175,
        "ten_map": "T.Ho Tru Tuyen Hung No",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20176,
        "ten_map": "Trai Hung No",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20177,
        "ten_map": "T.Thai Van Co Hung No",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20178,
        "ten_map": "T.Dieu Thuyen Hung No",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20201,
        "ten_map": "Lo Ren Ha Dong",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20221,
        "ten_map": "Tiem Thuoc Thuong Dang",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20241,
        "ten_map": "Nha Tro Thuong Dang",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20242,
        "ten_map": "Nha Tro Ha Dong",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20261,
        "ten_map": "Tien Trang Thuong Dang",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20281,
        "ten_map": "Quan Tra Giai Luong",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20301,
        "ten_map": "Quan Phu Thuong Dang",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20302,
        "ten_map": "Quan Phu Ha Dong",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20401,
        "ten_map": "Nhan Mon Quan 1",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20402,
        "ten_map": "Thong Do Nhan Mon Quan 1",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20403,
        "ten_map": "Nhan Mon Quan 2",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20404,
        "ten_map": "Thong Do Nhan Mon Quan 2",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20411,
        "ten_map": "Chan Nui Thu Duong",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20412,
        "ten_map": "Eo Nui Thu Duong",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20413,
        "ten_map": "Dinh Nui Thu Duong",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20414,
        "ten_map": "Nha Go Thu Duong TMY",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20501,
        "ten_map": "Son Dong Nha Quan 1",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20502,
        "ten_map": "Son Dong Nha Quan 2",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20503,
        "ten_map": "Son Dong Nha Quan 3",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20504,
        "ten_map": "Son Dong Nha Quan 4",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20505,
        "ten_map": "Son Dong Nha Quan 5",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20506,
        "ten_map": "Son Dong Nha Quan 6",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20507,
        "ten_map": "Son Dong Nha Quan 7",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20508,
        "ten_map": "Son Dong Nha Quan 8",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20509,
        "ten_map": "Son Dong Nha Quan 9",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20511,
        "ten_map": "Mo Khoang Hoang That 1",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20512,
        "ten_map": "Mo Khoang Hoang That 2",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20513,
        "ten_map": "Mo Khoang Hoang That 3",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20514,
        "ten_map": "Mo Khoang Hoang That 4",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20515,
        "ten_map": "Mo Khoang Hoang That 5",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20521,
        "ten_map": "Son Dong Tai Ha 1",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20522,
        "ten_map": "Son Dong Tai Ha 2",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20523,
        "ten_map": "Son Dong Tai Ha 3",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20524,
        "ten_map": "Son Dong Tai Ha 4",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20525,
        "ten_map": "Son Dong Tai Ha 5",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20526,
        "ten_map": "Son Dong Tai Ha 6",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20527,
        "ten_map": "Son Dong Tai Ha 7",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20528,
        "ten_map": "Son Dong Tai Ha 8",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20529,
        "ten_map": "Son Dong Tai Ha 9",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20530,
        "ten_map": "Son Dong Tai Ha 10",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20541,
        "ten_map": "Van Trung Son Dong 1",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20542,
        "ten_map": "Van Trung Son Dong 2",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20543,
        "ten_map": "Van Trung Son Dong 3",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20544,
        "ten_map": "Van Trung Son Dong 4",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20545,
        "ten_map": "Van Trung Son Dong 5",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20801,
        "ten_map": "Rung Cuu Nguyen 1",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20802,
        "ten_map": "Rung Cuu Nguyen 2",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20811,
        "ten_map": "Rung Don Luu 1",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20812,
        "ten_map": "Rung Don Luu 2",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20821,
        "ten_map": "Rung Cao Do 1",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20822,
        "ten_map": "Rung Cao Do 2",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20831,
        "ten_map": "Rung Ha Dong",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20841,
        "ten_map": "Rung Dinh Duong 1",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20842,
        "ten_map": "Rung Dinh Duong 2",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20843,
        "ten_map": "Rung Dinh Duong 3",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20851,
        "ten_map": "Rung Binh Duong 1",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20852,
        "ten_map": "Rung Binh Duong 2",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20853,
        "ten_map": "Rung Binh Duong 3",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20854,
        "ten_map": "Rung Binh Duong 4",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20861,
        "ten_map": "Rung Lam Bang 1",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20862,
        "ten_map": "Rung Lam Bang 2",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20871,
        "ten_map": "Rung Thuong Dang",
        "thanh": "Bình Châu"
    },
    {
        "idmap": 21000,
        "ten_map": "Kinh Chau",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21001,
        "ten_map": "Thanh Lan Duong",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "ten_map": "Lan Duong Duong Dao",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21003,
        "ten_map": "Dai Do Lan Duong",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21004,
        "ten_map": "Lan Duong Thanh Mon",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21011,
        "ten_map": "Giang Linh Thanh",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21012,
        "ten_map": "Dai Do Giang Linh 1",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21013,
        "ten_map": "Dai Do Giang Linh 2",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21014,
        "ten_map": "Giang Linh Diem Binh Dai",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21015,
        "ten_map": "Duong Lon Giang Linh",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21016,
        "ten_map": "Hoa Vien Giang Linh",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21021,
        "ten_map": "Tan Da Thon",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21031,
        "ten_map": "Thuy Kinh Trang",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21041,
        "ten_map": "Ngoa Long Cang",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21042,
        "ten_map": "Long Trung Thao Lo",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21043,
        "ten_map": "Long Trung Thao That",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21051,
        "ten_map": "Mach Thanh",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21061,
        "ten_map": "Lang Phong Duong",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21101,
        "ten_map": "N.Luu Ky Lan Duong",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21102,
        "ten_map": "N.H Thua Ngan Lan Duong",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21103,
        "ten_map": "N.Diep Nhi Giang Linh",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21104,
        "ten_map": "N.Hoang Trung Giang Linh",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21105,
        "ten_map": "N.Thi Ma Chinh",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21106,
        "ten_map": "N.TaoNhan G.Linh",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21107,
        "ten_map": "Phu Ho Giang Linh Gia Cat Luong",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21108,
        "ten_map": "Phu Ho Giang Linh",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21131,
        "ten_map": "N.Bang Thong Lan Duong",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21132,
        "ten_map": "N.Ma-Cam Phu Nhan Tan Da",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21133,
        "ten_map": "N.Tu Tu Giang Linh",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21134,
        "ten_map": "Quan dan Tan Da",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21135,
        "ten_map": "N.Quan Binh Giang Linh",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21136,
        "ten_map": "N.Ton Du Giang Linh",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21137,
        "ten_map": "Pho Ho Phong Duong",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21138,
        "ten_map": "Nha Ma Luong Giang Linh",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21171,
        "ten_map": "N.Gia Cat Van Lan Duong",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21172,
        "ten_map": "N.Ly Ma Ma Lan Duong",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21173,
        "ten_map": "N.Hoac Tuan Giang Linh",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21174,
        "ten_map": "N.Nguu Dai Ma Tan Da",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21175,
        "ten_map": "N.Chu Dien Tan Da",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21176,
        "ten_map": "N.H Nguyet Anh Giang Linh",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21177,
        "ten_map": "N.Ong Lao Ha Phong Duong",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21201,
        "ten_map": "Lo Ren Lan Duong",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21221,
        "ten_map": "Tiem Thuoc Lan Duong",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21222,
        "ten_map": "Hieu Thuoc Giang Linh",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21241,
        "ten_map": "Nha Tro Lan Duong",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21242,
        "ten_map": "Nha Tro Giang Linh",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21261,
        "ten_map": "Tien Trang Lan Duong",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21262,
        "ten_map": "Tien Trang Giang Linh",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21281,
        "ten_map": "Quan Tra Lan Duong",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21282,
        "ten_map": "Quan Tra Giang Linh",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21301,
        "ten_map": "Lan Duong Thu Duong",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21302,
        "ten_map": "Quan Phu Giang Linh",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21303,
        "ten_map": "Quan Phu Tan Da",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21304,
        "ten_map": "Quan Phu Phong Duong",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21401,
        "ten_map": "Lam Giang Dinh",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21411,
        "ten_map": "Cau Truong Bang",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21421,
        "ten_map": "Phong Hoa Dai",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21422,
        "ten_map": "Quan Dinh Phong Hoa Dai",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21423,
        "ten_map": "D.T Huong Bang",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21431,
        "ten_map": "Vo Dan Son",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21432,
        "ten_map": "Duong nui Vo Dan",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21433,
        "ten_map": "Dinh nui Vo Dan",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21434,
        "ten_map": "Nha nho Vo Dan",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21441,
        "ten_map": "Dan Khai Tieu Dao",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21442,
        "ten_map": "Lan Duong Dai Dao",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21451,
        "ten_map": "Dinh Luc Khau",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21452,
        "ten_map": "Ben Tau Luc Khau",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21501,
        "ten_map": "Tuoc Vi Son Dong 1",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21502,
        "ten_map": "Tuoc Vi Son Dong 2",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21503,
        "ten_map": "Tuoc Vi Son Dong 3",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21504,
        "ten_map": "Tuoc Vi Son Dong 4",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21505,
        "ten_map": "Tuoc Vi Son Dong 5",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21506,
        "ten_map": "Tuoc Vi Son Dong 6",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21507,
        "ten_map": "Tuoc Vi Son Dong 7",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21508,
        "ten_map": "Tuoc Vi Son Dong 8",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21509,
        "ten_map": "Tuoc Vi Son Dong 9",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21510,
        "ten_map": "Tuoc Vi Son Dong 10",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21511,
        "ten_map": "Tuoc Vi Son Dong 11",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21512,
        "ten_map": "Tuoc Vi Son Dong 12",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21521,
        "ten_map": "Son Dong Canh Son 1",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21522,
        "ten_map": "Son Dong Canh Son 2",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21523,
        "ten_map": "Son Dong Canh Son 3",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21524,
        "ten_map": "Son Dong Canh Son 4",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21525,
        "ten_map": "Son Dong Canh Son 5",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21526,
        "ten_map": "Son Dong Canh Son 6",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21527,
        "ten_map": "Son Dong Canh Son 7",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21528,
        "ten_map": "Son Dong Canh Son 8",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21529,
        "ten_map": "Son Dong Canh Son 9",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21530,
        "ten_map": "Son Dong Canh Son 10",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21531,
        "ten_map": "Son Dong Canh Son 11",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21532,
        "ten_map": "Son Dong Canh Son 12",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21533,
        "ten_map": "Son Dong Canh Son 13",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21534,
        "ten_map": "Son Dong Canh Son 14",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21535,
        "ten_map": "Son Dong Canh Son 15",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21536,
        "ten_map": "Son Dong Canh Son 16",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21537,
        "ten_map": "Son Dong Canh Son 17",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21541,
        "ten_map": "Mo Khoang Giang Linh 1",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21542,
        "ten_map": "Mo Khoang Giang Linh 2",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21543,
        "ten_map": "Mo Khoang Giang Linh 3",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21544,
        "ten_map": "Mo Khoang Giang Linh 4",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21545,
        "ten_map": "Mo Khoang Giang Linh 5",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21546,
        "ten_map": "Mo Khoang Giang Linh 6",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21547,
        "ten_map": "Mo Khoang Giang Linh 7",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21548,
        "ten_map": "Mo Khoang Giang Linh 8",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21549,
        "ten_map": "Mo Khoang Giang Linh 9",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21550,
        "ten_map": "Mo Khoang Giang Linh 10",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21551,
        "ten_map": "Mo Khoang Giang Linh 11",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21552,
        "ten_map": "Mo Khoang Giang Linh 12",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21553,
        "ten_map": "Mo Khoang Giang Linh 13",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21701,
        "ten_map": "Dong Ngo Thuy Trai",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21702,
        "ten_map": "Dong Ngo Trai 1",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21703,
        "ten_map": "Dinh Hoang Cai",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21704,
        "ten_map": "Dinh Cam Linh",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21705,
        "ten_map": "Ben Cang Thuyen Co",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21706,
        "ten_map": "Dong Ngo Trai 2",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21707,
        "ten_map": "Dinh Linh Ngo",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21708,
        "ten_map": "Dinh Linh Ngo",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21709,
        "ten_map": "Ben Cang Chien Thuyen 1",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21710,
        "ten_map": "Diem Quan Dai",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21711,
        "ten_map": "Sa Cat Tieu Chau",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21712,
        "ten_map": "Chau Noi GCL",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21713,
        "ten_map": "Dong Ngo Trai 3",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21714,
        "ten_map": "Dinh Chu Du",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21715,
        "ten_map": "Ben Cang Chien Thuyen 2",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21716,
        "ten_map": "Chu Du Dai Trai",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21717,
        "ten_map": "Dai Dinh Chu Du",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21718,
        "ten_map": "Dinh Trinh Pho",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21719,
        "ten_map": "Dinh Lao Sieu",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21720,
        "ten_map": "Dinh Linh Ngo",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21721,
        "ten_map": "Dinh Linh Ngo",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21722,
        "ten_map": "Dong Ngo Trai 4",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21723,
        "ten_map": "Dinh Sa Cat Can",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21724,
        "ten_map": "Cau Treo Tay Son",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21725,
        "ten_map": "Tai Son Ngam",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21726,
        "ten_map": "Ngam Noi Bang Thong",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21727,
        "ten_map": "Bac Tinh Dan",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21731,
        "ten_map": "Thuy Trai Mon Khau",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21732,
        "ten_map": "Tao Nguy Trai 2",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21733,
        "ten_map": "Dinh Truong Lieu",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21734,
        "ten_map": "Dinh Trinh Lap",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21735,
        "ten_map": "Dinh Linh Tao",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21736,
        "ten_map": "Dinh Linh Tao",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21737,
        "ten_map": "Tao Nguy Trai 3",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21738,
        "ten_map": "Dinh Thai Mao",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21739,
        "ten_map": "Dinh Y Cam",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21740,
        "ten_map": "Dinh Linh Tao",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21741,
        "ten_map": "Dinh Linh Tao",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21742,
        "ten_map": "Ben Cang Chien Thuyen 1",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21743,
        "ten_map": "Ben Cang Chien Thuyen 2",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21744,
        "ten_map": "Tao Nguy Trai 1",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21745,
        "ten_map": "Dinh Tu Tich",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21746,
        "ten_map": "Dinh Linh Tao",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21747,
        "ten_map": "Kho Luong Tuy Bac Son",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21748,
        "ten_map": "Kho Luong Thuc",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21749,
        "ten_map": "Can Trai",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21750,
        "ten_map": "Tao Nguy Trai 4",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21751,
        "ten_map": "Dinh Linh Tao",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21752,
        "ten_map": "Dinh Linh Tao",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21753,
        "ten_map": "Dinh Hua Chu",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21754,
        "ten_map": "Dinh Truong Can",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21755,
        "ten_map": "Dinh Tao Thao",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21756,
        "ten_map": "Lau Thuyen Nguy",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21757,
        "ten_map": "Luyen Quan truong",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21758,
        "ten_map": "Dai Dinh Tao Thao",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21759,
        "ten_map": "Dai Dinh Tao Thao",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21760,
        "ten_map": "Tam Giang Khau",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21761,
        "ten_map": "Trai Dong Ngo",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21762,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21763,
        "ten_map": "Thuy Trai Tao Dinh",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21771,
        "ten_map": "Tuoc Vi Dong",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21801,
        "ten_map": "Bac Vong Ba 1",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21802,
        "ten_map": "Bac Vong Ba 2",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21803,
        "ten_map": "Bac Vong Ba 3",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21804,
        "ten_map": "Bac Vong Ba 4",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21806,
        "ten_map": "Rung Tin Duong",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21807,
        "ten_map": "Nha nho Tin Duong",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21808,
        "ten_map": "Rung Tin Duong 1",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21809,
        "ten_map": "Rung Tin Duong 2",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21811,
        "ten_map": "Chu Thanh Trai Mon",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21812,
        "ten_map": "Chu Thanh Trai 1",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21813,
        "ten_map": "Chu Thanh Trai 2",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "ten_map": "Chu Thanh Trai 3",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21815,
        "ten_map": "Chu Thanh Dinh",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21816,
        "ten_map": "Ngoa Long Truc Lam",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21817,
        "ten_map": "Dinh Bac",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21820,
        "ten_map": "Thuong Dung Trai",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21821,
        "ten_map": "Thuong Dung Trai Mon",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21822,
        "ten_map": "Thuong Dung Thong Do",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21823,
        "ten_map": "Thuong Dung Trai",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21824,
        "ten_map": "Dinh Thuong Dung",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21825,
        "ten_map": "Dinh Thuong Dung",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21826,
        "ten_map": "Trai Cong an",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21827,
        "ten_map": "Dinh Cong an",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21831,
        "ten_map": "Truong Bang Ba 1",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21832,
        "ten_map": "Truong Bang Ba 2",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21833,
        "ten_map": "Truong Bang Ba 3",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21834,
        "ten_map": "Truong Bang Ba 4",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21836,
        "ten_map": "Rung Ha Khau 1",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21837,
        "ten_map": "Rung Ha Khau 2",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21841,
        "ten_map": "Tang Khau Trieu Trac 1",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21842,
        "ten_map": "Tang Khau Trieu Trac 2",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21843,
        "ten_map": "Tang Khau Trieu Trac 3",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21844,
        "ten_map": "Tang Khau Trieu Trac 4",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21846,
        "ten_map": "Rung Hien Son 1",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21847,
        "ten_map": "Rung Hien Son 2",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21848,
        "ten_map": "Rung Hien Son 3",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21851,
        "ten_map": "Nhi Linh Trai Mon",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21852,
        "ten_map": "Y Linh Trai 1",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21853,
        "ten_map": "Dinh Binh Y Linh Trai",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21854,
        "ten_map": "Dinh Binh Y Linh Trai",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21855,
        "ten_map": "Quan Dinh Y Linh Trai",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21856,
        "ten_map": "Y Linh Trai 2",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21857,
        "ten_map": "Dai Dinh Y Linh Trai",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21861,
        "ten_map": "Hoa Dung Dao 1",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21862,
        "ten_map": "Hoa Dung Dao 2",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21863,
        "ten_map": "Hoa Dung Dao 3",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21864,
        "ten_map": "Hoa Dung Dao 4",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21871,
        "ten_map": "Giang Ha Trai 1",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21872,
        "ten_map": "Giang Ha Trai 2",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21873,
        "ten_map": "Quan Dinh Giang Ha Trai",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21874,
        "ten_map": "Giang Ha Trai 3",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21875,
        "ten_map": "Quan Dinh Giang Ha Trai",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21876,
        "ten_map": "Giang Ha Trai 4",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21877,
        "ten_map": "Giang Ha Trai 5",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21881,
        "ten_map": "Rung Giang Linh 1",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21882,
        "ten_map": "Rung Giang Linh 2",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21883,
        "ten_map": "Dinh Chu Du Giang Linh",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21884,
        "ten_map": "Rung Giang Linh 3",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21885,
        "ten_map": "Rung Giang Linh 4",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21891,
        "ten_map": "Rung Mach Thanh 1",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21892,
        "ten_map": "Rung Mach Thanh 2",
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 22000,
        "ten_map": "Quan Trung"
    },
    {
        "idmap": 23000,
        "ten_map": "Kinh Nam",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23001,
        "ten_map": "Thanh Mon Truong Sa",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23002,
        "ten_map": "Dai Lo Truong Sa",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23003,
        "ten_map": "Duong Truong Sa",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23011,
        "ten_map": "Lung Linh Thanh Mon",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23012,
        "ten_map": "Dai Lo Lung Linh",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23021,
        "ten_map": "Dinh Mon Son Bo Lac",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23031,
        "ten_map": "Thon Dao Hoa Nguyen",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23041,
        "ten_map": "Thon Que Duong",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23051,
        "ten_map": "Bao Gia Trang",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23101,
        "ten_map": "Pho Ho Truong Sa",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23102,
        "ten_map": "N.Cam Khoi Dao Hoa Nguyen",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23103,
        "ten_map": "N.Tue Teu Dao Hoa Nguyen",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23104,
        "ten_map": "N.Quan Xuoc Que Duong",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23105,
        "ten_map": "N.Bao Lao Gia Bao Gia Trang",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23106,
        "ten_map": "N.Tr.Phu Nhan Bao Gia Trang",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23107,
        "ten_map": "N.Quach Du Bao Gia Trang",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23131,
        "ten_map": "N.Lieu Lap Truong Sa",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23132,
        "ten_map": "N.Huynh Tu Truong Sa",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23133,
        "ten_map": "N.Luu Do Lung Linh",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23134,
        "ten_map": "N.Tieu Tuc Tu Dao Hoa Nguyen",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23135,
        "ten_map": "N.Hau Dai Ma Dao Hoa Nguyen",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23136,
        "ten_map": "N.Ma Luy Que Duong",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23171,
        "ten_map": "Ban Ho Truong Sa",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23172,
        "ten_map": "N.Luu Ban Truong Sa",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23173,
        "ten_map": "N.Dau To Lung Linh",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23174,
        "ten_map": "Ban Ho Dinh Mon Son",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23175,
        "ten_map": "N.Anh Do Dinh Mon Son",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23176,
        "ten_map": "N.SaMaKha Dinh Mon Son",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23177,
        "ten_map": "N.Tinh Phu Dinh Mon Son",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23178,
        "ten_map": "N.Ly Lao Dau Que Duong",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23179,
        "ten_map": "Nha Tranh Nhi Dao",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23180,
        "ten_map": "N.Tuong Uyen Uat Nguyen",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23201,
        "ten_map": "Lo Ren Truong Sa",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23221,
        "ten_map": "Tiem Thuoc Truong Sa",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23241,
        "ten_map": "Nha Tro Truong Sa",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23242,
        "ten_map": "Nha Tro Lung Linh",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23261,
        "ten_map": "Tien Trang Truong Sa",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23262,
        "ten_map": "Tien Trang Lung Linh",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23281,
        "ten_map": "Tiem Tra Truong Sa",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23301,
        "ten_map": "Quan Phu Truong Sa",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23302,
        "ten_map": "Quan Phu Que Duong",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23401,
        "ten_map": "Dao Hoa Nguyen",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23402,
        "ten_map": "Dao Hoa Nguyen Lam",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23403,
        "ten_map": "Ao Dao Hoa",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23411,
        "ten_map": "Dinh Mon Son Dao",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23421,
        "ten_map": "Chan Nui Mao Hoang",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23422,
        "ten_map": "Eo Nui Mao Hoang",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23423,
        "ten_map": "Tung Moc Nui Mao Hoang",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23424,
        "ten_map": "Ho Dinh Nui Mao Hoang",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23431,
        "ten_map": "Ma Hoang Tuy Mo Nguyet",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23501,
        "ten_map": "Dinh Mon Son Thach Dong 1",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23502,
        "ten_map": "Dinh Mon Son Thach Dong 2",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23503,
        "ten_map": "Dinh Mon Son Thach Dong 3",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23504,
        "ten_map": "Dinh Mon Son Thach Dong 4",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23505,
        "ten_map": "Dinh Mon Son Thach Dong 5",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23506,
        "ten_map": "Dinh Mon Son Thach Dong 6",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23511,
        "ten_map": "Nhi Dao Son Dong 1",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23512,
        "ten_map": "Nhi Dao Son Dong 2",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23513,
        "ten_map": "Nhi Dao Son Dong 3",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23514,
        "ten_map": "Nhi Dao Son Dong 4",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23515,
        "ten_map": "Nhi Dao Son Dong 5",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23516,
        "ten_map": "Nhi Dao Son Dong 6",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23517,
        "ten_map": "Nhi Dao Son Dong 7",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23518,
        "ten_map": "Nhi Dao Son Dong 8",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23519,
        "ten_map": "Nhi Dao Son Dong 9",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23521,
        "ten_map": "Thien Dien Ma Dong 1",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23522,
        "ten_map": "Thien Dien Ma Dong 2",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23523,
        "ten_map": "Thien Dien Ma Dong 3",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23524,
        "ten_map": "Thien Dien Ma Dong 4",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23525,
        "ten_map": "Thien Dien Ma Dong 5",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23526,
        "ten_map": "Thien Dien Ma Dong 6",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23527,
        "ten_map": "Thien Dien Ma Dong 7",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23528,
        "ten_map": "Thien Dien Ma Dong 8",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23529,
        "ten_map": "Thien Dien Ma Dong 9",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23530,
        "ten_map": "Thien Dien Ma Dong 10",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23531,
        "ten_map": "Thien Dien Ma Dong 11",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23532,
        "ten_map": "Thien Dien Ma Dong 12",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23533,
        "ten_map": "Thien Dien Ma Dong 13",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23534,
        "ten_map": "Thien Dien Ma Dong 14",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23535,
        "ten_map": "Thien Dien Ma Dong 15",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23536,
        "ten_map": "Thien Dien Ma Dong 16",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23537,
        "ten_map": "Thien Dien Ma Dong 17",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23538,
        "ten_map": "Thien Dien Ma Dong 18",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23539,
        "ten_map": "Thien Dien Ma Dong 19",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23540,
        "ten_map": "Thien Dien Ma Dong 20",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23551,
        "ten_map": "Mo Khoang Khach Lanh Son 1",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23552,
        "ten_map": "Mo Khoang Khach Lanh Son 2",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23553,
        "ten_map": "Mo Khoang Khach Lanh Son 3",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23554,
        "ten_map": "Mo Khoang Khach Lanh Son 4",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23555,
        "ten_map": "Mo Khoang Khach Lanh Son 5",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23556,
        "ten_map": "Mo Khoang Khach Lanh Son 6",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23561,
        "ten_map": "Ma Hoang Tuy Nguyet Mo 1",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23562,
        "ten_map": "Ma Hoang Tuy Nguyet Mo 2",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 14610,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14611,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14612,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14612,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14613,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14613,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14613,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14613,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14613,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14614,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14615,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14618,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14618,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14618,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14618,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14618,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14618,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14618,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14618,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14619,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14620,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14620,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14620,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14621,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14621,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14621,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14622,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14622,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14623,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14623,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14625,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14625,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14627,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14627,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14629,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14629,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14630,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14630,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14631,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14631,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14631,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14632,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14632,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14632,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14632,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14632,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14632,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14632,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14632,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14632,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14632,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14632,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14632,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14634,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14634,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14635,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14636,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14636,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14637,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14637,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14637,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14637,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14637,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14637,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14637,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14637,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14637,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14638,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14638,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14638,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14638,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14638,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14638,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14638,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14638,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14638,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14639,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14639,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14639,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14639,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14639,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14639,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14639,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14639,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14639,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14640,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14640,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14640,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14640,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14640,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14640,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14640,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14640,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14640,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14641,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14641,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14641,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14641,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14641,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14641,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14641,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14641,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14641,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14642,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14642,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14642,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14642,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14642,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14642,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14642,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14642,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14642,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14642,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14642,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14642,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14643,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14643,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14643,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14643,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14643,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14643,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14643,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14643,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14643,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14643,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14643,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14643,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14644,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14644,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14644,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14644,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14644,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14644,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14644,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14644,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14644,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14644,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14644,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14644,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14645,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14645,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14645,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14645,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14645,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14645,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14645,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14645,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14645,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14645,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14645,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14645,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14646,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14646,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14646,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14646,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14646,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14646,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14646,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14646,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14646,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14646,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14646,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14646,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14648,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14648,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14648,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14648,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14648,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14648,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14648,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14648,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14649,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14653,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14661,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14661,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14661,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14661,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14670,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14670,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14671,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14671,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14671,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14672,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14672,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14673,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14673,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14673,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14674,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14674,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14674,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14675,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14675,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14676,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14676,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14677,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14677,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14678,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14678,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14678,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14679,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14679,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14680,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14680,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14680,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14681,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14681,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14682,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14682,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14682,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14682,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14682,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14682,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14682,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14682,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14682,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14682,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14682,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14682,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14683,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14685,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14687,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14687,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14687,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14687,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14688,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14688,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14688,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14689,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14689,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14689,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14691,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14691,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14692,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14692,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14692,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14693,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14693,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14693,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14693,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14693,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14693,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14693,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14693,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14693,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14693,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14693,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14693,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14694,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14696,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14696,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14696,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14697,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14697,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14697,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14697,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14801,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14801,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14801,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14801,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14801,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14801,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14801,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14801,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14801,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14801,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14801,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14801,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14801,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14801,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14801,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14801,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14801,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14801,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14801,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14801,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14801,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14801,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14802,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14802,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14802,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14802,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14802,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14802,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14802,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14802,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14802,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14802,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14802,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14802,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14802,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14802,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14802,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14802,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14802,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14802,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14803,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14803,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14803,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14803,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14803,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14803,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14805,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14805,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14805,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14805,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14805,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14805,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14813,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14813,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14813,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14813,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14813,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14813,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14813,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14813,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14813,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14813,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14813,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14813,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14813,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14813,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14813,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14813,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14813,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14814,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14814,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14814,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14814,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14814,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14814,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14814,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14814,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14815,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14821,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14821,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14821,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14821,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14821,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14821,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14821,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14821,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14821,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14821,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14821,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14821,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14821,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14821,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14821,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14821,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14822,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14822,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14822,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14822,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14822,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14822,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14823,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14823,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14823,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14823,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14823,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14823,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14823,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14824,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14824,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14824,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14824,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14824,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14824,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14825,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14825,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14825,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14825,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14825,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14825,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14831,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14831,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14831,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14831,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14831,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14831,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14831,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14831,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14831,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14831,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14831,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14831,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14831,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14831,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14831,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14831,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14831,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14831,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14831,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14831,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14831,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14831,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14831,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14831,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14831,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14831,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14831,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14832,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14832,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14832,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14832,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14832,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14832,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14832,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14832,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14832,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14832,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14832,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14832,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14832,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14832,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14832,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14832,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14832,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14833,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14833,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14833,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14833,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14835,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14835,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14835,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14835,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14836,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14836,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14836,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14836,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14836,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14836,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14836,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14836,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14836,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14836,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14836,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14836,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14836,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14836,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14836,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14836,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14836,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14837,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14837,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14837,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14837,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14837,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14837,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14838,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14838,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14838,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14838,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14838,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14838,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14842,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14842,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14842,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14843,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14843,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14843,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14844,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14844,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14844,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14844,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14845,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14845,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14845,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14845,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14845,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14845,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14845,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14846,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14846,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14846,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14846,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14846,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14846,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14851,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14851,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14851,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14851,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14851,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14851,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14851,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14852,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14852,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14852,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14852,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14852,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14852,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14852,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14852,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14852,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14852,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14861,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14861,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14861,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14861,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14861,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14861,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14861,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14861,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14861,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14861,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14862,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14862,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14862,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14862,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14862,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14862,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14862,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14862,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14862,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14862,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14862,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14871,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14871,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14871,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14871,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14871,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14871,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14871,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14871,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14871,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14871,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14871,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14872,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14872,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14872,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14872,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14872,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14872,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14872,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14872,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14872,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14873,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14873,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14873,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14873,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14873,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14873,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 14873,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 15000,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15000,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15000,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15000,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15000,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15000,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15000,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15000,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15000,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15000,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15000,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15001,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15001,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15001,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15001,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15001,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15001,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15001,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15001,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15001,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15001,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15001,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15001,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15001,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15001,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15001,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15001,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15001,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15001,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15001,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15001,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15001,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15002,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15002,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15002,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15002,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15002,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15003,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15003,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15011,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15011,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15011,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15011,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15011,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15011,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15011,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15011,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15011,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15011,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15011,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15011,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15011,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15011,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15011,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15011,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15011,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15011,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15011,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15011,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15011,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15011,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15011,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15011,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15012,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15012,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15012,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15012,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15012,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15012,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15012,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15012,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15013,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15013,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15013,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15013,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15013,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15013,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15013,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15013,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15013,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15013,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15013,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15013,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15013,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15013,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15013,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15013,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15013,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15013,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15013,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15013,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15013,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15013,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15013,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15013,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15013,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15013,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15013,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15013,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15013,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15014,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15014,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15014,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15014,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15014,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15014,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15014,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15014,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15014,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15014,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15014,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15021,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15021,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15021,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15021,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15021,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15021,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15021,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15021,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15021,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15022,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15022,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15022,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15022,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15022,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15022,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15022,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15022,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15023,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15023,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15023,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15024,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15024,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15024,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15025,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15031,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15031,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15031,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15031,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15031,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15031,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15031,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15031,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15031,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15031,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15031,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15031,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15031,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15031,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15031,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15031,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15031,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15031,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15031,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15031,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15031,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15101,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15102,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15102,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15102,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15102,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15102,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15102,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15102,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15102,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15103,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15103,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15103,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15103,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15103,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15103,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15103,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15104,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15104,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15104,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15104,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15104,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15131,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15132,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15132,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15132,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15133,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15133,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15133,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15134,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15134,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15134,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15134,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15134,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15135,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15135,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15136,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15136,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15171,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15172,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15173,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15173,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15173,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15173,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15173,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15173,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15174,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15174,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15201,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15202,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15221,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15241,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15241,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15241,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15242,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15242,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15242,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15242,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15242,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15243,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15243,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15261,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15262,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15301,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15301,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15301,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15301,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15301,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15301,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15301,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15301,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15301,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15302,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15302,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15302,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15302,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15302,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15302,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15302,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15302,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15303,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15303,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15303,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15303,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15303,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15303,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15303,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15304,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15304,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15304,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15304,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15304,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15304,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15304,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15304,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15304,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15304,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15304,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15304,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15304,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15306,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15306,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15306,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15306,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15306,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15307,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15307,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15307,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15307,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15307,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15307,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15307,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15307,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15307,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15307,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15307,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15307,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15401,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15401,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15401,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15401,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15401,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15402,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15402,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15402,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15402,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15402,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15402,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15403,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15403,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15403,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15403,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15404,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15404,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15404,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15411,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15411,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15412,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15412,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15412,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15414,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15414,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15414,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15414,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15414,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15414,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15414,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15414,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15415,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15415,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15415,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15415,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15415,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15415,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15415,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15415,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15415,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15421,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15421,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15423,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15423,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15423,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15423,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15423,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15423,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15423,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15423,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15423,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15423,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15423,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15424,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15424,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15424,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15424,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15424,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15424,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15424,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15424,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15424,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15424,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15424,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15424,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15424,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15424,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15424,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15424,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15424,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15424,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15424,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15431,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15431,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15431,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15431,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15431,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15431,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15433,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15433,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15433,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15433,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15433,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15433,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15433,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15433,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15433,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15433,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15434,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15435,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15435,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15435,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15435,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15435,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15435,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15435,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15435,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15435,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15435,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15435,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15435,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15435,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15435,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15435,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15435,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15436,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15441,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15441,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15442,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15442,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15442,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15442,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15442,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15442,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15443,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15443,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15444,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15444,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15445,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15445,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15445,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15445,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15445,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15445,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15451,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15451,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15451,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15451,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15451,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15451,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15451,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15451,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15452,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15452,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15452,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15452,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15452,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15452,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15452,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15452,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15453,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15453,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15453,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15453,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15453,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15453,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15453,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15453,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15454,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15454,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15454,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15454,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15454,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15454,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15454,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15455,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15455,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15455,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15455,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15455,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15455,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15455,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15455,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15455,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15455,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15455,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15455,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15455,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15456,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15456,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15456,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15456,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15456,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15456,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15456,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15456,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15456,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15456,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15456,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15456,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15456,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15456,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15456,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15456,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15456,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15456,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15456,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15456,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15456,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15456,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15456,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15456,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15457,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15457,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15457,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15457,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15457,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15457,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15457,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15457,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15457,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15457,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15457,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15457,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15457,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15457,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15457,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15457,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15457,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15457,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15457,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15457,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15457,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15457,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15457,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15457,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15458,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15459,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15459,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15459,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15459,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15459,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15460,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15460,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15460,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15460,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15460,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15460,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15460,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15460,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15460,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15460,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15461,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15461,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15461,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15461,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15461,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15461,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15461,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15461,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15461,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15461,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15461,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15461,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15461,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15462,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15462,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15462,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15462,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15462,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15462,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15462,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15462,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15462,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15463,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15463,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15463,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15463,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15463,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15463,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15463,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15463,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15463,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15463,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15463,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15463,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15463,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15463,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15463,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15463,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15463,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15463,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15463,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15463,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15463,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15463,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15463,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15463,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15463,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15463,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15463,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15463,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15463,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15463,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15463,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15463,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15463,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15463,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15463,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15464,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15464,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15471,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15471,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15471,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15471,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15471,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15471,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15471,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15471,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15471,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15471,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15471,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15471,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15472,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15472,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15472,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15472,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15481,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15481,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15481,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15481,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15481,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15481,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15481,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15481,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15481,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15481,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15481,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15481,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15491,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15491,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15491,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15491,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15491,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15491,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15491,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15491,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15491,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15491,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15491,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15492,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15492,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15492,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15492,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15492,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15492,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15492,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15492,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15492,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15492,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15492,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15492,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15492,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15492,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15492,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15492,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15492,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15492,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15492,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15492,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15492,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15494,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15494,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15494,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15494,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15494,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15494,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15494,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15494,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15494,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15501,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15501,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15501,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15501,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15501,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15501,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15501,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15501,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15501,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15501,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15501,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15501,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15501,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15501,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15501,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15501,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15501,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15501,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15501,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15501,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15501,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15501,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15501,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15501,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15501,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15502,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15502,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15503,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15503,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15504,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15505,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15505,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15505,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15505,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15505,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15505,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15505,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15505,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15505,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15506,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15506,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15506,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15506,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15506,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15506,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15506,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15506,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15506,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15506,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15506,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15507,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15507,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15507,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15507,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15507,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15507,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15507,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15508,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15508,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15509,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15509,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15510,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15510,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15510,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15510,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15510,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15510,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15510,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15510,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15510,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15510,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15510,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15510,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15511,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15512,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15513,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15514,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15515,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15516,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15517,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15517,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15517,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15518,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15518,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15518,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15518,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15521,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15521,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15521,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15521,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15521,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15521,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15521,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15521,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15521,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15521,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15521,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15521,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15521,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15521,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15521,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15521,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15522,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15522,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15522,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15522,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15522,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15523,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15524,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15524,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15524,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15524,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15524,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15524,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15524,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15524,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15524,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15524,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15525,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15525,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15526,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15526,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15527,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15528,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15529,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15529,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15541,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15541,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15541,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15541,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15541,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15541,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15541,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15541,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15541,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15542,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15542,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15542,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15543,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15543,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15543,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15543,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15545,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15545,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15545,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15545,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15545,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15546,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15547,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15548,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15548,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15548,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15548,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15549,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15549,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15549,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15549,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15550,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15550,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15550,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15551,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15552,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15553,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15553,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15553,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15554,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15554,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15554,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15554,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15555,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15561,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15561,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15562,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15562,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15563,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15563,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15564,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15564,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15564,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15564,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15565,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15565,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15565,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15566,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15567,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15567,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15568,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15568,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15568,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15568,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15568,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15569,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15569,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15569,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15570,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15570,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15570,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15570,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15571,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15571,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15571,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15571,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15571,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15571,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15571,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15571,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15581,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15581,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15581,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15581,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15581,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15581,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15581,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15581,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15582,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15591,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15591,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15591,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15591,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15591,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15591,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15591,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15591,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15591,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15591,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15591,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15591,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15591,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15591,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15591,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15591,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15591,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15701,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15701,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15701,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15701,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15701,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15701,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15701,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15701,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15701,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15701,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15701,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15701,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15701,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15701,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15701,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15701,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15701,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15701,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15701,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15701,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15701,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15701,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15701,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15701,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15701,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15701,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15701,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15701,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15701,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15701,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15702,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15702,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15702,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15702,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15702,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15702,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15702,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15702,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15702,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15702,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15702,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15702,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15702,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15702,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15702,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15702,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15704,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15704,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15704,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15704,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15704,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15704,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15705,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15706,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15706,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15706,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15706,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15706,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15706,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15706,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15706,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15706,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15706,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15706,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15706,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15706,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15711,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15711,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15711,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15711,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15711,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15711,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15711,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15711,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15711,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15711,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15711,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15802,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15802,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15802,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15802,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15802,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15802,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15802,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15802,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15802,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15802,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15802,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15802,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15804,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15805,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15805,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15805,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15805,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15805,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15805,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15805,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15805,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15805,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15805,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15805,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15811,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15811,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15811,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15811,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15811,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15811,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15811,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15811,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15812,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15812,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15812,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15812,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15812,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15812,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15812,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15812,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15812,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15812,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15812,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15812,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15812,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15812,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15821,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15821,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15821,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15821,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15822,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15822,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15822,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15822,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15822,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15823,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15823,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15823,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15823,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15823,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15823,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15823,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15823,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15823,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15823,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15824,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15824,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15824,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15824,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15824,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15824,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15831,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15831,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15831,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15831,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15831,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15831,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15831,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15831,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15831,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15832,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15832,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15832,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15832,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15832,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15832,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15841,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15841,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15841,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15841,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15841,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15841,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15842,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15842,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15843,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15843,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15843,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15843,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15843,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15843,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15843,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15843,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15843,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15843,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15843,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15843,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15843,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15843,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15843,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15843,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15844,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15844,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15844,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15845,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15845,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15845,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15845,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15846,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15846,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15847,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15847,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15848,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15848,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15848,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15848,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15848,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15848,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15851,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15851,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15851,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15851,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15852,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15852,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15852,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15852,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15852,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15853,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15853,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15853,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15853,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15853,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15853,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15861,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15861,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15861,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15861,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15861,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15861,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15861,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15861,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15861,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15861,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15861,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15861,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15861,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15861,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15861,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15861,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15861,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15861,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15861,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15861,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15861,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15861,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15861,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15861,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15861,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15861,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15861,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15861,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15861,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15861,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15862,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15862,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15862,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15862,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15862,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15862,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15863,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15863,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15863,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15863,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15863,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15863,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15863,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15863,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15863,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15871,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15871,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15871,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15871,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15871,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15871,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15871,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15881,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15881,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15881,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15881,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15881,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15881,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15881,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15881,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15891,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15891,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15891,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15891,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15891,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15891,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15891,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15891,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15891,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15891,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15891,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15891,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15893,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15893,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15893,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15893,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15893,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15893,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15893,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15893,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15895,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15895,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15895,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15895,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15895,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15895,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15895,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15895,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15895,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15895,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15895,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15895,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15895,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15896,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15896,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15896,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15896,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15896,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15896,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15896,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15896,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15896,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15896,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15896,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15901,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15901,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15901,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15901,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15901,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15901,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15901,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15901,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15901,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15901,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15901,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15901,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15901,
        "thanh": "Từ Châu"
    },
    {
        "idmap": 15901,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16001,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16001,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16001,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16001,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16001,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16001,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16001,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16001,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16001,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16001,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16001,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16001,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16101,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16131,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16402,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16402,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16402,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16402,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16402,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16402,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16402,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16412,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16412,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16412,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16412,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16412,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16412,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16412,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16412,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16412,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16412,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16412,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16501,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16501,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16501,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16502,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16504,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16504,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16504,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16504,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16504,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16505,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16506,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16506,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16506,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16506,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16507,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16508,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16508,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16509,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16510,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16510,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16510,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16510,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16511,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16511,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16512,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16521,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16521,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16522,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16523,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16523,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16523,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16523,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16523,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16523,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16523,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16523,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16523,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16523,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16523,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16523,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16523,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16523,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16525,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16525,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16527,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16527,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16527,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16527,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16527,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16531,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16531,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16531,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16532,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16532,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16532,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16533,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16533,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16533,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16534,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16534,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16535,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16535,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16535,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16535,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16535,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16535,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16536,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16536,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16536,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16537,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16537,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16537,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16537,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16538,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16538,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16539,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16539,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16539,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16540,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16542,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16551,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16551,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16551,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16551,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16551,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16551,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16551,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16551,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16801,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16801,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16801,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16801,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16801,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16801,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16802,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16802,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16802,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16802,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16802,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16811,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16811,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16811,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16811,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16811,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16811,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16811,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16811,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16811,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16811,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16811,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16811,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16811,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 16811,
        "thanh": "Nhị Châu"
    },
    {
        "idmap": 18000,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18001,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18002,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18002,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18002,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18002,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18002,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18002,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18002,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18003,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18003,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18003,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18011,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18011,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18011,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18011,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18011,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18011,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18012,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18012,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18012,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18012,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18012,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18012,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18013,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18021,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18021,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18021,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18021,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18021,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18021,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18021,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18022,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18022,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18022,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18022,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18022,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18022,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18031,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18031,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18031,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18031,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18031,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18031,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18031,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18031,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18031,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18031,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18031,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18031,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18031,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18031,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18031,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18031,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18031,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18031,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18031,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18031,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18031,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18031,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18041,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18041,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18041,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18041,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18041,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18041,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18041,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18041,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18041,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18041,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18041,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18041,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18041,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18041,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18041,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18041,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18041,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18041,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18041,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18051,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18051,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18051,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18051,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18051,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18051,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18051,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18051,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18051,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18051,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18051,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18051,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18061,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18061,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18061,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18061,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18061,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18061,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18061,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18061,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18061,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18071,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18071,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18071,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18071,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18071,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18071,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18071,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18071,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18071,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18071,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18071,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18071,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18071,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18081,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18081,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18101,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18101,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18101,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18101,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18102,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18102,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18102,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18102,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18103,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18103,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18104,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18104,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18104,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18105,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18105,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18106,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18106,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18107,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18107,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18107,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18107,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18108,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18108,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18108,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18108,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18109,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18109,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18109,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18110,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18111,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18111,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18111,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18111,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18111,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18111,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18112,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18112,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18112,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18112,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18112,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18112,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18112,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18112,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18113,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18113,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18113,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18113,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18113,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18113,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18113,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18113,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18113,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18113,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18113,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18113,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18113,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18113,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18113,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18113,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18114,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18114,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18114,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18115,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18115,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18115,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18115,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18115,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18116,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18116,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18116,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18116,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18117,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18117,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18118,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18118,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18131,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18131,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18132,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18132,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18132,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18132,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18132,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18133,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18134,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18134,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18135,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18136,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18137,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18137,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18138,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18138,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18138,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18138,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18139,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18140,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18140,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18141,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18141,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18142,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18142,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18142,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18142,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18142,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18143,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18143,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18144,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18144,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18171,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18171,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18172,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18172,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18173,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18174,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18174,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18174,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18174,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18175,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18175,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18175,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18176,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18176,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18176,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18176,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18176,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18176,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18176,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18176,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18176,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18177,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18178,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18179,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18179,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18179,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18179,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18179,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18180,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18180,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18180,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18180,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18180,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18180,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18180,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18180,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18180,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18181,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18182,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18182,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18182,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18182,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18183,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18184,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18185,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18185,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18185,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18186,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18186,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18187,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18187,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18187,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18187,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18188,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18188,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18188,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18188,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18189,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18201,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18202,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18203,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18221,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18221,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18221,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18222,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18241,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18241,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18241,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18241,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18242,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18242,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18242,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18242,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18242,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18242,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18243,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18243,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18243,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18243,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18261,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18262,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18301,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18301,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18301,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18301,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18301,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18301,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18301,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18301,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18301,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18301,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18301,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18301,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18301,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18301,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18301,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18301,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18301,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18301,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18301,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18301,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18301,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18301,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18301,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18301,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18301,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18301,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18301,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18301,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18302,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18302,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18302,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18302,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18302,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18302,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18303,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18303,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18303,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18303,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18303,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18303,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18303,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18303,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18303,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18304,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18304,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18304,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18304,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18304,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18304,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18304,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18304,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18304,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18305,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18305,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18305,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18305,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18305,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18305,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18305,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18305,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18305,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18305,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18305,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18305,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18305,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18305,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18305,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18305,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18305,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18305,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18305,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18305,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18401,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18401,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18402,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18402,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18402,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18403,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18403,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18403,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18403,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18403,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18403,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18403,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18403,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18404,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18404,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18404,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18404,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18404,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18404,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18404,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18404,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18404,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18404,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18404,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18404,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18404,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18404,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18404,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18404,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18404,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18404,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18404,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18404,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18404,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18405,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18405,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18405,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18405,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18405,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18405,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18405,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18405,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18405,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18405,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18405,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18411,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18411,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18411,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18411,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18411,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18421,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18421,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18421,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18421,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18421,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18421,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18421,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18421,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18421,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18422,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18422,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18422,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18423,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18423,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18423,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18423,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18423,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18423,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18423,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18423,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18423,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18423,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18423,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18424,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18424,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18424,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18424,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18424,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18424,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18432,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18432,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18432,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18432,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18432,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18432,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18432,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18433,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18433,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18433,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18433,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18433,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18433,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18433,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18433,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18433,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18433,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18433,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18433,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18434,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18434,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18434,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18434,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18434,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18434,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18435,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18435,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18435,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18435,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18435,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18442,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18442,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18442,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18442,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18442,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18442,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18443,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18443,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18444,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18444,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18444,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18444,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18444,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18444,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18444,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18451,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18451,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18453,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18453,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18453,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18453,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18454,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18454,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18454,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18455,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18455,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18455,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18455,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18455,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18455,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18462,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18462,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18462,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18462,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18462,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18462,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18462,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18462,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18501,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18501,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18501,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18501,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18503,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18503,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18503,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18504,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18504,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18504,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18506,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18506,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18506,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18506,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18511,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18511,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18511,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18511,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18511,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18511,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18511,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18511,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18511,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18511,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18511,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18511,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18511,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18511,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18511,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18513,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18513,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18513,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18513,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18515,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18515,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18515,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18517,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18517,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18517,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18519,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18519,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18519,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18519,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18519,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18519,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18523,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18525,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18525,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18525,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18525,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18525,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18527,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18527,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18527,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18527,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18527,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18527,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18529,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18529,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18529,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18529,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18529,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18529,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18531,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18531,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18531,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18531,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18531,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18531,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18531,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18531,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18531,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18531,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18531,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18531,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18531,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18531,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18531,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18531,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18531,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18531,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18531,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18531,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18701,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18701,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18701,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18701,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18701,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18701,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18701,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18701,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18701,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18701,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18701,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18701,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18701,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18701,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18701,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18701,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18701,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18701,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18701,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18701,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18701,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18701,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18701,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18701,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18701,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18701,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18701,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18701,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18701,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18701,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18701,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18703,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18703,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18703,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18703,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18703,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18703,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18703,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18703,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18703,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18703,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18704,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18704,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18704,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18704,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18704,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18704,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18704,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18705,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18705,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18705,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18705,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18705,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18705,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18705,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18705,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18705,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18705,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18705,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18705,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18705,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18705,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18705,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18705,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18705,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18705,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18705,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18706,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18706,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18706,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18706,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18706,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18706,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18706,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18706,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18801,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18801,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18801,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18801,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18801,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18801,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18801,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18801,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18801,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18801,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18801,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18801,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18801,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18801,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18801,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18801,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18801,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18801,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18801,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18801,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18801,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18801,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18801,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18801,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18801,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18801,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18802,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18802,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18802,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18802,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18802,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18802,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18803,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18803,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18803,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18803,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18804,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18804,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18804,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18804,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18804,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18804,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18805,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18805,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18805,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18805,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18805,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18806,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18806,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18806,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18806,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18806,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18806,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18806,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18806,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18807,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18807,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18807,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18807,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18807,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18807,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18808,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18809,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18809,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18809,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18809,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18810,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18810,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18810,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18811,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18811,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18811,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18811,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18811,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18811,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18811,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18811,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18811,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18811,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18811,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18811,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18811,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18811,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18811,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18811,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18811,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18812,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18812,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18812,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18812,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18812,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18812,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18814,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18814,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18814,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18814,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18814,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18814,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18814,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18814,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18814,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18814,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18814,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18814,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18814,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18814,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18815,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18815,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18815,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18815,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18815,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18815,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18815,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18815,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18815,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18815,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18815,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18816,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18816,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18816,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18816,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18816,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18816,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18816,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18816,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18816,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18816,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18816,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18816,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18816,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18816,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18816,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18817,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18817,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18817,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18817,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18817,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18817,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18817,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18817,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18817,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18818,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18818,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18818,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18818,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18818,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18818,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18818,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18819,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18819,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18819,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18819,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18820,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18820,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18820,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18821,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18821,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18821,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18821,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18821,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18821,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18821,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18821,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18821,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18822,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18822,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18822,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18822,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18822,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18822,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18822,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18822,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18822,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18822,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18822,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18822,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18822,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18823,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18823,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18823,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18823,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18823,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18823,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18824,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18824,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18824,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18824,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18824,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18824,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18826,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18826,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18826,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18826,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18826,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18826,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18827,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18827,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18827,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18827,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18828,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18828,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18828,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18828,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18828,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18828,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18831,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18831,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18831,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18831,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18831,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18831,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18831,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18831,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18831,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18831,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18831,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18831,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18831,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18831,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18831,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18831,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18831,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18831,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18831,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18831,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18831,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18831,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18831,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18831,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18831,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18831,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18831,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18831,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18831,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18832,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18832,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18832,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18832,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18832,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18832,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18832,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18832,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18836,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18836,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18836,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18836,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18836,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18836,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18836,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18836,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18837,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18837,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18837,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18837,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18837,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18837,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18838,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18838,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18838,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18838,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18838,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18838,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18841,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18841,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18841,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18841,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18841,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18841,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18841,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18841,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18841,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18841,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18841,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18842,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18842,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18842,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18842,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18842,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18842,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18842,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18843,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18843,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18843,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18843,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18843,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18843,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18844,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18844,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18844,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18844,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18844,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18844,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18846,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18846,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18846,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18846,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18846,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18846,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18851,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18851,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18851,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18851,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18856,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18856,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18856,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18856,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18856,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18856,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18856,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18856,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18856,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18856,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18861,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18861,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18861,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18861,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18862,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18862,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18862,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18862,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18862,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18862,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18862,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18862,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18862,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18863,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18864,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18864,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18864,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18864,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18864,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18864,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18871,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18881,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18881,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18881,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18881,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18881,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18881,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18881,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18891,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18891,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18891,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18891,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18891,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18891,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18891,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18892,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18892,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18892,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18892,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18892,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18892,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18990,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18990,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18990,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18990,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18990,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18990,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18990,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18990,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18990,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18991,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18991,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18991,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18991,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18991,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18992,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18992,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18992,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18992,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18992,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18992,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18992,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18992,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18992,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18992,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18992,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18992,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18992,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18992,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18992,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18992,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18992,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18992,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18992,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18992,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18992,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18992,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18993,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18993,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18993,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18993,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18993,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18993,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18993,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18993,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18993,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18993,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18993,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18993,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18993,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18993,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18993,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18993,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18993,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18993,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18993,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18994,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18994,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18994,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18994,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18994,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18994,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18994,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 18994,
        "thanh": "Giang Đông (Dương Châu)"
    },
    {
        "idmap": 19000,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19001,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19001,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19001,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19001,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19001,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19001,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19002,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19002,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19002,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19002,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19002,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19002,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19011,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19011,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19011,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19021,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19021,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19021,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19021,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19021,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19031,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19031,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19031,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19031,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19031,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19031,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19031,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19031,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19031,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19031,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19031,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19031,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19031,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19031,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19031,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19031,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19031,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19031,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19031,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19031,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19031,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19031,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19032,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19033,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19034,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19101,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19101,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19101,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19101,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19131,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19132,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19132,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19133,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19133,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19171,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19172,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19173,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19174,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19175,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19175,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19175,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19175,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19175,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19175,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19175,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19175,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19175,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19175,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19175,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19176,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19176,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19177,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19178,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19178,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19179,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19179,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19201,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19201,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19201,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19221,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19241,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19241,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19241,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19242,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19261,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19262,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19263,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19301,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19301,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19301,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19301,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19301,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19301,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19301,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19301,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19302,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19302,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19303,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19303,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19401,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19401,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19401,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19401,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19401,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19401,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19401,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19401,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19401,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19401,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19411,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19411,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19411,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19412,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19412,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19412,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19412,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19412,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19413,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19413,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19413,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19413,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19413,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19413,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19506,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19506,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19509,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19511,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19511,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19512,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19512,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19512,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19512,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19513,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19513,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19513,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19523,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19523,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19523,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19523,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19523,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19525,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19525,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19528,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19528,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19530,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19530,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19530,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19530,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19530,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19530,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19530,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19530,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19531,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19531,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19531,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19531,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19531,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19531,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19531,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19531,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19531,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19531,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19532,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19532,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19532,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19532,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19532,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19533,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19533,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19533,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19533,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19533,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19533,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19533,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19533,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19533,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19533,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19533,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19533,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19533,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19533,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19533,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19533,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19534,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19534,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19541,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19541,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19541,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19541,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19541,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19543,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19543,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19543,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19543,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19543,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19543,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19543,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19543,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19543,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19544,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19544,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19544,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19544,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19544,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19544,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19544,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19544,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19544,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19545,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19545,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19547,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19547,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19547,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19547,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19547,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19547,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19547,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19547,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19547,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19548,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19548,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19548,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19548,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19548,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19548,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19550,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19550,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19550,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19550,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19550,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19551,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19551,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19551,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19551,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19551,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19551,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19551,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19801,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19801,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19801,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19801,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19801,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19801,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19801,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19802,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19802,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19802,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19802,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19802,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19802,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19802,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19802,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19802,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19802,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19802,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19811,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19811,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19811,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19811,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19811,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19811,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19811,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19811,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19811,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19811,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19811,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19812,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19812,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19812,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19812,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19821,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19821,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19821,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19821,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19821,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19821,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19821,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19821,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19821,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19822,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19822,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19822,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19822,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19822,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19822,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19831,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19831,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19831,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19831,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19831,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19832,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19832,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19832,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19851,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19851,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19851,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19851,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19851,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19851,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19851,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19851,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19852,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19852,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19852,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19852,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19852,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19861,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 19861,
        "thanh": "Liêu Đông"
    },
    {
        "idmap": 20001,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20001,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20001,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20001,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20001,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20001,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20003,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20003,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20003,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20011,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20011,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20012,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20012,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20012,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20012,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20012,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20021,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20021,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20021,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20021,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20021,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20031,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20031,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20031,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20031,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20031,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20101,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20101,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20101,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20102,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20102,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20102,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20131,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20131,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20132,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20132,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20133,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20133,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20171,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20172,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20172,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20172,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20173,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20173,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20174,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20174,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20175,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20175,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20175,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20175,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20176,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20177,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20177,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20178,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20178,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20201,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20221,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20241,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20241,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20241,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20241,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20242,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20242,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20242,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20261,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20281,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20281,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20281,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20281,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20281,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20281,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20301,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20301,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20301,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20301,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20301,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20301,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20301,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20301,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20302,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20302,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20302,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20302,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20302,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20302,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20302,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20402,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20402,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20411,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20413,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20414,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20414,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20414,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20414,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20502,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20505,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20505,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20505,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20505,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20505,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20505,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20507,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20507,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20507,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20511,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20511,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20511,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20511,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20511,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20511,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20511,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20511,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20511,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20511,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20511,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20511,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20511,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20511,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20512,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20512,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20512,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20512,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20512,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20512,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20512,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20512,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20512,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20512,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20512,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20512,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20512,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20512,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20513,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20513,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20513,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20513,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20513,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20513,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20513,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20513,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20513,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20513,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20513,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20513,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20513,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20514,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20514,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20514,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20514,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20514,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20514,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20514,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20514,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20514,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20514,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20514,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20514,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20514,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20514,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20515,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20515,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20515,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20515,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20515,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20515,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20515,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20515,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20515,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20515,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20515,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20515,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20515,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20515,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20515,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20515,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20515,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20522,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20523,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20523,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20523,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20523,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20523,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20523,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20523,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20523,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20525,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20525,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20525,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20525,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20525,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20526,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20527,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20527,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20528,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20528,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20528,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20528,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20528,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20528,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20529,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20529,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20529,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20529,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20529,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20529,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20529,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20529,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20529,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20529,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20545,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20801,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20801,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20801,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20801,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20801,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20801,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20801,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20801,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20801,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20801,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20801,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20801,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20801,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20801,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20801,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20801,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20802,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20802,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20802,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20802,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20802,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20802,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20811,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20811,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20811,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20811,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20811,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20811,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20812,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20812,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20812,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20812,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20812,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20812,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20812,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20812,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20812,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20812,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20812,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20812,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20812,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20821,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20821,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20821,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20821,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20821,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20822,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20822,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20822,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20822,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20822,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20822,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20822,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20822,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20822,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20822,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20822,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20822,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20822,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20831,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20831,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20831,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20831,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20831,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20831,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20831,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20831,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20831,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20831,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20831,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20831,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20831,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20831,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20831,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20831,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20831,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20831,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20831,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20841,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20841,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20841,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20841,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20841,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20841,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20842,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20842,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20842,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20842,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20842,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20842,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20843,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20843,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20843,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20843,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20851,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20851,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20851,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20851,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20852,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20853,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20853,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20853,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20853,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20853,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20853,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20854,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20854,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20854,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20854,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20854,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20854,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20861,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20861,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20861,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20861,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20861,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20861,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20861,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20861,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20861,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20861,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20861,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20861,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20861,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20861,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20861,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20861,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20861,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20862,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20862,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20862,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20862,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20862,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20862,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20871,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20871,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20871,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20871,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20871,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20871,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 20871,
        "thanh": "Bình Châu"
    },
    {
        "idmap": 21000,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21000,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21000,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21001,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21001,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21001,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21001,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21001,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21001,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21001,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21001,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21001,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21001,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21001,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21001,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21001,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21001,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21001,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21001,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21001,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21001,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21001,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21002,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21003,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21003,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21003,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21003,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21003,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21003,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21003,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21003,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21004,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21004,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21004,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21004,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21004,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21004,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21004,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21004,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21004,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21004,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21004,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21004,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21004,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21004,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21011,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21011,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21011,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21011,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21011,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21011,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21011,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21011,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21011,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21011,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21011,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21011,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21011,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21011,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21011,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21011,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21011,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21011,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21011,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21011,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21011,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21011,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21011,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21011,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21011,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21011,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21011,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21011,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21011,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21011,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21012,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21012,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21012,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21012,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21012,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21012,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21012,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21012,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21012,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21012,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21012,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21013,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21013,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21013,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21013,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21013,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21013,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21013,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21013,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21014,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21014,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21014,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21014,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21014,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21014,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21014,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21014,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21014,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21014,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21014,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21014,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21014,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21014,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21014,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21014,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21014,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21014,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21014,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21014,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21014,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21014,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21014,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21014,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21014,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21014,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21014,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21014,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21014,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21014,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21014,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21015,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21016,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21016,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21016,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21016,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21016,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21021,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21021,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21021,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21021,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21021,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21021,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21021,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21021,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21021,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21021,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21021,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21021,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21021,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21021,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21021,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21021,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21021,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21021,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21021,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21021,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21021,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21021,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21021,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21021,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21021,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21021,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21021,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21021,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21021,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21021,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21021,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21031,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21031,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21042,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21042,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21042,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21042,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21042,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21042,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21042,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21043,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21043,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21043,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21043,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21043,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21051,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21051,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21051,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21051,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21051,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21051,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21051,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21101,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21101,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21101,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21101,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21101,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21101,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21101,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21102,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21102,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21102,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21102,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21102,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21102,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21102,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21102,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21102,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21102,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21102,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21103,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21103,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21103,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21104,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21104,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21105,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21105,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21105,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21105,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21106,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21106,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21106,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21106,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21106,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21107,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21107,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21107,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21107,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21107,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21107,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21107,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21107,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21107,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21107,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21107,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21108,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21108,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21108,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21108,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21131,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21132,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21132,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21132,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21133,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21133,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21133,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21133,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21134,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21134,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21134,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21134,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21135,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21135,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21135,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21136,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21138,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21138,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21171,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21172,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21173,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21173,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21173,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21174,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21174,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21175,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21175,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21175,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21175,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21176,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21176,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21176,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21177,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21201,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21201,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21221,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21222,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21241,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21241,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21241,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21241,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21242,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21261,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21262,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21281,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21281,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21281,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21281,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21281,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21281,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21281,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21281,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21281,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21282,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21282,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21282,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21282,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21282,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21282,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21301,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21301,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21301,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21301,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21301,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21301,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21301,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21301,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21301,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21301,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21301,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21301,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21301,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21301,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21302,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21302,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21302,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21302,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21302,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21302,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21302,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21302,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21302,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21302,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21302,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21302,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21302,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21303,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21303,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21303,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21303,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21303,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21303,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21303,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21303,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21303,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21303,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21303,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21303,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21303,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21303,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21303,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21303,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21303,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21304,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21304,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21304,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21304,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21304,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21304,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21304,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21401,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21401,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21401,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21401,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21401,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21411,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21411,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21411,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21411,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21411,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21411,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21411,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21411,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21411,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21411,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21411,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21411,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21411,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21411,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21411,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21411,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21411,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21411,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21411,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21411,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21411,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21421,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21421,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21421,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21421,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21421,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21422,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21422,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21422,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21423,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21423,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21423,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21423,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21423,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21432,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21432,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21432,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21432,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21432,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21432,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21432,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21433,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21433,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21433,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21433,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21433,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21433,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21433,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21433,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21433,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21433,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21434,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21434,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21442,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21442,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21442,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21442,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21442,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21442,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21442,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21442,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21451,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21451,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21451,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21451,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21451,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21451,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21451,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21451,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21451,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21451,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21451,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21451,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21451,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21451,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21451,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21451,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21451,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21452,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21452,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21452,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21452,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21452,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21452,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21452,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21452,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21452,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21452,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21452,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21452,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21452,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21452,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21452,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21452,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21452,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21452,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21452,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21501,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21501,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21501,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21501,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21502,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21503,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21503,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21503,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21503,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21503,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21503,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21503,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21504,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21506,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21506,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21506,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21506,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21506,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21506,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21506,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21506,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21506,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21506,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21506,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21506,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21506,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21506,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21506,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21506,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21506,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21508,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21508,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21508,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21508,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21508,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21508,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21508,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21508,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21508,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21508,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21509,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21509,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21509,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21509,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21509,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21509,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21510,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21512,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21512,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21512,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21512,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21512,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21512,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21512,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21512,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21512,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21512,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21521,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21521,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21522,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21522,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21525,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21525,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21525,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21527,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21527,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21529,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21529,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21529,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21529,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21529,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21529,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21529,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21529,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21529,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21533,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21533,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21533,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21533,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21533,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21533,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21533,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21534,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21536,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21536,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21536,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21536,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21536,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21536,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21536,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21541,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21541,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21541,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21542,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21542,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21542,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21543,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21543,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21543,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21543,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21543,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21543,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21543,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21544,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21544,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21544,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21544,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21545,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21545,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21545,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21545,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21545,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21545,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21545,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21545,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21545,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21545,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21545,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21545,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21545,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21545,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21545,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21545,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21546,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21546,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21546,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21546,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21547,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21547,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21547,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21547,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21547,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21547,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21547,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21547,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21547,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21548,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21548,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21549,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21549,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21549,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21549,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21549,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21549,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21549,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21549,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21549,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21549,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21550,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21550,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21550,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21551,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21551,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21551,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21551,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21551,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21551,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21551,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21552,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21552,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21553,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21553,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21553,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21553,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21553,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21553,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21553,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21553,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21553,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21553,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21701,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21701,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21701,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21701,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21701,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21701,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21701,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21701,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21701,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21701,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21702,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21702,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21702,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21702,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21702,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21702,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21702,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21703,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21703,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21703,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21703,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21704,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21704,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21705,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21705,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21705,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21705,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21705,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21705,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21705,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21705,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21705,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21705,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21706,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21706,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21706,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21706,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21706,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21707,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21708,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21709,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21709,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21710,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21710,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21710,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21710,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21710,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21710,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21710,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21710,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21710,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21710,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21710,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21710,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21710,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21710,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21710,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21710,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21710,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21710,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21710,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21710,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21710,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21710,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21710,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21710,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21710,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21710,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21710,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21710,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21711,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21711,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21712,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21712,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21712,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21712,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21712,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21713,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21713,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21713,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21713,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21713,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21713,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21713,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21713,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21713,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21713,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21713,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21714,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21714,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21714,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21714,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21714,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21714,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21715,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21715,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21716,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21716,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21716,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21717,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21717,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21717,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21717,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21717,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21717,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21717,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21717,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21717,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21717,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21717,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21717,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21717,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21717,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21717,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21717,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21717,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21717,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21717,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21717,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21717,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21717,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21717,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21717,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21717,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21717,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21717,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21717,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21717,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21717,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21717,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21717,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21717,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21717,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21718,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21718,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21718,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21718,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21719,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21719,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21720,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21722,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21722,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21722,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21723,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21725,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21725,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21725,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21725,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21725,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21726,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21726,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21727,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21727,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21727,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21727,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21727,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21727,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21727,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21727,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21727,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21727,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21727,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21727,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21727,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21727,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21727,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21727,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21727,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21727,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21727,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21727,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21727,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21727,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21731,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21731,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21732,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21732,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21732,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21732,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21732,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21732,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21732,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21732,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21732,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21732,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21732,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21732,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21733,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21734,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21735,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21736,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21736,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21736,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21736,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21737,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21737,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21737,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21737,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21737,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21737,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21737,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21737,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21738,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21738,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21739,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21739,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21740,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21740,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21742,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21742,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21742,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21743,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21743,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21743,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21743,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21743,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21743,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21743,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21743,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21744,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21744,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21744,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21744,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21744,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21744,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21744,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21744,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21744,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21744,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21744,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21744,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21744,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21744,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21744,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21744,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21745,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21745,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21745,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21746,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21747,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21747,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21747,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21747,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21748,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21749,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21749,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21749,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21749,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21749,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21749,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21749,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21749,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21749,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21749,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21749,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21749,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21749,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21749,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21749,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21750,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21750,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21750,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21750,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21750,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21750,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21750,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21750,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21750,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21750,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21750,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21752,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21753,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21754,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21755,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21755,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21755,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21755,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21755,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21755,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21755,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21755,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21756,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21756,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21756,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21756,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21756,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21756,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21756,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21756,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21756,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21756,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21756,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21756,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21756,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21756,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21756,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21756,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21756,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21756,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21756,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21756,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21756,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21756,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21756,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21756,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21756,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21756,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21756,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21756,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21756,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21757,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21757,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21757,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21757,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21757,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21757,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21757,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21757,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21757,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21757,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21757,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21757,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21757,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21757,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21758,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21758,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21758,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21758,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21758,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21758,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21758,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21758,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21758,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21758,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21759,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21759,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21759,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21759,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21759,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21759,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21759,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21759,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21759,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21759,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21759,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21759,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21759,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21759,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21760,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21760,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21760,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21760,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21760,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21760,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21760,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21760,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21760,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21760,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21760,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21760,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21760,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21760,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21761,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21761,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21761,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21761,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21762,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21762,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21762,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21763,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21763,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21763,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21763,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21763,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21763,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21763,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21763,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21763,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21763,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21763,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21763,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21763,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21763,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21763,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21763,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21763,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21763,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21771,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21771,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21771,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21771,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21771,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21771,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21771,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21771,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21801,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21801,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21801,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21801,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21801,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21801,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21801,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21801,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21801,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21801,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21801,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21801,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21801,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21801,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21802,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21802,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21802,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21802,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21802,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21802,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21802,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21802,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21802,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21802,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21802,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21802,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21802,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21802,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21803,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21803,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21803,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21803,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21803,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21803,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21803,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21803,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21803,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21803,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21804,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21804,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21804,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21804,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21804,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21804,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21806,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21806,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21806,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21806,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21806,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21807,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21807,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21807,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21807,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21807,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21807,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21807,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21807,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21807,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21808,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21808,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21808,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21808,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21808,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21808,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21808,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21808,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21808,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21808,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21808,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21808,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21808,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21808,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21808,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21808,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21809,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21809,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21809,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21809,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21809,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21809,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21811,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21811,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21811,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21811,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21811,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21811,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21811,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21811,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21811,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21811,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21812,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21812,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21812,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21812,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21812,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21812,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21812,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21812,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21812,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21812,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21812,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21812,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21812,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21812,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21812,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21812,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21812,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21812,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21812,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21812,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21812,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21812,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21812,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21813,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21813,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21813,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21813,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21813,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21813,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21813,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21813,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21813,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21814,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21815,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21815,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21815,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21816,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21816,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21816,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21816,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21817,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21817,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21817,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21817,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21817,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21817,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21818,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21818,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21818,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21818,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21818,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21819,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21819,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21819,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21819,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21819,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21819,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21819,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21819,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21819,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21819,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21819,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21819,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21819,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21820,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21820,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21820,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21820,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21820,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21820,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21821,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21821,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21821,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21821,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21821,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21821,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21821,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21821,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21821,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21821,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21821,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21821,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21821,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21821,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21821,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21821,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21821,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21821,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21821,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21821,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21821,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21823,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21823,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21823,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21823,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21823,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21823,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21823,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21823,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21824,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21824,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21826,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21826,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21826,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21826,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21827,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21831,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21831,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21831,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21831,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21831,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21831,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21831,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21831,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21831,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21831,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21831,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21831,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21831,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21831,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21831,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21831,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21831,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21831,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21831,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21831,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21831,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21831,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21832,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21832,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21832,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21832,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21832,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21832,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21832,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21832,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21832,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21832,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21833,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21833,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21833,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21833,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21833,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21833,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21833,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21833,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21833,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21833,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21833,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21833,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21833,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21833,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21833,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21833,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21834,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21834,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21834,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21834,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21834,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21834,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21836,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21836,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21836,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21836,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21837,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21837,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21837,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21837,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21837,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21837,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21837,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21837,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21837,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21837,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21837,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21837,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21837,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21841,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21841,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21841,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21841,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21841,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21841,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21842,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21844,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21844,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21844,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21844,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21844,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21844,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21846,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21847,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21847,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21847,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21847,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21847,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21847,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21847,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21847,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21847,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21847,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21847,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21847,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21847,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21847,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21847,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21847,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21847,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21847,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21847,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21847,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21848,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21848,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21848,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21848,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21848,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21848,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21851,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21851,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21852,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21852,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21852,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21852,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21852,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21852,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21853,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21855,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21855,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21855,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21855,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21855,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21855,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21855,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21856,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21856,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21857,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21857,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21857,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21861,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21861,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21861,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21861,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21861,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21861,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21861,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21861,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21861,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21861,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21861,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21861,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21861,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21861,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21861,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21861,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21861,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21861,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21861,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21861,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21862,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21862,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21862,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 21862,
        "thanh": "Kinh Bắc (Dinh Bắc)"
    },
    {
        "idmap": 22000,
        "thanh": "Quan Trung"
    },
    {
        "idmap": 23000,
        "ten_map": "Kinh Nam",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23001,
        "ten_map": "Thanh Mon Truong Sa",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23002,
        "ten_map": "Dai Lo Truong Sa",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23003,
        "ten_map": "Duong Truong Sa",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23011,
        "ten_map": "Lung Linh Thanh Mon",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23012,
        "ten_map": "Dai Lo Lung Linh",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23021,
        "ten_map": "Dinh Mon Son Bo Lac",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23031,
        "ten_map": "Thon Dao Hoa Nguyen",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23041,
        "ten_map": "Thon Que Duong",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23051,
        "ten_map": "Bao Gia Trang",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23101,
        "ten_map": "Pho Ho Truong Sa",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23102,
        "ten_map": "N.Cam Khoi Dao Hoa Nguyen",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23103,
        "ten_map": "N.Tue Teu Dao Hoa Nguyen",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23104,
        "ten_map": "N.Quan Xuoc Que Duong",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23105,
        "ten_map": "N.Bao Lao Gia Bao Gia Trang",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23106,
        "ten_map": "N.Tr.Phu Nhan Bao Gia Trang",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23107,
        "ten_map": "N.Quach Du Bao Gia Trang",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23131,
        "ten_map": "N.Lieu Lap Truong Sa",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23132,
        "ten_map": "N.Huynh Tu Truong Sa",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23133,
        "ten_map": "N.Luu Do Lung Linh",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23134,
        "ten_map": "N.Tieu Tuc Tu Dao Hoa Nguyen",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23135,
        "ten_map": "N.Hau Dai Ma Dao Hoa Nguyen",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23136,
        "ten_map": "N.Ma Luy Que Duong",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23171,
        "ten_map": "Ban Ho Truong Sa",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23172,
        "ten_map": "N.Luu Ban Truong Sa",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23173,
        "ten_map": "N.Dau To Lung Linh",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23174,
        "ten_map": "Ban Ho Dinh Mon Son",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23175,
        "ten_map": "N.Anh Do Dinh Mon Son",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23176,
        "ten_map": "N.SaMaKha Dinh Mon Son",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23177,
        "ten_map": "N.Tinh Phu Dinh Mon Son",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23178,
        "ten_map": "N.Ly Lao Dau Que Duong",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23179,
        "ten_map": "Nha Tranh Nhi Dao",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23180,
        "ten_map": "N.Tuong Uyen Uat Nguyen",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23201,
        "ten_map": "Lo Ren Truong Sa",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23221,
        "ten_map": "Tiem Thuoc Truong Sa",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23241,
        "ten_map": "Nha Tro Truong Sa",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23242,
        "ten_map": "Nha Tro Lung Linh",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23261,
        "ten_map": "Tien Trang Truong Sa",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23262,
        "ten_map": "Tien Trang Lung Linh",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23281,
        "ten_map": "Tiem Tra Truong Sa",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23301,
        "ten_map": "Quan Phu Truong Sa",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23302,
        "ten_map": "Quan Phu Que Duong",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23401,
        "ten_map": "Dao Hoa Nguyen",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23402,
        "ten_map": "Dao Hoa Nguyen Lam",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23403,
        "ten_map": "Ao Dao Hoa",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23411,
        "ten_map": "Dinh Mon Son Dao",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23421,
        "ten_map": "Chan Nui Mao Hoang",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23422,
        "ten_map": "Eo Nui Mao Hoang",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23423,
        "ten_map": "Tung Moc Nui Mao Hoang",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23424,
        "ten_map": "Ho Dinh Nui Mao Hoang",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23431,
        "ten_map": "Ma Hoang Tuy Mo Nguyet",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23501,
        "ten_map": "Dinh Mon Son Thach Dong 1",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23502,
        "ten_map": "Dinh Mon Son Thach Dong 2",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23503,
        "ten_map": "Dinh Mon Son Thach Dong 3",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23504,
        "ten_map": "Dinh Mon Son Thach Dong 4",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23505,
        "ten_map": "Dinh Mon Son Thach Dong 5",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23506,
        "ten_map": "Dinh Mon Son Thach Dong 6",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23511,
        "ten_map": "Nhi Dao Son Dong 1",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23512,
        "ten_map": "Nhi Dao Son Dong 2",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23513,
        "ten_map": "Nhi Dao Son Dong 3",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23514,
        "ten_map": "Nhi Dao Son Dong 4",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23515,
        "ten_map": "Nhi Dao Son Dong 5",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23516,
        "ten_map": "Nhi Dao Son Dong 6",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23517,
        "ten_map": "Nhi Dao Son Dong 7",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23518,
        "ten_map": "Nhi Dao Son Dong 8",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23519,
        "ten_map": "Nhi Dao Son Dong 9",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23521,
        "ten_map": "Thien Dien Ma Dong 1",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23522,
        "ten_map": "Thien Dien Ma Dong 2",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23523,
        "ten_map": "Thien Dien Ma Dong 3",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23524,
        "ten_map": "Thien Dien Ma Dong 4",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23525,
        "ten_map": "Thien Dien Ma Dong 5",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23526,
        "ten_map": "Thien Dien Ma Dong 6",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23527,
        "ten_map": "Thien Dien Ma Dong 7",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23528,
        "ten_map": "Thien Dien Ma Dong 8",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23529,
        "ten_map": "Thien Dien Ma Dong 9",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23530,
        "ten_map": "Thien Dien Ma Dong 10",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23531,
        "ten_map": "Thien Dien Ma Dong 11",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23532,
        "ten_map": "Thien Dien Ma Dong 12",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23533,
        "ten_map": "Thien Dien Ma Dong 13",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23534,
        "ten_map": "Thien Dien Ma Dong 14",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23535,
        "ten_map": "Thien Dien Ma Dong 15",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23536,
        "ten_map": "Thien Dien Ma Dong 16",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23537,
        "ten_map": "Thien Dien Ma Dong 17",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23538,
        "ten_map": "Thien Dien Ma Dong 18",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23539,
        "ten_map": "Thien Dien Ma Dong 19",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23540,
        "ten_map": "Thien Dien Ma Dong 20",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23551,
        "ten_map": "Mo Khoang Khach Lanh Son 1",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23552,
        "ten_map": "Mo Khoang Khach Lanh Son 2",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23553,
        "ten_map": "Mo Khoang Khach Lanh Son 3",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23554,
        "ten_map": "Mo Khoang Khach Lanh Son 4",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23555,
        "ten_map": "Mo Khoang Khach Lanh Son 5",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23556,
        "ten_map": "Mo Khoang Khach Lanh Son 6",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23561,
        "ten_map": "Ma Hoang Tuy Nguyet Mo 1",
        "thanh": "Kinh Nam (Dinh Nam)"
    },
    {
        "idmap": 23562,
        "ten_map": "Ma Hoang Tuy Nguyet Mo 2",
        "thanh": "Kinh Nam (Dinh Nam)"
    }
]