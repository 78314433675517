import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { HomeFourComponent } from './components/pages/home-four/home-four.component';
import { AboutComponent } from './components/pages/about/about.component';
import { TeamComponent } from './components/pages/team/team.component';
import { HowItWorksComponent } from './components/pages/how-it-works/how-it-works.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { CategoriesOneComponent } from './components/pages/categories-one/categories-one.component';
import { CategoriesTwoComponent } from './components/pages/categories-two/categories-two.component';
import { ListingDetailsComponent } from './components/pages/listing-details/listing-details.component';
import { ListingFiveComponent } from './components/pages/listing-five/listing-five.component';
import { ListingFourComponent } from './components/pages/listing-four/listing-four.component';
import { ListingThreeComponent } from './components/pages/listing-three/listing-three.component';
import { ListingTwoComponent } from './components/pages/listing-two/listing-two.component';
import { ListingOneComponent } from './components/pages/listing-one/listing-one.component';
import { ProductListComponent } from './components/pages/product-list/product-list.component';
import { ProductsDetailsComponent } from './components/pages/products-details/products-details.component';
import { CartComponent } from './components/pages/cart/cart.component';
import { CheckoutComponent } from './components/pages/checkout/checkout.component';
import { BlogOneComponent } from './components/pages/blog-one/blog-one.component';
import { BlogTwoComponent } from './components/pages/blog-two/blog-two.component';
import { BlogThreeComponent } from './components/pages/blog-three/blog-three.component';
import { BlogFourComponent } from './components/pages/blog-four/blog-four.component';
import { BlogFiveComponent } from './components/pages/blog-five/blog-five.component';
import { BlogDetailsOneComponent } from './components/pages/blog-details-one/blog-details-one.component';
import { BlogDetailsTwoComponent } from './components/pages/blog-details-two/blog-details-two.component';
import { BlogDetailsThreeComponent } from './components/pages/blog-details-three/blog-details-three.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { LoginComponent } from './components/pages/login/login.component';
import { RegisterComponent } from './components/pages/register/register.component';
import { HomeComponent } from './home/home.component';
import { ListingComponent } from './listing/listing.component';
import { AdminModule } from './admin/admin.module';
import { DetailComponent } from './detail/detail.component';
import { AfkarenaComponent } from './games/afkarena/afkarena.component';
import { CotuongComponent } from './games/cotuong/cotuong.component';
import {
    AuthGuardService as AuthGuard
} from './services/auth-guard.service';
import { CoupComponent } from './games/coup/coup.component';
import { CategoriesComponent } from './categories/categories.component';
import { BlogComponent } from './blog/blog.component';
import { BlogDetailsComponent } from './blog-details/blog-details.component';
import { SummonerswarComponent } from './games/summonerswar/summonerswar.component';
import { OnepieceComponent } from './games/onepiece/onepiece.component';
import { TsComponent } from './games/ts/ts.component';
import { WarcraftComponent } from './games/warcraft/warcraft.component';
import { VuaphapthuatComponent } from './games/vuaphapthuat/vuaphapthuat.component';
import { CaudoComponent } from './games/afkarena/caudo/caudo.component';
import { TestComponent } from './games/summonerswar/test/test.component';
import { TrialOfAscensionComponent } from './games/summonerswar/trial-of-ascension/trial-of-ascension.component';
import { ChatAIComponent } from './chat-ai/chat-ai.component';
import { RunesComponent } from './games/summonerswar/test/runes/runes.component';
import { CaroComponent } from './games/caro/caro.component';

const routes: Routes = [
	{path: '', component: HomeComponent, title: "MYLIFE | HOME"},

	{path: 'afkarena', component: AfkarenaComponent, title: "MYLIFE | AFK ARENA"},
	{path: 'afkarena/caudo', component: CaudoComponent, title: "MYLIFE | AFK ARENA | CÂU ĐỐ"},
	{path: 'summonerswar', component: SummonerswarComponent, title: "MYLIFE | SUMMONERS WARS"},
	{path: 'summonerswar/test', component: TestComponent, title: "MYLIFE | SUMMONERS WARS | TESTING"},
	{path: 'summonerswar/test/runes', component: RunesComponent, title: "MYLIFE | SUMMONERS WARS | TEST RUNES"},
	{path: 'summonerswar/toa', component: TrialOfAscensionComponent, title: "MYLIFE | SUMMONERS WARS | Trial of Ascension "},
	{path: 'onepiece', component: OnepieceComponent, title: "MYLIFE | One Piece"},
	{path: 'ts', component: TsComponent, title: "MYLIFE | TS Online"},
	{path: 'warcraft', component: WarcraftComponent, title: "MYLIFE | Warcraft"},
	{path: 'vuaphapthuat', component: VuaphapthuatComponent, title: "MYLIFE | Vua Pháp Thuật"},
	{path: 'chatai', component: ChatAIComponent, title: "MYLIFE | CHAT - AI"},
	{path: 'coup', component: CoupComponent, title: "MYLIFE | Cờ Úp", canActivate: [AuthGuard]},
	{path: 'cotuong', component: CotuongComponent, title: "MYLIFE | Cờ Tướng", canActivate: [AuthGuard]},
	{path: 'caro', component: CaroComponent, title: "MYLIFE | Caro"},

	{path: 'about', component: AboutComponent},
	{path: 'team', component: TeamComponent},
	{path: 'how-it-works', component: HowItWorksComponent},
	{path: 'categories', component: CategoriesComponent},
	{path: 'listing', component: ListingComponent, title: "MYLIFE | LISTING"},
	{path: 'listing/:id', component: DetailComponent},
	{path: 'listing-details', component: ListingDetailsComponent},
	{path: 'blog', component: BlogComponent},
	{path: 'blog-details/:id', component: BlogDetailsComponent},
	{path: 'product-list', component: ProductListComponent},
	{path: 'products-details', component: ProductsDetailsComponent},
	{path: 'cart', component: CartComponent},
	{path: 'checkout', component: CheckoutComponent},
	{path: 'faqs', component: FaqComponent},
	{path: 'error', component: ErrorComponent},
	{path: 'coming-soon', component: ComingSoonComponent},
	{path: 'login', component: LoginComponent},
	{path: 'register', component: RegisterComponent},
	{path: 'contact', component: ContactComponent},
	{
		path: 'admin-dashboard',
		loadChildren: () => AdminModule
	},
	{path: '**', component: ErrorComponent}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {}