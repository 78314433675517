<app-header-one></app-header-one>

<div class="page-title-area page-title-bg4">
    <div class="container">
        <div class="page-title-content">
            <h2>Blog Right Sidebar</h2>
        </div>
    </div>
</div>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/blog-details-one">
                                    <img src="assets/img/blog/img1.jpg" alt="image">
                                </a>
                                <div class="date">
                                    <span>January 29, 2020</span>
                                </div>
                            </div>

                            <div class="post-content">
                                <span class="category">Ideas</span>
                                <h3><a routerLink="/blog-details-one">Top 50 places to eat in Milwaukee</a></h3>
                                <a routerLink="/blog-details-one" class="details-btn">Read Story</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/blog-details-one">
                                    <img src="assets/img/blog/img2.jpg" alt="image">
                                </a>
                                <div class="date">
                                    <span>January 29, 2020</span>
                                </div>
                            </div>

                            <div class="post-content">
                                <span class="category">Advice</span>
                                <h3><a routerLink="/blog-details-one">28 places to visit in the USA</a></h3>
                                <a routerLink="/blog-details-one" class="details-btn">Read Story</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/blog-details-one">
                                    <img src="assets/img/blog/img3.jpg" alt="image">
                                </a>
                                <div class="date">
                                    <span>January 29, 2020</span>
                                </div>
                            </div>

                            <div class="post-content">
                                <span class="category">Social</span>
                                <h3><a routerLink="/blog-details-one">08 things to remember in Morning</a></h3>
                                <a routerLink="/blog-details-one" class="details-btn">Read Story</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/blog-details-one">
                                    <img src="assets/img/blog/img4.jpg" alt="image">
                                </a>
                                <div class="date">
                                    <span>January 29, 2020</span>
                                </div>
                            </div>

                            <div class="post-content">
                                <span class="category">Boy</span>
                                <h3><a routerLink="/blog-details-one">The best coffee shops in Bangkok</a></h3>
                                <a routerLink="/blog-details-one" class="details-btn">Read Story</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/blog-details-one">
                                    <img src="assets/img/blog/img5.jpg" alt="image">
                                </a>
                                <div class="date">
                                    <span>January 29, 2020</span>
                                </div>
                            </div>

                            <div class="post-content">
                                <span class="category">Platform</span>
                                <h3><a routerLink="/blog-details-one">Top 10 business location in USA</a></h3>
                                <a routerLink="/blog-details-one" class="details-btn">Read Story</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/blog-details-one">
                                    <img src="assets/img/blog/img6.jpg" alt="image">
                                </a>
                                <div class="date">
                                    <span>January 29, 2020</span>
                                </div>
                            </div>

                            <div class="post-content">
                                <span class="category">Shipping</span>
                                <h3><a routerLink="/blog-details-one">16 sweetest summer sky in London</a></h3>
                                <a routerLink="/blog-details-one" class="details-btn">Read Story</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/blog-details-one">
                                    <img src="assets/img/blog/img7.jpg" alt="image">
                                </a>
                                <div class="date">
                                    <span>January 29, 2020</span>
                                </div>
                            </div>

                            <div class="post-content">
                                <span class="category">Platform</span>
                                <h3><a routerLink="/blog-details-one">Best destination for travelers in Iceland</a></h3>
                                <a routerLink="/blog-details-one" class="details-btn">Read Story</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/blog-details-one">
                                    <img src="assets/img/blog/img8.jpg" alt="image">
                                </a>
                                <div class="date">
                                    <span>January 29, 2020</span>
                                </div>
                            </div>

                            <div class="post-content">
                                <span class="category">Shipping</span>
                                <h3><a routerLink="/blog-details-one">4 magnificent parks of England’s Ancient</a></h3>
                                <a routerLink="/blog-details-one" class="details-btn">Read Story</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="pagination-area text-center">
                            <a routerLink="/blog-four" class="prev page-numbers"><i class='bx bx-chevron-left'></i></a>
                            <span class="page-numbers current" aria-current="page">1</span>
                            <a routerLink="/blog-four" class="page-numbers">2</a>
                            <a routerLink="/blog-four" class="page-numbers">3</a>
                            <a routerLink="/blog-four" class="page-numbers">4</a>
                            <a routerLink="/blog-four" class="page-numbers">5</a>
                            <a routerLink="/blog-four" class="next page-numbers"><i class='bx bx-chevron-right'></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <section class="widget widget_search">
                        <form class="search-form">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class="bx bx-search-alt"></i></button>
                        </form>
                    </section>

                    <section class="widget widget_louise_posts_thumb">
                        <h3 class="widget-title">Popular Posts</h3>

                        <article class="item">
                            <a routerLink="/blog-details-one" class="thumb">
                                <span class="fullimage cover bg1" role="img"></span>
                            </a>
                            <div class="info">
                                <span>June 10, 2020</span>
                                <h4 class="title usmall"><a routerLink="/blog-details-one">Ultimate Bali Guide + Where to stay in Bali 2020</a></h4>
                            </div>

                            <div class="clear"></div>
                        </article>

                        <article class="item">
                            <a routerLink="/blog-details-one" class="thumb">
                                <span class="fullimage cover bg2" role="img"></span>
                            </a>
                            <div class="info">
                                <span>June 21, 2020</span>
                                <h4 class="title usmall"><a routerLink="/blog-details-one">Live the Island life: 20 unique Islands to visit in 2020</a></h4>
                            </div>

                            <div class="clear"></div>
                        </article>

                        <article class="item">
                            <a routerLink="/blog-details-one" class="thumb">
                                <span class="fullimage cover bg3" role="img"></span>
                            </a>
                            <div class="info">
                                <span>June 30, 2020</span>
                                <h4 class="title usmall"><a routerLink="/blog-details-one">Best Places to Visit in Europe this Autumn & Winter</a></h4>
                            </div>

                            <div class="clear"></div>
                        </article>
                    </section>

                    <section class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>

                        <ul>
                            <li><a routerLink="/">Design <span class="post-count">(03)</span></a></li>
                            <li><a routerLink="/">Lifestyle <span class="post-count">(05)</span></a></li>
                            <li><a routerLink="/">Script <span class="post-count">(10)</span></a></li>
                            <li><a routerLink="/">Device <span class="post-count">(08)</span></a></li>
                            <li><a routerLink="/">Tips <span class="post-count">(01)</span></a></li>
                        </ul>
                    </section>

                    <section class="widget widget_tag_cloud">
                        <h3 class="widget-title">Braike Tags</h3>

                        <div class="tagcloud">
                            <a routerLink="/">Business <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/">Design <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/">Braike <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Fashion <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Travel <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Smart <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Marketing <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Tips <span class="tag-link-count"> (2)</span></a>
                        </div>
                    </section>

                    <section class="widget widget_instagram">
                        <h3 class="widget-title">Instagram</h3>

                        <ul>
                            <li>
                                <a href="#" class="d-block">
                                    <img src="assets/img/blog/img1.jpg" alt="image">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="d-block">
                                    <img src="assets/img/blog/img2.jpg" alt="image">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="d-block">
                                    <img src="assets/img/blog/img3.jpg" alt="image">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="d-block">
                                    <img src="assets/img/blog/img4.jpg" alt="image">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="d-block">
                                    <img src="assets/img/blog/img5.jpg" alt="image">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="d-block">
                                    <img src="assets/img/blog/img6.jpg" alt="image">
                                </a>
                            </li>
                        </ul>
                    </section>

                    <section class="widget widget_contact">
                        <div class="text">
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <span>Emergency</span>
                            <a href="tel:+098798768753">+0987-9876-8753</a>
                        </div>
                    </section>
                </aside>
            </div>
        </div>
    </div>
</section>