import { Component, OnInit } from '@angular/core';
import { TSONLINENPC_PART1 } from 'src/app/data/tsonline.data';
import { TSONLINENPC_PART2 } from 'src/app/data/tsonline_part2.data';
import { TSONLINENPC_PART3 } from 'src/app/data/tsonline_part3.data';

@Component({
  selector: 'app-ts',
  templateUrl: './ts.component.html',
  styleUrls: ['./ts.component.scss']
})
export class TsComponent implements OnInit {

  currentDate = Date.now()
  items: any
  itemsPopular: any
  categories: any
  tags: any
  MAX: any
  startIndex: any
  total: any
  nameSearch: any
  datasource = [...TSONLINENPC_PART1, ...TSONLINENPC_PART2, ...TSONLINENPC_PART3]
  data = this.datasource.filter(c => c.ten_npc != undefined)
  imageDefault = "assets/img/blog/img1.jpg"
  constructor() { }

  ngOnInit(): void {
    this.items = this.data;
    for(var i = 0; i < this.items.length; i++){
      var item = this.items[i];
      var category = "tsonline";
      var subCategory = item.thanh ? item.thanh.replace("/", "-") : "others";
      var species = item.ten_map ? item.ten_map.replace("/", "-") : "others";
      if(!item.backgroundImages){
        item.backgroundImages = ["assets/img/blog/img1.jpg"];
      }
      item.backgroundImages[0] = "assets/img/" + category + "/" + subCategory + "/" + species + "/" + item.ten_npc + ".png";
    }
    this.total = this.items.length;
    this.MAX = 8;
    this.startIndex = 0;
    this.getPopular();
    this.getCategories();
    this.getTags();
  }

  handleMissingImage(event: Event) {
    (event.target as HTMLImageElement).src = this.imageDefault;
  }

  getCategories() {
    this.categories = [];
    for (var i = 0; i < this.data.length; i++) {
      var item = this.data[i];
      if(item.thanh){
        var flag = true;
        if (this.categories.length > 0) {
          var cate = this.categories.filter((c: { name: string; }) => c.name == item.thanh);
          if (cate.length > 0) {
            cate[0].total++;
            flag = false;
          }
        }

        if (flag) {
          this.categories.push({
            name: item.thanh,
            total: 1
          })
        }
      }
    }
  }
  getTags() {
    this.tags = [];
    for (var i = 0; i < this.data.length; i++) {
      var item = this.data[i];
      var flag_element = true;
      if (this.tags.length > 0) {
        var elementTemp = this.tags.filter((c: { name: string; }) => c.name == item.ten_map);
        if (elementTemp.length > 0) {
          elementTemp[0].total++;
          flag_element = false;
        }
      }

      if (flag_element && item.ten_map) {
        this.tags.push({
          name: item.ten_map,
          total: 1
        })
      }
    }
  }
  getPopular() {
    this.itemsPopular = [];
    for (var i = 0; i < 3 && i < this.total; i++) {
      var PopularIndex = Math.floor(Math.random() * (this.total - 1));
      this.itemsPopular.push(this.data[PopularIndex])
    }
  }

  onSearch() {
    this.items = [];
    for (var i = 0; i < this.data.length; i++) {
      var item = this.data[i];
      if (this.nameSearch && this.nameSearch != '') {
        if ( (item.ten_npc && item.ten_npc.toLowerCase().indexOf(this.nameSearch.toLowerCase()) > -1)
          || (item.thanh && item.thanh.toLowerCase().indexOf(this.nameSearch.toLowerCase()) > -1)
          || (item.ten_map && item.ten_map.toLowerCase().indexOf(this.nameSearch.toLowerCase()) > -1)) {
          this.items.push(item);
        }
      }
    }
  }
}
