import { Component } from '@angular/core';
import { CaroService } from 'src/app/services/caro.service';

@Component({
  selector: 'app-caro',
  templateUrl: './caro.component.html',
  styleUrls: ['./caro.component.scss']
})
export class CaroComponent {
  newMessage = '';
  messageList: string[] = [];
  items: any
  itemCurrent: any
  players: any

  constructor(private caroService: CaroService) { }

  ngOnInit() {
    this.caroService.getNewMessage().subscribe((message: any) => {
      this.messageList = message;
    });
    this.caroService.getItems().subscribe((data: any) => {
      this.items = data;
    });
  }

  sendMessage() {
    this.caroService.sendMessage(this.newMessage);
    this.newMessage = '';
  }

  setActive(item: any) {
    this.caroService.setActive(item);
  }
}
