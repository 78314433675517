<div class="breadcrumb-area">
    <h1>All Listing</h1>
    <ol class="breadcrumb">
        <li class="item"><a routerLink="/admin-dashboard"><i class='bx bx-home-alt'></i></a></li>
        <li class="item">Dashboard</li>
        <li class="item">My Listing</li>
    </ol>
</div>

<section class="listing-area mb-30">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
            <button class="nav-link active" id="all-listing-tab" data-bs-toggle="tab" data-bs-target="#all-listing" type="button" role="tab" aria-controls="all-listing" aria-selected="true">All listing (20)</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="active-listing-tab" data-bs-toggle="tab" data-bs-target="#active-listing" type="button" role="tab" aria-controls="active-listing" aria-selected="false">Active listing (15)</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="pending-listing-tab" data-bs-toggle="tab" data-bs-target="#pending-listing" type="button" role="tab" aria-controls="pending-listing" aria-selected="false">Pending listing (2)</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="expires-listing-tab" data-bs-toggle="tab" data-bs-target="#expires-listing" type="button" role="tab" aria-controls="expires-listing" aria-selected="false">Expires listing (3)</button>
        </li>
    </ul>
    <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="all-listing" role="tabpanel">
            <div class="row">
                <div class="col-lg-4 col-sm-12 col-md-6">
                    <div class="single-listing-item">
                        <div class="listing-image">
                            <a routerLink="listing-details" class="d-block"><img src="assets/img/listing/img5.jpg" alt="image"></a>
        
                            <div class="listing-tag">
                                <a routerLink="" class="d-block">Restaurant</a>
                            </div>
        
                            <div class="listing-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
        
                                <div class="rating-total">
                                    5.0 (1 reviews)
                                </div>
                            </div>
                        </div>
        
                        <div class="listing-content">
                            <div class="listing-author d-flex align-items-center">
                                <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Andro Smith</span>
                            </div>
        
                            <h3><a routerLink="listing-details" class="d-inline-block">Farmis Garden Hotel & Restaurant</a></h3>
        
                            <span class="location"><i class="bx bx-map"></i> 40 Journal Square, NG USA</span>
                        </div>
        
                        <div class="listing-box-footer">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="price">
                                    <span data-bs-toggle="tooltip" data-placement="top" title="Ultra Hight">
                                        $1500 - $2000
                                    </span>
                                </div>
        
                                <div class="listing-option-list">
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="Find Directions"><i class='bx bx-directions'></i></a>
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="Save"><i class='bx bx-heart'></i></a>
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="On the Map"><i class='bx bx-map'></i></a>
                                </div>
                            </div>
                        </div>
        
                        <div class="listing-badge">Open!</div>
        
                        <div class="listing-edit-delete-option">
                            <div class="d-flex justify-content-between align-items-center">
                                <a routerLink="" class="btn btn-md btn-primary">Edit Listing</a>
                                <a routerLink="" class="btn btn-md btn-danger ml-3">Delete Listing</a>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div class="col-lg-4 col-sm-12 col-md-6">
                    <div class="single-listing-item">
                        <div class="listing-image">
                            <a routerLink="listing-details" class="d-block"><img src="assets/img/listing/img6.jpg" alt="image"></a>
        
                            <div class="listing-tag">
                                <a routerLink="" class="d-block">Shopping</a>
                            </div>
        
                            <div class="listing-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
        
                                <div class="rating-total">
                                    5.0 (1 reviews)
                                </div>
                            </div>
                        </div>
        
                        <div class="listing-content">
                            <div class="listing-author d-flex align-items-center">
                                <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Andro Smith</span>
                            </div>
        
                            <h3><a routerLink="listing-details" class="d-inline-block">Skyview Shopping Complex Center</a></h3>
        
                            <span class="location"><i class="bx bx-map"></i> 55 County Laois, Ireland</span>
                        </div>
        
                        <div class="listing-box-footer">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="price">
                                    <span data-bs-toggle="tooltip" data-placement="top" title="Pricey">
                                        $100 - $200
                                    </span>
                                </div>
        
                                <div class="listing-option-list">
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="Find Directions"><i class='bx bx-directions'></i></a>
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="Save"><i class='bx bx-heart'></i></a>
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="On the Map"><i class='bx bx-map'></i></a>
                                </div>
                            </div>
                        </div>
        
                        <div class="listing-badge">Open!</div>
        
                        <div class="listing-edit-delete-option">
                            <div class="d-flex justify-content-between align-items-center">
                                <a routerLink="" class="btn btn-md btn-primary">Edit Listing</a>
                                <a routerLink="" class="btn btn-md btn-danger ml-3">Delete Listing</a>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div class="col-lg-4 col-sm-12 col-md-6">
                    <div class="single-listing-item">
                        <div class="listing-image">
                            <a routerLink="listing-details" class="d-block"><img src="assets/img/listing/img7.jpg" alt="image"></a>
        
                            <div class="listing-tag">
                                <a routerLink="" class="d-block">Gym</a>
                            </div>
        
                            <div class="listing-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
        
                                <div class="rating-total">
                                    4.5 (2 reviews)
                                </div>
                            </div>
                        </div>
        
                        <div class="listing-content">
                            <div class="listing-author d-flex align-items-center">
                                <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Andro Smith</span>
                            </div>
        
                            <h3><a routerLink="listing-details" class="d-inline-block">Power House Gym Training Center</a></h3>
        
                            <span class="location"><i class="bx bx-map"></i> Tilt Tilbury, United Kingdom</span>
                        </div>
        
                        <div class="listing-box-footer">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="price">
                                    <span data-bs-toggle="tooltip" data-placement="top" title="Moderate">
                                        $240 - $250
                                    </span>
                                </div>
        
                                <div class="listing-option-list">
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="Find Directions"><i class='bx bx-directions'></i></a>
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="Save"><i class='bx bx-heart'></i></a>
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="On the Map"><i class='bx bx-map'></i></a>
                                </div>
                            </div>
                        </div>
        
                        <div class="listing-badge">Open!</div>
        
                        <div class="listing-edit-delete-option">
                            <div class="d-flex justify-content-between align-items-center">
                                <a routerLink="" class="btn btn-md btn-primary">Edit Listing</a>
                                <a routerLink="" class="btn btn-md btn-danger ml-3">Delete Listing</a>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div class="col-lg-4 col-sm-12 col-md-6">
                    <div class="single-listing-item">
                        <div class="listing-image">
                            <a routerLink="listing-details" class="d-block"><img src="assets/img/listing/img8.jpg" alt="image"></a>
        
                            <div class="listing-tag">
                                <a routerLink="" class="d-block">Restaurant</a>
                            </div>
        
                            <div class="listing-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
        
                                <div class="rating-total">
                                    5.0 (1 reviews)
                                </div>
                            </div>
                        </div>
        
                        <div class="listing-content">
                            <div class="listing-author d-flex align-items-center">
                                <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Andro Smith</span>
                            </div>
        
                            <h3><a routerLink="listing-details" class="d-inline-block">The Magician Restaurant in USA</a></h3>
        
                            <span class="location"><i class="bx bx-map"></i> 40 Journal Square, NG USA</span>
                        </div>
        
                        <div class="listing-box-footer">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="price">
                                    <span data-bs-toggle="tooltip" data-placement="top" title="Ultra Hight">
                                        $1500 - $2000
                                    </span>
                                </div>
        
                                <div class="listing-option-list">
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="Find Directions"><i class='bx bx-directions'></i></a>
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="Save"><i class='bx bx-heart'></i></a>
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="On the Map"><i class='bx bx-map'></i></a>
                                </div>
                            </div>
                        </div>
        
                        <div class="listing-badge">Open!</div>
        
                        <div class="listing-edit-delete-option">
                            <div class="d-flex justify-content-between align-items-center">
                                <a routerLink="" class="btn btn-md btn-primary">Edit Listing</a>
                                <a routerLink="" class="btn btn-md btn-danger ml-3">Delete Listing</a>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div class="col-lg-4 col-sm-12 col-md-6">
                    <div class="single-listing-item">
                        <div class="listing-image">
                            <a routerLink="listing-details" class="d-block"><img src="assets/img/listing/img9.jpg" alt="image"></a>
        
                            <div class="listing-tag">
                                <a routerLink="" class="d-block">Shopping</a>
                            </div>
        
                            <div class="listing-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
        
                                <div class="rating-total">
                                    5.0 (1 reviews)
                                </div>
                            </div>
                        </div>
        
                        <div class="listing-content">
                            <div class="listing-author d-flex align-items-center">
                                <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Andro Smith</span>
                            </div>
        
                            <h3><a routerLink="listing-details" class="d-inline-block">New Oriental Shopping Center</a></h3>
        
                            <span class="location"><i class="bx bx-map"></i> 55 County Laois, Ireland</span>
                        </div>
        
                        <div class="listing-box-footer">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="price">
                                    <span data-bs-toggle="tooltip" data-placement="top" title="Pricey">
                                        $100 - $200
                                    </span>
                                </div>
        
                                <div class="listing-option-list">
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="Find Directions"><i class='bx bx-directions'></i></a>
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="Save"><i class='bx bx-heart'></i></a>
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="On the Map"><i class='bx bx-map'></i></a>
                                </div>
                            </div>
                        </div>
        
                        <div class="listing-badge closed">Closed!</div>
        
                        <div class="listing-edit-delete-option">
                            <div class="d-flex justify-content-between align-items-center">
                                <a routerLink="" class="btn btn-md btn-primary">Edit Listing</a>
                                <a routerLink="" class="btn btn-md btn-danger ml-3">Delete Listing</a>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div class="col-lg-4 col-sm-12 col-md-6">
                    <div class="single-listing-item">
                        <div class="listing-image">
                            <a routerLink="listing-details" class="d-block"><img src="assets/img/listing/img10.jpg" alt="image"></a>
        
                            <div class="listing-tag">
                                <a routerLink="" class="d-block">Gym</a>
                            </div>
        
                            <div class="listing-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
        
                                <div class="rating-total">
                                    4.5 (2 reviews)
                                </div>
                            </div>
                        </div>
        
                        <div class="listing-content">
                            <div class="listing-author d-flex align-items-center">
                                <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Andro Smith</span>
                            </div>
        
                            <h3><a routerLink="listing-details" class="d-inline-block">Looks Women's Fitness Center</a></h3>
        
                            <span class="location"><i class="bx bx-map"></i> Tilt Tilbury, United Kingdom</span>
                        </div>
        
                        <div class="listing-box-footer">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="price">
                                    <span data-bs-toggle="tooltip" data-placement="top" title="Moderate">
                                        $240 - $250
                                    </span>
                                </div>
        
                                <div class="listing-option-list">
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="Find Directions"><i class='bx bx-directions'></i></a>
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="Save"><i class='bx bx-heart'></i></a>
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="On the Map"><i class='bx bx-map'></i></a>
                                </div>
                            </div>
                        </div>
        
                        <div class="listing-badge">Open!</div>
                        
                        <span class="listing-discount">
                            <span>-15% OFF</span>
                        </span>
        
                        <div class="listing-edit-delete-option">
                            <div class="d-flex justify-content-between align-items-center">
                                <a routerLink="" class="btn btn-md btn-primary">Edit Listing</a>
                                <a routerLink="" class="btn btn-md btn-danger ml-3">Delete Listing</a>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="pagination-area text-center">
                        <a routerLink="my-listing" class="prev page-numbers"><i class='bx bx-chevrons-left'></i></a>
                        <span class="page-numbers current" aria-current="page">1</span>
                        <a routerLink="my-listing" class="page-numbers">2</a>
                        <a routerLink="my-listing" class="page-numbers">3</a>
                        <a routerLink="my-listing" class="page-numbers">...</a>
                        <a routerLink="my-listing" class="page-numbers">5</a>
                        <a routerLink="my-listing" class="next page-numbers"><i class='bx bx-chevrons-right'></i></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="active-listing" role="tabpanel">
            <div class="row">
                <div class="col-lg-4 col-sm-12 col-md-6">
                    <div class="single-listing-item">
                        <div class="listing-image">
                            <a routerLink="listing-details" class="d-block"><img src="assets/img/listing/img8.jpg" alt="image"></a>
        
                            <div class="listing-tag">
                                <a routerLink="" class="d-block">Restaurant</a>
                            </div>
        
                            <div class="listing-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
        
                                <div class="rating-total">
                                    5.0 (1 reviews)
                                </div>
                            </div>
                        </div>
        
                        <div class="listing-content">
                            <div class="listing-author d-flex align-items-center">
                                <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Andro Smith</span>
                            </div>
        
                            <h3><a routerLink="listing-details" class="d-inline-block">The Magician Restaurant in USA</a></h3>
        
                            <span class="location"><i class="bx bx-map"></i> 40 Journal Square, NG USA</span>
                        </div>
        
                        <div class="listing-box-footer">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="price">
                                    <span data-bs-toggle="tooltip" data-placement="top" title="Ultra Hight">
                                        $1500 - $2000
                                    </span>
                                </div>
        
                                <div class="listing-option-list">
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="Find Directions"><i class='bx bx-directions'></i></a>
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="Save"><i class='bx bx-heart'></i></a>
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="On the Map"><i class='bx bx-map'></i></a>
                                </div>
                            </div>
                        </div>
        
                        <div class="listing-badge">Open!</div>
        
                        <div class="listing-edit-delete-option">
                            <div class="d-flex justify-content-between align-items-center">
                                <a routerLink="" class="btn btn-md btn-primary">Edit Listing</a>
                                <a routerLink="" class="btn btn-md btn-danger ml-3">Delete Listing</a>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div class="col-lg-4 col-sm-12 col-md-6">
                    <div class="single-listing-item">
                        <div class="listing-image">
                            <a routerLink="listing-details" class="d-block"><img src="assets/img/listing/img9.jpg" alt="image"></a>
        
                            <div class="listing-tag">
                                <a routerLink="" class="d-block">Shopping</a>
                            </div>
        
                            <div class="listing-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
        
                                <div class="rating-total">
                                    5.0 (1 reviews)
                                </div>
                            </div>
                        </div>
        
                        <div class="listing-content">
                            <div class="listing-author d-flex align-items-center">
                                <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Andro Smith</span>
                            </div>
        
                            <h3><a routerLink="listing-details" class="d-inline-block">New Oriental Shopping Center</a></h3>
        
                            <span class="location"><i class="bx bx-map"></i> 55 County Laois, Ireland</span>
                        </div>
        
                        <div class="listing-box-footer">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="price">
                                    <span data-bs-toggle="tooltip" data-placement="top" title="Pricey">
                                        $100 - $200
                                    </span>
                                </div>
        
                                <div class="listing-option-list">
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="Find Directions"><i class='bx bx-directions'></i></a>
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="Save"><i class='bx bx-heart'></i></a>
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="On the Map"><i class='bx bx-map'></i></a>
                                </div>
                            </div>
                        </div>
        
                        <div class="listing-badge closed">Closed!</div>
        
                        <div class="listing-edit-delete-option">
                            <div class="d-flex justify-content-between align-items-center">
                                <a routerLink="" class="btn btn-md btn-primary">Edit Listing</a>
                                <a routerLink="" class="btn btn-md btn-danger ml-3">Delete Listing</a>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div class="col-lg-4 col-sm-12 col-md-6">
                    <div class="single-listing-item">
                        <div class="listing-image">
                            <a routerLink="listing-details" class="d-block"><img src="assets/img/listing/img10.jpg" alt="image"></a>
        
                            <div class="listing-tag">
                                <a routerLink="" class="d-block">Gym</a>
                            </div>
        
                            <div class="listing-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
        
                                <div class="rating-total">
                                    4.5 (2 reviews)
                                </div>
                            </div>
                        </div>
        
                        <div class="listing-content">
                            <div class="listing-author d-flex align-items-center">
                                <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Andro Smith</span>
                            </div>
        
                            <h3><a routerLink="listing-details" class="d-inline-block">Looks Women's Fitness Center</a></h3>
        
                            <span class="location"><i class="bx bx-map"></i> Tilt Tilbury, United Kingdom</span>
                        </div>
        
                        <div class="listing-box-footer">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="price">
                                    <span data-bs-toggle="tooltip" data-placement="top" title="Moderate">
                                        $240 - $250
                                    </span>
                                </div>
        
                                <div class="listing-option-list">
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="Find Directions"><i class='bx bx-directions'></i></a>
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="Save"><i class='bx bx-heart'></i></a>
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="On the Map"><i class='bx bx-map'></i></a>
                                </div>
                            </div>
                        </div>
        
                        <div class="listing-badge">Open!</div>
                        
                        <span class="listing-discount">
                            <span>-15% OFF</span>
                        </span>
        
                        <div class="listing-edit-delete-option">
                            <div class="d-flex justify-content-between align-items-center">
                                <a routerLink="" class="btn btn-md btn-primary">Edit Listing</a>
                                <a routerLink="" class="btn btn-md btn-danger ml-3">Delete Listing</a>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-4 col-sm-12 col-md-6">
                    <div class="single-listing-item">
                        <div class="listing-image">
                            <a routerLink="listing-details" class="d-block"><img src="assets/img/listing/img5.jpg" alt="image"></a>
        
                            <div class="listing-tag">
                                <a routerLink="" class="d-block">Restaurant</a>
                            </div>
        
                            <div class="listing-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
        
                                <div class="rating-total">
                                    5.0 (1 reviews)
                                </div>
                            </div>
                        </div>
        
                        <div class="listing-content">
                            <div class="listing-author d-flex align-items-center">
                                <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Andro Smith</span>
                            </div>
        
                            <h3><a routerLink="listing-details" class="d-inline-block">Farmis Garden Hotel & Restaurant</a></h3>
        
                            <span class="location"><i class="bx bx-map"></i> 40 Journal Square, NG USA</span>
                        </div>
        
                        <div class="listing-box-footer">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="price">
                                    <span data-bs-toggle="tooltip" data-placement="top" title="Ultra Hight">
                                        $1500 - $2000
                                    </span>
                                </div>
        
                                <div class="listing-option-list">
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="Find Directions"><i class='bx bx-directions'></i></a>
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="Save"><i class='bx bx-heart'></i></a>
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="On the Map"><i class='bx bx-map'></i></a>
                                </div>
                            </div>
                        </div>
        
                        <div class="listing-badge">Open!</div>
        
                        <div class="listing-edit-delete-option">
                            <div class="d-flex justify-content-between align-items-center">
                                <a routerLink="" class="btn btn-md btn-primary">Edit Listing</a>
                                <a routerLink="" class="btn btn-md btn-danger ml-3">Delete Listing</a>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div class="col-lg-4 col-sm-12 col-md-6">
                    <div class="single-listing-item">
                        <div class="listing-image">
                            <a routerLink="listing-details" class="d-block"><img src="assets/img/listing/img6.jpg" alt="image"></a>
        
                            <div class="listing-tag">
                                <a routerLink="" class="d-block">Shopping</a>
                            </div>
        
                            <div class="listing-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
        
                                <div class="rating-total">
                                    5.0 (1 reviews)
                                </div>
                            </div>
                        </div>
        
                        <div class="listing-content">
                            <div class="listing-author d-flex align-items-center">
                                <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Andro Smith</span>
                            </div>
        
                            <h3><a routerLink="listing-details" class="d-inline-block">Skyview Shopping Complex Center</a></h3>
        
                            <span class="location"><i class="bx bx-map"></i> 55 County Laois, Ireland</span>
                        </div>
        
                        <div class="listing-box-footer">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="price">
                                    <span data-bs-toggle="tooltip" data-placement="top" title="Pricey">
                                        $100 - $200
                                    </span>
                                </div>
        
                                <div class="listing-option-list">
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="Find Directions"><i class='bx bx-directions'></i></a>
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="Save"><i class='bx bx-heart'></i></a>
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="On the Map"><i class='bx bx-map'></i></a>
                                </div>
                            </div>
                        </div>
        
                        <div class="listing-badge">Open!</div>
        
                        <div class="listing-edit-delete-option">
                            <div class="d-flex justify-content-between align-items-center">
                                <a routerLink="" class="btn btn-md btn-primary">Edit Listing</a>
                                <a routerLink="" class="btn btn-md btn-danger ml-3">Delete Listing</a>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div class="col-lg-4 col-sm-12 col-md-6">
                    <div class="single-listing-item">
                        <div class="listing-image">
                            <a routerLink="listing-details" class="d-block"><img src="assets/img/listing/img7.jpg" alt="image"></a>
        
                            <div class="listing-tag">
                                <a routerLink="" class="d-block">Gym</a>
                            </div>
        
                            <div class="listing-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
        
                                <div class="rating-total">
                                    4.5 (2 reviews)
                                </div>
                            </div>
                        </div>
        
                        <div class="listing-content">
                            <div class="listing-author d-flex align-items-center">
                                <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Andro Smith</span>
                            </div>
        
                            <h3><a routerLink="listing-details" class="d-inline-block">Power House Gym Training Center</a></h3>
        
                            <span class="location"><i class="bx bx-map"></i> Tilt Tilbury, United Kingdom</span>
                        </div>
        
                        <div class="listing-box-footer">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="price">
                                    <span data-bs-toggle="tooltip" data-placement="top" title="Moderate">
                                        $240 - $250
                                    </span>
                                </div>
        
                                <div class="listing-option-list">
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="Find Directions"><i class='bx bx-directions'></i></a>
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="Save"><i class='bx bx-heart'></i></a>
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="On the Map"><i class='bx bx-map'></i></a>
                                </div>
                            </div>
                        </div>
        
                        <div class="listing-badge">Open!</div>
        
                        <div class="listing-edit-delete-option">
                            <div class="d-flex justify-content-between align-items-center">
                                <a routerLink="" class="btn btn-md btn-primary">Edit Listing</a>
                                <a routerLink="" class="btn btn-md btn-danger ml-3">Delete Listing</a>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="pagination-area text-center">
                        <a routerLink="my-listing" class="prev page-numbers"><i class='bx bx-chevrons-left'></i></a>
                        <span class="page-numbers current" aria-current="page">1</span>
                        <a routerLink="my-listing" class="page-numbers">2</a>
                        <a routerLink="my-listing" class="page-numbers">3</a>
                        <a routerLink="my-listing" class="page-numbers">...</a>
                        <a routerLink="my-listing" class="page-numbers">5</a>
                        <a routerLink="my-listing" class="next page-numbers"><i class='bx bx-chevrons-right'></i></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="pending-listing" role="tabpanel">
            <div class="row">
                <div class="col-lg-4 col-sm-12 col-md-6">
                    <div class="single-listing-item">
                        <div class="listing-image">
                            <a routerLink="listing-details" class="d-block"><img src="assets/img/listing/img5.jpg" alt="image"></a>
        
                            <div class="listing-tag">
                                <a routerLink="" class="d-block">Restaurant</a>
                            </div>
        
                            <div class="listing-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
        
                                <div class="rating-total">
                                    5.0 (1 reviews)
                                </div>
                            </div>
                        </div>
        
                        <div class="listing-content">
                            <div class="listing-author d-flex align-items-center">
                                <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Andro Smith</span>
                            </div>
        
                            <h3><a routerLink="listing-details" class="d-inline-block">Farmis Garden Hotel & Restaurant</a></h3>
        
                            <span class="location"><i class="bx bx-map"></i> 40 Journal Square, NG USA</span>
                        </div>
        
                        <div class="listing-box-footer">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="price">
                                    <span data-bs-toggle="tooltip" data-placement="top" title="Ultra Hight">
                                        $1500 - $2000
                                    </span>
                                </div>
        
                                <div class="listing-option-list">
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="Find Directions"><i class='bx bx-directions'></i></a>
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="Save"><i class='bx bx-heart'></i></a>
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="On the Map"><i class='bx bx-map'></i></a>
                                </div>
                            </div>
                        </div>
        
                        <div class="listing-badge">Open!</div>
        
                        <div class="listing-edit-delete-option">
                            <div class="d-flex justify-content-between align-items-center">
                                <a routerLink="" class="btn btn-md btn-primary">Edit Listing</a>
                                <a routerLink="" class="btn btn-md btn-danger ml-3">Delete Listing</a>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div class="col-lg-4 col-sm-12 col-md-6">
                    <div class="single-listing-item">
                        <div class="listing-image">
                            <a routerLink="listing-details" class="d-block"><img src="assets/img/listing/img6.jpg" alt="image"></a>
        
                            <div class="listing-tag">
                                <a routerLink="" class="d-block">Shopping</a>
                            </div>
        
                            <div class="listing-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
        
                                <div class="rating-total">
                                    5.0 (1 reviews)
                                </div>
                            </div>
                        </div>
        
                        <div class="listing-content">
                            <div class="listing-author d-flex align-items-center">
                                <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Andro Smith</span>
                            </div>
        
                            <h3><a routerLink="listing-details" class="d-inline-block">Skyview Shopping Complex Center</a></h3>
        
                            <span class="location"><i class="bx bx-map"></i> 55 County Laois, Ireland</span>
                        </div>
        
                        <div class="listing-box-footer">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="price">
                                    <span data-bs-toggle="tooltip" data-placement="top" title="Pricey">
                                        $100 - $200
                                    </span>
                                </div>
        
                                <div class="listing-option-list">
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="Find Directions"><i class='bx bx-directions'></i></a>
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="Save"><i class='bx bx-heart'></i></a>
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="On the Map"><i class='bx bx-map'></i></a>
                                </div>
                            </div>
                        </div>
        
                        <div class="listing-badge">Open!</div>
        
                        <div class="listing-edit-delete-option">
                            <div class="d-flex justify-content-between align-items-center">
                                <a routerLink="" class="btn btn-md btn-primary">Edit Listing</a>
                                <a routerLink="" class="btn btn-md btn-danger ml-3">Delete Listing</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="expires-listing" role="tabpanel">
            <div class="row">
                <div class="col-lg-4 col-sm-12 col-md-6">
                    <div class="single-listing-item">
                        <div class="listing-image">
                            <a routerLink="listing-details" class="d-block"><img src="assets/img/listing/img5.jpg" alt="image"></a>
        
                            <div class="listing-tag">
                                <a routerLink="" class="d-block">Restaurant</a>
                            </div>
        
                            <div class="listing-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
        
                                <div class="rating-total">
                                    5.0 (1 reviews)
                                </div>
                            </div>
                        </div>
        
                        <div class="listing-content">
                            <div class="listing-author d-flex align-items-center">
                                <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Andro Smith</span>
                            </div>
        
                            <h3><a routerLink="listing-details" class="d-inline-block">Farmis Garden Hotel & Restaurant</a></h3>
        
                            <span class="location"><i class="bx bx-map"></i> 40 Journal Square, NG USA</span>
                        </div>
        
                        <div class="listing-box-footer">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="price">
                                    <span data-bs-toggle="tooltip" data-placement="top" title="Ultra Hight">
                                        $1500 - $2000
                                    </span>
                                </div>
        
                                <div class="listing-option-list">
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="Find Directions"><i class='bx bx-directions'></i></a>
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="Save"><i class='bx bx-heart'></i></a>
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="On the Map"><i class='bx bx-map'></i></a>
                                </div>
                            </div>
                        </div>
        
                        <div class="listing-badge">Open!</div>
        
                        <div class="listing-edit-delete-option">
                            <div class="d-flex justify-content-between align-items-center">
                                <a routerLink="" class="btn btn-md btn-primary">Edit Listing</a>
                                <a routerLink="" class="btn btn-md btn-danger ml-3">Delete Listing</a>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div class="col-lg-4 col-sm-12 col-md-6">
                    <div class="single-listing-item">
                        <div class="listing-image">
                            <a routerLink="listing-details" class="d-block"><img src="assets/img/listing/img6.jpg" alt="image"></a>
        
                            <div class="listing-tag">
                                <a routerLink="" class="d-block">Shopping</a>
                            </div>
        
                            <div class="listing-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
        
                                <div class="rating-total">
                                    5.0 (1 reviews)
                                </div>
                            </div>
                        </div>
        
                        <div class="listing-content">
                            <div class="listing-author d-flex align-items-center">
                                <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Andro Smith</span>
                            </div>
        
                            <h3><a routerLink="listing-details" class="d-inline-block">Skyview Shopping Complex Center</a></h3>
        
                            <span class="location"><i class="bx bx-map"></i> 55 County Laois, Ireland</span>
                        </div>
        
                        <div class="listing-box-footer">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="price">
                                    <span data-bs-toggle="tooltip" data-placement="top" title="Pricey">
                                        $100 - $200
                                    </span>
                                </div>
        
                                <div class="listing-option-list">
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="Find Directions"><i class='bx bx-directions'></i></a>
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="Save"><i class='bx bx-heart'></i></a>
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="On the Map"><i class='bx bx-map'></i></a>
                                </div>
                            </div>
                        </div>
        
                        <div class="listing-badge">Open!</div>
        
                        <div class="listing-edit-delete-option">
                            <div class="d-flex justify-content-between align-items-center">
                                <a routerLink="" class="btn btn-md btn-primary">Edit Listing</a>
                                <a routerLink="" class="btn btn-md btn-danger ml-3">Delete Listing</a>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div class="col-lg-4 col-sm-12 col-md-6">
                    <div class="single-listing-item">
                        <div class="listing-image">
                            <a routerLink="listing-details" class="d-block"><img src="assets/img/listing/img7.jpg" alt="image"></a>
        
                            <div class="listing-tag">
                                <a routerLink="" class="d-block">Gym</a>
                            </div>
        
                            <div class="listing-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
        
                                <div class="rating-total">
                                    4.5 (2 reviews)
                                </div>
                            </div>
                        </div>
        
                        <div class="listing-content">
                            <div class="listing-author d-flex align-items-center">
                                <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Andro Smith</span>
                            </div>
        
                            <h3><a routerLink="listing-details" class="d-inline-block">Power House Gym Training Center</a></h3>
        
                            <span class="location"><i class="bx bx-map"></i> Tilt Tilbury, United Kingdom</span>
                        </div>
        
                        <div class="listing-box-footer">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="price">
                                    <span data-bs-toggle="tooltip" data-placement="top" title="Moderate">
                                        $240 - $250
                                    </span>
                                </div>
        
                                <div class="listing-option-list">
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="Find Directions"><i class='bx bx-directions'></i></a>
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="Save"><i class='bx bx-heart'></i></a>
                                    <a routerLink="" data-bs-toggle="tooltip" data-placement="top" title="On the Map"><i class='bx bx-map'></i></a>
                                </div>
                            </div>
                        </div>
        
                        <div class="listing-badge">Open!</div>
        
                        <div class="listing-edit-delete-option">
                            <div class="d-flex justify-content-between align-items-center">
                                <a routerLink="" class="btn btn-md btn-primary">Edit Listing</a>
                                <a routerLink="" class="btn btn-md btn-danger ml-3">Delete Listing</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>