<app-header></app-header>

<div class="page-title-area page-title-afkarena">
    <div class="container">
        <div class="page-title-content">
            <h2>AFK ARENA - Câu đố</h2>
        </div>
    </div>
</div>

<section class="listing-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="listing-filter-options">
                    <div class="row align-items-center">
                        <div class="col-lg-4">
                            <p>Hiển Thị 1 – {{MAX}} của {{CAUDO.length}} CÂU ĐỐ</p>
                        </div>
                        <div class="col-lg-8">
                            <form class="nav-search-form" (submit)="onSearch()">
                                <input type="text" class="form-control" placeholder="Tìm kiếm..." [(ngModel)]="nameSearch" [ngModelOptions]="{standalone: true}">
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12" *ngFor="let item of items">
                <div class="single-listing-box">
                    <a class="listing-image">
                        <img src="assets/img/afkCAUDO_bg.png" alt="image">
                    </a>

                    <div class="listing-badge">ngày thứ {{item.day}}</div>

                    <div class="listing-content">
                        <div class="content">
                            <div class="author">
                                <img src="assets/img/default/admin.jpg" alt="image">
                                <span>{{item.result}}</span>
                            </div>

                            <h3><a>{{item.title}}</a></h3>
                        </div>

                        <div class="footer-content">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="listing-option-list">
                                    <a title="Find Directions"><i class='bx bx-directions'></i></a>
                                    <a title="Save"><i class='bx bx-heart'></i></a>
                                    <a title="On the Map"><i class='bx bx-map'></i></a>
                                </div>

                                <div class="price-level">
                                    <span data-bs-toggle="tooltip" data-placement="top" title="Ultra Hight">
                                        {{item.result}}
                                    </span>
                                </div>

                                <div class="listing-category">
                                    <a data-bs-toggle="tooltip" data-placement="top" title="Category">
                                        <i class='bx bx-wine'></i>
                                        CÂU ĐỐ
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>