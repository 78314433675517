<app-header></app-header>

<div class="page-title-area" style="background-image: url(assets/img/vuaphapthuat/bgdTop.jpg);">
    <div class="container">
        <div class="page-title-content">
            <h2>Vua Pháp Thuật</h2>
        </div>
    </div>
</div>

<section class="team-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12" *ngFor="let item of items">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/vuaphapthuat/{{item.img}}_{{item.gender}}.png" class="shadow" alt="{{item.name}}">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="member-content">
                        <h3>{{item.name}}</h3>
                        <span>{{item.type}}</span>
                        <p>{{item.description}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-video></app-video>

<app-feedback></app-feedback>