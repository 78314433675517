import { Component, OnInit } from '@angular/core';
import { ReReviewsService } from 'src/app/services/reviews.service';

@Component({
  selector: 'app-admin-review',
  templateUrl: './admin-review.component.html',
  styleUrls: ['./admin-review.component.scss']
})
export class AdminReviewComponent implements OnInit {

  items: any
  isLoading = false
  constructor(private api: ReReviewsService) { }

  ngOnInit(): void {
    this.fetch()
  }

  fetch(){
    this.isLoading = true;
    this.api.SEARCH({}).subscribe((res:any)=> {
      this.items = res.results;
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
    })
  }

}
