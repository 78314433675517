import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReInvoiceService {

  domain = environment.ENDPOINT
  constructor(private httpClient: HttpClient) { }

  GETALL(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.domain}/api/invoice`,
      {
        headers: { "Authorization": environment.Authorization.readOnly }
      }).pipe(
        tap({ next: (data) => data, error: (error) => error })
      );
  }
  SEARCH(body: any): Observable<any[]> {
    return this.httpClient.post<any[]>(`${this.domain}/api/invoice/search`, body,
      {
        headers: { "Authorization": environment.Authorization.readOnly }
      }).pipe(
        tap({ next: (data) => data, error: (error) => error })
      );
  }
  CREATE(body: any): Observable<any[]> {
    return this.httpClient.post<any[]>(`${this.domain}/api/invoice`, body,
      {
        headers: { "Authorization": environment.Authorization.create }
      }).pipe(
        tap({ next: (data) => data, error: (error) => error })
      );
  }
  UPDATE(body: any, id: any): Observable<any[]> {
    return this.httpClient.put<any[]>(`${this.domain}/api/invoice/${id}`, body,
      {
        headers: { "Authorization": environment.Authorization.edit }
      }).pipe(
        tap({ next: (data) => data, error: (error) => error })
      );
  }
  DELETE(id: any): Observable<any[]> {
    return this.httpClient.delete<any[]>(`${this.domain}/api/invoice/${id}`,
      {
        headers: { "Authorization": environment.Authorization.edit }
      }).pipe(
        tap({ next: (data) => data, error: (error) => error })
      );
  }
}
