import { Component, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LISTING } from '../data/listing.data';
import { RealEstateService } from '../services/realestate.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isLogin: any
  username: any
  email: any

  settings = LISTING.Settings
  languageSelect = "Eng"
  constructor(private jwtHelper: JwtHelperService, private api: RealEstateService) { }

  ngOnInit() {
    this.setAuth();
  }

  setAuth() {
    const token = localStorage.getItem('token');
    if (token && token != '') {
      this.api.getUser({ token, fields: "token username email" }).subscribe((res: any) => {
        if (res.results && res.results.length > 0) {
          var user = res.results[0];
          var isTokenExpired = this.jwtHelper.isTokenExpired(token);
          if (!isTokenExpired) {
            this.isLogin = true;
            this.username = user.username;
            this.email = user.email;
          }
        }
      })
    }
  }

  classApplied = false;
  toggleClass() {
    this.classApplied = !this.classApplied;
  }
}
