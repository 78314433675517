<div *ngFor="let message of messageList">
    <li>
        {{message}}
    </li>
</div>
<input [(ngModel)]="newMessage" (keyup)="$event.keyCode == 13 && sendMessage()" />
<button (click)="sendMessage()">Send Message</button>

<table>
    <tr *ngFor="let row of items">
        <td *ngFor="let item of row">
            <button class="item" (click)="setActive(item)" [disabled]="item.elm"
                [ngClass]="{'odd': item.elm == 'odd', 'even': item.elm == 'even', 'itemCurrent': item.isCurrent}">
                {{item.label}}
            </button>
        </td>
    </tr>
</table>