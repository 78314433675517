<div class="breadcrumb-area">
    <h1>Add Listing</h1>
    <ol class="breadcrumb">
        <li class="item"><a routerLink="/admin-dashboard"><i class='bx bx-home-alt'></i></a></li>
        <li class="item">Dashboard</li>
        <li class="item">Listing</li>
    </ol>
</div>
<form [formGroup]="detailForm" (submit)="onSubmit()" *ngIf="!isLoading">
    <div class="add-listing-box mb-30">
        <h3>Basic Informations</h3>

        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label><i class='bx bx-briefcase-alt'></i> Listing Title:</label>
                    <input type="text" class="form-control" placeholder="Name of your business" formControlName="title">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bx-duplicate'></i> Type / Category:</label>
                    <ng-select [items]="SUBCATEGORY" [searchable]="true" placeholder="Select a Category"
                        formControlName="subCategory" bindLabel="name" bindValue="value">
                    </ng-select>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bx-duplicate'></i> Status:</label>
                    <ng-select [items]="STATUS" placeholder="Select a Status" formControlName="status" bindLabel="label"
                        bindValue="value">
                    </ng-select>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label><i class='bx bxs-key'></i> Keywords:</label>
                    <input type="text" class="form-control" placeholder="Maximum 15 , should be separated by commas"
                        formControlName="keywords">
                </div>
            </div>
        </div>
    </div>

    <div class="add-listing-box mb-30">
        <h3>Location / Contacts</h3>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bx-right-arrow-alt'></i> Longitude (Drag marker on the map):</label>
                    <input type="text" class="form-control" placeholder="Map Longitude" formControlName="longitude">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bx-down-arrow-alt'></i> Latitude (Drag marker on the map):</label>
                    <input type="text" class="form-control" placeholder="Map Latitude" formControlName="latitude">
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label><i class='bx bxs-map'></i> Maps:</label>
                    <input type="text" class="form-control" placeholder="Ember Maps Longitude & Latitude"
                        formControlName="maps" (change)="setMaps()">
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <div class="map" *ngIf="item.maps">
                        <iframe [src]="item.maps | safe"></iframe>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label><i class='bx bx-menu-alt-left'></i> City / Location:</label>
                    <ng-select [items]="CITY" [searchable]="true" placeholder="Select a City" formControlName="city"
                        bindLabel="name" bindValue="value">
                    </ng-select>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bx-location-plus'></i> Address:</label>
                    <input type="text" class="form-control" placeholder="Address of your business"
                        formControlName="address">
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bx-envelope'></i> Email Address:</label>
                    <input type="text" class="form-control" placeholder="androsmith@gmail.com" formControlName="email">
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bx-phone-call'></i> Phone:</label>
                    <input type="text" class="form-control" placeholder="+(123) 9876 0154" formControlName="phone">
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bx-globe'></i> Website:</label>
                    <input type="text" class="form-control" placeholder="EnvyTheme.com" formControlName="website">
                </div>
            </div>
        </div>
    </div>

    <div class="add-listing-box mb-30">
        <h3>Header Media</h3>

        <div class="row">
            <div class="col-lg-4 col-md-4">
                <div class="form-group">
                    <div class="add-list-media-header">
                        <label class="radio">
                            <input type="radio" name="header-media" value="background-image" checked
                                formControlName="headerMedia" name="headerMedia" [checked]="item.headerMedia =='background-image'">
                            <span>Background Image</span>
                        </label>
                    </div>
                    <img [src]="item.headerMediaBGImage" />
                    <div class="add-list-media-wrap">
                        <div class="text">
                            <span>
                                <i class='bx bx-image-add'></i>
                                Click here or drop files to upload
                            </span>
                        </div>
                        <input type="file" class="upload">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-4">
                <div class="form-group">
                    <div class="add-list-media-header">
                        <label class="radio">
                            <input type="radio" name="header-media" value="carousel-image" formControlName="headerMedia"
                                name="headerMedia" [checked]="item.headerMedia =='carousel-image'">
                            <span>Carousel Image</span>
                        </label>
                    </div>
                    <img [src]="item.headerMediaCIImage" />
                    <div class="add-list-media-wrap">
                        <div class="text">
                            <span>
                                <i class='bx bx-image-add'></i>
                                Click here or drop files to upload
                            </span>
                        </div>
                        <input type="file" class="upload">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-4">
                <div class="form-group">
                    <div class="add-list-media-header">
                        <label class="radio">
                            <input type="radio" name="header-media" value="video" formControlName="headerMedia"
                                name="headerMedia" [checked]="item.headerMedia =='header-media'">
                            <span>Video</span>
                        </label>
                    </div>

                    <div class="form-group">
                        <label><i class='bx bxl-youtube'></i> Youtube:</label>
                        <input type="text" class="form-control" placeholder="https://www.youtube.com/"
                            formControlName="headerMediaVideoYoutube">
                    </div>

                    <div class="form-group">
                        <label><i class='bx bxl-vimeo'></i> Vimeo:</label>
                        <input type="text" class="form-control" placeholder="https://vimeo.com/"
                            formControlName="headerMediaVideoVimeo">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="add-listing-box mb-30">
        <h3>Details</h3>

        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label><i class='bx bx-text'></i> Text:</label>
                    <textarea cols="30" rows="7" class="form-control" placeholder="Details..."
                        formControlName="detail"></textarea>
                </div>
            </div>
        </div>
    </div>

    <div class="add-listing-box mb-30">
        <h3>Price</h3>

        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label><i class='bx bx-purchase-tag'></i> Price:</label>
                    <input type="text" class="form-control" placeholder="$542" formControlName="price">
                </div>
            </div>
        </div>
    </div>

    <div class="add-listing-box mb-30">
        <h3>Facilities</h3>

        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <ul class="facilities-list">
                        <li>
                            <label class="checkbox">
                                <input type="checkbox" name="facilities-list" value="free-wifi" checked
                                    formControlName="facilitiesFreeWiFi">
                                <span>Free WiFi</span>
                            </label>
                        </li>
                        <li>
                            <label class="checkbox">
                                <input type="checkbox" name="facilities-list" value="parking"
                                    formControlName="facilitiesParking">
                                <span>Parking</span>
                            </label>
                        </li>
                        <li>
                            <label class="checkbox">
                                <input type="checkbox" name="facilities-list" value="fitness-center" checked
                                    formControlName="facilitiesFitnessCenter">
                                <span>Fitness Center</span>
                            </label>
                        </li>
                        <li>
                            <label class="checkbox">
                                <input type="checkbox" name="facilities-list" value="non-smoking-rooms"
                                    formControlName="facilitiesNonSmokingRooms">
                                <span>Non-smoking Rooms</span>
                            </label>
                        </li>
                        <li>
                            <label class="checkbox">
                                <input type="checkbox" name="facilities-list" value="airport-shuttle" checked
                                    formControlName="facilitiesAirportShuttle">
                                <span>Airport Shuttle</span>
                            </label>
                        </li>
                        <li>
                            <label class="checkbox">
                                <input type="checkbox" name="facilities-list" value="air-conditioning"
                                    formControlName="facilitiesAirConditioning">
                                <span>Air Conditioning</span>
                            </label>
                        </li>
                        <li>
                            <label class="checkbox">
                                <input type="checkbox" name="facilities-list" value="events"
                                    formControlName="facilitiesEvents">
                                <span>Events</span>
                            </label>
                        </li>
                        <li>
                            <label class="checkbox">
                                <input type="checkbox" name="facilities-list" value="friendly-workspace"
                                    formControlName="facilitiesFriendlyWorkspace">
                                <span>Friendly Workspace</span>
                            </label>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="add-listing-box mb-30">
        <h3>Content Widgets</h3>

        <div class="row">
            <div class="col-lg-4 col-md-4">
                <div class="form-group">
                    <div class="add-list-content-widgets">
                        <label class="checkbox">
                            <input type="checkbox" name="content-widgets" value="promo-video" checked
                                formControlName="contentWidgetsPromovideo">
                            <span>Promo video</span>
                        </label>
                    </div>

                    <div class="form-group">
                        <label><i class='bx bxl-youtube'></i> Youtube:</label>
                        <input type="text" class="form-control" placeholder="https://www.youtube.com/"
                            formControlName="contentWidgetsPromovideoYoutube">
                    </div>

                    <div class="form-group">
                        <label><i class='bx bxl-vimeo'></i> Vimeo:</label>
                        <input type="text" class="form-control" placeholder="https://vimeo.com/"
                            formControlName="contentWidgetsPromovideoVimeo">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-4">
                <div class="form-group">
                    <div class="add-list-content-widgets">
                        <label class="checkbox">
                            <input type="checkbox" name="content-widgets" value="gallery-thumbnails" checked
                                formControlName="contentWidgetsGalleryThumbnails">
                            <span>Gallery Thumbnails</span>
                        </label>
                    </div>
                    <img [src]="item.contentWidgetsGalleryThumbnailsImage" />
                    <div class="add-list-media-wrap">
                        <div class="text">
                            <span>
                                <i class='bx bx-image-add'></i>
                                Click here or drop files to upload
                            </span>
                        </div>
                        <input type="file" class="upload">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-4">
                <div class="form-group">
                    <div class="add-list-content-widgets">
                        <label class="checkbox">
                            <input type="checkbox" name="content-widgets" value="slider-image" checked
                                formControlName="contentWidgetsSlider">
                            <span>Slider</span>
                        </label>
                    </div>
                    <img [src]="item.contentWidgetsSliderImage" />
                    <div class="add-list-media-wrap">
                        <div class="text">
                            <span>
                                <i class='bx bx-image-add'></i>
                                Click here or drop files to upload
                            </span>
                        </div>
                        <input type="file" class="upload">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="add-listing-box mb-30">
        <h3>Sidebar Widgets</h3>

        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <div class="sidebar-widgets">
                        <div class="box">
                            <span class="title">1. Booking Form</span>
                            <label class="switch">
                                <input type="checkbox" formControlName="sidebarWidgetsBookingForm">
                                <span></span>
                            </label>
                        </div>

                        <div class="box">
                            <span class="title">2. Price Range</span>
                            <label class="switch">
                                <input type="checkbox" checked formControlName="sidebarWidgetsPriceRange">
                                <span></span>
                            </label>
                        </div>

                        <div class="box">
                            <span class="title">3. instagram</span>
                            <label class="switch">
                                <input type="checkbox" formControlName="sidebarWidgetsInstagram">
                                <span></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label><i class='bx bx-cloud'></i> Api Key:</label>
                    <input type="text" class="form-control" placeholder="Api key" formControlName="apiKey">
                </div>
            </div>
        </div>
    </div>

    <div class="add-listing-box mb-30">
        <h3>Your Socials</h3>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bxl-facebook'></i> Facebook:</label>
                    <input type="text" class="form-control" placeholder="https://www.facebook.com/"
                        formControlName="yourSocialsFacebook">
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bxl-twitter'></i> Twitter:</label>
                    <input type="text" class="form-control" placeholder="https://twitter.com/"
                        formControlName="yourSocialsTwitter">
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bxl-linkedin'></i> Linkedin:</label>
                    <input type="text" class="form-control" placeholder="https://www.linkedin.com/"
                        formControlName="yourSocialsLinkedin">
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bxl-instagram'></i> Instagram:</label>
                    <input type="text" class="form-control" placeholder="https://instagram.com/"
                        formControlName="yourSocialsInstagram">
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <button type="submit" [disabled]="isSubmit">Submit Listing</button>
                <div class="alert alert-success" role="alert" *ngIf="messageSuccess">
                    {{messageSuccess}}
                </div>
                <div class="alert alert-danger" role="alert" *ngIf="messageError">
                    {{messageError}}
                </div>
            </div>
        </div>
    </div>
</form>