<div class="breadcrumb-area">
    <h1>Bookings Listing</h1>

    <ol class="breadcrumb">
        <li class="item"><a routerLink="/admin-dashboard"><i class='bx bx-home-alt'></i></a></li>

        <li class="item">Dashboard</li>

        <li class="item">Listing</li>
    </ol>
</div>

<div class="card bookings-listing-box mb-30">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3>Bookings List</h3>

        <div class="dropdown">
            <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
                <i class='bx bx-dots-horizontal-rounded'></i>
            </button>
            <div class="dropdown-menu">
                <a class="dropdown-item d-flex align-items-center" href="#">
                    <i class='bx bx-show'></i> View
                </a>
                <a class="dropdown-item d-flex align-items-center" href="#">
                    <i class='bx bx-edit-alt'></i> Edit
                </a>
                <a class="dropdown-item d-flex align-items-center" href="#">
                    <i class='bx bx-trash'></i> Delete
                </a>
                <a class="dropdown-item d-flex align-items-center" href="#">
                    <i class='bx bx-printer'></i> Print
                </a>
                <a class="dropdown-item d-flex align-items-center" href="#">
                    <i class='bx bx-download'></i> Download
                </a>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>Customer</th>
                        <th>Details</th>
                        <th>Action</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let item of items">
                        <td class="name"><img src="assets/img/default/admin.jpg" class="shadow" alt="image">
                            <span>{{item.user.fullname}}</span>
                        </td>
                        <td class="details">
                            <h3>{{item.title}}</h3>
                            <ul>
                                <li>
                                    <span><i class='bx bx-map'></i> Address:</span>
                                    {{item.address}}
                                </li>
                                <li>
                                    <span><i class='bx bx-map'></i> Booking Date:</span>
                                    {{item.bookingDate | date:'medium'}}
                                </li>
                                <li>
                                    <span><i class='bx bx-map'></i> Price:</span>
                                    {{item.price | currency}}
                                </li>
                                <li>
                                    <span><i class='bx bx-map'></i> Persons:</span>
                                    {{item.persons}}
                                </li>
                                <li>
                                    <span><i class='bx bx-map'></i> Phone:</span>
                                    <a href="#">{{item.phone}}</a>
                                </li>
                                <li>
                                    <span><i class='bx bx-map'></i> Email:</span>
                                    <a href="#">{{item.email}}</a>
                                </li>
                                <li>
                                    <span><i class='bx bx-map'></i> Payment State:</span>
                                    <strong>Paid</strong> using {{item.paymentState}}
                                </li>
                            </ul>
                        </td>
                        <td>
                            <a routerLink="bookings" class="btn btn-md btn-primary">Approve</a>
                            <a routerLink="bookings" class="btn btn-md btn-danger ml-3">Reject</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>