import { Component, OnInit } from '@angular/core';
import { LISTING } from 'src/app/data/listing.data';
import { ImageService } from 'src/app/services/image.service';

@Component({
  selector: 'app-admin-games',
  templateUrl: './admin-games.component.html',
  styleUrls: ['./admin-games.component.scss']
})
export class AdminGamesComponent implements OnInit {

  nameSearch: any
  currentIndex = 0
  startIndex = 0
  limit = 6
  isLoading = false;
  GameCategories = LISTING.GameCategories
  items: any = {}
  data: any
  constructor(private api: ImageService) { }

  ngOnInit(): void {
    this.fetch();
  }

  fetch() {
    this.isLoading = true;
    this.api.GETALL().subscribe((res: any) => {
      this.data = res.results;
      this.items.all = res.results;
      for (var i = 0; i < this.GameCategories.length; i++) {
        var statusValue = this.GameCategories[i].value;
        this.items[statusValue] = this.items.all.filter((c:any) => c.category == statusValue);
      }
      this.isLoading = false;
    }, err => {
      this.items = {};
      this.isLoading = false;
    });
  }

  getPaging(arr: any) {
    var pages = [];
    var count = this.currentIndex + 1;
    var limit = this.currentIndex * this.limit;
    var totalPage = arr.length - limit;
    for (var i = 0; i < totalPage; i += this.limit) {
      pages.push(count);
      count++;
    }
    return pages;
  }

  setPage(n: any, max = null) {
    if (n < 0) return;
    if (max != null && n >= Math.ceil(max / this.limit)) return;
    this.currentIndex = n;
    this.startIndex = this.limit * n;
  }

  resetPage() {
    this.startIndex = 0;
    this.currentIndex = 0;
  }

  onSearch() {
    this.items.all = this.data.filter(c => c.name.indexOf(this.nameSearch) > -1);
    for (var i = 0; i < this.GameCategories.length; i++) {
      var statusValue = this.GameCategories[i].value;
      this.items[statusValue] = this.items.all.filter(c => c.category == statusValue);
    }
  }
}
