import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LISTING } from '../data/listing.data';
declare let $: any;

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {

  items = LISTING.Latest
  relatedList: any
  item: any
  id: string;

  constructor(private route: ActivatedRoute, private titleService: Title) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.item = this.items.find(c => c._id == this.id);
    this.relatedList = this.items.filter(c => c.subCategory == this.item.subCategory && c._id != this.id)
    this.titleService.setTitle("MYLIFE | " + this.item.name);
  }

}
