import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vuaphapthuat',
  templateUrl: './vuaphapthuat.component.html',
  styleUrls: ['./vuaphapthuat.component.scss']
})
export class VuaphapthuatComponent implements OnInit {

  items: any
  constructor() { }

  ngOnInit(): void {
    this.fetchLOCAL();
  }

  fetchLOCAL(){
    this.items = [
      {
        name: "Hiệp Sĩ", img: "hiepsi", gender: "male", description: "Tấn công liên tục nhiều mục tiêu, tấn công vật lý siêu cấp", type: "Tấn Công Vật Lý"
      },
      {
        name: "Hiệp Sĩ", img: "hiepsi", gender: "female", description: "Tấn công liên tục nhiều mục tiêu, tấn công vật lý siêu cấp", type: "Tấn Công Vật Lý"
      },
      {
        name: "Xạ Thủ", img: "xathu", gender: "male", description: "Tấn công tầm xa, sát thương liên tục nhiều mục tiêu, tấn công ma pháp siêu cấp", type: "Tấn Công Ma Pháp"
      },
      {
        name: "Xạ Thủ", img: "xathu", gender: "female", description: "Tấn công tầm xa, sát thương liên tục nhiều mục tiêu, tấn công ma pháp siêu cấp", type: "Tấn Công Ma Pháp"
      },
      {
        name: "Nhạc Công", img: "nhaccong", gender: "male", description: "Khống chế, nghịch chuyển sức mạnh, tấn công ma pháp", type: "Khống Chế"
      },
      {
        name: "Nhạc Công", img: "nhaccong", gender: "female", description: "Khống chế, nghịch chuyển sức mạnh, tấn công ma pháp", type: "Khống Chế"
      },
      {
        name: "Thợ Săn", img: "thosan", gender: "male", description: "Bắt pet với tốc độ siêu hạng, tấn công vật lý", type: "Thủ Hộ"
      },
      {
        name: "Thợ Săn", img: "thosan", gender: "female", description: "Bắt pet với tốc độ siêu hạng, tấn công vật lý", type: "Thủ Hộ"
      },
      {
        name: "Danh Y", img: "danhy", gender: "male", description: "Tăng cường sức mạnh, tịnh hóa, hồi phục năng lượng", type: "Hỗ trợ"
      },
      {
        name: "Danh Y", img: "danhy", gender: "female", description: "Tăng cường sức mạnh, tịnh hóa, hồi phục năng lượng", type: "Hỗ trợ"
      },
      {
        name: "Chiến Binh", img: "chienbinh", gender: "male", description: "Phòng ngự tuyệt đối, sinh mệnh siêu cao, xả thân tấn công", type: "Đỡ đòn"
      },
      {
        name: "Chiến Binh", img: "chienbinh", gender: "female", description: "Phòng ngự tuyệt đối, sinh mệnh siêu cao, xả thân tấn công", type: "Đỡ đòn"
      },
    ]
  }
}
