import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { AppDownloadComponent } from './components/common/app-download/app-download.component';
import { FeedbackComponent } from './components/common/feedback/feedback.component';
import { VideoComponent } from './components/common/video/video.component';
import { ProcessComponent } from './components/common/process/process.component';
import { HeaderOneComponent } from './components/layouts/header-one/header-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { HeaderTwoComponent } from './components/layouts/header-two/header-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { HomeFourComponent } from './components/pages/home-four/home-four.component';
import { InstagramComponent } from './components/common/instagram/instagram.component';
import { HeaderThreeComponent } from './components/layouts/header-three/header-three.component';
import { AboutComponent } from './components/pages/about/about.component';
import { FunfactsComponent } from './components/common/funfacts/funfacts.component';
import { TeamComponent } from './components/pages/team/team.component';
import { HowItWorksComponent } from './components/pages/how-it-works/how-it-works.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { CategoriesOneComponent } from './components/pages/categories-one/categories-one.component';
import { CategoriesTwoComponent } from './components/pages/categories-two/categories-two.component';
import { ListingOneComponent } from './components/pages/listing-one/listing-one.component';
import { ListingTwoComponent } from './components/pages/listing-two/listing-two.component';
import { ListingThreeComponent } from './components/pages/listing-three/listing-three.component';
import { ListingFourComponent } from './components/pages/listing-four/listing-four.component';
import { ListingFiveComponent } from './components/pages/listing-five/listing-five.component';
import { ListingDetailsComponent } from './components/pages/listing-details/listing-details.component';
import { ProductListComponent } from './components/pages/product-list/product-list.component';
import { ProductsDetailsComponent } from './components/pages/products-details/products-details.component';
import { CartComponent } from './components/pages/cart/cart.component';
import { CheckoutComponent } from './components/pages/checkout/checkout.component';
import { BlogOneComponent } from './components/pages/blog-one/blog-one.component';
import { BlogTwoComponent } from './components/pages/blog-two/blog-two.component';
import { BlogThreeComponent } from './components/pages/blog-three/blog-three.component';
import { BlogFourComponent } from './components/pages/blog-four/blog-four.component';
import { BlogFiveComponent } from './components/pages/blog-five/blog-five.component';
import { BlogDetailsOneComponent } from './components/pages/blog-details-one/blog-details-one.component';
import { BlogDetailsTwoComponent } from './components/pages/blog-details-two/blog-details-two.component';
import { BlogDetailsThreeComponent } from './components/pages/blog-details-three/blog-details-three.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { LoginComponent } from './components/pages/login/login.component';
import { RegisterComponent } from './components/pages/register/register.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterCustomComponent } from './footer/footer.component';
import { ListingComponent } from './listing/listing.component';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';
import { AdminModule } from './admin/admin.module';
import { DetailComponent } from './detail/detail.component';
import { AfkarenaComponent } from './games/afkarena/afkarena.component';
import { CotuongComponent } from './games/cotuong/cotuong.component';
import { CoTuongService } from './games/cotuong/cotuong.service';
import { SocketioService } from './games/cotuong/socketio.service';
import { CoupComponent } from './games/coup/coup.component';
import { CategoriesComponent } from './categories/categories.component';
import { BlogComponent } from './blog/blog.component';
import { BlogDetailsComponent } from './blog-details/blog-details.component';
import { VuaphapthuatComponent } from './games/vuaphapthuat/vuaphapthuat.component';
import { TsComponent } from './games/ts/ts.component';
import { WarcraftComponent } from './games/warcraft/warcraft.component';
import { SummonerswarComponent } from './games/summonerswar/summonerswar.component';
import { OnepieceComponent } from './games/onepiece/onepiece.component';
import { CaudoComponent } from './games/afkarena/caudo/caudo.component';
import { AngularMaterialCmModule } from './angular-material-cm/angular-material-cm.module';
import { DialogSelectStat, TestComponent } from './games/summonerswar/test/test.component';
import { TrialOfAscensionComponent } from './games/summonerswar/trial-of-ascension/trial-of-ascension.component';
import { ChatAIComponent } from './chat-ai/chat-ai.component';
import { Loading3DotComponent } from './loading3-dot/loading3-dot.component';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { RunesComponent } from './games/summonerswar/test/runes/runes.component';
import { CaroComponent } from './games/caro/caro.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeOneComponent,
    FooterComponent,
    AppDownloadComponent,
    FeedbackComponent,
    VideoComponent,
    ProcessComponent,
    HeaderOneComponent,
    HomeTwoComponent,
    PartnerComponent,
    HeaderTwoComponent,
    HomeThreeComponent,
    HomeFourComponent,
    InstagramComponent,
    HeaderThreeComponent,
    AboutComponent,
    FunfactsComponent,
    TeamComponent,
    HowItWorksComponent,
    ContactComponent,
    CategoriesOneComponent,
    CategoriesTwoComponent,
    ListingOneComponent,
    ListingTwoComponent,
    ListingThreeComponent,
    ListingFourComponent,
    ListingFiveComponent,
    ListingDetailsComponent,
    ProductListComponent,
    ProductsDetailsComponent,
    CartComponent,
    CheckoutComponent,
    BlogOneComponent,
    BlogTwoComponent,
    BlogThreeComponent,
    BlogFourComponent,
    BlogFiveComponent,
    BlogDetailsOneComponent,
    BlogDetailsTwoComponent,
    BlogDetailsThreeComponent,
    FaqComponent,
    ErrorComponent,
    ComingSoonComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    HeaderComponent,
    FooterCustomComponent,
    ListingComponent,
    DetailComponent,
    AfkarenaComponent,
    CotuongComponent,
    CoupComponent,
    CategoriesComponent,
    BlogComponent,
    BlogDetailsComponent,
    VuaphapthuatComponent,
    TsComponent,
    WarcraftComponent,
    SummonerswarComponent,
    OnepieceComponent,
    CaudoComponent,
    TestComponent,
    DialogSelectStat,
    TrialOfAscensionComponent,
    ChatAIComponent,
    Loading3DotComponent,
    RunesComponent,
    CaroComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxTypedJsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AdminModule,
    BrowserAnimationsModule,
    AngularMaterialCmModule
  ],
  providers: [
    AuthGuardService, AuthGuardService, AuthService,CoTuongService,SocketioService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
