import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LISTING } from 'src/app/data/listing.data';
import { RealEstateService } from 'src/app/services/realestate.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  settings = LISTING.Settings
  isSubmit = false;
  registerForm: FormGroup;
  errorMessage: any

  get f() { return this.registerForm.controls; }

  constructor(private fb: FormBuilder, private api: RealEstateService, private router: Router) {
    this.registerForm = this.fb.group({
      fullname: ['', Validators.required],
      email: ['', Validators.required],
      username: ['', Validators.required],
      password: ['', Validators.required],
      repassword: ['', Validators.required]
    });
  }

  ngOnInit(): void {
  }

  register(){
    this.errorMessage = "";
    if (this.registerForm.invalid) {
      this.errorMessage = "Input is required";
      return;
    }
    var val = this.registerForm.value;
    if(val.password != val.repassword){
      this.errorMessage = "Password not the same";
      return;
    }
    this.isSubmit = true;
    this.api.registerUser(val).subscribe((res: any) => {
      this.isSubmit = false;
      this.router.navigate(['/login']);
    }, error => {
      this.isSubmit = false;
      this.errorMessage = error.error.message;
    })
  }

}
