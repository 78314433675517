import { Injectable } from '@angular/core';
import { DefaultEventsMap } from '@socket.io/component-emitter';
import { io, Socket } from 'socket.io-client';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocketioService {

  socket: Socket<DefaultEventsMap, DefaultEventsMap>;
  constructor() { }

  setupSocketConnection(username: any, roomId: any) {
    this.socket = io(environment.ENDPOINT);
    this.socket.emit('messageOnServer', username);
    this.socket.on('ServerSend', (data: any) => {
      console.log("Welcome " + data + " To MYLIFE");
      this.socket.emit('addUser', username);
    });
    this.socket.emit('join', roomId);
  }
  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  sendMessage = ({ message, roomName }, cb) => {
    if (this.socket) this.socket.emit('message', { message, roomName }, cb);
  }
  sendData = ({ data, prevSelected, turnIs, roomName }, cb) => {
    if (this.socket) this.socket.emit('messageWithData', { data, prevSelected, turnIs, roomName }, cb);
  }
  // Handle message receive event
  subscribeToMessages = (cb) => {
    if (!this.socket) return (true);
    this.socket.on('message', msg => {
      return cb(null, msg);
    });
  }
  subscribeToMessageWithData = (cb) => {
    if (!this.socket) return (true);
    this.socket.on('messageWithData', datasource => {
      return cb(null, datasource);
    });
  }
  subscribeToGetUser = (cb) => {
    if (!this.socket) return (true);
    this.socket.on('getUsers', res => {
      return cb(null, res);
    });
  }
}
