import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { RealEstateService } from '../services/realestate.service';

@Component({
  selector: 'app-single-item',
  templateUrl: './single-item.component.html',
  styleUrls: ['./single-item.component.scss']
})
export class SingleItemComponent implements OnInit {

  @ViewChild('closeModal') closeModal: ElementRef
  
  isDelete = false
  messageSuccess: any
  messageError: any

  @Input() item: any
  @Input() edit:any

  constructor(private api: RealEstateService) { }

  ngOnInit(): void {
  }

  deleteItem(id: any){
    this.isDelete = true;
    this.api.DELETE(id).subscribe((res: any) => {
      this.isDelete = false;
      this.messageSuccess = "Deleted";
      this.closeModal.nativeElement.click() 
    }, err => {
      this.isDelete = false;
      this.messageError = err.error.error;
    });
  }
}
