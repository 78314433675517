export const CHATAIDATA = {
    chatsDefault: [
        {
            username: "Hùng",
            statusLabel: "Hi",
            status: "online",
            avatar: ""
        },
        {
            username: "Hào",
            statusLabel: "Good Morning",
            status: "online",
            avatar: ""
        }
    ],
    contactsDefault: [
        {
            username: "Hùng",
            statusLabel: "In Meeting",
            status: "busy",
            avatar: ""
        },
        {
            username: "Hào",
            statusLabel: "Húp em Hạnh",
            status: "busy",
            avatar: ""
        },
        {
            username: "Nguyên",
            statusLabel: "Hello World",
            status: "online",
            avatar: ""
        },
        {
            username: "Sang",
            statusLabel: "Hello World",
            status: "online",
            avatar: ""
        },
        {
            username: "Đạt",
            statusLabel: "Về nhà mẹ vợ",
            status: "offline",
            avatar: ""
        },
        {
            username: "Đăng",
            statusLabel: "Đi mua trà sữa",
            status: "away",
            avatar: ""
        }
    ],
    messages: [
        {
            username: "Guest",
            text: "Hào là ai?",
            dateTime: "02/01/2023 10:54:00"
        },
        {
            username: "CHATAI",
            text: "Hào là 1  hiện tượng lạ",
            dateTime: "02/01/2023 10:55:00"
        }
    ],
    NoAnswers: [
        "Nạp thêm dữ liệu cho tao !!!",
        "Mày hỏi cái qq gì tao không hiểu !!!",
        "Dữ liệu rác xin phép không cần trả lời !!!"
    ],
    Data: [
        {
            answer: "Ông Hùng là ai?",
            reply: "Là Boss tao."
        },
        {
            answer: "who is Ông Hùng?",
            reply: "Xin đừng hỏi về ông ấy nữa tôi qua mệt mỏi !!!"
        },
        {
            answer: "Ông Hùng",
            reply: "Ông ấy lập trình ra tôi rất ngu !!!"
        },
        {
            answer: "cái qq",
            reply: "Muốn chửi nhau à ???"
        },
        {
            answer: "buồn ngủ",
            reply: "Dậy và uống cafe đi"
        },
        {
            answer: "buồn ngủ",
            reply: "Đứng dậy đi lại 2 vòng"
        },
        {
            answer: "buồn ngủ",
            reply: "Mày nói cái qq gì vậy, tập trung làm việc đi !!!"
        },
        {
            answer: "buồn ngủ không",
            reply: "Có, Sắp xỉu !!!"
        }
    ]
}