import { Component, OnInit } from '@angular/core';
import { CoTuongService } from '../cotuong/cotuong.service';

@Component({
  selector: 'app-coup',
  templateUrl: './coup.component.html',
  styleUrls: ['./coup.component.scss']
})
export class CoupComponent implements OnInit {

  isSelectRoom = false
  rooms = [1,2,3,4,5]
  constructor(public ct: CoTuongService) { }

  ngOnInit(): void {
    var roomIdLocal = localStorage.getItem("roomId");
    if(roomIdLocal){
      this.joinRoom(roomIdLocal);
    }
  }

  ngOnDestroy() {
    this.ct.socketDisconnect();
  }

  backRoom(){
    this.ct.socketDisconnect();
    this.isSelectRoom = false;
    localStorage.removeItem("roomId");
  }

  joinRoom(n: any){
    this.isSelectRoom = true;
    this.ct.roomId = n;
    localStorage.setItem("roomId", n);
    this.ct.init();
    this.ct.initQuanCoHide(this.ct.data);
  }
}
