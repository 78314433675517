<div class="breadcrumb-area">
    <h1>All Games</h1>
    <ol class="breadcrumb">
        <li class="item"><a routerLink="/admin-dashboard"><i class='bx bx-home-alt'></i></a></li>
        <li class="item">Dashboard</li>
        <li class="item">My Games</li>
    </ol>
    <form class="nav-search-form ml-auto d-md-block" (submit)="onSearch()">
        <label><i class='bx bx-search'></i></label>
        <input type="text" class="form-control" placeholder="Search here..." [(ngModel)]="nameSearch" [ngModelOptions]="{standalone: true}">
    </form>
</div>

<section class="listing-area mb-30" *ngIf="!isLoading">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation" (click)="resetPage()">
            <button class="nav-link active" id="all-listing-tab" data-bs-toggle="tab" data-bs-target="#all-listing"
                type="button" role="tab" aria-controls="all-listing" aria-selected="true">All
                ({{items.all.length}})</button>
        </li>
        <li class="nav-item" role="presentation" *ngFor="let cate of GameCategories" (click)="resetPage()">
            <button class="nav-link" id="{{cate.value}}-listing-tab" data-bs-toggle="tab"
                attr.data-bs-target="#{{cate.value}}-listing" type="button" role="tab"
                attr.aria-controls="{{cate.value}}-listing" aria-selected="false">{{cate.label}}
                ({{items[cate.value].length}})</button>
        </li>
    </ul>
    <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="all-listing" role="tabpanel">
            <div class="row">
                <div class="col-lg-4 col-sm-12 col-md-6"
                    *ngFor="let item of items.all | slice:startIndex:startIndex+limit">
                    <app-game-single-item [item]="item" edit="true"></app-game-single-item>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="admin-pagination-area text-center">
                        <a (click)="setPage(currentIndex-1)" class="prev page-numbers"><i
                                class='bx bx-chevrons-left'></i></a>
                        <a *ngFor="let p of getPaging(items.all) | slice:0:5" class="page-numbers"
                            [ngClass]="{'current': p == currentIndex+1}" (click)="setPage(p-1)">{{p}}</a>
                        <a (click)="setPage(currentIndex+1, items.all.length)" class="next page-numbers"><i
                                class='bx bx-chevrons-right'></i></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="{{cate.value}}-listing" role="tabpanel" *ngFor="let cate of GameCategories">
            <div class="row">
                <div class="col-lg-4 col-sm-12 col-md-6"
                    *ngFor="let item of items[cate.value] | slice:startIndex:startIndex+limit">
                    <app-game-single-item [item]="item" edit="true"></app-game-single-item>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="admin-pagination-area text-center">
                        <a (click)="setPage(currentIndex-1)" class="prev page-numbers"><i
                                class='bx bx-chevrons-left'></i></a>
                        <a *ngFor="let p of getPaging(items[cate.value]) | slice:0:5" class="page-numbers"
                            [ngClass]="{'current': p == currentIndex+1}" (click)="setPage(p-1)">{{p}}</a>
                        <a (click)="setPage(currentIndex+1, items[cate.value].length)" class="next page-numbers"><i
                                class='bx bx-chevrons-right'></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>