import { Component, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LISTING } from 'src/app/data/listing.data';
import { RealEstateService } from 'src/app/services/realestate.service';

@Component({
    selector: 'app-admin-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class AdminHeaderComponent implements OnInit {

    isLogin: any
    username: any
    email: any

    settings = LISTING.Settings

    constructor(private jwtHelper: JwtHelperService, private api: RealEstateService) { }

    ngOnInit(): void {
        this.setAuth();
    }

    setAuth(){
        const token = localStorage.getItem('token');
        if (token && token != '') {
            this.api.getUser({ token, fields: "token username email" }).subscribe((res: any) => {
                if (res.results && res.results.length > 0) {
                    var user = res.results[0];
                    var isTokenExpired = this.jwtHelper.isTokenExpired(token);
                    if (!isTokenExpired) {
                        this.isLogin = true;
                        this.username = user.username;
                        this.email = user.email;
                    }
                }
            })
        }
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    classApplied2 = false;
    toggleClass2() {
        this.classApplied2 = !this.classApplied2;
    }

    classApplied3 = false;
    toggleClass3() {
        this.classApplied3 = !this.classApplied3;
    }

    classApplied4 = false;
    toggleClass4() {
        this.classApplied4 = !this.classApplied4;
    }

    classApplied5 = false;
    toggleClass5() {
        this.classApplied5 = !this.classApplied5;
    }

}