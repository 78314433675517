import { Component, OnDestroy, OnInit } from '@angular/core';
import { DATA } from './cotuong.data';
import { CoTuongService } from './cotuong.service';

@Component({
  selector: 'app-cotuong',
  templateUrl: './cotuong.component.html',
  styleUrls: ['./cotuong.component.scss']
})
export class CotuongComponent implements OnInit, OnDestroy {

  isSelectRoom = false
  rooms = [1,2,3,4,5]
  constructor(public ct: CoTuongService) { }

  ngOnInit(): void {
    var roomIdLocal = localStorage.getItem("roomId");
    if(roomIdLocal){
      this.joinRoom(roomIdLocal);
    }
  }

  ngOnDestroy() {
    this.ct.socketDisconnect();
  }

  backRoom(){
    this.ct.socketDisconnect();
    this.isSelectRoom = false;
    localStorage.removeItem("roomId");
  }

  joinRoom(n: any){
    this.isSelectRoom = true;
    this.ct.roomId = n;
    localStorage.setItem("roomId", n);
    this.ct.init();
    this.ct.initQuanCoDefault(this.ct.data);
  }
  
  runMatch(){
    var dt = DATA;
    for(var i = 0; i < dt[0].data.length; i++){
      var x = dt[0].data[i].x;
      var y = dt[0].data[i].y;
      this.ct.SELECTandMOVE(this.ct.data[y][x]);
    }
  }
}
