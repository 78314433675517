<div class="sidemenu-area">
    <div class="sidemenu-header">
        <a href="/" class="navbar-brand d-flex align-items-center">
            <img src="assets/img/logo-small.png" alt="image">
            <span>MYLIFE</span>
        </a>

        <div class="burger-menu d-none d-lg-block">
            <span class="top-bar"></span>
            <span class="middle-bar"></span>
            <span class="bottom-bar"></span>
        </div>

        <div class="responsive-burger-menu d-block d-lg-none">
            <span class="top-bar"></span>
            <span class="middle-bar"></span>
            <span class="bottom-bar"></span>
        </div>
    </div>

    <div class="sidemenu-body">
        <ul class="sidemenu-nav metisMenu h-100" id="sidemenu-nav" data-simplebar>
            <li class="nav-item-title">
                Main
            </li>

            <li class="nav-item">
                <a routerLink="/admin-dashboard" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <span class="icon"><i class='bx bx-home-circle'></i></span>
                    <span class="menu-title">Dashboard</span>
                </a>
            </li>

            <li class="nav-item-title">
                Listings
            </li>

            <li class="nav-item">
                <a routerLink="listing" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <span class="icon"><i class='bx bx-map-alt'></i></span>
                    <span class="menu-title">Listings</span>
                </a>
            </li>

            <li class="nav-item">
                <a routerLink="bookings" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <span class="icon"><i class='bx bx-calendar'></i></span>
                    <span class="menu-title">Bookings</span>
                </a>
            </li>

            <li class="nav-item">
                <a routerLink="reviews" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <span class="icon"><i class='bx bx-star'></i></span>
                    <span class="menu-title">Reviews</span>
                </a>
            </li>

            <li class="nav-item">
                <a routerLink="bookmarks" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <span class="icon"><i class='bx bxs-bookmark-star'></i></span>
                    <span class="menu-title">Bookmarks</span>
                </a>
            </li>

            <li class="nav-item">
                <a routerLink="invoice" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <span class="icon"><i class='bx bx-paper-plane'></i></span>
                    <span class="menu-title">Invoice</span>
                </a>
            </li>

            <li class="nav-item">
                <a routerLink="listing/-1" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <span class="icon"><i class='bx bx-location-plus'></i></span>
                    <span class="menu-title">Add Listings</span>
                </a>
            </li>

            <li class="nav-item-title">
                Apps
            </li>

            <li class="nav-item">
                <a routerLink="games" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <span class="icon"><i class='bx bx-map-alt'></i></span>
                    <span class="menu-title">Games</span>
                </a>
            </li>
            <li class="nav-item">
                <a routerLink="app-email" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <span class="icon"><i class='bx bx-envelope'></i></span>
                    <span class="menu-title">Email</span>
                    <span class="badge">3</span>
                </a>
            </li>

            <li class="nav-item">
                <a routerLink="app-message" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <span class="icon"><i class='bx bx-message'></i></span>
                    <span class="menu-title">Message</span>
                </a>
            </li>

            <li class="nav-item">
                <a routerLink="app-todo" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <span class="icon"><i class='bx bx-badge-check'></i></span>
                    <span class="menu-title">Todo</span>
                </a>
            </li>

            <li class="nav-item">
                <a routerLink="app-calendar" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <span class="icon"><i class='bx bx-calendar'></i></span>
                    <span class="menu-title">Calendar</span>
                </a>
            </li>

            <li class="nav-item-title">
                Account
            </li>

            <li class="nav-item">
                <a routerLink="my-profile" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <span class="icon"><i class='bx bx-user-pin'></i></span>
                    <span class="menu-title">My Profile</span>
                </a>
            </li>

            <li class="nav-item">
                <a href="/login" class="nav-link" routerLinkActive="active">
                    <span class="icon"><i class='bx bx-log-out'></i></span>
                    <span class="menu-title">Logout</span>
                </a>
            </li>

            <li class="nav-item-title">
                Others
            </li>

            <li class="nav-item">
                <a href="#" class="collapsed-nav-link nav-link" aria-expanded="false">
                    <span class="icon"><i class='bx bx-menu'></i></span>
                    <span class="menu-title">Menu Levels</span>
                </a>

                <ul class="sidemenu-nav-second-level">
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">First Level</span>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a href="javascript:void(0)" class="collapsed-nav-link nav-link" aria-expanded="false">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">Second Level</span>
                        </a>

                        <ul class="sidemenu-nav-third-level">
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link">
                                    <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                                    <span class="menu-title">Second Level 2.1</span>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link">
                                    <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                                    <span class="menu-title">Second Level 2.2</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>

            <li class="nav-item">
                <a href="javascript:void(0)" class="nav-link disabled">
                    <span class="icon"><i class='bx bx-unlink'></i></span>
                    <span class="menu-title">Disable Menu</span>
                </a>
            </li>
        </ul>
    </div>
</div>