import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-download',
  templateUrl: './app-download.component.html',
  styleUrls: ['./app-download.component.scss']
})
export class AppDownloadComponent implements OnInit {

  @Input() appDownload: any
  
  constructor() { }

  ngOnInit(): void {
  }

}
