export const LISTING = {
    Settings: {
        name: "MYLIFE",
        owner: "Hung Nguyen",
        website: "https://rev2.vercel.app/",
        logo: "/assets/img/logo_white.png",
        logoblack: "/assets/img/logo_black.png",
        top_trending: "/assets/img/listing/default/Top-Real-Estate.jpg",
        page_title_bg: "page-title-hcm",
        phone: "+84 83939 0597",
        email: "nvhungits@gmail.com",
        address: "TH, TQ, TP, HCM",
        description: "mylife.com offers you best about real estate.",
        banner: [
            {
                className: "banner-banner1"
            },
            {
                className: "video-banner2",
                URL_youtube: "https://www.youtube.com/watch?v=2EX8RGOGX7Y"
            }
        ],
        language: [
            {
                name: "Eng",
                flag: "assets/img/us-flag.jpg"
            },
            {
                name: "VN",
                flag: "assets/img/vn-flag.png"
            }
        ],
        price_min: 1000,
        price_max: 1000000,
        auth: {
            loginClassBG: "login-backgroundimage",
            registerClassBG: "register-backgroundimage"
        }
    },
    Facilities: [
        {
            name: "Free WiFi",
            value: "FreeWiFi"
        },
        {
            name: "Free Parking",
            value: "FreeParking"
        },
        {
            name: "Wireless Internet",
            value: "WirelessInternet"
        },
        {
            name: "InstantBook",
            value: "InstantBook"
        },
        {
            name: "Smoking Allowed",
            value: "SmokingAllowed"
        }
    ],
    SubCategory: [
        {
            name: "Shops", icon: "bx bx-shopping-bag", value: "shops"
        },
        {
            name: "Hotels", icon: "bx bx-bed", value: "hotels"
        },
        {
            name: "Restaurants", icon: "bx bx-drink", value: "restaurants"
        },
        {
            name: "Fitness", icon: "bx bx-dumbbell", value: "fitness"
        },
        {
            name: "Events", icon: "bx bx-calendar-star", value: "events"
        },
        {
            name: "Bar", icon: "bx bx-calendar-star", value: "bar"
        },
        {
            name: "Services", value: "services"
        },
        {
            name: "Hospital", value: "hospital"
        }
    ],
    City: [
        {
            name: "Hồ Chí Minh", icon: "bx bx-shopping-bag", value: "hochiminh"
        },
        {
            name: "Hà Nội", icon: "bx bx-shopping-bag", value: "hanoi"
        },
        {
            name: "Thanh Hóa", icon: "bx bx-shopping-bag", value: "thanhhoa"
        },
    ],
    Status: [
        {
            label: "Active",
            value: "active"
        },
        {
            label: "Pending",
            value: "pending"
        },
        {
            label: "Expires",
            value: "expires"
        }
    ],
    GameCategories: [
        {
            label: "Summoners War",
            value: "summonerswar"
        },
        {
            label: "AFK Arena",
            value: "afkarena"
        },
        {
            label: "Warcraft",
            value: "warcraft"
        },
        {
            label: "One Piece",
            value: "onepiece"
        },
        {
            label: "TSOnline",
            value: "tsonline"
        },
        {
            label: "Vua Pháp Thuật",
            value: "vuaphapthuat"
        }
    ],
    GameSubCategories: [
        { "value": "heroes", "label": "Heroes" },
        { "value": "monsters", "label": "Monsters" },
        { "value": "skills", "label": "Skills" },
        { "value": "runeType", "label": "RuneType" },
        { "value": "other", "label": "Other" }
    ],
    GameStatus: [
        { "value": "new", "label": "New" },
        { "value": "open", "label": "Open" },
        { "value": "pending", "label": "Pending" },
        { "value": "closed", "label": "Closed" }
    ],
    GameSpecies: [
        { "value": "Archangel", "label": "Archangel", parent: "summonerswar" },
        { "value": "Art Master", "label": "Art Master", parent: "summonerswar" },
        { "value": "Beast Monk", "label": "Beast Monk", parent: "summonerswar" },
        { "value": "Beast Rider", "label": "Beast Rider", parent: "summonerswar" },
        { "value": "Cannon Girl", "label": "Cannon Girl", parent: "summonerswar" },
        { "value": "Chimera", "label": "Chimera", parent: "summonerswar" },
        { "value": "Demon", "label": "Demon", parent: "summonerswar" },
        { "value": "Desert Queen", "label": "Desert Queen", parent: "summonerswar" },
        { "value": "Dragon", "label": "Dragon", parent: "summonerswar" },
        { "value": "Dragon Knight", "label": "Dragon Knight", parent: "summonerswar" },
        { "value": "Druid", "label": "Druid", parent: "summonerswar" },
        { "value": "Fairy King", "label": "Fairy King", parent: "summonerswar" },
        { "value": "Hell Lady", "label": "Hell Lady", parent: "summonerswar" },
        { "value": "Homunculus", "label": "Homunculus", parent: "summonerswar" },
        { "value": "Ifrit", "label": "Ifrit", parent: "summonerswar" },
        { "value": "KEN", "label": "KEN", parent: "summonerswar" },
        { "value": "Lightning Emperor", "label": "Lightning Emperor", parent: "summonerswar" },
        { "value": "M.BISON", "label": "M.BISON" , parent: "summonerswar"},
        { "value": "Mage", "label": "Mage", parent: "summonerswar" },
        { "value": "Monkey King", "label": "Monkey King", parent: "summonerswar" },
        { "value": "Occult Girl", "label": "Occult Girl", parent: "summonerswar" },
        { "value": "Onimusha", "label": "Onimusha", parent: "summonerswar" },
        { "value": "Onmyouji", "label": "Onmyouji", parent: "summonerswar" },
        { "value": "Oracle", "label": "Oracle", parent: "summonerswar" },
        { "value": "Paladin", "label": "Paladin", parent: "summonerswar" },
        { "value": "Panda Warrior", "label": "Panda Warrior", parent: "summonerswar" },
        { "value": "Phoenix", "label": "Phoenix", parent: "summonerswar" },
        { "value": "Pioneer", "label": "Pioneer" , parent: "summonerswar"},
        { "value": "Polar Queen", "label": "Polar Queen" , parent: "summonerswar"},
        { "value": "RYU", "label": "RYU" , parent: "summonerswar"},
        { "value": "Sea Emperor", "label": "Sea Emperor", parent: "summonerswar" },
        { "value": "Shadow Claw", "label": "Shadow Claw" , parent: "summonerswar"},
        { "value": "Shadowcaster", "label": "Shadowcaster" , parent: "summonerswar"},
        { "value": "Sky Surfer", "label": "Sky Surfer" , parent: "summonerswar"},
        { "value": "Slayer", "label": "Slayer", parent: "summonerswar" },
        { "value": "Striker", "label": "Striker", parent: "summonerswar" },
        { "value": "Totemist", "label": "Totemist" , parent: "summonerswar"},
        { "value": "Unicorn", "label": "Unicorn" , parent: "summonerswar"},
        { "value": "Valkyrja", "label": "Valkyrja", parent: "summonerswar" },
        { "value": "Vampire Lord", "label": "Vampire Lord", parent: "summonerswar" },
        { "value": "Weapon Master", "label": "Weapon Master" , parent: "summonerswar"},
        { "value": "Vampire", "label": "Vampire", parent: "summonerswar" },
        { "value": "Horus", "label": "Horus" , parent: "summonerswar"},
        { "value": "Battle Angel", "label": "Battle Angel", parent: "summonerswar" },
        { "value": "Hypnomeow", "label": "Hypnomeow" , parent: "summonerswar"},
        
        { "value": "Human", "label": "Human", parent: "warcraft" },
        { "value": "Undead", "label": "Undead", parent: "warcraft" },
        { "value": "Orc", "label": "Orc" , parent: "warcraft"},
        { "value": "Night elf/elves", "label": "Night elf/elves", parent: "warcraft"},

        { "value": "Straw Hat Pirates", "label": "Straw Hat Pirates", parent: "onepiece"},
        { "value": "Pirates", "label": "Pirates", parent: "onepiece"},
        { "value": "Marines", "label": "Marines", parent: "onepiece"},
        { "value": "Nobles", "label": "Nobles", parent: "onepiece"},
        { "value": "Races and Tribes", "label": "Races and Tribes", parent: "onepiece"}

    ],
    GameType: [
        { "value": "Defense", "label": "Defense" },
        { "value": "HP", "label": "HP" },
        { "value": "Support", "label": "Support" },
        { "value": "Attack", "label": "Attack" },
        { "value": "Strength", "label": "Strength" },
        { "value": "Intelligence", "label": "Intelligence" },
        { "value": "Agility", "label": "Agility" },
        { "value": "LeaderSkill", "label": "LeaderSkill" },
        { "value": "Skill", "label": "Skill" },
        { "value": "Active", "label": "Active" },
        { "value": "Passive", "label": "Passive" },
        { "value": "Ultimate", "label": "Ultimate" },
        { "value": "Effect", "label": "Effect" },
        { "value": "Melee", "label": "Melee" },
        { "value": "Ranged", "label": "Ranged" },
        { "value": "Melee/Ranged", "label": "Melee/Ranged" }
    ],
    GameElement: [
        { "value": "Dark", "label": "Dark" },
        { "value": "Light", "label": "Light" },
        { "value": "Fire", "label": "Fire" },
        { "value": "Wind", "label": "Wind" },
        { "value": "Water", "label": "Water" }
    ],
    Latest: [
        {
            _id: "1",
            status: "active",
            latitude: "106.6265457", longitude: "10.789557", city: "hochiminh", email: "nvhungits@gmail.com",
            
            headerMedia: 'video',
            headerMediaBGImage: "/assets/img/listing/default/chill-sky.jpg",
            headerMediaCIImage: "/assets/img/listing/default/chill-sky.jpg",
            headerMediaVideoYoutube: "https://www.youtube.com/channel/UCdpysT3_T2dxsLMe3lZYJXg",
            headerMediaVideoVimeo: "https://www.youtube.com/channel/UCdpysT3_T2dxsLMe3lZYJXg",
            
            detail: "...", price: 100, priceRange: "100 - 1000", apiKey: "localhost",
            facilitiesFreeWiFi: false, facilitiesParking: false, facilitiesFitnessCenter: false,
            facilitiesNonSmokingRooms: false, facilitiesAirportShuttle: false, facilitiesAirConditioning: false,
            facilitiesEvents: false, facilitiesFriendlyWorkspace: true,
            contentWidgetsPromovideo: true,
            contentWidgetsPromovideoYoutube: "https://www.youtube.com/channel/UCdpysT3_T2dxsLMe3lZYJXg",
            contentWidgetsPromovideoVimeo: "https://www.youtube.com/channel/UCdpysT3_T2dxsLMe3lZYJXg",
            contentWidgetsGalleryThumbnails: false,
            contentWidgetsGalleryThumbnailsImage: "/assets/img/listing/default/chill-sky.jpg",
            contentWidgetsSlider: false,
            contentWidgetsSliderImage: "/assets/img/listing/default/chill-sky.jpg",
            sidebarWidgetsBookingForm: false,
            sidebarWidgetsPriceRange: false,
            sidebarWidgetsInstagram: false,
            yourSocialsFacebook: "facebook.com/nvhungits", yourSocialsTwitter: "twitter.com/nvhungits", yourSocialsLinkedin: "linkedin.com/nvhungits", yourSocialsInstagram: "instagram.com/nvhungits",
            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.53224763717!2d106.69207091530244!3d10.770486862247184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f3e866e1431%3A0x622002f1dd05ced4!2sChill%20Skybar!5e0!3m2!1sen!2s!4v1670941949224!5m2!1sen!2s",
            keywords: "hotels,bar,fitness,shops,restaurants,events,hospital,services",
            details: "Chill Skybar is a modern stylish and vibrant club and lounge bar that offers both an entertainment nightlife and a social gathering experience destination",
            title: "Chill Sky Bar",
            img: "/assets/img/listing/default/chill-sky.jpg",
            address: "AB Tower, 76A Đ. Lê Lai, Phường Bến Thành, Quận 1",
            phone: "093 882 28 38",
            website: "capellaholdings.vn",
            rate: 4.2,
            subCategory: "Bar",
            subCategory_Icon: "bx bx-wine",
            carousel_Images: ["/assets/img/listing/ChillSkyBar1.jpg", "/assets/img/listing/ChillSkyBar2.jpg", "/assets/img/listing/ChillSkyBar3.jpg", "/assets/img/listing/ChillSkyBar4.jpg", "/assets/img/listing/ChillSkyBar5.jpg"]
        },
        {
            _id: "5",
            status: "expires",
            latitude: "106.6265457", longitude: "10.789557", city: "hochiminh", email: "nvhungits@gmail.com",
           
            headerMedia: 'video',
            headerMediaBGImage: "/assets/img/listing/default/chill-sky.jpg",
            headerMediaCIImage: "/assets/img/listing/default/chill-sky.jpg",
            headerMediaVideoYoutube: "https://www.youtube.com/channel/UCdpysT3_T2dxsLMe3lZYJXg",
            headerMediaVideoVimeo: "https://www.youtube.com/channel/UCdpysT3_T2dxsLMe3lZYJXg",
            
            detail: "...", price: 100, priceRange: "100 - 1000", apiKey: "localhost",
            facilitiesFreeWiFi: false, facilitiesParking: true, facilitiesFitnessCenter: false,
            facilitiesNonSmokingRooms: false, facilitiesAirportShuttle: false, facilitiesAirConditioning: false,
            facilitiesEvents: false, facilitiesFriendlyWorkspace: true,
            contentWidgetsPromovideo: true,
            contentWidgetsPromovideoYoutube: "https://www.youtube.com/channel/UCdpysT3_T2dxsLMe3lZYJXg",
            contentWidgetsPromovideoVimeo: "https://www.youtube.com/channel/UCdpysT3_T2dxsLMe3lZYJXg",
            contentWidgetsGalleryThumbnails: false,
            contentWidgetsGalleryThumbnailsImage: "/assets/img/listing/default/chill-sky.jpg",
            contentWidgetsSlider: false,
            contentWidgetsSliderImage: "/assets/img/listing/default/chill-sky.jpg",
            sidebarWidgetsBookingForm: true,
            sidebarWidgetsPriceRange: false,
            sidebarWidgetsInstagram: false,
            yourSocialsFacebook: "facebook.com/nvhungits", yourSocialsTwitter: "twitter.com/nvhungits", yourSocialsLinkedin: "linkedin.com/nvhungits", yourSocialsInstagram: "instagram.com/nvhungits",
            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.53224763717!2d106.69207091530244!3d10.770486862247184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f3e866e1431%3A0x622002f1dd05ced4!2sChill%20Skybar!5e0!3m2!1sen!2s!4v1670941949224!5m2!1sen!2s",
            keywords: "hotels,bar,fitness,shops,restaurants,events,hospital,services",
            title: "Chill Sky Bar 2",
            img: "/assets/img/listing/default/chill-sky.jpg",
            address: "AB Tower, 76A Đ. Lê Lai, Phường Bến Thành, Quận 1",
            phone: "093 882 28 38",
            website: "capellaholdings.vn",
            rate: 4.2,
            subCategory: "Bar",
            subCategory_Icon: "bx bx-wine",
            carousel_Images: ["/assets/img/listing/ChillSkyBar1.jpg", "/assets/img/listing/ChillSkyBar2.jpg", "/assets/img/listing/ChillSkyBar3.jpg", "/assets/img/listing/ChillSkyBar4.jpg", "/assets/img/listing/ChillSkyBar5.jpg"]
        },
        {
            _id: "2",
            status: "active",
            latitude: "106.6265457", longitude: "10.789557", city: "hochiminh", email: "nvhungits@gmail.com",
           
            headerMedia: 'video',
            headerMediaBGImage: "/assets/img/listing/default/chill-sky.jpg",
            headerMediaCIImage: "/assets/img/listing/default/chill-sky.jpg",
            headerMediaVideoYoutube: "https://www.youtube.com/channel/UCdpysT3_T2dxsLMe3lZYJXg",
            headerMediaVideoVimeo: "https://www.youtube.com/channel/UCdpysT3_T2dxsLMe3lZYJXg",
            
            detail: "...", price: 100, priceRange: "100 - 1000", apiKey: "localhost",
            facilitiesFreeWiFi: false, facilitiesParking: false, facilitiesFitnessCenter: false,
            facilitiesNonSmokingRooms: false, facilitiesAirportShuttle: false, facilitiesAirConditioning: false,
            facilitiesEvents: false, facilitiesFriendlyWorkspace: true,
            contentWidgetsPromovideo: true,
            contentWidgetsPromovideoYoutube: "https://www.youtube.com/channel/UCdpysT3_T2dxsLMe3lZYJXg",
            contentWidgetsPromovideoVimeo: "https://www.youtube.com/channel/UCdpysT3_T2dxsLMe3lZYJXg",
            contentWidgetsGalleryThumbnails: false,
            contentWidgetsGalleryThumbnailsImage: "/assets/img/listing/default/chill-sky.jpg",
            contentWidgetsSlider: false,
            contentWidgetsSliderImage: "/assets/img/listing/default/chill-sky.jpg",
            sidebarWidgetsBookingForm: false,
            sidebarWidgetsPriceRange: false,
            sidebarWidgetsInstagram: false,
            yourSocialsFacebook: "facebook.com/nvhungits", yourSocialsTwitter: "twitter.com/nvhungits", yourSocialsLinkedin: "linkedin.com/nvhungits", yourSocialsInstagram: "instagram.com/nvhungits",
            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.53224763717!2d106.69207091530244!3d10.770486862247184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f3e866e1431%3A0x622002f1dd05ced4!2sChill%20Skybar!5e0!3m2!1sen!2s!4v1670941949224!5m2!1sen!2s",
            keywords: "hotels,bar,fitness,shops,restaurants,events,hospital,services",
            img: "/assets/img/listing/default/cali.jpg",
            title: "California Fitness & Yoga",
            address: "Số 126 Hùng Vương, P.12, Q.5",
            phone: "1800 6995",
            website: "cali.vn",
            rate: 4.3,
            subCategory: "Fitness",
            subCategory_Icon: "bx bx-dumbbell",
            carousel_Images: ["/assets/img/listing/ChillSkyBar1.jpg", "/assets/img/listing/ChillSkyBar2.jpg", "/assets/img/listing/ChillSkyBar3.jpg", "/assets/img/listing/ChillSkyBar4.jpg", "/assets/img/listing/ChillSkyBar5.jpg"]
        },
        {
            _id: "3",
            status: "active",
            latitude: "106.6265457", longitude: "10.789557", city: "hochiminh", email: "nvhungits@gmail.com",

            headerMedia: 'video',
            headerMediaBGImage: "/assets/img/listing/default/chill-sky.jpg",
            headerMediaCIImage: "/assets/img/listing/default/chill-sky.jpg",
            headerMediaVideoYoutube: "https://www.youtube.com/channel/UCdpysT3_T2dxsLMe3lZYJXg",
            headerMediaVideoVimeo: "https://www.youtube.com/channel/UCdpysT3_T2dxsLMe3lZYJXg",

            detail: "...", price: 100, priceRange: "100 - 1000", apiKey: "localhost",
            facilitiesFreeWiFi: false, facilitiesParking: false, facilitiesFitnessCenter: false,
            facilitiesNonSmokingRooms: false, facilitiesAirportShuttle: false, facilitiesAirConditioning: false,
            facilitiesEvents: false, facilitiesFriendlyWorkspace: true,
            contentWidgetsPromovideo: true,
            contentWidgetsPromovideoYoutube: "https://www.youtube.com/channel/UCdpysT3_T2dxsLMe3lZYJXg",
            contentWidgetsPromovideoVimeo: "https://www.youtube.com/channel/UCdpysT3_T2dxsLMe3lZYJXg",
            contentWidgetsGalleryThumbnails: false,
            contentWidgetsGalleryThumbnailsImage: "/assets/img/listing/default/chill-sky.jpg",
            contentWidgetsSlider: false,
            contentWidgetsSliderImage: "/assets/img/listing/default/chill-sky.jpg",
            sidebarWidgetsBookingForm: false,
            sidebarWidgetsPriceRange: false,
            sidebarWidgetsInstagram: false,
            yourSocialsFacebook: "facebook.com/nvhungits", yourSocialsTwitter: "twitter.com/nvhungits", yourSocialsLinkedin: "linkedin.com/nvhungits", yourSocialsInstagram: "instagram.com/nvhungits",
            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.53224763717!2d106.69207091530244!3d10.770486862247184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f3e866e1431%3A0x622002f1dd05ced4!2sChill%20Skybar!5e0!3m2!1sen!2s!4v1670941949224!5m2!1sen!2s",
            keywords: "hotels,bar,fitness,shops,restaurants,events,hospital,services",
            img: "/assets/img/listing/default/Secret-Garden.jpg",
            title: "Secret Garden Restaurant",
            address: "131 Calmette, Phường Nguyễn Thái Bình, Quận 1",
            phone: "028 3821 1288",
            website: "http://facebook.com/secretgarden.131calmette",
            rate: 4.5,
            subCategory: "Restaurants",
            subCategory_Icon: "bx bx-drink",
            carousel_Images: ["/assets/img/listing/ChillSkyBar1.jpg", "/assets/img/listing/ChillSkyBar2.jpg", "/assets/img/listing/ChillSkyBar3.jpg", "/assets/img/listing/ChillSkyBar4.jpg", "/assets/img/listing/ChillSkyBar5.jpg"]
        },
        {
            _id: "4",
            status: "pending",
            latitude: "106.6265457", longitude: "10.789557", city: "hochiminh", email: "nvhungits@gmail.com",

            headerMedia: 'video',
            headerMediaBGImage: "/assets/img/listing/default/chill-sky.jpg",
            headerMediaCIImage: "/assets/img/listing/default/chill-sky.jpg",
            headerMediaVideoYoutube: "https://www.youtube.com/channel/UCdpysT3_T2dxsLMe3lZYJXg",
            headerMediaVideoVimeo: "https://www.youtube.com/channel/UCdpysT3_T2dxsLMe3lZYJXg",

            detail: "...", price: 100, priceRange: "100 - 1000", apiKey: "localhost",
            facilitiesFreeWiFi: false, facilitiesParking: false, facilitiesFitnessCenter: false,
            facilitiesNonSmokingRooms: false, facilitiesAirportShuttle: false, facilitiesAirConditioning: false,
            facilitiesEvents: false, facilitiesFriendlyWorkspace: true,
            contentWidgetsPromovideo: true,
            contentWidgetsPromovideoYoutube: "https://www.youtube.com/channel/UCdpysT3_T2dxsLMe3lZYJXg",
            contentWidgetsPromovideoVimeo: "https://www.youtube.com/channel/UCdpysT3_T2dxsLMe3lZYJXg",
            contentWidgetsGalleryThumbnails: false,
            contentWidgetsGalleryThumbnailsImage: "/assets/img/listing/default/chill-sky.jpg",
            contentWidgetsSlider: false,
            contentWidgetsSliderImage: "/assets/img/listing/default/chill-sky.jpg",
            sidebarWidgetsBookingForm: false,
            sidebarWidgetsPriceRange: false,
            sidebarWidgetsInstagram: false,
            yourSocialsFacebook: "facebook.com/nvhungits", yourSocialsTwitter: "twitter.com/nvhungits", yourSocialsLinkedin: "linkedin.com/nvhungits", yourSocialsInstagram: "instagram.com/nvhungits",
            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.53224763717!2d106.69207091530244!3d10.770486862247184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f3e866e1431%3A0x622002f1dd05ced4!2sChill%20Skybar!5e0!3m2!1sen!2s!4v1670941949224!5m2!1sen!2s",
            keywords: "hotels,bar,fitness,shops,restaurants,events,hospital,services",
            img: "/assets/img/listing/default/yame.jpg",
            title: "YaMe Shop",
            address: "770F Sư Vạn Hạnh, Phường 12, Quận 10",
            phone: "028 7307 1441",
            website: "yame.vn",
            rate: 4.3,
            subCategory: "Shops",
            subCategory_Icon: "bx bx-shopping-bag",
            carousel_Images: ["/assets/img/listing/ChillSkyBar1.jpg", "/assets/img/listing/ChillSkyBar2.jpg", "/assets/img/listing/ChillSkyBar3.jpg", "/assets/img/listing/ChillSkyBar4.jpg", "/assets/img/listing/ChillSkyBar5.jpg"]
        }
    ],
    Destinations: [
        {
            name: "Hồ Chí Minh",
            img: "/assets/img/destinations/default/hcm.jpg"
        },
        {
            name: "Hà Nội",
            img: "/assets/img/destinations/default/hn.jpg",
            items: [
                {
                    name: "Huế",
                    img: "/assets/img/destinations/default/hue.jpg"
                },
                {
                    name: "Nha Trang",
                    img: "/assets/img/destinations/default/nt.jpg"
                }
            ]
        }
    ],
    Feedback: [
        {
            img: 'assets/img/default/admin.jpg'
        },
        {
            img: 'assets/img/default/admin.jpg'
        },
        {
            img: 'assets/img/default/admin.jpg'
        }
    ],
    AppDownload: {
        img: "assets/img/default/mobile.png"
    },
    users: [
        {
            id: 1,
            avatar: "assets/img/default/admin.jpg",
            username: "admin",
            password: "Abc@123",
            token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIzNTM0NTQzNTQzNTQzNTM0NTMiLCJleHAiOjE3MzQ2OTkyNTZ9.gHX7PV3Sfp6tqKoeLuIOoDvEUCd54wqKaNtXMXlKQ1w",
            token_expired: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIzNTM0NTQzNTQzNTQzNTM0NTMiLCJleHAiOjExMzQ2OTkyNTZ9.fJl6wnXk1dIwZOy3tg_yEjuedSYHt_FUbsbyi3AcS08"
        },
        {
            id: 2,
            avatar: "assets/img/default/hung.png",
            username: "hung",
            password: "24041990",
            token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIzNTM0NTQzNTQzNTQzNTM0NTMiLCJleHAiOjE1MDQ2OTkyNTZ9.zG-2FvGegujxoLWwIQfNB5IT46D-xC4e8dEDYwi6aRM",
            token_expired: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIzNTM0NTQzNTQzNTQzNTM0NTMiLCJleHAiOjExMzQ2OTkyNTZ9.fJl6wnXk1dIwZOy3tg_yEjuedSYHt_FUbsbyi3AcS08"
        },
        {
            id: 3,
            avatar: "assets/img/default/chang.png",
            username: "chang",
            password: "19051994",
            token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIzNTM0NTQzNTQzNTQzNTM0NTMiLCJleHAiOjE1MDQ2OTkyNTZ9.zG-2FvGegujxoLWwIQfNB5IT46D-xC4e8dEDYwi6aRM",
            token_expired: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIzNTM0NTQzNTQzNTQzNTM0NTMiLCJleHAiOjExMzQ2OTkyNTZ9.fJl6wnXk1dIwZOy3tg_yEjuedSYHt_FUbsbyi3AcS08"
        },
        {
            id: 4,
            avatar: "assets/img/default/andy.png",
            username: "andy",
            password: "04012022",
            token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIzNTM0NTQzNTQzNTQzNTM0NTMiLCJleHAiOjE1MDQ2OTkyNTZ9.zG-2FvGegujxoLWwIQfNB5IT46D-xC4e8dEDYwi6aRM",
            token_expired: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIzNTM0NTQzNTQzNTQzNTM0NTMiLCJleHAiOjExMzQ2OTkyNTZ9.fJl6wnXk1dIwZOy3tg_yEjuedSYHt_FUbsbyi3AcS08"
        }
    ]
}