import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-runes',
  templateUrl: './runes.component.html',
  styleUrls: ['./runes.component.scss']
})
export class RunesComponent implements OnInit {
  runes: any = [];
  heroes: any = [];
  constructor() { }

  ngOnInit(): void {
    this.calculateTotal();
  }

  calculateTotal(){
    this.initRunes();
    this.initheroes();

    var hp = this.heroes[0]; 
    hp.value += this.runes[5].mainValue;
    var atk = this.heroes[1]; 
    atk.value += this.runes[0].mainValue;
    var def = this.heroes[2]; 
    def.value += this.runes[4].mainValue;

    var spd = this.heroes[3];

    var criRate = this.heroes[4];
    var hascriRate = this.runes.filter(c => c.mainName == "criRate");
    if(hascriRate.length > 0){
      criRate.value += hascriRate[0].mainValue;
    }
    var criDmg = this.heroes[5];
    var hascriDmg = this.runes.filter(c => c.mainName == "criDmg");
    if(hascriDmg.length > 0){
      criDmg.value += hascriDmg[0].mainValue;
    }
    var Res = this.heroes[6];
    var hasRes = this.runes.filter(c => c.mainName == "Res");
    if(hasRes.length > 0){
      Res.value += hasRes[0].mainValue;
    }
    var Acc = this.heroes[7];
    var hasAcc = this.runes.filter(c => c.mainName == "Acc");
    if(hasAcc.length > 0){
      Acc.value += hasAcc[0].mainValue;
    }

    //Fatal
    var hasFatal = this.runes.filter(c => c.name == "Fatal").length >= 4;
    if(hasFatal){
      atk.value += Math.floor(atk.value*0.35);
    }

    //Blade
    var hasBlade = this.runes.filter(c => c.name == "Blade");
    if(hasBlade.length >= 2){
      var count = Math.floor(hasBlade.length/2);
      criRate.value += 0.15*count;
    }

    this.runes.forEach(r => {
      r.options.forEach(op => {
        if(op.mainName == "ATK%")
          atk.addition += Math.floor(atk.value*op.mainValue);
        if(op.mainName == "HP%")
          hp.addition += Math.floor(hp.value*op.mainValue);
        if(op.mainName == "DEF%")
          def.addition += Math.floor(def.value*op.mainValue);
        if(op.mainName == "SPD+")
          spd.addition += op.mainValue;
        if(op.mainName == "ATK+")
          atk.addition += op.mainValue;
        if(op.mainName == "criRate")
          criRate.addition += op.mainValue;
        if(op.mainName == "criDmg")
          criDmg.addition += op.mainValue;
      });
    });
  }

  initRunes(){
    this.runes.push({
      slot: 1,
      name: "Fatal",
      mainName: "ATK",
      mainValue: 160,
      level: 15,
      options: [
        { mainName: "ATK%", mainValue: .3, unit: "percent" },
        { mainName: "HP%", mainValue: .2, unit: "percent"},
        { mainName: "criRate", mainValue: .1, unit: "percent" },
        { mainName: "criDmg", mainValue: .2, unit: "percent" }
      ]
    });
    this.runes.push({
      slot: 2,
      name: "Fatal",
      mainName: "ATK%",
      mainValue: .63,
      level: 15,
      options: [
        { mainName: "ATK+", mainValue: 50, unit: "number" },
        { mainName: "HP%", mainValue: .2, unit: "percent"},
        { mainName: "criRate", mainValue: .1, unit: "percent" },
        { mainName: "criDmg", mainValue: .1, unit: "percent" }
      ]
    });
    this.runes.push({
      slot: 6,
      name: "Fatal",
      mainName: "ATK%",
      mainValue: .63,
      level: 15,
      options: [
        { mainName: "ATK+", mainValue: 50, unit: "number" },
        { mainName: "DEF%", mainValue: .2, unit: "percent"},
        { mainName: "criRate", mainValue: .1, unit: "percent" },
        { mainName: "criDmg", mainValue: .1, unit: "percent" }
      ]
    });
    this.runes.push({
      slot: 4,
      name: "Fatal",
      mainName: "criDmg",
      mainValue: .80,
      level: 15,
      options: [
        { mainName: "ATK%", mainValue: .3, unit: "percent" },
        { mainName: "SPD+", mainValue: 50, unit: "number"},
        { mainName: "criRate", mainValue: .1, unit: "percent" },
        { mainName: "criDmg", mainValue: .1, unit: "percent" }
      ]
    });
    this.runes.push({
      slot: 3,
      name: "Blade",
      mainName: "DEF",
      mainValue: 160,
      level: 15,
      options: [
        { mainName: "DEF%", mainValue: .3, unit: "percent" },
        { mainName: "HP%", mainValue: .3, unit: "percent"},
        { mainName: "criRate", mainValue: .1, unit: "percent" },
        { mainName: "criDmg", mainValue: .1, unit: "percent" }
      ]
    });
    this.runes.push({
      slot: 5,
      name: "Blade",
      mainName: "HP",
      mainValue: 2425,
      level: 15,
      options: [
        { mainName: "ATK%", mainValue: .3, unit: "percent" },
        { mainName: "SPD+", mainValue: 50, unit: "number"},
        { mainName: "criRate", mainValue: .1, unit: "percent" },
        { mainName: "criDmg", mainValue: .1, unit: "percent" }
      ]
    });
  }

  initheroes(){
    this.heroes.push({
      name: "HP", value: 10000, unit: "number", addition: 0
    });
    this.heroes.push({
      name: "ATK", value: 800, unit: "number", addition: 0
    });
    this.heroes.push({
      name: "DEF", value: 500, unit: "number", addition: 0
    });
    this.heroes.push({
      name: "SPD", value: 100, unit: "number", addition: 0
    });
    this.heroes.push({
      name: "Tỷ lệ CRI", value: .15, unit: "percent", addition: 0
    });
    this.heroes.push({
      name: "CRI Dmg", value: .50, unit: "percent", addition: 0
    });
    this.heroes.push({
      name: "Kháng", value: .15, unit: "percent", addition: 0
    });
    this.heroes.push({
      name: "Chính xác", value: 0, unit: "percent", addition: 0
    });
  }

  RunesType: any = [
    {
      name: "Energy", set: 2, effect: 15, type: "HP", unit: "%"
    },
    {
      name: "Fatal", set: 4, effect: 35, type: "ATK", unit: "%"
    },
    {
      name: "Blade", set: 2, effect: 15, type: "CRI_RATE", unit: "%"
    },
    {
      name: "Swift", set: 4, effect: 25, type: "SPD", unit: "%"
    },
    {
      name: "Focus", set: 2, effect: 20, type: "ACC", unit: "%"
    },
    {
      name: "Guard", set: 2, effect: 15, type: "DEF", unit: "%"
    },
    {
      name: "Endure", set: 2, effect: 20, type: "RES", unit: "%"
    },
    {
      name: "Shield", set: 2, effect: 0, type: "InBattle", unit: "", description: "Ally Shield 3 turns (15% of HP)"
    },
    {
      name: "Revenge", set: 2, effect: 0, type: "InBattle", unit: "", description: "Counterattack +15%"
    },
    {
      name: "Will", set: 2, effect: 0, type: "InBattle", unit: "", description: "Immunity +1 turn"
    },
    {
      name: "Nemesis", set: 2,effect: 0, type: "InBattle", unit: "", description: "ATK Gauge +4% (for every 7% HP lost) "
    },
    {
      name: "Vampire", set: 4, effect: 0, type: "InBattle", unit: "", description: "Life Drain +35%"
    },
    {
      name: "Destroy", set: 2, effect: 0, type: "InBattle", unit: "", description: "30% of the damage dealt will reduce up to 4% of the enemy's Max HP"
    },
    {
      name: "Despair", set: 4, effect: 0, type: "InBattle", unit: "", description: "Stun Rate +25%"
    },
    {
      name: "Violent", set: 4, effect: 0, type: "InBattle", unit: "", description: "Get Extra Turn +22%"
    },
    {
      name: "Rage", set: 4, effect: 40, type: "CRI_DMG", unit: "%"
    },
    {
      name: "Fight", set: 2, effect: 0, type: "InBattle", unit: "", description: "Allies' ATK +8%"
    },
    {
      name: "Determination", set: 2, effect: 0, type: "InBattle", unit: "", description: "Allies' DEF +8%"
    },
    {
      name: "Accuracy", set: 2, effect: 0, type: "InBattle", unit: "", description: "Allies' ACC +8%"
    },
    {
      name: "Tolerance", set: 2, effect: 0, type: "InBattle", unit: "", description: "Allies' RES +8%"
    }
  ]
}
