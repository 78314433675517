<app-header></app-header>

<div class="page-title-area page-title-bg3">
    <div class="container">
        <div class="page-title-content">
            <h2>About Us</h2>
        </div>
    </div>
</div>

<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">About Us</span>
                    <h2>Louise Trusted Online Directory Site in the World</h2>
                    <h6>Louise.com offers you flexible and responsive listing experience.</h6>
                    <p>With a <strong>Louise</strong> account, you get more than a business listing. Your free Business Profile lets you easily connect with customers across <strong>Louise</strong> Search and Maps.</p>
                    <p>Your customers are ready to connect – by calling, messaging, or leaving reviews. You can do more business.</p>

                    <div class="features-text">
                        <h5><i class='bx bx-planet'></i>Start managing your business profile on Louise today</h5>
                        <p>More than just a local listing, your free Business Profile lets you manage how your business appears on <strong>Louise</strong> Search.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about/img1.jpg" class="shadow" alt="image">
                    <img src="assets/img/about/img2.jpg" class="shadow" alt="image">
                </div>
            </div>
        </div>

        <div class="about-inner-area">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Our Story</h3>
                        <p>When you make it easy to do business, your business grows.</p>
                        
                        <ul class="features-list">
                            <li><i class='bx bx-check'></i> Connect and get results</li>
                            <li><i class='bx bx-check'></i> Keep customers up-to-date</li>
                            <li><i class='bx bx-check'></i> Get to know your followers</li>
                            <li><i class='bx bx-check'></i> We trust Louise</li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Our Values</h3>
                        <p>When you make it easy to do business, your business grows.</p>
                        
                        <ul class="features-list">
                            <li><i class='bx bx-check'></i> Learning & Support</li>
                            <li><i class='bx bx-check'></i> Claim your listing</li>
                            <li><i class='bx bx-check'></i> Find yourself</li>
                            <li><i class='bx bx-check'></i> Complete your listing profile</li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="about-text">
                        <h3>Our Promise</h3>
                        <p>When you make it easy to do business, your business grows.</p>
                        
                        <ul class="features-list">
                            <li><i class='bx bx-check'></i> Verify your listing</li>
                            <li><i class='bx bx-check'></i> Love listing again</li>
                            <li><i class='bx bx-check'></i> Online listing.</li>
                            <li><i class='bx bx-check'></i> Listing for all seasons</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-video></app-video>

<section class="team-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Team</span>
            <h2>Meet Our Team</h2>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team/img1.jpg" class="shadow" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="member-content">
                        <h3>Andy Nguyễn</h3>
                        <span>CEO & Founder</span>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team/img2.jpg" class="shadow" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="member-content">
                        <h3>Sarah Taylor</h3>
                        <span>Co-Founder</span>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team/img3.jpg" class="shadow" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="member-content">
                        <h3>Steven Smith</h3>
                        <span>Web Developer</span>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team/img4.jpg" class="shadow" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="member-content">
                        <h3>John Capabel</h3>
                        <span>Programer</span>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-funfacts></app-funfacts>

<app-partner></app-partner>

<app-feedback></app-feedback>

<app-instagram></app-instagram>