import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-email-compose',
  templateUrl: './app-email-compose.component.html',
  styleUrls: ['./app-email-compose.component.scss']
})
export class AppEmailComposeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
