<app-header></app-header>

<div class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <h2>Product List</h2>
        </div>
    </div>
</div>

<section class="products-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/products-details">
                            <img src="assets/img/products/img1.jpg" class="main-image" alt="image">
                            <img src="assets/img/products/img-hover1.jpg" class="hover-image" alt="image">
                        </a>
                    </div>

                    <div class="products-content">
                        <h3><a routerLink="/products-details">Ham Salad</a></h3>
                        <div class="price">
                            <span class="old-price">$25</span>
                            <span class="new-price">$20</span>
                        </div>
                        <div class="star-rating">
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                        </div>
                        <a routerLink="/cart" class="add-to-cart">Add to Cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/products-details">
                            <img src="assets/img/products/img2.jpg" class="main-image" alt="image">
                            <img src="assets/img/products/img-hover2.jpg" class="hover-image" alt="image">
                        </a>

                        <div class="sale-tag">Sale!</div>
                    </div>

                    <div class="products-content">
                        <h3><a routerLink="/products-details">Fresh Cappuccino</a></h3>
                        <div class="price">
                            <span class="new-price">$25</span>
                        </div>
                        <div class="star-rating">
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                        </div>
                        <a routerLink="/cart" class="add-to-cart">Add to Cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/products-details">
                            <img src="assets/img/products/img3.jpg" class="main-image" alt="image">
                            <img src="assets/img/products/img-hover3.jpg" class="hover-image" alt="image">
                        </a>
                    </div>

                    <div class="products-content">
                        <h3><a routerLink="/products-details">Honey Cake</a></h3>
                        <div class="price">
                            <span class="old-price">$15</span>
                            <span class="new-price">$11</span>
                        </div>
                        <div class="star-rating">
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                        </div>
                        <a routerLink="/cart" class="add-to-cart">Add to Cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/products-details">
                            <img src="assets/img/products/img4.jpg" class="main-image" alt="image">
                            <img src="assets/img/products/img-hover4.jpg" class="hover-image" alt="image">
                        </a>
                    </div>

                    <div class="products-content">
                        <h3><a routerLink="/products-details">Delicious Dish</a></h3>
                        <div class="price">
                            <span class="new-price">$50</span>
                        </div>
                        <div class="star-rating">
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                        </div>
                        <a routerLink="/cart" class="add-to-cart">Add to Cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/products-details">
                            <img src="assets/img/products/img5.jpg" class="main-image" alt="image">
                            <img src="assets/img/products/img-hover5.jpg" class="hover-image" alt="image">
                        </a>
                    </div>

                    <div class="products-content">
                        <h3><a routerLink="/products-details">Cooking Meat</a></h3>
                        <div class="price">
                            <span class="old-price">$105</span>
                            <span class="new-price">$100</span>
                        </div>
                        <div class="star-rating">
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                        </div>
                        <a routerLink="/cart" class="add-to-cart">Add to Cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/products-details">
                            <img src="assets/img/products/img6.jpg" class="main-image" alt="image">
                            <img src="assets/img/products/img-hover6.jpg" class="hover-image" alt="image">
                        </a>

                        <div class="new-tag">New!</div>
                    </div>

                    <div class="products-content">
                        <h3><a routerLink="/products-details">Womens Tops Color</a></h3>
                        <div class="price">
                            <span class="old-price">$150</span>
                            <span class="new-price">$100</span>
                        </div>
                        <div class="star-rating">
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                        </div>
                        <a routerLink="/cart" class="add-to-cart">Add to Cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/products-details">
                            <img src="assets/img/products/img7.jpg" class="main-image" alt="image">
                            <img src="assets/img/products/img-hover7.jpg" class="hover-image" alt="image">
                        </a>
                    </div>

                    <div class="products-content">
                        <h3><a routerLink="/products-details">Vegetable Salad</a></h3>
                        <div class="price">
                            <span class="new-price">$12</span>
                        </div>
                        <div class="star-rating">
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                        </div>
                        <a routerLink="/cart" class="add-to-cart">Add to Cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/products-details">
                            <img src="assets/img/products/img8.jpg" class="main-image" alt="image">
                            <img src="assets/img/products/img-hover8.jpg" class="hover-image" alt="image">
                        </a>
                    </div>

                    <div class="products-content">
                        <h3><a routerLink="/products-details">Person Slicing</a></h3>
                        <div class="price">
                            <span class="old-price">$105</span>
                            <span class="new-price">$100</span>
                        </div>
                        <div class="star-rating">
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                        </div>
                        <a routerLink="/cart" class="add-to-cart">Add to Cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/products-details">
                            <img src="assets/img/products/img9.jpg" class="main-image" alt="image">
                            <img src="assets/img/products/img-hover9.jpg" class="hover-image" alt="image">
                        </a>

                        <div class="new-tag">New!</div>
                    </div>

                    <div class="products-content">
                        <h3><a routerLink="/products-details">Chocolate Mousse</a></h3>
                        <div class="price">
                            <span class="old-price">$50</span>
                            <span class="new-price">$45</span>
                        </div>
                        <div class="star-rating">
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                        </div>
                        <a routerLink="/cart" class="add-to-cart">Add to Cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="pagination-area text-center">
                    <a routerLink="/product-list" class="prev page-numbers"><i class='bx bx-chevron-left'></i></a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/product-list" class="page-numbers">2</a>
                    <a routerLink="/product-list" class="page-numbers">3</a>
                    <a routerLink="/product-list" class="page-numbers">4</a>
                    <a routerLink="/product-list" class="page-numbers">5</a>
                    <a routerLink="/product-list" class="next page-numbers"><i class='bx bx-chevron-right'></i></a>
                </div>
            </div>
        </div>
    </div>
</section>