import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { io } from 'socket.io-client';

@Injectable({
  providedIn: 'root'
})
export class CaroService {

  public message$: BehaviorSubject<string> = new BehaviorSubject('');
  public items$: BehaviorSubject<string> = new BehaviorSubject('');
  public players$: BehaviorSubject<string> = new BehaviorSubject('');
  constructor() { }

  socket = io('https://caro.onrender.com');

  public sendMessage(message: any) {
    this.socket.emit('message', message);
  }
  public getNewMessage = () => {
    this.socket.on('message', (message) => {
      this.message$.next(message);
    });

    return this.message$.asObservable();
  };

  public getItems = () => {
    this.socket.on('items', (data) => {
      this.items$.next(data);
    });

    return this.items$.asObservable();
  };
  public setActive(item: any) {
    this.socket.emit('item', item);
  }
}
