<div class="breadcrumb-area">
    <h1>Invoice</h1>

    <ol class="breadcrumb">
        <li class="item"><a routerLink="/admin-dashboard"><i class='bx bx-home-alt'></i></a></li>

        <li class="item">Dashboard</li>

        <li class="item">Invoice</li>
    </ol>
</div>

<div class="card recent-orders-box mb-30">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3>Invoices Listings</h3>

        <div class="dropdown">
            <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class='bx bx-dots-horizontal-rounded' ></i>
            </button>
            <div class="dropdown-menu">
                <a class="dropdown-item d-flex align-items-center" href="#">
                    <i class='bx bx-show'></i> View
                </a>
                <a class="dropdown-item d-flex align-items-center" href="#">
                    <i class='bx bx-edit-alt'></i> Edit
                </a>
                <a class="dropdown-item d-flex align-items-center" href="#">
                    <i class='bx bx-trash'></i> Delete
                </a>
                <a class="dropdown-item d-flex align-items-center" href="#">
                    <i class='bx bx-printer'></i> Print
                </a>
                <a class="dropdown-item d-flex align-items-center" href="#">
                    <i class='bx bx-download'></i> Download
                </a>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>Invoice</th>
                        <th>Customer</th>
                        <th>Purchase On</th>
                        <th>Amount</th>
                        <th>Status</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>#6791679</td>
                        <td class="name"><img src="assets/img/default/admin.jpg" alt="image"> Atony Rony</td>
                        <td>12 May 2019</td>
                        <td>$564</td>
                        <td><span class="badge badge-primary">Received</span></td>
                    </tr>
                    <tr>
                        <td>#6791674</td>
                        <td class="name"><img src="assets/img/default/admin.jpg" alt="image"> Tom Hardy</td>
                        <td>11 May 2019</td>
                        <td>$954</td>
                        <td><span class="badge badge-success">Pending</span></td>
                    </tr>
                    <tr>
                        <td>#6791654</td>
                        <td class="name"><img src="assets/img/default/admin.jpg" alt="image"> Colin Firth</td>
                        <td>10 May 2019</td>
                        <td>$214</td>
                        <td><span class="badge badge-danger">Declined</span></td>
                    </tr>
                    <tr>
                        <td>#6791699</td>
                        <td class="name"><img src="assets/img/default/admin.jpg" alt="image"> Jude Law</td>
                        <td>09 May 2019</td>
                        <td>$120</td>
                        <td><span class="badge badge-success">Pending</span></td>
                    </tr>
                    <tr>
                        <td>#6791647</td>
                        <td class="name"><img src="assets/img/default/admin.jpg" alt="image"> Idris Elba</td>
                        <td>08 May 2019</td>
                        <td>$999</td>
                        <td><span class="badge badge-primary">Received</span></td>
                    </tr>
                    <tr>
                        <td>#6791679</td>
                        <td class="name"><img src="assets/img/default/admin.jpg" alt="image"> Atony Rony</td>
                        <td>12 May 2019</td>
                        <td>$564</td>
                        <td><span class="badge badge-primary">Received</span></td>
                    </tr>
                    <tr>
                        <td>#6791674</td>
                        <td class="name"><img src="assets/img/default/admin.jpg" alt="image"> Tom Hardy</td>
                        <td>11 May 2019</td>
                        <td>$954</td>
                        <td><span class="badge badge-success">Pending</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>