<app-header-one></app-header-one>

<div class="main-banner banner-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="main-banner-content">
            <h1>Find The Best Places To Live</h1>

            <div class="main-search-wrap">
                <form>
                    <div class="row">
                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label><i class='bx bxs-keyboard'></i></label>
                                <input type="text" placeholder="Whar are you looking for?">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="form-group">
                                <label><a routerLink="/"><i class='bx bx-current-location'></i></a></label>
                                <input type="text" placeholder="Location">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="form-group">
                                <label><i class='bx bx-slider'></i></label>
                                <select>
                                    <option>All Categories</option>
                                    <option>Shops</option>
                                    <option>Hotels</option>
                                    <option>Restaurants</option>
                                    <option>Fitness</option>
                                    <option>Events</option>
                                    <option>Services</option>
                                    <option>Other</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="main-search-btn">
                        <button type="submit">Search <i class='bx bx-search-alt'></i></button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="main-categories-box">
        <ul>
            <li>
                <a routerLink="/listing-one">
                    <span class="icon">
                        <i class='bx bx-drink'></i>
                    </span>
                    Restaurants
                </a>
            </li>

            <li>
                <a routerLink="/listing-one">
                    <span class="icon">
                        <i class='bx bx-bed'></i>
                    </span>
                    Hotels
                </a>
            </li>

            <li>
                <a routerLink="/listing-one">
                    <span class="icon">
                        <i class='bx bx-shopping-bag'></i>
                    </span>
                    Shops
                </a>
            </li>

            <li>
                <a routerLink="/listing-one">
                    <span class="icon">
                        <i class='bx bx-dumbbell'></i>
                    </span>
                    Fitness
                </a>
            </li>

            <li>
                <a routerLink="/listing-one">
                    <span class="icon">
                        <i class='bx bx-calendar-star'></i>
                    </span>
                    Events
                </a>
            </li>
        </ul>
    </div>
</div>

<section class="listing-area extra-pt pb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Discover</span>
            <h2>The Latest Listing</h2>
        </div>
    </div>
    
    <div class="container-fluid">
        <div class="listing-slides owl-carousel owl-theme">
            <div class="single-listing-box">
                <a routerLink="/listing-details" class="listing-image">
                    <img src="assets/img/listing/img1.jpg" alt="image">
                </a>

                <div class="listing-badge">Open Now</div>

                <div class="listing-content">
                    <div class="content">
                        <div class="author">
                            <img src="assets/img/default/admin.jpg" alt="image">
                            <span>Andy Nguyễn</span>
                        </div>

                        <h3><a routerLink="/listing-details">The Goggi Restaurant</a></h3>
                        <span class="location"><i class='bx bx-map'></i> 40 Journal Square, NG USA</span>

                        <div class="rating">
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <span class="rating-count">2 reviews</span>
                        </div>
                    </div>

                    <div class="footer-content">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="listing-option-list">
                                <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                            </div>

                            <div class="price-level">
                                <span data-bs-toggle="tooltip" data-placement="top" title="Ultra Hight">
                                    <strong>$</strong>
                                    <strong>$</strong>
                                    <strong>$</strong>
                                    <strong>$</strong>
                                </span>
                            </div>

                            <div class="listing-category">
                                <a routerLink="/" data-bs-toggle="tooltip" data-placement="top" title="Category">
                                    <i class='bx bx-wine'></i>
                                    Restaurant
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="single-listing-box">
                <a routerLink="/listing-details" class="listing-image">
                    <img src="assets/img/listing/img2.jpg" alt="image">
                </a>

                <div class="listing-badge">Open Now</div>

                <div class="listing-content">
                    <div class="content">
                        <div class="author">
                            <img src="assets/img/default/admin.jpg" alt="image">
                            <span>Sarah Taylor</span>
                        </div>

                        <h3><a routerLink="/listing-details">Shop in Aurora Center</a></h3>
                        <span class="location"><i class='bx bx-map'></i> 55 County Laois, Ireland</span>

                        <div class="rating">
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <span class="rating-count">2 reviews</span>
                        </div>
                    </div>

                    <div class="footer-content">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="listing-option-list">
                                <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                            </div>

                            <div class="price-level">
                                <span data-bs-toggle="tooltip" data-placement="top" title="Pricey">
                                    <strong>$</strong>
                                    <strong>$</strong>
                                </span>
                            </div>

                            <div class="listing-category">
                                <a routerLink="/" data-bs-toggle="tooltip" data-placement="top" title="Category">
                                    <i class="bx bx-shopping-bag"></i>
                                    Shopping
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="single-listing-box">
                <a routerLink="/listing-details" class="listing-image">
                    <img src="assets/img/listing/img3.jpg" alt="image">
                </a>

                <div class="listing-badge closed">Closed Now</div>

                <div class="listing-content">
                    <div class="content">
                        <div class="author">
                            <img src="assets/img/default/admin.jpg" alt="image">
                            <span>James Andy</span>
                        </div>

                        <h3><a routerLink="/listing-details">USA Fitness Club</a></h3>
                        <span class="location"><i class='bx bx-map'></i> Tilt Tilbury, United Kingdom</span>

                        <div class="rating">
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <span class="rating-count">2 reviews</span>
                        </div>
                    </div>

                    <div class="footer-content">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="listing-option-list">
                                <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                            </div>

                            <div class="price-level">
                                <span data-bs-toggle="tooltip" data-placement="top" title="Moderate">
                                    <strong>$</strong>
                                    <strong>$</strong>
                                    <strong>$</strong>
                                </span>
                            </div>

                            <div class="listing-category">
                                <a routerLink="/" data-bs-toggle="tooltip" data-placement="top" title="Category">
                                    <i class='bx bx-blanket'></i>
                                    Fitness
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="single-listing-box">
                <a routerLink="/listing-details" class="listing-image">
                    <img src="assets/img/listing/img4.jpg" alt="image">
                </a>

                <div class="listing-badge">Open Now</div>

                <div class="listing-content">
                    <div class="content">
                        <div class="author">
                            <img src="assets/img/default/admin.jpg" alt="image">
                            <span>David Warner</span>
                        </div>

                        <h3><a routerLink="/listing-details">The Lounge & Bar</a></h3>
                        <span class="location"><i class='bx bx-map'></i> The Ritz-Carlton, Hong Kong</span>

                        <div class="rating">
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <span class="rating-count">2 reviews</span>
                        </div>
                    </div>

                    <div class="footer-content">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="listing-option-list">
                                <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                            </div>

                            <div class="price-level">
                                <span data-bs-toggle="tooltip" data-placement="top" title="Ultra Hight">
                                    <strong>$</strong>
                                    <strong>$</strong>
                                    <strong>$</strong>
                                    <strong>$</strong>
                                </span>
                            </div>

                            <div class="listing-category">
                                <a routerLink="/" data-bs-toggle="tooltip" data-placement="top" title="Category">
                                    <i class='bx bx-wine'></i>
                                    Bar
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="destinations-area pb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Browse</span>
            <h2>Popular Destinations</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-12 col-md-12">
                <div class="single-destinations-box">
                    <img src="assets/img/destinations/img1.jpg" alt="image">

                    <div class="content">
                        <h3>New York</h3>
                        <div class="rating">
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                        </div>
                    </div>

                    <span class="listing-count">125 Listings</span>

                    <a routerLink="/listing-one" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-8 col-sm-12 col-md-12">
                <div class="single-destinations-box">
                    <img src="assets/img/destinations/img2.jpg" alt="image">

                    <div class="content">
                        <h3>Paris</h3>
                        <div class="rating">
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                        </div>
                    </div>

                    <span class="listing-count">50 Listings</span>

                    <a routerLink="/listing-one" class="link-btn"></a>
                </div>

                <div class="row">
                    <div class="col-lg-6 col-sm-12 col-md-6">
                        <div class="single-destinations-box">
                            <img src="assets/img/destinations/img3.jpg" alt="image">
        
                            <div class="content">
                                <h3>Moscow</h3>
                                <div class="rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                            </div>
        
                            <span class="listing-count">15 Listings</span>
        
                            <a routerLink="/listing-one" class="link-btn"></a>
                        </div>
                    </div>
        
                    <div class="col-lg-6 col-sm-12 col-md-6">
                        <div class="single-destinations-box">
                            <img src="assets/img/destinations/img4.jpg" alt="image">
        
                            <div class="content">
                                <h3>London</h3>
                                <div class="rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                            </div>
        
                            <span class="listing-count">78 Listings</span>
        
                            <a routerLink="/listing-one" class="link-btn"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="destinations-btn-box">
            <a routerLink="/listing-one" class="default-btn">View All Destinations</a>
        </div>
    </div>
</section>

<app-process></app-process>

<section class="listing-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Discover</span>
            <h2>Most Popular Places</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-12 col-md-6">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img5.jpg" alt="image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">Restaurant</a>
                        </div>

                        <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                5.0 (1 reviews)
                            </div>
                        </div>
                    </div>

                    <div class="listing-content">
                        <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Steven Smith</span>
                        </div>

                        <h3><a routerLink="/listing-details" class="d-inline-block">Farmis Garden Hotel & Restaurant</a></h3>

                        <span class="location"><i class="bx bx-map"></i> 40 Journal Square, NG USA</span>
                    </div>

                    <div class="listing-box-footer">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="price">
                                <span data-bs-toggle="tooltip" data-placement="top" title="Ultra Hight">
                                    $1500 - $2000
                                </span>
                            </div>

                            <div class="listing-option-list">
                                <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="listing-badge">Open!</div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 col-md-6">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img6.jpg" alt="image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">Shopping</a>
                        </div>

                        <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                5.0 (1 reviews)
                            </div>
                        </div>
                    </div>

                    <div class="listing-content">
                        <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Sarah Taylor</span>
                        </div>

                        <h3><a routerLink="/listing-details" class="d-inline-block">Skyview Shopping Complex Center</a></h3>

                        <span class="location"><i class="bx bx-map"></i> 55 County Laois, Ireland</span>
                    </div>

                    <div class="listing-box-footer">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="price">
                                <span data-bs-toggle="tooltip" data-placement="top" title="Pricey">
                                    $100 - $200
                                </span>
                            </div>

                            <div class="listing-option-list">
                                <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="listing-badge">Open!</div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 col-md-6">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img7.jpg" alt="image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">Gym</a>
                        </div>

                        <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                4.5 (2 reviews)
                            </div>
                        </div>
                    </div>

                    <div class="listing-content">
                        <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                            <span>James Andy</span>
                        </div>

                        <h3><a routerLink="/listing-details" class="d-inline-block">Power House Gym Training Center</a></h3>

                        <span class="location"><i class="bx bx-map"></i> Tilt Tilbury, United Kingdom</span>
                    </div>

                    <div class="listing-box-footer">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="price">
                                <span data-bs-toggle="tooltip" data-placement="top" title="Moderate">
                                    $240 - $250
                                </span>
                            </div>

                            <div class="listing-option-list">
                                <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="listing-badge">Open!</div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 col-md-6">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img8.jpg" alt="image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">Restaurant</a>
                        </div>

                        <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                5.0 (1 reviews)
                            </div>
                        </div>
                    </div>

                    <div class="listing-content">
                        <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Steven Smith</span>
                        </div>

                        <h3><a routerLink="/listing-details" class="d-inline-block">The Magician Restaurant in USA</a></h3>

                        <span class="location"><i class="bx bx-map"></i> 40 Journal Square, NG USA</span>
                    </div>

                    <div class="listing-box-footer">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="price">
                                <span data-bs-toggle="tooltip" data-placement="top" title="Ultra Hight">
                                    $1500 - $2000
                                </span>
                            </div>

                            <div class="listing-option-list">
                                <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="listing-badge">Open!</div>
                </div>
            </div>
            
            <div class="col-lg-4 col-sm-12 col-md-6">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img9.jpg" alt="image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">Shopping</a>
                        </div>

                        <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                5.0 (1 reviews)
                            </div>
                        </div>
                    </div>

                    <div class="listing-content">
                        <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Sarah Taylor</span>
                        </div>

                        <h3><a routerLink="/listing-details" class="d-inline-block">New Oriental Shopping Center</a></h3>

                        <span class="location"><i class="bx bx-map"></i> 55 County Laois, Ireland</span>
                    </div>

                    <div class="listing-box-footer">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="price">
                                <span data-bs-toggle="tooltip" data-placement="top" title="Pricey">
                                    $100 - $200
                                </span>
                            </div>

                            <div class="listing-option-list">
                                <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="listing-badge closed">Closed!</div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 col-md-6">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img10.jpg" alt="image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">Gym</a>
                        </div>

                        <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                4.5 (2 reviews)
                            </div>
                        </div>
                    </div>

                    <div class="listing-content">
                        <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                            <span>James Andy</span>
                        </div>

                        <h3><a routerLink="/listing-details" class="d-inline-block">Looks Women's Fitness Center</a></h3>

                        <span class="location"><i class="bx bx-map"></i> Tilt Tilbury, United Kingdom</span>
                    </div>

                    <div class="listing-box-footer">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="price">
                                <span data-bs-toggle="tooltip" data-placement="top" title="Moderate">
                                    $240 - $250
                                </span>
                            </div>

                            <div class="listing-option-list">
                                <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="listing-badge">Open!</div>
                    
                    <span class="listing-discount">
                        <span>-15% OFF</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</section>

<app-video></app-video>

<app-feedback></app-feedback>

<app-app-download></app-app-download>