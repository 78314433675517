export const TSONLINENPC_PART2 = [
    {
        "idnpc": 13026,
        "idmap": 11505,
        "ten_map": "Son Dong Thai Son 5",
        "thanh": "Thanh Châu",
        "ten_npc": "Dư Phan",
        "level": 83,
        "hp": 677,
        "sp": 323,
        "hpx": 33,
        "spx": 16,
        "int": 68,
        "atk": 17,
        "def": 28,
        "agi": 34,
        "item1": 27034,
        "item2": 27040,
        "item3": 27046,
        "item4": 0,
        "item5": 0,
        "item6": 47122
    },
    {
        "idnpc": 13053,
        "idmap": 11521,
        "ten_map": "Son Dong Thai Son 19",
        "thanh": "Thanh Châu",
        "ten_npc": "Tôn Tịnh",
        "level": 83,
        "hp": 828,
        "sp": 353,
        "hpx": 39,
        "spx": 21,
        "int": 33,
        "atk": 50,
        "def": 23,
        "agi": 30,
        "item1": 26080,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13102,
        "idmap": 11543,
        "ten_map": "Dong Quy Than 13",
        "thanh": "Thanh Châu",
        "ten_npc": "Huỳnh Bỉnh",
        "level": 83,
        "hp": 652,
        "sp": 329,
        "hpx": 36,
        "spx": 17,
        "int": 20,
        "atk": 58,
        "def": 24,
        "agi": 40,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47331
    },
    {
        "idnpc": 14008,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Phương Duyệt",
        "level": 83,
        "hp": 635,
        "sp": 349,
        "hpx": 33,
        "spx": 20,
        "int": 19,
        "atk": 61,
        "def": 35,
        "agi": 32,
        "item1": 26051,
        "item2": 26082,
        "item3": 26003,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14016,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Lý Biệt",
        "level": 83,
        "hp": 635,
        "sp": 345,
        "hpx": 33,
        "spx": 20,
        "int": 7,
        "atk": 55,
        "def": 32,
        "agi": 33,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14034,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Đọan Uy",
        "level": 83,
        "hp": 635,
        "sp": 354,
        "hpx": 33,
        "spx": 21,
        "int": 37,
        "atk": 15,
        "def": 30,
        "agi": 26,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14053,
        "idmap": 11581,
        "thanh": "Thanh Châu",
        "ten_npc": "Trần Kỷ",
        "level": 83,
        "hp": 635,
        "sp": 347,
        "hpx": 33,
        "spx": 20,
        "int": 13,
        "atk": 66,
        "def": 33,
        "agi": 36,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14065,
        "idmap": 11581,
        "thanh": "Thanh Châu",
        "ten_npc": "Lý Mông",
        "level": 83,
        "hp": 635,
        "sp": 348,
        "hpx": 33,
        "spx": 20,
        "int": 17,
        "atk": 47,
        "def": 31,
        "agi": 32,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14117,
        "idmap": 11584,
        "thanh": "Thanh Châu",
        "ten_npc": "Từ Tha",
        "level": 83,
        "hp": 635,
        "sp": 345,
        "hpx": 33,
        "spx": 20,
        "int": 7,
        "atk": 59,
        "def": 26,
        "agi": 50,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14220,
        "idmap": 11823,
        "thanh": "Thanh Châu",
        "ten_npc": "Trương Mạo",
        "level": 83,
        "hp": 635,
        "sp": 346,
        "hpx": 33,
        "spx": 20,
        "int": 11,
        "atk": 60,
        "def": 32,
        "agi": 45,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14239,
        "idmap": 11832,
        "ten_map": "Lang Ta Phi Lam 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Bành Hổ",
        "level": 83,
        "hp": 677,
        "sp": 345,
        "hpx": 33,
        "spx": 20,
        "int": 6,
        "atk": 72,
        "def": 28,
        "agi": 36,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14336,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Lý Phong",
        "level": 83,
        "hp": 694,
        "sp": 349,
        "hpx": 40,
        "spx": 20,
        "int": 19,
        "atk": 60,
        "def": 35,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14362,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Trương Anh",
        "level": 83,
        "hp": 594,
        "sp": 347,
        "hpx": 33,
        "spx": 20,
        "int": 13,
        "atk": 63,
        "def": 15,
        "agi": 52,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14421,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "khách Hứa Cung",
        "level": 83,
        "hp": 635,
        "sp": 289,
        "hpx": 33,
        "spx": 20,
        "int": 15,
        "atk": 39,
        "def": 27,
        "agi": 32,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14422,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "khách Hứa Cung",
        "level": 83,
        "hp": 635,
        "sp": 289,
        "hpx": 33,
        "spx": 20,
        "int": 14,
        "atk": 37,
        "def": 28,
        "agi": 34,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14468,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Trương Doãn",
        "level": 83,
        "hp": 718,
        "sp": 346,
        "hpx": 33,
        "spx": 20,
        "int": 11,
        "atk": 60,
        "def": 42,
        "agi": 29,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47355
    },
    {
        "idnpc": 14500,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Trương Thi",
        "level": 83,
        "hp": 677,
        "sp": 345,
        "hpx": 33,
        "spx": 20,
        "int": 8,
        "atk": 58,
        "def": 24,
        "agi": 52,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14580,
        "idmap": 12041,
        "ten_map": "Thon Thuong Son",
        "thanh": "U Châu",
        "ten_npc": "Điền Ngân",
        "level": 83,
        "hp": 766,
        "sp": 323,
        "hpx": 48,
        "spx": 16,
        "int": 66,
        "atk": 14,
        "def": 32,
        "agi": 21,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14650,
        "idmap": 12063,
        "ten_map": "Hoang Cung Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Cao Định",
        "level": 83,
        "hp": 766,
        "sp": 323,
        "hpx": 48,
        "spx": 16,
        "int": 13,
        "atk": 62,
        "def": 11,
        "agi": 45,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15437,
        "idmap": 12409,
        "thanh": "U Châu",
        "ten_npc": "Nguỵ Doãn",
        "level": 83,
        "hp": 873,
        "sp": 349,
        "hpx": 45,
        "spx": 20,
        "int": 19,
        "atk": 58,
        "def": 17,
        "agi": 39,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17134,
        "idmap": 12539,
        "thanh": "U Châu",
        "ten_npc": "Tượng Thanh",
        "level": 83,
        "hp": 594,
        "sp": 290,
        "hpx": 33,
        "spx": 20,
        "int": 18,
        "atk": 35,
        "def": 35,
        "agi": 35,
        "item1": 26094,
        "item2": 26094,
        "item3": 27010,
        "item4": 42023,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10215,
        "idmap": 10917,
        "ten_npc": "Đai Phong",
        "level": 84,
        "hp": 643,
        "sp": 355,
        "hpx": 34,
        "spx": 21,
        "int": 26,
        "atk": 53,
        "def": 34,
        "agi": 35,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11059,
        "idmap": 10983,
        "ten_npc": "Phò Cang",
        "level": 84,
        "hp": 585,
        "sp": 366,
        "hpx": 24,
        "spx": 23,
        "int": 65,
        "atk": 26,
        "def": 32,
        "agi": 33,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47196
    },
    {
        "idnpc": 11165,
        "idmap": 11011,
        "ten_map": "Bac Hai Thanh Mon",
        "thanh": "Thanh Châu",
        "ten_npc": "Đỗ Kích",
        "level": 84,
        "hp": 585,
        "sp": 354,
        "hpx": 24,
        "spx": 21,
        "int": 24,
        "atk": 55,
        "def": 35,
        "agi": 38,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11184,
        "idmap": 11012,
        "ten_map": "Dai Do Bac Hai",
        "thanh": "Thanh Châu",
        "ten_npc": "Khổng Tín",
        "level": 84,
        "hp": 611,
        "sp": 307,
        "hpx": 63,
        "spx": 13,
        "int": 14,
        "atk": 60,
        "def": 22,
        "agi": 27,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12031,
        "idmap": 11041,
        "thanh": "Thanh Châu",
        "ten_npc": "Lưu Phong",
        "level": 84,
        "hp": 643,
        "sp": 353,
        "hpx": 34,
        "spx": 21,
        "int": 22,
        "atk": 58,
        "def": 28,
        "agi": 34,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47166
    },
    {
        "idnpc": 13047,
        "idmap": 11516,
        "ten_map": "Son Dong Thai Son 14",
        "thanh": "Thanh Châu",
        "ten_npc": "Đổng Trạc",
        "level": 84,
        "hp": 765,
        "sp": 343,
        "hpx": 26,
        "spx": 19,
        "int": 21,
        "atk": 66,
        "def": 30,
        "agi": 36,
        "item1": 26098,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47219
    },
    {
        "idnpc": 13085,
        "idmap": 11536,
        "ten_map": "Dong Quy Than 6",
        "thanh": "Thanh Châu",
        "ten_npc": "Lữ Đại",
        "level": 84,
        "hp": 627,
        "sp": 337,
        "hpx": 24,
        "spx": 18,
        "int": 20,
        "atk": 58,
        "def": 14,
        "agi": 62,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47231
    },
    {
        "idnpc": 13114,
        "idmap": 11560,
        "thanh": "Thanh Châu",
        "ten_npc": "Đặng Đang",
        "level": 84,
        "hp": 652,
        "sp": 289,
        "hpx": 35,
        "spx": 10,
        "int": 23,
        "atk": 50,
        "def": 53,
        "agi": 26,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14022,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Lý Ân",
        "level": 84,
        "hp": 643,
        "sp": 352,
        "hpx": 34,
        "spx": 20,
        "int": 16,
        "atk": 50,
        "def": 40,
        "agi": 26,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 14023,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Lưu Lữ",
        "level": 84,
        "hp": 643,
        "sp": 361,
        "hpx": 34,
        "spx": 22,
        "int": 47,
        "atk": 22,
        "def": 40,
        "agi": 38,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14048,
        "idmap": 11564,
        "thanh": "Thanh Châu",
        "ten_npc": "Tống Quả",
        "level": 84,
        "hp": 643,
        "sp": 351,
        "hpx": 34,
        "spx": 20,
        "int": 15,
        "atk": 42,
        "def": 37,
        "agi": 31,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 14230,
        "idmap": 11831,
        "ten_map": "Lang Ta Phi Lam 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Cao Phan",
        "level": 84,
        "hp": 676,
        "sp": 325,
        "hpx": 39,
        "spx": 16,
        "int": 12,
        "atk": 58,
        "def": 45,
        "agi": 27,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14320,
        "idmap": 12002,
        "ten_map": "Dai Lo Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Xích Minh",
        "level": 84,
        "hp": 702,
        "sp": 354,
        "hpx": 34,
        "spx": 21,
        "int": 25,
        "atk": 50,
        "def": 34,
        "agi": 35,
        "item1": 26078,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14423,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "khách Hứa Cung",
        "level": 84,
        "hp": 643,
        "sp": 293,
        "hpx": 34,
        "spx": 20,
        "int": 17,
        "atk": 38,
        "def": 26,
        "agi": 33,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14484,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Nan Lâu",
        "level": 84,
        "hp": 664,
        "sp": 379,
        "hpx": 30,
        "spx": 25,
        "int": 5,
        "atk": 63,
        "def": 26,
        "agi": 48,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14493,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Phan Lâm",
        "level": 84,
        "hp": 685,
        "sp": 351,
        "hpx": 34,
        "spx": 20,
        "int": 13,
        "atk": 64,
        "def": 28,
        "agi": 37,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14515,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Hàn Huyền",
        "level": 84,
        "hp": 1021,
        "sp": 319,
        "hpx": 34,
        "spx": 15,
        "int": 17,
        "atk": 63,
        "def": 28,
        "agi": 39,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14531,
        "idmap": 12022,
        "ten_map": "Dai Lo Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Lý Kham",
        "level": 84,
        "hp": 730,
        "sp": 289,
        "hpx": 41,
        "spx": 10,
        "int": 8,
        "atk": 58,
        "def": 62,
        "agi": 17,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14549,
        "idmap": 12023,
        "ten_map": "Diem Binh Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Dương Hoài",
        "level": 84,
        "hp": 712,
        "sp": 313,
        "hpx": 38,
        "spx": 14,
        "int": 22,
        "atk": 59,
        "def": 39,
        "agi": 25,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14653,
        "idmap": 12063,
        "ten_map": "Hoang Cung Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Kimhoàntamkêt",
        "level": 84,
        "hp": 863,
        "sp": 361,
        "hpx": 49,
        "spx": 22,
        "int": 18,
        "atk": 53,
        "def": 13,
        "agi": 41,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14666,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Khaisơnđộngchủ",
        "level": 84,
        "hp": 754,
        "sp": 355,
        "hpx": 45,
        "spx": 21,
        "int": 18,
        "atk": 57,
        "def": 16,
        "agi": 40,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14674,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Cáp cách đinh",
        "level": 84,
        "hp": 832,
        "sp": 343,
        "hpx": 37,
        "spx": 19,
        "int": 21,
        "atk": 72,
        "def": 26,
        "agi": 36,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17322,
        "idmap": 12702,
        "ten_map": "DaiTruong Huong Dung",
        "thanh": "U Châu",
        "ten_npc": "Bệnh Thôn Phu",
        "level": 84,
        "hp": 643,
        "sp": 358,
        "hpx": 34,
        "spx": 21,
        "int": 36,
        "atk": 22,
        "def": 33,
        "agi": 21,
        "item1": 27076,
        "item2": 27076,
        "item3": 27076,
        "item4": 41029,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17324,
        "idmap": 12702,
        "ten_map": "DaiTruong Huong Dung",
        "thanh": "U Châu",
        "ten_npc": "Binh Tiểu Dân",
        "level": 84,
        "hp": 643,
        "sp": 348,
        "hpx": 34,
        "spx": 20,
        "int": 6,
        "atk": 34,
        "def": 33,
        "agi": 39,
        "item1": 32025,
        "item2": 27071,
        "item3": 32025,
        "item4": 33018,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17327,
        "idmap": 12703,
        "ten_map": "QuanTruong Huong Dung",
        "thanh": "U Châu",
        "ten_npc": "Thủ Tiết Ẩn Sĩ",
        "level": 84,
        "hp": 643,
        "sp": 358,
        "hpx": 34,
        "spx": 21,
        "int": 36,
        "atk": 25,
        "def": 29,
        "agi": 22,
        "item1": 27011,
        "item2": 32008,
        "item3": 27011,
        "item4": 41009,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17388,
        "idmap": 12803,
        "ten_map": "Rung Cay ngoai o 2",
        "thanh": "U Châu",
        "ten_npc": "LínhTr.Thành",
        "level": 84,
        "hp": 643,
        "sp": 356,
        "hpx": 34,
        "spx": 21,
        "int": 31,
        "atk": 33,
        "def": 27,
        "agi": 21,
        "item1": 27011,
        "item2": 27012,
        "item3": 27011,
        "item4": 41008,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10079,
        "idmap": 10813,
        "ten_npc": "Khư Tinh",
        "level": 85,
        "hp": 651,
        "sp": 355,
        "hpx": 34,
        "spx": 21,
        "int": 16,
        "atk": 61,
        "def": 37,
        "agi": 36,
        "item1": 27027,
        "item2": 26084,
        "item3": 26006,
        "item4": 0,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 11101,
        "idmap": 10994,
        "ten_map": "Va Dai Dâu Va",
        "ten_npc": "Điền Dự",
        "level": 85,
        "hp": 682,
        "sp": 303,
        "hpx": 39,
        "spx": 12,
        "int": 40,
        "atk": 57,
        "def": 22,
        "agi": 27,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47123
    },
    {
        "idnpc": 11118,
        "idmap": 10997,
        "ten_npc": "Cao Nhu",
        "level": 85,
        "hp": 609,
        "sp": 369,
        "hpx": 34,
        "spx": 23,
        "int": 61,
        "atk": 27,
        "def": 22,
        "agi": 32,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47276
    },
    {
        "idnpc": 11137,
        "idmap": 11001,
        "ten_map": "Thanh Môn Dong Quan",
        "thanh": "Thanh Châu",
        "ten_npc": "Lâu Quế",
        "level": 85,
        "hp": 621,
        "sp": 345,
        "hpx": 29,
        "spx": 19,
        "int": 61,
        "atk": 26,
        "def": 20,
        "agi": 44,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47124
    },
    {
        "idnpc": 11140,
        "idmap": 11001,
        "ten_map": "Thanh Môn Dong Quan",
        "thanh": "Thanh Châu",
        "ten_npc": "Đặng Triển",
        "level": 85,
        "hp": 645,
        "sp": 321,
        "hpx": 33,
        "spx": 15,
        "int": 15,
        "atk": 68,
        "def": 20,
        "agi": 48,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12058,
        "idmap": 11203,
        "thanh": "Thanh Châu",
        "ten_npc": "Gia Cát Quân",
        "level": 85,
        "hp": 755,
        "sp": 351,
        "hpx": 37,
        "spx": 20,
        "int": 59,
        "atk": 23,
        "def": 27,
        "agi": 33,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47126
    },
    {
        "idnpc": 12081,
        "idmap": 11301,
        "ten_map": "Dong Quan Quan Phu",
        "thanh": "Thanh Châu",
        "ten_npc": "Huỳnh Tụ",
        "level": 85,
        "hp": 718,
        "sp": 309,
        "hpx": 31,
        "spx": 13,
        "int": 28,
        "atk": 50,
        "def": 11,
        "agi": 64,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12084,
        "idmap": 11301,
        "ten_map": "Dong Quan Quan Phu",
        "thanh": "Thanh Châu",
        "ten_npc": "Ảnh Đồ",
        "level": 85,
        "hp": 682,
        "sp": 353,
        "hpx": 32,
        "spx": 20,
        "int": 9,
        "atk": 61,
        "def": 17,
        "agi": 57,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12118,
        "idmap": 11411,
        "ten_map": "Khuc Trac Khong Mieu",
        "thanh": "Thanh Châu",
        "ten_npc": "Cư Phù",
        "level": 85,
        "hp": 645,
        "sp": 364,
        "hpx": 26,
        "spx": 22,
        "int": 22,
        "atk": 62,
        "def": 27,
        "agi": 38,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12152,
        "idmap": 11501,
        "ten_map": "Son Dong Thai Son 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Lưu Báo",
        "level": 85,
        "hp": 834,
        "sp": 327,
        "hpx": 36,
        "spx": 16,
        "int": 21,
        "atk": 61,
        "def": 23,
        "agi": 41,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14020,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Trương Long",
        "level": 85,
        "hp": 651,
        "sp": 355,
        "hpx": 34,
        "spx": 21,
        "int": 16,
        "atk": 42,
        "def": 35,
        "agi": 34,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14025,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Hồ Trân",
        "level": 85,
        "hp": 651,
        "sp": 357,
        "hpx": 34,
        "spx": 21,
        "int": 23,
        "atk": 55,
        "def": 38,
        "agi": 34,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14035,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Hạ Dục",
        "level": 85,
        "hp": 651,
        "sp": 353,
        "hpx": 34,
        "spx": 20,
        "int": 10,
        "atk": 45,
        "def": 34,
        "agi": 35,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14038,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Trương Chương",
        "level": 85,
        "hp": 651,
        "sp": 353,
        "hpx": 34,
        "spx": 20,
        "int": 9,
        "atk": 41,
        "def": 32,
        "agi": 45,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14043,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Trương Bao",
        "level": 85,
        "hp": 651,
        "sp": 360,
        "hpx": 34,
        "spx": 21,
        "int": 31,
        "atk": 48,
        "def": 34,
        "agi": 30,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14084,
        "idmap": 11582,
        "thanh": "Thanh Châu",
        "ten_npc": "Thái Mao",
        "level": 85,
        "hp": 651,
        "sp": 359,
        "hpx": 34,
        "spx": 21,
        "int": 27,
        "atk": 51,
        "def": 35,
        "agi": 37,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 47181
    },
    {
        "idnpc": 14157,
        "idmap": 11801,
        "ten_map": "Rung Dong Quan 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Đinh Nguyên",
        "level": 85,
        "hp": 651,
        "sp": 357,
        "hpx": 34,
        "spx": 21,
        "int": 22,
        "atk": 64,
        "def": 33,
        "agi": 31,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14175,
        "idmap": 11802,
        "ten_map": "Rung Dong Quan 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Triệu Dung",
        "level": 85,
        "hp": 651,
        "sp": 356,
        "hpx": 34,
        "spx": 21,
        "int": 19,
        "atk": 59,
        "def": 35,
        "agi": 32,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14280,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Hồ Tài",
        "level": 85,
        "hp": 651,
        "sp": 357,
        "hpx": 34,
        "spx": 21,
        "int": 21,
        "atk": 47,
        "def": 30,
        "agi": 52,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14308,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Tiết Lan",
        "level": 85,
        "hp": 609,
        "sp": 357,
        "hpx": 34,
        "spx": 21,
        "int": 21,
        "atk": 52,
        "def": 40,
        "agi": 32,
        "item1": 26109,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14313,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Thành Liêm",
        "level": 85,
        "hp": 709,
        "sp": 358,
        "hpx": 34,
        "spx": 21,
        "int": 24,
        "atk": 54,
        "def": 35,
        "agi": 32,
        "item1": 26076,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14461,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Cù Cung",
        "level": 85,
        "hp": 797,
        "sp": 297,
        "hpx": 44,
        "spx": 11,
        "int": 6,
        "atk": 60,
        "def": 59,
        "agi": 17,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14543,
        "idmap": 12023,
        "ten_map": "Diem Binh Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Triệu Vĩ",
        "level": 85,
        "hp": 627,
        "sp": 376,
        "hpx": 23,
        "spx": 24,
        "int": 61,
        "atk": 11,
        "def": 55,
        "agi": 25,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14547,
        "idmap": 12023,
        "ten_map": "Diem Binh Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Lý Dị",
        "level": 85,
        "hp": 682,
        "sp": 333,
        "hpx": 32,
        "spx": 17,
        "int": 21,
        "atk": 54,
        "def": 33,
        "agi": 40,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14567,
        "idmap": 12031,
        "ten_map": "Thon Lau Song",
        "thanh": "U Châu",
        "ten_npc": "Ngô Cự",
        "level": 85,
        "hp": 779,
        "sp": 327,
        "hpx": 48,
        "spx": 16,
        "int": 9,
        "atk": 57,
        "def": 28,
        "agi": 38,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14663,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Mạnh đại bá",
        "level": 85,
        "hp": 700,
        "sp": 327,
        "hpx": 35,
        "spx": 16,
        "int": 18,
        "atk": 52,
        "def": 26,
        "agi": 50,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14667,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "AnKhê tùtrưởng",
        "level": 85,
        "hp": 736,
        "sp": 339,
        "hpx": 41,
        "spx": 18,
        "int": 21,
        "atk": 62,
        "def": 15,
        "agi": 41,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17122,
        "idmap": 12537,
        "thanh": "U Châu",
        "ten_npc": "Thi Lệ Binh",
        "level": 85,
        "hp": 609,
        "sp": 297,
        "hpx": 34,
        "spx": 21,
        "int": 19,
        "atk": 30,
        "def": 47,
        "agi": 29,
        "item1": 27011,
        "item2": 27011,
        "item3": 26070,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17125,
        "idmap": 12537,
        "thanh": "U Châu",
        "ten_npc": "Uy Viễn Quân",
        "level": 85,
        "hp": 670,
        "sp": 296,
        "hpx": 44,
        "spx": 21,
        "int": 17,
        "atk": 38,
        "def": 32,
        "agi": 28,
        "item1": 27011,
        "item2": 27011,
        "item3": 26070,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17197,
        "idmap": 12571,
        "ten_map": "S.Dong KhVang 51",
        "thanh": "U Châu",
        "ten_npc": "Quan.Tr.Trưởng",
        "level": 85,
        "hp": 670,
        "sp": 296,
        "hpx": 44,
        "spx": 21,
        "int": 16,
        "atk": 39,
        "def": 30,
        "agi": 30,
        "item1": 26011,
        "item2": 26070,
        "item3": 26070,
        "item4": 34009,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17323,
        "idmap": 12702,
        "ten_map": "DaiTruong Huong Dung",
        "thanh": "U Châu",
        "ten_npc": "Binh Tân Dân",
        "level": 85,
        "hp": 651,
        "sp": 352,
        "hpx": 34,
        "spx": 20,
        "int": 5,
        "atk": 35,
        "def": 38,
        "agi": 34,
        "item1": 32008,
        "item2": 32008,
        "item3": 32008,
        "item4": 34009,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17328,
        "idmap": 12703,
        "ten_map": "QuanTruong Huong Dung",
        "thanh": "U Châu",
        "ten_npc": "Lữo Nông Phu",
        "level": 85,
        "hp": 651,
        "sp": 360,
        "hpx": 34,
        "spx": 21,
        "int": 33,
        "atk": 33,
        "def": 42,
        "agi": 4,
        "item1": 26105,
        "item2": 26236,
        "item3": 26105,
        "item4": 42027,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10227,
        "idmap": 10951,
        "ten_npc": "LínhTrườngKích",
        "level": 86,
        "hp": 663,
        "sp": 275,
        "hpx": 35,
        "spx": 7,
        "int": 8,
        "atk": 50,
        "def": 46,
        "agi": 25,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11085,
        "idmap": 10992,
        "ten_map": "Vung dat bang gia 1",
        "ten_npc": "Hàn Hạo",
        "level": 86,
        "hp": 660,
        "sp": 364,
        "hpx": 34,
        "spx": 22,
        "int": 33,
        "atk": 49,
        "def": 34,
        "agi": 30,
        "item1": 26041,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47203
    },
    {
        "idnpc": 11158,
        "idmap": 11011,
        "ten_map": "Bac Hai Thanh Mon",
        "thanh": "Thanh Châu",
        "ten_npc": "Đổng Hành",
        "level": 86,
        "hp": 681,
        "sp": 305,
        "hpx": 38,
        "spx": 12,
        "int": 18,
        "atk": 44,
        "def": 50,
        "agi": 40,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11159,
        "idmap": 11011,
        "ten_map": "Bac Hai Thanh Mon",
        "thanh": "Thanh Châu",
        "ten_npc": "Đổng Siêu",
        "level": 86,
        "hp": 675,
        "sp": 311,
        "hpx": 37,
        "spx": 13,
        "int": 20,
        "atk": 43,
        "def": 51,
        "agi": 37,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11160,
        "idmap": 11011,
        "ten_map": "Bac Hai Thanh Mon",
        "thanh": "Thanh Châu",
        "ten_npc": "Từ Cán",
        "level": 86,
        "hp": 663,
        "sp": 305,
        "hpx": 35,
        "spx": 12,
        "int": 48,
        "atk": 16,
        "def": 49,
        "agi": 42,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11182,
        "idmap": 11012,
        "ten_map": "Dai Do Bac Hai",
        "thanh": "Thanh Châu",
        "ten_npc": "Doãn Phụng",
        "level": 86,
        "hp": 393,
        "sp": 348,
        "hpx": 26,
        "spx": 19,
        "int": 62,
        "atk": 51,
        "def": 19,
        "agi": 26,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11216,
        "idmap": 11031,
        "thanh": "Thanh Châu",
        "ten_npc": "Gia Cát Kiền",
        "level": 86,
        "hp": 1038,
        "sp": 403,
        "hpx": 40,
        "spx": 28,
        "int": 71,
        "atk": 8,
        "def": 17,
        "agi": 40,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12024,
        "idmap": 11041,
        "thanh": "Thanh Châu",
        "ten_npc": "Dương Nhi",
        "level": 86,
        "hp": 675,
        "sp": 354,
        "hpx": 37,
        "spx": 20,
        "int": 58,
        "atk": 14,
        "def": 28,
        "agi": 46,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12078,
        "idmap": 11301,
        "ten_map": "Dong Quan Quan Phu",
        "thanh": "Thanh Châu",
        "ten_npc": "Phổ Khang",
        "level": 86,
        "hp": 725,
        "sp": 323,
        "hpx": 31,
        "spx": 15,
        "int": 10,
        "atk": 66,
        "def": 26,
        "agi": 53,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47214
    },
    {
        "idnpc": 12097,
        "idmap": 11307,
        "ten_map": "Dong Quan Quan Phu",
        "thanh": "Thanh Châu",
        "ten_npc": "Phí Quan",
        "level": 86,
        "hp": 779,
        "sp": 305,
        "hpx": 47,
        "spx": 12,
        "int": 14,
        "atk": 56,
        "def": 47,
        "agi": 24,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47347
    },
    {
        "idnpc": 12130,
        "idmap": 11423,
        "ten_map": "Lang Ta Cung",
        "thanh": "Thanh Châu",
        "ten_npc": "Lý Thiệu",
        "level": 86,
        "hp": 639,
        "sp": 360,
        "hpx": 24,
        "spx": 21,
        "int": 70,
        "atk": 18,
        "def": 31,
        "agi": 40,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14019,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Ngũ Tập",
        "level": 86,
        "hp": 660,
        "sp": 317,
        "hpx": 34,
        "spx": 14,
        "int": 20,
        "atk": 36,
        "def": 37,
        "agi": 30,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14075,
        "idmap": 11582,
        "thanh": "Thanh Châu",
        "ten_npc": "Thôi Dũng",
        "level": 86,
        "hp": 660,
        "sp": 358,
        "hpx": 34,
        "spx": 21,
        "int": 14,
        "atk": 46,
        "def": 37,
        "agi": 30,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14309,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Lý Phong",
        "level": 86,
        "hp": 617,
        "sp": 358,
        "hpx": 34,
        "spx": 21,
        "int": 11,
        "atk": 48,
        "def": 50,
        "agi": 38,
        "item1": 26109,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14314,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Hầu Thành",
        "level": 86,
        "hp": 715,
        "sp": 362,
        "hpx": 34,
        "spx": 21,
        "int": 24,
        "atk": 57,
        "def": 36,
        "agi": 31,
        "item1": 26076,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14338,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Lạc Tựu",
        "level": 86,
        "hp": 691,
        "sp": 348,
        "hpx": 40,
        "spx": 19,
        "int": 18,
        "atk": 57,
        "def": 28,
        "agi": 40,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14350,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Kim Thượng",
        "level": 86,
        "hp": 732,
        "sp": 329,
        "hpx": 37,
        "spx": 16,
        "int": 25,
        "atk": 54,
        "def": 48,
        "agi": 22,
        "item1": 26034,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14411,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Ninja.Ph.Băng",
        "level": 86,
        "hp": 617,
        "sp": 297,
        "hpx": 34,
        "spx": 21,
        "int": 9,
        "atk": 66,
        "def": 30,
        "agi": 36,
        "item1": 26070,
        "item2": 26062,
        "item3": 26030,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14483,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Tổ Sơn",
        "level": 86,
        "hp": 731,
        "sp": 390,
        "hpx": 39,
        "spx": 26,
        "int": 9,
        "atk": 58,
        "def": 32,
        "agi": 47,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14495,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Vưu Đột",
        "level": 86,
        "hp": 700,
        "sp": 358,
        "hpx": 34,
        "spx": 21,
        "int": 11,
        "atk": 65,
        "def": 41,
        "agi": 29,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14498,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Trương Hùng",
        "level": 86,
        "hp": 703,
        "sp": 356,
        "hpx": 34,
        "spx": 20,
        "int": 7,
        "atk": 66,
        "def": 51,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14536,
        "idmap": 12023,
        "ten_map": "Diem Binh Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Mã Ngọan",
        "level": 86,
        "hp": 703,
        "sp": 287,
        "hpx": 34,
        "spx": 9,
        "int": 26,
        "atk": 62,
        "def": 54,
        "agi": 17,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14579,
        "idmap": 12041,
        "ten_map": "Thon Thuong Son",
        "thanh": "U Châu",
        "ten_npc": "Tô Bá",
        "level": 86,
        "hp": 706,
        "sp": 305,
        "hpx": 35,
        "spx": 12,
        "int": 8,
        "atk": 66,
        "def": 63,
        "agi": 16,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14618,
        "idmap": 12061,
        "ten_map": "Diep Thanh Thanh Mon",
        "thanh": "U Châu",
        "ten_npc": "Lan Mộc La Na",
        "level": 86,
        "hp": 731,
        "sp": 342,
        "hpx": 39,
        "spx": 18,
        "int": 62,
        "atk": 15,
        "def": 30,
        "agi": 39,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47375
    },
    {
        "idnpc": 14654,
        "idmap": 12063,
        "ten_map": "Hoang Cung Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Đổng Trà Na",
        "level": 86,
        "hp": 780,
        "sp": 323,
        "hpx": 33,
        "spx": 15,
        "int": 53,
        "atk": 18,
        "def": 37,
        "agi": 46,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17329,
        "idmap": 12703,
        "ten_map": "QuanTruong Huong Dung",
        "thanh": "U Châu",
        "ten_npc": "Trang Gia Hán",
        "level": 86,
        "hp": 660,
        "sp": 355,
        "hpx": 34,
        "spx": 20,
        "int": 4,
        "atk": 40,
        "def": 35,
        "agi": 37,
        "item1": 26236,
        "item2": 26105,
        "item3": 26236,
        "item4": 42028,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17389,
        "idmap": 12803,
        "ten_map": "Rung Cay ngoai o 2",
        "thanh": "U Châu",
        "ten_npc": "Tr.Thành Vệ",
        "level": 86,
        "hp": 660,
        "sp": 356,
        "hpx": 34,
        "spx": 20,
        "int": 5,
        "atk": 43,
        "def": 43,
        "agi": 25,
        "item1": 26070,
        "item2": 27118,
        "item3": 26070,
        "item4": 34028,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 17568,
        "idmap": 12825,
        "ten_map": "Rung Lau Song 1",
        "thanh": "U Châu",
        "ten_npc": "Đoàn Tiên Đăng",
        "level": 86,
        "hp": 718,
        "sp": 299,
        "hpx": 44,
        "spx": 11,
        "int": 9,
        "atk": 48,
        "def": 44,
        "agi": 15,
        "item1": 27087,
        "item2": 27087,
        "item3": 32007,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17570,
        "idmap": 12826,
        "thanh": "U Châu",
        "ten_npc": "Cương Trụ Binh",
        "level": 86,
        "hp": 694,
        "sp": 311,
        "hpx": 40,
        "spx": 13,
        "int": 12,
        "atk": 48,
        "def": 46,
        "agi": 12,
        "item1": 26248,
        "item2": 26248,
        "item3": 26234,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10063,
        "idmap": 10810,
        "ten_npc": "Tặc.Tr.Kích",
        "level": 87,
        "hp": 668,
        "sp": 360,
        "hpx": 35,
        "spx": 21,
        "int": 6,
        "atk": 38,
        "def": 39,
        "agi": 33,
        "item1": 26249,
        "item2": 26235,
        "item3": 26235,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11136,
        "idmap": 11001,
        "ten_map": "Thanh Môn Dong Quan",
        "thanh": "Thanh Châu",
        "ten_npc": "Trương Hỷ",
        "level": 87,
        "hp": 705,
        "sp": 313,
        "hpx": 41,
        "spx": 13,
        "int": 22,
        "atk": 61,
        "def": 52,
        "agi": 13,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47295
    },
    {
        "idnpc": 11185,
        "idmap": 11012,
        "ten_map": "Dai Do Bac Hai",
        "thanh": "Thanh Châu",
        "ten_npc": "Dương Nhạc",
        "level": 87,
        "hp": 378,
        "sp": 301,
        "hpx": 23,
        "spx": 11,
        "int": 62,
        "atk": 18,
        "def": 23,
        "agi": 67,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12055,
        "idmap": 11202,
        "thanh": "Thanh Châu",
        "ten_npc": "Hoắc Tuấn",
        "level": 87,
        "hp": 719,
        "sp": 399,
        "hpx": 29,
        "spx": 27,
        "int": 13,
        "atk": 65,
        "def": 38,
        "agi": 30,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47154
    },
    {
        "idnpc": 12115,
        "idmap": 11411,
        "ten_map": "Khuc Trac Khong Mieu",
        "thanh": "Thanh Châu",
        "ten_npc": "Dương Hồng",
        "level": 87,
        "hp": 731,
        "sp": 313,
        "hpx": 38,
        "spx": 13,
        "int": 64,
        "atk": 43,
        "def": 22,
        "agi": 24,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47343
    },
    {
        "idnpc": 13092,
        "idmap": 11540,
        "ten_map": "Dong Quy Than 10",
        "thanh": "Thanh Châu",
        "ten_npc": "Ngô Phạm",
        "level": 87,
        "hp": 688,
        "sp": 344,
        "hpx": 31,
        "spx": 18,
        "int": 72,
        "atk": 27,
        "def": 17,
        "agi": 40,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47250
    },
    {
        "idnpc": 13094,
        "idmap": 11541,
        "ten_map": "Dong Quy Than 11",
        "thanh": "Thanh Châu",
        "ten_npc": "Thủy Linh",
        "level": 87,
        "hp": 633,
        "sp": 234,
        "hpx": 22,
        "spx": 10,
        "int": 57,
        "atk": 62,
        "def": 12,
        "agi": 41,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14027,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Cao Thạc",
        "level": 87,
        "hp": 668,
        "sp": 361,
        "hpx": 35,
        "spx": 21,
        "int": 11,
        "atk": 56,
        "def": 36,
        "agi": 27,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14029,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Dương Định",
        "level": 87,
        "hp": 668,
        "sp": 363,
        "hpx": 35,
        "spx": 21,
        "int": 18,
        "atk": 46,
        "def": 39,
        "agi": 26,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 14041,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Tần Nghi",
        "level": 87,
        "hp": 668,
        "sp": 363,
        "hpx": 35,
        "spx": 21,
        "int": 16,
        "atk": 59,
        "def": 33,
        "agi": 32,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14413,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Ninja.T.Phong",
        "level": 87,
        "hp": 624,
        "sp": 300,
        "hpx": 35,
        "spx": 21,
        "int": 9,
        "atk": 69,
        "def": 29,
        "agi": 34,
        "item1": 26070,
        "item2": 26062,
        "item3": 26030,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14482,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Mao Cam",
        "level": 87,
        "hp": 676,
        "sp": 405,
        "hpx": 29,
        "spx": 28,
        "int": 11,
        "atk": 59,
        "def": 45,
        "agi": 31,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14499,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Trương Tiễn",
        "level": 87,
        "hp": 711,
        "sp": 361,
        "hpx": 35,
        "spx": 21,
        "int": 10,
        "atk": 59,
        "def": 22,
        "agi": 55,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14530,
        "idmap": 12022,
        "ten_map": "Dai Lo Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Thành Nghị",
        "level": 87,
        "hp": 731,
        "sp": 301,
        "hpx": 38,
        "spx": 11,
        "int": 7,
        "atk": 66,
        "def": 56,
        "agi": 24,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14540,
        "idmap": 12023,
        "ten_map": "Diem Binh Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Linh Bao",
        "level": 87,
        "hp": 651,
        "sp": 423,
        "hpx": 25,
        "spx": 31,
        "int": 22,
        "atk": 60,
        "def": 38,
        "agi": 26,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15434,
        "idmap": 12409,
        "thanh": "U Châu",
        "ten_npc": "Thiết Toàn",
        "level": 87,
        "hp": 904,
        "sp": 364,
        "hpx": 45,
        "spx": 21,
        "int": 19,
        "atk": 69,
        "def": 19,
        "agi": 39,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17325,
        "idmap": 12703,
        "ten_map": "QuanTruong Huong Dung",
        "thanh": "U Châu",
        "ten_npc": "Lữo Lý Trưởng",
        "level": 87,
        "hp": 668,
        "sp": 369,
        "hpx": 35,
        "spx": 22,
        "int": 36,
        "atk": 27,
        "def": 32,
        "agi": 21,
        "item1": 27071,
        "item2": 32025,
        "item3": 27071,
        "item4": 40009,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17569,
        "idmap": 12826,
        "thanh": "U Châu",
        "ten_npc": "Trại ChiếcXung",
        "level": 87,
        "hp": 712,
        "sp": 307,
        "hpx": 42,
        "spx": 12,
        "int": 13,
        "atk": 47,
        "def": 42,
        "agi": 16,
        "item1": 27010,
        "item2": 27010,
        "item3": 32024,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17571,
        "idmap": 12826,
        "thanh": "U Châu",
        "ten_npc": "Nhận GiápBinh",
        "level": 87,
        "hp": 712,
        "sp": 313,
        "hpx": 42,
        "spx": 13,
        "int": 10,
        "atk": 47,
        "def": 45,
        "agi": 15,
        "item1": 26247,
        "item2": 26247,
        "item3": 26234,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10064,
        "idmap": 10810,
        "ten_npc": "BinhThươngSắt",
        "level": 88,
        "hp": 676,
        "sp": 363,
        "hpx": 35,
        "spx": 21,
        "int": 5,
        "atk": 38,
        "def": 38,
        "agi": 35,
        "item1": 26235,
        "item2": 26249,
        "item3": 26249,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10066,
        "idmap": 10811,
        "ten_npc": "Biêu Võ Sĩ",
        "level": 88,
        "hp": 676,
        "sp": 364,
        "hpx": 35,
        "spx": 21,
        "int": 7,
        "atk": 38,
        "def": 37,
        "agi": 33,
        "item1": 26249,
        "item2": 26235,
        "item3": 26235,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11005,
        "idmap": 10964,
        "ten_npc": "Ngưu Kim",
        "level": 88,
        "hp": 816,
        "sp": 352,
        "hpx": 45,
        "spx": 18,
        "int": 22,
        "atk": 57,
        "def": 30,
        "agi": 34,
        "item1": 26007,
        "item2": 27007,
        "item3": 27038,
        "item4": 0,
        "item5": 0,
        "item6": 47103
    },
    {
        "idnpc": 11067,
        "idmap": 10985,
        "ten_npc": "Mãn Sủng",
        "level": 88,
        "hp": 632,
        "sp": 386,
        "hpx": 28,
        "spx": 25,
        "int": 80,
        "atk": 12,
        "def": 34,
        "agi": 32,
        "item1": 27043,
        "item2": 26030,
        "item3": 27009,
        "item4": 0,
        "item5": 0,
        "item6": 47197
    },
    {
        "idnpc": 11142,
        "idmap": 11002,
        "ten_map": "Dai Do Dong Quan",
        "thanh": "Thanh Châu",
        "ten_npc": "Trương Kí (Đả)",
        "level": 88,
        "hp": 760,
        "sp": 279,
        "hpx": 49,
        "spx": 7,
        "int": 60,
        "atk": 14,
        "def": 49,
        "agi": 23,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47252
    },
    {
        "idnpc": 11147,
        "idmap": 11003,
        "ten_map": "Cân Giao Dông Quan",
        "thanh": "Thanh Châu",
        "ten_npc": "Ti Trạm",
        "level": 88,
        "hp": 742,
        "sp": 297,
        "hpx": 46,
        "spx": 10,
        "int": 14,
        "atk": 58,
        "def": 49,
        "agi": 25,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47268
    },
    {
        "idnpc": 11181,
        "idmap": 11012,
        "ten_map": "Dai Do Bac Hai",
        "thanh": "Thanh Châu",
        "ten_npc": "Triệu Hoắc",
        "level": 88,
        "hp": 456,
        "sp": 352,
        "hpx": 35,
        "spx": 19,
        "int": 17,
        "atk": 69,
        "def": 30,
        "agi": 35,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11186,
        "idmap": 11012,
        "ten_map": "Dai Do Bac Hai",
        "thanh": "Thanh Châu",
        "ten_npc": "Dương Mạc",
        "level": 88,
        "hp": 387,
        "sp": 383,
        "hpx": 24,
        "spx": 24,
        "int": 69,
        "atk": 19,
        "def": 18,
        "agi": 51,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11210,
        "idmap": 11031,
        "thanh": "Thanh Châu",
        "ten_npc": "Tư Mã Phu",
        "level": 88,
        "hp": 1057,
        "sp": 316,
        "hpx": 40,
        "spx": 13,
        "int": 70,
        "atk": 18,
        "def": 23,
        "agi": 42,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11217,
        "idmap": 11031,
        "thanh": "Thanh Châu",
        "ten_npc": "Hạ Hầu Nho",
        "level": 88,
        "hp": 1057,
        "sp": 334,
        "hpx": 40,
        "spx": 16,
        "int": 9,
        "atk": 74,
        "def": 30,
        "agi": 33,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12016,
        "idmap": 11041,
        "thanh": "Thanh Châu",
        "ten_npc": "Liêu Hóa",
        "level": 88,
        "hp": 676,
        "sp": 359,
        "hpx": 35,
        "spx": 20,
        "int": 13,
        "atk": 69,
        "def": 35,
        "agi": 39,
        "item1": 27043,
        "item2": 27008,
        "item3": 26029,
        "item4": 0,
        "item5": 0,
        "item6": 47125
    },
    {
        "idnpc": 12026,
        "idmap": 11041,
        "thanh": "Thanh Châu",
        "ten_npc": "Lôi Đồng",
        "level": 88,
        "hp": 705,
        "sp": 322,
        "hpx": 40,
        "spx": 14,
        "int": 28,
        "atk": 59,
        "def": 44,
        "agi": 18,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47323
    },
    {
        "idnpc": 13095,
        "idmap": 11541,
        "ten_map": "Dong Quy Than 11",
        "thanh": "Thanh Châu",
        "ten_npc": "Thổi Tuyết",
        "level": 88,
        "hp": 627,
        "sp": 242,
        "hpx": 20,
        "spx": 11,
        "int": 57,
        "atk": 60,
        "def": 9,
        "agi": 46,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14031,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Viên Thuật",
        "level": 88,
        "hp": 1420,
        "sp": 370,
        "hpx": 35,
        "spx": 22,
        "int": 28,
        "atk": 56,
        "def": 33,
        "agi": 37,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 47163
    },
    {
        "idnpc": 14037,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Điền Cảnh",
        "level": 88,
        "hp": 676,
        "sp": 380,
        "hpx": 35,
        "spx": 23,
        "int": 58,
        "atk": 19,
        "def": 37,
        "agi": 38,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 14062,
        "idmap": 11581,
        "thanh": "Thanh Châu",
        "ten_npc": "Hòang Tổ",
        "level": 88,
        "hp": 896,
        "sp": 366,
        "hpx": 35,
        "spx": 21,
        "int": 13,
        "atk": 68,
        "def": 38,
        "agi": 36,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14074,
        "idmap": 11582,
        "thanh": "Thanh Châu",
        "ten_npc": "Triệu Sầm",
        "level": 88,
        "hp": 676,
        "sp": 368,
        "hpx": 35,
        "spx": 22,
        "int": 21,
        "atk": 57,
        "def": 37,
        "agi": 39,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14180,
        "idmap": 11802,
        "ten_map": "Rung Dong Quan 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Tân Bình",
        "level": 88,
        "hp": 676,
        "sp": 383,
        "hpx": 35,
        "spx": 24,
        "int": 68,
        "atk": 11,
        "def": 38,
        "agi": 35,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 47194
    },
    {
        "idnpc": 14296,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Hồ Cư Nhi",
        "level": 88,
        "hp": 743,
        "sp": 346,
        "hpx": 35,
        "spx": 18,
        "int": 26,
        "atk": 58,
        "def": 36,
        "agi": 33,
        "item1": 26164,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14321,
        "idmap": 12002,
        "ten_map": "Dai Lo Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Tào Tính",
        "level": 88,
        "hp": 732,
        "sp": 383,
        "hpx": 35,
        "spx": 24,
        "int": 25,
        "atk": 52,
        "def": 36,
        "agi": 34,
        "item1": 26080,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14332,
        "idmap": 12002,
        "ten_map": "Dai Lo Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Hà Dược",
        "level": 88,
        "hp": 643,
        "sp": 414,
        "hpx": 37,
        "spx": 29,
        "int": 70,
        "atk": 13,
        "def": 26,
        "agi": 31,
        "item1": 26053,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47236
    },
    {
        "idnpc": 14406,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Dư Phu La",
        "level": 88,
        "hp": 911,
        "sp": 365,
        "hpx": 52,
        "spx": 21,
        "int": 11,
        "atk": 56,
        "def": 38,
        "agi": 28,
        "item1": 26002,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47092
    },
    {
        "idnpc": 14410,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Ninja Động Địa",
        "level": 88,
        "hp": 632,
        "sp": 303,
        "hpx": 35,
        "spx": 21,
        "int": 9,
        "atk": 68,
        "def": 30,
        "agi": 34,
        "item1": 27071,
        "item2": 32006,
        "item3": 27009,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14440,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Tiết Mô Hồ",
        "level": 88,
        "hp": 896,
        "sp": 365,
        "hpx": 35,
        "spx": 21,
        "int": 9,
        "atk": 61,
        "def": 28,
        "agi": 48,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14441,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Bỉnh Cư Hồ",
        "level": 88,
        "hp": 896,
        "sp": 364,
        "hpx": 35,
        "spx": 21,
        "int": 8,
        "atk": 60,
        "def": 28,
        "agi": 50,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14486,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Phổ Phú Lư",
        "level": 88,
        "hp": 682,
        "sp": 408,
        "hpx": 29,
        "spx": 28,
        "int": 9,
        "atk": 61,
        "def": 27,
        "agi": 49,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14522,
        "idmap": 12021,
        "ten_map": "Cua Thanh Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "TPNhân Áp Trại",
        "level": 88,
        "hp": 645,
        "sp": 303,
        "hpx": 23,
        "spx": 11,
        "int": 52,
        "atk": 61,
        "def": 15,
        "agi": 41,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14535,
        "idmap": 12022,
        "ten_map": "Dai Lo Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Trường Hoàng",
        "level": 88,
        "hp": 780,
        "sp": 310,
        "hpx": 45,
        "spx": 12,
        "int": 23,
        "atk": 65,
        "def": 16,
        "agi": 42,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14541,
        "idmap": 12023,
        "ten_map": "Diem Binh Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Đặng Hiền",
        "level": 88,
        "hp": 676,
        "sp": 408,
        "hpx": 28,
        "spx": 28,
        "int": 19,
        "atk": 61,
        "def": 25,
        "agi": 36,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47359
    },
    {
        "idnpc": 14562,
        "idmap": 12031,
        "ten_map": "Thon Lau Song",
        "thanh": "U Châu",
        "ten_npc": "Sĩ Nhất",
        "level": 88,
        "hp": 737,
        "sp": 310,
        "hpx": 38,
        "spx": 12,
        "int": 22,
        "atk": 62,
        "def": 45,
        "agi": 24,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14572,
        "idmap": 12031,
        "ten_map": "Thon Lau Song",
        "thanh": "U Châu",
        "ten_npc": "Hắc Khách",
        "level": 88,
        "hp": 657,
        "sp": 285,
        "hpx": 25,
        "spx": 8,
        "int": 9,
        "atk": 59,
        "def": 37,
        "agi": 34,
        "item1": 27071,
        "item2": 32006,
        "item3": 27009,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14573,
        "idmap": 12032,
        "thanh": "U Châu",
        "ten_npc": "Tử Khách",
        "level": 88,
        "hp": 657,
        "sp": 285,
        "hpx": 25,
        "spx": 8,
        "int": 9,
        "atk": 59,
        "def": 37,
        "agi": 34,
        "item1": 26070,
        "item2": 26062,
        "item3": 26030,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14574,
        "idmap": 12032,
        "thanh": "U Châu",
        "ten_npc": "Hồng Khách",
        "level": 88,
        "hp": 657,
        "sp": 285,
        "hpx": 25,
        "spx": 8,
        "int": 9,
        "atk": 59,
        "def": 37,
        "agi": 34,
        "item1": 27071,
        "item2": 32006,
        "item3": 27009,
        "item4": 37987,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14575,
        "idmap": 12032,
        "thanh": "U Châu",
        "ten_npc": "Lục Khách",
        "level": 88,
        "hp": 657,
        "sp": 285,
        "hpx": 25,
        "spx": 8,
        "int": 9,
        "atk": 59,
        "def": 37,
        "agi": 34,
        "item1": 26070,
        "item2": 26062,
        "item3": 26030,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14643,
        "idmap": 12063,
        "ten_map": "Hoang Cung Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Võ tướng dự bị",
        "level": 88,
        "hp": 516,
        "sp": 328,
        "hpx": 45,
        "spx": 15,
        "int": 18,
        "atk": 59,
        "def": 25,
        "agi": 41,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14648,
        "idmap": 12063,
        "ten_map": "Hoang Cung Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Ung Khải",
        "level": 88,
        "hp": 829,
        "sp": 359,
        "hpx": 53,
        "spx": 20,
        "int": 27,
        "atk": 57,
        "def": 23,
        "agi": 41,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14661,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Mạnh lân",
        "level": 88,
        "hp": 767,
        "sp": 279,
        "hpx": 43,
        "spx": 7,
        "int": 14,
        "atk": 71,
        "def": 24,
        "agi": 44,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14684,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "ĐôngThiệnkỳchủ",
        "level": 88,
        "hp": 632,
        "sp": 366,
        "hpx": 35,
        "spx": 21,
        "int": 13,
        "atk": 69,
        "def": 19,
        "agi": 45,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14685,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "TâyThiệnkỳchủ",
        "level": 88,
        "hp": 632,
        "sp": 368,
        "hpx": 35,
        "spx": 21,
        "int": 19,
        "atk": 75,
        "def": 13,
        "agi": 39,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15287,
        "idmap": 12242,
        "ten_map": "Nha Tro Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Sứ gỉa hung nô",
        "level": 88,
        "hp": 787,
        "sp": 359,
        "hpx": 39,
        "spx": 20,
        "int": 62,
        "atk": 13,
        "def": 29,
        "agi": 42,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17205,
        "idmap": 12581,
        "ten_map": "Mo Nguyet Trieu Ca 1",
        "thanh": "U Châu",
        "ten_npc": "Phi Hùng Binh",
        "level": 88,
        "hp": 696,
        "sp": 272,
        "hpx": 35,
        "spx": 16,
        "int": 23,
        "atk": 36,
        "def": 42,
        "agi": 34,
        "item1": 27011,
        "item2": 27011,
        "item3": 32023,
        "item4": 36009,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 10070,
        "idmap": 10811,
        "ten_npc": "Tà Võ Sĩ",
        "level": 89,
        "hp": 684,
        "sp": 367,
        "hpx": 36,
        "spx": 21,
        "int": 5,
        "atk": 41,
        "def": 38,
        "agi": 36,
        "item1": 26235,
        "item2": 26249,
        "item3": 26249,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10226,
        "idmap": 10951,
        "ten_npc": "Binh Khăn Vàng",
        "level": 89,
        "hp": 684,
        "sp": 293,
        "hpx": 36,
        "spx": 9,
        "int": 8,
        "atk": 64,
        "def": 38,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10234,
        "idmap": 10951,
        "ten_npc": "Dương Bỉnh",
        "level": 89,
        "hp": 705,
        "sp": 355,
        "hpx": 39,
        "spx": 19,
        "int": 17,
        "atk": 60,
        "def": 46,
        "agi": 27,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11103,
        "idmap": 10994,
        "ten_map": "Va Dai Dâu Va",
        "ten_npc": "Lương Tập",
        "level": 89,
        "hp": 684,
        "sp": 369,
        "hpx": 36,
        "spx": 21,
        "int": 11,
        "atk": 64,
        "def": 31,
        "agi": 44,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47200
    },
    {
        "idnpc": 11164,
        "idmap": 11011,
        "ten_map": "Bac Hai Thanh Mon",
        "thanh": "Thanh Châu",
        "ten_npc": "Triệu Dự",
        "level": 89,
        "hp": 600,
        "sp": 367,
        "hpx": 22,
        "spx": 21,
        "int": 70,
        "atk": 49,
        "def": 22,
        "agi": 35,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47301
    },
    {
        "idnpc": 11180,
        "idmap": 11012,
        "ten_map": "Dai Do Bac Hai",
        "thanh": "Thanh Châu",
        "ten_npc": "Lương Khoan",
        "level": 89,
        "hp": 464,
        "sp": 361,
        "hpx": 36,
        "spx": 20,
        "int": 14,
        "atk": 68,
        "def": 36,
        "agi": 36,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12006,
        "idmap": 11031,
        "thanh": "Thanh Châu",
        "ten_npc": "Ngô Ban",
        "level": 89,
        "hp": 723,
        "sp": 324,
        "hpx": 42,
        "spx": 14,
        "int": 24,
        "atk": 62,
        "def": 21,
        "agi": 45,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47345
    },
    {
        "idnpc": 12056,
        "idmap": 11203,
        "thanh": "Thanh Châu",
        "ten_npc": "Trần Đáo",
        "level": 89,
        "hp": 794,
        "sp": 355,
        "hpx": 39,
        "spx": 19,
        "int": 12,
        "atk": 65,
        "def": 34,
        "agi": 39,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47134
    },
    {
        "idnpc": 12173,
        "idmap": 11502,
        "ten_map": "Son Dong Thai Son 2",
        "thanh": "Thanh Châu",
        "ten_npc": "N.Bantiênphong",
        "level": 89,
        "hp": 3513,
        "sp": 1245,
        "hpx": 192,
        "spx": 164,
        "int": 174,
        "atk": 212,
        "def": 171,
        "agi": 195,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14018,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Lý Xiêm",
        "level": 89,
        "hp": 684,
        "sp": 368,
        "hpx": 36,
        "spx": 21,
        "int": 9,
        "atk": 52,
        "def": 33,
        "agi": 32,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14036,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Ngô Khang",
        "level": 89,
        "hp": 684,
        "sp": 370,
        "hpx": 36,
        "spx": 21,
        "int": 14,
        "atk": 44,
        "def": 36,
        "agi": 37,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14042,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Trần Vệ",
        "level": 89,
        "hp": 684,
        "sp": 369,
        "hpx": 36,
        "spx": 21,
        "int": 12,
        "atk": 55,
        "def": 35,
        "agi": 32,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14092,
        "idmap": 11583,
        "thanh": "Thanh Châu",
        "ten_npc": "Ngụy Tục",
        "level": 89,
        "hp": 684,
        "sp": 376,
        "hpx": 36,
        "spx": 22,
        "int": 34,
        "atk": 46,
        "def": 42,
        "agi": 33,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14237,
        "idmap": 11832,
        "ten_map": "Lang Ta Phi Lam 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Tô Điền",
        "level": 89,
        "hp": 706,
        "sp": 398,
        "hpx": 32,
        "spx": 26,
        "int": 11,
        "atk": 63,
        "def": 35,
        "agi": 43,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47287
    },
    {
        "idnpc": 14281,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Lý Lạc",
        "level": 89,
        "hp": 684,
        "sp": 375,
        "hpx": 36,
        "spx": 22,
        "int": 30,
        "atk": 55,
        "def": 36,
        "agi": 34,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14481,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Kim Kỳ",
        "level": 89,
        "hp": 706,
        "sp": 398,
        "hpx": 32,
        "spx": 26,
        "int": 12,
        "atk": 59,
        "def": 32,
        "agi": 47,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14548,
        "idmap": 12023,
        "ten_map": "Diem Binh Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Bàng Lạc",
        "level": 89,
        "hp": 728,
        "sp": 336,
        "hpx": 36,
        "spx": 16,
        "int": 15,
        "atk": 61,
        "def": 52,
        "agi": 28,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17206,
        "idmap": 12581,
        "ten_map": "Mo Nguyet Trieu Ca 1",
        "thanh": "U Châu",
        "ten_npc": "Phi Hùng Võ Sĩ",
        "level": 89,
        "hp": 718,
        "sp": 274,
        "hpx": 38,
        "spx": 16,
        "int": 22,
        "atk": 40,
        "def": 38,
        "agi": 34,
        "item1": 27011,
        "item2": 27011,
        "item3": 32023,
        "item4": 41010,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10252,
        "idmap": 10951,
        "ten_npc": "Hồi Đao Tốt",
        "level": 90,
        "hp": 753,
        "sp": 271,
        "hpx": 46,
        "spx": 5,
        "int": 10,
        "atk": 56,
        "def": 48,
        "agi": 14,
        "item1": 26095,
        "item2": 26095,
        "item3": 26251,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11138,
        "idmap": 11001,
        "ten_map": "Thanh Môn Dong Quan",
        "thanh": "Thanh Châu",
        "ten_npc": "Đỗ Ky",
        "level": 90,
        "hp": 739,
        "sp": 332,
        "hpx": 29,
        "spx": 15,
        "int": 87,
        "atk": 21,
        "def": 20,
        "agi": 38,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47209
    },
    {
        "idnpc": 11162,
        "idmap": 11011,
        "ten_map": "Bac Hai Thanh Mon",
        "thanh": "Thanh Châu",
        "ten_npc": "Văn Ất",
        "level": 90,
        "hp": 644,
        "sp": 369,
        "hpx": 21,
        "spx": 21,
        "int": 42,
        "atk": 61,
        "def": 23,
        "agi": 41,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12102,
        "idmap": 11307,
        "ten_map": "Dong Quan Quan Phu",
        "thanh": "Thanh Châu",
        "ten_npc": "Trương Dực",
        "level": 90,
        "hp": 909,
        "sp": 345,
        "hpx": 42,
        "spx": 17,
        "int": 38,
        "atk": 65,
        "def": 21,
        "agi": 26,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47247
    },
    {
        "idnpc": 12139,
        "idmap": 11432,
        "ten_map": "Dinh nui Thai Son",
        "thanh": "Thanh Châu",
        "ten_npc": "Dương Phong",
        "level": 90,
        "hp": 888,
        "sp": 357,
        "hpx": 46,
        "spx": 19,
        "int": 15,
        "atk": 75,
        "def": 23,
        "agi": 46,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13117,
        "idmap": 11560,
        "thanh": "Thanh Châu",
        "ten_npc": "Tôn Hoán",
        "level": 90,
        "hp": 829,
        "sp": 351,
        "hpx": 51,
        "spx": 18,
        "int": 17,
        "atk": 71,
        "def": 29,
        "agi": 32,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47329
    },
    {
        "idnpc": 14058,
        "idmap": 11581,
        "thanh": "Thanh Châu",
        "ten_npc": "Tả Linh",
        "level": 90,
        "hp": 712,
        "sp": 389,
        "hpx": 32,
        "spx": 24,
        "int": 65,
        "atk": 20,
        "def": 38,
        "agi": 41,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 47228
    },
    {
        "idnpc": 14122,
        "idmap": 11584,
        "thanh": "Thanh Châu",
        "ten_npc": "Trương Huân",
        "level": 90,
        "hp": 737,
        "sp": 374,
        "hpx": 36,
        "spx": 22,
        "int": 16,
        "atk": 68,
        "def": 33,
        "agi": 45,
        "item1": 26016,
        "item2": 27019,
        "item3": 26035,
        "item4": 0,
        "item5": 0,
        "item6": 47367
    },
    {
        "idnpc": 14254,
        "idmap": 11841,
        "ten_map": "Rung Vi Son 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Cung Bác Ngọc",
        "level": 90,
        "hp": 769,
        "sp": 371,
        "hpx": 34,
        "spx": 21,
        "int": 6,
        "atk": 66,
        "def": 49,
        "agi": 32,
        "item1": 27045,
        "item2": 32025,
        "item3": 27045,
        "item4": 0,
        "item5": 46034,
        "item6": 0
    },
    {
        "idnpc": 14273,
        "idmap": 12000,
        "ten_map": "U Chau",
        "thanh": "U Châu",
        "ten_npc": "Tưởng Kỳ",
        "level": 90,
        "hp": 737,
        "sp": 380,
        "hpx": 36,
        "spx": 23,
        "int": 34,
        "atk": 60,
        "def": 34,
        "agi": 33,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14412,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Ninja Lướt Hỏa",
        "level": 90,
        "hp": 647,
        "sp": 309,
        "hpx": 36,
        "spx": 21,
        "int": 8,
        "atk": 70,
        "def": 32,
        "agi": 35,
        "item1": 27071,
        "item2": 32006,
        "item3": 27009,
        "item4": 37987,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14517,
        "idmap": 12015,
        "ten_map": "N.Quan Vu TQ",
        "thanh": "U Châu",
        "ten_npc": "Mai Thành",
        "level": 90,
        "hp": 800,
        "sp": 339,
        "hpx": 39,
        "spx": 16,
        "int": 9,
        "atk": 48,
        "def": 68,
        "agi": 28,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14521,
        "idmap": 12018,
        "ten_map": "Quan Tra TQ",
        "thanh": "U Châu",
        "ten_npc": "ĐPNhân Áp Trại",
        "level": 90,
        "hp": 689,
        "sp": 289,
        "hpx": 21,
        "spx": 8,
        "int": 59,
        "atk": 65,
        "def": 13,
        "agi": 43,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14554,
        "idmap": 12023,
        "ten_map": "Diem Binh Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Phù Cấm",
        "level": 90,
        "hp": 819,
        "sp": 345,
        "hpx": 42,
        "spx": 17,
        "int": 15,
        "atk": 61,
        "def": 28,
        "agi": 45,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14636,
        "idmap": 12062,
        "ten_map": "Dai Do Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Bùi Yêu",
        "level": 90,
        "hp": 769,
        "sp": 308,
        "hpx": 34,
        "spx": 11,
        "int": 12,
        "atk": 67,
        "def": 62,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14662,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Chúc Bưu",
        "level": 90,
        "hp": 774,
        "sp": 345,
        "hpx": 42,
        "spx": 17,
        "int": 60,
        "atk": 19,
        "def": 13,
        "agi": 57,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15444,
        "idmap": 12409,
        "thanh": "U Châu",
        "ten_npc": "ÁC bộc nhân",
        "level": 90,
        "hp": 2582,
        "sp": 257,
        "hpx": 36,
        "spx": 13,
        "int": 135,
        "atk": 10,
        "def": 50,
        "agi": 120,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15447,
        "idmap": 12409,
        "thanh": "U Châu",
        "ten_npc": "Biển nóng",
        "level": 90,
        "hp": 3032,
        "sp": 251,
        "hpx": 36,
        "spx": 12,
        "int": 10,
        "atk": 135,
        "def": 60,
        "agi": 120,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17054,
        "idmap": 12519,
        "thanh": "U Châu",
        "ten_npc": "Thíêt Vệ Quân",
        "level": 90,
        "hp": 692,
        "sp": 371,
        "hpx": 36,
        "spx": 21,
        "int": 5,
        "atk": 41,
        "def": 39,
        "agi": 35,
        "item1": 27071,
        "item2": 27078,
        "item3": 27071,
        "item4": 33009,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10253,
        "idmap": 10961,
        "ten_npc": "Phi Mâu Tướng",
        "level": 91,
        "hp": 809,
        "sp": 273,
        "hpx": 54,
        "spx": 5,
        "int": 8,
        "atk": 52,
        "def": 45,
        "agi": 15,
        "item1": 26251,
        "item2": 26251,
        "item3": 26095,
        "item4": 42025,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11003,
        "idmap": 10963,
        "ten_npc": "Văn Thính",
        "level": 91,
        "hp": 745,
        "sp": 353,
        "hpx": 36,
        "spx": 18,
        "int": 20,
        "atk": 70,
        "def": 39,
        "agi": 39,
        "item1": 27043,
        "item2": 26029,
        "item3": 26068,
        "item4": 0,
        "item5": 0,
        "item6": 47149
    },
    {
        "idnpc": 11014,
        "idmap": 10965,
        "ten_npc": "Lữ Hổ",
        "level": 91,
        "hp": 745,
        "sp": 381,
        "hpx": 36,
        "spx": 22,
        "int": 25,
        "atk": 58,
        "def": 40,
        "agi": 40,
        "item1": 27042,
        "item2": 26060,
        "item3": 26060,
        "item4": 0,
        "item5": 0,
        "item6": 47260
    },
    {
        "idnpc": 11171,
        "idmap": 11011,
        "ten_map": "Bac Hai Thanh Mon",
        "thanh": "Thanh Châu",
        "ten_npc": "Khương Súc",
        "level": 91,
        "hp": 749,
        "sp": 366,
        "hpx": 37,
        "spx": 20,
        "int": 65,
        "atk": 22,
        "def": 29,
        "agi": 37,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11187,
        "idmap": 11012,
        "ten_map": "Dai Do Bac Hai",
        "thanh": "Thanh Châu",
        "ten_npc": "Ngô Chất",
        "level": 91,
        "hp": 718,
        "sp": 384,
        "hpx": 32,
        "spx": 23,
        "int": 70,
        "atk": 18,
        "def": 23,
        "agi": 44,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47332
    },
    {
        "idnpc": 12005,
        "idmap": 11031,
        "thanh": "Thanh Châu",
        "ten_npc": "Hướng Bàng",
        "level": 91,
        "hp": 700,
        "sp": 366,
        "hpx": 29,
        "spx": 20,
        "int": 20,
        "atk": 72,
        "def": 41,
        "agi": 27,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47062
    },
    {
        "idnpc": 12098,
        "idmap": 11307,
        "ten_map": "Dong Quan Quan Phu",
        "thanh": "Thanh Châu",
        "ten_npc": "Lý Khôi",
        "level": 91,
        "hp": 855,
        "sp": 322,
        "hpx": 32,
        "spx": 13,
        "int": 64,
        "atk": 48,
        "def": 36,
        "agi": 17,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47211
    },
    {
        "idnpc": 12125,
        "idmap": 11411,
        "ten_map": "Khuc Trac Khong Mieu",
        "thanh": "Thanh Châu",
        "ten_npc": "Xạ Hoàn",
        "level": 91,
        "hp": 879,
        "sp": 328,
        "hpx": 36,
        "spx": 14,
        "int": 69,
        "atk": 14,
        "def": 31,
        "agi": 46,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47296
    },
    {
        "idnpc": 12164,
        "idmap": 11502,
        "ten_map": "Son Dong Thai Son 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Trình Kỳ",
        "level": 91,
        "hp": 1014,
        "sp": 415,
        "hpx": 43,
        "spx": 28,
        "int": 85,
        "atk": 16,
        "def": 18,
        "agi": 35,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13062,
        "idmap": 11523,
        "ten_map": "Son Dong Thai Son 21",
        "thanh": "Thanh Châu",
        "ten_npc": "Chu Nhiên",
        "level": 91,
        "hp": 745,
        "sp": 353,
        "hpx": 36,
        "spx": 18,
        "int": 22,
        "atk": 68,
        "def": 41,
        "agi": 37,
        "item1": 26161,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47075
    },
    {
        "idnpc": 13093,
        "idmap": 11541,
        "ten_map": "Dong Quy Than 11",
        "thanh": "Thanh Châu",
        "ten_npc": "Tôn Thiều",
        "level": 91,
        "hp": 706,
        "sp": 298,
        "hpx": 30,
        "spx": 9,
        "int": 58,
        "atk": 73,
        "def": 8,
        "agi": 31,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47258
    },
    {
        "idnpc": 14014,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Lý Túc",
        "level": 91,
        "hp": 745,
        "sp": 353,
        "hpx": 36,
        "spx": 18,
        "int": 24,
        "atk": 64,
        "def": 39,
        "agi": 41,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 47229
    },
    {
        "idnpc": 14028,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Từ Vinh",
        "level": 91,
        "hp": 745,
        "sp": 381,
        "hpx": 36,
        "spx": 23,
        "int": 26,
        "atk": 63,
        "def": 41,
        "agi": 33,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 49001,
        "item6": 47217
    },
    {
        "idnpc": 14069,
        "idmap": 11581,
        "thanh": "Thanh Châu",
        "ten_npc": "Lôi Bạo",
        "level": 91,
        "hp": 745,
        "sp": 377,
        "hpx": 36,
        "spx": 22,
        "int": 13,
        "atk": 73,
        "def": 36,
        "agi": 42,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14163,
        "idmap": 11801,
        "ten_map": "Rung Dong Quan 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Hà Ngẩu",
        "level": 91,
        "hp": 745,
        "sp": 387,
        "hpx": 36,
        "spx": 23,
        "int": 44,
        "atk": 38,
        "def": 45,
        "agi": 35,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14169,
        "idmap": 11802,
        "ten_map": "Rung Dong Quan 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Triệu Nga",
        "level": 91,
        "hp": 745,
        "sp": 377,
        "hpx": 36,
        "spx": 22,
        "int": 14,
        "atk": 67,
        "def": 35,
        "agi": 43,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 47001
    },
    {
        "idnpc": 14305,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Viên Hoan",
        "level": 91,
        "hp": 854,
        "sp": 395,
        "hpx": 28,
        "spx": 25,
        "int": 72,
        "atk": 24,
        "def": 30,
        "agi": 33,
        "item1": 26157,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47213
    },
    {
        "idnpc": 14349,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Lưu Huân",
        "level": 91,
        "hp": 836,
        "sp": 383,
        "hpx": 36,
        "spx": 23,
        "int": 30,
        "atk": 57,
        "def": 32,
        "agi": 34,
        "item1": 26026,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14437,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Quỷ Bí Vàng",
        "level": 91,
        "hp": 836,
        "sp": 313,
        "hpx": 36,
        "spx": 22,
        "int": 13,
        "atk": 62,
        "def": 41,
        "agi": 34,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14534,
        "idmap": 12022,
        "ten_map": "Dai Lo Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Hầu Tuyển",
        "level": 91,
        "hp": 825,
        "sp": 341,
        "hpx": 42,
        "spx": 16,
        "int": 6,
        "atk": 62,
        "def": 52,
        "agi": 30,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14658,
        "idmap": 12063,
        "ten_map": "Hoang Cung Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Đớilaiđộngchủ",
        "level": 91,
        "hp": 822,
        "sp": 353,
        "hpx": 34,
        "spx": 18,
        "int": 47,
        "atk": 53,
        "def": 20,
        "agi": 38,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17058,
        "idmap": 12521,
        "ten_map": "S.Dong KhVang 1",
        "thanh": "U Châu",
        "ten_npc": "Nhất Giai Binh",
        "level": 91,
        "hp": 700,
        "sp": 375,
        "hpx": 36,
        "spx": 21,
        "int": 5,
        "atk": 44,
        "def": 35,
        "agi": 36,
        "item1": 27078,
        "item2": 27071,
        "item3": 27078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17336,
        "idmap": 12711,
        "ten_map": "Blinh Chien Truong Dung Cam",
        "thanh": "U Châu",
        "ten_npc": "Binh Giác Man",
        "level": 91,
        "hp": 700,
        "sp": 374,
        "hpx": 36,
        "spx": 21,
        "int": 4,
        "atk": 41,
        "def": 38,
        "agi": 37,
        "item1": 26070,
        "item2": 26070,
        "item3": 27071,
        "item4": 43030,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10216,
        "idmap": 10918,
        "ten_npc": "Lương.Tr.Ninh",
        "level": 92,
        "hp": 730,
        "sp": 389,
        "hpx": 33,
        "spx": 23,
        "int": 40,
        "atk": 58,
        "def": 35,
        "agi": 35,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10225,
        "idmap": 10951,
        "ten_npc": "Binh Khăn Vàng",
        "level": 92,
        "hp": 768,
        "sp": 306,
        "hpx": 39,
        "spx": 10,
        "int": 11,
        "atk": 71,
        "def": 41,
        "agi": 11,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10235,
        "idmap": 10951,
        "ten_npc": "Diêm Phố",
        "level": 92,
        "hp": 780,
        "sp": 374,
        "hpx": 41,
        "spx": 21,
        "int": 67,
        "atk": 25,
        "def": 50,
        "agi": 12,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47280
    },
    {
        "idnpc": 11029,
        "idmap": 10965,
        "ten_npc": "Cao Lạm",
        "level": 92,
        "hp": 754,
        "sp": 368,
        "hpx": 37,
        "spx": 20,
        "int": 21,
        "atk": 68,
        "def": 42,
        "agi": 36,
        "item1": 27068,
        "item2": 26116,
        "item3": 26020,
        "item4": 0,
        "item5": 0,
        "item6": 47120
    },
    {
        "idnpc": 11139,
        "idmap": 11001,
        "ten_map": "Thanh Môn Dong Quan",
        "thanh": "Thanh Châu",
        "ten_npc": "Triệu Nghiêm",
        "level": 92,
        "hp": 758,
        "sp": 355,
        "hpx": 30,
        "spx": 18,
        "int": 83,
        "atk": 17,
        "def": 27,
        "agi": 41,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47206
    },
    {
        "idnpc": 11151,
        "idmap": 11011,
        "ten_map": "Bac Hai Thanh Mon",
        "thanh": "Thanh Châu",
        "ten_npc": "Lưu Nhược",
        "level": 92,
        "hp": 851,
        "sp": 337,
        "hpx": 45,
        "spx": 15,
        "int": 12,
        "atk": 66,
        "def": 56,
        "agi": 20,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47294
    },
    {
        "idnpc": 11152,
        "idmap": 11011,
        "ten_map": "Bac Hai Thanh Mon",
        "thanh": "Thanh Châu",
        "ten_npc": "Lưu Triển",
        "level": 92,
        "hp": 863,
        "sp": 318,
        "hpx": 47,
        "spx": 12,
        "int": 13,
        "atk": 68,
        "def": 52,
        "agi": 21,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47311
    },
    {
        "idnpc": 11172,
        "idmap": 11011,
        "ten_map": "Bac Hai Thanh Mon",
        "thanh": "Thanh Châu",
        "ten_npc": "Viên Mẫn",
        "level": 92,
        "hp": 749,
        "sp": 355,
        "hpx": 36,
        "spx": 18,
        "int": 20,
        "atk": 67,
        "def": 31,
        "agi": 42,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11179,
        "idmap": 11012,
        "ten_map": "Dai Do Bac Hai",
        "thanh": "Thanh Châu",
        "ten_npc": "Hạ Hầu Tồn",
        "level": 92,
        "hp": 681,
        "sp": 399,
        "hpx": 25,
        "spx": 25,
        "int": 45,
        "atk": 68,
        "def": 21,
        "agi": 32,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11192,
        "idmap": 11013,
        "ten_map": "Rung Bac Hai",
        "thanh": "Thanh Châu",
        "ten_npc": "Diêm Hành",
        "level": 92,
        "hp": 718,
        "sp": 362,
        "hpx": 31,
        "spx": 19,
        "int": 65,
        "atk": 51,
        "def": 11,
        "agi": 39,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11218,
        "idmap": 11031,
        "thanh": "Thanh Châu",
        "ten_npc": "Phí Diệu",
        "level": 92,
        "hp": 1108,
        "sp": 362,
        "hpx": 42,
        "spx": 19,
        "int": 11,
        "atk": 71,
        "def": 26,
        "agi": 44,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12011,
        "idmap": 11041,
        "thanh": "Thanh Châu",
        "ten_npc": "Châu Sáng",
        "level": 92,
        "hp": 754,
        "sp": 383,
        "hpx": 37,
        "spx": 22,
        "int": 19,
        "atk": 72,
        "def": 37,
        "agi": 38,
        "item1": 27007,
        "item2": 27068,
        "item3": 27009,
        "item4": 0,
        "item5": 0,
        "item6": 47046
    },
    {
        "idnpc": 12103,
        "idmap": 11307,
        "ten_map": "Dong Quan Quan Phu",
        "thanh": "Thanh Châu",
        "ten_npc": "Trương Nghi",
        "level": 92,
        "hp": 918,
        "sp": 362,
        "hpx": 41,
        "spx": 19,
        "int": 18,
        "atk": 68,
        "def": 44,
        "agi": 24,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47235
    },
    {
        "idnpc": 12126,
        "idmap": 11411,
        "ten_map": "Khuc Trac Khong Mieu",
        "thanh": "Thanh Châu",
        "ten_npc": "Lý Triều",
        "level": 92,
        "hp": 850,
        "sp": 368,
        "hpx": 30,
        "spx": 20,
        "int": 20,
        "atk": 72,
        "def": 30,
        "agi": 42,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13027,
        "idmap": 11505,
        "ten_map": "Son Dong Thai Son 5",
        "thanh": "Thanh Châu",
        "ten_npc": "Phan Chương",
        "level": 92,
        "hp": 754,
        "sp": 368,
        "hpx": 37,
        "spx": 20,
        "int": 21,
        "atk": 70,
        "def": 36,
        "agi": 40,
        "item1": 27062,
        "item2": 27063,
        "item3": 26068,
        "item4": 0,
        "item5": 0,
        "item6": 47233
    },
    {
        "idnpc": 13121,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Trương Hy",
        "level": 92,
        "hp": 768,
        "sp": 417,
        "hpx": 39,
        "spx": 28,
        "int": 71,
        "atk": 14,
        "def": 25,
        "agi": 39,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14052,
        "idmap": 11581,
        "ten_map": "Mo Khoang Dong Quan 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Quách Đồ",
        "level": 92,
        "hp": 754,
        "sp": 401,
        "hpx": 37,
        "spx": 25,
        "int": 79,
        "atk": 19,
        "def": 39,
        "agi": 35,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 47180
    },
    {
        "idnpc": 14241,
        "idmap": 11841,
        "ten_map": "Rung Vi Son 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Lý Điều",
        "level": 92,
        "hp": 846,
        "sp": 380,
        "hpx": 37,
        "spx": 22,
        "int": 9,
        "atk": 68,
        "def": 42,
        "agi": 35,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14255,
        "idmap": 11841,
        "ten_map": "Rung Vi Son 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Lý Văn Hầu",
        "level": 92,
        "hp": 832,
        "sp": 355,
        "hpx": 42,
        "spx": 18,
        "int": 10,
        "atk": 63,
        "def": 46,
        "agi": 35,
        "item1": 32025,
        "item2": 27045,
        "item3": 32025,
        "item4": 0,
        "item5": 46034,
        "item6": 0
    },
    {
        "idnpc": 14274,
        "idmap": 12000,
        "ten_map": "U Chau",
        "thanh": "U Châu",
        "ten_npc": "Hàn Mãnh",
        "level": 92,
        "hp": 754,
        "sp": 383,
        "hpx": 37,
        "spx": 23,
        "int": 21,
        "atk": 70,
        "def": 35,
        "agi": 39,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47202
    },
    {
        "idnpc": 14438,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Ti Nô Mẫu Ly",
        "level": 92,
        "hp": 846,
        "sp": 397,
        "hpx": 37,
        "spx": 25,
        "int": 66,
        "atk": 32,
        "def": 29,
        "agi": 28,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47130
    },
    {
        "idnpc": 14539,
        "idmap": 12023,
        "ten_map": "Diem Binh Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Lưu Quý",
        "level": 92,
        "hp": 832,
        "sp": 349,
        "hpx": 42,
        "spx": 17,
        "int": 16,
        "atk": 65,
        "def": 46,
        "agi": 27,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47303
    },
    {
        "idnpc": 14563,
        "idmap": 12031,
        "ten_map": "Thon Lau Song",
        "thanh": "U Châu",
        "ten_npc": "Sĩ Hữu",
        "level": 92,
        "hp": 838,
        "sp": 417,
        "hpx": 43,
        "spx": 28,
        "int": 64,
        "atk": 17,
        "def": 31,
        "agi": 33,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47273
    },
    {
        "idnpc": 14595,
        "idmap": 12051,
        "ten_map": "Tan Tich Lac Hai",
        "thanh": "U Châu",
        "ten_npc": "Thành Luật",
        "level": 92,
        "hp": 826,
        "sp": 368,
        "hpx": 41,
        "spx": 20,
        "int": 11,
        "atk": 81,
        "def": 27,
        "agi": 33,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14623,
        "idmap": 12061,
        "ten_map": "Diep Thanh Thanh Mon",
        "thanh": "U Châu",
        "ten_npc": "Khôngtướcvương",
        "level": 92,
        "hp": 758,
        "sp": 448,
        "hpx": 30,
        "spx": 33,
        "int": 62,
        "atk": 7,
        "def": 31,
        "agi": 53,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14627,
        "idmap": 12062,
        "ten_map": "Dai Do Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Lưu Tuấn",
        "level": 92,
        "hp": 906,
        "sp": 368,
        "hpx": 54,
        "spx": 20,
        "int": 13,
        "atk": 67,
        "def": 40,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14628,
        "idmap": 12062,
        "ten_map": "Dai Do Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Mã Hán",
        "level": 92,
        "hp": 845,
        "sp": 355,
        "hpx": 44,
        "spx": 18,
        "int": 21,
        "atk": 68,
        "def": 41,
        "agi": 24,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14640,
        "idmap": 12062,
        "ten_map": "Dai Do Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Cam Ngạn",
        "level": 92,
        "hp": 795,
        "sp": 380,
        "hpx": 36,
        "spx": 22,
        "int": 79,
        "atk": 14,
        "def": 28,
        "agi": 37,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15304,
        "idmap": 12267,
        "thanh": "U Châu",
        "ten_npc": "Nữ Vu Di Châu",
        "level": 92,
        "hp": 734,
        "sp": 294,
        "hpx": 41,
        "spx": 8,
        "int": 45,
        "atk": 36,
        "def": 22,
        "agi": 32,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17154,
        "idmap": 12554,
        "thanh": "U Châu",
        "ten_npc": "Đội Đại Đao",
        "level": 92,
        "hp": 708,
        "sp": 314,
        "hpx": 37,
        "spx": 22,
        "int": 6,
        "atk": 46,
        "def": 34,
        "agi": 38,
        "item1": 26070,
        "item2": 26104,
        "item3": 26011,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17207,
        "idmap": 12581,
        "ten_map": "Mo Nguyet Trieu Ca 1",
        "thanh": "U Châu",
        "ten_npc": "PhiHùngTQuân",
        "level": 92,
        "hp": 767,
        "sp": 339,
        "hpx": 40,
        "spx": 26,
        "int": 24,
        "atk": 43,
        "def": 39,
        "agi": 34,
        "item1": 27071,
        "item2": 26094,
        "item3": 32008,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17209,
        "idmap": 12581,
        "ten_map": "Mo Nguyet Trieu Ca 1",
        "thanh": "U Châu",
        "ten_npc": "PhiHùngQuânSĩ",
        "level": 92,
        "hp": 767,
        "sp": 339,
        "hpx": 40,
        "spx": 26,
        "int": 24,
        "atk": 43,
        "def": 38,
        "agi": 35,
        "item1": 26011,
        "item2": 26070,
        "item3": 26094,
        "item4": 36020,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17258,
        "idmap": 12588,
        "thanh": "U Châu",
        "ten_npc": "Hộ Quân",
        "level": 92,
        "hp": 740,
        "sp": 325,
        "hpx": 42,
        "spx": 13,
        "int": 9,
        "atk": 54,
        "def": 47,
        "agi": 18,
        "item1": 27077,
        "item2": 32025,
        "item3": 27077,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17261,
        "idmap": 12588,
        "thanh": "U Châu",
        "ten_npc": "Điển Quân",
        "level": 92,
        "hp": 722,
        "sp": 343,
        "hpx": 39,
        "spx": 16,
        "int": 11,
        "atk": 55,
        "def": 46,
        "agi": 16,
        "item1": 32008,
        "item2": 27088,
        "item3": 32008,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17337,
        "idmap": 12711,
        "ten_map": "Blinh Chien Truong Dung Cam",
        "thanh": "U Châu",
        "ten_npc": "Binh Tôn Man",
        "level": 92,
        "hp": 708,
        "sp": 379,
        "hpx": 37,
        "spx": 22,
        "int": 5,
        "atk": 44,
        "def": 37,
        "agi": 38,
        "item1": 27071,
        "item2": 27071,
        "item3": 26070,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17435,
        "idmap": 12808,
        "ten_map": "Map nguoi rom TQ",
        "thanh": "U Châu",
        "ten_npc": "Lương ChâuBinh",
        "level": 92,
        "hp": 722,
        "sp": 343,
        "hpx": 39,
        "spx": 16,
        "int": 9,
        "atk": 47,
        "def": 46,
        "agi": 26,
        "item1": 26011,
        "item2": 26070,
        "item3": 26011,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17436,
        "idmap": 12808,
        "ten_map": "Map nguoi rom TQ",
        "thanh": "U Châu",
        "ten_npc": "Tuần Tiêu Binh",
        "level": 92,
        "hp": 728,
        "sp": 337,
        "hpx": 40,
        "spx": 15,
        "int": 10,
        "atk": 45,
        "def": 48,
        "agi": 25,
        "item1": 26070,
        "item2": 26011,
        "item3": 26070,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10018,
        "idmap": 10803,
        "ten_npc": "Triệu.H.Cương",
        "level": 93,
        "hp": 763,
        "sp": 389,
        "hpx": 37,
        "spx": 23,
        "int": 26,
        "atk": 60,
        "def": 36,
        "agi": 44,
        "item1": 32006,
        "item2": 0,
        "item3": 0,
        "item4": 48006,
        "item5": 49001,
        "item6": 47004
    },
    {
        "idnpc": 10056,
        "idmap": 10809,
        "ten_npc": "Binh Dao Mi",
        "level": 93,
        "hp": 716,
        "sp": 382,
        "hpx": 37,
        "spx": 22,
        "int": 5,
        "atk": 44,
        "def": 38,
        "agi": 37,
        "item1": 26249,
        "item2": 26235,
        "item3": 26249,
        "item4": 37968,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10192,
        "idmap": 10910,
        "ten_npc": "Ma Sư Hắt Sơn",
        "level": 93,
        "hp": 733,
        "sp": 283,
        "hpx": 40,
        "spx": 6,
        "int": 62,
        "atk": 48,
        "def": 22,
        "agi": 37,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10233,
        "idmap": 10951,
        "ten_npc": "Dương Nhâm",
        "level": 93,
        "hp": 786,
        "sp": 364,
        "hpx": 41,
        "spx": 19,
        "int": 20,
        "atk": 62,
        "def": 45,
        "agi": 28,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47321
    },
    {
        "idnpc": 11176,
        "idmap": 11012,
        "ten_map": "Dai Do Bac Hai",
        "thanh": "Thanh Châu",
        "ten_npc": "Tuần Huy",
        "level": 93,
        "hp": 792,
        "sp": 370,
        "hpx": 42,
        "spx": 20,
        "int": 69,
        "atk": 12,
        "def": 46,
        "agi": 27,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47333
    },
    {
        "idnpc": 12064,
        "idmap": 11242,
        "ten_map": "Nha Tro Bac Hai",
        "thanh": "Thanh Châu",
        "ten_npc": "H.Thừa Ngản",
        "level": 93,
        "hp": 809,
        "sp": 403,
        "hpx": 37,
        "spx": 25,
        "int": 72,
        "atk": 22,
        "def": 33,
        "agi": 27,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47173
    },
    {
        "idnpc": 12069,
        "idmap": 11242,
        "ten_map": "Nha Tro Bac Hai",
        "thanh": "Thanh Châu",
        "ten_npc": "Liêu Lập",
        "level": 93,
        "hp": 789,
        "sp": 327,
        "hpx": 34,
        "spx": 13,
        "int": 73,
        "atk": 23,
        "def": 31,
        "agi": 42,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47178
    },
    {
        "idnpc": 13015,
        "idmap": 11503,
        "ten_map": "Son Dong Thai Son 3",
        "thanh": "Thanh Châu",
        "ten_npc": "Tôn Du",
        "level": 93,
        "hp": 783,
        "sp": 414,
        "hpx": 33,
        "spx": 27,
        "int": 20,
        "atk": 63,
        "def": 42,
        "agi": 29,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47176
    },
    {
        "idnpc": 13036,
        "idmap": 11509,
        "ten_map": "Son Dong Thai Son 9",
        "thanh": "Thanh Châu",
        "ten_npc": "Đại Kiều",
        "level": 93,
        "hp": 960,
        "sp": 318,
        "hpx": 38,
        "spx": 20,
        "int": 20,
        "atk": 67,
        "def": 34,
        "agi": 37,
        "item1": 27029,
        "item2": 0,
        "item3": 0,
        "item4": 48023,
        "item5": 0,
        "item6": 47094
    },
    {
        "idnpc": 13063,
        "idmap": 11524,
        "ten_map": "Son Dong Thai Son 22",
        "thanh": "Thanh Châu",
        "ten_npc": "Chu Hằng",
        "level": 93,
        "hp": 780,
        "sp": 370,
        "hpx": 40,
        "spx": 20,
        "int": 22,
        "atk": 70,
        "def": 28,
        "agi": 34,
        "item1": 26165,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47164
    },
    {
        "idnpc": 13088,
        "idmap": 11536,
        "ten_map": "Dong Quy Than 6",
        "thanh": "Thanh Châu",
        "ten_npc": "Trương Thừa",
        "level": 93,
        "hp": 674,
        "sp": 451,
        "hpx": 23,
        "spx": 33,
        "int": 70,
        "atk": 29,
        "def": 43,
        "agi": 18,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47288
    },
    {
        "idnpc": 14039,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Lý Hắt",
        "level": 93,
        "hp": 763,
        "sp": 385,
        "hpx": 37,
        "spx": 22,
        "int": 15,
        "atk": 72,
        "def": 48,
        "agi": 31,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14045,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Trương Tế",
        "level": 93,
        "hp": 763,
        "sp": 376,
        "hpx": 37,
        "spx": 21,
        "int": 32,
        "atk": 64,
        "def": 36,
        "agi": 35,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 49001,
        "item6": 47255
    },
    {
        "idnpc": 14348,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Kiều Sanh",
        "level": 93,
        "hp": 868,
        "sp": 389,
        "hpx": 37,
        "spx": 23,
        "int": 31,
        "atk": 55,
        "def": 36,
        "agi": 34,
        "item1": 26026,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14551,
        "idmap": 12023,
        "ten_map": "Diem Binh Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Trịnh Độ",
        "level": 93,
        "hp": 870,
        "sp": 339,
        "hpx": 47,
        "spx": 15,
        "int": 67,
        "atk": 25,
        "def": 50,
        "agi": 12,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47313
    },
    {
        "idnpc": 14559,
        "idmap": 12023,
        "ten_map": "Diem Binh Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Dương Phong",
        "level": 93,
        "hp": 826,
        "sp": 302,
        "hpx": 40,
        "spx": 9,
        "int": 64,
        "atk": 56,
        "def": 14,
        "agi": 33,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47205
    },
    {
        "idnpc": 14612,
        "idmap": 12061,
        "ten_map": "Diep Thanh Thanh Mon",
        "thanh": "U Châu",
        "ten_npc": "Ngịch Cơ",
        "level": 93,
        "hp": 833,
        "sp": 386,
        "hpx": 41,
        "spx": 23,
        "int": 16,
        "atk": 71,
        "def": 22,
        "agi": 41,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14664,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Chúc Tuyền",
        "level": 93,
        "hp": 805,
        "sp": 389,
        "hpx": 44,
        "spx": 23,
        "int": 75,
        "atk": 23,
        "def": 17,
        "agi": 45,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14665,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Chúc Anh",
        "level": 93,
        "hp": 799,
        "sp": 376,
        "hpx": 43,
        "spx": 21,
        "int": 72,
        "atk": 25,
        "def": 18,
        "agi": 47,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14669,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Sĩ An",
        "level": 93,
        "hp": 799,
        "sp": 401,
        "hpx": 43,
        "spx": 25,
        "int": 18,
        "atk": 69,
        "def": 29,
        "agi": 42,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15274,
        "idmap": 12241,
        "ten_map": "Nha Tro Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Người làm dữ",
        "level": 93,
        "hp": 809,
        "sp": 289,
        "hpx": 37,
        "spx": 7,
        "int": 4,
        "atk": 62,
        "def": 41,
        "agi": 32,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17155,
        "idmap": 12555,
        "thanh": "U Châu",
        "ten_npc": "Dúc Liệt sĩ",
        "level": 93,
        "hp": 716,
        "sp": 317,
        "hpx": 37,
        "spx": 22,
        "int": 5,
        "atk": 46,
        "def": 35,
        "agi": 38,
        "item1": 26011,
        "item2": 26069,
        "item3": 32025,
        "item4": 37968,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17156,
        "idmap": 12556,
        "thanh": "U Châu",
        "ten_npc": "Khư Hổ Binh",
        "level": 93,
        "hp": 716,
        "sp": 318,
        "hpx": 37,
        "spx": 22,
        "int": 6,
        "atk": 46,
        "def": 33,
        "agi": 39,
        "item1": 26070,
        "item2": 26104,
        "item3": 26011,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17210,
        "idmap": 12581,
        "ten_map": "Mo Nguyet Trieu Ca 1",
        "thanh": "U Châu",
        "ten_npc": "PhiHùngQTrưởng",
        "level": 93,
        "hp": 775,
        "sp": 343,
        "hpx": 40,
        "spx": 26,
        "int": 25,
        "atk": 39,
        "def": 42,
        "agi": 34,
        "item1": 26070,
        "item2": 26105,
        "item3": 26011,
        "item4": 38006,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17259,
        "idmap": 12588,
        "thanh": "U Châu",
        "ten_npc": "Giám Quân",
        "level": 93,
        "hp": 752,
        "sp": 345,
        "hpx": 43,
        "spx": 16,
        "int": 9,
        "atk": 48,
        "def": 47,
        "agi": 20,
        "item1": 26249,
        "item2": 26236,
        "item3": 26249,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17260,
        "idmap": 12588,
        "thanh": "U Châu",
        "ten_npc": "Tuyên Quân",
        "level": 93,
        "hp": 758,
        "sp": 296,
        "hpx": 44,
        "spx": 8,
        "int": 6,
        "atk": 66,
        "def": 51,
        "agi": 8,
        "item1": 26235,
        "item2": 26250,
        "item3": 26235,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17437,
        "idmap": 12808,
        "ten_map": "Map nguoi rom TQ",
        "thanh": "U Châu",
        "ten_npc": "KKhương Binh",
        "level": 93,
        "hp": 733,
        "sp": 321,
        "hpx": 40,
        "spx": 12,
        "int": 6,
        "atk": 56,
        "def": 41,
        "agi": 28,
        "item1": 26011,
        "item2": 26070,
        "item3": 26011,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10057,
        "idmap": 10809,
        "ten_npc": "Binh Kiếm Sắt",
        "level": 94,
        "hp": 724,
        "sp": 386,
        "hpx": 38,
        "spx": 22,
        "int": 6,
        "atk": 36,
        "def": 39,
        "agi": 43,
        "item1": 26235,
        "item2": 26249,
        "item3": 26235,
        "item4": 50001,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10240,
        "idmap": 10951,
        "ten_npc": "đại đao tặc",
        "level": 94,
        "hp": 789,
        "sp": 285,
        "hpx": 48,
        "spx": 6,
        "int": 6,
        "atk": 58,
        "def": 55,
        "agi": 11,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10241,
        "idmap": 10951,
        "ten_npc": "Loan đao tặc",
        "level": 94,
        "hp": 789,
        "sp": 285,
        "hpx": 48,
        "spx": 6,
        "int": 6,
        "atk": 58,
        "def": 55,
        "agi": 11,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10254,
        "idmap": 10961,
        "ten_npc": "Kiêu Dũng Binh",
        "level": 94,
        "hp": 789,
        "sp": 292,
        "hpx": 48,
        "spx": 7,
        "int": 10,
        "atk": 53,
        "def": 32,
        "agi": 44,
        "item1": 32026,
        "item2": 32026,
        "item3": 27051,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10257,
        "idmap": 10961,
        "ten_npc": "Nghê Hải Tặc",
        "level": 94,
        "hp": 767,
        "sp": 304,
        "hpx": 37,
        "spx": 9,
        "int": 11,
        "atk": 55,
        "def": 51,
        "agi": 21,
        "item1": 26139,
        "item2": 26139,
        "item3": 26203,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11042,
        "idmap": 10966,
        "ten_npc": "Tào Thuần",
        "level": 94,
        "hp": 771,
        "sp": 394,
        "hpx": 38,
        "spx": 23,
        "int": 30,
        "atk": 61,
        "def": 37,
        "agi": 39,
        "item1": 27007,
        "item2": 26029,
        "item3": 26068,
        "item4": 0,
        "item5": 0,
        "item6": 47244
    },
    {
        "idnpc": 11048,
        "idmap": 10966,
        "ten_npc": "Hứa Du",
        "level": 94,
        "hp": 749,
        "sp": 411,
        "hpx": 34,
        "spx": 26,
        "int": 84,
        "atk": 13,
        "def": 33,
        "agi": 38,
        "item1": 27063,
        "item2": 26020,
        "item3": 26116,
        "item4": 0,
        "item5": 0,
        "item6": 47161
    },
    {
        "idnpc": 11157,
        "idmap": 11011,
        "ten_map": "Bac Hai Thanh Mon",
        "thanh": "Thanh Châu",
        "ten_npc": "Diêm On",
        "level": 94,
        "hp": 715,
        "sp": 416,
        "hpx": 21,
        "spx": 27,
        "int": 25,
        "atk": 51,
        "def": 27,
        "agi": 64,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12110,
        "idmap": 11401,
        "ten_map": "Dinh Mat Vi Son",
        "thanh": "Thanh Châu",
        "ten_npc": "Đổng Hòa",
        "level": 94,
        "hp": 927,
        "sp": 348,
        "hpx": 40,
        "spx": 16,
        "int": 68,
        "atk": 7,
        "def": 55,
        "agi": 31,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47234
    },
    {
        "idnpc": 13037,
        "idmap": 11509,
        "ten_map": "Son Dong Thai Son 9",
        "thanh": "Thanh Châu",
        "ten_npc": "Tiểu Kiều",
        "level": 94,
        "hp": 972,
        "sp": 369,
        "hpx": 34,
        "spx": 28,
        "int": 68,
        "atk": 18,
        "def": 32,
        "agi": 38,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 48024,
        "item5": 0,
        "item6": 47080
    },
    {
        "idnpc": 13111,
        "idmap": 11555,
        "thanh": "Thanh Châu",
        "ten_npc": "Đại Võ Quan",
        "level": 94,
        "hp": 780,
        "sp": 329,
        "hpx": 39,
        "spx": 13,
        "int": 62,
        "atk": 41,
        "def": 14,
        "agi": 48,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47281
    },
    {
        "idnpc": 14080,
        "idmap": 11582,
        "thanh": "Thanh Châu",
        "ten_npc": "Phan Thầu",
        "level": 94,
        "hp": 771,
        "sp": 360,
        "hpx": 38,
        "spx": 18,
        "int": 22,
        "atk": 79,
        "def": 37,
        "agi": 34,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 49001,
        "item6": 47249
    },
    {
        "idnpc": 14119,
        "idmap": 11584,
        "thanh": "Thanh Châu",
        "ten_npc": "Thuần.Nh.Quỳnh",
        "level": 94,
        "hp": 771,
        "sp": 390,
        "hpx": 38,
        "spx": 23,
        "int": 18,
        "atk": 76,
        "def": 37,
        "agi": 36,
        "item1": 26016,
        "item2": 27019,
        "item3": 26035,
        "item4": 0,
        "item5": 0,
        "item6": 47238
    },
    {
        "idnpc": 14258,
        "idmap": 12000,
        "ten_map": "U Chau",
        "thanh": "U Châu",
        "ten_npc": "Biên Chương",
        "level": 94,
        "hp": 818,
        "sp": 388,
        "hpx": 38,
        "spx": 22,
        "int": 11,
        "atk": 63,
        "def": 43,
        "agi": 37,
        "item1": 27045,
        "item2": 32025,
        "item3": 27045,
        "item4": 46036,
        "item5": 46035,
        "item6": 0
    },
    {
        "idnpc": 14367,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Nghiêm Dư",
        "level": 94,
        "hp": 977,
        "sp": 390,
        "hpx": 38,
        "spx": 23,
        "int": 16,
        "atk": 69,
        "def": 43,
        "agi": 30,
        "item1": 26051,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14435,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Đa Mô",
        "level": 94,
        "hp": 1006,
        "sp": 386,
        "hpx": 38,
        "spx": 22,
        "int": 6,
        "atk": 75,
        "def": 32,
        "agi": 41,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14436,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Di Di",
        "level": 94,
        "hp": 1006,
        "sp": 405,
        "hpx": 38,
        "spx": 25,
        "int": 67,
        "atk": 22,
        "def": 31,
        "agi": 34,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47005
    },
    {
        "idnpc": 14505,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Khứ Ti",
        "level": 94,
        "hp": 790,
        "sp": 373,
        "hpx": 33,
        "spx": 20,
        "int": 5,
        "atk": 67,
        "def": 27,
        "agi": 62,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14564,
        "idmap": 12031,
        "ten_map": "Thon Lau Song",
        "thanh": "U Châu",
        "ten_npc": "Sĩ Vũ",
        "level": 94,
        "hp": 802,
        "sp": 341,
        "hpx": 35,
        "spx": 15,
        "int": 30,
        "atk": 66,
        "def": 46,
        "agi": 24,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47279
    },
    {
        "idnpc": 14611,
        "idmap": 12061,
        "ten_map": "Diep Thanh Thanh Mon",
        "thanh": "U Châu",
        "ten_npc": "Nhĩ Gia",
        "level": 94,
        "hp": 833,
        "sp": 397,
        "hpx": 40,
        "spx": 24,
        "int": 16,
        "atk": 72,
        "def": 23,
        "agi": 40,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14626,
        "idmap": 12062,
        "ten_map": "Dai Do Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Tưởng Thạch",
        "level": 94,
        "hp": 827,
        "sp": 366,
        "hpx": 39,
        "spx": 19,
        "int": 11,
        "atk": 81,
        "def": 37,
        "agi": 31,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14670,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Hề Nê",
        "level": 94,
        "hp": 792,
        "sp": 366,
        "hpx": 41,
        "spx": 19,
        "int": 26,
        "atk": 66,
        "def": 16,
        "agi": 48,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15278,
        "idmap": 12241,
        "ten_map": "Nha Tro Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Dũng Sĩ  Châu",
        "level": 94,
        "hp": 708,
        "sp": 335,
        "hpx": 35,
        "spx": 14,
        "int": 8,
        "atk": 55,
        "def": 31,
        "agi": 41,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17157,
        "idmap": 12556,
        "thanh": "U Châu",
        "ten_npc": "Tu La Quân",
        "level": 94,
        "hp": 724,
        "sp": 320,
        "hpx": 38,
        "spx": 22,
        "int": 5,
        "atk": 47,
        "def": 32,
        "agi": 40,
        "item1": 26011,
        "item2": 26069,
        "item3": 32025,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17438,
        "idmap": 12808,
        "ten_map": "Map nguoi rom TQ",
        "thanh": "U Châu",
        "ten_npc": "Khương Quân Vệ",
        "level": 94,
        "hp": 776,
        "sp": 323,
        "hpx": 46,
        "spx": 12,
        "int": 6,
        "atk": 57,
        "def": 38,
        "agi": 25,
        "item1": 26070,
        "item2": 26011,
        "item3": 26070,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10141,
        "idmap": 10859,
        "ten_npc": "Dã Võ Quân",
        "level": 95,
        "hp": 732,
        "sp": 324,
        "hpx": 38,
        "spx": 22,
        "int": 6,
        "atk": 51,
        "def": 44,
        "agi": 27,
        "item1": 26011,
        "item2": 26013,
        "item3": 26070,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10255,
        "idmap": 10961,
        "ten_npc": "Câu Đao Tướng",
        "level": 95,
        "hp": 795,
        "sp": 294,
        "hpx": 48,
        "spx": 7,
        "int": 9,
        "atk": 52,
        "def": 38,
        "agi": 35,
        "item1": 27051,
        "item2": 27051,
        "item3": 32026,
        "item4": 0,
        "item5": 38008,
        "item6": 0
    },
    {
        "idnpc": 11035,
        "idmap": 10966,
        "ten_npc": "Trương Tú",
        "level": 95,
        "hp": 600,
        "sp": 400,
        "hpx": 32,
        "spx": 24,
        "int": 36,
        "atk": 68,
        "def": 36,
        "agi": 34,
        "item1": 27044,
        "item2": 27050,
        "item3": 26020,
        "item4": 0,
        "item5": 0,
        "item6": 47350
    },
    {
        "idnpc": 11089,
        "idmap": 10992,
        "ten_map": "Vung dat bang gia 1",
        "ten_npc": "Cư Vệ",
        "level": 95,
        "hp": 827,
        "sp": 362,
        "hpx": 38,
        "spx": 18,
        "int": 23,
        "atk": 64,
        "def": 38,
        "agi": 39,
        "item1": 26051,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47177
    },
    {
        "idnpc": 11173,
        "idmap": 11011,
        "ten_map": "Bac Hai Thanh Mon",
        "thanh": "Thanh Châu",
        "ten_npc": "Hạ Hầu Đức",
        "level": 95,
        "hp": 736,
        "sp": 381,
        "hpx": 31,
        "spx": 21,
        "int": 21,
        "atk": 70,
        "def": 31,
        "agi": 45,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13010,
        "idmap": 11502,
        "ten_map": "Son Dong Thai Son 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Lăng Thống",
        "level": 95,
        "hp": 827,
        "sp": 362,
        "hpx": 38,
        "spx": 18,
        "int": 21,
        "atk": 73,
        "def": 34,
        "agi": 36,
        "item1": 27008,
        "item2": 27043,
        "item3": 27039,
        "item4": 0,
        "item5": 0,
        "item6": 47074
    },
    {
        "idnpc": 13118,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Tôn Lững",
        "level": 95,
        "hp": 749,
        "sp": 290,
        "hpx": 33,
        "spx": 17,
        "int": 68,
        "atk": 52,
        "def": 14,
        "agi": 37,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47354
    },
    {
        "idnpc": 13122,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Từ Tường",
        "level": 95,
        "hp": 805,
        "sp": 462,
        "hpx": 42,
        "spx": 34,
        "int": 77,
        "atk": 21,
        "def": 14,
        "agi": 34,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14030,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Viên Thiệu",
        "level": 95,
        "hp": 780,
        "sp": 399,
        "hpx": 38,
        "spx": 24,
        "int": 35,
        "atk": 60,
        "def": 39,
        "agi": 34,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 47107
    },
    {
        "idnpc": 14051,
        "idmap": 11572,
        "ten_map": "Dong Hai Tac Phung Lai 22",
        "thanh": "Thanh Châu",
        "ten_npc": "Quách Kỷ",
        "level": 95,
        "hp": 780,
        "sp": 396,
        "hpx": 38,
        "spx": 23,
        "int": 23,
        "atk": 75,
        "def": 38,
        "agi": 34,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 47251
    },
    {
        "idnpc": 14063,
        "idmap": 11581,
        "thanh": "Thanh Châu",
        "ten_npc": "Dương Phụng",
        "level": 95,
        "hp": 780,
        "sp": 362,
        "hpx": 38,
        "spx": 18,
        "int": 29,
        "atk": 72,
        "def": 34,
        "agi": 39,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14439,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Di Chi",
        "level": 95,
        "hp": 1017,
        "sp": 391,
        "hpx": 38,
        "spx": 23,
        "int": 8,
        "atk": 80,
        "def": 35,
        "agi": 35,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14533,
        "idmap": 12022,
        "ten_map": "Dai Lo Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Trình Ngân",
        "level": 95,
        "hp": 765,
        "sp": 362,
        "hpx": 28,
        "spx": 18,
        "int": 11,
        "atk": 70,
        "def": 29,
        "agi": 63,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14660,
        "idmap": 12063,
        "ten_map": "Hoang Cung Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "ĐạiQuânMộcLộc",
        "level": 95,
        "hp": 869,
        "sp": 356,
        "hpx": 37,
        "spx": 17,
        "int": 25,
        "atk": 68,
        "def": 22,
        "agi": 50,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14707,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Cam Lễ",
        "level": 95,
        "hp": 3202,
        "sp": 326,
        "hpx": 38,
        "spx": 23,
        "int": 14,
        "atk": 154,
        "def": 64,
        "agi": 124,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15377,
        "idmap": 12310,
        "ten_map": "Lac Hai Quan Phu",
        "thanh": "U Châu",
        "ten_npc": "Kiển Tông",
        "level": 95,
        "hp": 752,
        "sp": 444,
        "hpx": 26,
        "spx": 31,
        "int": 74,
        "atk": 12,
        "def": 26,
        "agi": 55,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15446,
        "idmap": 12409,
        "thanh": "U Châu",
        "ten_npc": "A Chi",
        "level": 95,
        "hp": 3227,
        "sp": 324,
        "hpx": 42,
        "spx": 23,
        "int": 8,
        "atk": 130,
        "def": 22,
        "agi": 140,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17064,
        "idmap": 12521,
        "ten_map": "S.Dong KhVang 1",
        "thanh": "U Châu",
        "ten_npc": "Tinh Nhuệ Binh",
        "level": 95,
        "hp": 732,
        "sp": 390,
        "hpx": 38,
        "spx": 22,
        "int": 5,
        "atk": 45,
        "def": 39,
        "agi": 39,
        "item1": 27072,
        "item2": 27072,
        "item3": 27072,
        "item4": 40010,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17190,
        "idmap": 12569,
        "thanh": "U Châu",
        "ten_npc": "Cấm Vệ Binh",
        "level": 95,
        "hp": 922,
        "sp": 330,
        "hpx": 38,
        "spx": 24,
        "int": 27,
        "atk": 46,
        "def": 37,
        "agi": 40,
        "item1": 27012,
        "item2": 27011,
        "item3": 26070,
        "item4": 37986,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10135,
        "idmap": 10856,
        "ten_npc": "Binh Nộ Giáp",
        "level": 96,
        "hp": 740,
        "sp": 328,
        "hpx": 38,
        "spx": 23,
        "int": 8,
        "atk": 53,
        "def": 25,
        "agi": 42,
        "item1": 26070,
        "item2": 26071,
        "item3": 26011,
        "item4": 46016,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10258,
        "idmap": 10961,
        "ten_npc": "Sa Hải Tặc",
        "level": 96,
        "hp": 788,
        "sp": 321,
        "hpx": 38,
        "spx": 11,
        "int": 12,
        "atk": 54,
        "def": 49,
        "agi": 25,
        "item1": 26203,
        "item2": 26203,
        "item3": 26139,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11199,
        "idmap": 11021,
        "ten_map": "Thon Phung Lai",
        "thanh": "Thanh Châu",
        "ten_npc": "Vương Tất",
        "level": 96,
        "hp": 1122,
        "sp": 302,
        "hpx": 38,
        "spx": 8,
        "int": 16,
        "atk": 62,
        "def": 19,
        "agi": 50,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12066,
        "idmap": 11242,
        "ten_map": "Nha Tro Bac Hai",
        "thanh": "Thanh Châu",
        "ten_npc": "Quan Ngân Bình",
        "level": 96,
        "hp": 853,
        "sp": 383,
        "hpx": 41,
        "spx": 21,
        "int": 13,
        "atk": 47,
        "def": 41,
        "agi": 57,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 48060,
        "item5": 0,
        "item6": 47118
    },
    {
        "idnpc": 12073,
        "idmap": 11261,
        "ten_map": "Tien Trang Dong Quan",
        "thanh": "Thanh Châu",
        "ten_npc": "Thích Phổ",
        "level": 96,
        "hp": 709,
        "sp": 421,
        "hpx": 18,
        "spx": 27,
        "int": 20,
        "atk": 74,
        "def": 52,
        "agi": 28,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47223
    },
    {
        "idnpc": 12093,
        "idmap": 11305,
        "ten_map": "Trai quan Cong Thanh",
        "thanh": "Thanh Châu",
        "ten_npc": "Hùynh Quyền",
        "level": 96,
        "hp": 899,
        "sp": 371,
        "hpx": 33,
        "spx": 19,
        "int": 62,
        "atk": 52,
        "def": 23,
        "agi": 33,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47227
    },
    {
        "idnpc": 13057,
        "idmap": 11521,
        "ten_map": "Son Dong Thai Son 19",
        "thanh": "Thanh Châu",
        "ten_npc": "Lý Thuật",
        "level": 96,
        "hp": 686,
        "sp": 396,
        "hpx": 22,
        "spx": 23,
        "int": 75,
        "atk": 11,
        "def": 31,
        "agi": 60,
        "item1": 26064,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47198
    },
    {
        "idnpc": 13131,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Lữ Bá",
        "level": 96,
        "hp": 1036,
        "sp": 358,
        "hpx": 32,
        "spx": 17,
        "int": 32,
        "atk": 84,
        "def": 14,
        "agi": 40,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14015,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Lý Thôi",
        "level": 96,
        "hp": 761,
        "sp": 401,
        "hpx": 34,
        "spx": 24,
        "int": 27,
        "atk": 72,
        "def": 40,
        "agi": 36,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 49001,
        "item6": 47253
    },
    {
        "idnpc": 14040,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Hồ Xích Nhi",
        "level": 96,
        "hp": 788,
        "sp": 400,
        "hpx": 38,
        "spx": 24,
        "int": 24,
        "atk": 76,
        "def": 37,
        "agi": 43,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 14195,
        "idmap": 11811,
        "ten_map": "Rung Don Khuu",
        "thanh": "Thanh Châu",
        "ten_npc": "Vương Quốc",
        "level": 96,
        "hp": 836,
        "sp": 395,
        "hpx": 38,
        "spx": 23,
        "int": 8,
        "atk": 67,
        "def": 47,
        "agi": 36,
        "item1": 26063,
        "item2": 26070,
        "item3": 26063,
        "item4": 46036,
        "item5": 46035,
        "item6": 0
    },
    {
        "idnpc": 14334,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Dương Hòanh",
        "level": 96,
        "hp": 927,
        "sp": 477,
        "hpx": 28,
        "spx": 36,
        "int": 80,
        "atk": 14,
        "def": 30,
        "agi": 34,
        "item1": 26002,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47195
    },
    {
        "idnpc": 14532,
        "idmap": 12022,
        "ten_map": "Dai Lo Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Dương Thu",
        "level": 96,
        "hp": 803,
        "sp": 308,
        "hpx": 33,
        "spx": 9,
        "int": 32,
        "atk": 67,
        "def": 58,
        "agi": 21,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14593,
        "idmap": 12051,
        "ten_map": "Tan Tich Lac Hai",
        "thanh": "U Châu",
        "ten_npc": "Dục Diên",
        "level": 96,
        "hp": 803,
        "sp": 390,
        "hpx": 33,
        "spx": 22,
        "int": 23,
        "atk": 53,
        "def": 34,
        "agi": 55,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14608,
        "idmap": 12051,
        "ten_map": "Tan Tich Lac Hai",
        "thanh": "U Châu",
        "ten_npc": "A Luân",
        "level": 96,
        "hp": 865,
        "sp": 340,
        "hpx": 43,
        "spx": 14,
        "int": 15,
        "atk": 68,
        "def": 52,
        "agi": 28,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14617,
        "idmap": 12061,
        "ten_map": "Diep Thanh Thanh Mon",
        "thanh": "U Châu",
        "ten_npc": "Sử Huỳnh",
        "level": 96,
        "hp": 915,
        "sp": 383,
        "hpx": 51,
        "spx": 21,
        "int": 12,
        "atk": 63,
        "def": 29,
        "agi": 44,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17208,
        "idmap": 12581,
        "ten_map": "Mo Nguyet Trieu Ca 1",
        "thanh": "U Châu",
        "ten_npc": "Đội Áo Đen",
        "level": 96,
        "hp": 932,
        "sp": 333,
        "hpx": 38,
        "spx": 24,
        "int": 27,
        "atk": 46,
        "def": 38,
        "agi": 41,
        "item1": 26071,
        "item2": 26070,
        "item3": 27011,
        "item4": 40009,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17212,
        "idmap": 12582,
        "thanh": "U Châu",
        "ten_npc": "Đại Thổ Phỉ",
        "level": 96,
        "hp": 740,
        "sp": 393,
        "hpx": 38,
        "spx": 23,
        "int": 3,
        "atk": 42,
        "def": 43,
        "agi": 40,
        "item1": 27072,
        "item2": 27072,
        "item3": 27079,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10139,
        "idmap": 10858,
        "ten_npc": "Quân Mãnh Hổ",
        "level": 97,
        "hp": 749,
        "sp": 330,
        "hpx": 39,
        "spx": 23,
        "int": 7,
        "atk": 48,
        "def": 42,
        "agi": 31,
        "item1": 26105,
        "item2": 26106,
        "item3": 27071,
        "item4": 42024,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11028,
        "idmap": 10965,
        "ten_npc": "Hạ Hầu Thượng",
        "level": 97,
        "hp": 779,
        "sp": 348,
        "hpx": 36,
        "spx": 15,
        "int": 12,
        "atk": 84,
        "def": 26,
        "agi": 53,
        "item1": 26157,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47221
    },
    {
        "idnpc": 14004,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Phùng Phương",
        "level": 97,
        "hp": 811,
        "sp": 380,
        "hpx": 41,
        "spx": 20,
        "int": 28,
        "atk": 68,
        "def": 36,
        "agi": 40,
        "item1": 26051,
        "item2": 26082,
        "item3": 26003,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14257,
        "idmap": 11841,
        "ten_map": "Rung Vi Son 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Hàn Tục",
        "level": 97,
        "hp": 836,
        "sp": 367,
        "hpx": 45,
        "spx": 18,
        "int": 24,
        "atk": 68,
        "def": 36,
        "agi": 42,
        "item1": 27019,
        "item2": 26035,
        "item3": 26086,
        "item4": 0,
        "item5": 0,
        "item6": 47241
    },
    {
        "idnpc": 17225,
        "idmap": 12585,
        "thanh": "U Châu",
        "ten_npc": "Nha Môn Tướng",
        "level": 97,
        "hp": 749,
        "sp": 398,
        "hpx": 39,
        "spx": 23,
        "int": 5,
        "atk": 45,
        "def": 40,
        "agi": 38,
        "item1": 27079,
        "item2": 27079,
        "item3": 27072,
        "item4": 37969,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17338,
        "idmap": 12711,
        "ten_map": "Blinh Chien Truong Dung Cam",
        "thanh": "U Châu",
        "ten_npc": "Dung Binh",
        "level": 97,
        "hp": 749,
        "sp": 398,
        "hpx": 39,
        "spx": 23,
        "int": 5,
        "atk": 45,
        "def": 42,
        "agi": 36,
        "item1": 26251,
        "item2": 26251,
        "item3": 26238,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10120,
        "idmap": 10820,
        "ten_npc": "Thiên Thánh Sĩ",
        "level": 98,
        "hp": 757,
        "sp": 414,
        "hpx": 39,
        "spx": 25,
        "int": 46,
        "atk": 36,
        "def": 35,
        "agi": 15,
        "item1": 32026,
        "item2": 32026,
        "item3": 26252,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10140,
        "idmap": 10858,
        "ten_npc": "Ma Võ Sĩ",
        "level": 98,
        "hp": 757,
        "sp": 333,
        "hpx": 39,
        "spx": 23,
        "int": 5,
        "atk": 50,
        "def": 25,
        "agi": 52,
        "item1": 27071,
        "item2": 26071,
        "item3": 26105,
        "item4": 46018,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11178,
        "idmap": 11012,
        "ten_map": "Dai Do Bac Hai",
        "thanh": "Thanh Châu",
        "ten_npc": "Lữ Thường",
        "level": 98,
        "hp": 741,
        "sp": 382,
        "hpx": 29,
        "spx": 20,
        "int": 12,
        "atk": 64,
        "def": 35,
        "agi": 67,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11200,
        "idmap": 11021,
        "ten_map": "Thon Phung Lai",
        "thanh": "Thanh Châu",
        "ten_npc": "Mộ dung liệt",
        "level": 98,
        "hp": 1022,
        "sp": 539,
        "hpx": 19,
        "spx": 45,
        "int": 13,
        "atk": 83,
        "def": 13,
        "agi": 43,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11201,
        "idmap": 11021,
        "ten_map": "Thon Phung Lai",
        "thanh": "Thanh Châu",
        "ten_npc": "Tiêu Bính",
        "level": 98,
        "hp": 1210,
        "sp": 350,
        "hpx": 49,
        "spx": 15,
        "int": 13,
        "atk": 75,
        "def": 11,
        "agi": 53,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12008,
        "idmap": 11032,
        "thanh": "Thanh Châu",
        "ten_npc": "Ngô Ý",
        "level": 98,
        "hp": 874,
        "sp": 338,
        "hpx": 50,
        "spx": 13,
        "int": 27,
        "atk": 66,
        "def": 18,
        "agi": 51,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47170
    },
    {
        "idnpc": 12082,
        "idmap": 11301,
        "ten_map": "Dong Quan Quan Phu",
        "thanh": "Thanh Châu",
        "ten_npc": "Lôi Trục",
        "level": 98,
        "hp": 759,
        "sp": 410,
        "hpx": 24,
        "spx": 25,
        "int": 33,
        "atk": 79,
        "def": 50,
        "agi": 15,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12116,
        "idmap": 11411,
        "ten_map": "Khuc Trac Khong Mieu",
        "thanh": "Thanh Châu",
        "ten_npc": "A Quý",
        "level": 98,
        "hp": 888,
        "sp": 369,
        "hpx": 29,
        "spx": 18,
        "int": 26,
        "atk": 75,
        "def": 54,
        "agi": 33,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13020,
        "idmap": 11503,
        "ten_map": "Son Dong Thai Son 3",
        "thanh": "Thanh Châu",
        "ten_npc": "Trần Võ",
        "level": 98,
        "hp": 786,
        "sp": 407,
        "hpx": 36,
        "spx": 24,
        "int": 24,
        "atk": 74,
        "def": 35,
        "agi": 41,
        "item1": 26029,
        "item2": 27007,
        "item3": 27009,
        "item4": 0,
        "item5": 0,
        "item6": 47146
    },
    {
        "idnpc": 13029,
        "idmap": 11505,
        "ten_map": "Son Dong Thai Son 5",
        "thanh": "Thanh Châu",
        "ten_npc": "Gia Cát Cẩn",
        "level": 98,
        "hp": 897,
        "sp": 426,
        "hpx": 46,
        "spx": 27,
        "int": 84,
        "atk": 28,
        "def": 19,
        "agi": 22,
        "item1": 27036,
        "item2": 27037,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 47142
    },
    {
        "idnpc": 13034,
        "idmap": 11509,
        "ten_map": "Son Dong Thai Son 9",
        "thanh": "Thanh Châu",
        "ten_npc": "Quan Trạch",
        "level": 98,
        "hp": 754,
        "sp": 423,
        "hpx": 31,
        "spx": 27,
        "int": 73,
        "atk": 25,
        "def": 39,
        "agi": 32,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47078
    },
    {
        "idnpc": 14005,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Hạ Mậu",
        "level": 98,
        "hp": 786,
        "sp": 409,
        "hpx": 36,
        "spx": 24,
        "int": 28,
        "atk": 74,
        "def": 34,
        "agi": 38,
        "item1": 26051,
        "item2": 26082,
        "item3": 26003,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14480,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Trần Bộc",
        "level": 98,
        "hp": 879,
        "sp": 388,
        "hpx": 43,
        "spx": 21,
        "int": 8,
        "atk": 65,
        "def": 58,
        "agi": 32,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14529,
        "idmap": 12021,
        "ten_map": "Cua Thanh Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Lương Hưng",
        "level": 98,
        "hp": 904,
        "sp": 350,
        "hpx": 47,
        "spx": 15,
        "int": 3,
        "atk": 78,
        "def": 72,
        "agi": 9,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14607,
        "idmap": 12051,
        "ten_map": "Tan Tich Lac Hai",
        "thanh": "U Châu",
        "ten_npc": "Phất La",
        "level": 98,
        "hp": 790,
        "sp": 438,
        "hpx": 29,
        "spx": 29,
        "int": 70,
        "atk": 27,
        "def": 22,
        "agi": 50,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47341
    },
    {
        "idnpc": 14625,
        "idmap": 12062,
        "ten_map": "Dai Do Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Khúc Diễn",
        "level": 98,
        "hp": 822,
        "sp": 388,
        "hpx": 34,
        "spx": 21,
        "int": 11,
        "atk": 86,
        "def": 42,
        "agi": 34,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14638,
        "idmap": 12062,
        "ten_map": "Dai Do Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "An Quốc",
        "level": 98,
        "hp": 879,
        "sp": 388,
        "hpx": 43,
        "spx": 21,
        "int": 21,
        "atk": 73,
        "def": 48,
        "agi": 19,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17072,
        "idmap": 12523,
        "thanh": "U Châu",
        "ten_npc": "Qu.Thanh Dịch",
        "level": 98,
        "hp": 757,
        "sp": 401,
        "hpx": 39,
        "spx": 23,
        "int": 3,
        "atk": 46,
        "def": 36,
        "agi": 47,
        "item1": 27079,
        "item2": 27079,
        "item3": 27079,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17339,
        "idmap": 12721,
        "ten_map": "An The Thon",
        "thanh": "U Châu",
        "ten_npc": "Phi Nữ Tôn Tộc",
        "level": 98,
        "hp": 757,
        "sp": 412,
        "hpx": 39,
        "spx": 25,
        "int": 38,
        "atk": 31,
        "def": 36,
        "agi": 27,
        "item1": 26238,
        "item2": 26238,
        "item3": 26251,
        "item4": 34010,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10048,
        "idmap": 10808,
        "ten_npc": "Đội Chiến Rìu",
        "level": 99,
        "hp": 765,
        "sp": 405,
        "hpx": 40,
        "spx": 23,
        "int": 4,
        "atk": 46,
        "def": 38,
        "agi": 44,
        "item1": 26252,
        "item2": 26252,
        "item3": 32026,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10058,
        "idmap": 10809,
        "ten_npc": "Trảm.Ph.Quang",
        "level": 99,
        "hp": 765,
        "sp": 405,
        "hpx": 40,
        "spx": 23,
        "int": 4,
        "atk": 43,
        "def": 38,
        "agi": 47,
        "item1": 32026,
        "item2": 32026,
        "item3": 26252,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10131,
        "idmap": 10854,
        "ten_npc": "Sĩ Cẩm Tử",
        "level": 99,
        "hp": 963,
        "sp": 335,
        "hpx": 40,
        "spx": 23,
        "int": 3,
        "atk": 59,
        "def": 57,
        "agi": 13,
        "item1": 26095,
        "item2": 26096,
        "item3": 27072,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10259,
        "idmap": 10961,
        "ten_npc": "Ngạc Hải Tặc",
        "level": 99,
        "hp": 817,
        "sp": 283,
        "hpx": 40,
        "spx": 4,
        "int": 6,
        "atk": 70,
        "def": 58,
        "agi": 18,
        "item1": 26138,
        "item2": 26138,
        "item3": 26137,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11022,
        "idmap": 10965,
        "ten_npc": "Hạ Hầu Lâm",
        "level": 99,
        "hp": 930,
        "sp": 353,
        "hpx": 58,
        "spx": 15,
        "int": 47,
        "atk": 6,
        "def": 67,
        "agi": 35,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47292
    },
    {
        "idnpc": 14010,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Ngưu Phụ",
        "level": 99,
        "hp": 814,
        "sp": 413,
        "hpx": 40,
        "spx": 25,
        "int": 30,
        "atk": 64,
        "def": 41,
        "agi": 36,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 47257
    },
    {
        "idnpc": 14485,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Tô Bộc Diên",
        "level": 99,
        "hp": 828,
        "sp": 454,
        "hpx": 34,
        "spx": 31,
        "int": 8,
        "atk": 77,
        "def": 31,
        "agi": 46,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14657,
        "idmap": 12063,
        "ten_map": "Hoang Cung Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "ĐoáTưĐạiVương",
        "level": 99,
        "hp": 1004,
        "sp": 403,
        "hpx": 54,
        "spx": 23,
        "int": 15,
        "atk": 68,
        "def": 26,
        "agi": 41,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15172,
        "idmap": 12101,
        "ten_map": "N.Ly Lang Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Ti Di Hô",
        "level": 99,
        "hp": 992,
        "sp": 429,
        "hpx": 36,
        "spx": 27,
        "int": 81,
        "atk": 28,
        "def": 24,
        "agi": 32,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 48027,
        "item5": 0,
        "item6": 47002
    },
    {
        "idnpc": 10061,
        "idmap": 10810,
        "ten_npc": "Tướng Xoa Kích",
        "level": 100,
        "hp": 773,
        "sp": 409,
        "hpx": 40,
        "spx": 24,
        "int": 4,
        "atk": 48,
        "def": 45,
        "agi": 35,
        "item1": 26252,
        "item2": 26252,
        "item3": 32026,
        "item4": 37988,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10136,
        "idmap": 10856,
        "ten_npc": "Binh Bạo Giáp",
        "level": 100,
        "hp": 773,
        "sp": 339,
        "hpx": 40,
        "spx": 24,
        "int": 6,
        "atk": 57,
        "def": 38,
        "agi": 31,
        "item1": 27072,
        "item2": 32027,
        "item3": 26095,
        "item4": 34026,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10165,
        "idmap": 10901,
        "ten_npc": "Quân Thái Bình",
        "level": 100,
        "hp": 917,
        "sp": 368,
        "hpx": 47,
        "spx": 17,
        "int": 11,
        "atk": 46,
        "def": 55,
        "agi": 20,
        "item1": 26013,
        "item2": 26013,
        "item3": 26106,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10260,
        "idmap": 10961,
        "ten_npc": "Giao Hải Tặc",
        "level": 100,
        "hp": 703,
        "sp": 317,
        "hpx": 21,
        "spx": 9,
        "int": 16,
        "atk": 68,
        "def": 32,
        "agi": 50,
        "item1": 26137,
        "item2": 26137,
        "item3": 26138,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11016,
        "idmap": 10965,
        "ten_npc": "Lý Điển",
        "level": 100,
        "hp": 973,
        "sp": 342,
        "hpx": 40,
        "spx": 13,
        "int": 17,
        "atk": 88,
        "def": 41,
        "agi": 37,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47105
    },
    {
        "idnpc": 11163,
        "idmap": 11011,
        "ten_map": "Bac Hai Thanh Mon",
        "thanh": "Thanh Châu",
        "ten_npc": "Võ Châu",
        "level": 100,
        "hp": 1149,
        "sp": 399,
        "hpx": 52,
        "spx": 22,
        "int": 79,
        "atk": 17,
        "def": 33,
        "agi": 36,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47284
    },
    {
        "idnpc": 13011,
        "idmap": 11502,
        "ten_map": "Son Dong Thai Son 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Tôn Hằng",
        "level": 100,
        "hp": 986,
        "sp": 349,
        "hpx": 42,
        "spx": 14,
        "int": 18,
        "atk": 74,
        "def": 26,
        "agi": 62,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47290
    },
    {
        "idnpc": 13038,
        "idmap": 11509,
        "ten_map": "Son Dong Thai Son 9",
        "thanh": "Thanh Châu",
        "ten_npc": "Tôn.Th.Hương",
        "level": 100,
        "hp": 973,
        "sp": 342,
        "hpx": 40,
        "spx": 13,
        "int": 20,
        "atk": 82,
        "def": 34,
        "agi": 47,
        "item1": 27022,
        "item2": 0,
        "item3": 0,
        "item4": 48028,
        "item5": 0,
        "item6": 47081
    },
    {
        "idnpc": 14528,
        "idmap": 12021,
        "ten_map": "Cua Thanh Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Mã Thiết",
        "level": 100,
        "hp": 935,
        "sp": 355,
        "hpx": 34,
        "spx": 15,
        "int": 31,
        "atk": 83,
        "def": 27,
        "agi": 47,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47222
    },
    {
        "idnpc": 14686,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "ThácNguyênHán",
        "level": 100,
        "hp": 849,
        "sp": 412,
        "hpx": 60,
        "spx": 24,
        "int": 13,
        "atk": 83,
        "def": 23,
        "agi": 33,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14706,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Bách Trị",
        "level": 100,
        "hp": 3373,
        "sp": 342,
        "hpx": 40,
        "spx": 24,
        "int": 14,
        "atk": 154,
        "def": 64,
        "agi": 124,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14709,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Sĩ Phụ",
        "level": 100,
        "hp": 3873,
        "sp": 342,
        "hpx": 40,
        "spx": 24,
        "int": 14,
        "atk": 154,
        "def": 64,
        "agi": 124,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15445,
        "idmap": 12409,
        "thanh": "U Châu",
        "ten_npc": "Nhiệt bố ba la",
        "level": 100,
        "hp": 3936,
        "sp": 279,
        "hpx": 50,
        "spx": 14,
        "int": 13,
        "atk": 150,
        "def": 23,
        "agi": 150,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17332,
        "idmap": 12705,
        "ten_map": "Binh Truong Huong Dung",
        "thanh": "U Châu",
        "ten_npc": "Tẩu Hà Thủ",
        "level": 100,
        "hp": 873,
        "sp": 409,
        "hpx": 40,
        "spx": 24,
        "int": 5,
        "atk": 43,
        "def": 36,
        "agi": 48,
        "item1": 26138,
        "item2": 26203,
        "item3": 26203,
        "item4": 40030,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17487,
        "idmap": 12816,
        "thanh": "U Châu",
        "ten_npc": "Binh Ích Châu",
        "level": 100,
        "hp": 898,
        "sp": 386,
        "hpx": 44,
        "spx": 20,
        "int": 18,
        "atk": 48,
        "def": 43,
        "agi": 23,
        "item1": 26303,
        "item2": 26303,
        "item3": 26238,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17491,
        "idmap": 12817,
        "thanh": "U Châu",
        "ten_npc": "Thục Binh",
        "level": 100,
        "hp": 886,
        "sp": 386,
        "hpx": 42,
        "spx": 20,
        "int": 22,
        "atk": 46,
        "def": 44,
        "agi": 23,
        "item1": 26303,
        "item2": 26303,
        "item3": 27072,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10167,
        "idmap": 10901,
        "ten_npc": "Đ.Cương Tướng",
        "level": 101,
        "hp": 930,
        "sp": 363,
        "hpx": 48,
        "spx": 16,
        "int": 12,
        "atk": 46,
        "def": 56,
        "agi": 22,
        "item1": 26071,
        "item2": 26071,
        "item3": 26238,
        "item4": 35010,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11001,
        "idmap": 10963,
        "ten_npc": "Dư Cấm",
        "level": 101,
        "hp": 983,
        "sp": 357,
        "hpx": 40,
        "spx": 15,
        "int": 19,
        "atk": 85,
        "def": 40,
        "agi": 38,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47042
    },
    {
        "idnpc": 13039,
        "idmap": 11509,
        "ten_map": "Son Dong Thai Son 9",
        "thanh": "Thanh Châu",
        "ten_npc": "Tổ Mậu",
        "level": 101,
        "hp": 955,
        "sp": 408,
        "hpx": 36,
        "spx": 23,
        "int": 22,
        "atk": 77,
        "def": 34,
        "agi": 40,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47136
    },
    {
        "idnpc": 13124,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Thị Nghi",
        "level": 101,
        "hp": 829,
        "sp": 421,
        "hpx": 40,
        "spx": 25,
        "int": 80,
        "atk": 11,
        "def": 33,
        "agi": 54,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47305
    },
    {
        "idnpc": 14366,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Nghiêm.B.Hổ",
        "level": 101,
        "hp": 983,
        "sp": 325,
        "hpx": 40,
        "spx": 10,
        "int": 9,
        "atk": 81,
        "def": 48,
        "agi": 44,
        "item1": 26040,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47108
    },
    {
        "idnpc": 14526,
        "idmap": 12021,
        "ten_map": "Cua Thanh Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Thiện Vạn",
        "level": 101,
        "hp": 1019,
        "sp": 376,
        "hpx": 46,
        "spx": 18,
        "int": 15,
        "atk": 75,
        "def": 53,
        "agi": 23,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14616,
        "idmap": 12061,
        "ten_map": "Diep Thanh Thanh Mon",
        "thanh": "U Châu",
        "ten_npc": "Chu Phù",
        "level": 101,
        "hp": 936,
        "sp": 427,
        "hpx": 33,
        "spx": 26,
        "int": 75,
        "atk": 12,
        "def": 43,
        "agi": 54,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47261
    },
    {
        "idnpc": 15282,
        "idmap": 12242,
        "ten_map": "Nha Tro Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Bắc Hà",
        "level": 101,
        "hp": 886,
        "sp": 452,
        "hpx": 25,
        "spx": 30,
        "int": 33,
        "atk": 77,
        "def": 25,
        "agi": 51,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17333,
        "idmap": 12706,
        "ten_map": "Binh Truong Huong Dung",
        "thanh": "U Châu",
        "ten_npc": "Du Đình thủ",
        "level": 101,
        "hp": 882,
        "sp": 413,
        "hpx": 40,
        "spx": 24,
        "int": 4,
        "atk": 48,
        "def": 37,
        "agi": 47,
        "item1": 26203,
        "item2": 26138,
        "item3": 26138,
        "item4": 33010,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17489,
        "idmap": 12817,
        "thanh": "U Châu",
        "ten_npc": "Hán Trung Binh",
        "level": 101,
        "hp": 886,
        "sp": 395,
        "hpx": 41,
        "spx": 21,
        "int": 17,
        "atk": 48,
        "def": 53,
        "agi": 20,
        "item1": 26251,
        "item2": 26251,
        "item3": 26298,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10003,
        "idmap": 10701,
        "ten_map": "Nha Lao",
        "ten_npc": "Trương Lương",
        "level": 102,
        "hp": 931,
        "sp": 474,
        "hpx": 31,
        "spx": 33,
        "int": 60,
        "atk": 33,
        "def": 39,
        "agi": 42,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47097
    },
    {
        "idnpc": 10239,
        "idmap": 10951,
        "ten_npc": "T.ThủyTặc",
        "level": 102,
        "hp": 937,
        "sp": 308,
        "hpx": 48,
        "spx": 7,
        "int": 8,
        "atk": 69,
        "def": 58,
        "agi": 11,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11038,
        "idmap": 10966,
        "ten_npc": "Tào Nhu",
        "level": 102,
        "hp": 823,
        "sp": 404,
        "hpx": 14,
        "spx": 22,
        "int": 60,
        "atk": 72,
        "def": 38,
        "agi": 32,
        "item1": 27007,
        "item2": 26029,
        "item3": 27050,
        "item4": 0,
        "item5": 0,
        "item6": 47133
    },
    {
        "idnpc": 11191,
        "idmap": 11013,
        "ten_map": "Rung Bac Hai",
        "thanh": "Thanh Châu",
        "ten_npc": "Thành Công Anh",
        "level": 102,
        "hp": 1109,
        "sp": 486,
        "hpx": 43,
        "spx": 35,
        "int": 85,
        "atk": 18,
        "def": 29,
        "agi": 35,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47309
    },
    {
        "idnpc": 12018,
        "idmap": 11041,
        "thanh": "Thanh Châu",
        "ten_npc": "Mã Lụy",
        "level": 102,
        "hp": 957,
        "sp": 423,
        "hpx": 19,
        "spx": 25,
        "int": 77,
        "atk": 63,
        "def": 27,
        "agi": 33,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47153
    },
    {
        "idnpc": 13017,
        "idmap": 11503,
        "ten_map": "Son Dong Thai Son 3",
        "thanh": "Thanh Châu",
        "ten_npc": "Từ Thịnh",
        "level": 102,
        "hp": 931,
        "sp": 397,
        "hpx": 31,
        "spx": 21,
        "int": 26,
        "atk": 79,
        "def": 41,
        "agi": 40,
        "item1": 27068,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47071
    },
    {
        "idnpc": 13116,
        "idmap": 11560,
        "thanh": "Thanh Châu",
        "ten_npc": "Toàn Tông",
        "level": 102,
        "hp": 1020,
        "sp": 404,
        "hpx": 45,
        "spx": 22,
        "int": 17,
        "atk": 74,
        "def": 32,
        "agi": 52,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47307
    },
    {
        "idnpc": 13123,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Hồ Tống",
        "level": 102,
        "hp": 861,
        "sp": 404,
        "hpx": 44,
        "spx": 22,
        "int": 82,
        "atk": 31,
        "def": 16,
        "agi": 50,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47325
    },
    {
        "idnpc": 14064,
        "idmap": 11581,
        "thanh": "Thanh Châu",
        "ten_npc": "Đổng Hoàng",
        "level": 102,
        "hp": 993,
        "sp": 391,
        "hpx": 41,
        "spx": 20,
        "int": 25,
        "atk": 68,
        "def": 42,
        "agi": 42,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 47256
    },
    {
        "idnpc": 14527,
        "idmap": 12021,
        "ten_map": "Cua Thanh Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Mã Hưu",
        "level": 102,
        "hp": 976,
        "sp": 378,
        "hpx": 38,
        "spx": 18,
        "int": 28,
        "atk": 85,
        "def": 49,
        "agi": 24,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47262
    },
    {
        "idnpc": 14581,
        "idmap": 12041,
        "ten_map": "Thon Thuong Son",
        "thanh": "U Châu",
        "ten_npc": "Thương Diệu",
        "level": 102,
        "hp": 1090,
        "sp": 385,
        "hpx": 56,
        "spx": 19,
        "int": 14,
        "atk": 87,
        "def": 38,
        "agi": 28,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14634,
        "idmap": 12062,
        "ten_map": "Dai Do Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Long Dã",
        "level": 102,
        "hp": 1033,
        "sp": 563,
        "hpx": 47,
        "spx": 47,
        "int": 9,
        "atk": 88,
        "def": 18,
        "agi": 36,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17334,
        "idmap": 12711,
        "ten_map": "Blinh Chien Truong Dung Cam",
        "thanh": "U Châu",
        "ten_npc": "Lính Kình Châu",
        "level": 102,
        "hp": 891,
        "sp": 417,
        "hpx": 41,
        "spx": 24,
        "int": 4,
        "atk": 45,
        "def": 42,
        "agi": 45,
        "item1": 26137,
        "item2": 26137,
        "item3": 26139,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17341,
        "idmap": 12721,
        "ten_map": "An The Thon",
        "thanh": "U Châu",
        "ten_npc": "Tôn Biêu Hán",
        "level": 102,
        "hp": 891,
        "sp": 417,
        "hpx": 41,
        "spx": 24,
        "int": 4,
        "atk": 52,
        "def": 51,
        "agi": 29,
        "item1": 26238,
        "item2": 26238,
        "item3": 26251,
        "item4": 37989,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17490,
        "idmap": 12817,
        "thanh": "U Châu",
        "ten_npc": "Nam Trịnh Binh",
        "level": 102,
        "hp": 912,
        "sp": 404,
        "hpx": 44,
        "spx": 22,
        "int": 18,
        "atk": 50,
        "def": 47,
        "agi": 21,
        "item1": 26298,
        "item2": 26298,
        "item3": 26251,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17492,
        "idmap": 12817,
        "thanh": "U Châu",
        "ten_npc": "ThKhương Binh",
        "level": 102,
        "hp": 956,
        "sp": 410,
        "hpx": 51,
        "spx": 23,
        "int": 12,
        "atk": 51,
        "def": 43,
        "agi": 22,
        "item1": 27072,
        "item2": 27072,
        "item3": 26303,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10224,
        "idmap": 10920,
        "ten_npc": "Binh Khăn Vàng",
        "level": 103,
        "hp": 1021,
        "sp": 355,
        "hpx": 44,
        "spx": 14,
        "int": 14,
        "atk": 76,
        "def": 62,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11041,
        "idmap": 10966,
        "ten_npc": "Tào Chân",
        "level": 103,
        "hp": 868,
        "sp": 419,
        "hpx": 20,
        "spx": 24,
        "int": 51,
        "atk": 77,
        "def": 31,
        "agi": 36,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47165
    },
    {
        "idnpc": 11091,
        "idmap": 10992,
        "ten_map": "Vung dat bang gia 1",
        "ten_npc": "Đổng Chiêu",
        "level": 103,
        "hp": 1004,
        "sp": 402,
        "hpx": 41,
        "spx": 28,
        "int": 82,
        "atk": 18,
        "def": 34,
        "agi": 33,
        "item1": 26045,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47175
    },
    {
        "idnpc": 11166,
        "idmap": 11011,
        "ten_map": "Bac Hai Thanh Mon",
        "thanh": "Thanh Châu",
        "ten_npc": "Triệu Ngang",
        "level": 103,
        "hp": 1131,
        "sp": 444,
        "hpx": 45,
        "spx": 28,
        "int": 24,
        "atk": 76,
        "def": 11,
        "agi": 58,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47337
    },
    {
        "idnpc": 12087,
        "idmap": 11301,
        "ten_map": "Dong Quan Quan Phu",
        "thanh": "Thanh Châu",
        "ten_npc": "Bàng Đức Công",
        "level": 103,
        "hp": 1067,
        "sp": 400,
        "hpx": 35,
        "spx": 21,
        "int": 81,
        "atk": 23,
        "def": 51,
        "agi": 34,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47254
    },
    {
        "idnpc": 12135,
        "idmap": 11431,
        "ten_map": "Rung Thai Son",
        "thanh": "Thanh Châu",
        "ten_npc": "Mã Trung",
        "level": 103,
        "hp": 1066,
        "sp": 425,
        "hpx": 51,
        "spx": 25,
        "int": 28,
        "atk": 79,
        "def": 31,
        "agi": 31,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12158,
        "idmap": 11501,
        "ten_map": "Son Dong Thai Son 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Doãng Mặc",
        "level": 103,
        "hp": 1066,
        "sp": 448,
        "hpx": 51,
        "spx": 29,
        "int": 92,
        "atk": 14,
        "def": 40,
        "agi": 26,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13096,
        "idmap": 11541,
        "ten_map": "Dong Quy Than 11",
        "thanh": "Thanh Châu",
        "ten_npc": "Tôn Kiểu",
        "level": 103,
        "hp": 977,
        "sp": 362,
        "hpx": 37,
        "spx": 15,
        "int": 22,
        "atk": 78,
        "def": 48,
        "agi": 33,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47218
    },
    {
        "idnpc": 14494,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Phí Sạn",
        "level": 103,
        "hp": 1066,
        "sp": 368,
        "hpx": 51,
        "spx": 16,
        "int": 8,
        "atk": 83,
        "def": 60,
        "agi": 25,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14504,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Hô Trụ Tuyền",
        "level": 103,
        "hp": 1066,
        "sp": 368,
        "hpx": 51,
        "spx": 16,
        "int": 5,
        "atk": 76,
        "def": 66,
        "agi": 19,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14525,
        "idmap": 12021,
        "ten_map": "Cua Thanh Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Đậu Mậu",
        "level": 103,
        "hp": 1015,
        "sp": 387,
        "hpx": 43,
        "spx": 19,
        "int": 15,
        "atk": 74,
        "def": 59,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14546,
        "idmap": 12023,
        "ten_map": "Diem Binh Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Bàng Nhi",
        "level": 103,
        "hp": 1028,
        "sp": 400,
        "hpx": 45,
        "spx": 21,
        "int": 22,
        "atk": 71,
        "def": 52,
        "agi": 32,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47336
    },
    {
        "idnpc": 14637,
        "idmap": 12062,
        "ten_map": "Dai Do Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Y Lị",
        "level": 103,
        "hp": 939,
        "sp": 419,
        "hpx": 31,
        "spx": 24,
        "int": 74,
        "atk": 70,
        "def": 15,
        "agi": 31,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14652,
        "idmap": 12063,
        "ten_map": "Hoang Cung Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Lữ Khải",
        "level": 103,
        "hp": 829,
        "sp": 413,
        "hpx": 38,
        "spx": 23,
        "int": 85,
        "atk": 18,
        "def": 31,
        "agi": 50,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14679,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Thiết Mộc Lăng",
        "level": 103,
        "hp": 798,
        "sp": 374,
        "hpx": 41,
        "spx": 28,
        "int": 84,
        "atk": 14,
        "def": 34,
        "agi": 44,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14691,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Thiết Mộc Lăng",
        "level": 103,
        "hp": 1416,
        "sp": 351,
        "hpx": 41,
        "spx": 25,
        "int": 14,
        "atk": 84,
        "def": 34,
        "agi": 44,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14692,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Thiết Mộc Lăng",
        "level": 103,
        "hp": 1931,
        "sp": 351,
        "hpx": 41,
        "spx": 25,
        "int": 14,
        "atk": 94,
        "def": 44,
        "agi": 54,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17335,
        "idmap": 12711,
        "ten_map": "Blinh Chien Truong Dung Cam",
        "thanh": "U Châu",
        "ten_npc": "Giảng võ quân",
        "level": 103,
        "hp": 901,
        "sp": 421,
        "hpx": 41,
        "spx": 24,
        "int": 5,
        "atk": 42,
        "def": 46,
        "agi": 43,
        "item1": 26139,
        "item2": 26139,
        "item3": 26137,
        "item4": 50003,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17439,
        "idmap": 12808,
        "ten_map": "Map nguoi rom TQ",
        "thanh": "U Châu",
        "ten_npc": "Thương Binh TN",
        "level": 103,
        "hp": 899,
        "sp": 349,
        "hpx": 41,
        "spx": 13,
        "int": 17,
        "atk": 58,
        "def": 48,
        "agi": 24,
        "item1": 26013,
        "item2": 26095,
        "item3": 26013,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17488,
        "idmap": 12817,
        "thanh": "U Châu",
        "ten_npc": "Thành Đô Vĩ",
        "level": 103,
        "hp": 944,
        "sp": 387,
        "hpx": 48,
        "spx": 19,
        "int": 17,
        "atk": 51,
        "def": 44,
        "agi": 24,
        "item1": 26238,
        "item2": 26238,
        "item3": 26303,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11040,
        "idmap": 10966,
        "ten_npc": "Tào Hồng",
        "level": 104,
        "hp": 1014,
        "sp": 332,
        "hpx": 42,
        "spx": 10,
        "int": 17,
        "atk": 92,
        "def": 41,
        "agi": 38,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47115
    },
    {
        "idnpc": 11072,
        "idmap": 10987,
        "ten_map": "Van Thanh Me Cung 1",
        "ten_npc": "Tang Bá",
        "level": 104,
        "hp": 1014,
        "sp": 396,
        "hpx": 42,
        "spx": 20,
        "int": 16,
        "atk": 78,
        "def": 42,
        "agi": 40,
        "item1": 26082,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47093
    },
    {
        "idnpc": 11123,
        "idmap": 10997,
        "ten_npc": "Quách Nữ Vương",
        "level": 104,
        "hp": 980,
        "sp": 421,
        "hpx": 20,
        "spx": 24,
        "int": 80,
        "atk": 72,
        "def": 15,
        "agi": 37,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 48042,
        "item5": 0,
        "item6": 47139
    },
    {
        "idnpc": 12042,
        "idmap": 11133,
        "ten_map": "N.Ung Thieu Phung Lai Thon",
        "thanh": "Thanh Châu",
        "ten_npc": "Quan Bình",
        "level": 104,
        "hp": 1118,
        "sp": 332,
        "hpx": 42,
        "spx": 10,
        "int": 17,
        "atk": 89,
        "def": 41,
        "agi": 41,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47047
    },
    {
        "idnpc": 12090,
        "idmap": 11303,
        "thanh": "Thanh Châu",
        "ten_npc": "Điệp Nhi",
        "level": 104,
        "hp": 1133,
        "sp": 332,
        "hpx": 44,
        "spx": 10,
        "int": 63,
        "atk": 75,
        "def": 22,
        "agi": 35,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 48044,
        "item5": 0,
        "item6": 47192
    },
    {
        "idnpc": 12136,
        "idmap": 11431,
        "ten_map": "Rung Thai Son",
        "thanh": "Thanh Châu",
        "ten_npc": "Châu Quần",
        "level": 104,
        "hp": 1023,
        "sp": 428,
        "hpx": 43,
        "spx": 25,
        "int": 92,
        "atk": 8,
        "def": 25,
        "agi": 57,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14240,
        "idmap": 11841,
        "ten_map": "Rung Vi Son 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Quản Thừa",
        "level": 104,
        "hp": 1146,
        "sp": 409,
        "hpx": 46,
        "spx": 22,
        "int": 13,
        "atk": 81,
        "def": 39,
        "agi": 47,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14449,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Quách Viện",
        "level": 104,
        "hp": 984,
        "sp": 466,
        "hpx": 37,
        "spx": 31,
        "int": 16,
        "atk": 78,
        "def": 35,
        "agi": 51,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47207
    },
    {
        "idnpc": 14490,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Thạch.Q.Nguyên",
        "level": 104,
        "hp": 1014,
        "sp": 451,
        "hpx": 42,
        "spx": 29,
        "int": 87,
        "atk": 18,
        "def": 35,
        "agi": 40,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47150
    },
    {
        "idnpc": 14576,
        "idmap": 12032,
        "thanh": "U Châu",
        "ten_npc": "Sơn Đại Vương",
        "level": 104,
        "hp": 1093,
        "sp": 357,
        "hpx": 54,
        "spx": 14,
        "int": 12,
        "atk": 77,
        "def": 59,
        "agi": 36,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17028,
        "idmap": 12514,
        "thanh": "U Châu",
        "ten_npc": "Thạch Hổ binh",
        "level": 104,
        "hp": 806,
        "sp": 352,
        "hpx": 42,
        "spx": 25,
        "int": 7,
        "atk": 56,
        "def": 34,
        "agi": 43,
        "item1": 27072,
        "item2": 32027,
        "item3": 26095,
        "item4": 35018,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17340,
        "idmap": 12721,
        "ten_map": "An The Thon",
        "thanh": "U Châu",
        "ten_npc": "Tr.Quân Hiệu",
        "level": 104,
        "hp": 910,
        "sp": 425,
        "hpx": 42,
        "spx": 25,
        "int": 5,
        "atk": 46,
        "def": 41,
        "agi": 48,
        "item1": 26251,
        "item2": 26251,
        "item3": 26238,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17440,
        "idmap": 12808,
        "ten_map": "Map nguoi rom TQ",
        "thanh": "U Châu",
        "ten_npc": "Địch Dũng Sĩ",
        "level": 104,
        "hp": 931,
        "sp": 345,
        "hpx": 45,
        "spx": 12,
        "int": 15,
        "atk": 65,
        "def": 49,
        "agi": 21,
        "item1": 26298,
        "item2": 26095,
        "item3": 26298,
        "item4": 36018,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10228,
        "idmap": 10951,
        "ten_npc": "TLỗChưa RaTrận",
        "level": 105,
        "hp": 906,
        "sp": 392,
        "hpx": 40,
        "spx": 19,
        "int": 16,
        "atk": 57,
        "def": 59,
        "agi": 17,
        "item1": 26251,
        "item2": 26251,
        "item3": 32026,
        "item4": 36010,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10229,
        "idmap": 10951,
        "ten_npc": "Phong Đao Binh",
        "level": 105,
        "hp": 944,
        "sp": 366,
        "hpx": 46,
        "spx": 15,
        "int": 12,
        "atk": 64,
        "def": 60,
        "agi": 11,
        "item1": 32026,
        "item2": 32026,
        "item3": 26251,
        "item4": 50003,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11037,
        "idmap": 10966,
        "ten_npc": "Tào Phi",
        "level": 105,
        "hp": 921,
        "sp": 468,
        "hpx": 26,
        "spx": 31,
        "int": 81,
        "atk": 22,
        "def": 52,
        "agi": 39,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47034
    },
    {
        "idnpc": 11057,
        "idmap": 10983,
        "ten_npc": "Trần Quần",
        "level": 105,
        "hp": 960,
        "sp": 455,
        "hpx": 32,
        "spx": 29,
        "int": 88,
        "atk": 14,
        "def": 36,
        "agi": 42,
        "item1": 26078,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47151
    },
    {
        "idnpc": 11215,
        "idmap": 11031,
        "thanh": "Thanh Châu",
        "ten_npc": "Thường Điêu",
        "level": 105,
        "hp": 1508,
        "sp": 398,
        "hpx": 52,
        "spx": 20,
        "int": 15,
        "atk": 85,
        "def": 45,
        "agi": 30,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 65193,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12089,
        "idmap": 11303,
        "thanh": "Thanh Châu",
        "ten_npc": "Phụng Nhi",
        "level": 105,
        "hp": 1110,
        "sp": 353,
        "hpx": 39,
        "spx": 13,
        "int": 62,
        "atk": 76,
        "def": 21,
        "agi": 38,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 48043,
        "item5": 0,
        "item6": 47191
    },
    {
        "idnpc": 13032,
        "idmap": 11509,
        "ten_map": "Son Dong Thai Son 9",
        "thanh": "Thanh Châu",
        "ten_npc": "Hàn Đang",
        "level": 105,
        "hp": 1024,
        "sp": 434,
        "hpx": 42,
        "spx": 26,
        "int": 21,
        "atk": 76,
        "def": 36,
        "agi": 40,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47152
    },
    {
        "idnpc": 13101,
        "idmap": 11543,
        "ten_map": "Dong Quy Than 13",
        "thanh": "Thanh Châu",
        "ten_npc": "Công Tôn Dương",
        "level": 105,
        "hp": 998,
        "sp": 340,
        "hpx": 38,
        "spx": 11,
        "int": 76,
        "atk": 65,
        "def": 22,
        "agi": 38,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47225
    },
    {
        "idnpc": 14007,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Đổng Việt",
        "level": 105,
        "hp": 998,
        "sp": 424,
        "hpx": 38,
        "spx": 24,
        "int": 36,
        "atk": 65,
        "def": 38,
        "agi": 40,
        "item1": 26051,
        "item2": 26082,
        "item3": 26003,
        "item4": 0,
        "item5": 0,
        "item6": 47216
    },
    {
        "idnpc": 14024,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Kỷ Linh",
        "level": 105,
        "hp": 1011,
        "sp": 372,
        "hpx": 40,
        "spx": 16,
        "int": 21,
        "atk": 82,
        "def": 44,
        "agi": 38,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 47158
    },
    {
        "idnpc": 14256,
        "idmap": 11841,
        "ten_map": "Rung Vi Son 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Mã Thăng",
        "level": 105,
        "hp": 1024,
        "sp": 340,
        "hpx": 42,
        "spx": 11,
        "int": 19,
        "atk": 91,
        "def": 41,
        "agi": 37,
        "item1": 27019,
        "item2": 26035,
        "item3": 26086,
        "item4": 0,
        "item5": 0,
        "item6": 47095
    },
    {
        "idnpc": 14393,
        "idmap": 12011,
        "ten_map": "Thanh Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Thạc Tấn",
        "level": 105,
        "hp": 1502,
        "sp": 372,
        "hpx": 51,
        "spx": 16,
        "int": 21,
        "atk": 86,
        "def": 36,
        "agi": 30,
        "item1": 26034,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47112
    },
    {
        "idnpc": 14561,
        "idmap": 12023,
        "ten_map": "Diem Binh Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Sĩ Tiếp",
        "level": 105,
        "hp": 1088,
        "sp": 360,
        "hpx": 52,
        "spx": 14,
        "int": 40,
        "atk": 77,
        "def": 31,
        "agi": 34,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47315
    },
    {
        "idnpc": 17029,
        "idmap": 12514,
        "thanh": "U Châu",
        "ten_npc": "Vân Báo Nữ",
        "level": 105,
        "hp": 814,
        "sp": 356,
        "hpx": 42,
        "spx": 25,
        "int": 7,
        "atk": 33,
        "def": 43,
        "agi": 57,
        "item1": 26095,
        "item2": 26072,
        "item3": 26013,
        "item4": 36022,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17342,
        "idmap": 12721,
        "ten_map": "An The Thon",
        "thanh": "U Châu",
        "ten_npc": "ThiếtKích Quân",
        "level": 105,
        "hp": 919,
        "sp": 428,
        "hpx": 42,
        "spx": 25,
        "int": 5,
        "atk": 49,
        "def": 47,
        "agi": 39,
        "item1": 26251,
        "item2": 26251,
        "item3": 27072,
        "item4": 41026,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17443,
        "idmap": 12809,
        "ten_map": "Rung Nha Quan",
        "thanh": "U Châu",
        "ten_npc": "Lũng Hữu Binh",
        "level": 105,
        "hp": 900,
        "sp": 353,
        "hpx": 39,
        "spx": 13,
        "int": 19,
        "atk": 61,
        "def": 54,
        "agi": 21,
        "item1": 27012,
        "item2": 27012,
        "item3": 26298,
        "item4": 34010,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10146,
        "idmap": 10861,
        "ten_npc": "Thủy Sư Quân",
        "level": 106,
        "hp": 928,
        "sp": 388,
        "hpx": 42,
        "spx": 18,
        "int": 67,
        "atk": 14,
        "def": 52,
        "agi": 17,
        "item1": 26137,
        "item2": 26138,
        "item3": 26137,
        "item4": 50001,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10159,
        "idmap": 10868,
        "ten_npc": "Hắc Pháp Sư",
        "level": 106,
        "hp": 951,
        "sp": 355,
        "hpx": 46,
        "spx": 13,
        "int": 66,
        "atk": 14,
        "def": 56,
        "agi": 14,
        "item1": 26139,
        "item2": 26202,
        "item3": 26139,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10177,
        "idmap": 10905,
        "ten_npc": "Lính Ma Dao",
        "level": 106,
        "hp": 977,
        "sp": 375,
        "hpx": 50,
        "spx": 16,
        "int": 5,
        "atk": 66,
        "def": 59,
        "agi": 11,
        "item1": 26013,
        "item2": 26013,
        "item3": 26071,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10266,
        "idmap": 10961,
        "ten_npc": "Lý Ngọc",
        "level": 106,
        "hp": 1121,
        "sp": 436,
        "hpx": 56,
        "spx": 26,
        "int": 18,
        "atk": 76,
        "def": 48,
        "agi": 24,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10267,
        "idmap": 10961,
        "ten_npc": "Vương Hải",
        "level": 106,
        "hp": 1128,
        "sp": 436,
        "hpx": 57,
        "spx": 26,
        "int": 18,
        "atk": 73,
        "def": 50,
        "agi": 24,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11120,
        "idmap": 10997,
        "ten_npc": "Tr.Xuân Hoa",
        "level": 106,
        "hp": 1093,
        "sp": 490,
        "hpx": 35,
        "spx": 34,
        "int": 84,
        "atk": 37,
        "def": 27,
        "agi": 32,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47224
    },
    {
        "idnpc": 12109,
        "idmap": 11401,
        "ten_map": "Dinh Mat Vi Son",
        "thanh": "Thanh Châu",
        "ten_npc": "Bành Dạng",
        "level": 106,
        "hp": 967,
        "sp": 413,
        "hpx": 32,
        "spx": 22,
        "int": 75,
        "atk": 27,
        "def": 41,
        "agi": 53,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47212
    },
    {
        "idnpc": 13104,
        "idmap": 11543,
        "ten_map": "Dong Quy Than 13",
        "thanh": "Thanh Châu",
        "ten_npc": "Đại Võ Quan",
        "level": 106,
        "hp": 1019,
        "sp": 432,
        "hpx": 40,
        "spx": 25,
        "int": 86,
        "atk": 17,
        "def": 47,
        "agi": 36,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47230
    },
    {
        "idnpc": 14017,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Lý Dư",
        "level": 106,
        "hp": 890,
        "sp": 484,
        "hpx": 20,
        "spx": 33,
        "int": 91,
        "atk": 16,
        "def": 40,
        "agi": 42,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 49001,
        "item6": 47088
    },
    {
        "idnpc": 14111,
        "idmap": 11584,
        "thanh": "Thanh Châu",
        "ten_npc": "Tượng Nghĩa Cừ",
        "level": 106,
        "hp": 1140,
        "sp": 436,
        "hpx": 42,
        "spx": 26,
        "int": 16,
        "atk": 85,
        "def": 34,
        "agi": 50,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47179
    },
    {
        "idnpc": 14125,
        "idmap": 11584,
        "thanh": "Thanh Châu",
        "ten_npc": "Hoàng Bửu Tung",
        "level": 106,
        "hp": 993,
        "sp": 375,
        "hpx": 36,
        "spx": 16,
        "int": 26,
        "atk": 82,
        "def": 40,
        "agi": 42,
        "item1": 26016,
        "item2": 27019,
        "item3": 26035,
        "item4": 0,
        "item5": 0,
        "item6": 47169
    },
    {
        "idnpc": 14592,
        "idmap": 12042,
        "ten_map": "Rung Thuong Son",
        "thanh": "U Châu",
        "ten_npc": "Tiết Quy Ni",
        "level": 106,
        "hp": 1051,
        "sp": 490,
        "hpx": 45,
        "spx": 34,
        "int": 77,
        "atk": 31,
        "def": 18,
        "agi": 45,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47340
    },
    {
        "idnpc": 14635,
        "idmap": 12062,
        "ten_map": "Dai Do Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Trán",
        "level": 106,
        "hp": 999,
        "sp": 509,
        "hpx": 37,
        "spx": 37,
        "int": 77,
        "atk": 17,
        "def": 32,
        "agi": 51,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17343,
        "idmap": 12721,
        "ten_map": "An The Thon",
        "thanh": "U Châu",
        "ten_npc": "Đội Tiên Phong",
        "level": 106,
        "hp": 928,
        "sp": 433,
        "hpx": 42,
        "spx": 25,
        "int": 6,
        "atk": 48,
        "def": 48,
        "agi": 38,
        "item1": 27072,
        "item2": 27072,
        "item3": 26251,
        "item4": 34010,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17444,
        "idmap": 12809,
        "ten_map": "Rung Nha Quan",
        "thanh": "U Châu",
        "ten_npc": "Sáo Tham Trại",
        "level": 106,
        "hp": 906,
        "sp": 388,
        "hpx": 39,
        "spx": 18,
        "int": 21,
        "atk": 60,
        "def": 50,
        "agi": 19,
        "item1": 26298,
        "item2": 26298,
        "item3": 27012,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10147,
        "idmap": 10862,
        "ten_npc": "Phù Thủy Lửa",
        "level": 107,
        "hp": 937,
        "sp": 383,
        "hpx": 43,
        "spx": 17,
        "int": 66,
        "atk": 16,
        "def": 53,
        "agi": 19,
        "item1": 26138,
        "item2": 26137,
        "item3": 26138,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10158,
        "idmap": 10867,
        "ten_npc": "Lục Pháp Sư",
        "level": 107,
        "hp": 984,
        "sp": 345,
        "hpx": 50,
        "spx": 11,
        "int": 67,
        "atk": 15,
        "def": 54,
        "agi": 18,
        "item1": 26202,
        "item2": 26139,
        "item3": 26202,
        "item4": 50003,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10179,
        "idmap": 10905,
        "ten_npc": "Lính Ma Kiếm",
        "level": 107,
        "hp": 1003,
        "sp": 364,
        "hpx": 53,
        "spx": 14,
        "int": 60,
        "atk": 23,
        "def": 53,
        "agi": 12,
        "item1": 26071,
        "item2": 26071,
        "item3": 26013,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11144,
        "idmap": 11003,
        "ten_map": "Cân Giao Dông Quan",
        "thanh": "Thanh Châu",
        "ten_npc": "Trương Chế",
        "level": 107,
        "hp": 1198,
        "sp": 416,
        "hpx": 50,
        "spx": 22,
        "int": 89,
        "atk": 31,
        "def": 42,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47119
    },
    {
        "idnpc": 12117,
        "idmap": 11411,
        "ten_map": "Khuc Trac Khong Mieu",
        "thanh": "Thanh Châu",
        "ten_npc": "Truyền Đồng",
        "level": 107,
        "hp": 981,
        "sp": 422,
        "hpx": 33,
        "spx": 23,
        "int": 24,
        "atk": 88,
        "def": 35,
        "agi": 50,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47298
    },
    {
        "idnpc": 14620,
        "idmap": 12061,
        "ten_map": "Diep Thanh Thanh Mon",
        "thanh": "U Châu",
        "ten_npc": "Chiêu ThụTruân",
        "level": 107,
        "hp": 1039,
        "sp": 416,
        "hpx": 42,
        "spx": 22,
        "int": 49,
        "atk": 66,
        "def": 26,
        "agi": 49,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47373
    },
    {
        "idnpc": 14680,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Thiết Mộc Yến",
        "level": 107,
        "hp": 896,
        "sp": 365,
        "hpx": 53,
        "spx": 26,
        "int": 16,
        "atk": 81,
        "def": 52,
        "agi": 26,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17344,
        "idmap": 12721,
        "ten_map": "An The Thon",
        "thanh": "U Châu",
        "ten_npc": "Binh DiêuTương",
        "level": 107,
        "hp": 937,
        "sp": 436,
        "hpx": 43,
        "spx": 25,
        "int": 5,
        "atk": 50,
        "def": 47,
        "agi": 42,
        "item1": 26202,
        "item2": 26203,
        "item3": 26205,
        "item4": 34023,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17563,
        "idmap": 12824,
        "ten_map": "Rung Trung Son 3",
        "thanh": "U Châu",
        "ten_npc": "Ap Lương Binh",
        "level": 107,
        "hp": 984,
        "sp": 403,
        "hpx": 50,
        "spx": 20,
        "int": 13,
        "atk": 56,
        "def": 51,
        "agi": 23,
        "item1": 26252,
        "item2": 26252,
        "item3": 26298,
        "item4": 50001,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11064,
        "idmap": 10984,
        "ten_npc": "Giả Lục",
        "level": 108,
        "hp": 1055,
        "sp": 467,
        "hpx": 43,
        "spx": 30,
        "int": 89,
        "atk": 18,
        "def": 43,
        "agi": 34,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47137
    },
    {
        "idnpc": 12114,
        "idmap": 11401,
        "ten_map": "Dinh Mat Vi Son",
        "thanh": "Thanh Châu",
        "ten_npc": "Hứa Tĩnh",
        "level": 108,
        "hp": 1105,
        "sp": 411,
        "hpx": 51,
        "spx": 21,
        "int": 78,
        "atk": 8,
        "def": 54,
        "agi": 44,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47168
    },
    {
        "idnpc": 14633,
        "idmap": 12062,
        "ten_map": "Dai Do Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Thản Già",
        "level": 108,
        "hp": 1221,
        "sp": 463,
        "hpx": 69,
        "spx": 29,
        "int": 17,
        "atk": 87,
        "def": 39,
        "agi": 15,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17416,
        "idmap": 12806,
        "ten_map": "Rung Di Huyen",
        "thanh": "U Châu",
        "ten_npc": "Tuần Hãng Viên",
        "level": 108,
        "hp": 977,
        "sp": 411,
        "hpx": 48,
        "spx": 21,
        "int": 6,
        "atk": 64,
        "def": 38,
        "agi": 36,
        "item1": 26188,
        "item2": 26106,
        "item3": 26188,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17441,
        "idmap": 12809,
        "ten_map": "Rung Nha Quan",
        "thanh": "U Châu",
        "ten_npc": "Mong Hổ quân",
        "level": 108,
        "hp": 958,
        "sp": 386,
        "hpx": 45,
        "spx": 17,
        "int": 15,
        "atk": 63,
        "def": 48,
        "agi": 25,
        "item1": 26013,
        "item2": 26013,
        "item3": 26095,
        "item4": 50003,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17564,
        "idmap": 12825,
        "ten_map": "Rung Lau Song 1",
        "thanh": "U Châu",
        "ten_npc": "Đội TruyTrọng",
        "level": 108,
        "hp": 977,
        "sp": 411,
        "hpx": 48,
        "spx": 21,
        "int": 13,
        "atk": 58,
        "def": 52,
        "agi": 21,
        "item1": 26298,
        "item2": 26298,
        "item3": 26252,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11197,
        "idmap": 11013,
        "ten_map": "Rung Bac Hai",
        "thanh": "Thanh Châu",
        "ten_npc": "Đại võ quan",
        "level": 109,
        "hp": 996,
        "sp": 491,
        "hpx": 33,
        "spx": 33,
        "int": 101,
        "atk": 34,
        "def": 26,
        "agi": 49,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12029,
        "idmap": 11041,
        "thanh": "Thanh Châu",
        "ten_npc": "Lưu Ba",
        "level": 109,
        "hp": 1138,
        "sp": 452,
        "hpx": 38,
        "spx": 27,
        "int": 79,
        "atk": 44,
        "def": 31,
        "agi": 38,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47172
    },
    {
        "idnpc": 12138,
        "idmap": 11432,
        "ten_map": "Dinh nui Thai Son",
        "thanh": "Thanh Châu",
        "ten_npc": "Sa Tuấn",
        "level": 109,
        "hp": 1138,
        "sp": 446,
        "hpx": 55,
        "spx": 26,
        "int": 86,
        "atk": 20,
        "def": 33,
        "agi": 37,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47348
    },
    {
        "idnpc": 14067,
        "idmap": 11581,
        "thanh": "Thanh Châu",
        "ten_npc": "Đổng Văn",
        "level": 109,
        "hp": 1065,
        "sp": 456,
        "hpx": 44,
        "spx": 28,
        "int": 42,
        "atk": 52,
        "def": 42,
        "agi": 41,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 47286
    },
    {
        "idnpc": 14594,
        "idmap": 12051,
        "ten_map": "Tan Tich Lac Hai",
        "thanh": "U Châu",
        "ten_npc": "Tố Lợi",
        "level": 109,
        "hp": 1093,
        "sp": 446,
        "hpx": 48,
        "spx": 26,
        "int": 12,
        "atk": 90,
        "def": 63,
        "agi": 15,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17031,
        "idmap": 12514,
        "thanh": "U Châu",
        "ten_npc": "Hắt Hùng.D.Sĩ",
        "level": 109,
        "hp": 847,
        "sp": 368,
        "hpx": 44,
        "spx": 26,
        "int": 5,
        "atk": 67,
        "def": 45,
        "agi": 25,
        "item1": 26106,
        "item2": 26107,
        "item3": 26202,
        "item4": 34031,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17345,
        "idmap": 12721,
        "ten_map": "An The Thon",
        "thanh": "U Châu",
        "ten_npc": "Ẩn Đà Thủ",
        "level": 109,
        "hp": 956,
        "sp": 444,
        "hpx": 44,
        "spx": 26,
        "int": 6,
        "atk": 43,
        "def": 50,
        "agi": 45,
        "item1": 26203,
        "item2": 26202,
        "item3": 26205,
        "item4": 41031,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10002,
        "idmap": 10701,
        "ten_map": "Nha Lao",
        "ten_npc": "Trương Bảo",
        "level": 110,
        "hp": 1075,
        "sp": 467,
        "hpx": 27,
        "spx": 29,
        "int": 65,
        "atk": 38,
        "def": 44,
        "agi": 47,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47096
    },
    {
        "idnpc": 11155,
        "idmap": 11011,
        "ten_map": "Bac Hai Thanh Mon",
        "thanh": "Thanh Châu",
        "ten_npc": "Điển Mãn",
        "level": 110,
        "hp": 1269,
        "sp": 384,
        "hpx": 57,
        "spx": 16,
        "int": 11,
        "atk": 87,
        "def": 57,
        "agi": 30,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47220
    },
    {
        "idnpc": 12033,
        "idmap": 11042,
        "thanh": "Thanh Châu",
        "ten_npc": "Lưu Bị",
        "level": 110,
        "hp": 1107,
        "sp": 422,
        "hpx": 32,
        "spx": 22,
        "int": 52,
        "atk": 65,
        "def": 39,
        "agi": 40,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 48039,
        "item5": 0,
        "item6": 47053
    },
    {
        "idnpc": 14061,
        "idmap": 11581,
        "thanh": "Thanh Châu",
        "ten_npc": "Hoa Hùng",
        "level": 110,
        "hp": 1185,
        "sp": 345,
        "hpx": 44,
        "spx": 10,
        "int": 17,
        "atk": 94,
        "def": 44,
        "agi": 41,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 47147
    },
    {
        "idnpc": 14596,
        "idmap": 12051,
        "ten_map": "Tan Tich Lac Hai",
        "thanh": "U Châu",
        "ten_npc": "Tu Võ Lô",
        "level": 110,
        "hp": 1159,
        "sp": 481,
        "hpx": 40,
        "spx": 31,
        "int": 16,
        "atk": 87,
        "def": 37,
        "agi": 48,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14606,
        "idmap": 12051,
        "ten_map": "Tan Tich Lac Hai",
        "thanh": "U Châu",
        "ten_npc": "Ất Địa Gia Lan",
        "level": 110,
        "hp": 1276,
        "sp": 384,
        "hpx": 58,
        "spx": 16,
        "int": 21,
        "atk": 67,
        "def": 29,
        "agi": 68,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17032,
        "idmap": 12515,
        "thanh": "U Châu",
        "ten_npc": "Chu Nha.D.Sĩ",
        "level": 110,
        "hp": 855,
        "sp": 371,
        "hpx": 44,
        "spx": 26,
        "int": 5,
        "atk": 65,
        "def": 50,
        "agi": 28,
        "item1": 26202,
        "item2": 26205,
        "item3": 26188,
        "item4": 41027,
        "item5": 36018,
        "item6": 0
    },
    {
        "idnpc": 17262,
        "idmap": 12589,
        "ten_map": "Mo Nguyet Trieu Ca 9",
        "thanh": "U Châu",
        "ten_npc": "Thốt Bác",
        "level": 110,
        "hp": 978,
        "sp": 410,
        "hpx": 46,
        "spx": 20,
        "int": 26,
        "atk": 57,
        "def": 53,
        "agi": 17,
        "item1": 26188,
        "item2": 26071,
        "item3": 26071,
        "item4": 41010,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17417,
        "idmap": 12806,
        "ten_map": "Rung Di Huyen",
        "thanh": "U Châu",
        "ten_npc": "TiểuThuyềnBinh",
        "level": 110,
        "hp": 1004,
        "sp": 410,
        "hpx": 50,
        "spx": 20,
        "int": 12,
        "atk": 67,
        "def": 35,
        "agi": 34,
        "item1": 26106,
        "item2": 26188,
        "item3": 26106,
        "item4": 34027,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17442,
        "idmap": 12809,
        "ten_map": "Rung Nha Quan",
        "thanh": "U Châu",
        "ten_npc": "Khương Đao Thủ",
        "level": 110,
        "hp": 984,
        "sp": 384,
        "hpx": 47,
        "spx": 16,
        "int": 16,
        "atk": 64,
        "def": 53,
        "agi": 22,
        "item1": 26298,
        "item2": 26298,
        "item3": 26095,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11036,
        "idmap": 10966,
        "ten_npc": "Tào Nhân",
        "level": 111,
        "hp": 1129,
        "sp": 386,
        "hpx": 34,
        "spx": 16,
        "int": 17,
        "atk": 94,
        "def": 46,
        "agi": 45,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47025
    },
    {
        "idnpc": 12025,
        "idmap": 11041,
        "thanh": "Thanh Châu",
        "ten_npc": "Đổng Uẩn",
        "level": 111,
        "hp": 919,
        "sp": 482,
        "hpx": 44,
        "spx": 31,
        "int": 97,
        "atk": 27,
        "def": 17,
        "agi": 47,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13013,
        "idmap": 11502,
        "ten_map": "Son Dong Thai Son 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Tôn Dực",
        "level": 111,
        "hp": 1116,
        "sp": 542,
        "hpx": 32,
        "spx": 40,
        "int": 16,
        "atk": 77,
        "def": 38,
        "agi": 52,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47132
    },
    {
        "idnpc": 14183,
        "idmap": 11811,
        "ten_map": "Rung Don Khuu",
        "thanh": "Thanh Châu",
        "ten_npc": "Cao Thuận",
        "level": 111,
        "hp": 1057,
        "sp": 378,
        "hpx": 23,
        "spx": 18,
        "int": 22,
        "atk": 94,
        "def": 58,
        "agi": 37,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 47089
    },
    {
        "idnpc": 14603,
        "idmap": 12051,
        "ten_map": "Tan Tich Lac Hai",
        "thanh": "U Châu",
        "ten_npc": "Bồ Ba Địa Qua",
        "level": 111,
        "hp": 1213,
        "sp": 405,
        "hpx": 47,
        "spx": 19,
        "int": 70,
        "atk": 47,
        "def": 24,
        "agi": 44,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47324
    },
    {
        "idnpc": 14610,
        "idmap": 12061,
        "ten_map": "Diep Thanh Thanh Mon",
        "thanh": "U Châu",
        "ten_npc": "Tư La Hầu",
        "level": 111,
        "hp": 1291,
        "sp": 418,
        "hpx": 59,
        "spx": 21,
        "int": 21,
        "atk": 88,
        "def": 39,
        "agi": 31,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15456,
        "idmap": 12409,
        "thanh": "U Châu",
        "ten_npc": "Trị Nguyên Đa",
        "level": 111,
        "hp": 3749,
        "sp": 425,
        "hpx": 44,
        "spx": 22,
        "int": 17,
        "atk": 97,
        "def": 32,
        "agi": 47,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17445,
        "idmap": 12809,
        "ten_map": "Rung Nha Quan",
        "thanh": "U Châu",
        "ten_npc": "LChâu Thiết Vĩ",
        "level": 111,
        "hp": 1011,
        "sp": 392,
        "hpx": 50,
        "spx": 17,
        "int": 11,
        "atk": 63,
        "def": 55,
        "agi": 22,
        "item1": 27080,
        "item2": 27080,
        "item3": 32038,
        "item4": 46018,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17446,
        "idmap": 12809,
        "ten_map": "Rung Nha Quan",
        "thanh": "U Châu",
        "ten_npc": "TruânBiên Quân",
        "level": 111,
        "hp": 985,
        "sp": 366,
        "hpx": 46,
        "spx": 13,
        "int": 28,
        "atk": 61,
        "def": 57,
        "agi": 14,
        "item1": 32038,
        "item2": 32038,
        "item3": 27080,
        "item4": 50003,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10261,
        "idmap": 10961,
        "ten_npc": "Đại Vũ Quan",
        "level": 112,
        "hp": 1180,
        "sp": 388,
        "hpx": 75,
        "spx": 16,
        "int": 7,
        "atk": 58,
        "def": 51,
        "agi": 12,
        "item1": 26252,
        "item2": 26252,
        "item3": 32038,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11052,
        "idmap": 10982,
        "ten_npc": "Quách Chuẩn",
        "level": 112,
        "hp": 1215,
        "sp": 440,
        "hpx": 46,
        "spx": 24,
        "int": 23,
        "atk": 84,
        "def": 34,
        "agi": 49,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47320
    },
    {
        "idnpc": 11071,
        "idmap": 10986,
        "ten_map": "Nghia Trang Van Thang",
        "ten_npc": "Lạc Tiến",
        "level": 112,
        "hp": 1176,
        "sp": 414,
        "hpx": 40,
        "spx": 20,
        "int": 20,
        "atk": 86,
        "def": 43,
        "agi": 45,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47129
    },
    {
        "idnpc": 11093,
        "idmap": 10992,
        "ten_map": "Vung dat bang gia 1",
        "ten_npc": "Khí Chí Tài",
        "level": 112,
        "hp": 1118,
        "sp": 447,
        "hpx": 31,
        "spx": 25,
        "int": 96,
        "atk": 26,
        "def": 20,
        "agi": 55,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47138
    },
    {
        "idnpc": 12059,
        "idmap": 11221,
        "ten_map": "Tiem Thuoc Bac Hai",
        "thanh": "Thanh Châu",
        "ten_npc": "H.Nguyệt Anh",
        "level": 112,
        "hp": 1207,
        "sp": 479,
        "hpx": 45,
        "spx": 30,
        "int": 76,
        "atk": 36,
        "def": 33,
        "agi": 43,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 48045,
        "item5": 0,
        "item6": 47058
    },
    {
        "idnpc": 13030,
        "idmap": 11508,
        "ten_map": "Son Dong Thai Son 8",
        "thanh": "Thanh Châu",
        "ten_npc": "Lỗ Túc",
        "level": 112,
        "hp": 1111,
        "sp": 483,
        "hpx": 30,
        "spx": 31,
        "int": 90,
        "atk": 17,
        "def": 42,
        "agi": 44,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47079
    },
    {
        "idnpc": 17263,
        "idmap": 12589,
        "ten_map": "Mo Nguyet Trieu Ca 9",
        "thanh": "U Châu",
        "ten_npc": "Phiến Tướng",
        "level": 112,
        "hp": 991,
        "sp": 421,
        "hpx": 46,
        "spx": 21,
        "int": 24,
        "atk": 58,
        "def": 54,
        "agi": 17,
        "item1": 26071,
        "item2": 26188,
        "item3": 26188,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17402,
        "idmap": 12805,
        "ten_map": "Tan Cung Phi Chi Lam",
        "thanh": "U Châu",
        "ten_npc": "Quân Đầu Bếp",
        "level": 112,
        "hp": 1030,
        "sp": 414,
        "hpx": 52,
        "spx": 20,
        "int": 9,
        "atk": 66,
        "def": 36,
        "agi": 37,
        "item1": 27080,
        "item2": 27080,
        "item3": 32026,
        "item4": 33027,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10262,
        "idmap": 10961,
        "ten_npc": "Đại Vũ Quan",
        "level": 113,
        "hp": 1122,
        "sp": 338,
        "hpx": 65,
        "spx": 8,
        "int": 19,
        "atk": 53,
        "def": 52,
        "agi": 28,
        "item1": 32038,
        "item2": 32038,
        "item3": 26252,
        "item4": 50003,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11006,
        "idmap": 10964,
        "ten_npc": "Lưu Hoa",
        "level": 113,
        "hp": 1080,
        "sp": 514,
        "hpx": 24,
        "spx": 35,
        "int": 91,
        "atk": 22,
        "def": 41,
        "agi": 43,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47117
    },
    {
        "idnpc": 11023,
        "idmap": 10965,
        "ten_npc": "Hạ Hầu Đức",
        "level": 113,
        "hp": 1289,
        "sp": 410,
        "hpx": 56,
        "spx": 19,
        "int": 92,
        "atk": 14,
        "def": 55,
        "agi": 31,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47259
    },
    {
        "idnpc": 12122,
        "idmap": 11411,
        "ten_map": "Khuc Trac Khong Mieu",
        "thanh": "Thanh Châu",
        "ten_npc": "Laỉ Cung",
        "level": 113,
        "hp": 1211,
        "sp": 495,
        "hpx": 44,
        "spx": 32,
        "int": 87,
        "atk": 22,
        "def": 28,
        "agi": 54,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47263
    },
    {
        "idnpc": 14605,
        "idmap": 12051,
        "ten_map": "Tan Tich Lac Hai",
        "thanh": "U Châu",
        "ten_npc": "Qua Luân Tri",
        "level": 113,
        "hp": 1219,
        "sp": 534,
        "hpx": 45,
        "spx": 38,
        "int": 18,
        "atk": 73,
        "def": 58,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14693,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Thiết Mộc Lăng",
        "level": 113,
        "hp": 2688,
        "sp": 383,
        "hpx": 45,
        "spx": 27,
        "int": 14,
        "atk": 104,
        "def": 54,
        "agi": 64,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17264,
        "idmap": 12589,
        "ten_map": "Mo Nguyet Trieu Ca 9",
        "thanh": "U Châu",
        "ten_npc": "Tì Tướng",
        "level": 113,
        "hp": 1004,
        "sp": 436,
        "hpx": 47,
        "spx": 23,
        "int": 25,
        "atk": 52,
        "def": 60,
        "agi": 18,
        "item1": 26013,
        "item2": 26013,
        "item3": 26238,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17356,
        "idmap": 12722,
        "ten_map": "Rung An The Thon",
        "thanh": "U Châu",
        "ten_npc": "Binh Sói Nuốt",
        "level": 113,
        "hp": 993,
        "sp": 460,
        "hpx": 45,
        "spx": 27,
        "int": 5,
        "atk": 45,
        "def": 50,
        "agi": 52,
        "item1": 27012,
        "item2": 27072,
        "item3": 27072,
        "item4": 50003,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17451,
        "idmap": 12811,
        "ten_map": "Rung Vo Tan",
        "thanh": "U Châu",
        "ten_npc": "Điển Vi Binh",
        "level": 113,
        "hp": 1011,
        "sp": 423,
        "hpx": 48,
        "spx": 21,
        "int": 14,
        "atk": 68,
        "def": 48,
        "agi": 25,
        "item1": 26298,
        "item2": 26298,
        "item3": 26251,
        "item4": 0,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 12054,
        "idmap": 11202,
        "thanh": "Thanh Châu",
        "ten_npc": "Thi Mã Chinh",
        "level": 114,
        "hp": 1230,
        "sp": 485,
        "hpx": 46,
        "spx": 30,
        "int": 71,
        "atk": 35,
        "def": 42,
        "agi": 34,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47044
    },
    {
        "idnpc": 14584,
        "idmap": 12042,
        "ten_map": "Rung Thuong Son",
        "thanh": "U Châu",
        "ten_npc": "Lang Trĩ",
        "level": 114,
        "hp": 1259,
        "sp": 445,
        "hpx": 50,
        "spx": 24,
        "int": 29,
        "atk": 88,
        "def": 40,
        "agi": 35,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14590,
        "idmap": 12042,
        "ten_map": "Rung Thuong Son",
        "thanh": "U Châu",
        "ten_npc": "Bộ Độ Căn",
        "level": 114,
        "hp": 1363,
        "sp": 491,
        "hpx": 66,
        "spx": 31,
        "int": 17,
        "atk": 82,
        "def": 31,
        "agi": 39,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47328
    },
    {
        "idnpc": 14613,
        "idmap": 12061,
        "ten_map": "Diep Thanh Thanh Mon",
        "thanh": "U Châu",
        "ten_npc": "Khôn Đàn Huống",
        "level": 114,
        "hp": 1174,
        "sp": 438,
        "hpx": 37,
        "spx": 23,
        "int": 27,
        "atk": 78,
        "def": 60,
        "agi": 41,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15384,
        "idmap": 12401,
        "ten_map": "Tien Do Tu Vo Son",
        "thanh": "U Châu",
        "ten_npc": "Mã Lững",
        "level": 114,
        "hp": 1174,
        "sp": 419,
        "hpx": 37,
        "spx": 20,
        "int": 70,
        "atk": 85,
        "def": 22,
        "agi": 37,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47358
    },
    {
        "idnpc": 17312,
        "idmap": 12701,
        "ten_map": "Doanh Trai Huong Dung",
        "thanh": "U Châu",
        "ten_npc": "BinhĐaoMãHàn",
        "level": 114,
        "hp": 888,
        "sp": 384,
        "hpx": 46,
        "spx": 27,
        "int": 6,
        "atk": 61,
        "def": 38,
        "agi": 47,
        "item1": 26137,
        "item2": 26140,
        "item3": 26137,
        "item4": 37990,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17403,
        "idmap": 12805,
        "ten_map": "Tan Cung Phi Chi Lam",
        "thanh": "U Châu",
        "ten_npc": "BinhTrườngSa",
        "level": 114,
        "hp": 1044,
        "sp": 425,
        "hpx": 52,
        "spx": 21,
        "int": 8,
        "atk": 69,
        "def": 39,
        "agi": 36,
        "item1": 32026,
        "item2": 32026,
        "item3": 27080,
        "item4": 36024,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17452,
        "idmap": 12811,
        "ten_map": "Rung Vo Tan",
        "thanh": "U Châu",
        "ten_npc": "Thiết bạiBinh",
        "level": 114,
        "hp": 1037,
        "sp": 419,
        "hpx": 51,
        "spx": 20,
        "int": 14,
        "atk": 66,
        "def": 51,
        "agi": 24,
        "item1": 26251,
        "item2": 26251,
        "item3": 26298,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10151,
        "idmap": 10864,
        "ten_npc": "Man Nữ Võ Lăng",
        "level": 115,
        "hp": 1031,
        "sp": 388,
        "hpx": 49,
        "spx": 15,
        "int": 62,
        "atk": 22,
        "def": 47,
        "agi": 33,
        "item1": 27072,
        "item2": 27072,
        "item3": 27051,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11044,
        "idmap": 10966,
        "ten_npc": "Tào Trực",
        "level": 115,
        "hp": 1110,
        "sp": 624,
        "hpx": 26,
        "spx": 51,
        "int": 78,
        "atk": 30,
        "def": 39,
        "agi": 44,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47035
    },
    {
        "idnpc": 11060,
        "idmap": 10983,
        "ten_npc": "Trình Lập",
        "level": 115,
        "hp": 1117,
        "sp": 497,
        "hpx": 27,
        "spx": 32,
        "int": 96,
        "atk": 17,
        "def": 43,
        "agi": 45,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47029
    },
    {
        "idnpc": 11065,
        "idmap": 10985,
        "ten_npc": "Giả Hủ",
        "level": 115,
        "hp": 1136,
        "sp": 498,
        "hpx": 30,
        "spx": 32,
        "int": 99,
        "atk": 17,
        "def": 40,
        "agi": 42,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47038
    },
    {
        "idnpc": 12028,
        "idmap": 11041,
        "thanh": "Thanh Châu",
        "ten_npc": "Mã Đại",
        "level": 115,
        "hp": 1241,
        "sp": 434,
        "hpx": 46,
        "spx": 22,
        "int": 25,
        "atk": 87,
        "def": 42,
        "agi": 44,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47148
    },
    {
        "idnpc": 12094,
        "idmap": 11305,
        "ten_map": "Trai quan Cong Thanh",
        "thanh": "Thanh Châu",
        "ten_npc": "Mạnh Đạt",
        "level": 115,
        "hp": 1222,
        "sp": 454,
        "hpx": 43,
        "spx": 25,
        "int": 39,
        "atk": 80,
        "def": 22,
        "agi": 57,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47185
    },
    {
        "idnpc": 13024,
        "idmap": 11505,
        "ten_map": "Son Dong Thai Son 5",
        "thanh": "Thanh Châu",
        "ten_npc": "Trình Phổ",
        "level": 115,
        "hp": 1176,
        "sp": 408,
        "hpx": 36,
        "spx": 18,
        "int": 26,
        "atk": 91,
        "def": 43,
        "agi": 46,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47073
    },
    {
        "idnpc": 14602,
        "idmap": 12051,
        "ten_map": "Tan Tich Lac Hai",
        "thanh": "U Châu",
        "ten_npc": "Tây na bà địa",
        "level": 115,
        "hp": 1267,
        "sp": 421,
        "hpx": 50,
        "spx": 20,
        "int": 73,
        "atk": 50,
        "def": 22,
        "agi": 43,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47312
    },
    {
        "idnpc": 17265,
        "idmap": 12589,
        "ten_map": "Mo Nguyet Trieu Ca 9",
        "thanh": "U Châu",
        "ten_npc": "Đô Úy",
        "level": 115,
        "hp": 1011,
        "sp": 447,
        "hpx": 46,
        "spx": 24,
        "int": 22,
        "atk": 60,
        "def": 55,
        "agi": 19,
        "item1": 26238,
        "item2": 26238,
        "item3": 26013,
        "item4": 42024,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17313,
        "idmap": 12701,
        "ten_map": "Doanh Trai Huong Dung",
        "thanh": "U Châu",
        "ten_npc": "ThươngBinhMHàn",
        "level": 115,
        "hp": 896,
        "sp": 387,
        "hpx": 46,
        "spx": 27,
        "int": 5,
        "atk": 62,
        "def": 40,
        "agi": 45,
        "item1": 26138,
        "item2": 26141,
        "item3": 26137,
        "item4": 42025,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17357,
        "idmap": 12722,
        "ten_map": "Rung An The Thon",
        "thanh": "U Châu",
        "ten_npc": "Hổ Tiêu Võ Sĩ",
        "level": 115,
        "hp": 1011,
        "sp": 468,
        "hpx": 46,
        "spx": 27,
        "int": 6,
        "atk": 46,
        "def": 49,
        "agi": 51,
        "item1": 27072,
        "item2": 27012,
        "item3": 27012,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10152,
        "idmap": 10864,
        "ten_npc": "Man.B.Võ Lăng",
        "level": 116,
        "hp": 1070,
        "sp": 423,
        "hpx": 54,
        "spx": 20,
        "int": 10,
        "atk": 68,
        "def": 44,
        "agi": 34,
        "item1": 27051,
        "item2": 27051,
        "item3": 27072,
        "item4": 37991,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10238,
        "idmap": 10951,
        "ten_npc": "Chu Phụng",
        "level": 116,
        "hp": 1381,
        "sp": 397,
        "hpx": 66,
        "spx": 16,
        "int": 12,
        "atk": 82,
        "def": 62,
        "agi": 33,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11203,
        "idmap": 11021,
        "ten_map": "Thon Phung Lai",
        "thanh": "Thanh Châu",
        "ten_npc": "Trạch Nguyên",
        "level": 116,
        "hp": 1274,
        "sp": 450,
        "hpx": 32,
        "spx": 24,
        "int": 23,
        "atk": 91,
        "def": 42,
        "agi": 60,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14104,
        "idmap": 11583,
        "thanh": "Thanh Châu",
        "ten_npc": "Hứa Thiệu",
        "level": 116,
        "hp": 1151,
        "sp": 515,
        "hpx": 31,
        "spx": 34,
        "int": 84,
        "atk": 25,
        "def": 45,
        "agi": 43,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47160
    },
    {
        "idnpc": 14632,
        "idmap": 12062,
        "ten_map": "Dai Do Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Yểm La",
        "level": 116,
        "hp": 1296,
        "sp": 430,
        "hpx": 53,
        "spx": 21,
        "int": 21,
        "atk": 91,
        "def": 53,
        "agi": 34,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17447,
        "idmap": 12809,
        "ten_map": "Rung Nha Quan",
        "thanh": "U Châu",
        "ten_npc": "Tiêm Kích Binh",
        "level": 116,
        "hp": 1011,
        "sp": 417,
        "hpx": 45,
        "spx": 19,
        "int": 18,
        "atk": 66,
        "def": 58,
        "agi": 24,
        "item1": 27080,
        "item2": 27080,
        "item3": 32038,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11025,
        "idmap": 10965,
        "ten_npc": "Từ Thứ",
        "level": 117,
        "hp": 1193,
        "sp": 494,
        "hpx": 36,
        "spx": 26,
        "int": 98,
        "atk": 22,
        "def": 40,
        "agi": 42,
        "item1": 26109,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47045
    },
    {
        "idnpc": 12021,
        "idmap": 11041,
        "thanh": "Thanh Châu",
        "ten_npc": "Phí Vĩ",
        "level": 117,
        "hp": 887,
        "sp": 455,
        "hpx": 34,
        "spx": 37,
        "int": 101,
        "atk": 35,
        "def": 27,
        "agi": 50,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12085,
        "idmap": 11301,
        "ten_map": "Dong Quan Quan Phu",
        "thanh": "Thanh Châu",
        "ten_npc": "Đơn Phúc",
        "level": 117,
        "hp": 1193,
        "sp": 494,
        "hpx": 36,
        "spx": 26,
        "int": 98,
        "atk": 22,
        "def": 40,
        "agi": 42,
        "item1": 26109,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13025,
        "idmap": 11505,
        "ten_map": "Son Dong Thai Son 5",
        "thanh": "Thanh Châu",
        "ten_npc": "Hoàng Cái",
        "level": 117,
        "hp": 1199,
        "sp": 406,
        "hpx": 37,
        "spx": 17,
        "int": 27,
        "atk": 93,
        "def": 44,
        "agi": 46,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47076
    },
    {
        "idnpc": 14002,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Văn Sú",
        "level": 117,
        "hp": 1298,
        "sp": 366,
        "hpx": 52,
        "spx": 11,
        "int": 13,
        "atk": 96,
        "def": 47,
        "agi": 45,
        "item1": 26030,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47087
    },
    {
        "idnpc": 14079,
        "idmap": 11582,
        "thanh": "Thanh Châu",
        "ten_npc": "Thẩm Phối",
        "level": 117,
        "hp": 1173,
        "sp": 502,
        "hpx": 33,
        "spx": 32,
        "int": 87,
        "atk": 16,
        "def": 46,
        "agi": 47,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 47135
    },
    {
        "idnpc": 14591,
        "idmap": 12042,
        "ten_map": "Rung Thuong Son",
        "thanh": "U Châu",
        "ten_npc": "Phù La Hán",
        "level": 117,
        "hp": 1147,
        "sp": 564,
        "hpx": 29,
        "spx": 41,
        "int": 39,
        "atk": 73,
        "def": 35,
        "agi": 55,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47310
    },
    {
        "idnpc": 17266,
        "idmap": 12589,
        "ten_map": "Mo Nguyet Trieu Ca 9",
        "thanh": "U Châu",
        "ten_npc": "Bộ Túc",
        "level": 117,
        "hp": 1018,
        "sp": 439,
        "hpx": 45,
        "spx": 22,
        "int": 39,
        "atk": 55,
        "def": 50,
        "agi": 21,
        "item1": 26252,
        "item2": 26252,
        "item3": 26298,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17267,
        "idmap": 12589,
        "ten_map": "Mo Nguyet Trieu Ca 9",
        "thanh": "U Châu",
        "ten_npc": "Binh Tào",
        "level": 117,
        "hp": 1018,
        "sp": 452,
        "hpx": 45,
        "spx": 24,
        "int": 39,
        "atk": 60,
        "def": 52,
        "agi": 22,
        "item1": 26298,
        "item2": 26298,
        "item3": 26252,
        "item4": 37996,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17448,
        "idmap": 12809,
        "ten_map": "Rung Nha Quan",
        "thanh": "U Châu",
        "ten_npc": "PhươngTrân Đội",
        "level": 117,
        "hp": 1038,
        "sp": 412,
        "hpx": 48,
        "spx": 18,
        "int": 17,
        "atk": 63,
        "def": 63,
        "agi": 22,
        "item1": 32038,
        "item2": 32038,
        "item3": 27080,
        "item4": 37969,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17453,
        "idmap": 12811,
        "ten_map": "Rung Vo Tan",
        "thanh": "U Châu",
        "ten_npc": "Nhung Tướng",
        "level": 117,
        "hp": 1038,
        "sp": 412,
        "hpx": 48,
        "spx": 18,
        "int": 35,
        "atk": 60,
        "def": 54,
        "agi": 17,
        "item1": 26252,
        "item2": 26252,
        "item3": 32028,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12002,
        "idmap": 11031,
        "thanh": "Thanh Châu",
        "ten_npc": "Vương Bình",
        "level": 118,
        "hp": 1234,
        "sp": 428,
        "hpx": 41,
        "spx": 20,
        "int": 33,
        "atk": 87,
        "def": 48,
        "agi": 44,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47344
    },
    {
        "idnpc": 12147,
        "idmap": 11501,
        "ten_map": "Son Dong Thai Son 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Trương Thiệu",
        "level": 118,
        "hp": 1104,
        "sp": 421,
        "hpx": 57,
        "spx": 19,
        "int": 29,
        "atk": 99,
        "def": 19,
        "agi": 49,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13019,
        "idmap": 11503,
        "ten_map": "Son Dong Thai Son 3",
        "thanh": "Thanh Châu",
        "ten_npc": "Trương Nhung",
        "level": 118,
        "hp": 1142,
        "sp": 510,
        "hpx": 27,
        "spx": 32,
        "int": 99,
        "atk": 11,
        "def": 46,
        "agi": 51,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47128
    },
    {
        "idnpc": 14604,
        "idmap": 12051,
        "ten_map": "Tan Tich Lac Hai",
        "thanh": "U Châu",
        "ten_npc": "Luân Đa Tính",
        "level": 118,
        "hp": 1234,
        "sp": 639,
        "hpx": 41,
        "spx": 52,
        "int": 17,
        "atk": 96,
        "def": 32,
        "agi": 34,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15404,
        "idmap": 12406,
        "thanh": "U Châu",
        "ten_npc": "Phác Hồ",
        "level": 118,
        "hp": 3975,
        "sp": 418,
        "hpx": 45,
        "spx": 31,
        "int": 195,
        "atk": 25,
        "def": 49,
        "agi": 129,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17347,
        "idmap": 12721,
        "ten_map": "An The Thon",
        "thanh": "U Châu",
        "ten_npc": "Thăng Phàm Thủ",
        "level": 118,
        "hp": 1039,
        "sp": 494,
        "hpx": 47,
        "spx": 30,
        "int": 42,
        "atk": 45,
        "def": 46,
        "agi": 23,
        "item1": 26139,
        "item2": 26138,
        "item3": 26204,
        "item4": 37985,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17348,
        "idmap": 12721,
        "ten_map": "An The Thon",
        "thanh": "U Châu",
        "ten_npc": "Binh Kỳ Hiệu",
        "level": 118,
        "hp": 1039,
        "sp": 491,
        "hpx": 47,
        "spx": 30,
        "int": 41,
        "atk": 44,
        "def": 50,
        "agi": 21,
        "item1": 26137,
        "item2": 26139,
        "item3": 26140,
        "item4": 46018,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17454,
        "idmap": 12811,
        "ten_map": "Rung Vo Tan",
        "thanh": "U Châu",
        "ten_npc": "XíchKhươngBinh",
        "level": 118,
        "hp": 1039,
        "sp": 434,
        "hpx": 47,
        "spx": 21,
        "int": 16,
        "atk": 60,
        "def": 59,
        "agi": 30,
        "item1": 32028,
        "item2": 32028,
        "item3": 26252,
        "item4": 34026,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11027,
        "idmap": 10965,
        "ten_npc": "Xích Chiêu",
        "level": 119,
        "hp": 1190,
        "sp": 496,
        "hpx": 33,
        "spx": 30,
        "int": 30,
        "atk": 85,
        "def": 61,
        "agi": 41,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12023,
        "idmap": 11041,
        "thanh": "Thanh Châu",
        "ten_npc": "Quách Du",
        "level": 119,
        "hp": 1282,
        "sp": 457,
        "hpx": 47,
        "spx": 24,
        "int": 34,
        "atk": 78,
        "def": 24,
        "agi": 70,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 48046,
        "item5": 0,
        "item6": 47190
    },
    {
        "idnpc": 13132,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Tôn Hoàn",
        "level": 119,
        "hp": 1405,
        "sp": 408,
        "hpx": 48,
        "spx": 29,
        "int": 30,
        "atk": 102,
        "def": 21,
        "agi": 46,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14681,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Thạch Hạo",
        "level": 119,
        "hp": 929,
        "sp": 459,
        "hpx": 48,
        "spx": 37,
        "int": 90,
        "atk": 19,
        "def": 31,
        "agi": 45,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17314,
        "idmap": 12701,
        "ten_map": "Doanh Trai Huong Dung",
        "thanh": "U Châu",
        "ten_npc": "Võ Sĩ Mã Hàn",
        "level": 119,
        "hp": 929,
        "sp": 400,
        "hpx": 48,
        "spx": 28,
        "int": 5,
        "atk": 65,
        "def": 54,
        "agi": 36,
        "item1": 27080,
        "item2": 27083,
        "item3": 27080,
        "item4": 37970,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17358,
        "idmap": 12722,
        "ten_map": "Rung An The Thon",
        "thanh": "U Châu",
        "ten_npc": "Dịch Đao Binh",
        "level": 119,
        "hp": 1048,
        "sp": 486,
        "hpx": 48,
        "spx": 28,
        "int": 13,
        "atk": 52,
        "def": 48,
        "agi": 47,
        "item1": 27080,
        "item2": 27080,
        "item3": 27118,
        "item4": 37974,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10001,
        "idmap": 10701,
        "ten_map": "Nha Lao",
        "ten_npc": "Trương Giác",
        "level": 120,
        "hp": 1299,
        "sp": 591,
        "hpx": 30,
        "spx": 44,
        "int": 70,
        "atk": 30,
        "def": 46,
        "agi": 50,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 48005,
        "item5": 0,
        "item6": 47003
    },
    {
        "idnpc": 10230,
        "idmap": 10951,
        "ten_npc": "Trung chế tửu",
        "level": 120,
        "hp": 1031,
        "sp": 393,
        "hpx": 44,
        "spx": 14,
        "int": 52,
        "atk": 67,
        "def": 45,
        "agi": 16,
        "item1": 27051,
        "item2": 27051,
        "item3": 26237,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11007,
        "idmap": 10964,
        "ten_npc": "Vương Song",
        "level": 120,
        "hp": 1378,
        "sp": 591,
        "hpx": 42,
        "spx": 44,
        "int": 19,
        "atk": 101,
        "def": 31,
        "agi": 43,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12080,
        "idmap": 11301,
        "ten_map": "Dong Quan Quan Phu",
        "thanh": "Thanh Châu",
        "ten_npc": "Tưởng Uyển",
        "level": 120,
        "hp": 1332,
        "sp": 516,
        "hpx": 35,
        "spx": 33,
        "int": 93,
        "atk": 27,
        "def": 65,
        "agi": 28,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47245
    },
    {
        "idnpc": 13008,
        "idmap": 11502,
        "ten_map": "Son Dong Thai Son 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Chu Thái",
        "level": 120,
        "hp": 1391,
        "sp": 379,
        "hpx": 44,
        "spx": 12,
        "int": 28,
        "atk": 95,
        "def": 48,
        "agi": 43,
        "item1": 27034,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47067
    },
    {
        "idnpc": 14599,
        "idmap": 12051,
        "ten_map": "Tan Tich Lac Hai",
        "thanh": "U Châu",
        "ten_npc": "Phạm Hùng",
        "level": 120,
        "hp": 1404,
        "sp": 578,
        "hpx": 46,
        "spx": 42,
        "int": 17,
        "atk": 93,
        "def": 31,
        "agi": 49,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47306
    },
    {
        "idnpc": 14708,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Sĩ Huy",
        "level": 120,
        "hp": 5258,
        "sp": 406,
        "hpx": 48,
        "spx": 29,
        "int": 14,
        "atk": 154,
        "def": 64,
        "agi": 124,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15403,
        "idmap": 12406,
        "thanh": "U Châu",
        "ten_npc": "Đỗ Hộ",
        "level": 120,
        "hp": 4303,
        "sp": 487,
        "hpx": 85,
        "spx": 41,
        "int": 23,
        "atk": 165,
        "def": 70,
        "agi": 89,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17268,
        "idmap": 12589,
        "ten_map": "Mo Nguyet Trieu Ca 9",
        "thanh": "U Châu",
        "ten_npc": "Huyện Úy",
        "level": 120,
        "hp": 1038,
        "sp": 446,
        "hpx": 45,
        "spx": 22,
        "int": 30,
        "atk": 64,
        "def": 57,
        "agi": 22,
        "item1": 27079,
        "item2": 32026,
        "item3": 27079,
        "item4": 40031,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17269,
        "idmap": 12589,
        "ten_map": "Mo Nguyet Trieu Ca 9",
        "thanh": "U Châu",
        "ten_npc": "Hiệu Úy",
        "level": 120,
        "hp": 1031,
        "sp": 452,
        "hpx": 44,
        "spx": 23,
        "int": 40,
        "atk": 63,
        "def": 47,
        "agi": 21,
        "item1": 32026,
        "item2": 27079,
        "item3": 32026,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17315,
        "idmap": 12701,
        "ten_map": "Doanh Trai Huong Dung",
        "thanh": "U Châu",
        "ten_npc": "Tướng Mã Hàn",
        "level": 120,
        "hp": 938,
        "sp": 403,
        "hpx": 48,
        "spx": 28,
        "int": 5,
        "atk": 66,
        "def": 51,
        "agi": 38,
        "item1": 26071,
        "item2": 26072,
        "item3": 26071,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17346,
        "idmap": 12721,
        "ten_map": "An The Thon",
        "thanh": "U Châu",
        "ten_npc": "Đội Trịch Tiêu",
        "level": 120,
        "hp": 1058,
        "sp": 500,
        "hpx": 48,
        "spx": 30,
        "int": 43,
        "atk": 48,
        "def": 47,
        "agi": 22,
        "item1": 26138,
        "item2": 26139,
        "item3": 26204,
        "item4": 40031,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17360,
        "idmap": 12723,
        "ten_map": "Nha tranh An The Thon 1",
        "thanh": "U Châu",
        "ten_npc": "Liệt Trãm Sĩ",
        "level": 120,
        "hp": 1058,
        "sp": 489,
        "hpx": 48,
        "spx": 29,
        "int": 10,
        "atk": 51,
        "def": 47,
        "agi": 52,
        "item1": 32009,
        "item2": 32009,
        "item3": 27079,
        "item4": 33028,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17449,
        "idmap": 12811,
        "ten_map": "Rung Vo Tan",
        "thanh": "U Châu",
        "ten_npc": "Thảo Nghị Quân",
        "level": 120,
        "hp": 1031,
        "sp": 439,
        "hpx": 44,
        "spx": 21,
        "int": 33,
        "atk": 56,
        "def": 55,
        "agi": 29,
        "item1": 32026,
        "item2": 32026,
        "item3": 27051,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17493,
        "idmap": 12817,
        "thanh": "U Châu",
        "ten_npc": "Tam Hạp Quân",
        "level": 120,
        "hp": 1091,
        "sp": 459,
        "hpx": 53,
        "spx": 24,
        "int": 21,
        "atk": 50,
        "def": 65,
        "agi": 24,
        "item1": 32026,
        "item2": 32026,
        "item3": 26308,
        "item4": 37972,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10231,
        "idmap": 10951,
        "ten_npc": "Đao Sư Quân",
        "level": 121,
        "hp": 1031,
        "sp": 401,
        "hpx": 43,
        "spx": 15,
        "int": 59,
        "atk": 68,
        "def": 43,
        "agi": 11,
        "item1": 26237,
        "item2": 26237,
        "item3": 27051,
        "item4": 33030,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11175,
        "idmap": 11011,
        "ten_map": "Bac Hai Thanh Mon",
        "thanh": "Thanh Châu",
        "ten_npc": "Công Tôn Tập",
        "level": 121,
        "hp": 1381,
        "sp": 541,
        "hpx": 41,
        "spx": 36,
        "int": 89,
        "atk": 41,
        "def": 18,
        "agi": 55,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47316
    },
    {
        "idnpc": 12015,
        "idmap": 11041,
        "thanh": "Thanh Châu",
        "ten_npc": "Mã Lương",
        "level": 121,
        "hp": 1255,
        "sp": 561,
        "hpx": 22,
        "spx": 39,
        "int": 99,
        "atk": 38,
        "def": 30,
        "agi": 53,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47116
    },
    {
        "idnpc": 14091,
        "idmap": 11583,
        "thanh": "Thanh Châu",
        "ten_npc": "Nhan Lương",
        "level": 121,
        "hp": 1430,
        "sp": 388,
        "hpx": 48,
        "spx": 13,
        "int": 22,
        "atk": 97,
        "def": 44,
        "agi": 48,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 47086
    },
    {
        "idnpc": 17349,
        "idmap": 12722,
        "ten_map": "Rung An The Thon",
        "thanh": "U Châu",
        "ten_npc": "Thủy Sư Trưởng",
        "level": 121,
        "hp": 1067,
        "sp": 506,
        "hpx": 48,
        "spx": 31,
        "int": 49,
        "atk": 46,
        "def": 43,
        "agi": 22,
        "item1": 26139,
        "item2": 26137,
        "item3": 26140,
        "item4": 35019,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17359,
        "idmap": 12723,
        "ten_map": "Nha tranh An The Thon 1",
        "thanh": "U Châu",
        "ten_npc": "Mãnh Trảo Sĩ",
        "level": 121,
        "hp": 1067,
        "sp": 493,
        "hpx": 48,
        "spx": 29,
        "int": 12,
        "atk": 51,
        "def": 46,
        "agi": 51,
        "item1": 27118,
        "item2": 27118,
        "item3": 27080,
        "item4": 34024,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17450,
        "idmap": 12811,
        "ten_map": "Rung Vo Tan",
        "thanh": "U Châu",
        "ten_npc": "Liệtphong võsĩ",
        "level": 121,
        "hp": 1124,
        "sp": 435,
        "hpx": 57,
        "spx": 20,
        "int": 18,
        "atk": 61,
        "def": 58,
        "agi": 23,
        "item1": 27051,
        "item2": 27051,
        "item3": 32026,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17494,
        "idmap": 12817,
        "thanh": "U Châu",
        "ten_npc": "TâyThục ThủySư",
        "level": 121,
        "hp": 1091,
        "sp": 461,
        "hpx": 52,
        "spx": 24,
        "int": 22,
        "atk": 53,
        "def": 63,
        "agi": 23,
        "item1": 26308,
        "item2": 26308,
        "item3": 32026,
        "item4": 34025,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13005,
        "idmap": 11502,
        "ten_map": "Son Dong Thai Son 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Lữ Mông",
        "level": 122,
        "hp": 1278,
        "sp": 543,
        "hpx": 24,
        "spx": 36,
        "int": 45,
        "atk": 87,
        "def": 32,
        "agi": 61,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47066
    },
    {
        "idnpc": 13115,
        "idmap": 11560,
        "thanh": "Thanh Châu",
        "ten_npc": "Tôn Thiệu",
        "level": 122,
        "hp": 1430,
        "sp": 503,
        "hpx": 47,
        "spx": 30,
        "int": 94,
        "atk": 21,
        "def": 41,
        "agi": 53,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47215
    },
    {
        "idnpc": 13127,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Đại võ quan",
        "level": 122,
        "hp": 5924,
        "sp": 543,
        "hpx": 44,
        "spx": 36,
        "int": 50,
        "atk": 147,
        "def": 52,
        "agi": 116,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14597,
        "idmap": 12051,
        "ten_map": "Tan Tich Lac Hai",
        "thanh": "U Châu",
        "ten_npc": "Nhạn ĐiêuTuyết",
        "level": 122,
        "hp": 1437,
        "sp": 543,
        "hpx": 48,
        "spx": 36,
        "int": 29,
        "atk": 84,
        "def": 39,
        "agi": 48,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14642,
        "idmap": 12063,
        "ten_map": "Hoang Cung Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Toa Nguyệt",
        "level": 122,
        "hp": 1444,
        "sp": 497,
        "hpx": 49,
        "spx": 29,
        "int": 22,
        "atk": 92,
        "def": 47,
        "agi": 47,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17361,
        "idmap": 12724,
        "ten_map": "Nha tranh An The Thon 2",
        "thanh": "U Châu",
        "ten_npc": "Long Vệ",
        "level": 122,
        "hp": 1076,
        "sp": 497,
        "hpx": 49,
        "spx": 29,
        "int": 11,
        "atk": 54,
        "def": 50,
        "agi": 49,
        "item1": 27079,
        "item2": 27079,
        "item3": 32009,
        "item4": 41027,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17455,
        "idmap": 12811,
        "ten_map": "Rung Vo Tan",
        "thanh": "U Châu",
        "ten_npc": "Tề Vũ Quân",
        "level": 122,
        "hp": 1044,
        "sp": 430,
        "hpx": 44,
        "spx": 19,
        "int": 39,
        "atk": 47,
        "def": 63,
        "agi": 32,
        "item1": 26298,
        "item2": 26298,
        "item3": 26251,
        "item4": 36020,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17456,
        "idmap": 12811,
        "ten_map": "Rung Vo Tan",
        "thanh": "U Châu",
        "ten_npc": "LũngTrungTrại",
        "level": 122,
        "hp": 1044,
        "sp": 444,
        "hpx": 44,
        "spx": 21,
        "int": 32,
        "atk": 67,
        "def": 54,
        "agi": 25,
        "item1": 26251,
        "item2": 26251,
        "item3": 26298,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17495,
        "idmap": 12817,
        "thanh": "U Châu",
        "ten_npc": "Phan Sơn Binh",
        "level": 122,
        "hp": 1051,
        "sp": 464,
        "hpx": 45,
        "spx": 24,
        "int": 23,
        "atk": 66,
        "def": 60,
        "agi": 25,
        "item1": 26303,
        "item2": 26303,
        "item3": 26238,
        "item4": 41028,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10265,
        "idmap": 10961,
        "ten_npc": "Bành Tài",
        "level": 123,
        "hp": 1547,
        "sp": 532,
        "hpx": 63,
        "spx": 34,
        "int": 21,
        "atk": 81,
        "def": 57,
        "agi": 28,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13018,
        "idmap": 11503,
        "ten_map": "Son Dong Thai Son 3",
        "thanh": "Thanh Châu",
        "ten_npc": "Trương Chiêu",
        "level": 123,
        "hp": 1320,
        "sp": 530,
        "hpx": 29,
        "spx": 34,
        "int": 100,
        "atk": 13,
        "def": 47,
        "agi": 53,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47110
    },
    {
        "idnpc": 14520,
        "idmap": 12018,
        "ten_map": "Quan Tra TQ",
        "thanh": "U Châu",
        "ten_npc": "Tiểu Kim",
        "level": 123,
        "hp": 1360,
        "sp": 426,
        "hpx": 35,
        "spx": 18,
        "int": 88,
        "atk": 37,
        "def": 19,
        "agi": 89,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47131
    },
    {
        "idnpc": 14609,
        "idmap": 12061,
        "ten_map": "Diep Thanh Thanh Mon",
        "thanh": "U Châu",
        "ten_npc": "Bà La Môn",
        "level": 123,
        "hp": 1353,
        "sp": 492,
        "hpx": 34,
        "spx": 28,
        "int": 34,
        "atk": 86,
        "def": 38,
        "agi": 63,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14694,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Thiết Mộc Lăng",
        "level": 123,
        "hp": 3546,
        "sp": 416,
        "hpx": 49,
        "spx": 29,
        "int": 14,
        "atk": 114,
        "def": 64,
        "agi": 74,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17459,
        "idmap": 12811,
        "ten_map": "Rung Vo Tan",
        "thanh": "U Châu",
        "ten_npc": "Kinh Trì Binh",
        "level": 123,
        "hp": 1071,
        "sp": 459,
        "hpx": 47,
        "spx": 23,
        "int": 44,
        "atk": 51,
        "def": 58,
        "agi": 21,
        "item1": 26071,
        "item2": 26071,
        "item3": 26188,
        "item4": 40030,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17496,
        "idmap": 12817,
        "thanh": "U Châu",
        "ten_npc": "Thục Đao Binh",
        "level": 123,
        "hp": 1071,
        "sp": 453,
        "hpx": 47,
        "spx": 22,
        "int": 40,
        "atk": 54,
        "def": 50,
        "agi": 31,
        "item1": 26238,
        "item2": 26238,
        "item3": 26303,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10232,
        "idmap": 10951,
        "ten_npc": "Trương Lỗ",
        "level": 124,
        "hp": 1510,
        "sp": 488,
        "hpx": 56,
        "spx": 27,
        "int": 85,
        "atk": 41,
        "def": 21,
        "agi": 57,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47171
    },
    {
        "idnpc": 12046,
        "idmap": 11172,
        "ten_map": "N.Me Thai Su Tu Bac Hai",
        "thanh": "Thanh Châu",
        "ten_npc": "Nghiêm Nhan",
        "level": 124,
        "hp": 1550,
        "sp": 441,
        "hpx": 62,
        "spx": 20,
        "int": 24,
        "atk": 88,
        "def": 60,
        "agi": 30,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47063
    },
    {
        "idnpc": 14487,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Tả Hiền Vương",
        "level": 124,
        "hp": 1496,
        "sp": 488,
        "hpx": 54,
        "spx": 27,
        "int": 12,
        "atk": 96,
        "def": 41,
        "agi": 55,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47143
    },
    {
        "idnpc": 14557,
        "idmap": 12023,
        "ten_map": "Diem Binh Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Bàng",
        "level": 124,
        "hp": 1443,
        "sp": 448,
        "hpx": 46,
        "spx": 21,
        "int": 27,
        "atk": 92,
        "def": 64,
        "agi": 34,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47183
    },
    {
        "idnpc": 14682,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Vũ Văn Hoằng",
        "level": 124,
        "hp": 1136,
        "sp": 448,
        "hpx": 65,
        "spx": 34,
        "int": 11,
        "atk": 91,
        "def": 31,
        "agi": 51,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17318,
        "idmap": 12702,
        "ten_map": "DaiTruong Huong Dung",
        "thanh": "U Châu",
        "ten_npc": "Binh Thuận Nô",
        "level": 124,
        "hp": 971,
        "sp": 416,
        "hpx": 50,
        "spx": 29,
        "int": 4,
        "atk": 69,
        "def": 41,
        "agi": 50,
        "item1": 27089,
        "item2": 26141,
        "item3": 26071,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17460,
        "idmap": 12811,
        "ten_map": "Rung Vo Tan",
        "thanh": "U Châu",
        "ten_npc": "Hoảng Đao Thủ",
        "level": 124,
        "hp": 1078,
        "sp": 448,
        "hpx": 47,
        "spx": 21,
        "int": 43,
        "atk": 52,
        "def": 59,
        "agi": 23,
        "item1": 26188,
        "item2": 26188,
        "item3": 26071,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11066,
        "idmap": 10985,
        "ten_npc": "Cửu Du",
        "level": 125,
        "hp": 1346,
        "sp": 537,
        "hpx": 30,
        "spx": 34,
        "int": 97,
        "atk": 21,
        "def": 50,
        "agi": 48,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47039
    },
    {
        "idnpc": 11205,
        "idmap": 11021,
        "ten_map": "Thon Phung Lai",
        "thanh": "Thanh Châu",
        "ten_npc": "ThànhHà phẫnnộ",
        "level": 125,
        "hp": 5550,
        "sp": 1179,
        "hpx": 154,
        "spx": 130,
        "int": 231,
        "atk": 96,
        "def": 120,
        "agi": 170,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12044,
        "idmap": 11171,
        "ten_map": "N.Dien Vien Ngoai",
        "thanh": "Thanh Châu",
        "ten_npc": "Quan Xước",
        "level": 125,
        "hp": 1593,
        "sp": 457,
        "hpx": 67,
        "spx": 22,
        "int": 34,
        "atk": 86,
        "def": 67,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47189
    },
    {
        "idnpc": 14489,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Thôi Châu Bình",
        "level": 125,
        "hp": 1479,
        "sp": 537,
        "hpx": 50,
        "spx": 34,
        "int": 97,
        "atk": 44,
        "def": 25,
        "agi": 42,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47114
    },
    {
        "idnpc": 14524,
        "idmap": 12021,
        "ten_map": "Cua Thanh Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Tống Kiện",
        "level": 125,
        "hp": 1560,
        "sp": 450,
        "hpx": 62,
        "spx": 21,
        "int": 17,
        "atk": 90,
        "def": 72,
        "agi": 26,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47159
    },
    {
        "idnpc": 14538,
        "idmap": 12023,
        "ten_map": "Diem Binh Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Trương Nhâm",
        "level": 125,
        "hp": 1459,
        "sp": 430,
        "hpx": 47,
        "spx": 18,
        "int": 61,
        "atk": 85,
        "def": 52,
        "agi": 27,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47188
    },
    {
        "idnpc": 14601,
        "idmap": 12051,
        "ten_map": "Tan Tich Lac Hai",
        "thanh": "U Châu",
        "ten_npc": "Phạm Tầm",
        "level": 125,
        "hp": 1553,
        "sp": 604,
        "hpx": 61,
        "spx": 44,
        "int": 91,
        "atk": 21,
        "def": 40,
        "agi": 34,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47300
    },
    {
        "idnpc": 14672,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Cây màu đen",
        "level": 125,
        "hp": 2431,
        "sp": 1193,
        "hpx": 152,
        "spx": 132,
        "int": 129,
        "atk": 105,
        "def": 108,
        "agi": 143,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14703,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "MạnhVânđộngchủ",
        "level": 125,
        "hp": 1546,
        "sp": 437,
        "hpx": 60,
        "spx": 19,
        "int": 17,
        "atk": 90,
        "def": 51,
        "agi": 51,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14704,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "ChúcNinhNguyệt",
        "level": 125,
        "hp": 1399,
        "sp": 611,
        "hpx": 38,
        "spx": 45,
        "int": 92,
        "atk": 15,
        "def": 60,
        "agi": 38,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17319,
        "idmap": 12702,
        "ten_map": "DaiTruong Huong Dung",
        "thanh": "U Châu",
        "ten_npc": "Binh Quế Lâu",
        "level": 125,
        "hp": 979,
        "sp": 419,
        "hpx": 50,
        "spx": 29,
        "int": 4,
        "atk": 72,
        "def": 44,
        "agi": 48,
        "item1": 26071,
        "item2": 27083,
        "item3": 27089,
        "item4": 41032,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17461,
        "idmap": 12811,
        "ten_map": "Rung Vo Tan",
        "thanh": "U Châu",
        "ten_npc": "Tuần Sơn Binh",
        "level": 125,
        "hp": 1111,
        "sp": 457,
        "hpx": 51,
        "spx": 22,
        "int": 33,
        "atk": 52,
        "def": 69,
        "agi": 22,
        "item1": 27012,
        "item2": 27012,
        "item3": 26298,
        "item4": 38010,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17497,
        "idmap": 12817,
        "thanh": "U Châu",
        "ten_npc": "Kh Tinh Binh",
        "level": 125,
        "hp": 1078,
        "sp": 513,
        "hpx": 46,
        "spx": 30,
        "int": 25,
        "atk": 68,
        "def": 55,
        "agi": 24,
        "item1": 26252,
        "item2": 26252,
        "item3": 26298,
        "item4": 42010,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17503,
        "idmap": 12818,
        "thanh": "U Châu",
        "ten_npc": "ĐôngChâu Binh",
        "level": 125,
        "hp": 1138,
        "sp": 430,
        "hpx": 55,
        "spx": 18,
        "int": 46,
        "atk": 57,
        "def": 52,
        "agi": 21,
        "item1": 26308,
        "item2": 26308,
        "item3": 26298,
        "item4": 33028,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11045,
        "idmap": 10966,
        "ten_npc": "Tào Tương",
        "level": 126,
        "hp": 1382,
        "sp": 486,
        "hpx": 34,
        "spx": 26,
        "int": 25,
        "atk": 91,
        "def": 44,
        "agi": 62,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47037
    },
    {
        "idnpc": 11105,
        "idmap": 10994,
        "ten_map": "Va Dai Dâu Va",
        "ten_npc": "Thôi Diêm",
        "level": 126,
        "hp": 1523,
        "sp": 541,
        "hpx": 55,
        "spx": 34,
        "int": 95,
        "atk": 10,
        "def": 24,
        "agi": 74,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47141
    },
    {
        "idnpc": 12010,
        "idmap": 11034,
        "thanh": "Thanh Châu",
        "ten_npc": "Sa Ma Kha",
        "level": 126,
        "hp": 1315,
        "sp": 687,
        "hpx": 24,
        "spx": 56,
        "int": 12,
        "atk": 84,
        "def": 37,
        "agi": 75,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47204
    },
    {
        "idnpc": 12040,
        "idmap": 11132,
        "ten_map": "N.Te Tien Sinh Bac Hai",
        "thanh": "Thanh Châu",
        "ten_npc": "Ngụy Diên",
        "level": 126,
        "hp": 1435,
        "sp": 580,
        "hpx": 42,
        "spx": 40,
        "int": 19,
        "atk": 92,
        "def": 39,
        "agi": 53,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47055
    },
    {
        "idnpc": 14200,
        "idmap": 11811,
        "ten_map": "Rung Don Khuu",
        "thanh": "Thanh Châu",
        "ten_npc": "Điêu Thuyền",
        "level": 126,
        "hp": 1435,
        "sp": 540,
        "hpx": 42,
        "spx": 34,
        "int": 34,
        "atk": 80,
        "def": 40,
        "agi": 52,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 48030,
        "item5": 0,
        "item6": 47083
    },
    {
        "idnpc": 14622,
        "idmap": 12061,
        "ten_map": "Diep Thanh Thanh Mon",
        "thanh": "U Châu",
        "ten_npc": "Ma Cổ La",
        "level": 126,
        "hp": 1502,
        "sp": 526,
        "hpx": 52,
        "spx": 32,
        "int": 82,
        "atk": 60,
        "def": 23,
        "agi": 43,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14646,
        "idmap": 12063,
        "ten_map": "Hoang Cung Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Mạnh Ưu",
        "level": 126,
        "hp": 1344,
        "sp": 433,
        "hpx": 66,
        "spx": 18,
        "int": 28,
        "atk": 91,
        "def": 35,
        "agi": 50,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17396,
        "idmap": 12804,
        "ten_map": "D.Ham Phi Chi Lam",
        "thanh": "U Châu",
        "ten_npc": "ThủySưKinhSở",
        "level": 126,
        "hp": 1145,
        "sp": 500,
        "hpx": 55,
        "spx": 28,
        "int": 32,
        "atk": 61,
        "def": 53,
        "agi": 20,
        "item1": 26139,
        "item2": 26139,
        "item3": 26203,
        "item4": 50001,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17397,
        "idmap": 12804,
        "ten_map": "D.Ham Phi Chi Lam",
        "thanh": "U Châu",
        "ten_npc": "Giám Đấu Sĩ",
        "level": 126,
        "hp": 1151,
        "sp": 486,
        "hpx": 56,
        "spx": 26,
        "int": 35,
        "atk": 59,
        "def": 52,
        "agi": 21,
        "item1": 26203,
        "item2": 26203,
        "item3": 26139,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17462,
        "idmap": 12811,
        "ten_map": "Rung Vo Tan",
        "thanh": "U Châu",
        "ten_npc": "Kì SơnTrú Quân",
        "level": 126,
        "hp": 1098,
        "sp": 466,
        "hpx": 48,
        "spx": 23,
        "int": 37,
        "atk": 64,
        "def": 53,
        "agi": 25,
        "item1": 26298,
        "item2": 26298,
        "item3": 27012,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17498,
        "idmap": 12817,
        "thanh": "U Châu",
        "ten_npc": "Mãn Tướng",
        "level": 126,
        "hp": 1131,
        "sp": 439,
        "hpx": 53,
        "spx": 19,
        "int": 27,
        "atk": 74,
        "def": 49,
        "agi": 28,
        "item1": 26298,
        "item2": 26298,
        "item3": 26252,
        "item4": 29276,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 17504,
        "idmap": 12818,
        "thanh": "U Châu",
        "ten_npc": "Tam phụ quân",
        "level": 126,
        "hp": 1138,
        "sp": 453,
        "hpx": 54,
        "spx": 21,
        "int": 26,
        "atk": 58,
        "def": 67,
        "agi": 24,
        "item1": 26298,
        "item2": 26298,
        "item3": 26308,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10206,
        "idmap": 10914,
        "ten_npc": "GiápBinh KVàng",
        "level": 127,
        "hp": 1123,
        "sp": 502,
        "hpx": 51,
        "spx": 28,
        "int": 22,
        "atk": 60,
        "def": 61,
        "agi": 28,
        "item1": 27012,
        "item2": 27012,
        "item3": 27072,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12009,
        "idmap": 11033,
        "thanh": "Thanh Châu",
        "ten_npc": "Lý Nghiêm",
        "level": 127,
        "hp": 1465,
        "sp": 462,
        "hpx": 45,
        "spx": 22,
        "int": 83,
        "atk": 78,
        "def": 34,
        "agi": 31,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47162
    },
    {
        "idnpc": 12019,
        "idmap": 11041,
        "thanh": "Thanh Châu",
        "ten_npc": "Trương Bào",
        "level": 127,
        "hp": 1398,
        "sp": 549,
        "hpx": 35,
        "spx": 35,
        "int": 22,
        "atk": 95,
        "def": 40,
        "agi": 63,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47061
    },
    {
        "idnpc": 12045,
        "idmap": 11171,
        "ten_map": "N.Dien Vien Ngoai",
        "thanh": "Thanh Châu",
        "ten_npc": "Quan Hưng",
        "level": 127,
        "hp": 1385,
        "sp": 549,
        "hpx": 33,
        "spx": 35,
        "int": 34,
        "atk": 93,
        "def": 33,
        "agi": 62,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47060
    },
    {
        "idnpc": 12171,
        "idmap": 11502,
        "ten_map": "Son Dong Thai Son 2",
        "thanh": "Thanh Châu",
        "ten_npc": "T.Bảo anhdũng",
        "level": 127,
        "hp": 4945,
        "sp": 1556,
        "hpx": 185,
        "spx": 185,
        "int": 172,
        "atk": 245,
        "def": 190,
        "agi": 213,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12172,
        "idmap": 11502,
        "ten_map": "Son Dong Thai Son 2",
        "thanh": "Thanh Châu",
        "ten_npc": "QuanHưngphẫnnộ",
        "level": 127,
        "hp": 4678,
        "sp": 1556,
        "hpx": 183,
        "spx": 185,
        "int": 184,
        "atk": 243,
        "def": 183,
        "agi": 212,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14659,
        "idmap": 12063,
        "ten_map": "Hoang Cung Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Ngột đột cốt",
        "level": 127,
        "hp": 1218,
        "sp": 401,
        "hpx": 46,
        "spx": 13,
        "int": 27,
        "atk": 93,
        "def": 45,
        "agi": 57,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17457,
        "idmap": 12811,
        "ten_map": "Rung Vo Tan",
        "thanh": "U Châu",
        "ten_npc": "Kích Trận Binh",
        "level": 127,
        "hp": 1118,
        "sp": 489,
        "hpx": 50,
        "spx": 26,
        "int": 28,
        "atk": 67,
        "def": 49,
        "agi": 30,
        "item1": 27079,
        "item2": 27079,
        "item3": 32038,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17501,
        "idmap": 12818,
        "thanh": "U Châu",
        "ten_npc": "Nghiêm Hán CSĩ",
        "level": 127,
        "hp": 1131,
        "sp": 468,
        "hpx": 52,
        "spx": 23,
        "int": 25,
        "atk": 66,
        "def": 51,
        "agi": 33,
        "item1": 26106,
        "item2": 26071,
        "item3": 26071,
        "item4": 37989,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10210,
        "idmap": 10916,
        "ten_npc": "Đấu Sĩ Kh.Vàng",
        "level": 128,
        "hp": 1132,
        "sp": 498,
        "hpx": 51,
        "spx": 27,
        "int": 22,
        "atk": 64,
        "def": 69,
        "agi": 21,
        "item1": 27072,
        "item2": 27072,
        "item3": 27012,
        "item4": 50003,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11021,
        "idmap": 10965,
        "ten_npc": "Hạ Hầu Uyên",
        "level": 128,
        "hp": 1516,
        "sp": 430,
        "hpx": 51,
        "spx": 17,
        "int": 18,
        "atk": 97,
        "def": 50,
        "agi": 53,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47024
    },
    {
        "idnpc": 11188,
        "idmap": 11013,
        "ten_map": "Rung Bac Hai",
        "thanh": "Thanh Châu",
        "ten_npc": "Tông Thế Lâm",
        "level": 128,
        "hp": 710,
        "sp": 572,
        "hpx": 55,
        "spx": 38,
        "int": 93,
        "atk": 26,
        "def": 36,
        "agi": 50,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47304
    },
    {
        "idnpc": 14589,
        "idmap": 12042,
        "ten_map": "Rung Thuong Son",
        "thanh": "U Châu",
        "ten_npc": "Kha Tỉ Năng",
        "level": 128,
        "hp": 1455,
        "sp": 471,
        "hpx": 42,
        "spx": 23,
        "int": 39,
        "atk": 85,
        "def": 42,
        "agi": 63,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47314
    },
    {
        "idnpc": 14673,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Chu Tuấn",
        "level": 128,
        "hp": 2949,
        "sp": 1150,
        "hpx": 131,
        "spx": 124,
        "int": 138,
        "atk": 152,
        "def": 147,
        "agi": 145,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17458,
        "idmap": 12811,
        "ten_map": "Rung Vo Tan",
        "thanh": "U Châu",
        "ten_npc": "Luyện ChuyQuân",
        "level": 128,
        "hp": 1118,
        "sp": 457,
        "hpx": 49,
        "spx": 21,
        "int": 53,
        "atk": 64,
        "def": 46,
        "agi": 23,
        "item1": 32038,
        "item2": 32038,
        "item3": 27079,
        "item4": 40034,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17502,
        "idmap": 12818,
        "thanh": "U Châu",
        "ten_npc": "Viên Trấn Binh",
        "level": 128,
        "hp": 1132,
        "sp": 477,
        "hpx": 51,
        "spx": 24,
        "int": 22,
        "atk": 60,
        "def": 75,
        "agi": 23,
        "item1": 26071,
        "item2": 26106,
        "item3": 26106,
        "item4": 46018,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13003,
        "idmap": 11502,
        "ten_map": "Son Dong Thai Son 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Cam Ninh",
        "level": 129,
        "hp": 1620,
        "sp": 473,
        "hpx": 46,
        "spx": 23,
        "int": 21,
        "atk": 94,
        "def": 59,
        "agi": 45,
        "item1": 27017,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47065
    },
    {
        "idnpc": 13128,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Đại võ quan",
        "level": 129,
        "hp": 6358,
        "sp": 608,
        "hpx": 60,
        "spx": 43,
        "int": 41,
        "atk": 140,
        "def": 89,
        "agi": 75,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14600,
        "idmap": 12051,
        "ten_map": "Tan Tich Lac Hai",
        "thanh": "U Châu",
        "ten_npc": "Phạm Sư Mạn",
        "level": 129,
        "hp": 1417,
        "sp": 453,
        "hpx": 35,
        "spx": 20,
        "int": 35,
        "atk": 92,
        "def": 76,
        "agi": 38,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47302
    },
    {
        "idnpc": 14683,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Vệ Hạo",
        "level": 129,
        "hp": 1013,
        "sp": 416,
        "hpx": 42,
        "spx": 28,
        "int": 85,
        "atk": 86,
        "def": 13,
        "agi": 43,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17362,
        "idmap": 12724,
        "ten_map": "Nha tranh An The Thon 2",
        "thanh": "U Châu",
        "ten_npc": "Phi Tướng",
        "level": 129,
        "hp": 1142,
        "sp": 537,
        "hpx": 52,
        "spx": 32,
        "int": 47,
        "atk": 53,
        "def": 50,
        "agi": 22,
        "item1": 27082,
        "item2": 27082,
        "item3": 26239,
        "item4": 42029,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17499,
        "idmap": 12818,
        "thanh": "U Châu",
        "ten_npc": "Thiết Xoa Quân",
        "level": 129,
        "hp": 1124,
        "sp": 487,
        "hpx": 49,
        "spx": 25,
        "int": 33,
        "atk": 67,
        "def": 54,
        "agi": 28,
        "item1": 26304,
        "item2": 32027,
        "item3": 32027,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11020,
        "idmap": 10965,
        "ten_npc": "Hạ Hầu Đôn",
        "level": 130,
        "hp": 1671,
        "sp": 442,
        "hpx": 52,
        "spx": 18,
        "int": 20,
        "atk": 100,
        "def": 51,
        "agi": 49,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 48011,
        "item5": 0,
        "item6": 47023
    },
    {
        "idnpc": 11031,
        "idmap": 10966,
        "ten_npc": "Trương Hợp",
        "level": 130,
        "hp": 1624,
        "sp": 469,
        "hpx": 45,
        "spx": 22,
        "int": 23,
        "atk": 97,
        "def": 53,
        "agi": 49,
        "item1": 26034,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47033
    },
    {
        "idnpc": 13016,
        "idmap": 11503,
        "ten_map": "Son Dong Thai Son 3",
        "thanh": "Thanh Châu",
        "ten_npc": "Tôn Quyền",
        "level": 130,
        "hp": 1516,
        "sp": 590,
        "hpx": 29,
        "spx": 40,
        "int": 72,
        "atk": 54,
        "def": 45,
        "agi": 50,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 48013,
        "item5": 0,
        "item6": 47069
    },
    {
        "idnpc": 14054,
        "idmap": 11581,
        "thanh": "Thanh Châu",
        "ten_npc": "Trần Cung",
        "level": 130,
        "hp": 1523,
        "sp": 590,
        "hpx": 30,
        "spx": 40,
        "int": 91,
        "atk": 26,
        "def": 47,
        "agi": 56,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 47113
    },
    {
        "idnpc": 14583,
        "idmap": 12041,
        "ten_map": "Thon Thuong Son",
        "thanh": "U Châu",
        "ten_npc": "Bào Xuất",
        "level": 130,
        "hp": 1739,
        "sp": 475,
        "hpx": 62,
        "spx": 23,
        "int": 41,
        "atk": 94,
        "def": 56,
        "agi": 31,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47186
    },
    {
        "idnpc": 14644,
        "idmap": 12063,
        "ten_map": "Hoang Cung Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Võ tướng dự bị",
        "level": 130,
        "hp": 1274,
        "sp": 394,
        "hpx": 51,
        "spx": 11,
        "int": 37,
        "atk": 99,
        "def": 41,
        "agi": 54,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14677,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Vũ Văn Chí",
        "level": 130,
        "hp": 1286,
        "sp": 465,
        "hpx": 72,
        "spx": 35,
        "int": 93,
        "atk": 13,
        "def": 23,
        "agi": 63,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17363,
        "idmap": 12724,
        "ten_map": "Nha tranh An The Thon 2",
        "thanh": "U Châu",
        "ten_npc": "Cự Bằng Đấu Sĩ",
        "level": 130,
        "hp": 1151,
        "sp": 540,
        "hpx": 52,
        "spx": 33,
        "int": 45,
        "atk": 51,
        "def": 53,
        "agi": 23,
        "item1": 26239,
        "item2": 26239,
        "item3": 27082,
        "item4": 40034,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17463,
        "idmap": 12811,
        "ten_map": "Rung Vo Tan",
        "thanh": "U Châu",
        "ten_npc": "Đội Hắc Long",
        "level": 130,
        "hp": 1198,
        "sp": 482,
        "hpx": 59,
        "spx": 24,
        "int": 19,
        "atk": 73,
        "def": 64,
        "agi": 26,
        "item1": 27013,
        "item2": 26299,
        "item3": 27013,
        "item4": 33031,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17500,
        "idmap": 12818,
        "thanh": "U Châu",
        "ten_npc": "Tr Chinh Quân",
        "level": 130,
        "hp": 1171,
        "sp": 442,
        "hpx": 55,
        "spx": 18,
        "int": 23,
        "atk": 77,
        "def": 61,
        "agi": 23,
        "item1": 32027,
        "item2": 26304,
        "item3": 26304,
        "item4": 41029,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11026,
        "idmap": 10965,
        "ten_npc": "Tuân Úc",
        "level": 131,
        "hp": 1547,
        "sp": 606,
        "hpx": 32,
        "spx": 42,
        "int": 99,
        "atk": 22,
        "def": 45,
        "agi": 52,
        "item1": 26002,
        "item2": 27037,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47026
    },
    {
        "idnpc": 11050,
        "idmap": 10982,
        "ten_npc": "Hứa Chử",
        "level": 131,
        "hp": 1716,
        "sp": 356,
        "hpx": 57,
        "spx": 5,
        "int": 8,
        "atk": 100,
        "def": 80,
        "agi": 42,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 48016,
        "item5": 0,
        "item6": 47030
    },
    {
        "idnpc": 11077,
        "idmap": 10991,
        "ten_map": "Loi Dai Doan P",
        "ten_npc": "Bàng Đức",
        "level": 131,
        "hp": 1695,
        "sp": 525,
        "hpx": 54,
        "spx": 30,
        "int": 28,
        "atk": 97,
        "def": 56,
        "agi": 45,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47040
    },
    {
        "idnpc": 11204,
        "idmap": 11021,
        "ten_map": "Thon Phung Lai",
        "thanh": "Thanh Châu",
        "ten_npc": "BàngĐức phẫnnộ",
        "level": 131,
        "hp": 5778,
        "sp": 1202,
        "hpx": 154,
        "spx": 130,
        "int": 128,
        "atk": 197,
        "def": 155,
        "agi": 145,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14003,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Điền Phong",
        "level": 131,
        "hp": 1547,
        "sp": 563,
        "hpx": 32,
        "spx": 36,
        "int": 100,
        "atk": 14,
        "def": 56,
        "agi": 52,
        "item1": 26051,
        "item2": 26082,
        "item3": 26003,
        "item4": 0,
        "item5": 0,
        "item6": 47111
    },
    {
        "idnpc": 14598,
        "idmap": 12051,
        "ten_map": "Tan Tich Lac Hai",
        "thanh": "U Châu",
        "ten_npc": "Khu Liên",
        "level": 131,
        "hp": 1675,
        "sp": 606,
        "hpx": 51,
        "spx": 42,
        "int": 102,
        "atk": 21,
        "def": 57,
        "agi": 40,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47308
    },
    {
        "idnpc": 14645,
        "idmap": 12063,
        "ten_map": "Hoang Cung Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Võ tướng dự bị",
        "level": 131,
        "hp": 1336,
        "sp": 518,
        "hpx": 59,
        "spx": 29,
        "int": 20,
        "atk": 99,
        "def": 26,
        "agi": 74,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17464,
        "idmap": 12811,
        "ten_map": "Rung Vo Tan",
        "thanh": "U Châu",
        "ten_npc": "TrườngĐịnhQuân",
        "level": 131,
        "hp": 1151,
        "sp": 484,
        "hpx": 51,
        "spx": 24,
        "int": 32,
        "atk": 66,
        "def": 60,
        "agi": 27,
        "item1": 26299,
        "item2": 27013,
        "item3": 26299,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17505,
        "idmap": 12818,
        "thanh": "U Châu",
        "ten_npc": "Trại Vũ Đô",
        "level": 131,
        "hp": 1212,
        "sp": 471,
        "hpx": 60,
        "spx": 22,
        "int": 20,
        "atk": 57,
        "def": 81,
        "agi": 21,
        "item1": 26304,
        "item2": 26304,
        "item3": 26309,
        "item4": 40033,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17506,
        "idmap": 12818,
        "thanh": "U Châu",
        "ten_npc": "TrưởngLangQuân",
        "level": 131,
        "hp": 1144,
        "sp": 498,
        "hpx": 50,
        "spx": 26,
        "int": 28,
        "atk": 68,
        "def": 57,
        "agi": 31,
        "item1": 26309,
        "item2": 26309,
        "item3": 26304,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11024,
        "idmap": 10965,
        "ten_npc": "Từ Hoảng",
        "level": 132,
        "hp": 1698,
        "sp": 480,
        "hpx": 53,
        "spx": 23,
        "int": 27,
        "atk": 96,
        "def": 35,
        "agi": 60,
        "item1": 26109,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47032
    },
    {
        "idnpc": 11202,
        "idmap": 11021,
        "ten_map": "Thon Phung Lai",
        "thanh": "Thanh Châu",
        "ten_npc": "TừHoảng phẫnnộ",
        "level": 132,
        "hp": 5149,
        "sp": 1158,
        "hpx": 153,
        "spx": 123,
        "int": 127,
        "atk": 196,
        "def": 135,
        "agi": 160,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11208,
        "idmap": 11031,
        "thanh": "Thanh Châu",
        "ten_npc": "Từ Thương",
        "level": 132,
        "hp": 5488,
        "sp": 1002,
        "hpx": 203,
        "spx": 100,
        "int": 100,
        "atk": 186,
        "def": 140,
        "agi": 160,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11209,
        "idmap": 11031,
        "thanh": "Thanh Châu",
        "ten_npc": "Lữ Kiến",
        "level": 132,
        "hp": 5149,
        "sp": 1002,
        "hpx": 153,
        "spx": 100,
        "int": 127,
        "atk": 173,
        "def": 158,
        "agi": 183,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12012,
        "idmap": 11041,
        "thanh": "Thanh Châu",
        "ten_npc": "Pháp Chính",
        "level": 132,
        "hp": 1645,
        "sp": 575,
        "hpx": 45,
        "spx": 37,
        "int": 105,
        "atk": 33,
        "def": 66,
        "agi": 29,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47057
    },
    {
        "idnpc": 12022,
        "idmap": 11041,
        "thanh": "Thanh Châu",
        "ten_npc": "Hoàng Trung",
        "level": 132,
        "hp": 1740,
        "sp": 507,
        "hpx": 59,
        "spx": 27,
        "int": 21,
        "atk": 97,
        "def": 66,
        "agi": 42,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 48059,
        "item5": 0,
        "item6": 47054
    },
    {
        "idnpc": 14676,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Thạch Thí",
        "level": 132,
        "hp": 1170,
        "sp": 444,
        "hpx": 53,
        "spx": 31,
        "int": 12,
        "atk": 84,
        "def": 84,
        "agi": 34,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17035,
        "idmap": 12515,
        "thanh": "U Châu",
        "ten_npc": "Báo Võ Sĩ",
        "level": 132,
        "hp": 1170,
        "sp": 537,
        "hpx": 53,
        "spx": 31,
        "int": 11,
        "atk": 70,
        "def": 51,
        "agi": 44,
        "item1": 26239,
        "item2": 26242,
        "item3": 26254,
        "item4": 40035,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12027,
        "idmap": 11041,
        "thanh": "Thanh Châu",
        "ten_npc": "Triệu Vân",
        "level": 133,
        "hp": 1676,
        "sp": 482,
        "hpx": 48,
        "spx": 23,
        "int": 42,
        "atk": 98,
        "def": 48,
        "agi": 50,
        "item1": 0,
        "item2": 27019,
        "item3": 0,
        "item4": 48004,
        "item5": 0,
        "item6": 47048
    },
    {
        "idnpc": 12092,
        "idmap": 11304,
        "thanh": "Thanh Châu",
        "ten_npc": "Trương Tòng",
        "level": 133,
        "hp": 1588,
        "sp": 686,
        "hpx": 35,
        "spx": 53,
        "int": 105,
        "atk": 27,
        "def": 53,
        "agi": 42,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47157
    },
    {
        "idnpc": 14021,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Tữ Thụ",
        "level": 133,
        "hp": 1574,
        "sp": 584,
        "hpx": 33,
        "spx": 38,
        "int": 99,
        "atk": 14,
        "def": 52,
        "agi": 60,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 47091
    },
    {
        "idnpc": 14671,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Hoàng Phủ Tung",
        "level": 133,
        "hp": 2407,
        "sp": 1114,
        "hpx": 136,
        "spx": 116,
        "int": 126,
        "atk": 182,
        "def": 140,
        "agi": 142,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14695,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Thiết Mộc Lăng",
        "level": 133,
        "hp": 4504,
        "sp": 448,
        "hpx": 53,
        "spx": 32,
        "int": 14,
        "atk": 124,
        "def": 74,
        "agi": 84,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15277,
        "idmap": 12241,
        "ten_map": "Nha Tro Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Lộ Cạt",
        "level": 133,
        "hp": 1540,
        "sp": 557,
        "hpx": 28,
        "spx": 34,
        "int": 82,
        "atk": 90,
        "def": 34,
        "agi": 46,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47127
    },
    {
        "idnpc": 17036,
        "idmap": 12515,
        "thanh": "U Châu",
        "ten_npc": "Hổ Vệ Binh",
        "level": 133,
        "hp": 1179,
        "sp": 540,
        "hpx": 53,
        "spx": 32,
        "int": 10,
        "atk": 70,
        "def": 54,
        "agi": 42,
        "item1": 26254,
        "item2": 26255,
        "item3": 26239,
        "item4": 37970,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17507,
        "idmap": 12818,
        "thanh": "U Châu",
        "ten_npc": "ThủQuan Sĩ Tốt",
        "level": 133,
        "hp": 1192,
        "sp": 503,
        "hpx": 55,
        "spx": 26,
        "int": 20,
        "atk": 76,
        "def": 62,
        "agi": 23,
        "item1": 26309,
        "item2": 26309,
        "item3": 32027,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11034,
        "idmap": 10966,
        "ten_npc": "Trương Liêu",
        "level": 134,
        "hp": 1673,
        "sp": 409,
        "hpx": 46,
        "spx": 23,
        "int": 32,
        "atk": 99,
        "def": 51,
        "agi": 48,
        "item1": 26041,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47031
    },
    {
        "idnpc": 11049,
        "idmap": 10982,
        "ten_npc": "Tào Thống",
        "level": 134,
        "hp": 1482,
        "sp": 566,
        "hpx": 18,
        "spx": 35,
        "int": 114,
        "atk": 17,
        "def": 41,
        "agi": 75,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47156
    },
    {
        "idnpc": 11063,
        "idmap": 10984,
        "ten_npc": "Dương Tu",
        "level": 134,
        "hp": 1578,
        "sp": 607,
        "hpx": 32,
        "spx": 41,
        "int": 107,
        "atk": 21,
        "def": 45,
        "agi": 55,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 48061,
        "item5": 0,
        "item6": 47155
    },
    {
        "idnpc": 12017,
        "idmap": 11041,
        "thanh": "Thanh Châu",
        "ten_npc": "Mã Siêu",
        "level": 134,
        "hp": 1625,
        "sp": 682,
        "hpx": 39,
        "spx": 52,
        "int": 21,
        "atk": 98,
        "def": 45,
        "agi": 61,
        "item1": 0,
        "item2": 26158,
        "item3": 0,
        "item4": 48029,
        "item5": 0,
        "item6": 47056
    },
    {
        "idnpc": 12020,
        "idmap": 11041,
        "thanh": "Thanh Châu",
        "ten_npc": "Trương Phi",
        "level": 134,
        "hp": 1725,
        "sp": 396,
        "hpx": 54,
        "spx": 10,
        "int": 17,
        "atk": 101,
        "def": 56,
        "agi": 60,
        "item1": 0,
        "item2": 26036,
        "item3": 0,
        "item4": 48057,
        "item5": 0,
        "item6": 47049
    },
    {
        "idnpc": 12166,
        "idmap": 11502,
        "ten_map": "Son Dong Thai Son 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Trương Phi",
        "level": 134,
        "hp": 1725,
        "sp": 396,
        "hpx": 54,
        "spx": 10,
        "int": 17,
        "atk": 101,
        "def": 56,
        "agi": 60,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13002,
        "idmap": 11502,
        "ten_map": "Son Dong Thai Son 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Thái Sử Từ",
        "level": 134,
        "hp": 1646,
        "sp": 491,
        "hpx": 42,
        "spx": 24,
        "int": 32,
        "atk": 96,
        "def": 42,
        "agi": 62,
        "item1": 27016,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47064
    },
    {
        "idnpc": 17406,
        "idmap": 12805,
        "ten_map": "Tan Cung Phi Chi Lam",
        "thanh": "U Châu",
        "ten_npc": "Trấn Nam Binh",
        "level": 134,
        "hp": 1212,
        "sp": 512,
        "hpx": 57,
        "spx": 27,
        "int": 8,
        "atk": 60,
        "def": 78,
        "agi": 36,
        "item1": 26253,
        "item2": 26253,
        "item3": 26239,
        "item4": 46018,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17465,
        "idmap": 12811,
        "ten_map": "Rung Vo Tan",
        "thanh": "U Châu",
        "ten_npc": "Đội Thiết Vĩ",
        "level": 134,
        "hp": 1185,
        "sp": 512,
        "hpx": 53,
        "spx": 27,
        "int": 25,
        "atk": 65,
        "def": 75,
        "agi": 22,
        "item1": 26299,
        "item2": 26299,
        "item3": 26254,
        "item4": 37973,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11018,
        "idmap": 10965,
        "ten_npc": "Điển Vi",
        "level": 135,
        "hp": 1738,
        "sp": 425,
        "hpx": 54,
        "spx": 14,
        "int": 17,
        "atk": 100,
        "def": 70,
        "agi": 45,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 48015,
        "item5": 0,
        "item6": 47022
    },
    {
        "idnpc": 11053,
        "idmap": 10982,
        "ten_npc": "Quách Gia",
        "level": 135,
        "hp": 1602,
        "sp": 582,
        "hpx": 34,
        "spx": 37,
        "int": 108,
        "atk": 20,
        "def": 48,
        "agi": 53,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47028
    },
    {
        "idnpc": 12043,
        "idmap": 11171,
        "ten_map": "N.Dien Vien Ngoai",
        "thanh": "Thanh Châu",
        "ten_npc": "Quan Vũ",
        "level": 135,
        "hp": 1643,
        "sp": 487,
        "hpx": 40,
        "spx": 23,
        "int": 40,
        "atk": 100,
        "def": 49,
        "agi": 48,
        "item1": 26157,
        "item2": 0,
        "item3": 0,
        "item4": 48058,
        "item5": 0,
        "item6": 47050
    },
    {
        "idnpc": 12165,
        "idmap": 11502,
        "ten_map": "Son Dong Thai Son 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Quan Vũ",
        "level": 135,
        "hp": 1643,
        "sp": 487,
        "hpx": 40,
        "spx": 23,
        "int": 40,
        "atk": 100,
        "def": 49,
        "agi": 48,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13009,
        "idmap": 11502,
        "ten_map": "Son Dong Thai Son 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Chu Du",
        "level": 135,
        "hp": 1615,
        "sp": 499,
        "hpx": 36,
        "spx": 54,
        "int": 109,
        "atk": 42,
        "def": 38,
        "agi": 51,
        "item1": 27035,
        "item2": 0,
        "item3": 0,
        "item4": 48009,
        "item5": 0,
        "item6": 47070
    },
    {
        "idnpc": 13014,
        "idmap": 11503,
        "ten_map": "Son Dong Thai Son 3",
        "thanh": "Thanh Châu",
        "ten_npc": "Tôn Sách",
        "level": 135,
        "hp": 1670,
        "sp": 471,
        "hpx": 44,
        "spx": 21,
        "int": 54,
        "atk": 91,
        "def": 40,
        "agi": 50,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47068
    },
    {
        "idnpc": 14614,
        "idmap": 12061,
        "ten_map": "Diep Thanh Thanh Mon",
        "thanh": "U Châu",
        "ten_npc": "Tăng Già Bà La",
        "level": 135,
        "hp": 1656,
        "sp": 548,
        "hpx": 42,
        "spx": 32,
        "int": 112,
        "atk": 24,
        "def": 44,
        "agi": 66,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17350,
        "idmap": 12722,
        "ten_map": "Rung An The Thon",
        "thanh": "U Châu",
        "ten_npc": "Quân Tiền Bộ",
        "level": 135,
        "hp": 1198,
        "sp": 549,
        "hpx": 54,
        "spx": 32,
        "int": 12,
        "atk": 78,
        "def": 49,
        "agi": 41,
        "item1": 27081,
        "item2": 27090,
        "item3": 27081,
        "item4": 35022,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17407,
        "idmap": 12805,
        "ten_map": "Tan Cung Phi Chi Lam",
        "thanh": "U Châu",
        "ten_npc": "Đồng Tùy Quân",
        "level": 135,
        "hp": 1219,
        "sp": 466,
        "hpx": 57,
        "spx": 20,
        "int": 9,
        "atk": 66,
        "def": 80,
        "agi": 34,
        "item1": 26239,
        "item2": 26239,
        "item3": 26253,
        "item4": 33029,
        "item5": 50004,
        "item6": 0
    },
    {
        "idnpc": 17466,
        "idmap": 12811,
        "ten_map": "Rung Vo Tan",
        "thanh": "U Châu",
        "ten_npc": "UngLương Binh",
        "level": 135,
        "hp": 1198,
        "sp": 460,
        "hpx": 54,
        "spx": 19,
        "int": 46,
        "atk": 63,
        "def": 62,
        "agi": 24,
        "item1": 26254,
        "item2": 26254,
        "item3": 26299,
        "item4": 0,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 17508,
        "idmap": 12818,
        "thanh": "U Châu",
        "ten_npc": "TrưởngTúc Quân",
        "level": 135,
        "hp": 1191,
        "sp": 500,
        "hpx": 53,
        "spx": 25,
        "int": 36,
        "atk": 74,
        "def": 54,
        "agi": 25,
        "item1": 32027,
        "item2": 32027,
        "item3": 26309,
        "item4": 36023,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17351,
        "idmap": 12722,
        "ten_map": "Rung An The Thon",
        "thanh": "U Châu",
        "ten_npc": "Phục Binh",
        "level": 136,
        "hp": 1208,
        "sp": 552,
        "hpx": 54,
        "spx": 32,
        "int": 10,
        "atk": 80,
        "def": 49,
        "agi": 41,
        "item1": 32011,
        "item2": 26171,
        "item3": 32011,
        "item4": 36019,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13012,
        "idmap": 11502,
        "ten_map": "Son Dong Thai Son 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Tôn Kiên",
        "level": 137,
        "hp": 1698,
        "sp": 539,
        "hpx": 45,
        "spx": 30,
        "int": 48,
        "atk": 82,
        "def": 49,
        "agi": 50,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47140
    },
    {
        "idnpc": 17039,
        "idmap": 12516,
        "thanh": "U Châu",
        "ten_npc": "Hổ Võ Sĩ",
        "level": 137,
        "hp": 1217,
        "sp": 558,
        "hpx": 55,
        "spx": 33,
        "int": 15,
        "atk": 76,
        "def": 45,
        "agi": 48,
        "item1": 26253,
        "item2": 26256,
        "item3": 26253,
        "item4": 38009,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17467,
        "idmap": 12815,
        "ten_map": "Tuyet Dia Bac Phong 1",
        "thanh": "U Châu",
        "ten_npc": "Quân TiềnPhong",
        "level": 137,
        "hp": 1217,
        "sp": 409,
        "hpx": 55,
        "spx": 25,
        "int": 33,
        "atk": 79,
        "def": 59,
        "agi": 22,
        "item1": 26072,
        "item2": 26253,
        "item3": 26253,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17468,
        "idmap": 12815,
        "ten_map": "Tuyet Dia Bac Phong 1",
        "thanh": "U Châu",
        "ten_npc": "Binh Hổ Thân",
        "level": 137,
        "hp": 1205,
        "sp": 395,
        "hpx": 53,
        "spx": 23,
        "int": 53,
        "atk": 72,
        "def": 49,
        "agi": 23,
        "item1": 26253,
        "item2": 26072,
        "item3": 26072,
        "item4": 37971,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15322,
        "idmap": 12301,
        "ten_map": "Dai Dien Chu Hau",
        "thanh": "U Châu",
        "ten_npc": "Ty Cẩu",
        "level": 138,
        "hp": 1743,
        "sp": 453,
        "hpx": 50,
        "spx": 17,
        "int": 22,
        "atk": 81,
        "def": 51,
        "agi": 82,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47174
    },
    {
        "idnpc": 17509,
        "idmap": 12818,
        "thanh": "U Châu",
        "ten_npc": "Thư Kích Thủ",
        "level": 138,
        "hp": 1198,
        "sp": 549,
        "hpx": 51,
        "spx": 31,
        "int": 21,
        "atk": 83,
        "def": 54,
        "agi": 32,
        "item1": 26310,
        "item2": 26310,
        "item3": 26300,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14678,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Lưu Tử Bân",
        "level": 139,
        "hp": 1305,
        "sp": 479,
        "hpx": 56,
        "spx": 35,
        "int": 46,
        "atk": 106,
        "def": 16,
        "agi": 66,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17040,
        "idmap": 12517,
        "thanh": "U Châu",
        "ten_npc": "Hổ Dũng Sĩ",
        "level": 139,
        "hp": 1236,
        "sp": 565,
        "hpx": 56,
        "spx": 33,
        "int": 12,
        "atk": 76,
        "def": 46,
        "agi": 50,
        "item1": 26239,
        "item2": 26242,
        "item3": 26242,
        "item4": 37973,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17469,
        "idmap": 12815,
        "ten_map": "Tuyet Dia Bac Phong 1",
        "thanh": "U Châu",
        "ten_npc": "Binh bân lang",
        "level": 139,
        "hp": 1225,
        "sp": 475,
        "hpx": 54,
        "spx": 20,
        "int": 57,
        "atk": 71,
        "def": 51,
        "agi": 22,
        "item1": 26255,
        "item2": 26255,
        "item3": 26300,
        "item4": 34035,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17511,
        "idmap": 12818,
        "thanh": "U Châu",
        "ten_npc": "LongTương Binh",
        "level": 139,
        "hp": 1225,
        "sp": 468,
        "hpx": 54,
        "spx": 19,
        "int": 63,
        "atk": 48,
        "def": 73,
        "agi": 18,
        "item1": 27053,
        "item2": 27053,
        "item3": 27053,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17404,
        "idmap": 12805,
        "ten_map": "Tan Cung Phi Chi Lam",
        "thanh": "U Châu",
        "ten_npc": "Ninh Viên Binh",
        "level": 140,
        "hp": 1427,
        "sp": 526,
        "hpx": 62,
        "spx": 27,
        "int": 10,
        "atk": 57,
        "def": 82,
        "agi": 39,
        "item1": 26108,
        "item2": 26108,
        "item3": 26242,
        "item4": 50001,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17418,
        "idmap": 12806,
        "ten_map": "Rung Di Huyen",
        "thanh": "U Châu",
        "ten_npc": "Q.Phản Giang",
        "level": 140,
        "hp": 1420,
        "sp": 400,
        "hpx": 61,
        "spx": 23,
        "int": 44,
        "atk": 65,
        "def": 74,
        "agi": 12,
        "item1": 26206,
        "item2": 26206,
        "item3": 26143,
        "item4": 42031,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17470,
        "idmap": 12815,
        "ten_map": "Tuyet Dia Bac Phong 1",
        "thanh": "U Châu",
        "ten_npc": "Trưởng binh",
        "level": 140,
        "hp": 1420,
        "sp": 484,
        "hpx": 61,
        "spx": 21,
        "int": 25,
        "atk": 78,
        "def": 69,
        "agi": 25,
        "item1": 26300,
        "item2": 26300,
        "item3": 26255,
        "item4": 0,
        "item5": 50004,
        "item6": 0
    },
    {
        "idnpc": 17510,
        "idmap": 12818,
        "thanh": "U Châu",
        "ten_npc": "Lạc Thành Quân",
        "level": 140,
        "hp": 1413,
        "sp": 512,
        "hpx": 60,
        "spx": 25,
        "int": 30,
        "atk": 76,
        "def": 62,
        "agi": 26,
        "item1": 26300,
        "item2": 26300,
        "item3": 26310,
        "item4": 42027,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17512,
        "idmap": 12818,
        "thanh": "U Châu",
        "ten_npc": "Ưng Dương Quân",
        "level": 140,
        "hp": 1413,
        "sp": 478,
        "hpx": 60,
        "spx": 20,
        "int": 43,
        "atk": 74,
        "def": 60,
        "agi": 22,
        "item1": 27015,
        "item2": 27015,
        "item3": 27015,
        "item4": 35021,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11167,
        "idmap": 11011,
        "ten_map": "Bac Hai Thanh Mon",
        "thanh": "Thanh Châu",
        "ten_npc": "Quản Lộ",
        "level": 141,
        "hp": 2084,
        "sp": 645,
        "hpx": 54,
        "spx": 44,
        "int": 108,
        "atk": 26,
        "def": 42,
        "agi": 58,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47352
    },
    {
        "idnpc": 17352,
        "idmap": 12722,
        "ten_map": "Rung An The Thon",
        "thanh": "U Châu",
        "ten_npc": "Túc Vệ Binh",
        "level": 141,
        "hp": 1396,
        "sp": 572,
        "hpx": 56,
        "spx": 33,
        "int": 9,
        "atk": 84,
        "def": 52,
        "agi": 43,
        "item1": 26241,
        "item2": 26171,
        "item3": 26241,
        "item4": 42030,
        "item5": 34029,
        "item6": 0
    },
    {
        "idnpc": 17405,
        "idmap": 12805,
        "ten_map": "Tan Cung Phi Chi Lam",
        "thanh": "U Châu",
        "ten_npc": "Đội Đặc Kích",
        "level": 141,
        "hp": 1421,
        "sp": 542,
        "hpx": 60,
        "spx": 29,
        "int": 9,
        "atk": 60,
        "def": 84,
        "agi": 36,
        "item1": 26242,
        "item2": 26242,
        "item3": 26108,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17475,
        "idmap": 12815,
        "ten_map": "Tuyet Dia Bac Phong 1",
        "thanh": "U Châu",
        "ten_npc": "KhươngTrảm Đao",
        "level": 141,
        "hp": 1421,
        "sp": 514,
        "hpx": 60,
        "spx": 25,
        "int": 26,
        "atk": 68,
        "def": 78,
        "agi": 22,
        "item1": 26255,
        "item2": 32040,
        "item3": 26255,
        "item4": 0,
        "item5": 50004,
        "item6": 0
    },
    {
        "idnpc": 17353,
        "idmap": 12722,
        "ten_map": "Rung An The Thon",
        "thanh": "U Châu",
        "ten_npc": "Hổ Bàn Binh",
        "level": 142,
        "hp": 1406,
        "sp": 576,
        "hpx": 57,
        "spx": 34,
        "int": 9,
        "atk": 85,
        "def": 52,
        "agi": 42,
        "item1": 27074,
        "item2": 27090,
        "item3": 27074,
        "item4": 43086,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17408,
        "idmap": 12805,
        "ten_map": "Tan Cung Phi Chi Lam",
        "thanh": "U Châu",
        "ten_npc": "Chu Đôn Binh",
        "level": 142,
        "hp": 1435,
        "sp": 551,
        "hpx": 61,
        "spx": 30,
        "int": 8,
        "atk": 86,
        "def": 52,
        "agi": 42,
        "item1": 26256,
        "item2": 27120,
        "item3": 26256,
        "item4": 34028,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17419,
        "idmap": 12806,
        "ten_map": "Rung Di Huyen",
        "thanh": "U Châu",
        "ten_npc": "Cự Giam Binh",
        "level": 142,
        "hp": 1463,
        "sp": 390,
        "hpx": 65,
        "spx": 21,
        "int": 45,
        "atk": 66,
        "def": 73,
        "agi": 11,
        "item1": 26143,
        "item2": 26143,
        "item3": 26206,
        "item4": 35030,
        "item5": 50004,
        "item6": 0
    },
    {
        "idnpc": 17471,
        "idmap": 12815,
        "ten_map": "Tuyet Dia Bac Phong 1",
        "thanh": "U Châu",
        "ten_npc": "Sơn Đấu Sĩ",
        "level": 142,
        "hp": 1428,
        "sp": 544,
        "hpx": 60,
        "spx": 29,
        "int": 25,
        "atk": 73,
        "def": 66,
        "agi": 28,
        "item1": 27015,
        "item2": 27015,
        "item3": 27053,
        "item4": 37986,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17476,
        "idmap": 12815,
        "ten_map": "Tuyet Dia Bac Phong 1",
        "thanh": "U Châu",
        "ten_npc": "QuỷKhươngTướng",
        "level": 142,
        "hp": 1463,
        "sp": 510,
        "hpx": 65,
        "spx": 24,
        "int": 32,
        "atk": 80,
        "def": 57,
        "agi": 23,
        "item1": 32040,
        "item2": 26255,
        "item3": 32040,
        "item4": 36021,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14696,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Thiết Mộc Lăng",
        "level": 143,
        "hp": 5564,
        "sp": 482,
        "hpx": 57,
        "spx": 34,
        "int": 14,
        "atk": 134,
        "def": 84,
        "agi": 94,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17043,
        "idmap": 12519,
        "thanh": "U Châu",
        "ten_npc": "Môn Hạ Đốc",
        "level": 143,
        "hp": 1417,
        "sp": 580,
        "hpx": 57,
        "spx": 34,
        "int": 10,
        "atk": 83,
        "def": 54,
        "agi": 45,
        "item1": 26140,
        "item2": 26144,
        "item3": 26140,
        "item4": 37975,
        "item5": 50004,
        "item6": 0
    },
    {
        "idnpc": 17409,
        "idmap": 12805,
        "ten_map": "Tan Cung Phi Chi Lam",
        "thanh": "U Châu",
        "ten_npc": "Tiểu Úy",
        "level": 143,
        "hp": 1450,
        "sp": 547,
        "hpx": 62,
        "spx": 29,
        "int": 9,
        "atk": 88,
        "def": 53,
        "agi": 44,
        "item1": 27120,
        "item2": 26256,
        "item3": 27120,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17472,
        "idmap": 12815,
        "ten_map": "Tuyet Dia Bac Phong 1",
        "thanh": "U Châu",
        "ten_npc": "Nhô Tiến Quân",
        "level": 143,
        "hp": 1443,
        "sp": 540,
        "hpx": 61,
        "spx": 28,
        "int": 30,
        "atk": 78,
        "def": 64,
        "agi": 24,
        "item1": 27053,
        "item2": 27053,
        "item3": 27015,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17044,
        "idmap": 12519,
        "thanh": "U Châu",
        "ten_npc": "Tướng Bộ Khúc",
        "level": 144,
        "hp": 1427,
        "sp": 584,
        "hpx": 58,
        "spx": 34,
        "int": 8,
        "atk": 86,
        "def": 52,
        "agi": 46,
        "item1": 26204,
        "item2": 26206,
        "item3": 26204,
        "item4": 35019,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17513,
        "idmap": 12818,
        "thanh": "U Châu",
        "ten_npc": "Tử Binh Binh",
        "level": 144,
        "hp": 1444,
        "sp": 514,
        "hpx": 60,
        "spx": 24,
        "int": 48,
        "atk": 77,
        "def": 57,
        "agi": 20,
        "item1": 27120,
        "item2": 27120,
        "item3": 26305,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11074,
        "idmap": 10989,
        "ten_map": "Van Thanh Me Cung 3",
        "ten_npc": "Ân Phục",
        "level": 145,
        "hp": 1879,
        "sp": 697,
        "hpx": 38,
        "spx": 50,
        "int": 100,
        "atk": 49,
        "def": 28,
        "agi": 74,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 48032,
        "item5": 0,
        "item6": 47043
    },
    {
        "idnpc": 14585,
        "idmap": 12042,
        "ten_map": "Rung Thuong Son",
        "thanh": "U Châu",
        "ten_npc": "MộngCưSĩ",
        "level": 145,
        "hp": 2163,
        "sp": 558,
        "hpx": 58,
        "spx": 30,
        "int": 113,
        "atk": 34,
        "def": 46,
        "agi": 58,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47283
    },
    {
        "idnpc": 17010,
        "idmap": 12512,
        "thanh": "U Châu",
        "ten_npc": "Thủ Thành Binh",
        "level": 145,
        "hp": 1438,
        "sp": 589,
        "hpx": 58,
        "spx": 34,
        "int": 12,
        "atk": 80,
        "def": 53,
        "agi": 47,
        "item1": 26107,
        "item2": 26108,
        "item3": 26072,
        "item4": 42026,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17414,
        "idmap": 12806,
        "ten_map": "Rung Di Huyen",
        "thanh": "U Châu",
        "ten_npc": "Linh Lăng Binh",
        "level": 145,
        "hp": 1465,
        "sp": 558,
        "hpx": 62,
        "spx": 30,
        "int": 8,
        "atk": 65,
        "def": 88,
        "agi": 31,
        "item1": 32011,
        "item2": 32011,
        "item3": 26271,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17473,
        "idmap": 12815,
        "ten_map": "Tuyet Dia Bac Phong 1",
        "thanh": "U Châu",
        "ten_npc": "Quân Dẫn Hỏa",
        "level": 145,
        "hp": 1438,
        "sp": 503,
        "hpx": 58,
        "spx": 22,
        "int": 62,
        "atk": 72,
        "def": 48,
        "agi": 22,
        "item1": 27120,
        "item2": 26108,
        "item3": 26108,
        "item4": 41035,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17514,
        "idmap": 12818,
        "thanh": "U Châu",
        "ten_npc": "Hãn Đấu Sĩ",
        "level": 145,
        "hp": 1410,
        "sp": 558,
        "hpx": 54,
        "spx": 30,
        "int": 33,
        "atk": 74,
        "def": 68,
        "agi": 26,
        "item1": 26305,
        "item2": 26305,
        "item3": 27120,
        "item4": 0,
        "item5": 50004,
        "item6": 0
    },
    {
        "idnpc": 10214,
        "idmap": 10917,
        "ten_npc": "Người Đào Mộ",
        "level": 146,
        "hp": 1459,
        "sp": 561,
        "hpx": 60,
        "spx": 30,
        "int": 38,
        "atk": 50,
        "def": 76,
        "agi": 36,
        "item1": 27015,
        "item2": 27015,
        "item3": 27015,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14488,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Phổ Tịnh",
        "level": 146,
        "hp": 2178,
        "sp": 628,
        "hpx": 58,
        "spx": 40,
        "int": 112,
        "atk": 36,
        "def": 73,
        "agi": 25,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47184
    },
    {
        "idnpc": 14675,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Thiết mộc gia",
        "level": 146,
        "hp": 1355,
        "sp": 495,
        "hpx": 66,
        "spx": 35,
        "int": 24,
        "atk": 109,
        "def": 34,
        "agi": 71,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17033,
        "idmap": 12515,
        "thanh": "U Châu",
        "ten_npc": "Báo Quân Vệ",
        "level": 146,
        "hp": 1448,
        "sp": 593,
        "hpx": 58,
        "spx": 35,
        "int": 13,
        "atk": 83,
        "def": 55,
        "agi": 45,
        "item1": 26072,
        "item2": 26073,
        "item3": 26107,
        "item4": 34028,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17415,
        "idmap": 12806,
        "ten_map": "Rung Di Huyen",
        "thanh": "U Châu",
        "ten_npc": "Tương Hương Vệ",
        "level": 146,
        "hp": 1487,
        "sp": 547,
        "hpx": 64,
        "spx": 28,
        "int": 10,
        "atk": 68,
        "def": 87,
        "agi": 32,
        "item1": 26271,
        "item2": 26271,
        "item3": 32011,
        "item4": 34029,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17474,
        "idmap": 12815,
        "ten_map": "Tuyet Dia Bac Phong 1",
        "thanh": "U Châu",
        "ten_npc": "Tiệt Kích Quân",
        "level": 146,
        "hp": 1448,
        "sp": 498,
        "hpx": 58,
        "spx": 21,
        "int": 48,
        "atk": 72,
        "def": 67,
        "agi": 25,
        "item1": 26108,
        "item2": 27120,
        "item3": 27120,
        "item4": 36031,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 17477,
        "idmap": 12815,
        "ten_map": "Tuyet Dia Bac Phong 1",
        "thanh": "U Châu",
        "ten_npc": "MaoKhương Binh",
        "level": 146,
        "hp": 1466,
        "sp": 568,
        "hpx": 61,
        "spx": 31,
        "int": 25,
        "atk": 83,
        "def": 69,
        "agi": 22,
        "item1": 32040,
        "item2": 32040,
        "item3": 26015,
        "item4": 37987,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10142,
        "idmap": 10859,
        "ten_npc": "Binh Sĩ Dã Hổ",
        "level": 147,
        "hp": 1474,
        "sp": 584,
        "hpx": 61,
        "spx": 33,
        "int": 11,
        "atk": 82,
        "def": 61,
        "agi": 42,
        "item1": 27074,
        "item2": 27074,
        "item3": 27074,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14615,
        "idmap": 12061,
        "ten_map": "Diep Thanh Thanh Mon",
        "thanh": "U Châu",
        "ten_npc": "Mạn Đà La",
        "level": 147,
        "hp": 2104,
        "sp": 737,
        "hpx": 46,
        "spx": 55,
        "int": 120,
        "atk": 7,
        "def": 46,
        "agi": 71,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47360
    },
    {
        "idnpc": 17478,
        "idmap": 12815,
        "ten_map": "Tuyet Dia Bac Phong 1",
        "thanh": "U Châu",
        "ten_npc": "PhiKhươngTướng",
        "level": 147,
        "hp": 1474,
        "sp": 528,
        "hpx": 61,
        "spx": 25,
        "int": 31,
        "atk": 86,
        "def": 67,
        "agi": 22,
        "item1": 26015,
        "item2": 26015,
        "item3": 32040,
        "item4": 42035,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10145,
        "idmap": 10861,
        "ten_npc": "Địa Sư Quân",
        "level": 148,
        "hp": 1475,
        "sp": 579,
        "hpx": 60,
        "spx": 32,
        "int": 74,
        "atk": 46,
        "def": 60,
        "agi": 21,
        "item1": 27053,
        "item2": 27053,
        "item3": 27053,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17045,
        "idmap": 12519,
        "thanh": "U Châu",
        "ten_npc": "Bắc Quân Hiệu",
        "level": 148,
        "hp": 1469,
        "sp": 601,
        "hpx": 59,
        "spx": 35,
        "int": 11,
        "atk": 87,
        "def": 53,
        "agi": 45,
        "item1": 26205,
        "item2": 26208,
        "item3": 26205,
        "item4": 35020,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17048,
        "idmap": 12519,
        "thanh": "U Châu",
        "ten_npc": "Tinh Duệ Binh",
        "level": 148,
        "hp": 1469,
        "sp": 600,
        "hpx": 59,
        "spx": 35,
        "int": 9,
        "atk": 85,
        "def": 55,
        "agi": 47,
        "item1": 26142,
        "item2": 26141,
        "item3": 26142,
        "item4": 41030,
        "item5": 50004,
        "item6": 0
    },
    {
        "idnpc": 17410,
        "idmap": 12806,
        "ten_map": "Rung Di Huyen",
        "thanh": "U Châu",
        "ten_npc": "Nam Dũng Sĩ",
        "level": 148,
        "hp": 1495,
        "sp": 572,
        "hpx": 63,
        "spx": 31,
        "int": 12,
        "atk": 68,
        "def": 86,
        "agi": 30,
        "item1": 26272,
        "item2": 26272,
        "item3": 32028,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17515,
        "idmap": 12818,
        "thanh": "U Châu",
        "ten_npc": "Binh Bá Quận",
        "level": 148,
        "hp": 1475,
        "sp": 468,
        "hpx": 60,
        "spx": 16,
        "int": 44,
        "atk": 76,
        "def": 69,
        "agi": 27,
        "item1": 26271,
        "item2": 26271,
        "item3": 27135,
        "item4": 35026,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17354,
        "idmap": 12722,
        "ten_map": "Rung An The Thon",
        "thanh": "U Châu",
        "ten_npc": "Vũ Lâm Sư",
        "level": 149,
        "hp": 1479,
        "sp": 604,
        "hpx": 60,
        "spx": 35,
        "int": 10,
        "atk": 90,
        "def": 59,
        "agi": 41,
        "item1": 26255,
        "item2": 26255,
        "item3": 27090,
        "item4": 40032,
        "item5": 40036,
        "item6": 0
    },
    {
        "idnpc": 17411,
        "idmap": 12806,
        "ten_map": "Rung Di Huyen",
        "thanh": "U Châu",
        "ten_npc": "Khoai Đao Thủ",
        "level": 149,
        "hp": 1510,
        "sp": 568,
        "hpx": 64,
        "spx": 30,
        "int": 11,
        "atk": 69,
        "def": 90,
        "agi": 31,
        "item1": 32028,
        "item2": 32028,
        "item3": 26272,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17516,
        "idmap": 12818,
        "thanh": "U Châu",
        "ten_npc": "Sí Viêm Quân",
        "level": 149,
        "hp": 1468,
        "sp": 505,
        "hpx": 58,
        "spx": 21,
        "int": 45,
        "atk": 68,
        "def": 82,
        "agi": 23,
        "item1": 27135,
        "item2": 27135,
        "item3": 26271,
        "item4": 0,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 17519,
        "idmap": 12818,
        "thanh": "U Châu",
        "ten_npc": "Cấm Quân",
        "level": 149,
        "hp": 1503,
        "sp": 484,
        "hpx": 63,
        "spx": 18,
        "int": 24,
        "atk": 88,
        "def": 62,
        "agi": 22,
        "item1": 26256,
        "item2": 26256,
        "item3": 26241,
        "item4": 41031,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11047,
        "idmap": 10966,
        "ten_npc": "Tào Tháo",
        "level": 150,
        "hp": 2320,
        "sp": 451,
        "hpx": 50,
        "spx": 13,
        "int": 109,
        "atk": 42,
        "def": 37,
        "agi": 79,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 48038,
        "item5": 0,
        "item6": 47027
    },
    {
        "idnpc": 13146,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Đinh Phụng",
        "level": 150,
        "hp": 5090,
        "sp": 437,
        "hpx": 60,
        "spx": 11,
        "int": 20,
        "atk": 150,
        "def": 25,
        "agi": 150,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14009,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Lữ Bố",
        "level": 150,
        "hp": 2320,
        "sp": 451,
        "hpx": 50,
        "spx": 13,
        "int": 16,
        "atk": 105,
        "def": 70,
        "agi": 76,
        "item1": 26051,
        "item2": 26082,
        "item3": 26003,
        "item4": 48014,
        "item5": 0,
        "item6": 47082
    },
    {
        "idnpc": 14066,
        "idmap": 11581,
        "thanh": "Thanh Châu",
        "ten_npc": "Đổng Trác",
        "level": 150,
        "hp": 2362,
        "sp": 500,
        "hpx": 56,
        "spx": 20,
        "int": 30,
        "atk": 62,
        "def": 90,
        "agi": 72,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 48031,
        "item5": 0,
        "item6": 47084
    },
    {
        "idnpc": 14283,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Lữ Bố",
        "level": 150,
        "hp": 2313,
        "sp": 542,
        "hpx": 49,
        "spx": 26,
        "int": 21,
        "atk": 84,
        "def": 80,
        "agi": 70,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 14284,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Lữ Bố",
        "level": 150,
        "hp": 2320,
        "sp": 549,
        "hpx": 50,
        "spx": 27,
        "int": 45,
        "atk": 66,
        "def": 70,
        "agi": 72,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 14285,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Lữ Bố",
        "level": 150,
        "hp": 2334,
        "sp": 528,
        "hpx": 52,
        "spx": 24,
        "int": 20,
        "atk": 82,
        "def": 66,
        "agi": 86,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 17355,
        "idmap": 12722,
        "ten_map": "Rung An The Thon",
        "thanh": "U Châu",
        "ten_npc": "Quân Ngự Doanh",
        "level": 150,
        "hp": 1490,
        "sp": 608,
        "hpx": 60,
        "spx": 35,
        "int": 9,
        "atk": 91,
        "def": 58,
        "agi": 42,
        "item1": 26241,
        "item2": 26241,
        "item3": 26171,
        "item4": 41033,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17392,
        "idmap": 12804,
        "ten_map": "D.Ham Phi Chi Lam",
        "thanh": "U Châu",
        "ten_npc": "Chu Xấp Binh",
        "level": 150,
        "hp": 1511,
        "sp": 500,
        "hpx": 63,
        "spx": 20,
        "int": 8,
        "atk": 95,
        "def": 64,
        "agi": 45,
        "item1": 27120,
        "item2": 27120,
        "item3": 27121,
        "item4": 33034,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17412,
        "idmap": 12806,
        "ten_map": "Rung Di Huyen",
        "thanh": "U Châu",
        "ten_npc": "Sở Hổ Sĩ",
        "level": 150,
        "hp": 1532,
        "sp": 563,
        "hpx": 66,
        "spx": 29,
        "int": 9,
        "atk": 62,
        "def": 93,
        "agi": 36,
        "item1": 32011,
        "item2": 32011,
        "item3": 26272,
        "item4": 37992,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17520,
        "idmap": 12818,
        "thanh": "U Châu",
        "ten_npc": "Đội Vệ Cung",
        "level": 150,
        "hp": 1490,
        "sp": 507,
        "hpx": 60,
        "spx": 21,
        "int": 25,
        "atk": 84,
        "def": 84,
        "agi": 24,
        "item1": 26241,
        "item2": 26241,
        "item3": 26256,
        "item4": 33040,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15457,
        "idmap": 12409,
        "thanh": "U Châu",
        "ten_npc": "Trị Nguyên Đa",
        "level": 151,
        "hp": 8843,
        "sp": 1365,
        "hpx": 160,
        "spx": 143,
        "int": 150,
        "atk": 197,
        "def": 132,
        "agi": 147,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17393,
        "idmap": 12804,
        "ten_map": "D.Ham Phi Chi Lam",
        "thanh": "U Châu",
        "ten_npc": "Đòan Cận Vệ",
        "level": 151,
        "hp": 1533,
        "sp": 509,
        "hpx": 65,
        "spx": 21,
        "int": 8,
        "atk": 92,
        "def": 66,
        "agi": 44,
        "item1": 26256,
        "item2": 26256,
        "item3": 26257,
        "item4": 42030,
        "item5": 50005,
        "item6": 0
    },
    {
        "idnpc": 17413,
        "idmap": 12806,
        "ten_map": "Rung Di Huyen",
        "thanh": "U Châu",
        "ten_npc": "Mã Đao Thủ",
        "level": 151,
        "hp": 1554,
        "sp": 558,
        "hpx": 68,
        "spx": 28,
        "int": 8,
        "atk": 65,
        "def": 92,
        "agi": 35,
        "item1": 26272,
        "item2": 26272,
        "item3": 32011,
        "item4": 33036,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17521,
        "idmap": 12818,
        "thanh": "U Châu",
        "ten_npc": "Cường Tẩu Binh",
        "level": 151,
        "hp": 1491,
        "sp": 523,
        "hpx": 59,
        "spx": 23,
        "int": 22,
        "atk": 79,
        "def": 89,
        "agi": 24,
        "item1": 27120,
        "item2": 27120,
        "item3": 26310,
        "item4": 42028,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17522,
        "idmap": 12818,
        "thanh": "U Châu",
        "ten_npc": "TrậnLiệt Tướng",
        "level": 152,
        "hp": 1540,
        "sp": 511,
        "hpx": 65,
        "spx": 21,
        "int": 33,
        "atk": 82,
        "def": 77,
        "agi": 25,
        "item1": 26310,
        "item2": 26310,
        "item3": 27120,
        "item4": 0,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 17523,
        "idmap": 12819,
        "thanh": "U Châu",
        "ten_npc": "Thục Tráng Sĩ",
        "level": 152,
        "hp": 1590,
        "sp": 540,
        "hpx": 72,
        "spx": 25,
        "int": 36,
        "atk": 97,
        "def": 57,
        "agi": 16,
        "item1": 26255,
        "item2": 26255,
        "item3": 26242,
        "item4": 36024,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14697,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Thiết Mộc Lăng",
        "level": 153,
        "hp": 6723,
        "sp": 515,
        "hpx": 61,
        "spx": 36,
        "int": 14,
        "atk": 244,
        "def": 184,
        "agi": 194,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17420,
        "idmap": 12806,
        "ten_map": "Rung Di Huyen",
        "thanh": "U Châu",
        "ten_npc": "Binh Võ Lăng",
        "level": 153,
        "hp": 1562,
        "sp": 570,
        "hpx": 67,
        "spx": 29,
        "int": 11,
        "atk": 72,
        "def": 94,
        "agi": 28,
        "item1": 26271,
        "item2": 26271,
        "item3": 27134,
        "item4": 34030,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 17517,
        "idmap": 12818,
        "thanh": "U Châu",
        "ten_npc": "Trưởng Phủ Đội",
        "level": 153,
        "hp": 1555,
        "sp": 535,
        "hpx": 66,
        "spx": 24,
        "int": 45,
        "atk": 78,
        "def": 66,
        "agi": 24,
        "item1": 26310,
        "item2": 26310,
        "item3": 27135,
        "item4": 34030,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17524,
        "idmap": 12819,
        "thanh": "U Châu",
        "ten_npc": "QuânTinh Trung",
        "level": 153,
        "hp": 1562,
        "sp": 528,
        "hpx": 67,
        "spx": 23,
        "int": 30,
        "atk": 89,
        "def": 72,
        "agi": 21,
        "item1": 26242,
        "item2": 26242,
        "item3": 26255,
        "item4": 0,
        "item5": 50005,
        "item6": 0
    },
    {
        "idnpc": 17421,
        "idmap": 12806,
        "ten_map": "Rung Di Huyen",
        "thanh": "U Châu",
        "ten_npc": "TrạiTrậnThương",
        "level": 154,
        "hp": 1577,
        "sp": 579,
        "hpx": 68,
        "spx": 30,
        "int": 10,
        "atk": 71,
        "def": 92,
        "agi": 31,
        "item1": 27134,
        "item2": 27134,
        "item3": 26271,
        "item4": 36029,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17518,
        "idmap": 12818,
        "thanh": "U Châu",
        "ten_npc": "Phó Tướng",
        "level": 154,
        "hp": 1556,
        "sp": 530,
        "hpx": 65,
        "spx": 23,
        "int": 27,
        "atk": 92,
        "def": 71,
        "agi": 27,
        "item1": 27135,
        "item2": 27135,
        "item3": 26310,
        "item4": 0,
        "item5": 50005,
        "item6": 0
    },
    {
        "idnpc": 17527,
        "idmap": 12820,
        "ten_map": "Tuyet Dia Bac Phong 6",
        "thanh": "U Châu",
        "ten_npc": "HòanThươngTrại",
        "level": 154,
        "hp": 1612,
        "sp": 488,
        "hpx": 73,
        "spx": 17,
        "int": 61,
        "atk": 85,
        "def": 48,
        "agi": 21,
        "item1": 26271,
        "item2": 26300,
        "item3": 26300,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13147,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Lăng Thống",
        "level": 155,
        "hp": 5263,
        "sp": 497,
        "hpx": 62,
        "spx": 18,
        "int": 21,
        "atk": 153,
        "def": 34,
        "agi": 148,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14409,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Yêu Quỷ Vương",
        "level": 155,
        "hp": 2472,
        "sp": 525,
        "hpx": 40,
        "spx": 46,
        "int": 12,
        "atk": 112,
        "def": 48,
        "agi": 82,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17394,
        "idmap": 12804,
        "ten_map": "D.Ham Phi Chi Lam",
        "thanh": "U Châu",
        "ten_npc": "Quân Sở Duyệt",
        "level": 155,
        "hp": 1578,
        "sp": 525,
        "hpx": 67,
        "spx": 22,
        "int": 9,
        "atk": 95,
        "def": 69,
        "agi": 45,
        "item1": 26144,
        "item2": 26144,
        "item3": 26171,
        "item4": 35024,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17528,
        "idmap": 12820,
        "ten_map": "Tuyet Dia Bac Phong 6",
        "thanh": "U Châu",
        "ten_npc": "Nguyên Đao Thủ",
        "level": 155,
        "hp": 1585,
        "sp": 525,
        "hpx": 68,
        "spx": 22,
        "int": 32,
        "atk": 87,
        "def": 78,
        "agi": 22,
        "item1": 26300,
        "item2": 26271,
        "item3": 26271,
        "item4": 37988,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10263,
        "idmap": 10961,
        "ten_npc": "Đại Vũ Quan",
        "level": 156,
        "hp": 1649,
        "sp": 513,
        "hpx": 76,
        "spx": 20,
        "int": 22,
        "atk": 83,
        "def": 78,
        "agi": 29,
        "item1": 26347,
        "item2": 26347,
        "item3": 27084,
        "item4": 0,
        "item5": 50005,
        "item6": 0
    },
    {
        "idnpc": 12178,
        "idmap": 11502,
        "ten_map": "Son Dong Thai Son 2",
        "thanh": "Thanh Châu",
        "ten_npc": "NguỵDiênphẫnnộ",
        "level": 156,
        "hp": 5860,
        "sp": 1362,
        "hpx": 142,
        "spx": 140,
        "int": 119,
        "atk": 192,
        "def": 139,
        "agi": 153,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17395,
        "idmap": 12804,
        "ten_map": "D.Ham Phi Chi Lam",
        "thanh": "U Châu",
        "ten_npc": "BinhThiếtTrận",
        "level": 156,
        "hp": 1607,
        "sp": 513,
        "hpx": 70,
        "spx": 20,
        "int": 8,
        "atk": 92,
        "def": 75,
        "agi": 42,
        "item1": 26143,
        "item2": 26143,
        "item3": 26258,
        "item4": 35029,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17398,
        "idmap": 12805,
        "ten_map": "Tan Cung Phi Chi Lam",
        "thanh": "U Châu",
        "ten_npc": "QuânThiếtĐôn",
        "level": 156,
        "hp": 1600,
        "sp": 584,
        "hpx": 69,
        "spx": 30,
        "int": 14,
        "atk": 75,
        "def": 83,
        "agi": 36,
        "item1": 26145,
        "item2": 26145,
        "item3": 26208,
        "item4": 33035,
        "item5": 50005,
        "item6": 0
    },
    {
        "idnpc": 17531,
        "idmap": 12820,
        "ten_map": "Tuyet Dia Bac Phong 6",
        "thanh": "U Châu",
        "ten_npc": "Thành Vũ Sĩ",
        "level": 156,
        "hp": 1586,
        "sp": 549,
        "hpx": 67,
        "spx": 25,
        "int": 37,
        "atk": 88,
        "def": 69,
        "agi": 23,
        "item1": 26305,
        "item2": 26305,
        "item3": 32028,
        "item4": 40035,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10264,
        "idmap": 10961,
        "ten_npc": "Tử sĩ Tiên Ti",
        "level": 157,
        "hp": 1664,
        "sp": 544,
        "hpx": 77,
        "spx": 24,
        "int": 26,
        "atk": 79,
        "def": 74,
        "agi": 30,
        "item1": 27084,
        "item2": 27084,
        "item3": 26347,
        "item4": 33041,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17422,
        "idmap": 12807,
        "ten_map": "Rung Cay ngoai O 3",
        "thanh": "U Châu",
        "ten_npc": "Hưng Hán Quân",
        "level": 157,
        "hp": 1607,
        "sp": 593,
        "hpx": 69,
        "spx": 31,
        "int": 12,
        "atk": 74,
        "def": 95,
        "agi": 27,
        "item1": 27120,
        "item2": 27120,
        "item3": 26015,
        "item4": 40037,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17525,
        "idmap": 12819,
        "thanh": "U Châu",
        "ten_npc": "Quân Hộ Quốc",
        "level": 157,
        "hp": 1586,
        "sp": 551,
        "hpx": 66,
        "spx": 25,
        "int": 22,
        "atk": 88,
        "def": 84,
        "agi": 25,
        "item1": 26108,
        "item2": 26108,
        "item3": 26242,
        "item4": 37993,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17399,
        "idmap": 12805,
        "ten_map": "Tan Cung Phi Chi Lam",
        "thanh": "U Châu",
        "ten_npc": "Binh Đặc Trận",
        "level": 158,
        "hp": 1622,
        "sp": 596,
        "hpx": 70,
        "spx": 31,
        "int": 16,
        "atk": 78,
        "def": 83,
        "agi": 35,
        "item1": 26208,
        "item2": 26208,
        "item3": 26145,
        "item4": 35025,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17526,
        "idmap": 12819,
        "thanh": "U Châu",
        "ten_npc": "Triệu Vũ Trại",
        "level": 158,
        "hp": 1594,
        "sp": 553,
        "hpx": 66,
        "spx": 25,
        "int": 21,
        "atk": 86,
        "def": 90,
        "agi": 27,
        "item1": 26242,
        "item2": 26242,
        "item3": 26108,
        "item4": 33037,
        "item5": 49002,
        "item6": 0
    },
    {
        "idnpc": 17532,
        "idmap": 12820,
        "ten_map": "Tuyet Dia Bac Phong 6",
        "thanh": "U Châu",
        "ten_npc": "Trọng My Binh",
        "level": 158,
        "hp": 1587,
        "sp": 539,
        "hpx": 65,
        "spx": 23,
        "int": 30,
        "atk": 91,
        "def": 85,
        "agi": 21,
        "item1": 32028,
        "item2": 32028,
        "item3": 26305,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17423,
        "idmap": 12807,
        "ten_map": "Rung Cay ngoai O 3",
        "thanh": "U Châu",
        "ten_npc": "Tiểu Hiệu Trại",
        "level": 159,
        "hp": 1644,
        "sp": 591,
        "hpx": 72,
        "spx": 30,
        "int": 13,
        "atk": 76,
        "def": 95,
        "agi": 28,
        "item1": 26015,
        "item2": 26015,
        "item3": 27120,
        "item4": 37982,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17529,
        "idmap": 12820,
        "ten_map": "Tuyet Dia Bac Phong 6",
        "thanh": "U Châu",
        "ten_npc": "Long Vũ Binh",
        "level": 159,
        "hp": 1595,
        "sp": 584,
        "hpx": 65,
        "spx": 29,
        "int": 28,
        "atk": 87,
        "def": 83,
        "agi": 24,
        "item1": 26171,
        "item2": 26171,
        "item3": 26257,
        "item4": 50001,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17533,
        "idmap": 12820,
        "ten_map": "Tuyet Dia Bac Phong 6",
        "thanh": "U Châu",
        "ten_npc": "Cử Thành Trại",
        "level": 159,
        "hp": 1573,
        "sp": 458,
        "hpx": 62,
        "spx": 27,
        "int": 31,
        "atk": 88,
        "def": 83,
        "agi": 25,
        "item1": 26311,
        "item2": 32041,
        "item3": 32041,
        "item4": 37974,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13145,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Từ Thịnh",
        "level": 160,
        "hp": 5201,
        "sp": 529,
        "hpx": 31,
        "spx": 21,
        "int": 26,
        "atk": 165,
        "def": 41,
        "agi": 155,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13151,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Tôn Hoàn",
        "level": 160,
        "hp": 5435,
        "sp": 544,
        "hpx": 64,
        "spx": 39,
        "int": 30,
        "atk": 170,
        "def": 21,
        "agi": 140,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17424,
        "idmap": 12807,
        "ten_map": "Rung Cay ngoai O 3",
        "thanh": "U Châu",
        "ten_npc": "TrườngKíchThủ",
        "level": 160,
        "hp": 1812,
        "sp": 586,
        "hpx": 72,
        "spx": 29,
        "int": 11,
        "atk": 96,
        "def": 72,
        "agi": 34,
        "item1": 26258,
        "item2": 26258,
        "item3": 27121,
        "item4": 42036,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17530,
        "idmap": 12820,
        "ten_map": "Tuyet Dia Bac Phong 6",
        "thanh": "U Châu",
        "ten_npc": "Hưng Quốc Quân",
        "level": 160,
        "hp": 1777,
        "sp": 558,
        "hpx": 67,
        "spx": 25,
        "int": 28,
        "atk": 87,
        "def": 81,
        "agi": 28,
        "item1": 26257,
        "item2": 26257,
        "item3": 26171,
        "item4": 40043,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17534,
        "idmap": 12820,
        "ten_map": "Tuyet Dia Bac Phong 6",
        "thanh": "U Châu",
        "ten_npc": "Thượng Tướng",
        "level": 160,
        "hp": 1741,
        "sp": 474,
        "hpx": 62,
        "spx": 29,
        "int": 33,
        "atk": 86,
        "def": 83,
        "agi": 33,
        "item1": 32041,
        "item2": 26311,
        "item3": 26311,
        "item4": 42030,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17541,
        "idmap": 12821,
        "ten_map": "Rung Trung Son 1",
        "thanh": "U Châu",
        "ten_npc": "KhươngCốc Binh",
        "level": 160,
        "hp": 1762,
        "sp": 572,
        "hpx": 65,
        "spx": 27,
        "int": 29,
        "atk": 83,
        "def": 81,
        "agi": 30,
        "item1": 26306,
        "item2": 26306,
        "item3": 26301,
        "item4": 42037,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13148,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Hàn Đang",
        "level": 161,
        "hp": 5470,
        "sp": 657,
        "hpx": 64,
        "spx": 39,
        "int": 21,
        "atk": 168,
        "def": 36,
        "agi": 166,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17425,
        "idmap": 12807,
        "ten_map": "Rung Cay ngoai O 3",
        "thanh": "U Châu",
        "ten_npc": " Đạo Binh",
        "level": 161,
        "hp": 1821,
        "sp": 596,
        "hpx": 72,
        "spx": 30,
        "int": 12,
        "atk": 96,
        "def": 73,
        "agi": 35,
        "item1": 27121,
        "item2": 27121,
        "item3": 26258,
        "item4": 36025,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17537,
        "idmap": 12820,
        "ten_map": "Tuyet Dia Bac Phong 6",
        "thanh": "U Châu",
        "ten_npc": "Hạp Đao Binh",
        "level": 161,
        "hp": 1778,
        "sp": 610,
        "hpx": 66,
        "spx": 32,
        "int": 30,
        "atk": 81,
        "def": 83,
        "agi": 28,
        "item1": 26306,
        "item2": 26306,
        "item3": 26301,
        "item4": 36026,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17542,
        "idmap": 12821,
        "ten_map": "Rung Trung Son 1",
        "thanh": "U Châu",
        "ten_npc": "SKhương Tướng",
        "level": 161,
        "hp": 1778,
        "sp": 603,
        "hpx": 66,
        "spx": 31,
        "int": 30,
        "atk": 83,
        "def": 79,
        "agi": 31,
        "item1": 26301,
        "item2": 26301,
        "item3": 26306,
        "item4": 41032,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13150,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Chu Nhiên",
        "level": 162,
        "hp": 5504,
        "sp": 512,
        "hpx": 65,
        "spx": 18,
        "int": 22,
        "atk": 158,
        "def": 41,
        "agi": 145,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17400,
        "idmap": 12805,
        "ten_map": "Tan Cung Phi Chi Lam",
        "thanh": "U Châu",
        "ten_npc": "Võ Sĩ GiápCứng",
        "level": 162,
        "hp": 1844,
        "sp": 598,
        "hpx": 74,
        "spx": 30,
        "int": 14,
        "atk": 74,
        "def": 91,
        "agi": 33,
        "item1": 26171,
        "item2": 26171,
        "item3": 26257,
        "item4": 41034,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17535,
        "idmap": 12820,
        "ten_map": "Tuyet Dia Bac Phong 6",
        "thanh": "U Châu",
        "ten_npc": "LữnTrung ĐBinh",
        "level": 162,
        "hp": 1801,
        "sp": 577,
        "hpx": 68,
        "spx": 27,
        "int": 28,
        "atk": 85,
        "def": 85,
        "agi": 29,
        "item1": 32041,
        "item2": 32041,
        "item3": 26311,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17538,
        "idmap": 12820,
        "ten_map": "Tuyet Dia Bac Phong 6",
        "thanh": "U Châu",
        "ten_npc": "Phàn Sơn Thủ",
        "level": 162,
        "hp": 1787,
        "sp": 612,
        "hpx": 66,
        "spx": 32,
        "int": 26,
        "atk": 83,
        "def": 84,
        "agi": 31,
        "item1": 26301,
        "item2": 26301,
        "item3": 26306,
        "item4": 40044,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17401,
        "idmap": 12805,
        "ten_map": "Tan Cung Phi Chi Lam",
        "thanh": "U Châu",
        "ten_npc": "Hắc Báo Vệ",
        "level": 163,
        "hp": 1839,
        "sp": 615,
        "hpx": 72,
        "spx": 32,
        "int": 15,
        "atk": 75,
        "def": 93,
        "agi": 33,
        "item1": 26257,
        "item2": 26257,
        "item3": 26171,
        "item4": 37976,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17536,
        "idmap": 12820,
        "ten_map": "Tuyet Dia Bac Phong 6",
        "thanh": "U Châu",
        "ten_npc": "LữnTrung XBinh",
        "level": 163,
        "hp": 1796,
        "sp": 608,
        "hpx": 66,
        "spx": 31,
        "int": 31,
        "atk": 81,
        "def": 84,
        "agi": 29,
        "item1": 26311,
        "item2": 26311,
        "item3": 32041,
        "item4": 40036,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17429,
        "idmap": 12808,
        "ten_map": "Map nguoi rom TQ",
        "thanh": "U Châu",
        "ten_npc": "Man Cuồng Ma",
        "level": 164,
        "hp": 1869,
        "sp": 617,
        "hpx": 75,
        "spx": 32,
        "int": 62,
        "atk": 86,
        "def": 47,
        "agi": 25,
        "item1": 26284,
        "item2": 26284,
        "item3": 26258,
        "item4": 37980,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17543,
        "idmap": 12821,
        "ten_map": "Rung Trung Son 1",
        "thanh": "U Châu",
        "ten_npc": "Đoàn BiênPhòng",
        "level": 164,
        "hp": 1804,
        "sp": 603,
        "hpx": 66,
        "spx": 30,
        "int": 31,
        "atk": 84,
        "def": 87,
        "agi": 29,
        "item1": 26273,
        "item2": 26273,
        "item3": 27121,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17426,
        "idmap": 12807,
        "ten_map": "Rung Cay ngoai O 3",
        "thanh": "U Châu",
        "ten_npc": "Man Võ Dã",
        "level": 165,
        "hp": 1870,
        "sp": 612,
        "hpx": 74,
        "spx": 31,
        "int": 11,
        "atk": 99,
        "def": 77,
        "agi": 33,
        "item1": 27103,
        "item2": 27103,
        "item3": 26283,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17539,
        "idmap": 12820,
        "ten_map": "Tuyet Dia Bac Phong 6",
        "thanh": "U Châu",
        "ten_npc": "Hãn Khương Vĩ",
        "level": 165,
        "hp": 1835,
        "sp": 591,
        "hpx": 69,
        "spx": 28,
        "int": 31,
        "atk": 89,
        "def": 79,
        "agi": 31,
        "item1": 26258,
        "item2": 26258,
        "item3": 26285,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17544,
        "idmap": 12821,
        "ten_map": "Rung Trung Son 1",
        "thanh": "U Châu",
        "ten_npc": "Người Sơn Vũ",
        "level": 165,
        "hp": 1806,
        "sp": 598,
        "hpx": 65,
        "spx": 29,
        "int": 30,
        "atk": 87,
        "def": 87,
        "agi": 29,
        "item1": 27121,
        "item2": 27121,
        "item3": 26273,
        "item4": 42033,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17427,
        "idmap": 12807,
        "ten_map": "Rung Cay ngoai O 3",
        "thanh": "U Châu",
        "ten_npc": "Cường Đôn Quân",
        "level": 166,
        "hp": 1894,
        "sp": 600,
        "hpx": 76,
        "spx": 29,
        "int": 10,
        "atk": 95,
        "def": 83,
        "agi": 32,
        "item1": 26283,
        "item2": 26283,
        "item3": 27103,
        "item4": 35031,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17430,
        "idmap": 12808,
        "ten_map": "Map nguoi rom TQ",
        "thanh": "U Châu",
        "ten_npc": "Man Cuồng Ma",
        "level": 166,
        "hp": 1865,
        "sp": 629,
        "hpx": 72,
        "spx": 33,
        "int": 13,
        "atk": 95,
        "def": 78,
        "agi": 34,
        "item1": 26258,
        "item2": 26258,
        "item3": 26284,
        "item4": 40038,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13021,
        "idmap": 11503,
        "ten_map": "Son Dong Thai Son 3",
        "thanh": "Thanh Châu",
        "ten_npc": "Lục Tốn",
        "level": 167,
        "hp": 3202,
        "sp": 834,
        "hpx": 71,
        "spx": 38,
        "int": 116,
        "atk": 34,
        "def": 69,
        "agi": 55,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 48010,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17479,
        "idmap": 12815,
        "ten_map": "Tuyet Dia Bac Phong 1",
        "thanh": "U Châu",
        "ten_npc": "Biên Quan Quân",
        "level": 167,
        "hp": 1838,
        "sp": 617,
        "hpx": 67,
        "spx": 31,
        "int": 46,
        "atk": 87,
        "def": 86,
        "agi": 25,
        "item1": 27091,
        "item2": 27091,
        "item3": 27121,
        "item4": 33038,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17540,
        "idmap": 12820,
        "ten_map": "Tuyet Dia Bac Phong 6",
        "thanh": "U Châu",
        "ten_npc": "TướngSơnKhương",
        "level": 167,
        "hp": 1845,
        "sp": 631,
        "hpx": 68,
        "spx": 33,
        "int": 31,
        "atk": 87,
        "def": 82,
        "agi": 32,
        "item1": 26285,
        "item2": 26285,
        "item3": 26258,
        "item4": 37994,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17431,
        "idmap": 12808,
        "ten_map": "Map nguoi rom TQ",
        "thanh": "U Châu",
        "ten_npc": "Đọat Hạm Binh",
        "level": 168,
        "hp": 1897,
        "sp": 598,
        "hpx": 74,
        "spx": 28,
        "int": 14,
        "atk": 99,
        "def": 83,
        "agi": 33,
        "item1": 27121,
        "item2": 27121,
        "item3": 27090,
        "item4": 34031,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17480,
        "idmap": 12815,
        "ten_map": "Tuyet Dia Bac Phong 1",
        "thanh": "U Châu",
        "ten_npc": "LChâu Du Binh",
        "level": 168,
        "hp": 1839,
        "sp": 590,
        "hpx": 66,
        "spx": 27,
        "int": 27,
        "atk": 90,
        "def": 92,
        "agi": 31,
        "item1": 27121,
        "item2": 27121,
        "item3": 27091,
        "item4": 36028,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17545,
        "idmap": 12821,
        "ten_map": "Rung Trung Son 1",
        "thanh": "U Châu",
        "ten_npc": "Hào Dũng Sĩ",
        "level": 168,
        "hp": 1839,
        "sp": 612,
        "hpx": 66,
        "spx": 30,
        "int": 31,
        "atk": 88,
        "def": 86,
        "agi": 32,
        "item1": 27091,
        "item2": 27091,
        "item3": 27121,
        "item4": 34037,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17432,
        "idmap": 12808,
        "ten_map": "Map nguoi rom TQ",
        "thanh": "U Châu",
        "ten_npc": "Trưởng PBinh",
        "level": 169,
        "hp": 1949,
        "sp": 585,
        "hpx": 80,
        "spx": 26,
        "int": 14,
        "atk": 97,
        "def": 87,
        "agi": 28,
        "item1": 27090,
        "item2": 27090,
        "item3": 27121,
        "item4": 35023,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17433,
        "idmap": 12808,
        "ten_map": "Map nguoi rom TQ",
        "thanh": "U Châu",
        "ten_npc": "Tráng Sĩ Trại",
        "level": 169,
        "hp": 1934,
        "sp": 593,
        "hpx": 78,
        "spx": 27,
        "int": 12,
        "atk": 100,
        "def": 86,
        "agi": 29,
        "item1": 26273,
        "item2": 26284,
        "item3": 26273,
        "item4": 42032,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12150,
        "idmap": 11501,
        "ten_map": "Son Dong Thai Son 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Qu.Bìnhanhdũng",
        "level": 170,
        "hp": 4803,
        "sp": 1194,
        "hpx": 168,
        "spx": 110,
        "int": 117,
        "atk": 189,
        "def": 141,
        "agi": 141,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 26398,
        "item6": 0
    },
    {
        "idnpc": 17434,
        "idmap": 12808,
        "ten_map": "Map nguoi rom TQ",
        "thanh": "U Châu",
        "ten_npc": "Chẩm Đao Thủ",
        "level": 170,
        "hp": 1958,
        "sp": 617,
        "hpx": 80,
        "spx": 30,
        "int": 16,
        "atk": 98,
        "def": 81,
        "agi": 32,
        "item1": 26284,
        "item2": 26273,
        "item3": 26284,
        "item4": 36027,
        "item5": 49002,
        "item6": 0
    },
    {
        "idnpc": 17483,
        "idmap": 12816,
        "thanh": "U Châu",
        "ten_npc": "Đội Đôn Hoàng",
        "level": 170,
        "hp": 1864,
        "sp": 609,
        "hpx": 67,
        "spx": 29,
        "int": 31,
        "atk": 90,
        "def": 89,
        "agi": 33,
        "item1": 27103,
        "item2": 27103,
        "item3": 27121,
        "item4": 37740,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17484,
        "idmap": 12816,
        "thanh": "U Châu",
        "ten_npc": "Tuất Biên Uy",
        "level": 170,
        "hp": 1864,
        "sp": 617,
        "hpx": 67,
        "spx": 30,
        "int": 29,
        "atk": 91,
        "def": 90,
        "agi": 32,
        "item1": 27103,
        "item2": 27103,
        "item3": 27090,
        "item4": 37997,
        "item5": 49002,
        "item6": 0
    },
    {
        "idnpc": 17546,
        "idmap": 12821,
        "ten_map": "Rung Trung Son 1",
        "thanh": "U Châu",
        "ten_npc": "Truân Sở Vệ",
        "level": 170,
        "hp": 1864,
        "sp": 631,
        "hpx": 67,
        "spx": 32,
        "int": 30,
        "atk": 89,
        "def": 90,
        "agi": 31,
        "item1": 27121,
        "item2": 27121,
        "item3": 27091,
        "item4": 37976,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12041,
        "idmap": 11132,
        "ten_map": "N.Te Tien Sinh Bac Hai",
        "thanh": "Thanh Châu",
        "ten_npc": "Bàng Thống",
        "level": 171,
        "hp": 2959,
        "sp": 1088,
        "hpx": 28,
        "spx": 83,
        "int": 119,
        "atk": 45,
        "def": 37,
        "agi": 87,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 48012,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14560,
        "idmap": 12023,
        "ten_map": "Diem Binh Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Lý Ý",
        "level": 171,
        "hp": 11073,
        "sp": 910,
        "hpx": 86,
        "spx": 23,
        "int": 123,
        "atk": 22,
        "def": 82,
        "agi": 56,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14700,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "ChDung phẫn nộ",
        "level": 171,
        "hp": 6471,
        "sp": 1335,
        "hpx": 159,
        "spx": 129,
        "int": 120,
        "atk": 299,
        "def": 126,
        "agi": 274,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17547,
        "idmap": 12822,
        "ten_map": "Dia Dao",
        "thanh": "U Châu",
        "ten_npc": "Hiểm Đao Binh",
        "level": 171,
        "hp": 1880,
        "sp": 626,
        "hpx": 68,
        "spx": 31,
        "int": 31,
        "atk": 87,
        "def": 90,
        "agi": 32,
        "item1": 26274,
        "item2": 26274,
        "item3": 32041,
        "item4": 36030,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17549,
        "idmap": 12822,
        "ten_map": "Dia Dao",
        "thanh": "U Châu",
        "ten_npc": "Gấu Hổ Sĩ",
        "level": 171,
        "hp": 1901,
        "sp": 619,
        "hpx": 71,
        "spx": 30,
        "int": 33,
        "atk": 88,
        "def": 87,
        "agi": 30,
        "item1": 26257,
        "item2": 26257,
        "item3": 27121,
        "item4": 37998,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17548,
        "idmap": 12822,
        "ten_map": "Dia Dao",
        "thanh": "U Châu",
        "ten_npc": "TiểuthànhBinh",
        "level": 172,
        "hp": 1903,
        "sp": 621,
        "hpx": 70,
        "spx": 30,
        "int": 32,
        "atk": 89,
        "def": 86,
        "agi": 33,
        "item1": 32041,
        "item2": 32041,
        "item3": 26274,
        "item4": 41036,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17550,
        "idmap": 12822,
        "ten_map": "Dia Dao",
        "thanh": "U Châu",
        "ten_npc": "Đội Huyến Giáp",
        "level": 172,
        "hp": 1881,
        "sp": 636,
        "hpx": 67,
        "spx": 32,
        "int": 35,
        "atk": 91,
        "def": 88,
        "agi": 27,
        "item1": 27121,
        "item2": 27121,
        "item3": 26257,
        "item4": 37978,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12174,
        "idmap": 11502,
        "ten_map": "Son Dong Thai Son 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Khương Duy",
        "level": 173,
        "hp": 2598,
        "sp": 573,
        "hpx": 69,
        "spx": 23,
        "int": 118,
        "atk": 98,
        "def": 38,
        "agi": 58,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17481,
        "idmap": 12815,
        "ten_map": "Tuyet Dia Bac Phong 1",
        "thanh": "U Châu",
        "ten_npc": "HoangMạc doanh",
        "level": 173,
        "hp": 1897,
        "sp": 645,
        "hpx": 68,
        "spx": 33,
        "int": 33,
        "atk": 90,
        "def": 88,
        "agi": 31,
        "item1": 26306,
        "item2": 26306,
        "item3": 26301,
        "item4": 40042,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17485,
        "idmap": 12816,
        "thanh": "U Châu",
        "ten_npc": "Giác Đấu Binh",
        "level": 173,
        "hp": 1904,
        "sp": 638,
        "hpx": 69,
        "spx": 32,
        "int": 30,
        "atk": 91,
        "def": 92,
        "agi": 30,
        "item1": 26307,
        "item2": 26307,
        "item3": 26301,
        "item4": 33039,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17482,
        "idmap": 12815,
        "ten_map": "Tuyet Dia Bac Phong 1",
        "thanh": "U Châu",
        "ten_npc": "Chinh kỳ binh",
        "level": 174,
        "hp": 1906,
        "sp": 619,
        "hpx": 68,
        "spx": 29,
        "int": 35,
        "atk": 92,
        "def": 90,
        "agi": 32,
        "item1": 26301,
        "item2": 26301,
        "item3": 26306,
        "item4": 41039,
        "item5": 49002,
        "item6": 0
    },
    {
        "idnpc": 17486,
        "idmap": 12816,
        "thanh": "U Châu",
        "ten_npc": "Phiếu Đô Uy",
        "level": 174,
        "hp": 1913,
        "sp": 626,
        "hpx": 69,
        "spx": 30,
        "int": 32,
        "atk": 91,
        "def": 93,
        "agi": 31,
        "item1": 26301,
        "item2": 26301,
        "item3": 26307,
        "item4": 40041,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14690,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "ChuBao phẫn nộ",
        "level": 175,
        "hp": 4713,
        "sp": 1217,
        "hpx": 140,
        "spx": 111,
        "int": 20,
        "atk": 255,
        "def": 120,
        "agi": 133,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17551,
        "idmap": 12823,
        "ten_map": "Rung Trung Son 2",
        "thanh": "U Châu",
        "ten_npc": "Thủ Trại Binh",
        "level": 175,
        "hp": 1907,
        "sp": 657,
        "hpx": 67,
        "spx": 34,
        "int": 33,
        "atk": 89,
        "def": 88,
        "agi": 35,
        "item1": 26307,
        "item2": 26307,
        "item3": 26285,
        "item4": 46018,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17553,
        "idmap": 12823,
        "ten_map": "Rung Trung Son 2",
        "thanh": "U Châu",
        "ten_npc": "Củ Trấn Quân",
        "level": 175,
        "hp": 1929,
        "sp": 657,
        "hpx": 70,
        "spx": 34,
        "int": 33,
        "atk": 86,
        "def": 88,
        "agi": 35,
        "item1": 27092,
        "item2": 27092,
        "item3": 27122,
        "item4": 34032,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11010,
        "idmap": 10964,
        "ten_npc": "Tư Mã Ý",
        "level": 176,
        "hp": 3349,
        "sp": 1282,
        "hpx": 70,
        "spx": 47,
        "int": 118,
        "atk": 32,
        "def": 72,
        "agi": 74,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 48026,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17552,
        "idmap": 12823,
        "ten_map": "Rung Trung Son 2",
        "thanh": "U Châu",
        "ten_npc": "Trường Ải Khẩu",
        "level": 176,
        "hp": 1923,
        "sp": 645,
        "hpx": 68,
        "spx": 32,
        "int": 31,
        "atk": 93,
        "def": 92,
        "agi": 34,
        "item1": 26285,
        "item2": 26285,
        "item3": 26307,
        "item4": 41037,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17554,
        "idmap": 12823,
        "ten_map": "Rung Trung Son 2",
        "thanh": "U Châu",
        "ten_npc": "Chương Vũ Trại",
        "level": 176,
        "hp": 1938,
        "sp": 645,
        "hpx": 70,
        "spx": 32,
        "int": 33,
        "atk": 90,
        "def": 91,
        "agi": 34,
        "item1": 27122,
        "item2": 27122,
        "item3": 27092,
        "item4": 42034,
        "item5": 49002,
        "item6": 0
    },
    {
        "idnpc": 13149,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Chu Thái",
        "level": 177,
        "hp": 7598,
        "sp": 502,
        "hpx": 44,
        "spx": 12,
        "int": 28,
        "atk": 188,
        "def": 48,
        "agi": 170,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17555,
        "idmap": 12823,
        "ten_map": "Rung Trung Son 2",
        "thanh": "U Châu",
        "ten_npc": "Du Kích Quân",
        "level": 177,
        "hp": 1961,
        "sp": 662,
        "hpx": 72,
        "spx": 34,
        "int": 32,
        "atk": 90,
        "def": 88,
        "agi": 34,
        "item1": 26286,
        "item2": 26286,
        "item3": 26274,
        "item4": 40039,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17557,
        "idmap": 12824,
        "ten_map": "Rung Trung Son 3",
        "thanh": "U Châu",
        "ten_npc": "Đoàn Vệ Vũ",
        "level": 177,
        "hp": 1939,
        "sp": 647,
        "hpx": 69,
        "spx": 32,
        "int": 33,
        "atk": 95,
        "def": 93,
        "agi": 29,
        "item1": 26275,
        "item2": 26275,
        "item3": 26285,
        "item4": 37981,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 17556,
        "idmap": 12823,
        "ten_map": "Rung Trung Son 2",
        "thanh": "U Châu",
        "ten_npc": "Xung Kích Quân",
        "level": 178,
        "hp": 1955,
        "sp": 650,
        "hpx": 70,
        "spx": 32,
        "int": 33,
        "atk": 93,
        "def": 91,
        "agi": 34,
        "item1": 26274,
        "item2": 26274,
        "item3": 26286,
        "item4": 34036,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17558,
        "idmap": 12824,
        "ten_map": "Rung Trung Son 3",
        "thanh": "U Châu",
        "ten_npc": "Uy Hán Quân",
        "level": 178,
        "hp": 1948,
        "sp": 628,
        "hpx": 69,
        "spx": 29,
        "int": 33,
        "atk": 92,
        "def": 97,
        "agi": 32,
        "item1": 26285,
        "item2": 26285,
        "item3": 26275,
        "item4": 37995,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17559,
        "idmap": 12824,
        "ten_map": "Rung Trung Son 3",
        "thanh": "U Châu",
        "ten_npc": "TruânKhẩnBinh",
        "level": 179,
        "hp": 1949,
        "sp": 652,
        "hpx": 68,
        "spx": 32,
        "int": 35,
        "atk": 94,
        "def": 96,
        "agi": 32,
        "item1": 26307,
        "item2": 26307,
        "item3": 26302,
        "item4": 40045,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17561,
        "idmap": 12824,
        "ten_map": "Rung Trung Son 3",
        "thanh": "U Châu",
        "ten_npc": "Vô Tiền Mãn Sĩ",
        "level": 179,
        "hp": 1964,
        "sp": 637,
        "hpx": 70,
        "spx": 30,
        "int": 38,
        "atk": 94,
        "def": 93,
        "agi": 32,
        "item1": 27123,
        "item2": 27123,
        "item3": 26307,
        "item4": 37979,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12001,
        "idmap": 11031,
        "thanh": "Thanh Châu",
        "ten_npc": "Gia Cát Lượng",
        "level": 180,
        "hp": 3354,
        "sp": 913,
        "hpx": 62,
        "spx": 55,
        "int": 125,
        "atk": 28,
        "def": 68,
        "agi": 79,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 48022,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14702,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Mạnh Ưu phẫnnộ",
        "level": 180,
        "hp": 8616,
        "sp": 1284,
        "hpx": 166,
        "spx": 118,
        "int": 128,
        "atk": 191,
        "def": 135,
        "agi": 150,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15088,
        "idmap": 12091,
        "ten_map": "Dai Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Hoa Đà",
        "level": 180,
        "hp": 11527,
        "sp": 675,
        "hpx": 72,
        "spx": 52,
        "int": 200,
        "atk": 50,
        "def": 100,
        "agi": 120,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17560,
        "idmap": 12824,
        "ten_map": "Rung Trung Son 3",
        "thanh": "U Châu",
        "ten_npc": "Nông Chiến Sĩ",
        "level": 180,
        "hp": 1973,
        "sp": 662,
        "hpx": 70,
        "spx": 33,
        "int": 33,
        "atk": 94,
        "def": 96,
        "agi": 31,
        "item1": 26302,
        "item2": 26302,
        "item3": 27092,
        "item4": 34034,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17562,
        "idmap": 12824,
        "ten_map": "Rung Trung Son 3",
        "thanh": "U Châu",
        "ten_npc": "Nhô Tướng",
        "level": 180,
        "hp": 1965,
        "sp": 691,
        "hpx": 69,
        "spx": 37,
        "int": 36,
        "atk": 93,
        "def": 95,
        "agi": 27,
        "item1": 26307,
        "item2": 26307,
        "item3": 27123,
        "item4": 41038,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12149,
        "idmap": 11501,
        "ten_map": "Son Dong Thai Son 1",
        "thanh": "Thanh Châu",
        "ten_npc": "QuanVũ phẫn nộ",
        "level": 185,
        "hp": 4938,
        "sp": 1337,
        "hpx": 140,
        "spx": 123,
        "int": 140,
        "atk": 200,
        "def": 149,
        "agi": 148,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 26398,
        "item6": 0
    },
    {
        "idnpc": 14252,
        "idmap": 11841,
        "ten_map": "Rung Vi Son 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Từ Phúc",
        "level": 185,
        "hp": 10001,
        "sp": 1163,
        "hpx": 74,
        "spx": 49,
        "int": 122,
        "atk": 63,
        "def": 57,
        "agi": 106,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14523,
        "idmap": 12021,
        "ten_map": "Cua Thanh Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Tử Hư Nhân",
        "level": 185,
        "hp": 8151,
        "sp": 1095,
        "hpx": 74,
        "spx": 40,
        "int": 123,
        "atk": 64,
        "def": 60,
        "agi": 110,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14624,
        "idmap": 12061,
        "ten_map": "Diep Thanh Thanh Mon",
        "thanh": "U Châu",
        "ten_npc": "Từ Đăng",
        "level": 185,
        "hp": 8003,
        "sp": 1095,
        "hpx": 54,
        "spx": 40,
        "int": 137,
        "atk": 34,
        "def": 80,
        "agi": 126,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14701,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Mạnh Hộ phẫnnộ",
        "level": 185,
        "hp": 8719,
        "sp": 1249,
        "hpx": 151,
        "spx": 111,
        "int": 137,
        "atk": 199,
        "def": 141,
        "agi": 154,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15171,
        "idmap": 12101,
        "ten_map": "N.Ly Lang Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Hỏa Đức Quân",
        "level": 185,
        "hp": 11408,
        "sp": 874,
        "hpx": 14,
        "spx": 10,
        "int": 256,
        "atk": 28,
        "def": 66,
        "agi": 204,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 48025,
        "item5": 65168,
        "item6": 0
    },
    {
        "idnpc": 15231,
        "idmap": 12173,
        "ten_map": "N.Trinh Lao Dau Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Nguyệt Lữo",
        "level": 185,
        "hp": 13303,
        "sp": 733,
        "hpx": 20,
        "spx": 16,
        "int": 263,
        "atk": 60,
        "def": 56,
        "agi": 213,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15336,
        "idmap": 12302,
        "ten_map": "Quan Phu Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Thôn Nhân",
        "level": 185,
        "hp": 11408,
        "sp": 681,
        "hpx": 14,
        "spx": 13,
        "int": 34,
        "atk": 171,
        "def": 163,
        "agi": 70,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15337,
        "idmap": 12302,
        "ten_map": "Quan Phu Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Thôn Nhân",
        "level": 185,
        "hp": 11408,
        "sp": 681,
        "hpx": 14,
        "spx": 13,
        "int": 34,
        "atk": 171,
        "def": 163,
        "agi": 70,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14689,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "UngKhải phẫnnộ",
        "level": 188,
        "hp": 5101,
        "sp": 1325,
        "hpx": 153,
        "spx": 120,
        "int": 27,
        "atk": 257,
        "def": 123,
        "agi": 141,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14148,
        "idmap": 11585,
        "thanh": "Thanh Châu",
        "ten_npc": "Tiên Từ Vô Sơn",
        "level": 190,
        "hp": 2804,
        "sp": 682,
        "hpx": 76,
        "spx": 51,
        "int": 124,
        "atk": 40,
        "def": 68,
        "agi": 76,
        "item1": 27022,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15166,
        "idmap": 12101,
        "ten_map": "N.Ly Lang Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Tiên Nhân",
        "level": 196,
        "hp": 10143,
        "sp": 753,
        "hpx": 17,
        "spx": 14,
        "int": 253,
        "atk": 37,
        "def": 194,
        "agi": 57,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10039,
        "idmap": 10806,
        "ten_npc": "NamHoa LữoTiên",
        "level": 200,
        "hp": 11724,
        "sp": 1824,
        "hpx": 200,
        "spx": 200,
        "int": 300,
        "atk": 250,
        "def": 250,
        "agi": 300,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12167,
        "idmap": 11502,
        "ten_map": "Son Dong Thai Son 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Oan hồn Tr.Phi",
        "level": 200,
        "hp": 31574,
        "sp": 1612,
        "hpx": 180,
        "spx": 100,
        "int": 100,
        "atk": 400,
        "def": 400,
        "agi": 400,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14225,
        "idmap": 11831,
        "ten_map": "Lang Ta Phi Lam 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Tả Từ",
        "level": 200,
        "hp": 11724,
        "sp": 1824,
        "hpx": 200,
        "spx": 200,
        "int": 250,
        "atk": 200,
        "def": 200,
        "agi": 250,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15009,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Bạch Lữo Nhân",
        "level": 200,
        "hp": 8822,
        "sp": 746,
        "hpx": 80,
        "spx": 57,
        "int": 200,
        "atk": 200,
        "def": 200,
        "agi": 200,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15049,
        "idmap": 12071,
        "ten_map": "Thanh Dai Huyen 1",
        "thanh": "U Châu",
        "ten_npc": "Bắc Tinh Quân",
        "level": 200,
        "hp": 21724,
        "sp": 2164,
        "hpx": 200,
        "spx": 200,
        "int": 300,
        "atk": 250,
        "def": 250,
        "agi": 300,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15050,
        "idmap": 12072,
        "thanh": "U Châu",
        "ten_npc": "Nam Tinh Quân",
        "level": 200,
        "hp": 21724,
        "sp": 2164,
        "hpx": 200,
        "spx": 200,
        "int": 300,
        "atk": 250,
        "def": 250,
        "agi": 300,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15052,
        "idmap": 12074,
        "thanh": "U Châu",
        "ten_npc": "Đinh Cát",
        "level": 200,
        "hp": 11724,
        "sp": 1824,
        "hpx": 200,
        "spx": 200,
        "int": 250,
        "atk": 200,
        "def": 200,
        "agi": 250,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15241,
        "idmap": 12177,
        "ten_map": "Nha Luu Bi Lau Song",
        "thanh": "U Châu",
        "ten_npc": "Diêm Bắc Quân",
        "level": 200,
        "hp": 11724,
        "sp": 2164,
        "hpx": 200,
        "spx": 200,
        "int": 300,
        "atk": 250,
        "def": 250,
        "agi": 300,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15242,
        "idmap": 12177,
        "ten_map": "Nha Luu Bi Lau Song",
        "thanh": "U Châu",
        "ten_npc": "Diêm Nam Quân",
        "level": 200,
        "hp": 11724,
        "sp": 2164,
        "hpx": 200,
        "spx": 200,
        "int": 300,
        "atk": 250,
        "def": 250,
        "agi": 300,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15340,
        "idmap": 12302,
        "ten_map": "Quan Phu Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Bắc Ông",
        "level": 200,
        "hp": 5724,
        "sp": 1964,
        "hpx": 200,
        "spx": 200,
        "int": 200,
        "atk": 200,
        "def": 200,
        "agi": 200,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15341,
        "idmap": 12303,
        "ten_map": "Quan Phu BinhNguyen",
        "thanh": "U Châu",
        "ten_npc": "Nam Ông",
        "level": 200,
        "hp": 5724,
        "sp": 1964,
        "hpx": 200,
        "spx": 200,
        "int": 200,
        "atk": 200,
        "def": 200,
        "agi": 200,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15443,
        "idmap": 12409,
        "thanh": "U Châu",
        "ten_npc": "Tà mã vương",
        "level": 200,
        "hp": 20724,
        "sp": 1964,
        "hpx": 200,
        "spx": 200,
        "int": 200,
        "atk": 200,
        "def": 200,
        "agi": 200,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    }
]