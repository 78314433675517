import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ImageService } from '../services/image.service';

@Component({
  selector: 'app-game-single-item',
  templateUrl: './game-single-item.component.html',
  styleUrls: ['./game-single-item.component.scss']
})
export class GameSingleItemComponent implements OnInit {

  @ViewChild('closeModal') closeModal: ElementRef
  
  isDelete = false
  messageSuccess: any
  messageError: any

  @Input() item: any
  @Input() edit:any

  imageDefault = '/assets/img/listing/img1.jpg'

  constructor(private api: ImageService) { }

  ngOnInit(): void {
    var category = this.item.category.replace("/", "-");
    var subCategory = this.item.subCategory.replace("/", "-");
    var species = this.item.species ? this.item.species.replace("/", "-") : "others";
    this.item.backgroundImages[0] = "assets/img/" + category + "/" + subCategory + "/" + species + "/" + this.item.slug + ".png";
  }

  handleMissingImage(event: Event) {
    (event.target as HTMLImageElement).src = this.imageDefault;
  }

  deleteItem(id: any){
    this.isDelete = true;
    this.api.DELETE(id).subscribe((res: any) => {
      this.isDelete = false;
      this.messageSuccess = "Deleted";
      this.closeModal.nativeElement.click() 
    }, err => {
      this.isDelete = false;
      this.messageError = err.error.error;
    });
  }

}
