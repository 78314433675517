import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-email-read',
  templateUrl: './app-email-read.component.html',
  styleUrls: ['./app-email-read.component.scss']
})
export class AppEmailReadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
