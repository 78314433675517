export const DATA = [
    {
        black: "Vương Thiên Nhất",
        red: "Tiết Hàm Đệ",
        data: [
            { x: 7, y: 7 }, { x: 4, y: 7 },
            { x: 7, y: 0 }, { x: 6, y: 2 },
            { x: 7, y: 9 }, { x: 6, y: 7 },
            { x: 8, y: 0 }, { x: 7, y: 0 },
            { x: 8, y: 9 }, { x: 7, y: 9 },
            { x: 1, y: 0 }, { x: 2, y: 2 },
            { x: 2, y: 6 }, { x: 2, y: 5 },
            { x: 6, y: 3 }, { x: 6, y: 4 },
            { x: 7, y: 9 }, { x: 7, y: 3 },
            { x: 6, y: 2 }, { x: 5, y: 4 },
            { x: 1, y: 9 }, { x: 2, y: 7 },
            { x: 6, y: 0 }, { x: 4, y: 2 },
            { x: 4, y: 6 }, { x: 4, y: 5 },
            { x: 6, y: 4 }, { x: 6, y: 5 },
            { x: 7, y: 3 }, { x: 5, y: 3 },
            { x: 5, y: 4 }, { x: 6, y: 6 },
            { x: 6, y: 7 }, { x: 4, y: 6 },
            { x: 7, y: 2 }, { x: 7, y: 9 },
            { x: 4, y: 5 }, { x: 4, y: 4 },
            { x: 7, y: 9 }, { x: 8, y: 9 },
            { x: 4, y: 7 }, { x: 6, y: 7 },
        ]
    }
    //https://www.youtube.com/watch?v=1Nz-152WHW0&ab_channel=BLVC%E1%BB%9DT%C6%B0%E1%BB%9BngMaiQu%C3%BDL%C3%A2n
]