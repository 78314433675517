import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'loading-3-dot',
  templateUrl: './loading3-dot.component.html',
  styleUrls: ['./loading3-dot.component.scss']
})
export class Loading3DotComponent implements OnInit {

  @Input() type: any = 'elastic'
  constructor() { }

  ngOnInit(): void {
  }

}
