<div class="breadcrumb-area">
    <h1>Games Details</h1>
    <ol class="breadcrumb">
        <li class="item"><a routerLink="/admin-dashboard"><i class='bx bx-home-alt'></i></a></li>
        <li class="item">Dashboard</li>
        <li class="item">Listing</li>
    </ol>
</div>
<form [formGroup]="detailForm" (submit)="onSubmit()" *ngIf="!isLoading">
    <div class="add-listing-box mb-30">
        <h3>Basic Informations</h3>

        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label><i class='bx bx-briefcase-alt'></i> Name:</label>
                    <input type="text" class="form-control" placeholder="Name of monster" formControlName="name">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bx-duplicate'></i> Category:</label>
                    <ng-select [items]="CATEGORY" [searchable]="true" placeholder="Select a Category"
                        formControlName="category" bindLabel="label" bindValue="value">
                    </ng-select>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bx-duplicate'></i> SubCategory:</label>
                    <ng-select [items]="SUBCATEGORY" [searchable]="true" placeholder="Select a SubCategory"
                        formControlName="subCategory" bindLabel="label" bindValue="value">
                    </ng-select>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label><i class='bx bxs-key'></i> Keywords:</label>
                    <input type="text" class="form-control" placeholder="Maximum 15 , should be separated by commas"
                        formControlName="keywords">
                </div>
            </div>
        </div>
    </div>

    <div class="add-listing-box mb-30">
        <h3>Details</h3>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bxl-twitter'></i> Address:</label>
                    <input type="text" class="form-control" formControlName="address">
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label><i class='bx bx-text'></i> Text:</label>
                    <textarea cols="30" rows="7" class="form-control" formControlName="detail"></textarea>
                </div>
            </div>
        </div>
    </div>

    <div class="add-listing-box mb-30">
        <h3>Feedback</h3>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bx-purchase-tag'></i> Rate:</label>
                    <input type="number" class="form-control" max="5" min="1" formControlName="rate">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bx-purchase-tag'></i> Grade:</label>
                    <input type="number" class="form-control" max="5" min="1"  formControlName="grade">
                </div>
            </div>
        </div>
    </div>

    <div class="add-listing-box mb-30">
        <h3>Attributes</h3>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bx-duplicate'></i> Species:</label>
                    <ng-select [items]="SPECIES" placeholder="Select a Species" formControlName="species" bindLabel="label"
                        bindValue="value">
                    </ng-select>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bx-duplicate'></i> Type:</label>
                    <ng-select [items]="TYPE" placeholder="Select a Type" formControlName="type" bindLabel="label"
                        bindValue="value">
                    </ng-select>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bx-duplicate'></i> Element:</label>
                    <ng-select [items]="ELEMENT" placeholder="Select a Element" formControlName="element" bindLabel="label"
                        bindValue="value">
                    </ng-select>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bx-duplicate'></i> Status:</label>
                    <ng-select [items]="STATUS" placeholder="Select a Status" formControlName="status" bindLabel="label"
                        bindValue="value">
                    </ng-select>
                </div>
            </div>
        </div>
    </div>

    <div class="add-listing-box mb-30">
        <h3>Skills</h3>

        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label><i class='bx bx-cloud'></i> Leader Skill:</label>
                    <input type="text" class="form-control" formControlName="leaderSkill">
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bxl-facebook'></i> Skill1:</label>
                    <input type="text" class="form-control" formControlName="skill1">
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bxl-twitter'></i> Skill2:</label>
                    <input type="text" class="form-control" formControlName="skill2">
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bxl-linkedin'></i> Skill3:</label>
                    <input type="text" class="form-control" formControlName="skill3">
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bxl-instagram'></i> Skill4:</label>
                    <input type="text" class="form-control" formControlName="skill4">
                </div>
            </div>
        </div>

        <h3>Systems</h3>
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bxl-facebook'></i> Slug:</label>
                    <input type="text" class="form-control" formControlName="slug">
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bxl-twitter'></i> Created:</label>
                    <input type="text" class="form-control" formControlName="created" disabled="true">
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bxl-linkedin'></i> Created By:</label>
                    <input type="text" class="form-control" formControlName="createdBy" disabled="true">
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bxl-instagram'></i> Modified:</label>
                    <input type="text" class="form-control" formControlName="modified" disabled="true">
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bxl-instagram'></i> Modified By:</label>
                    <input type="text" class="form-control" formControlName="modifiedBy" disabled="true">
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <button type="submit" [disabled]="isSubmit">Submit Listing</button>
                <div class="alert alert-success" role="alert" *ngIf="messageSuccess">
                    {{messageSuccess}}
                </div>
                <div class="alert alert-danger" role="alert" *ngIf="messageError">
                    {{messageError}}
                </div>
            </div>
        </div>
    </div>
</form>