<app-header-one></app-header-one>

<div class="page-title-area page-title-bg4">
    <div class="container">
        <div class="page-title-content">
            <h2>Listing</h2>
        </div>
    </div>
</div>

<section class="listing-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="listing-filter-options">
                    <div class="row align-items-center">
                        <div class="col-lg-8">
                            <p>Showing 1 – 18 of 100</p>
                        </div>

                        <div class="col-lg-4">
                            <div class="listing-ordering-list">
                                <select>
                                    <option>Sort by Price: Low to High</option>
                                    <option>Default Sorting</option>
                                    <option>Sort by Popularity</option>
                                    <option>Sort by Average Rating</option>
                                    <option>Sort by Latest</option>
                                    <option>Sort by Price: High to Low</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 col-md-6">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img5.jpg" alt="image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">Restaurant</a>
                        </div>

                        <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                5.0 (1 reviews)
                            </div>
                        </div>
                    </div>

                    <div class="listing-content">
                        <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Steven Smith</span>
                        </div>

                        <h3><a href="#" class="d-inline-block">Farmis Garden Hotel & Restaurant</a></h3>

                        <span class="location"><i class="bx bx-map"></i> 40 Journal Square, NG USA</span>
                    </div>

                    <div class="listing-box-footer">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="price">
                                <span data-bs-toggle="tooltip" data-placement="top" title="Ultra Hight">
                                    $1500 - $2000
                                </span>
                            </div>

                            <div class="listing-option-list">
                                <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="listing-badge">Open!</div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 col-md-6">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img6.jpg" alt="image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">Shopping</a>
                        </div>

                        <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                5.0 (1 reviews)
                            </div>
                        </div>
                    </div>

                    <div class="listing-content">
                        <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Sarah Taylor</span>
                        </div>

                        <h3><a href="#" class="d-inline-block">Skyview Shopping Complex Center</a></h3>

                        <span class="location"><i class="bx bx-map"></i> 55 County Laois, Ireland</span>
                    </div>

                    <div class="listing-box-footer">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="price">
                                <span data-bs-toggle="tooltip" data-placement="top" title="Pricey">
                                    $100 - $200
                                </span>
                            </div>

                            <div class="listing-option-list">
                                <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="listing-badge">Open!</div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 col-md-6">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img7.jpg" alt="image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">Gym</a>
                        </div>

                        <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                4.5 (2 reviews)
                            </div>
                        </div>
                    </div>

                    <div class="listing-content">
                        <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                            <span>James Andy</span>
                        </div>

                        <h3><a href="#" class="d-inline-block">Power House Gym Training Center</a></h3>

                        <span class="location"><i class="bx bx-map"></i> Tilt Tilbury, United Kingdom</span>
                    </div>

                    <div class="listing-box-footer">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="price">
                                <span data-bs-toggle="tooltip" data-placement="top" title="Moderate">
                                    $240 - $250
                                </span>
                            </div>

                            <div class="listing-option-list">
                                <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="listing-badge">Open!</div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 col-md-6">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img8.jpg" alt="image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">Restaurant</a>
                        </div>

                        <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                5.0 (1 reviews)
                            </div>
                        </div>
                    </div>

                    <div class="listing-content">
                        <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Steven Smith</span>
                        </div>

                        <h3><a href="#" class="d-inline-block">The Magician Restaurant in USA</a></h3>

                        <span class="location"><i class="bx bx-map"></i> 40 Journal Square, NG USA</span>
                    </div>

                    <div class="listing-box-footer">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="price">
                                <span data-bs-toggle="tooltip" data-placement="top" title="Ultra Hight">
                                    $1500 - $2000
                                </span>
                            </div>

                            <div class="listing-option-list">
                                <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="listing-badge">Open!</div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 col-md-6">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img9.jpg" alt="image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">Shopping</a>
                        </div>

                        <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                5.0 (1 reviews)
                            </div>
                        </div>
                    </div>

                    <div class="listing-content">
                        <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Sarah Taylor</span>
                        </div>

                        <h3><a href="#" class="d-inline-block">New Oriental Shopping Center</a></h3>

                        <span class="location"><i class="bx bx-map"></i> 55 County Laois, Ireland</span>
                    </div>

                    <div class="listing-box-footer">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="price">
                                <span data-bs-toggle="tooltip" data-placement="top" title="Pricey">
                                    $100 - $200
                                </span>
                            </div>

                            <div class="listing-option-list">
                                <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="listing-badge closed">Closed!</div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 col-md-6">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img10.jpg" alt="image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">Gym</a>
                        </div>

                        <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                4.5 (2 reviews)
                            </div>
                        </div>
                    </div>

                    <div class="listing-content">
                        <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                            <span>James Andy</span>
                        </div>

                        <h3><a href="#" class="d-inline-block">Looks Women's Fitness Center</a></h3>

                        <span class="location"><i class="bx bx-map"></i> Tilt Tilbury, United Kingdom</span>
                    </div>

                    <div class="listing-box-footer">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="price">
                                <span data-bs-toggle="tooltip" data-placement="top" title="Moderate">
                                    $240 - $250
                                </span>
                            </div>

                            <div class="listing-option-list">
                                <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="listing-badge">Open!</div>
                    
                    <span class="listing-discount">
                        <span>-15% OFF</span>
                    </span>
                </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="pagination-area text-center">
                    <a routerLink="/" class="prev page-numbers"><i class='bx bx-chevron-left'></i></a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/" class="page-numbers">2</a>
                    <a routerLink="/" class="page-numbers">3</a>
                    <a routerLink="/" class="page-numbers">4</a>
                    <a routerLink="/" class="page-numbers">5</a>
                    <a routerLink="/" class="next page-numbers"><i class='bx bx-chevron-right'></i></a>
                </div>
            </div>
        </div>
    </div>
</section>