import { Component, OnInit } from '@angular/core';
import { ReInvoiceService } from 'src/app/services/invoice.service';

@Component({
  selector: 'app-admin-invoice',
  templateUrl: './admin-invoice.component.html',
  styleUrls: ['./admin-invoice.component.scss']
})
export class AdminInvoiceComponent implements OnInit {

  items: any
  isLoading = false
  constructor(private api: ReInvoiceService) { }

  ngOnInit(): void {
    this.fetch()
  }

  fetch(){
    this.isLoading = true;
    this.api.SEARCH({}).subscribe((res:any)=> {
      this.items = res.results;
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
    })
  }

}
