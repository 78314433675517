<app-header></app-header>

<div class="page-title-area page-title-bg2">
    <div class="container">
        <div class="page-title-content">
            <h2>Listing Details</h2>
        </div>
    </div>
</div>

<section class="listing-details-area pt-100 pb-70">
    <div class="container">
        <div class="listing-details-header">
            <div class="row align-items-center">
                <div class="col-lg-8">
                    <div class="listing-title">
                        <h2>{{item.name}}</h2>
                        <p>{{item.details}}</p>
                    </div>

                    <div class="listing-meta">
                        <ul>
                            <li>
                                <i class='bx bx-folder-open'></i>
                                <span>New Visitor</span>
                                <a routerLink="/">300</a>
                            </li>
                            <li>
                                <i class='bx bx-group'></i>
                                <span>Happy Customers</span>
                                <a routerLink="/">813,454</a>
                            </li>
                            <li>
                                <i class='bx bx-calendar'></i>
                                <span>Last Updated</span>
                                <a routerLink="/">01/14/2019</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="listing-price">
                        <div class="listing-review">
                            <div class="review-stars">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>
                            <span class="reviews-total d-inline-block">(2 reviews)</span>
                        </div>

                        <div class="price">$80 - $250</div>
                        <a routerLink="/listing-details" class="default-btn">Claim Now!</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="listing-details-image-slides owl-carousel owl-theme">
                    <div class="listing-details-image text-center" *ngFor="let img of item.carousel_Images">
                        <img [src]="img" alt="image">
                    </div>
                </div>

                <div class="listing-details-desc">
                    <h3>Description</h3>
                    <p><strong>Hi! Welcome to Certified Graphic Design with Free Project Course, the only course you
                            need to become a BI Analyst.</strong></p>
                    <p>We are proud to present you this one-of-a-kind opportunity. There are several online listing
                        teaching some of the skills related to the BI Analyst profession. The truth of the matter is
                        that none of them completely prepare you.</p>

                    <ul class="description-features-list">
                        <li>Feasibility and Economic Studies</li>
                        <li>Design Coordination</li>
                        <li>Pro Forma Financial Analysis</li>
                        <li>Scheduling</li>
                        <li>Contact Cegotiation</li>
                    </ul>

                    <h3>Amenities</h3>

                    <div class="amenities-list">
                        <ul>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Elevator in Building
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Instant Book
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Friendly Workspace
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Wireless Internet
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Free Parking on Premises
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Free Parking on Street
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Air Conditioned
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Live Music
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Accepting Credit Cards
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Pool
                                </span>
                            </li>
                        </ul>
                    </div>

                    <h3>Location</h3>

                    <div id="map">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.53224763717!2d106.69207091530244!3d10.770486862247184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f3e866e1431%3A0x622002f1dd05ced4!2sChill%20Skybar!5e0!3m2!1sen!2s!4v1670941949224!5m2!1sen!2s"
                            width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>

                    <h3>Meet Your Author</h3>

                    <div class="listing-author">
                        <div class="author-profile-header"></div>
                        <div class="author-profile">
                            <div class="author-profile-title">
                                <img src="assets/img/default/admin.jpg" class="shadow-sm rounded-circle" alt="image">

                                <div class="author-profile-title-details">
                                    <div class="author-profile-details">
                                        <h4>Andy Nguyễn</h4>
                                        <span class="d-block">Photographer, Author, Teacher</span>
                                    </div>
                                </div>
                            </div>
                            <p>Andy Nguyễn is a celebrated photographer, author, and teacher who brings passion to
                                everything he does.</p>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                                has been the industry's standard dummy text ever since the 1500s.</p>
                        </div>
                    </div>

                    <div class="listing-review-comments">
                        <h3>3 Reviews</h3>

                        <div class="user-review">
                            <img src="assets/img/default/admin.jpg" alt="image">

                            <div class="review-rating">
                                <div class="review-stars">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>

                                <span class="d-inline-block">Andy Nguyễn</span>
                            </div>

                            <span class="d-block sub-comment">Excellent</span>
                            <p>Very well built theme, couldn't be happier with it. Can't wait for future updates to see
                                what else they add in.</p>
                        </div>

                        <div class="user-review">
                            <img src="assets/img/default/admin.jpg" alt="image">

                            <div class="review-rating">
                                <div class="review-stars">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>

                                <span class="d-inline-block">Sarah Taylor</span>
                            </div>

                            <span class="d-block sub-comment">Video Quality!</span>
                            <p>Was really easy to implement and they quickly answer my additional questions!</p>
                        </div>

                        <div class="user-review">
                            <img src="assets/img/default/admin.jpg" alt="image">

                            <div class="review-rating">
                                <div class="review-stars">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>

                                <span class="d-inline-block">David Warner</span>
                            </div>

                            <span class="d-block sub-comment">Perfect Coding!</span>
                            <p>Stunning design, very dedicated crew who welcome new ideas suggested by customers, nice
                                support.</p>
                        </div>
                    </div>
                </div>

                <div class="related-listing" *ngIf="relatedList.length > 0">
                    <h3>Related Listing</h3>

                    <div class="row">
                        <div class="col-lg-6 col-sm-12 col-md-6" *ngFor="let item of relatedList">
                            <app-single-item [item]="item"></app-single-item>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="listing-sidebar-widget">
                    <div class="listing-opening-hours">
                        <h3>Opening Hours</h3>

                        <ul>
                            <li><span>Saturday:</span> 9 AM - 3 PM</li>
                            <li><span>Sunday:</span> Closed</li>
                            <li><span>Monday:</span> 9 AM - 3 PM</li>
                            <li><span>Tuesday:</span> 9 AM - 3 PM</li>
                            <li><span>Wednesday:</span> 9 AM - 3 PM</li>
                            <li><span>Thursday:</span> 9 AM - 3 PM</li>
                            <li><span>Friday:</span> 9 AM - 3 PM</li>
                        </ul>
                    </div>

                    <div class="listing-contact-info">
                        <h3>Contact Info</h3>

                        <ul>
                            <li><span>Adress:</span> <a href="#">{{item.address}}</a></li>
                            <li><span>Phone:</span> <a href="tel:+01321654214">{{item.phone}}</a></li>
                            <li><span>Email:</span> <a href="mailto:hello@louise.com">{{item.email}}</a></li>
                            <li><span>Website:</span> <a href="#">{{item.website}}</a></li>
                            <li><a href="#">+ Google Map</a></li>
                        </ul>
                    </div>

                    <div class="listing-book-table">
                        <h3>Book A Table</h3>

                        <form>
                            <div class="form-group mb-3">
                                <label>Name:</label>
                                <input type="text" class="form-control">
                            </div>

                            <div class="form-group mb-3">
                                <label>Email:</label>
                                <input type="email" class="form-control">
                            </div>

                            <div class="form-group mb-3">
                                <label>Persons:</label>
                                <div class="input-counter">
                                    <span class="minus-btn"><i class='bx bx-minus'></i></span>
                                    <input type="text" value="1">
                                    <span class="plus-btn"><i class='bx bx-plus'></i></span>
                                </div>
                            </div>

                            <div class="form-group mb-3">
                                <label>Date:</label>
                                <input type="text" value="05/12/2020" class="form-control">
                            </div>

                            <div class="form-group mb-3">
                                <label>Write Message:</label>
                                <textarea class="form-control" cols="30" rows="5"></textarea>
                            </div>

                            <button type="submit" class="default-btn">Book Table Now!</button>
                        </form>
                    </div>

                    <div class="listing-instagram">
                        <h3>Instagram</h3>

                        <ul>
                            <li>
                                <a href="#" class="d-block">
                                    <img src="assets/img/blog/img1.jpg" alt="image">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="d-block">
                                    <img src="assets/img/blog/img2.jpg" alt="image">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="d-block">
                                    <img src="assets/img/blog/img3.jpg" alt="image">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="d-block">
                                    <img src="assets/img/blog/img4.jpg" alt="image">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="d-block">
                                    <img src="assets/img/blog/img5.jpg" alt="image">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="d-block">
                                    <img src="assets/img/blog/img6.jpg" alt="image">
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="listing-contact">
                        <div class="text">
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <span>Emergency</span>
                            <a href="tel:+098798768753">+0987-9876-8753</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>