<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>About The {{settings.name}}</h3>

                    <div class="about-the-store">
                        <p>{{settings.description}}</p>

                        <ul class="footer-contact-info">
                            <li><i class='bx bx-map'></i> <a href="#" target="_blank">{{settings.address}}</a></li>
                            <li><i class='bx bx-phone-call'></i> <a href="tel:+01321654214">{{settings.phone}}</a></li>
                            <li><i class='bx bx-envelope'></i> <a href="mailto:hello@louise.com">{{settings.email}}</a>
                            </li>
                        </ul>
                    </div>

                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-4">
                    <h3>Quick Links</h3>

                    <ul class="quick-links">
                        <li><a routerLink="/about">About Us</a></li>
                        <li><a routerLink="/listing">Listing</a></li>
                        <li><a routerLink="/how-it-works">How It Works</a></li>
                        <li><a routerLink="/faqs">FAQ's</a></li>
                        <li><a routerLink="/contact">Contact Us</a></li>
                        <li><a routerLink="/register">Register</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Customer Support</h3>

                    <ul class="customer-support">
                        <li><a routerLink="/login">My Account</a></li>
                        <li><a routerLink="/checkout">Checkout</a></li>
                        <li><a routerLink="/cart">Cart</a></li>
                        <li><a routerLink="/faqs">FAQ's</a></li>
                        <li><a routerLink="/register">Register</a></li>
                        <li><a routerLink="/contact">Help & Support</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Newsletter</h3>

                    <div class="footer-newsletter-box">
                        <p>To get the latest news and latest updates from us.</p>

                        <form class="newsletter-form" data-bs-toggle="validator">
                            <label>Your E-mail Address:</label>
                            <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                            <button type="submit">Subscribe</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>© {{settings.name}} is Proudly Owned by <a [href]="settings.website"
                            target="_blank">{{settings.owner}}</a></p>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Privacy Policy</a></li>
                        <li><a routerLink="/">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>

<div class="go-top"><i class='bx bx-chevron-up'></i></div>

<div class="modal right fade shoppingCartModal" id="shoppingCartModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <a type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"><i class='bx bx-x'></i></span>
            </a>

            <div class="modal-body">
                <h3>My Cart (3)</h3>

                <div class="products-cart-content">
                    <div class="products-cart">
                        <div class="products-image">
                            <a routerLink="/"><img src="assets/img/products/img1.jpg" alt="image"></a>
                        </div>

                        <div class="products-content">
                            <h3><a routerLink="/">Ham Salad</a></h3>
                            <span>Quantity: 01</span>
                            <div class="products-price">
                                $20
                            </div>
                            <a routerLink="/" class="remove-btn"><i class='bx bx-trash'></i></a>
                        </div>
                    </div>

                    <div class="products-cart">
                        <div class="products-image">
                            <a routerLink="#"><img src="assets/img/products/img2.jpg" alt="image"></a>
                        </div>

                        <div class="products-content">
                            <h3><a routerLink="#">Fresh Cappuccino</a></h3>
                            <span>Quantity: 02</span>
                            <div class="products-price">
                                $25
                            </div>
                            <a routerLink="#" class="remove-btn"><i class='bx bx-trash'></i></a>
                        </div>
                    </div>

                    <div class="products-cart">
                        <div class="products-image">
                            <a routerLink="#"><img src="assets/img/products/img3.jpg" alt="image"></a>
                        </div>

                        <div class="products-content">
                            <h3><a routerLink="#">Honey Cake</a></h3>
                            <span>Quantity: 01</span>
                            <div class="products-price">
                                $11
                            </div>
                            <a routerLink="#" class="remove-btn"><i class='bx bx-trash'></i></a>
                        </div>
                    </div>
                </div>

                <div class="products-cart-subtotal">
                    <span>Subtotal</span>

                    <span class="subtotal">$524.00</span>
                </div>

                <div class="products-cart-btn">
                    <a routerLink="/cart" class="default-btn">View Bag & Checkout</a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>

            <div class="search-overlay-close">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>

            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class='bx bx-search-alt'></i></button>
                </form>
            </div>
        </div>
    </div>
</div>