<mat-progress-bar mode="indeterminate" *ngIf="isSubmit"></mat-progress-bar>
<div class="container text-center">
    <div class="row justify-content-start">
        <div class="col-4 bgbox">
            <button class="btImg" mat-stroked-button color="primary"
                [ngStyle]="{'top': item.top +'px', 'left': item.left+'px', 'width': item.width+'px', 'height': item.height+'px'}"
                *ngFor="let item of slots" (click)="getRunesBySlot(item)"
                [matTooltip]="item.stat1 + '\n' + item.stat2 + '\n' + item.stat3 + '\n' + item.stat4">
                {{item.label}}
            </button>
        </div>
        <div class="col-4" *ngIf="runesByFilter.length > 0">
            <button class="button" mat-stroked-button color="accent" *ngFor="let item of runesByFilter"
                (click)="getRuneStat(item)">
                {{item}}
            </button>
        </div>
    </div>
    <!-- <div class="row justify-content-start" *ngIf="runesByFilter.length > 0">
        <div class="col-8">
            <button class="button" mat-stroked-button color="accent" *ngFor="let item of runesByFilter"
                (click)="getRuneStat(item)">
                {{item}}
            </button>
        </div>
    </div> -->
    <div class="row justify-content-start">
        <div class="col-8">
            <table mat-table [dataSource]="infos" class="mat-elevation-z8">
                <ng-content></ng-content>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>
                <ng-container matColumnDef="value">
                    <th mat-header-cell *matHeaderCellDef> Stat </th>
                    <td mat-cell *matCellDef="let element"> 
                            {{element.defaultValue}} 
                            <small *ngIf="element.addition > 0">+ ({{element.addition}})</small>
                        
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>
</div>