<app-header-one></app-header-one>

<div class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <h2>Listing</h2>
        </div>
    </div>
</div>

<section class="listing-area pt-100 pb-70">
    <div class="container">
        <div class="listing-topbar">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-3">
                    <div class="topbar-title">
                        <h2>Recent Listings</h2>
                    </div>
                </div>

                <div class="col-lg-8 col-md-9">
                    <div class="topbar-ordering-and-categories">
                        <div class="row align-items-center">
                            <div class="col-lg-5 col-md-5 offset-lg-2 offset-md-1 col-sm-6">
                                <div class="topbar-ordering">
                                    <select>
                                        <option>Sort by popularity</option>
                                        <option>Sort by latest</option>
                                        <option>Default sorting</option>
                                        <option>Sort by rating</option>
                                        <option>Sort by new</option>
                                        <option>Sort by lowest price</option>
                                        <option>Sort by highest price</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-lg-5 col-md-6 col-sm-6">
                                <div class="topbar-categories">
                                    <select>
                                        <option>All Categories</option>
                                        <option>Shops</option>
                                        <option>Hotels</option>
                                        <option>Restaurants</option>
                                        <option>Fitness</option>
                                        <option>Events</option>
                                        <option>Services</option>
                                        <option>Other</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="listing-items">
            <div class="col-lg-4 col-sm-12 col-md-6 grid-item">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img5.jpg" alt="image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">Restaurant</a>
                        </div>

                        <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                5.0 (1 reviews)
                            </div>
                        </div>
                    </div>

                    <div class="listing-content">
                        <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Steven Smith</span>
                        </div>

                        <h3><a routerLink="/listing-details" class="d-inline-block">Farmis Garden Hotel & Restaurant</a></h3>

                        <span class="location"><i class="bx bx-map"></i> 40 Journal Square, NG USA</span>
                    </div>

                    <div class="listing-box-footer">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="price">
                                <span data-bs-toggle="tooltip" data-placement="top" title="Ultra Hight">
                                    $1500 - $2000
                                </span>
                            </div>

                            <div class="listing-option-list">
                                <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="listing-badge">Open!</div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 col-md-6 grid-item">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img6.jpg" alt="image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">Shopping</a>
                        </div>

                        <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                5.0 (1 reviews)
                            </div>
                        </div>
                    </div>

                    <div class="listing-content">
                        <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Sarah Taylor</span>
                        </div>

                        <h3><a routerLink="/listing-details" class="d-inline-block">Skyview Shopping Complex Center</a></h3>

                        <span class="location"><i class="bx bx-map"></i> 55 County Laois, Ireland</span>
                    </div>

                    <div class="listing-badge">Open!</div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 col-md-6 grid-item">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img7.jpg" alt="image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">Gym</a>
                        </div>

                        <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                4.5 (2 reviews)
                            </div>
                        </div>
                    </div>

                    <div class="listing-content">
                        <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                            <span>James Andy</span>
                        </div>

                        <h3><a routerLink="/listing-details" class="d-inline-block">Power House Gym Training Center</a></h3>

                        <span class="location"><i class="bx bx-map"></i> Tilt Tilbury, United Kingdom</span>
                        <span class="phone-number"><i class='bx bx-phone-call'></i> <a href="tel:+882546654">+882-546-654</a></span>
                        <span class="email"><i class='bx bx-envelope'></i> <a href="mailto:hello@braike.com">hello@braike.com</a></span>
                    </div>

                    <div class="listing-box-footer">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="price">
                                <span data-bs-toggle="tooltip" data-placement="top" title="Moderate">
                                    $240 - $250
                                </span>
                            </div>

                            <div class="listing-option-list">
                                <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="listing-badge">Open!</div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 col-md-6 grid-item">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img8.jpg" alt="image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">Restaurant</a>
                        </div>

                        <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                5.0 (1 reviews)
                            </div>
                        </div>
                    </div>

                    <div class="listing-content">
                        <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Steven Smith</span>
                        </div>

                        <h3><a routerLink="/listing-details" class="d-inline-block">The Magician Restaurant in USA</a></h3>

                        <span class="location"><i class="bx bx-map"></i> Tilt Tilbury, United Kingdom</span>
                        <span class="phone-number"><i class='bx bx-phone-call'></i> <a href="tel:+882546654">+882-546-654</a></span>
                        <span class="email"><i class='bx bx-envelope'></i> <a href="mailto:hello@braike.com">hello@braike.com</a></span>
                    </div>

                    <div class="listing-box-footer">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="price">
                                <span data-bs-toggle="tooltip" data-placement="top" title="Ultra Hight">
                                    $1500 - $2000
                                </span>
                            </div>

                            <div class="listing-option-list">
                                <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="listing-badge">Open!</div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 col-md-6 grid-item">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img9.jpg" alt="image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">Shopping</a>
                        </div>

                        <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                5.0 (1 reviews)
                            </div>
                        </div>
                    </div>

                    <div class="listing-content">
                        <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Sarah Taylor</span>
                        </div>

                        <h3><a routerLink="/listing-details" class="d-inline-block">New Oriental Shopping Center</a></h3>

                        <span class="location"><i class="bx bx-map"></i> Tilt Tilbury, United Kingdom</span>
                        <span class="phone-number"><i class='bx bx-phone-call'></i> <a href="tel:+882546654">+882-546-654</a></span>
                        <span class="email"><i class='bx bx-envelope'></i> <a href="mailto:hello@braike.com">hello@braike.com</a></span>
                    </div>

                    <div class="listing-box-footer">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="price">
                                <span data-bs-toggle="tooltip" data-placement="top" title="Pricey">
                                    $100 - $200
                                </span>
                            </div>

                            <div class="listing-option-list">
                                <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="listing-badge closed">Closed!</div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 col-md-6 grid-item">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img10.jpg" alt="image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">Gym</a>
                        </div>

                        <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                4.5 (2 reviews)
                            </div>
                        </div>
                    </div>

                    <div class="listing-content">
                        <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                            <span>James Andy</span>
                        </div>

                        <h3><a routerLink="/listing-details" class="d-inline-block">Looks Women's Fitness Center</a></h3>

                        <span class="location"><i class="bx bx-map"></i> Tilt Tilbury, United Kingdom</span>
                    </div>

                    <div class="listing-badge">Open!</div>
                    
                    <span class="listing-discount">
                        <span>-15% OFF</span>
                    </span>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 col-md-6 grid-item">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img11.jpg" alt="image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">Restaurant</a>
                        </div>

                        <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                5.0 (1 reviews)
                            </div>
                        </div>
                    </div>

                    <div class="listing-content">
                        <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Steven Smith</span>
                        </div>

                        <h3><a routerLink="/listing-details" class="d-inline-block">Logan Brown Restaurant</a></h3>

                        <span class="location"><i class="bx bx-map"></i> 40 Journal Square, NG USA</span>
                    </div>

                    <div class="listing-box-footer">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="price">
                                <span data-bs-toggle="tooltip" data-placement="top" title="Ultra Hight">
                                    $1500 - $2000
                                </span>
                            </div>

                            <div class="listing-option-list">
                                <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="listing-badge">Open!</div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 col-md-6 grid-item">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img12.jpg" alt="image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">Shopping</a>
                        </div>

                        <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                5.0 (1 reviews)
                            </div>
                        </div>
                    </div>

                    <div class="listing-content">
                        <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Sarah Taylor</span>
                        </div>

                        <h3><a routerLink="/listing-details" class="d-inline-block">Centro Comercial Aricanduva</a></h3>

                        <span class="location"><i class="bx bx-map"></i> 55 County Laois, Ireland</span>
                    </div>

                    <div class="listing-badge">Open!</div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 col-md-6 grid-item">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img13.jpg" alt="image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">Gym</a>
                        </div>

                        <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                4.5 (2 reviews)
                            </div>
                        </div>
                    </div>

                    <div class="listing-content">
                        <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                            <span>James Andy</span>
                        </div>

                        <h3><a routerLink="/listing-details" class="d-inline-block">Foundation Crossfit Center</a></h3>

                        <span class="location"><i class="bx bx-map"></i> Tilt Tilbury, United Kingdom</span>
                        <span class="phone-number"><i class='bx bx-phone-call'></i> <a href="tel:+882546654">+882-546-654</a></span>
                        <span class="email"><i class='bx bx-envelope'></i> <a href="mailto:hello@braike.com">hello@braike.com</a></span>
                    </div>

                    <div class="listing-box-footer">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="price">
                                <span data-bs-toggle="tooltip" data-placement="top" title="Moderate">
                                    $240 - $250
                                </span>
                            </div>

                            <div class="listing-option-list">
                                <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="listing-badge">Open!</div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 col-md-6 grid-item">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img14.jpg" alt="image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">Restaurant</a>
                        </div>

                        <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                5.0 (1 reviews)
                            </div>
                        </div>
                    </div>

                    <div class="listing-content">
                        <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Steven Smith</span>
                        </div>

                        <h3><a routerLink="/listing-details" class="d-inline-block">Boulcott Street Bistro Restaurant</a></h3>

                        <span class="location"><i class="bx bx-map"></i> Tilt Tilbury, United Kingdom</span>
                        <span class="phone-number"><i class='bx bx-phone-call'></i> <a href="tel:+882546654">+882-546-654</a></span>
                        <span class="email"><i class='bx bx-envelope'></i> <a href="mailto:hello@braike.com">hello@braike.com</a></span>
                    </div>

                    <div class="listing-box-footer">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="price">
                                <span data-bs-toggle="tooltip" data-placement="top" title="Ultra Hight">
                                    $1500 - $2000
                                </span>
                            </div>

                            <div class="listing-option-list">
                                <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="listing-badge">Open!</div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 col-md-6 grid-item">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img15.jpg" alt="image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">Shopping</a>
                        </div>

                        <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                5.0 (1 reviews)
                            </div>
                        </div>
                    </div>

                    <div class="listing-content">
                        <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Sarah Taylor</span>
                        </div>

                        <h3><a routerLink="/listing-details" class="d-inline-block">Centro Comercial Santafé</a></h3>

                        <span class="location"><i class="bx bx-map"></i> Tilt Tilbury, United Kingdom</span>
                        <span class="phone-number"><i class='bx bx-phone-call'></i> <a href="tel:+882546654">+882-546-654</a></span>
                        <span class="email"><i class='bx bx-envelope'></i> <a href="mailto:hello@braike.com">hello@braike.com</a></span>
                    </div>

                    <div class="listing-box-footer">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="price">
                                <span data-bs-toggle="tooltip" data-placement="top" title="Pricey">
                                    $100 - $200
                                </span>
                            </div>

                            <div class="listing-option-list">
                                <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="listing-badge closed">Closed!</div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 col-md-6 grid-item">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img16.jpg" alt="image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">Gym</a>
                        </div>

                        <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                4.5 (2 reviews)
                            </div>
                        </div>
                    </div>

                    <div class="listing-content">
                        <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                            <span>James Andy</span>
                        </div>

                        <h3><a routerLink="/listing-details" class="d-inline-block">Redbike Studios Fitness</a></h3>

                        <span class="location"><i class="bx bx-map"></i> Tilt Tilbury, United Kingdom</span>
                    </div>

                    <div class="listing-badge">Open!</div>
                    
                    <span class="listing-discount">
                        <span>-15% OFF</span>
                    </span>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 col-md-6 grid-item">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img17.jpg" alt="image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">Restaurant</a>
                        </div>

                        <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                5.0 (1 reviews)
                            </div>
                        </div>
                    </div>

                    <div class="listing-content">
                        <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Steven Smith</span>
                        </div>

                        <h3><a routerLink="/listing-details" class="d-inline-block">Egmont Street Eatery</a></h3>

                        <span class="location"><i class="bx bx-map"></i> 40 Journal Square, NG USA</span>
                    </div>

                    <div class="listing-box-footer">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="price">
                                <span data-bs-toggle="tooltip" data-placement="top" title="Ultra Hight">
                                    $1500 - $2000
                                </span>
                            </div>

                            <div class="listing-option-list">
                                <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="listing-badge">Open!</div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 col-md-6 grid-item">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img18.jpg" alt="image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">Shopping</a>
                        </div>

                        <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                5.0 (1 reviews)
                            </div>
                        </div>
                    </div>

                    <div class="listing-content">
                        <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Sarah Taylor</span>
                        </div>

                        <h3><a routerLink="/listing-details" class="d-inline-block">South Coast Plaza</a></h3>

                        <span class="location"><i class="bx bx-map"></i> 55 County Laois, Ireland</span>
                    </div>

                    <div class="listing-badge">Open!</div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 col-md-6 grid-item">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img19.jpg" alt="image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">Gym</a>
                        </div>

                        <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                4.5 (2 reviews)
                            </div>
                        </div>
                    </div>

                    <div class="listing-content">
                        <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                            <span>James Andy</span>
                        </div>

                        <h3><a routerLink="/listing-details" class="d-inline-block">York Street Crossfit</a></h3>

                        <span class="location"><i class="bx bx-map"></i> Tilt Tilbury, United Kingdom</span>
                        <span class="phone-number"><i class='bx bx-phone-call'></i> <a href="tel:+882546654">+882-546-654</a></span>
                        <span class="email"><i class='bx bx-envelope'></i> <a href="mailto:hello@braike.com">hello@braike.com</a></span>
                    </div>

                    <div class="listing-box-footer">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="price">
                                <span data-bs-toggle="tooltip" data-placement="top" title="Moderate">
                                    $240 - $250
                                </span>
                            </div>

                            <div class="listing-option-list">
                                <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="listing-badge">Open!</div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 col-md-6 grid-item">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img20.jpg" alt="image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">Restaurant</a>
                        </div>

                        <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                5.0 (1 reviews)
                            </div>
                        </div>
                    </div>

                    <div class="listing-content">
                        <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Steven Smith</span>
                        </div>

                        <h3><a routerLink="/listing-details" class="d-inline-block">Noble Rot Wine Bar</a></h3>

                        <span class="location"><i class="bx bx-map"></i> Tilt Tilbury, United Kingdom</span>
                        <span class="phone-number"><i class='bx bx-phone-call'></i> <a href="tel:+882546654">+882-546-654</a></span>
                        <span class="email"><i class='bx bx-envelope'></i> <a href="mailto:hello@braike.com">hello@braike.com</a></span>
                    </div>

                    <div class="listing-box-footer">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="price">
                                <span data-bs-toggle="tooltip" data-placement="top" title="Ultra Hight">
                                    $1500 - $2000
                                </span>
                            </div>

                            <div class="listing-option-list">
                                <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="listing-badge">Open!</div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 col-md-6 grid-item">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img21.jpg" alt="image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">Shopping</a>
                        </div>

                        <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                5.0 (1 reviews)
                            </div>
                        </div>
                    </div>

                    <div class="listing-content">
                        <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Sarah Taylor</span>
                        </div>

                        <h3><a routerLink="/listing-details" class="d-inline-block">Mall of America</a></h3>

                        <span class="location"><i class="bx bx-map"></i> Tilt Tilbury, United Kingdom</span>
                        <span class="phone-number"><i class='bx bx-phone-call'></i> <a href="tel:+882546654">+882-546-654</a></span>
                        <span class="email"><i class='bx bx-envelope'></i> <a href="mailto:hello@braike.com">hello@braike.com</a></span>
                    </div>

                    <div class="listing-box-footer">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="price">
                                <span data-bs-toggle="tooltip" data-placement="top" title="Pricey">
                                    $100 - $200
                                </span>
                            </div>

                            <div class="listing-option-list">
                                <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="listing-badge closed">Closed!</div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 col-md-6 grid-item">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img22.jpg" alt="image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">Gym</a>
                        </div>

                        <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                4.5 (2 reviews)
                            </div>
                        </div>
                    </div>

                    <div class="listing-content">
                        <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                            <span>James Andy</span>
                        </div>

                        <h3><a routerLink="/listing-details" class="d-inline-block">Tiger’s Den Crossfit</a></h3>

                        <span class="location"><i class="bx bx-map"></i> Tilt Tilbury, United Kingdom</span>
                    </div>

                    <div class="listing-badge">Open!</div>
                    
                    <span class="listing-discount">
                        <span>-15% OFF</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</section>