import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LISTING } from '../data/listing.data';

@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss']
})
export class ListingComponent implements OnInit {

  data = LISTING.Latest
  items: any
  SubCategories = LISTING.SubCategory
  Facilities = LISTING.Facilities
  settings = LISTING.Settings
  filters = {
    SubCategories: [],
    Facilities: []
  }
  maxInPage: any

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.filters = {
          SubCategories: params.subcategories ? params.subcategories.split(",") : [],
          Facilities: params.facilities ? params.facilities.split(",") : []
        }
        this.fetch();
      }
    );
  }

  fetch(){
    var itemTemp = new Array();
    for(var i = 0; i < this.data.length; i++){
      var item = this.data[i];
      if(this.filters.SubCategories.length > 0){
        var childInSubCategory = this.filters.SubCategories.filter(subcate => subcate == item.subCategory);
        if(childInSubCategory.length > 0){
          itemTemp.push(item);
        }
      }
      else{
        itemTemp.push(item);
      }
    }
    this.items = itemTemp;
    this.maxInPage = this.items.length > 6 ? 6 : this.items.length
  }

  isSubCategoriesFilter(key: any) {
    var flag = this.filters.SubCategories.filter(c => c == key);
    if (flag.length > 0)
      return true;
    return false;
  }
  isFacilitiesFilter(key: any) {
    var flag = this.filters.Facilities.filter(c => c == key);
    if (flag.length > 0)
      return true;
    return false;
  }
}
