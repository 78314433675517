<mat-form-field appearance="fill" style="width: 100%;">
    <mat-label>Level</mat-label>
    <input matInput placeholder="0-15" minlength="1" maxlength="2" value="15" type="number" />
</mat-form-field>
<table class="example-full-width" cellspacing="0">
    <tr>
        <td>
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Option 1</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Number" matInput [formControl]="f.stat1"
                    [matAutocomplete]="auto">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option *ngFor="let option of allStats" [value]="option">
                        {{option}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </td>
        <td>
            <mat-form-field appearance="fill">
                <mat-label>+</mat-label>
                <input matInput placeholder="0-15" minlength="1" maxlength="2" type="number" [formControl]="f.stat1Value" />
            </mat-form-field>
        </td>
    </tr>
    <tr>
        <td>
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Option 2</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Number" matInput [formControl]="f.stat2"
                    [matAutocomplete]="auto">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option *ngFor="let option of allStats" [value]="option">
                        {{option}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </td>
        <td>
            <mat-form-field appearance="fill">
                <mat-label>+</mat-label>
                <input matInput placeholder="0-15" minlength="1" maxlength="2" type="number" [formControl]="f.stat2Value"/>
            </mat-form-field>
        </td>
    </tr>
    <tr>
        <td>
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Option 3</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Number" matInput [formControl]="f.stat3"
                    [matAutocomplete]="auto">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option *ngFor="let option of allStats" [value]="option">
                        {{option}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </td>
        <td>
            <mat-form-field appearance="fill">
                <mat-label>+</mat-label>
                <input matInput placeholder="0-15" minlength="1" maxlength="2" type="number" [formControl]="f.stat3Value"/>
            </mat-form-field>
        </td>
    </tr>
    <tr>
        <td>
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Option 4</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Number" matInput [formControl]="f.stat4"
                    [matAutocomplete]="auto">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option *ngFor="let option of allStats" [value]="option">
                        {{option}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </td>
        <td>
            <mat-form-field appearance="fill">
                <mat-label>+</mat-label>
                <input matInput placeholder="0-15" minlength="1" maxlength="2" type="number" [formControl]="f.stat4Value"/>
            </mat-form-field>
        </td>
    </tr>
</table>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Close</button>
    <button mat-button [mat-dialog-close]="f" cdkFocusInitial>Ok</button>
</div>