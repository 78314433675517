import { Component, OnInit } from '@angular/core';
import { LISTING } from 'src/app/data/listing.data';

@Component({
  selector: 'app-admin-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class AdminFooterComponent implements OnInit {

  settings = LISTING.Settings
  
  constructor() { }

  ngOnInit(): void {
  }

}
