import { Component, OnInit } from '@angular/core';
import { GAMEDATA } from 'src/app/data/game.data';
import { GAMEDATA_PART2 } from 'src/app/data/game_part2.data';

@Component({
  selector: 'app-summonerswar',
  templateUrl: './summonerswar.component.html',
  styleUrls: ['./summonerswar.component.scss']
})
export class SummonerswarComponent implements OnInit {

  currentDate = Date.now()
  items: any
  itemsPopular: any
  categories: any
  tags: any
  MAX: any
  startIndex: any
  total: any
  nameSearch: any
  datasource = [...GAMEDATA, ...GAMEDATA_PART2]
  data = this.datasource.filter(c => c.category == "summonerswar" && c.subCategory != "skills" && c.subCategory != "runes" && c.subCategory != "runetypes")
  imageDefault = "assets/img/blog/img1.jpg"
  constructor() { }

  ngOnInit(): void {
    this.items = this.data;
    for(var i = 0; i < this.items.length; i++){
      var item = this.items[i];
      var category = item.category.replace("/", "-");
      var subCategory = item.subCategory.replace("/", "-");
      var species = item.species ? item.species.replace("/", "-") : "others";
      if(!item.backgroundImages){
        item.backgroundImages = ["assets/img/blog/img1.jpg"];
      }
      item.backgroundImages[0] = "assets/img/" + category + "/" + subCategory + "/" + species + "/" + item.slug + ".png";
    }
    this.total = this.items.length;
    this.MAX = 8;
    this.startIndex = 0;
    this.getPopular();
    this.getCategories();
    this.getTags();
  }

  handleMissingImage(event: Event) {
    (event.target as HTMLImageElement).src = this.imageDefault;
  }

  getCategories() {
    this.categories = [];
    for (var i = 0; i < this.data.length; i++) {
      var item = this.data[i];
      var flag = true;
      if (this.categories.length > 0) {
        var cate = this.categories.filter((c: { name: string; }) => c.name == item.subCategory);
        if (cate.length > 0) {
          cate[0].total++;
          flag = false;
        }
      }

      if (flag) {
        this.categories.push({
          name: item.subCategory,
          total: 1
        })
      }
    }
  }
  getTags() {
    this.tags = [];
    for (var i = 0; i < this.data.length; i++) {
      var item = this.data[i];
      var flag_element = true;
      var flag_species = true;
      var flag_type = true;
      if (this.tags.length > 0) {
        var elementTemp = this.tags.filter((c: { name: string; }) => c.name == item.element);
        if (elementTemp.length > 0) {
          elementTemp[0].total++;
          flag_element = false;
        }
        var speciestemp = this.tags.filter((c: { name: string; }) => c.name == item.species);
        if (speciestemp.length > 0) {
          speciestemp[0].total++;
          flag_species = false;
        }
        var typeTemp = this.tags.filter((c: { name: string; }) => c.name == item.type);
        if (typeTemp.length > 0) {
          typeTemp[0].total++;
          flag_type = false;
        }
      }

      if (flag_element && item.element) {
        this.tags.push({
          name: item.element,
          total: 1
        })
      }
      if (flag_species && item.species) {
        this.tags.push({
          name: item.species,
          total: 1
        })
      }
      if (flag_type && item.type) {
        this.tags.push({
          name: item.type,
          total: 1
        })
      }
    }
  }
  getPopular() {
    this.itemsPopular = [];
    for (var i = 0; i < 3 && i < this.total; i++) {
      var PopularIndex = Math.floor(Math.random() * (this.total - 1));
      this.itemsPopular.push(this.data[PopularIndex])
    }
  }

  onSearch() {
    this.items = [];
    for (var i = 0; i < this.data.length; i++) {
      var item = this.data[i];
      if (this.nameSearch && this.nameSearch != '') {
        if (item.name.toLowerCase().indexOf(this.nameSearch.toLowerCase()) > -1
          || item.subCategory.toLowerCase().indexOf(this.nameSearch.toLowerCase()) > -1
          || (item.species && item.species.toLowerCase().indexOf(this.nameSearch.toLowerCase()) > -1)
          || (item.element && item.element.toLowerCase().indexOf(this.nameSearch.toLowerCase()) > -1)
          || (item.type && item.type.toLowerCase().indexOf(this.nameSearch.toLowerCase()) > -1)) {
          this.items.push(item);
        }
      }
    }
  }

}
