<div *ngIf="!isSelectRoom">
    <h3>Rooms</h3>
    <div class="list-group">
        <a (click)="joinRoom(r)" *ngFor="let r of rooms" class="list-group-item list-group-item-action">
            {{r}}
        </a>
    </div>
</div>

<div class="cotuong" *ngIf="isSelectRoom">
    <table class="banco">
        <tr *ngFor="let row of ct.data">
            <td *ngFor="let col of row" [ngClass]="ct.isCustomCell(col)">
                <button class="conco_content" [ngClass]="ct.isSelect(col)"
                    (click)="ct.CoUp_SELECTandMOVE(col)" [title]="col.name"></button>
            </td>
        </tr>
    </table>

    <div *ngIf="ct.users">{{ct.users.length}} Online</div>
    <a (click)="backRoom()">Back</a> |
    <span class="name">Hi! {{ct.username}}</span> - <span class="name">Room {{ct.roomId}}</span> - <span class="name">Player
        {{ct.playerName}}</span> |
    <img src="assets/img/default/admin.jpg" width="35" class="rounded-circle" alt="image"> |
    <a routerLink="/login">Logout</a>
    <ul>
        <li *ngFor="let msg of ct.messages.reverse(); index as i" [ngClass]="{'notification-message-unread': i == 0}">
            {{msg.message}}
        </li>
    </ul>
</div>