import { Component, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LISTING } from '../data/listing.data';
declare let $: any;

@Component({
  selector: 'app-footer-custom',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterCustomComponent implements OnInit {

  settings = LISTING.Settings
  location: any;
  routerSubscription: any;

  constructor(private router: Router) {
  }


  ngOnInit(): void {
    // this.recallJsFuntions();
  }

  recallJsFuntions() {
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
      .subscribe(event => {
        $.getScript('../assets/js/main.js');
        this.location = this.router.url;
        if (!(event instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0);
      });
  }

}
