<app-header></app-header>

<div class="page-title-area page-title-bg2">
    <div class="container">
        <div class="page-title-content">
            <h2>Blog Details</h2>
        </div>
    </div>
</div>

<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-image">
                        <img src="assets/img/summonerswar/toa/hell/result.png" alt="completed TOA HELL">
                    </div>
                    <!-- <div class="article-video">
                        <iframe src="https://www.youtube.com/embed/bk7McNUjWgw"></iframe>
                    </div> -->
                    <div class="article-image-slides owl-carousel owl-theme">
                        <div class="article-image">
                            <img src="assets/img/summonerswar/toa/hell/1.png" alt="Level 1">
                        </div>
                        <div class="article-image">
                            <img src="assets/img/summonerswar/toa/hell/2.png" alt="Level 2">
                        </div>
                        <div class="article-image">
                            <img src="assets/img/summonerswar/toa/hell/3.png" alt="Level 3">
                        </div>
                        <div class="article-image">
                            <img src="assets/img/summonerswar/toa/hell/3.png" alt="Level 3">
                        </div>
                        <div class="article-image">
                            <img src="assets/img/summonerswar/toa/hell/4.png" alt="Level 4">
                        </div>
                        <div class="article-image">
                            <img src="assets/img/summonerswar/toa/hell/5.png" alt="Level 5">
                        </div>
                        <div class="article-image">
                            <img src="assets/img/summonerswar/toa/hell/6.png" alt="Level 6">
                        </div>
                        <div class="article-image">
                            <img src="assets/img/summonerswar/toa/hell/7.png" alt="Level 7">
                        </div>
                        <div class="article-image">
                            <img src="assets/img/summonerswar/toa/hell/8.png" alt="Level 8">
                        </div>
                        <div class="article-image">
                            <img src="assets/img/summonerswar/toa/hell/9.png" alt="Level 9">
                        </div>
                        <div class="article-image">
                            <img src="assets/img/summonerswar/toa/hell/10.png" alt="Level 10">
                        </div>

                    </div>

                    <div class="article-content">
                        <div class="entry-meta">
                            <ul>
                                <li>
                                    <i class='bx bx-folder-open'></i>
                                    <span>Category</span>
                                    <a routerLink="/blog-details-one">Summonerswar</a>
                                </li>
                                <li>
                                    <i class='bx bx-group'></i>
                                    <span>View</span>
                                    <a routerLink="/blog-details-one">813,454</a>
                                </li>
                                <li>
                                    <i class='bx bx-calendar'></i>
                                    <span>Last Updated</span>
                                    <a routerLink="/blog-details-one">01/14/2023</a>
                                </li>
                            </ul>
                        </div>

                        <h3>What is Trial of Ascension and Why Farm It?</h3>

                        <p>Trial of Ascension (ToA) is PvE content in Summoners War that is unlocked at lvl 15 - there
                            are two difficulties, ToA Normal (ToAN) and ToA Hard (ToAH).

                            You should start farming ToA by +trying to get as far as you can once you are able to clear
                            GB7 because you get some pretty nice rewards.

                            Once you’re able to clear GB7 your team should be strong enough to at least get your energy
                            back and some free crystals at stage 5 and 10. </p>

                        <blockquote class="wp-block-quote">
                            <ul style="text-align: initial;">
                                <li><strong>PvE</strong> – Player vs Environment (any game combat where you are NOT
                                    fighting other players)</li>
                                <li><strong>CC </strong>- Crowd Control (Freeze, stun, sleep, attack bar reduction,
                                    provoke, Increase skill cooldown, and silence)</li>
                                <li><strong>ATB</strong> - attack bar (attack gauge)</li>
                                <li><strong>AoE</strong> - Area of Effect (affects all enemies or allies)</li>
                                <li><strong>DoT</strong> - Damage over Time (Continuous Damage -&gt; 5% hp reduction,
                                    applies when unit gains a turn)</li>
                                <li><strong>Debuff</strong> - harmful effect</li>
                                <li><strong>Cleanse </strong>– Remove harmful effects</li>
                                <li><strong>Proc</strong> - activation of skill or rune effect</li>
                            </ul>

                            <cite>Hung Nguyen</cite>
                        </blockquote>

                        <p>While clearing ToA you get small rewards for each stage you clear and big rewards every 10 stages. The higher floors you clear the better the 10th stage reward will be. Here is a breakdown of what you get for each stage (Scroll to the right):</p>

                        <ul class="wp-block-gallery columns-3">
                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog/img2.jpg" alt="image">
                                </figure>
                            </li>

                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog/img3.jpg" alt="image">
                                </figure>
                            </li>

                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog/img4.jpg" alt="image">
                                </figure>
                            </li>
                        </ul>

                        <h3>Four major elements that we offer:</h3>

                        <ul class="features-list">
                            <li><i class='bx bx-badge-check'></i> CC : +SPD > 120</li>
                            <li><i class='bx bx-badge-check'></i> CC: +HP > 20.000</li>
                            <li><i class='bx bx-badge-check'></i> TANK: +SPD > 120</li>
                            <li><i class='bx bx-badge-check'></i> TANK: +HP > 20.000</li>
                        </ul>

                        <h3>Setting the mood with incense</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                            sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat
                            voluptatem.</p>

                        <h3>The rise of marketing and why you need it</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                    </div>

                    <div class="article-footer">
                        <div class="article-tags">
                            <span><i class='bx bx-purchase-tag'></i></span>

                            <a routerLink="/blog-one">Fashion</a>,
                            <a routerLink="/blog-one">Games</a>,
                            <a routerLink="/blog-one">Travel</a>
                        </div>

                        <div class="article-share">
                            <ul class="social">
                                <li><span>Share:</span></li>
                                <li><a href="#" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a>
                                </li>
                                <li><a href="#" class="twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" class="linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a>
                                </li>
                                <li><a href="#" class="instagram" target="_blank"><i class='bx bxl-instagram'></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="article-author">
                        <div class="author-profile-header"></div>
                        <div class="author-profile">
                            <div class="author-profile-title">
                                <img src="assets/img/default/admin.jpg" class="shadow-sm" alt="image">

                                <div class="author-profile-title-details">
                                    <div class="author-profile-details">
                                        <h4>Chris Orwig</h4>
                                        <span class="d-block">Photographer, Author, Writer</span>
                                    </div>
                                </div>
                            </div>
                            <p>Chris Orwig is a celebrated photographer, author, and writer who brings passion to
                                everything he does.</p>
                        </div>
                    </div>

                    <div class="louise-post-navigation">
                        <div class="prev-link-wrapper">
                            <div class="info-prev-link-wrapper">
                                <a routerLink="/blog-details-one">
                                    <span class="image-prev">
                                        <img src="assets/img/blog/img5.jpg" alt="image">
                                        <span class="post-nav-title">Prev</span>
                                    </span>

                                    <span class="prev-link-info-wrapper">
                                        <span class="prev-title">28 places to visit in the USA</span>
                                        <span class="meta-wrapper">
                                            <span class="date-post">January 21, 2020</span>
                                        </span>
                                    </span>
                                </a>
                            </div>
                        </div>

                        <div class="next-link-wrapper">
                            <div class="info-next-link-wrapper">
                                <a routerLink="/blog-details-one">
                                    <span class="next-link-info-wrapper">
                                        <span class="next-title">08 things to remember in Morning</span>
                                        <span class="meta-wrapper">
                                            <span class="date-post">January 19, 2020</span>
                                        </span>
                                    </span>

                                    <span class="image-next">
                                        <img src="assets/img/blog/img6.jpg" alt="image">
                                        <span class="post-nav-title">Next</span>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="comments-area">
                        <h3 class="comments-title">2 Comments:</h3>

                        <ol class="comment-list">
                            <li class="comment">
                                <article class="comment-body">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <img src="assets/img/default/admin.jpg" class="avatar" alt="image">
                                            <b class="fn">John Jones</b>
                                            <span class="says">says:</span>
                                        </div>

                                        <div class="comment-metadata">
                                            <a routerLink="/">
                                                <span>April 24, 2019 at 10:59 am</span>
                                            </a>
                                        </div>
                                    </footer>

                                    <div class="comment-content">
                                        <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s,
                                            when an unknown printer took a galley of type and scrambled it to make a
                                            type specimen.</p>
                                    </div>

                                    <div class="reply">
                                        <a routerLink="/blog-details-one" class="comment-reply-link">Reply</a>
                                    </div>
                                </article>

                                <ol class="children">
                                    <li class="comment">
                                        <article class="comment-body">
                                            <footer class="comment-meta">
                                                <div class="comment-author vcard">
                                                    <img src="assets/img/default/admin.jpg" class="avatar" alt="image">
                                                    <b class="fn">Steven Smith</b>
                                                    <span class="says">says:</span>
                                                </div>

                                                <div class="comment-metadata">
                                                    <a routerLink="/">
                                                        <span>April 24, 2019 at 10:59 am</span>
                                                    </a>
                                                </div>
                                            </footer>

                                            <div class="comment-content">
                                                <p>Lorem Ipsum has been the industry’s standard dummy text ever since
                                                    the 1500s, when an unknown printer took a galley of type and
                                                    scrambled it to make a type specimen.</p>
                                            </div>

                                            <div class="reply">
                                                <a routerLink="/blog-details-one" class="comment-reply-link">Reply</a>
                                            </div>
                                        </article>

                                        <ol class="children">
                                            <li class="comment">
                                                <article class="comment-body">
                                                    <footer class="comment-meta">
                                                        <div class="comment-author vcard">
                                                            <img src="assets/img/default/admin.jpg" class="avatar"
                                                                alt="image">
                                                            <b class="fn">Sarah Taylor</b>
                                                            <span class="says">says:</span>
                                                        </div>

                                                        <div class="comment-metadata">
                                                            <a href="#">
                                                                <span>April 24, 2019 at 10:59 am</span>
                                                            </a>
                                                        </div>
                                                    </footer>

                                                    <div class="comment-content">
                                                        <p>Lorem Ipsum has been the industry’s standard dummy text ever
                                                            since the 1500s, when an unknown printer took a galley of
                                                            type and scrambled it to make a type specimen.</p>
                                                    </div>

                                                    <div class="reply">
                                                        <a routerLink="/blog-details-one"
                                                            class="comment-reply-link">Reply</a>
                                                    </div>
                                                </article>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>

                            <li class="comment">
                                <article class="comment-body">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <img src="assets/img/default/admin.jpg" class="avatar" alt="image">
                                            <b class="fn">John Doe</b>
                                            <span class="says">says:</span>
                                        </div>

                                        <div class="comment-metadata">
                                            <a routerLink="/">
                                                <span>April 24, 2019 at 10:59 am</span>
                                            </a>
                                        </div>
                                    </footer>

                                    <div class="comment-content">
                                        <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s,
                                            when an unknown printer took a galley of type and scrambled it to make a
                                            type specimen.</p>
                                    </div>

                                    <div class="reply">
                                        <a routerLink="/blog-details-one" class="comment-reply-link">Reply</a>
                                    </div>
                                </article>

                                <ol class="children">
                                    <li class="comment">
                                        <article class="comment-body">
                                            <footer class="comment-meta">
                                                <div class="comment-author vcard">
                                                    <img src="assets/img/default/admin.jpg" class="avatar" alt="image">
                                                    <b class="fn">Andy Nguyễn</b>
                                                    <span class="says">says:</span>
                                                </div>

                                                <div class="comment-metadata">
                                                    <a routerLink="/">
                                                        <span>April 24, 2019 at 10:59 am</span>
                                                    </a>
                                                </div>
                                            </footer>

                                            <div class="comment-content">
                                                <p>Lorem Ipsum has been the industry’s standard dummy text ever since
                                                    the 1500s, when an unknown printer took a galley of type and
                                                    scrambled it to make a type specimen.</p>
                                            </div>

                                            <div class="reply">
                                                <a routerLink="/blog-details-one" class="comment-reply-link">Reply</a>
                                            </div>
                                        </article>
                                    </li>
                                </ol>
                            </li>
                        </ol>

                        <div class="comment-respond">
                            <h3 class="comment-reply-title">Leave a Reply</h3>

                            <form class="comment-form">
                                <p class="comment-notes">
                                    <span id="email-notes">Your email address will not be published.</span>
                                    Required fields are marked
                                    <span class="required">*</span>
                                </p>
                                <p class="comment-form-author">
                                    <label>Name <span class="required">*</span></label>
                                    <input type="text" id="author" placeholder="Your Name*" name="author"
                                        required="required">
                                </p>
                                <p class="comment-form-email">
                                    <label>Email <span class="required">*</span></label>
                                    <input type="email" id="email" placeholder="Your Email*" name="email"
                                        required="required">
                                </p>
                                <p class="comment-form-url">
                                    <label>Website</label>
                                    <input type="url" id="url" placeholder="Website" name="url">
                                </p>
                                <p class="comment-form-comment">
                                    <label>Comment</label>
                                    <textarea name="comment" id="comment" cols="45" placeholder="Your Comment..."
                                        rows="5" maxlength="65525" required="required"></textarea>
                                </p>
                                <p class="comment-form-cookies-consent">
                                    <input type="checkbox" value="yes" name="wp-comment-cookies-consent"
                                        id="wp-comment-cookies-consent">
                                    <label for="wp-comment-cookies-consent">Save my name, email, and website in this
                                        browser for the next time I comment.</label>
                                </p>
                                <p class="form-submit">
                                    <input type="submit" name="submit" id="submit" class="submit"
                                        value="Post A Comment">
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <section class="widget widget_search">
                        <form class="search-form">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class="bx bx-search-alt"></i></button>
                        </form>
                    </section>

                    <section class="widget widget_louise_posts_thumb">
                        <h3 class="widget-title">Popular Posts</h3>

                        <article class="item">
                            <a routerLink="/blog-details-one" class="thumb">
                                <span class="fullimage cover bg1" role="img"></span>
                            </a>
                            <div class="info">
                                <span>June 10, 2020</span>
                                <h4 class="title usmall"><a routerLink="/blog-details-one">Ultimate Bali Guide + Where
                                        to stay in Bali 2020</a></h4>
                            </div>

                            <div class="clear"></div>
                        </article>

                        <article class="item">
                            <a routerLink="/blog-details-one" class="thumb">
                                <span class="fullimage cover bg2" role="img"></span>
                            </a>
                            <div class="info">
                                <span>June 21, 2020</span>
                                <h4 class="title usmall"><a routerLink="/blog-details-one">Live the Island life: 20
                                        unique Islands to visit in 2020</a></h4>
                            </div>

                            <div class="clear"></div>
                        </article>

                        <article class="item">
                            <a routerLink="/blog-details-one" class="thumb">
                                <span class="fullimage cover bg3" role="img"></span>
                            </a>
                            <div class="info">
                                <span>June 30, 2020</span>
                                <h4 class="title usmall"><a routerLink="/blog-details-one">Best Places to Visit in
                                        Europe this Autumn & Winter</a></h4>
                            </div>

                            <div class="clear"></div>
                        </article>
                    </section>

                    <section class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>

                        <ul>
                            <li><a routerLink="/">Design <span class="post-count">(03)</span></a></li>
                            <li><a routerLink="/">Lifestyle <span class="post-count">(05)</span></a></li>
                            <li><a routerLink="/">Script <span class="post-count">(10)</span></a></li>
                            <li><a routerLink="/">Device <span class="post-count">(08)</span></a></li>
                            <li><a routerLink="/">Tips <span class="post-count">(01)</span></a></li>
                        </ul>
                    </section>

                    <section class="widget widget_tag_cloud">
                        <h3 class="widget-title">Braike Tags</h3>

                        <div class="tagcloud">
                            <a routerLink="/">Business <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/">Design <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/">Braike <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Fashion <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Travel <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Smart <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Marketing <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Tips <span class="tag-link-count"> (2)</span></a>
                        </div>
                    </section>

                    <section class="widget widget_instagram">
                        <h3 class="widget-title">Instagram</h3>

                        <ul>
                            <li>
                                <a href="#" class="d-block">
                                    <img src="assets/img/blog/img1.jpg" alt="image">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="d-block">
                                    <img src="assets/img/blog/img2.jpg" alt="image">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="d-block">
                                    <img src="assets/img/blog/img3.jpg" alt="image">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="d-block">
                                    <img src="assets/img/blog/img4.jpg" alt="image">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="d-block">
                                    <img src="assets/img/blog/img5.jpg" alt="image">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="d-block">
                                    <img src="assets/img/blog/img6.jpg" alt="image">
                                </a>
                            </li>
                        </ul>
                    </section>

                    <section class="widget widget_contact">
                        <div class="text">
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <span>Emergency</span>
                            <a href="tel:+098798768753">+0987-9876-8753</a>
                        </div>
                    </section>
                </aside>
            </div>
        </div>
    </div>
</section>