import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {

  isSubmit = false
  selectStat: any
  runesByFilter = []

  currentSlot: any
  infos: any
  displayedColumns = ['name', 'value'];
  slots = [
    { name: "1", top: 25, left: 40, height: 50, width: 50, label: 'ATK+', stat1: '', stat1Value: '', stat2: '', stat2Value: '', stat3: '', stat3Value: '', stat4: '', stat4Value: '', level: 4 },
    { name: "2", top: 55, left: 20, height: 50, width: 50, label: 'SPD+', stat1: '', stat1Value: '', stat2: '', stat2Value: '', stat3: '', stat3Value: '', stat4: '', stat4Value: '', level: 4 },
    { name: "3", top: 45, left: 90, height: 50, width: 50, label: 'DEF+', stat1: '', stat1Value: '', stat2: '', stat2Value: '', stat3: '', stat3Value: '', stat4: '', stat4Value: '', level: 4 },
    { name: "4", top: 75, left: -35, height: 50, width: 50, label: 'DMG+%', stat1: '', stat1Value: '', stat2: '', stat2Value: '', stat3: '', stat3Value: '', stat4: '', stat4Value: '', level: 5 },
    { name: "5", top: -20, left: -10, height: 50, width: 50, label: 'HP+', stat1: '', stat1Value: '', stat2: '', stat2Value: '', stat3: '', stat3Value: '', stat4: '', stat4Value: '', level: 5 },
    { name: "6", top: -75, left: -85, height: 50, width: 50, label: 'ATK+%', stat1: '', stat1Value: '', stat2: '', stat2Value: '', stat3: '', stat3Value: '', stat4: '', stat4Value: '', level: 5 },
  ]
  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  getRunesBySlot(slotNumber) {
    var arr = [];
    this.currentSlot = slotNumber;
    switch (slotNumber.name) {
      case "1":
        arr = ["ATK+"];
        break;
      case "2":
        arr = ['ATK+', 'ATK+%', 'DEF+', 'DEF+%', 'HP+', 'HP+%', 'SPD+'];
        break;
      case "3":
        arr = ["DEF+"];
        break;
      case "4":
        arr = ['ATK+', 'ATK+%', 'DMG+% ', 'RATE+% ', 'DEF+', 'DEF+%', 'HP+', 'HP+%'];
        break;
      case "5":
        arr = ["HP+"];
        break;
      case "6":
        arr = ['ACC+%', 'ATK+', 'ATK+%', 'DEF+', 'DEF+%', 'HP+', 'HP+%', 'RES+%'];
        break;
      default: arr = [];
    }
    this.runesByFilter = arr;
  }
  getRuneStat(runeName) {
    this.currentSlot.label = runeName;
    this.openDialog()
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogSelectStat, {
      data: this.currentSlot,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.currentSlot.stat1 = result.stat1.value;
        this.currentSlot.stat1Value = result.stat1Value.value;
        this.currentSlot.stat2 = result.stat2.value;
        this.currentSlot.stat2Value = result.stat2Value.value;
        this.currentSlot.stat3 = result.stat3.value;
        this.currentSlot.stat3Value = result.stat3Value.value;
        this.currentSlot.stat4 = result.stat4.value;
        this.currentSlot.stat4Value = result.stat4Value.value;
        this.calculateData();
      }
    });
  }

  calculateData(){
    this.infos = [
      {
        "name": "HP", defaultValue: 1000, unit: "int", addition: 0
      },
      {
        "name": "ATK", defaultValue: 100, unit: "int", addition: 0
      },
      {
        "name": "DEF", defaultValue: 100, unit: "int", addition: 0
      },
      {
        "name": "SPD", defaultValue: 100, unit: "int", addition: 0
      },
  
      {
        "name": "Tỉ lệ CRT", defaultValue: 25, unit: "percent", addition: 0
      },
      {
        "name": "CRI Dmg", defaultValue: 50, unit: "percent", addition: 0
      },
      {
        "name": "Kháng", defaultValue: 30, unit: "percent", addition: 0
      },
      {
        "name": "Chính xác", defaultValue: 30, unit: "percent", addition: 0
      }
    ];
    for(var i = 0; i < this.slots.length; i++){
      var item = this.slots[i];
      this.setAdditionValue(item.stat1, item.stat1Value);
      this.setAdditionValue(item.stat2, item.stat2Value);
      this.setAdditionValue(item.stat3, item.stat3Value);
      this.setAdditionValue(item.stat4, item.stat4Value);
    }
  }
  setAdditionValue(item, value) {
    if(item == "HP+"){
      this.infos[0].addition += value;
    }
    else if(item == "HP+%"){
      this.infos[0].addition += Math.ceil(this.infos[1].defaultValue*(value/100));
    }
    else if(item == "ATK+"){
      this.infos[1].addition += value;
    }
    else if(item == "ATK+%"){
      this.infos[1].addition += Math.ceil(this.infos[1].defaultValue*(value/100));
    }
    else if(item == "DEF+"){
      this.infos[2].addition += value;
    }
    else if(item == "DEF+%"){
      this.infos[2].addition += Math.ceil(this.infos[2].defaultValue*(value/100));
    }
    else if(item == "SPD+"){
      this.infos[3].addition += value;
    }
    else if(item == "CRIRate+%"){
      this.infos[4].addition += Math.ceil(this.infos[4].defaultValue*(value/100));
    }
    else if(item == "CRIDmg+%"){
      this.infos[5].addition += Math.ceil(this.infos[5].defaultValue*(value/100));
    }
    else if(item == "RES+%"){
      this.infos[6].addition += Math.ceil(this.infos[6].defaultValue*(value/100));
    }
    else if(item == "ACC+%"){
      this.infos[7].addition += Math.ceil(this.infos[7].defaultValue*(value/100));
    }
  }
}


@Component({
  selector: 'dialogHTML',
  templateUrl: 'dialog.html',
})
export class DialogSelectStat {

  constructor(public dialogRef: MatDialogRef<DialogSelectStat>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  f: any = {
    // stat1Value: new FormControl(this.data.stat1Value),
    // stat2Value: new FormControl(this.data.stat2Value),
    // stat3Value: new FormControl(this.data.stat3Value),
    // stat4Value: new FormControl(this.data.stat4Value),
    // stat1: new FormControl(this.data.stat1),
    // stat2: new FormControl(this.data.stat2),
    // stat3: new FormControl(this.data.stat3),
    // stat4: new FormControl(this.data.stat4),
  }
  allStats: string[] = ['ACC+%', 'ATK+', 'ATK+%', 'CRIDmg+%', 'CRIRate+%', 'DEF+', 'DEF+%', 'HP+', 'HP+%', 'RES+%', 'SPD+'];
}