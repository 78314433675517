import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
    AuthGuardService as AuthGuard
} from '../services/auth-guard.service';
import { AdminBookingComponent } from './admin-booking/admin-booking.component';
import { AdminBookmarkComponent } from './admin-bookmark/admin-bookmark.component';
import { AdminGamesDetailComponent } from './admin-games-detail/admin-games-detail.component';
import { AdminGamesComponent } from './admin-games/admin-games.component';
import { AdminInvoiceComponent } from './admin-invoice/admin-invoice.component';
import { AdminListingDetailComponent } from './admin-listing-detail/admin-listing-detail.component';
import { AdminListingComponent } from './admin-listing/admin-listing.component';
import { AdminReviewComponent } from './admin-review/admin-review.component';
import { AdminComponent } from './admin.component';
import { AppCalendarComponent } from './components/pages/app-calendar/app-calendar.component';
import { AppEmailComposeComponent } from './components/pages/app-email-compose/app-email-compose.component';
import { AppEmailReadComponent } from './components/pages/app-email-read/app-email-read.component';
import { AppEmailComponent } from './components/pages/app-email/app-email.component';
import { AppMessageComponent } from './components/pages/app-message/app-message.component';
import { AppTodoComponent } from './components/pages/app-todo/app-todo.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { MyProfileComponent } from './components/pages/my-profile/my-profile.component';

const routes: Routes = [
    {
        path: '', component: AdminComponent, title: "MYLIFE | Admin",
        children: [
            { path: '', component: DashboardComponent },
            { path: 'dashboard', component: DashboardComponent },
            { path: 'app-message', component: AppMessageComponent },
            { path: 'app-email', component: AppEmailComponent },
            { path: 'app-email-read', component: AppEmailReadComponent },
            { path: 'app-email-compose', component: AppEmailComposeComponent },
            { path: 'app-todo', component: AppTodoComponent },
            { path: 'app-calendar', component: AppCalendarComponent },
            { path: 'my-profile', component: MyProfileComponent },
            { path: 'games', component: AdminGamesComponent, title: "MYLIFE | ADMIN GAMES" },
            { path: 'games/:id', component: AdminGamesDetailComponent, title: "MYLIFE | ADMIN GAMES" },
            { path: 'invoice', component: AdminInvoiceComponent, title: "MYLIFE | ADMIN INVOICE" },
            { path: 'reviews', component: AdminReviewComponent, title: "MYLIFE | ADMIN REVIEWS" },
            { path: 'listing', component: AdminListingComponent, title: "MYLIFE | ADMIN LISTING"},
            { path: 'listing/:id', component: AdminListingDetailComponent },
            { path: 'bookmarks', component: AdminBookmarkComponent, title: "MYLIFE | ADMIN BOOKMARKS" },
            { path: 'bookings', component: AdminBookingComponent, title: "MYLIFE | ADMIN BOOKINGS"}
        ],
        canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdminRoutingModule { }