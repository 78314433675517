import { AfterViewChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CHATAIDATA } from './chatai.data';

@Component({
  selector: 'app-chat-ai',
  templateUrl: './chat-ai.component.html',
  styleUrls: ['./chat-ai.component.scss']
})
export class ChatAIComponent implements OnInit, AfterViewChecked {

  @ViewChild("#messageContainer") mLoading: ElementRef;

  loading = false;
  textSearch: any
  chats = CHATAIDATA.chatsDefault
  contacts = CHATAIDATA.contactsDefault
  messages = CHATAIDATA.messages
  NoAswers = CHATAIDATA.NoAnswers
  data = CHATAIDATA.Data
  constructor() { }

  ngOnInit(): void {

  }

  ngAfterViewInit() { }

  ngAfterViewChecked() {}

  onSubmit() {
    if (this.textSearch && this.textSearch != '') {

      this.messages.push({
        text: this.textSearch,
        username: "Guest",
        dateTime: Date.now().toString()
      })
      this.loading = true;
      var index = Math.floor(Math.random() * this.NoAswers.length);
      var reply = this.NoAswers[index];
      var anwser = this.data.filter(c => c.answer.toLowerCase().indexOf(this.textSearch.toLowerCase()) > -1);
      if (anwser.length > 0) {
        var indexRes = Math.floor(Math.random() * anwser.length);
        reply = anwser[indexRes].reply;
      }

      setTimeout(() => {
        this.loading = false;
        this.textSearch = "";
        this.messages.push({
          text: reply,
          username: "CHATAI",
          dateTime: Date.now().toString()
        })
      }, 2000);
    }
    else {
      this.loading = false;
    }
  }
}
