<section class="register-area">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div [class]="settings.auth.registerClassBG">
                <img src="assets/img/register-bg.jpg" alt="image">
            </div>
        </div>

        <div class="col-lg-6 col-md-12 p-0">
            <div class="register-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="register-form">
                            <div class="logo">
                                <a routerLink="/"><img [src]="settings.logoblack" alt="image"></a>
                            </div>

                            <h3>Create Your Account Now</h3>
                            <p>Already register? <a routerLink="/login">Login</a></p>

                            <form [formGroup]="registerForm" (click)="register()">
                                <div class="form-group mb-3">
                                    <input type="text" placeholder="Enter Your Name" class="form-control" formControlName="fullname">
                                </div>

                                <div class="form-group mb-3">
                                    <input type="email" placeholder="Enter Your Email" class="form-control" formControlName="email">
                                </div>

                                <div class="form-group mb-3">
                                    <input type="text" placeholder="Enter Username" class="form-control" formControlName="username">
                                </div>

                                <div class="form-group mb-3">
                                    <input type="password" placeholder="Enter Your Password" class="form-control" formControlName="password">
                                </div>

                                <div class="form-group mb-3">
                                    <input type="password" placeholder="Confirm Your Password" class="form-control" formControlName="repassword">
                                </div>

                                <button type="submit" [disabled]="isSubmit">
                                    {{isSubmit ? "Signing Up" : "Signup"}}
                                </button>

                                <div class="alert alert-danger" role="alert" *ngIf="errorMessage && errorMessage != ''">
                                    {{errorMessage}}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>