import { Injectable } from "@angular/core";
import { SocketioService } from "./socketio.service";

@Injectable()
export class CoTuongService {

    DEFAULT: any = {
        "0x4": "bk",
        "0x0": "br", "0x8": "br",
        "0x1": "bn", "0x7": "bn",
        "0x2": "bb", "0x6": "bb",
        "0x3": "ba", "0x5": "ba",
        "2x1": "bc", "2x7": "bc",
        "3x0": "bp", "3x2": "bp", "3x4": "bp", "3x6": "bp", "3x8": "bp",
        "9x4": "rk",
        "9x0": "rr", "9x8": "rr",
        "9x1": "rn", "9x7": "rn",
        "9x2": "rb", "9x6": "rb",
        "9x3": "ra", "9x5": "ra",
        "7x1": "rc", "7x7": "rc",
        "6x0": "rp", "6x2": "rp", "6x4": "rp", "6x6": "rp", "6x8": "rp",
    }
    selected: any
    prevSelected: any
    dataCanMove: any = new Array()
    messages = new Array()
    data: any

    username: any
    roomId: any
    users: any

    turnIs: any
    playerName: any

    constructor(private socketService: SocketioService) {

    }

    init() {
        if (!this.data)
            this.data = this.initBanCo();

        this.username = localStorage.getItem("username");
        this.playerName = localStorage.getItem("playerName");
        this.socketService.setupSocketConnection(this.username, this.roomId);
        this.socketService.subscribeToMessages((err, data) => {
            this.messages = [...this.messages, data];
        });
        this.socketService.subscribeToMessageWithData((err, datasource) => {
            this.data = datasource.data;
            this.prevSelected = datasource.prevSelected;
            this.turnIs = datasource.turnIs;
            localStorage.setItem("datasource", JSON.stringify(datasource))
        });

        this.socketService.subscribeToGetUser((err, res) => {
            this.users = res;
        });
    }

    SENDER = {
        id: "123",
        name: "John Doe",
    };
    socketDisconnect() {
        this.socketService.disconnect();
        this.socketService.subscribeToGetUser((err, res) => {
            this.users = res;
        });
    }
    sendMessage() {
        var message = "Hello User " + Math.floor(Math.random() * 100000000);
        this.socketService.sendMessage({ message, roomName: this.roomId }, cb => {});
        this.messages = [
            ...this.messages,
            {
                message,
                ...this.SENDER,
            },
        ];
    }

    initBanCo() {
        var data = new Array();
        for (var i = 0; i < 10; i++) {
            data[i] = new Array();
            for (var j = 0; j < 9; j++) {
                var name = i + "x" + j;
                var left = '-3em'; var top = '-3em';
                data[i].push({ name: name, status: 1, top: top, left: left, x: i, y: j })
            }
        }
        return data;
    }
    initQuanCoDefault(data: any) {
        for (var i = 0; i < 10; i++) {
            for (var j = 0; j < 9; j++) {
                var name = this.DEFAULT[data[i][j].name];
                data[i][j].img = name;
                if (name)
                    data[i][j].isBlack = name.substring(0, 1) == 'b';
                else
                    data[i][j].isBlack = undefined;
                data[i][j].hide = false;
            }
        }
    }
    initQuanCoHide(data: any) {
        var DEFAULT_B_HIDE = ['br', 'br', 'bb', 'bb', 'bn', 'bn', 'ba', 'ba', 'bc', 'bc', 'bp', 'bp', 'bp', 'bp', 'bp']
        for (var i = 0; i < 10; i++) {
            for (var j = 0; j < 9; j++) {
                if (i == 0 || i == 9) {
                    if (j == 4) continue;
                }
                var name = this.DEFAULT[data[i][j].name];
                if (name) {
                    var nameIndex = Math.floor(Math.random() * DEFAULT_B_HIDE.length);

                    data[i][j].img = DEFAULT_B_HIDE[nameIndex];
                    if (name)
                        data[i][j].isBlack = name.substring(0, 1) == 'b';
                    else
                        data[i][j].isBlack = undefined;
                    DEFAULT_B_HIDE.splice(nameIndex, 1);
                    data[i][j].hide = true;
                }
                if (DEFAULT_B_HIDE.length == 0) {
                    DEFAULT_B_HIDE = ['rr', 'rr', 'rb', 'rb', 'rn', 'rn', 'ra', 'ra', 'rc', 'rc', 'rp', 'rp', 'rp', 'rp', 'rp'];
                }
            }
        }
        data[0][4].img = this.DEFAULT['0x4']; data[0][4].hide = false;
        data[0][4].isBlack = true;
        data[9][4].img = this.DEFAULT['9x4']; data[9][4].hide = false;
        data[9][4].isBlack = false;
    }

    //Mã
    N_Move(x: number, y: number, data: any) {
        var arr = new Array();
        if (x + 2 <= 9 && !data[x + 1][y].img) {
            if (y + 1 <= 8)
                arr.push((x + 2) + "x" + (y + 1));
            if (y - 1 >= 0)
                arr.push((x + 2) + "x" + (y - 1));
        }
        if (x + 1 <= 9) {
            if (y + 2 <= 8 && !data[x][y + 1].img)
                arr.push((x + 1) + "x" + (y + 2));
            if (y - 2 >= 0 && !data[x][y - 1].img)
                arr.push((x + 1) + "x" + (y - 2));
        }
        if (x - 2 >= 0 && !data[x - 1][y].img) {
            if (y + 1 <= 8)
                arr.push((x - 2) + "x" + (y + 1));
            if (y - 1 >= 0)
                arr.push((x - 2) + "x" + (y - 1));
        }
        if (x - 1 >= 0) {
            if (y + 2 <= 8 && !data[x][y + 1].img)
                arr.push((x - 1) + "x" + (y + 2));
            if (y - 2 >= 0 && !data[x][y - 1].img)
                arr.push((x - 1) + "x" + (y - 2));
        }
        return arr;
    }
    //Xe
    R_Move(x: number, y: number, data: any) {
        var arr = new Array();
        for (var j = y + 1; j < 9; j++) {
            arr.push(x + "x" + j)
            if (data[x][j].img) {
                break;
            }
        }
        for (var j = y - 1; j >= 0; j--) {
            arr.push(x + "x" + j)
            if (data[x][j].img) {
                break;
            }
        }
        for (var i = x + 1; i < 10; i++) {
            arr.push(i + "x" + y)
            if (data[i][y].img) {
                break;
            }
        }
        for (var i = x - 1; i >= 0; i--) {
            arr.push(i + "x" + y)
            if (data[i][y].img) {
                break;
            }
        }
        return arr;
    }
    //Tượng
    B_Move(x: number, y: number, isCoUp = false) {
        var arr = new Array();
        if (x + 2 <= 9 && (x + 2 != 6 || isCoUp)) {
            if (y + 2 <= 8) {
                if (!this.data[x + 1][y + 1].img)
                    arr.push((x + 2) + "x" + (y + 2));
            }
            if (y - 2 >= 0) {
                if (!this.data[x + 1][y - 1].img)
                    arr.push((x + 2) + "x" + (y - 2));
            }
        }
        if (x - 2 >= 0 && (x - 2 != 3 || isCoUp)) {
            if (y + 2 <= 8) {
                if (!this.data[x - 1][y + 1].img)
                    arr.push((x - 2) + "x" + (y + 2));
            }
            if (y - 2 >= 0) {
                if (!this.data[x - 1][y - 1].img)
                    arr.push((x - 2) + "x" + (y - 2));
            }
        }
        return arr;
    }
    //Sĩ
    A_Move(x: number, y: number, isCoUp = false) {
        var arr = new Array();
        if (x + 1 <= 9 && (x + 1 != 3 || isCoUp)) {
            if (y + 1 <= 5 || isCoUp)
                arr.push((x + 1) + "x" + (y + 1));
            if (y - 1 >= 3 || isCoUp)
                arr.push((x + 1) + "x" + (y - 1));
        }
        if (x - 1 >= 0 && (x - 1 != 6 || isCoUp)) {
            if (y + 1 <= 5 || isCoUp)
                arr.push((x - 1) + "x" + (y + 1));
            if (y - 1 >= 3 || isCoUp)
                arr.push((x - 1) + "x" + (y - 1));
        }
        return arr;
    }
    //Pháo
    C_Move(x: number, y: number, data: any) {
        var arr = new Array();
        var flag = 0;
        for (var j = y + 1; j < 9; j++) {
            if (data[x][j].img) {
                flag++;
                if (flag == 2) {
                    arr.push(x + "x" + j);
                    break;
                } continue;
            }
            if (flag == 0)
                arr.push(x + "x" + j)
        }
        flag = 0;
        for (var j = y - 1; j >= 0; j--) {
            if (data[x][j].img) {
                flag++;
                if (flag == 2) {
                    arr.push(x + "x" + j)
                    break;
                } continue;
            }
            if (flag == 0)
                arr.push(x + "x" + j)
        }
        flag = 0;
        for (var i = x + 1; i < 10; i++) {
            if (data[i][y].img) {
                flag++;
                if (flag == 2) {
                    arr.push(i + "x" + y)
                    break;
                } continue;
            }
            if (flag == 0)
                arr.push(i + "x" + y)
        }
        flag = 0;
        for (var i = x - 1; i >= 0; i--) {
            if (data[i][y].img) {
                flag++;
                if (flag == 2) {
                    arr.push(i + "x" + y)
                    break;
                } continue;
            }
            if (flag == 0)
                arr.push(i + "x" + y)
        }
        return arr;
    }
    //Tốt
    P_Move(x: number, y: number, selected: any) {
        var arr = new Array();
        var isBlack = selected.img.substring(0, 1) == 'b';
        if (x + 1 <= 9 && isBlack) {
            arr.push((x + 1) + "x" + (y));
        }
        if (x - 1 >= 0 && !isBlack) {
            arr.push((x - 1) + "x" + (y));
        }
        if (y + 1 <= 9) {
            if (isBlack && x > 4)
                arr.push((x) + "x" + (y + 1));
            if (!isBlack && x < 5)
                arr.push((x) + "x" + (y + 1));
        }
        if (y - 1 >= 0) {
            if (isBlack && x > 4)
                arr.push((x) + "x" + (y - 1));
            if (!isBlack && x < 5)
                arr.push((x) + "x" + (y - 1));
        }
        return arr;
    }
    //Tướng
    K_Move(x: number, y: number) {
        var arr = new Array();
        if (x + 1 <= 9 && x != 2) {
            arr.push((x + 1) + "x" + (y));
        }
        if (x - 1 >= 0 && x != 7) {
            arr.push((x - 1) + "x" + (y));
        }
        if (y + 1 < 6) {
            arr.push((x) + "x" + (y + 1));
        }
        if (y - 1 > 2) {
            arr.push((x) + "x" + (y - 1));
        }
        return arr;
    }

    SELECTandMOVE(item: any) {

        var isBlack = (this.selected && this.selected.isBlack) ? true : false;
        var playerName = isBlack == true ? "Black" : (isBlack == false ? "Red" : undefined);
        var message = "Select ";
        if (this.selected) {
            message += (playerName + " " + this.selected.name + ",")
        }

        if (this.selected && (!item.img || this.selected.isBlack != item.isBlack)) {
            var isValid = this.dataCanMove.find((cm: any) => cm == item.name);
            if (this.selected && isValid) {
                this.turnIs = this.selected.isBlack ? "Black" : "Red";
                message += " Moved to " + item.name;
                this.prevSelected = this.selected;
                item.img = this.selected.img;
                item.isBlack = this.selected.isBlack;
                this.data[this.selected.x][this.selected.y].img = undefined;
                this.data[this.selected.x][this.selected.y].isBlack = undefined;
                this.selected = undefined;
                this.dataCanMove = undefined;
                this.socketService.sendMessage({ message, roomName: this.roomId }, cb => {});
                this.socketService.sendData({
                    data: this.data, prevSelected: this.prevSelected,
                    turnIs: this.turnIs, roomName: this.roomId
                }, cb => {});
                this.messages = [
                    ...this.messages,
                    {
                        message,
                        ...this.SENDER,
                    },
                ];
            }
        }
        else {

            var playerNameSelect = item.isBlack ? "Black" : "Red";
            if (!this.playerName) {
                this.playerName = playerNameSelect;
                localStorage.setItem("playerName", playerNameSelect)
            }
            var turnBlack = this.turnIs ? this.turnIs == "Black" : undefined;
            if (item.isBlack == turnBlack || !item.img || (this.playerName && this.playerName != playerNameSelect)) {
                return;
            }

            message += " Selected " + item.name;
            this.selected = item;
            if (item.img == 'rn' || item.img == 'bn') {
                this.dataCanMove = this.N_Move(item.x, item.y, this.data);
            }
            else if (item.img == 'rr' || item.img == 'br') {
                this.dataCanMove = this.R_Move(item.x, item.y, this.data);
            }
            else if (item.img == 'rb' || item.img == 'bb') {
                this.dataCanMove = this.B_Move(item.x, item.y);
            }
            else if (item.img == 'ra' || item.img == 'ba') {
                this.dataCanMove = this.A_Move(item.x, item.y);
            }
            else if (item.img == 'rc' || item.img == 'bc') {
                this.dataCanMove = this.C_Move(item.x, item.y, this.data);
            }
            else if (item.img == 'rp' || item.img == 'bp') {
                this.dataCanMove = this.P_Move(item.x, item.y, this.selected);
            }
            else if (item.img == 'rk' || item.img == 'bk') {
                this.dataCanMove = this.K_Move(item.x, item.y);
            }

            //Valid CanMove
            var dataCanMoveTemp = new Array();
            for (var i = 0; i < this.data.length; i++) {
                var row = this.data[i];
                for (var j = 0; j < row.length; j++) {
                    var col = row[j];
                    if (col.isBlack != this.selected.isBlack) {
                        var isValid = this.dataCanMove.find((cm: any) => cm == col.name);
                        if (isValid) {
                            dataCanMoveTemp.push(col.name);
                        }
                    }
                }
            }
            this.dataCanMove = dataCanMoveTemp;
        }
    }
    CoUp_SELECTandMOVE(item: any) {
        var isBlack = (this.selected && this.selected.isBlack) ? true : false;
        var playerName = isBlack == true ? "Black" : (isBlack == false ? "Red" : undefined);
        var message = "Select ";
        if (this.selected) {
            message += (playerName + " " + this.selected.name + ",")
        }

        if (this.selected && (!item.img || this.selected.isBlack != item.isBlack)) {
            var isValid = this.dataCanMove.find((cm: any) => cm == item.name);
            if (this.selected && isValid) {
                this.selected.hide = false;
                item.hide = false;
                this.turnIs = this.selected.isBlack ? "Black" : "Red";
                message += " Moved to " + item.name;
                this.prevSelected = this.selected;
                item.img = this.selected.img;
                item.isBlack = this.selected.isBlack;
                this.data[this.selected.x][this.selected.y].img = undefined;
                this.data[this.selected.x][this.selected.y].isBlack = undefined;
                this.selected = undefined;
                this.dataCanMove = undefined;
                this.socketService.sendMessage({ message, roomName: this.roomId }, cb => {});
                this.socketService.sendData({
                    data: this.data, prevSelected: this.prevSelected,
                    turnIs: this.turnIs, roomName: this.roomId
                }, cb => {});
                this.messages = [
                    ...this.messages,
                    {
                        message,
                        ...this.SENDER,
                    },
                ];
            }
        }
        else {
            var playerNameSelect = item.isBlack ? "Black" : "Red";
            if (!this.playerName) {
                this.playerName = playerNameSelect;
                localStorage.setItem("playerName", playerNameSelect)
            }
            var turnBlack = this.turnIs ? this.turnIs == "Black" : undefined;
            if (item.isBlack == turnBlack || !item.img || (this.playerName && this.playerName != playerNameSelect)) {
                return;
            }

            message += " Selected " + item.name;
            this.selected = item;
            var itemCheck = this.selected;
            var nameTemp = item.hide ? this.DEFAULT[item.name] : item.img;
            var isCoUp = !item.hide;
            if (nameTemp == 'rn' || nameTemp == 'bn') {//Mã
                this.dataCanMove = this.N_Move(item.x, item.y, this.data);
            }
            else if (nameTemp == 'rr' || nameTemp == 'br') {//Xe
                this.dataCanMove = this.R_Move(item.x, item.y, this.data);
            }
            else if (nameTemp == 'rb' || nameTemp == 'bb') {//Tượng
                this.dataCanMove = this.B_Move(item.x, item.y, isCoUp);
            }
            else if (nameTemp == 'ra' || nameTemp == 'ba') {//Sĩ
                this.dataCanMove = this.A_Move(item.x, item.y, isCoUp);
            }
            else if (nameTemp == 'rc' || nameTemp == 'bc') {//Pháo
                this.dataCanMove = this.C_Move(item.x, item.y, this.data);
            }
            else if (nameTemp == 'rp' || nameTemp == 'bp') {//Tốt
                this.dataCanMove = this.P_Move(item.x, item.y, this.selected);
            }
            else if (nameTemp == 'rk' || nameTemp == 'bk') {//Tướng
                this.dataCanMove = this.K_Move(item.x, item.y);
            }

            //Valid CanMove
            var dataCanMoveTemp = new Array();
            for (var i = 0; i < this.data.length; i++) {
                var row = this.data[i];
                for (var j = 0; j < row.length; j++) {
                    var col = row[j];
                    if (col.isBlack != itemCheck.isBlack) {
                        var isValid = this.dataCanMove.find((cm: any) => cm == col.name);
                        if (isValid) {
                            dataCanMoveTemp.push(col.name);
                        }
                    }
                }
            }
            this.dataCanMove = dataCanMoveTemp;
        }
    }
    isSelect(item: any) {
        var className = "";
        if (this.selected && item.x == this.selected.x && item.y == this.selected.y)
            className += "selected";
        if (this.prevSelected && item.x == this.prevSelected.x && item.y == this.prevSelected.y)
            className += "prevSelected";
        if (item.hide && item.img) {
            className += " cotuong_hide";
        }
        if (!item.hide && item.img) {
            className += " " + item.img;
        }
        if (this.dataCanMove && this.dataCanMove.length > 0) {
            var isCanMove = this.dataCanMove.find((cm: any) => cm == item.name);
            if (isCanMove && isCanMove.length > 0) {
                className += "  canMove";
            }
        }
        return className;
    }
    isCustomCell(item: any) {
        var className = "";
        if (item.x == 4 && item.y != 0 && item.y != 8)
            className += "banco_river";
        else {
            if ((item.x == 0 && item.y == 4) || (item.x == 1 && item.y == 3) || (item.x == 7 && item.y == 4) || (item.x == 8 && item.y == 3))
                className += "banco_cross_1";
            if ((item.x == 0 && item.y == 3) || (item.x == 1 && item.y == 4) || (item.x == 7 && item.y == 3) || (item.x == 8 && item.y == 4))
                className += "banco_cross_2";
        }
        return className;
    }
}