<app-header></app-header>

<div class="page-title-area {{settings.page_title_bg}}">
    <div class="container">
        <div class="page-title-content">
            <h2>Listing</h2>
        </div>
    </div>
</div>

<section class="listing-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="listing-widget-area">
                    <div class="listing-widget filter-list-widget">
                        <h3 class="listing-widget-title">Current Selection</h3>

                        <div class="selected-filters-wrap-list">
                            <ul>
                                <li *ngFor="let item of filters.SubCategories"><a routerLink="/"><i class='bx bx-x'></i> {{item}}</a></li>
                                <li *ngFor="let item of filters.Facilities"><a routerLink="/"><i class='bx bx-x'></i> {{item}}</a></li>
                            </ul>

                            <a routerLink="/" class="delete-selected-filters"><i class='bx bx-trash'></i> <span>Clear All</span></a>
                        </div>
                    </div>

                    <div class="listing-widget facilities-list-widget">
                        <h3 class="listing-widget-title">Facilities</h3>

                        <ul class="facilities-list-row">
                            <li *ngFor="let item of Facilities" [value]="item.name" [ngClass]="{'active': isFacilitiesFilter(item.name)}">
                                <a routerLink="/">{{item.name}}</a>
                            </li>
                        </ul>
                    </div>

                    <div class="listing-widget price-list-widget">
                        <h3 class="listing-widget-title">Price</h3>

                        <div class="collection-filter-by-price">
                            <input class="js-range-of-price" type="text" [attr.data-min]="settings.price_min" [attr.data-max]="settings.price_max"
                                name="filter_by_price" data-step="10">
                        </div>
                    </div>

                    <div class="listing-widget categories-list-widget">
                        <h3 class="listing-widget-title">Categories</h3>

                        <ul class="categories-list-row">
                            <li *ngFor="let item of SubCategories" [value]="item.name" [ngClass]="{'active': isSubCategoriesFilter(item.name)}">
                                <a routerLink="/">{{item.name}}</a></li>
                            <li><a routerLink="/">Others</a></li>
                        </ul>
                    </div>

                    <div class="listing-widget aside-trending-widget">
                        <div class="aside-trending-products">
                            <img [src]="settings.top_trending" alt="image">

                            <div class="category">
                                <h3>Top Listing</h3>
                                <span>Spring/Summer 2022 Collection</span>
                            </div>
                            <a routerLink="/listing" class="link-btn"></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-8 col-md-12" *ngIf="items.length > 0">
                <div class="listing-filter-options">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <p>Showing 1 - {{maxInPage}} of {{items.length}}</p>
                        </div>

                        <div class="col-lg-5">
                            <div class="listing-ordering-list">
                                <select>
                                    <option>Sort by Price: Low to High</option>
                                    <option>Default Sorting</option>
                                    <option>Sort by Popularity</option>
                                    <option>Sort by Average Rating</option>
                                    <option>Sort by Latest</option>
                                    <option>Sort by Price: High to Low</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12 col-md-12" *ngFor="let item of items">
                        <div class="single-listing-box">
                            <a [routerLink]="['/listing/' + item._id]" class="listing-image">
                                <img [src]="item.img" alt="image">
                            </a>

                            <div class="listing-badge">Open Now</div>

                            <div class="listing-content">
                                <div class="content">
                                    <div class="author">
                                        <img src="assets/img/default/admin.jpg" alt="image">
                                        <span>Andy Nguyễn</span>
                                    </div>

                                    <h3><a [routerLink]="['/listing/' + item._id]">{{item.name}}</a></h3>
                                    <span class="location"><i class='bx bx-map'></i> {{item.address}}</span>

                                    <div class="rating">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <span class="rating-count">2 reviews</span>
                                    </div>
                                </div>

                                <div class="footer-content">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="listing-option-list">
                                            <a routerLink="/" title="Find Directions">
                                                <i class='bx bx-directions'></i>
                                            </a>
                                            <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                            <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                                        </div>

                                        <div class="price-level">
                                            <span data-bs-toggle="tooltip" data-placement="top" title="Ultra Hight">
                                                <strong>$</strong>
                                                <strong>$</strong>
                                                <strong>$</strong>
                                                <strong>$</strong>
                                            </span>
                                        </div>

                                        <div class="listing-category">
                                            <a routerLink="/" data-bs-toggle="tooltip" data-placement="top"
                                                title="Category">
                                                <i [class]='item.subCategory_Icon'></i>
                                                {{item.subCategory}}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="pagination-area text-center">
                            <a routerLink="/listing-one" class="prev page-numbers"><i
                                    class='bx bx-chevron-left'></i></a>
                            <span class="page-numbers current" aria-current="page">1</span>
                            <a routerLink="/listing-one" class="page-numbers">2</a>
                            <a routerLink="/listing-one" class="page-numbers">3</a>
                            <a routerLink="/listing-one" class="page-numbers">4</a>
                            <a routerLink="/listing-one" class="page-numbers">5</a>
                            <a routerLink="/listing-one" class="next page-numbers"><i
                                    class='bx bx-chevron-right'></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 col-md-12" *ngIf="items.length == 0">
                <div class="row">
                    <div class="col-lg-12 col-md-12">No records</div>
                </div>
            </div>
        </div>
    </div>
</section>