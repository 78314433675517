export const TSONLINENPC_PART1 = [
    {
        "idnpc": 10271,
        "idmap": 10963,
        "ten_npc": "Đại võ quan",
        "level": 0,
        "hp": 20,
        "sp": 60,
        "hpx": 0,
        "spx": 0,
        "int": 0,
        "atk": 0,
        "def": 0,
        "agi": 0,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11219,
        "idmap": 11031,
        "thanh": "Thanh Châu",
        "ten_npc": "Đặng Ngải",
        "level": 0,
        "hp": 20,
        "sp": 60,
        "hpx": 0,
        "spx": 0,
        "int": 0,
        "atk": 0,
        "def": 0,
        "agi": 0,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11220,
        "idmap": 11031,
        "thanh": "Thanh Châu",
        "ten_npc": "Chung Hội",
        "level": 0,
        "hp": 20,
        "sp": 60,
        "hpx": 0,
        "spx": 0,
        "int": 0,
        "atk": 0,
        "def": 0,
        "agi": 0,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12128,
        "idmap": 11423,
        "ten_map": "Lang Ta Cung",
        "thanh": "Thanh Châu",
        "ten_npc": "Bàng Thống",
        "level": 0,
        "hp": 20,
        "sp": 60,
        "hpx": 0,
        "spx": 0,
        "int": 0,
        "atk": 0,
        "def": 0,
        "agi": 0,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12177,
        "idmap": 11502,
        "ten_map": "Son Dong Thai Son 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Hồn ma",
        "level": 0,
        "hp": 20,
        "sp": 60,
        "hpx": 0,
        "spx": 0,
        "int": 0,
        "atk": 0,
        "def": 0,
        "agi": 0,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12179,
        "idmap": 11502,
        "ten_map": "Son Dong Thai Son 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Đại võ quan",
        "level": 0,
        "hp": 20,
        "sp": 60,
        "hpx": 0,
        "spx": 0,
        "int": 0,
        "atk": 0,
        "def": 0,
        "agi": 0,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13154,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Đại Võ Quan",
        "level": 0,
        "hp": 20,
        "sp": 60,
        "hpx": 0,
        "spx": 0,
        "int": 0,
        "atk": 0,
        "def": 0,
        "agi": 0,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10037,
        "idmap": 10806,
        "ten_npc": "Binh Đạo Ma",
        "level": 1,
        "hp": 23,
        "sp": 63,
        "hpx": 0,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 32002,
        "item2": 32002,
        "item3": 26002,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11206,
        "idmap": 11031,
        "thanh": "Thanh Châu",
        "ten_npc": "VuCấm C.Thuyền",
        "level": 1,
        "hp": 26,
        "sp": 61,
        "hpx": 0,
        "spx": 0,
        "int": 0,
        "atk": 0,
        "def": 0,
        "agi": 0,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11207,
        "idmap": 11031,
        "thanh": "Thanh Châu",
        "ten_npc": "BàngĐứcCThuyền",
        "level": 1,
        "hp": 26,
        "sp": 61,
        "hpx": 0,
        "spx": 0,
        "int": 0,
        "atk": 0,
        "def": 0,
        "agi": 0,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12034,
        "idmap": 11043,
        "thanh": "Thanh Châu",
        "ten_npc": "Lưu Thuyền",
        "level": 1,
        "hp": 43,
        "sp": 42,
        "hpx": 1,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14127,
        "idmap": 11584,
        "thanh": "Thanh Châu",
        "ten_npc": "Độc Hành Đạo",
        "level": 1,
        "hp": 24,
        "sp": 63,
        "hpx": 1,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 0,
        "agi": 0,
        "item1": 26157,
        "item2": 26157,
        "item3": 26033,
        "item4": 0,
        "item5": 43078,
        "item6": 0
    },
    {
        "idnpc": 14129,
        "idmap": 11584,
        "thanh": "Thanh Châu",
        "ten_npc": "Hưởng Mã",
        "level": 1,
        "hp": 20,
        "sp": 63,
        "hpx": 1,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 0,
        "item1": 26157,
        "item2": 26157,
        "item3": 26033,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15051,
        "idmap": 12073,
        "thanh": "U Châu",
        "ten_npc": "Loạn Dân",
        "level": 1,
        "hp": 19,
        "sp": 63,
        "hpx": 1,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 2,
        "agi": 0,
        "item1": 26001,
        "item2": 26001,
        "item3": 26002,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15053,
        "idmap": 12081,
        "ten_map": "Thanh Chan Dinh 1",
        "thanh": "U Châu",
        "ten_npc": "Thôn Dân Ác Dữ",
        "level": 1,
        "hp": 22,
        "sp": 63,
        "hpx": 1,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 0,
        "item1": 26001,
        "item2": 26001,
        "item3": 26002,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15428,
        "idmap": 12408,
        "thanh": "U Châu",
        "ten_npc": "Cônhichiếnhoả",
        "level": 1,
        "hp": 23,
        "sp": 62,
        "hpx": 0,
        "spx": 0,
        "int": 0,
        "atk": 0,
        "def": 0,
        "agi": 0,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15429,
        "idmap": 12408,
        "thanh": "U Châu",
        "ten_npc": "Cônhichiếnhoả",
        "level": 1,
        "hp": 23,
        "sp": 62,
        "hpx": 0,
        "spx": 0,
        "int": 0,
        "atk": 0,
        "def": 0,
        "agi": 0,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17002,
        "idmap": 12512,
        "thanh": "U Châu",
        "ten_npc": "Lính",
        "level": 1,
        "hp": 25,
        "sp": 63,
        "hpx": 1,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 0,
        "item1": 26002,
        "item2": 27035,
        "item3": 26162,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10038,
        "idmap": 10806,
        "ten_npc": "Binh Khăn Vàng",
        "level": 2,
        "hp": 25,
        "sp": 64,
        "hpx": 0,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 26001,
        "item2": 26001,
        "item3": 27035,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12124,
        "idmap": 11411,
        "ten_map": "Khuc Trac Khong Mieu",
        "thanh": "Thanh Châu",
        "ten_npc": "A Đẩu",
        "level": 2,
        "hp": 27,
        "sp": 65,
        "hpx": 1,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14110,
        "idmap": 11584,
        "thanh": "Thanh Châu",
        "ten_npc": "Gia Đinh Ác",
        "level": 2,
        "hp": 27,
        "sp": 65,
        "hpx": 1,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 27065,
        "item2": 27035,
        "item3": 26064,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14128,
        "idmap": 11584,
        "thanh": "Thanh Châu",
        "ten_npc": "Dâm Tặc",
        "level": 2,
        "hp": 27,
        "sp": 64,
        "hpx": 1,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 2,
        "agi": 0,
        "item1": 26157,
        "item2": 26157,
        "item3": 26033,
        "item4": 0,
        "item5": 46018,
        "item6": 0
    },
    {
        "idnpc": 17003,
        "idmap": 12512,
        "thanh": "U Châu",
        "ten_npc": "Hộ Vệ",
        "level": 2,
        "hp": 27,
        "sp": 65,
        "hpx": 1,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17005,
        "idmap": 12512,
        "thanh": "U Châu",
        "ten_npc": "Vệ Binh",
        "level": 2,
        "hp": 27,
        "sp": 64,
        "hpx": 1,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 26016,
        "item2": 26016,
        "item3": 26080,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17020,
        "idmap": 12514,
        "thanh": "U Châu",
        "ten_npc": "Dâu Châu Binh",
        "level": 2,
        "hp": 27,
        "sp": 64,
        "hpx": 1,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 26002,
        "item2": 26016,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17021,
        "idmap": 12514,
        "thanh": "U Châu",
        "ten_npc": "Trác Quận Binh",
        "level": 2,
        "hp": 27,
        "sp": 64,
        "hpx": 1,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 26001,
        "item2": 26016,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10036,
        "idmap": 10806,
        "ten_npc": "Lính Khăn Vàng",
        "level": 3,
        "hp": 31,
        "sp": 66,
        "hpx": 1,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 26003,
        "item2": 27022,
        "item3": 26002,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14093,
        "idmap": 11583,
        "thanh": "Thanh Châu",
        "ten_npc": "Tô Song",
        "level": 3,
        "hp": 35,
        "sp": 66,
        "hpx": 1,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14126,
        "idmap": 11584,
        "thanh": "Thanh Châu",
        "ten_npc": "Gi.Dang ĐạiĐạo",
        "level": 3,
        "hp": 33,
        "sp": 66,
        "hpx": 2,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 2,
        "agi": 0,
        "item1": 26157,
        "item2": 26157,
        "item3": 26033,
        "item4": 0,
        "item5": 42004,
        "item6": 0
    },
    {
        "idnpc": 14259,
        "idmap": 12000,
        "ten_map": "U Chau",
        "thanh": "U Châu",
        "ten_npc": "Đại Phu",
        "level": 3,
        "hp": 31,
        "sp": 66,
        "hpx": 1,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15017,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "A Ma",
        "level": 3,
        "hp": 31,
        "sp": 66,
        "hpx": 1,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15081,
        "idmap": 12091,
        "ten_map": "Dai Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Đại Ngốc",
        "level": 3,
        "hp": 31,
        "sp": 66,
        "hpx": 1,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15133,
        "idmap": 12094,
        "ten_map": "BacThangKhong Tuoc1",
        "thanh": "U Châu",
        "ten_npc": "Dì Trần",
        "level": 3,
        "hp": 31,
        "sp": 66,
        "hpx": 1,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17004,
        "idmap": 12512,
        "thanh": "U Châu",
        "ten_npc": "Vệ Sĩ",
        "level": 3,
        "hp": 33,
        "sp": 66,
        "hpx": 2,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 26098,
        "item2": 26098,
        "item3": 26016,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17006,
        "idmap": 12512,
        "thanh": "U Châu",
        "ten_npc": "Dung Binh",
        "level": 3,
        "hp": 31,
        "sp": 67,
        "hpx": 1,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 26080,
        "item2": 26033,
        "item3": 26064,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17022,
        "idmap": 12514,
        "thanh": "U Châu",
        "ten_npc": "Đào Binh",
        "level": 3,
        "hp": 31,
        "sp": 66,
        "hpx": 1,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 26041,
        "item2": 26041,
        "item3": 26016,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10081,
        "idmap": 10813,
        "ten_npc": "Lính Cô Hồn",
        "level": 4,
        "hp": 35,
        "sp": 68,
        "hpx": 2,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 26033,
        "item2": 26033,
        "item3": 26057,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14112,
        "idmap": 11584,
        "thanh": "Thanh Châu",
        "ten_npc": "Đốc Du Ác",
        "level": 4,
        "hp": 32,
        "sp": 67,
        "hpx": 1,
        "spx": 1,
        "int": 1,
        "atk": 2,
        "def": 1,
        "agi": 1,
        "item1": 27065,
        "item2": 27034,
        "item3": 26051,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14124,
        "idmap": 11584,
        "thanh": "Thanh Châu",
        "ten_npc": "Kiều Phụ",
        "level": 4,
        "hp": 39,
        "sp": 68,
        "hpx": 1,
        "spx": 1,
        "int": 1,
        "atk": 3,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14131,
        "idmap": 11584,
        "thanh": "Thanh Châu",
        "ten_npc": "Sơn Tặc",
        "level": 4,
        "hp": 35,
        "sp": 68,
        "hpx": 2,
        "spx": 1,
        "int": 2,
        "atk": 1,
        "def": 2,
        "agi": 1,
        "item1": 26039,
        "item2": 26039,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14153,
        "idmap": 11586,
        "thanh": "Thanh Châu",
        "ten_npc": "Tiểu Thắng",
        "level": 4,
        "hp": 35,
        "sp": 68,
        "hpx": 2,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14201,
        "idmap": 11811,
        "ten_map": "Rung Don Khuu",
        "thanh": "Thanh Châu",
        "ten_npc": "Lưu Trưởng Quý",
        "level": 4,
        "hp": 35,
        "sp": 68,
        "hpx": 2,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15004,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Lưu Quả Phụ",
        "level": 4,
        "hp": 36,
        "sp": 68,
        "hpx": 2,
        "spx": 1,
        "int": 2,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15014,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Mã cô nương",
        "level": 4,
        "hp": 35,
        "sp": 68,
        "hpx": 2,
        "spx": 1,
        "int": 2,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15018,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Tiểu Cửu Tử",
        "level": 4,
        "hp": 35,
        "sp": 68,
        "hpx": 2,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15028,
        "idmap": 12071,
        "ten_map": "Thanh Dai Huyen 1",
        "thanh": "U Châu",
        "ten_npc": "Lý Lang",
        "level": 4,
        "hp": 36,
        "sp": 68,
        "hpx": 2,
        "spx": 1,
        "int": 2,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15032,
        "idmap": 12071,
        "ten_map": "Thanh Dai Huyen 1",
        "thanh": "U Châu",
        "ten_npc": "Tiểu Túc Tử",
        "level": 4,
        "hp": 36,
        "sp": 71,
        "hpx": 2,
        "spx": 2,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15042,
        "idmap": 12071,
        "ten_map": "Thanh Dai Huyen 1",
        "thanh": "U Châu",
        "ten_npc": "Tiểu Đỗ",
        "level": 4,
        "hp": 33,
        "sp": 68,
        "hpx": 1,
        "spx": 1,
        "int": 1,
        "atk": 3,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15045,
        "idmap": 12071,
        "ten_map": "Thanh Dai Huyen 1",
        "thanh": "U Châu",
        "ten_npc": "Lữo Nhạc Sư",
        "level": 4,
        "hp": 33,
        "sp": 68,
        "hpx": 1,
        "spx": 1,
        "int": 2,
        "atk": 2,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15047,
        "idmap": 12071,
        "ten_map": "Thanh Dai Huyen 1",
        "thanh": "U Châu",
        "ten_npc": "Ngô Tiểu Muội",
        "level": 4,
        "hp": 33,
        "sp": 68,
        "hpx": 1,
        "spx": 1,
        "int": 1,
        "atk": 2,
        "def": 1,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15080,
        "idmap": 12091,
        "ten_map": "Dai Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Ngưu Đại Ma",
        "level": 4,
        "hp": 35,
        "sp": 68,
        "hpx": 2,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15094,
        "idmap": 12092,
        "ten_map": "ThongDo Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Hổ Cang Đường",
        "level": 4,
        "hp": 35,
        "sp": 68,
        "hpx": 2,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15123,
        "idmap": 12093,
        "ten_map": "DaiDien Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Độc Cư Lữo Bác",
        "level": 4,
        "hp": 35,
        "sp": 68,
        "hpx": 2,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15196,
        "idmap": 12106,
        "ten_map": "P.Tao Phi Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Tăng Tiểu Đệ",
        "level": 4,
        "hp": 35,
        "sp": 68,
        "hpx": 2,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15350,
        "idmap": 12304,
        "ten_map": "Quan Phu TQ",
        "thanh": "U Châu",
        "ten_npc": "Xe em bé",
        "level": 4,
        "hp": 35,
        "sp": 68,
        "hpx": 2,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17011,
        "idmap": 12513,
        "thanh": "U Châu",
        "ten_npc": "Binh",
        "level": 4,
        "hp": 35,
        "sp": 68,
        "hpx": 2,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 26041,
        "item2": 27035,
        "item3": 26080,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17023,
        "idmap": 12514,
        "thanh": "U Châu",
        "ten_npc": "Đào Binh",
        "level": 4,
        "hp": 35,
        "sp": 68,
        "hpx": 2,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 2,
        "agi": 1,
        "item1": 26157,
        "item2": 26157,
        "item3": 26041,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10042,
        "idmap": 10807,
        "ten_npc": "Vệ Binh K.Vàng",
        "level": 5,
        "hp": 38,
        "sp": 70,
        "hpx": 2,
        "spx": 1,
        "int": 1,
        "atk": 3,
        "def": 2,
        "agi": 1,
        "item1": 26001,
        "item2": 27022,
        "item3": 26002,
        "item4": 20737,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11211,
        "idmap": 11031,
        "thanh": "Thanh Châu",
        "ten_npc": "Biện Vương Hậu",
        "level": 5,
        "hp": 38,
        "sp": 74,
        "hpx": 2,
        "spx": 1,
        "int": 4,
        "atk": 1,
        "def": 2,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12175,
        "idmap": 11502,
        "ten_map": "Son Dong Thai Son 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Mẹ Khương Duy",
        "level": 5,
        "hp": 38,
        "sp": 74,
        "hpx": 2,
        "spx": 1,
        "int": 2,
        "atk": 2,
        "def": 2,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14114,
        "idmap": 11584,
        "thanh": "Thanh Châu",
        "ten_npc": "Mã Phu",
        "level": 5,
        "hp": 35,
        "sp": 69,
        "hpx": 1,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14202,
        "idmap": 11811,
        "ten_map": "Rung Don Khuu",
        "thanh": "Thanh Châu",
        "ten_npc": "Trịnh Đại bác",
        "level": 5,
        "hp": 38,
        "sp": 70,
        "hpx": 2,
        "spx": 1,
        "int": 1,
        "atk": 3,
        "def": 2,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14260,
        "idmap": 12000,
        "ten_map": "U Chau",
        "thanh": "U Châu",
        "ten_npc": "Đại Phu",
        "level": 5,
        "hp": 38,
        "sp": 70,
        "hpx": 2,
        "spx": 1,
        "int": 3,
        "atk": 1,
        "def": 1,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15008,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Khúc Lữo Ma Ma",
        "level": 5,
        "hp": 38,
        "sp": 70,
        "hpx": 2,
        "spx": 1,
        "int": 3,
        "atk": 1,
        "def": 1,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15016,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Bác Vương",
        "level": 5,
        "hp": 38,
        "sp": 70,
        "hpx": 2,
        "spx": 1,
        "int": 2,
        "atk": 1,
        "def": 3,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15020,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Điền.V.Ngoại",
        "level": 5,
        "hp": 38,
        "sp": 70,
        "hpx": 2,
        "spx": 1,
        "int": 2,
        "atk": 3,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15041,
        "idmap": 12071,
        "ten_map": "Thanh Dai Huyen 1",
        "thanh": "U Châu",
        "ten_npc": "Phạm Tiên Sinh",
        "level": 5,
        "hp": 38,
        "sp": 72,
        "hpx": 2,
        "spx": 2,
        "int": 3,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15043,
        "idmap": 12071,
        "ten_map": "Thanh Dai Huyen 1",
        "thanh": "U Châu",
        "ten_npc": "Hầu Đại Ma",
        "level": 5,
        "hp": 35,
        "sp": 69,
        "hpx": 1,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 4,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15046,
        "idmap": 12071,
        "ten_map": "Thanh Dai Huyen 1",
        "thanh": "U Châu",
        "ten_npc": "Mẹ Thái Sử Từ",
        "level": 5,
        "hp": 35,
        "sp": 69,
        "hpx": 1,
        "spx": 1,
        "int": 4,
        "atk": 2,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15093,
        "idmap": 12091,
        "ten_map": "Dai Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Tiểu Anh",
        "level": 5,
        "hp": 38,
        "sp": 70,
        "hpx": 2,
        "spx": 1,
        "int": 2,
        "atk": 2,
        "def": 2,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15095,
        "idmap": 12092,
        "ten_map": "ThongDo Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Đỗ Bình",
        "level": 5,
        "hp": 38,
        "sp": 70,
        "hpx": 2,
        "spx": 1,
        "int": 3,
        "atk": 1,
        "def": 1,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15109,
        "idmap": 12093,
        "ten_map": "DaiDien Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Chị Thẩm",
        "level": 5,
        "hp": 38,
        "sp": 70,
        "hpx": 2,
        "spx": 1,
        "int": 2,
        "atk": 3,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15118,
        "idmap": 12093,
        "ten_map": "DaiDien Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Tiếp Tân",
        "level": 5,
        "hp": 38,
        "sp": 71,
        "hpx": 2,
        "spx": 1,
        "int": 4,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15135,
        "idmap": 12094,
        "ten_map": "BacThangKhong Tuoc1",
        "thanh": "U Châu",
        "ten_npc": "Tiểu Đao",
        "level": 5,
        "hp": 38,
        "sp": 70,
        "hpx": 2,
        "spx": 1,
        "int": 1,
        "atk": 3,
        "def": 2,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15197,
        "idmap": 12107,
        "ten_map": "P.Quach Nu Hoang",
        "thanh": "U Châu",
        "ten_npc": "Chu Tiểu Muội",
        "level": 5,
        "hp": 38,
        "sp": 70,
        "hpx": 2,
        "spx": 1,
        "int": 2,
        "atk": 1,
        "def": 2,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15411,
        "idmap": 12407,
        "thanh": "U Châu",
        "ten_npc": "Chúc Đại Trùng",
        "level": 5,
        "hp": 38,
        "sp": 76,
        "hpx": 2,
        "spx": 2,
        "int": 1,
        "atk": 4,
        "def": 0,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15438,
        "idmap": 12409,
        "thanh": "U Châu",
        "ten_npc": "Thiết lão lão",
        "level": 5,
        "hp": 61,
        "sp": 74,
        "hpx": 2,
        "spx": 1,
        "int": 4,
        "atk": 2,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15439,
        "idmap": 12409,
        "thanh": "U Châu",
        "ten_npc": "Thạch lão lão",
        "level": 5,
        "hp": 61,
        "sp": 74,
        "hpx": 2,
        "spx": 1,
        "int": 4,
        "atk": 2,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15455,
        "idmap": 12409,
        "thanh": "U Châu",
        "ten_npc": "Bà mối",
        "level": 5,
        "hp": 56,
        "sp": 74,
        "hpx": 2,
        "spx": 1,
        "int": 4,
        "atk": 0,
        "def": 0,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16089,
        "idmap": 12508,
        "thanh": "U Châu",
        "ten_npc": "Thương nhân tb",
        "level": 5,
        "hp": 38,
        "sp": 70,
        "hpx": 2,
        "spx": 1,
        "int": 0,
        "atk": 4,
        "def": 0,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17007,
        "idmap": 12512,
        "thanh": "U Châu",
        "ten_npc": "Tiểu Lính",
        "level": 5,
        "hp": 35,
        "sp": 69,
        "hpx": 1,
        "spx": 1,
        "int": 2,
        "atk": 3,
        "def": 2,
        "agi": 1,
        "item1": 26164,
        "item2": 26164,
        "item3": 26157,
        "item4": 27003,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14096,
        "idmap": 11583,
        "thanh": "Thanh Châu",
        "ten_npc": "Cửu Sỏi",
        "level": 6,
        "hp": 47,
        "sp": 74,
        "hpx": 1,
        "spx": 2,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 48007,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14105,
        "idmap": 11583,
        "thanh": "Thanh Châu",
        "ten_npc": "Tống Nhân",
        "level": 6,
        "hp": 38,
        "sp": 83,
        "hpx": 1,
        "spx": 5,
        "int": 4,
        "atk": 1,
        "def": 2,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14106,
        "idmap": 11583,
        "thanh": "Thanh Châu",
        "ten_npc": "Tống Dũng",
        "level": 6,
        "hp": 48,
        "sp": 71,
        "hpx": 4,
        "spx": 1,
        "int": 1,
        "atk": 4,
        "def": 3,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14109,
        "idmap": 11584,
        "thanh": "Thanh Châu",
        "ten_npc": "Quách Dịch",
        "level": 6,
        "hp": 44,
        "sp": 69,
        "hpx": 3,
        "spx": 1,
        "int": 2,
        "atk": 3,
        "def": 1,
        "agi": 3,
        "item1": 27065,
        "item2": 27035,
        "item3": 26064,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14577,
        "idmap": 12032,
        "thanh": "U Châu",
        "ten_npc": "Lô Thực",
        "level": 6,
        "hp": 43,
        "sp": 77,
        "hpx": 2,
        "spx": 3,
        "int": 3,
        "atk": 1,
        "def": 2,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14578,
        "idmap": 12041,
        "ten_map": "Thon Thuong Son",
        "thanh": "U Châu",
        "ten_npc": "Đổng Trác",
        "level": 6,
        "hp": 43,
        "sp": 74,
        "hpx": 2,
        "spx": 2,
        "int": 1,
        "atk": 3,
        "def": 2,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15002,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Tiểu Ngạc",
        "level": 6,
        "hp": 55,
        "sp": 72,
        "hpx": 2,
        "spx": 2,
        "int": 2,
        "atk": 1,
        "def": 2,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15023,
        "idmap": 12065,
        "ten_map": "Hem Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Bà Lữo Trịnh",
        "level": 6,
        "hp": 43,
        "sp": 73,
        "hpx": 2,
        "spx": 2,
        "int": 3,
        "atk": 2,
        "def": 1,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15024,
        "idmap": 12065,
        "ten_map": "Hem Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "A Hổ",
        "level": 6,
        "hp": 43,
        "sp": 72,
        "hpx": 2,
        "spx": 2,
        "int": 1,
        "atk": 3,
        "def": 1,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15031,
        "idmap": 12071,
        "ten_map": "Thanh Dai Huyen 1",
        "thanh": "U Châu",
        "ten_npc": "Bác Lổ",
        "level": 6,
        "hp": 44,
        "sp": 74,
        "hpx": 3,
        "spx": 2,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15036,
        "idmap": 12071,
        "ten_map": "Thanh Dai Huyen 1",
        "thanh": "U Châu",
        "ten_npc": "Bà Lữo Mạnh",
        "level": 6,
        "hp": 41,
        "sp": 74,
        "hpx": 2,
        "spx": 2,
        "int": 2,
        "atk": 1,
        "def": 4,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15039,
        "idmap": 12071,
        "ten_map": "Thanh Dai Huyen 1",
        "thanh": "U Châu",
        "ten_npc": "Pác Lang",
        "level": 6,
        "hp": 41,
        "sp": 74,
        "hpx": 2,
        "spx": 2,
        "int": 3,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15083,
        "idmap": 12091,
        "ten_map": "Dai Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Chiêm Cường",
        "level": 6,
        "hp": 43,
        "sp": 73,
        "hpx": 2,
        "spx": 2,
        "int": 4,
        "atk": 1,
        "def": 1,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15103,
        "idmap": 12093,
        "ten_map": "DaiDien Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Thương Binh",
        "level": 6,
        "hp": 43,
        "sp": 72,
        "hpx": 2,
        "spx": 2,
        "int": 2,
        "atk": 4,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15113,
        "idmap": 12093,
        "ten_map": "DaiDien Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Lý Phụ",
        "level": 6,
        "hp": 43,
        "sp": 73,
        "hpx": 2,
        "spx": 2,
        "int": 3,
        "atk": 1,
        "def": 1,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15195,
        "idmap": 12106,
        "ten_map": "P.Tao Phi Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Dì Giặt Đồ",
        "level": 6,
        "hp": 44,
        "sp": 71,
        "hpx": 3,
        "spx": 1,
        "int": 2,
        "atk": 2,
        "def": 3,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15427,
        "idmap": 12408,
        "thanh": "U Châu",
        "ten_npc": "Ngườihoáduyên",
        "level": 6,
        "hp": 43,
        "sp": 75,
        "hpx": 2,
        "spx": 1,
        "int": 5,
        "atk": 0,
        "def": 6,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15440,
        "idmap": 12409,
        "thanh": "U Châu",
        "ten_npc": "My tiểu muội",
        "level": 6,
        "hp": 44,
        "sp": 71,
        "hpx": 3,
        "spx": 1,
        "int": 3,
        "atk": 1,
        "def": 1,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16095,
        "idmap": 12511,
        "thanh": "U Châu",
        "ten_npc": "Địa chủ du mục",
        "level": 6,
        "hp": 43,
        "sp": 72,
        "hpx": 2,
        "spx": 1,
        "int": 0,
        "atk": 4,
        "def": 1,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17001,
        "idmap": 12511,
        "thanh": "U Châu",
        "ten_npc": "Quân Áo Giáp",
        "level": 6,
        "hp": 44,
        "sp": 71,
        "hpx": 3,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 5,
        "agi": 1,
        "item1": 26098,
        "item2": 26098,
        "item3": 26080,
        "item4": 27003,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17012,
        "idmap": 12513,
        "thanh": "U Châu",
        "ten_npc": "Tiểu Binh",
        "level": 6,
        "hp": 44,
        "sp": 71,
        "hpx": 3,
        "spx": 1,
        "int": 1,
        "atk": 3,
        "def": 2,
        "agi": 2,
        "item1": 27040,
        "item2": 27034,
        "item3": 26019,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17013,
        "idmap": 12513,
        "thanh": "U Châu",
        "ten_npc": "Sĩ Binh",
        "level": 6,
        "hp": 43,
        "sp": 71,
        "hpx": 2,
        "spx": 1,
        "int": 1,
        "atk": 3,
        "def": 3,
        "agi": 2,
        "item1": 27065,
        "item2": 26045,
        "item3": 27034,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10035,
        "idmap": 10806,
        "ten_npc": "Tiểu.Ph.Tướng",
        "level": 7,
        "hp": 47,
        "sp": 75,
        "hpx": 3,
        "spx": 2,
        "int": 1,
        "atk": 3,
        "def": 2,
        "agi": 4,
        "item1": 26047,
        "item2": 26082,
        "item3": 26157,
        "item4": 46016,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12047,
        "idmap": 11172,
        "ten_map": "N.Me Thai Su Tu Bac Hai",
        "thanh": "Thanh Châu",
        "ten_npc": "Mẹ Lưu Bị",
        "level": 7,
        "hp": 57,
        "sp": 75,
        "hpx": 3,
        "spx": 2,
        "int": 5,
        "atk": 1,
        "def": 1,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14133,
        "idmap": 11585,
        "thanh": "Thanh Châu",
        "ten_npc": "Cường Đạo",
        "level": 7,
        "hp": 47,
        "sp": 72,
        "hpx": 3,
        "spx": 1,
        "int": 1,
        "atk": 4,
        "def": 3,
        "agi": 2,
        "item1": 26039,
        "item2": 26165,
        "item3": 26057,
        "item4": 0,
        "item5": 26115,
        "item6": 0
    },
    {
        "idnpc": 14261,
        "idmap": 12000,
        "ten_map": "U Chau",
        "thanh": "U Châu",
        "ten_npc": "Đại Phu",
        "level": 7,
        "hp": 47,
        "sp": 75,
        "hpx": 3,
        "spx": 2,
        "int": 4,
        "atk": 1,
        "def": 1,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15021,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Hạng Lữo Bác",
        "level": 7,
        "hp": 47,
        "sp": 75,
        "hpx": 3,
        "spx": 2,
        "int": 4,
        "atk": 1,
        "def": 2,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15026,
        "idmap": 12065,
        "ten_map": "Hem Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Đại Ngưu",
        "level": 7,
        "hp": 47,
        "sp": 75,
        "hpx": 3,
        "spx": 2,
        "int": 4,
        "atk": 2,
        "def": 2,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15033,
        "idmap": 12071,
        "ten_map": "Thanh Dai Huyen 1",
        "thanh": "U Châu",
        "ten_npc": "Ông Lữo Trần",
        "level": 7,
        "hp": 47,
        "sp": 76,
        "hpx": 3,
        "spx": 2,
        "int": 2,
        "atk": 1,
        "def": 4,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15034,
        "idmap": 12071,
        "ten_map": "Thanh Dai Huyen 1",
        "thanh": "U Châu",
        "ten_npc": "Hàn Đại Thẩm",
        "level": 7,
        "hp": 47,
        "sp": 76,
        "hpx": 3,
        "spx": 2,
        "int": 2,
        "atk": 4,
        "def": 1,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15038,
        "idmap": 12071,
        "ten_map": "Thanh Dai Huyen 1",
        "thanh": "U Châu",
        "ten_npc": "Tiểu Mỹ",
        "level": 7,
        "hp": 47,
        "sp": 76,
        "hpx": 3,
        "spx": 2,
        "int": 2,
        "atk": 3,
        "def": 3,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15058,
        "idmap": 12081,
        "ten_map": "Thanh Chan Dinh 1",
        "thanh": "U Châu",
        "ten_npc": "Anh Bình",
        "level": 7,
        "hp": 41,
        "sp": 72,
        "hpx": 1,
        "spx": 1,
        "int": 1,
        "atk": 6,
        "def": 2,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15059,
        "idmap": 12081,
        "ten_map": "Thanh Chan Dinh 1",
        "thanh": "U Châu",
        "ten_npc": "Bác Trần",
        "level": 7,
        "hp": 44,
        "sp": 75,
        "hpx": 2,
        "spx": 2,
        "int": 2,
        "atk": 2,
        "def": 4,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15071,
        "idmap": 12081,
        "ten_map": "Thanh Chan Dinh 1",
        "thanh": "U Châu",
        "ten_npc": "Lưu Cô Nương",
        "level": 7,
        "hp": 47,
        "sp": 75,
        "hpx": 3,
        "spx": 2,
        "int": 4,
        "atk": 2,
        "def": 1,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15079,
        "idmap": 12091,
        "ten_map": "Dai Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "A Long",
        "level": 7,
        "hp": 47,
        "sp": 75,
        "hpx": 3,
        "spx": 2,
        "int": 1,
        "atk": 1,
        "def": 5,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15115,
        "idmap": 12093,
        "ten_map": "DaiDien Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Ông Tiền",
        "level": 7,
        "hp": 47,
        "sp": 75,
        "hpx": 3,
        "spx": 2,
        "int": 1,
        "atk": 2,
        "def": 5,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15134,
        "idmap": 12094,
        "ten_map": "BacThangKhong Tuoc1",
        "thanh": "U Châu",
        "ten_npc": "Bác A Thông",
        "level": 7,
        "hp": 47,
        "sp": 75,
        "hpx": 3,
        "spx": 2,
        "int": 6,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15136,
        "idmap": 12095,
        "ten_map": "Tang Cao Khong Tuoc Dai",
        "thanh": "U Châu",
        "ten_npc": "Khai Khoáng",
        "level": 7,
        "hp": 47,
        "sp": 75,
        "hpx": 3,
        "spx": 2,
        "int": 1,
        "atk": 6,
        "def": 2,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15209,
        "idmap": 12135,
        "ten_map": "Nha Trieu Van Thuong Son",
        "thanh": "U Châu",
        "ten_npc": "Tiểu Hoa",
        "level": 7,
        "hp": 47,
        "sp": 75,
        "hpx": 3,
        "spx": 2,
        "int": 2,
        "atk": 3,
        "def": 2,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15240,
        "idmap": 12177,
        "ten_map": "Nha Luu Bi Lau Song",
        "thanh": "U Châu",
        "ten_npc": "Tiểu Tuyết",
        "level": 7,
        "hp": 47,
        "sp": 75,
        "hpx": 3,
        "spx": 2,
        "int": 1,
        "atk": 2,
        "def": 2,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15271,
        "idmap": 12223,
        "ten_map": "Hieu Thuoc TQ",
        "thanh": "U Châu",
        "ten_npc": "Tiểu Lợi Tử",
        "level": 7,
        "hp": 41,
        "sp": 75,
        "hpx": 1,
        "spx": 2,
        "int": 3,
        "atk": 1,
        "def": 4,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15323,
        "idmap": 12301,
        "ten_map": "Dai Dien Chu Hau",
        "thanh": "U Châu",
        "ten_npc": "Bào Xuất Mẫu",
        "level": 7,
        "hp": 41,
        "sp": 75,
        "hpx": 1,
        "spx": 2,
        "int": 4,
        "atk": 2,
        "def": 2,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15453,
        "idmap": 12409,
        "thanh": "U Châu",
        "ten_npc": "Xảo đồ tể",
        "level": 7,
        "hp": 71,
        "sp": 79,
        "hpx": 3,
        "spx": 2,
        "int": 1,
        "atk": 3,
        "def": 1,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16096,
        "idmap": 12511,
        "thanh": "U Châu",
        "ten_npc": "Tiềntrangdumục",
        "level": 7,
        "hp": 47,
        "sp": 75,
        "hpx": 3,
        "spx": 2,
        "int": 4,
        "atk": 0,
        "def": 1,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17008,
        "idmap": 12512,
        "thanh": "U Châu",
        "ten_npc": "Sĩ Lính",
        "level": 7,
        "hp": 47,
        "sp": 72,
        "hpx": 3,
        "spx": 1,
        "int": 1,
        "atk": 5,
        "def": 2,
        "agi": 2,
        "item1": 26098,
        "item2": 26045,
        "item3": 26045,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17026,
        "idmap": 12514,
        "thanh": "U Châu",
        "ten_npc": "Dâu Châu quân",
        "level": 7,
        "hp": 47,
        "sp": 72,
        "hpx": 3,
        "spx": 1,
        "int": 1,
        "atk": 4,
        "def": 2,
        "agi": 3,
        "item1": 26098,
        "item2": 27034,
        "item3": 26098,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10059,
        "idmap": 10810,
        "ten_npc": "Binh Giáp Nặng",
        "level": 8,
        "hp": 51,
        "sp": 74,
        "hpx": 3,
        "spx": 1,
        "int": 1,
        "atk": 3,
        "def": 7,
        "agi": 1,
        "item1": 27034,
        "item2": 27034,
        "item3": 27034,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10082,
        "idmap": 10813,
        "ten_npc": "Lính Ma Dữ",
        "level": 8,
        "hp": 51,
        "sp": 77,
        "hpx": 3,
        "spx": 2,
        "int": 3,
        "atk": 4,
        "def": 1,
        "agi": 3,
        "item1": 26033,
        "item2": 26033,
        "item3": 26057,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10110,
        "idmap": 10818,
        "ten_npc": "Khăn Vàng",
        "level": 8,
        "hp": 51,
        "sp": 77,
        "hpx": 3,
        "spx": 2,
        "int": 2,
        "atk": 4,
        "def": 3,
        "agi": 2,
        "item1": 26002,
        "item2": 26016,
        "item3": 26001,
        "item4": 20737,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12050,
        "idmap": 11174,
        "ten_map": "N.Thai Su Tu Phung Lai Thon",
        "thanh": "Thanh Châu",
        "ten_npc": "Mẹ Triệu Vân",
        "level": 8,
        "hp": 63,
        "sp": 77,
        "hpx": 3,
        "spx": 2,
        "int": 4,
        "atk": 1,
        "def": 2,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12053,
        "idmap": 11202,
        "thanh": "Thanh Châu",
        "ten_npc": "Ma Phu Nhân",
        "level": 8,
        "hp": 63,
        "sp": 77,
        "hpx": 3,
        "spx": 2,
        "int": 4,
        "atk": 1,
        "def": 2,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14099,
        "idmap": 11583,
        "thanh": "Thanh Châu",
        "ten_npc": "Tào Cửu",
        "level": 8,
        "hp": 63,
        "sp": 77,
        "hpx": 3,
        "spx": 2,
        "int": 3,
        "atk": 3,
        "def": 2,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14472,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Thái phu nhân",
        "level": 8,
        "hp": 51,
        "sp": 77,
        "hpx": 3,
        "spx": 2,
        "int": 1,
        "atk": 3,
        "def": 5,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15019,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Ngô Đại Ma",
        "level": 8,
        "hp": 51,
        "sp": 77,
        "hpx": 3,
        "spx": 2,
        "int": 2,
        "atk": 4,
        "def": 2,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15022,
        "idmap": 12065,
        "ten_map": "Hem Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Ngũ Lữo Đầu",
        "level": 8,
        "hp": 51,
        "sp": 77,
        "hpx": 3,
        "spx": 2,
        "int": 2,
        "atk": 5,
        "def": 3,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15029,
        "idmap": 12071,
        "ten_map": "Thanh Dai Huyen 1",
        "thanh": "U Châu",
        "ten_npc": "Hương Hương",
        "level": 8,
        "hp": 50,
        "sp": 78,
        "hpx": 3,
        "spx": 2,
        "int": 5,
        "atk": 1,
        "def": 2,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15048,
        "idmap": 12071,
        "ten_map": "Thanh Dai Huyen 1",
        "thanh": "U Châu",
        "ten_npc": "Phan A",
        "level": 8,
        "hp": 47,
        "sp": 78,
        "hpx": 2,
        "spx": 2,
        "int": 7,
        "atk": 1,
        "def": 1,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15055,
        "idmap": 12081,
        "ten_map": "Thanh Chan Dinh 1",
        "thanh": "U Châu",
        "ten_npc": "Duy Duy",
        "level": 8,
        "hp": 51,
        "sp": 77,
        "hpx": 3,
        "spx": 2,
        "int": 1,
        "atk": 4,
        "def": 2,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15056,
        "idmap": 12081,
        "ten_map": "Thanh Chan Dinh 1",
        "thanh": "U Châu",
        "ten_npc": "Nạn Dân",
        "level": 8,
        "hp": 51,
        "sp": 77,
        "hpx": 3,
        "spx": 2,
        "int": 2,
        "atk": 2,
        "def": 3,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15077,
        "idmap": 12084,
        "thanh": "U Châu",
        "ten_npc": "Vương Lữo đầu",
        "level": 8,
        "hp": 51,
        "sp": 77,
        "hpx": 3,
        "spx": 2,
        "int": 1,
        "atk": 1,
        "def": 2,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15097,
        "idmap": 12093,
        "ten_map": "DaiDien Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Tiểu Lưu",
        "level": 8,
        "hp": 51,
        "sp": 77,
        "hpx": 3,
        "spx": 2,
        "int": 2,
        "atk": 3,
        "def": 4,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15099,
        "idmap": 12093,
        "ten_map": "DaiDien Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "A Thông",
        "level": 8,
        "hp": 51,
        "sp": 78,
        "hpx": 3,
        "spx": 2,
        "int": 5,
        "atk": 1,
        "def": 1,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15119,
        "idmap": 12093,
        "ten_map": "DaiDien Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Tiêu Sư",
        "level": 8,
        "hp": 51,
        "sp": 77,
        "hpx": 3,
        "spx": 2,
        "int": 3,
        "atk": 6,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15122,
        "idmap": 12093,
        "ten_map": "DaiDien Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Bà Lữo Vạn",
        "level": 8,
        "hp": 51,
        "sp": 77,
        "hpx": 3,
        "spx": 2,
        "int": 3,
        "atk": 3,
        "def": 4,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15125,
        "idmap": 12093,
        "ten_map": "DaiDien Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Bác Liễu",
        "level": 8,
        "hp": 51,
        "sp": 78,
        "hpx": 3,
        "spx": 2,
        "int": 5,
        "atk": 2,
        "def": 1,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15126,
        "idmap": 12093,
        "ten_map": "DaiDien Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Lữo Cư Lưu",
        "level": 8,
        "hp": 51,
        "sp": 77,
        "hpx": 3,
        "spx": 2,
        "int": 4,
        "atk": 4,
        "def": 2,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15161,
        "idmap": 12096,
        "ten_map": "Bac Thang Khong Tuoc2",
        "thanh": "U Châu",
        "ten_npc": "Người Hầu",
        "level": 8,
        "hp": 51,
        "sp": 77,
        "hpx": 3,
        "spx": 2,
        "int": 1,
        "atk": 3,
        "def": 4,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15169,
        "idmap": 12101,
        "ten_map": "N.Ly Lang Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Sái Hoa Đà",
        "level": 8,
        "hp": 51,
        "sp": 77,
        "hpx": 3,
        "spx": 2,
        "int": 1,
        "atk": 3,
        "def": 4,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15187,
        "idmap": 12105,
        "ten_map": "P.Tao Truc Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Ông Lữo Lục",
        "level": 8,
        "hp": 51,
        "sp": 78,
        "hpx": 3,
        "spx": 2,
        "int": 5,
        "atk": 2,
        "def": 2,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15216,
        "idmap": 12139,
        "ten_map": "N.Quach Gia TQ",
        "thanh": "U Châu",
        "ten_npc": "Vương Đại Thẩm",
        "level": 8,
        "hp": 51,
        "sp": 77,
        "hpx": 3,
        "spx": 2,
        "int": 4,
        "atk": 4,
        "def": 2,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15239,
        "idmap": 12176,
        "ten_map": "N.Ngo Tieu Muoi",
        "thanh": "U Châu",
        "ten_npc": "Thiên Hạc",
        "level": 8,
        "hp": 51,
        "sp": 77,
        "hpx": 3,
        "spx": 2,
        "int": 2,
        "atk": 4,
        "def": 4,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15272,
        "idmap": 12224,
        "thanh": "U Châu",
        "ten_npc": "Tiểu Đào Tử",
        "level": 8,
        "hp": 51,
        "sp": 77,
        "hpx": 3,
        "spx": 2,
        "int": 3,
        "atk": 5,
        "def": 2,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15308,
        "idmap": 12281,
        "ten_map": "Quan Tra",
        "thanh": "U Châu",
        "ten_npc": "Tỳ Nữ",
        "level": 8,
        "hp": 51,
        "sp": 78,
        "hpx": 3,
        "spx": 2,
        "int": 5,
        "atk": 3,
        "def": 1,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15359,
        "idmap": 12305,
        "ten_map": "Trai Giam Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Uyển Ni",
        "level": 8,
        "hp": 53,
        "sp": 80,
        "hpx": 4,
        "spx": 3,
        "int": 2,
        "atk": 3,
        "def": 4,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15451,
        "idmap": 12409,
        "thanh": "U Châu",
        "ten_npc": "Vươnglãoch.quỹ",
        "level": 8,
        "hp": 79,
        "sp": 83,
        "hpx": 3,
        "spx": 2,
        "int": 6,
        "atk": 1,
        "def": 2,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15459,
        "idmap": 12411,
        "ten_map": "Thiet Thach Son",
        "thanh": "U Châu",
        "ten_npc": "Hộ săn bắn",
        "level": 8,
        "hp": 79,
        "sp": 82,
        "hpx": 3,
        "spx": 2,
        "int": 0,
        "atk": 6,
        "def": 1,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15460,
        "idmap": 12411,
        "ten_map": "Thiet Thach Son",
        "thanh": "U Châu",
        "ten_npc": "Viên ăn mày",
        "level": 8,
        "hp": 79,
        "sp": 79,
        "hpx": 3,
        "spx": 1,
        "int": 2,
        "atk": 4,
        "def": 1,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16005,
        "idmap": 12413,
        "ten_map": "Thiet Thach Son Trang",
        "thanh": "U Châu",
        "ten_npc": "NhàBuôn TạpHóa",
        "level": 8,
        "hp": 51,
        "sp": 77,
        "hpx": 3,
        "spx": 2,
        "int": 4,
        "atk": 3,
        "def": 2,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16092,
        "idmap": 12511,
        "thanh": "U Châu",
        "ten_npc": "Đồ tễ Nam Man",
        "level": 8,
        "hp": 51,
        "sp": 74,
        "hpx": 3,
        "spx": 1,
        "int": 1,
        "atk": 8,
        "def": 1,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10111,
        "idmap": 10818,
        "ten_npc": "Binh Kh.Vàng",
        "level": 9,
        "hp": 55,
        "sp": 80,
        "hpx": 4,
        "spx": 2,
        "int": 5,
        "atk": 2,
        "def": 3,
        "agi": 2,
        "item1": 27029,
        "item2": 27034,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12048,
        "idmap": 11173,
        "ten_map": "N.Dinh Ngu Phu Phung Lai Thon",
        "thanh": "Thanh Châu",
        "ten_npc": "Cam Cô Nương",
        "level": 9,
        "hp": 68,
        "sp": 80,
        "hpx": 4,
        "spx": 2,
        "int": 5,
        "atk": 2,
        "def": 1,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12049,
        "idmap": 11173,
        "ten_map": "N.Dinh Ngu Phu Phung Lai Thon",
        "thanh": "Thanh Châu",
        "ten_npc": "Phu Nhân Cam",
        "level": 9,
        "hp": 68,
        "sp": 80,
        "hpx": 4,
        "spx": 2,
        "int": 5,
        "atk": 2,
        "def": 1,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14123,
        "idmap": 11584,
        "thanh": "Thanh Châu",
        "ten_npc": "Lưu.Ng.Khởi",
        "level": 9,
        "hp": 68,
        "sp": 80,
        "hpx": 4,
        "spx": 2,
        "int": 4,
        "atk": 2,
        "def": 3,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14158,
        "idmap": 11801,
        "ten_map": "Rung Dong Quan 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Đại Sơn Tặc",
        "level": 9,
        "hp": 55,
        "sp": 75,
        "hpx": 4,
        "spx": 1,
        "int": 1,
        "atk": 5,
        "def": 4,
        "agi": 3,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14507,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Lưu Phu Nhân",
        "level": 9,
        "hp": 49,
        "sp": 79,
        "hpx": 2,
        "spx": 2,
        "int": 2,
        "atk": 3,
        "def": 6,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15013,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "tiểu thôn cô",
        "level": 9,
        "hp": 55,
        "sp": 79,
        "hpx": 4,
        "spx": 2,
        "int": 2,
        "atk": 5,
        "def": 2,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15015,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Chú Trần",
        "level": 9,
        "hp": 55,
        "sp": 79,
        "hpx": 4,
        "spx": 2,
        "int": 1,
        "atk": 5,
        "def": 4,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15025,
        "idmap": 12065,
        "ten_map": "Hem Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Vương Đại Ma",
        "level": 9,
        "hp": 55,
        "sp": 80,
        "hpx": 4,
        "spx": 2,
        "int": 4,
        "atk": 2,
        "def": 3,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15027,
        "idmap": 12065,
        "ten_map": "Hem Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Kim Vô Lại",
        "level": 9,
        "hp": 55,
        "sp": 79,
        "hpx": 4,
        "spx": 2,
        "int": 3,
        "atk": 4,
        "def": 2,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15030,
        "idmap": 12071,
        "ten_map": "Thanh Dai Huyen 1",
        "thanh": "U Châu",
        "ten_npc": "Tiểu Lưu Manh",
        "level": 9,
        "hp": 56,
        "sp": 79,
        "hpx": 4,
        "spx": 2,
        "int": 1,
        "atk": 5,
        "def": 4,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15074,
        "idmap": 12081,
        "ten_map": "Thanh Chan Dinh 1",
        "thanh": "U Châu",
        "ten_npc": "Cao Đại Ma",
        "level": 9,
        "hp": 46,
        "sp": 75,
        "hpx": 1,
        "spx": 1,
        "int": 3,
        "atk": 6,
        "def": 6,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15084,
        "idmap": 12091,
        "ten_map": "Dai Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Hà Hùng",
        "level": 9,
        "hp": 55,
        "sp": 82,
        "hpx": 4,
        "spx": 3,
        "int": 2,
        "atk": 2,
        "def": 4,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15086,
        "idmap": 12091,
        "ten_map": "Dai Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Diệp Đại Bác",
        "level": 9,
        "hp": 55,
        "sp": 80,
        "hpx": 4,
        "spx": 2,
        "int": 4,
        "atk": 5,
        "def": 2,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15092,
        "idmap": 12091,
        "ten_map": "Dai Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Chú Củng",
        "level": 9,
        "hp": 55,
        "sp": 79,
        "hpx": 4,
        "spx": 2,
        "int": 3,
        "atk": 4,
        "def": 2,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15100,
        "idmap": 12093,
        "ten_map": "DaiDien Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Tiểu Ninh",
        "level": 9,
        "hp": 55,
        "sp": 79,
        "hpx": 4,
        "spx": 2,
        "int": 1,
        "atk": 5,
        "def": 3,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15102,
        "idmap": 12093,
        "ten_map": "DaiDien Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Vệ Đại Ca",
        "level": 9,
        "hp": 55,
        "sp": 79,
        "hpx": 4,
        "spx": 2,
        "int": 3,
        "atk": 4,
        "def": 2,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15105,
        "idmap": 12093,
        "ten_map": "DaiDien Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Tiểu Diêu",
        "level": 9,
        "hp": 55,
        "sp": 79,
        "hpx": 4,
        "spx": 2,
        "int": 3,
        "atk": 4,
        "def": 2,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15106,
        "idmap": 12093,
        "ten_map": "DaiDien Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Tú Tú",
        "level": 9,
        "hp": 55,
        "sp": 79,
        "hpx": 4,
        "spx": 2,
        "int": 1,
        "atk": 5,
        "def": 2,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15108,
        "idmap": 12093,
        "ten_map": "DaiDien Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Cô Nhũ Phân",
        "level": 9,
        "hp": 55,
        "sp": 80,
        "hpx": 4,
        "spx": 2,
        "int": 4,
        "atk": 2,
        "def": 2,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15183,
        "idmap": 12104,
        "ten_map": "Tao Phu Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Linh Linh",
        "level": 9,
        "hp": 60,
        "sp": 75,
        "hpx": 5,
        "spx": 1,
        "int": 4,
        "atk": 2,
        "def": 3,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15207,
        "idmap": 12135,
        "ten_map": "Nha Trieu Van Thuong Son",
        "thanh": "U Châu",
        "ten_npc": "Tạ Thiếu Gia",
        "level": 9,
        "hp": 55,
        "sp": 79,
        "hpx": 4,
        "spx": 2,
        "int": 3,
        "atk": 3,
        "def": 3,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15214,
        "idmap": 12138,
        "ten_map": "Pho Ho Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Châu Mẫu",
        "level": 9,
        "hp": 55,
        "sp": 79,
        "hpx": 4,
        "spx": 2,
        "int": 2,
        "atk": 3,
        "def": 3,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15268,
        "idmap": 12206,
        "thanh": "U Châu",
        "ten_npc": "Thiệu Phu Nhân",
        "level": 9,
        "hp": 53,
        "sp": 79,
        "hpx": 3,
        "spx": 2,
        "int": 1,
        "atk": 5,
        "def": 4,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15358,
        "idmap": 12305,
        "ten_map": "Trai Giam Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Chu Khắc",
        "level": 9,
        "hp": 55,
        "sp": 75,
        "hpx": 4,
        "spx": 1,
        "int": 3,
        "atk": 2,
        "def": 4,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15412,
        "idmap": 12407,
        "thanh": "U Châu",
        "ten_npc": "Mãnh Đại Đồ",
        "level": 9,
        "hp": 55,
        "sp": 85,
        "hpx": 4,
        "spx": 2,
        "int": 1,
        "atk": 6,
        "def": 0,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15418,
        "idmap": 12407,
        "thanh": "U Châu",
        "ten_npc": "A Chi",
        "level": 9,
        "hp": 55,
        "sp": 79,
        "hpx": 4,
        "spx": 2,
        "int": 1,
        "atk": 7,
        "def": 1,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15419,
        "idmap": 12407,
        "thanh": "U Châu",
        "ten_npc": "Biển nóng",
        "level": 9,
        "hp": 55,
        "sp": 80,
        "hpx": 4,
        "spx": 2,
        "int": 7,
        "atk": 1,
        "def": 3,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15422,
        "idmap": 12408,
        "thanh": "U Châu",
        "ten_npc": "Tiểuácma Noel",
        "level": 9,
        "hp": 70,
        "sp": 79,
        "hpx": 8,
        "spx": 2,
        "int": 1,
        "atk": 1,
        "def": 3,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15423,
        "idmap": 12408,
        "thanh": "U Châu",
        "ten_npc": "Tô Việt",
        "level": 9,
        "hp": 63,
        "sp": 80,
        "hpx": 6,
        "spx": 2,
        "int": 4,
        "atk": 1,
        "def": 2,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16001,
        "idmap": 12411,
        "ten_map": "Thiet Thach Son",
        "thanh": "U Châu",
        "ten_npc": "Tiểu Nhị",
        "level": 9,
        "hp": 55,
        "sp": 79,
        "hpx": 4,
        "spx": 2,
        "int": 1,
        "atk": 5,
        "def": 4,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16087,
        "idmap": 12508,
        "thanh": "U Châu",
        "ten_npc": "Thương nhân",
        "level": 9,
        "hp": 55,
        "sp": 80,
        "hpx": 4,
        "spx": 2,
        "int": 5,
        "atk": 1,
        "def": 1,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17046,
        "idmap": 12519,
        "thanh": "U Châu",
        "ten_npc": "Tinh Binh",
        "level": 9,
        "hp": 55,
        "sp": 75,
        "hpx": 4,
        "spx": 1,
        "int": 2,
        "atk": 4,
        "def": 5,
        "agi": 2,
        "item1": 26041,
        "item2": 26157,
        "item3": 26098,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17191,
        "idmap": 12569,
        "thanh": "U Châu",
        "ten_npc": "DâuChâuTrưởng",
        "level": 9,
        "hp": 55,
        "sp": 79,
        "hpx": 4,
        "spx": 2,
        "int": 2,
        "atk": 4,
        "def": 4,
        "agi": 2,
        "item1": 27002,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10034,
        "idmap": 10805,
        "ten_npc": "Đại.Ph.Tướng",
        "level": 10,
        "hp": 59,
        "sp": 82,
        "hpx": 4,
        "spx": 2,
        "int": 2,
        "atk": 5,
        "def": 5,
        "agi": 2,
        "item1": 26157,
        "item2": 26082,
        "item3": 27029,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10067,
        "idmap": 10811,
        "ten_npc": "Giải Võ Sĩ",
        "level": 10,
        "hp": 59,
        "sp": 82,
        "hpx": 4,
        "spx": 3,
        "int": 3,
        "atk": 5,
        "def": 3,
        "agi": 3,
        "item1": 26045,
        "item2": 26162,
        "item3": 26162,
        "item4": 46016,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14571,
        "idmap": 12031,
        "ten_map": "Thon Lau Song",
        "thanh": "U Châu",
        "ten_npc": "Trâu Tịnh",
        "level": 10,
        "hp": 59,
        "sp": 80,
        "hpx": 4,
        "spx": 2,
        "int": 4,
        "atk": 2,
        "def": 3,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15082,
        "idmap": 12091,
        "ten_map": "Dai Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Tăng Tiểu Ca",
        "level": 10,
        "hp": 63,
        "sp": 82,
        "hpx": 5,
        "spx": 3,
        "int": 5,
        "atk": 2,
        "def": 1,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15091,
        "idmap": 12091,
        "ten_map": "Dai Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Trương Lữo Lữo",
        "level": 10,
        "hp": 59,
        "sp": 82,
        "hpx": 4,
        "spx": 3,
        "int": 6,
        "atk": 3,
        "def": 3,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15104,
        "idmap": 12093,
        "ten_map": "DaiDien Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Khuâng Vác",
        "level": 10,
        "hp": 59,
        "sp": 82,
        "hpx": 4,
        "spx": 2,
        "int": 2,
        "atk": 5,
        "def": 4,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15132,
        "idmap": 12093,
        "ten_map": "DaiDien Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Nữ Nhạc Sư",
        "level": 10,
        "hp": 59,
        "sp": 82,
        "hpx": 4,
        "spx": 3,
        "int": 5,
        "atk": 2,
        "def": 2,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15193,
        "idmap": 12106,
        "ten_map": "P.Tao Phi Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Tuệ Tuệ",
        "level": 10,
        "hp": 59,
        "sp": 82,
        "hpx": 4,
        "spx": 2,
        "int": 2,
        "atk": 2,
        "def": 5,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15205,
        "idmap": 12134,
        "ten_map": "N.Cong Ton Toan Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Đứa Con Hoang",
        "level": 10,
        "hp": 63,
        "sp": 77,
        "hpx": 5,
        "spx": 1,
        "int": 2,
        "atk": 3,
        "def": 2,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15229,
        "idmap": 12172,
        "ten_map": "N.Ta Lao Dau Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Chủ Võ Đài",
        "level": 10,
        "hp": 59,
        "sp": 83,
        "hpx": 4,
        "spx": 3,
        "int": 7,
        "atk": 1,
        "def": 2,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15258,
        "idmap": 12201,
        "ten_map": "Lo Ren",
        "thanh": "U Châu",
        "ten_npc": "Tiểu Lục Đậu",
        "level": 10,
        "hp": 56,
        "sp": 82,
        "hpx": 3,
        "spx": 3,
        "int": 5,
        "atk": 2,
        "def": 4,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15269,
        "idmap": 12221,
        "ten_map": "Hieu Thuoc",
        "thanh": "U Châu",
        "ten_npc": "Hình phu nhân",
        "level": 10,
        "hp": 59,
        "sp": 82,
        "hpx": 4,
        "spx": 3,
        "int": 5,
        "atk": 2,
        "def": 3,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15284,
        "idmap": 12242,
        "ten_map": "Nha Tro Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Tiểu Đồng",
        "level": 10,
        "hp": 59,
        "sp": 82,
        "hpx": 4,
        "spx": 3,
        "int": 5,
        "atk": 2,
        "def": 3,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15348,
        "idmap": 12303,
        "ten_map": "Quan Phu BinhNguyen",
        "thanh": "U Châu",
        "ten_npc": "tiểu mục đồng",
        "level": 10,
        "hp": 59,
        "sp": 87,
        "hpx": 4,
        "spx": 4,
        "int": 4,
        "atk": 1,
        "def": 3,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15398,
        "idmap": 12406,
        "thanh": "U Châu",
        "ten_npc": "Phàm Thần",
        "level": 10,
        "hp": 59,
        "sp": 80,
        "hpx": 4,
        "spx": 2,
        "int": 4,
        "atk": 3,
        "def": 3,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15399,
        "idmap": 12406,
        "thanh": "U Châu",
        "ten_npc": "người làng",
        "level": 10,
        "hp": 59,
        "sp": 82,
        "hpx": 4,
        "spx": 3,
        "int": 3,
        "atk": 3,
        "def": 3,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15420,
        "idmap": 12407,
        "thanh": "U Châu",
        "ten_npc": "Lão Dữu nông",
        "level": 10,
        "hp": 59,
        "sp": 77,
        "hpx": 4,
        "spx": 1,
        "int": 1,
        "atk": 5,
        "def": 5,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16002,
        "idmap": 12412,
        "ten_map": "Thiet Thach Son Lo",
        "thanh": "U Châu",
        "ten_npc": "Chủ Tiệm Thuốc",
        "level": 10,
        "hp": 59,
        "sp": 83,
        "hpx": 4,
        "spx": 3,
        "int": 7,
        "atk": 1,
        "def": 2,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16004,
        "idmap": 12413,
        "ten_map": "Thiet Thach Son Trang",
        "thanh": "U Châu",
        "ten_npc": "Chủ Tiền Trang",
        "level": 10,
        "hp": 59,
        "sp": 82,
        "hpx": 4,
        "spx": 2,
        "int": 2,
        "atk": 2,
        "def": 8,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16006,
        "idmap": 12413,
        "ten_map": "Thiet Thach Son Trang",
        "thanh": "U Châu",
        "ten_npc": "Chủ Quán Trà",
        "level": 10,
        "hp": 59,
        "sp": 82,
        "hpx": 4,
        "spx": 3,
        "int": 4,
        "atk": 3,
        "def": 1,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16007,
        "idmap": 12418,
        "thanh": "U Châu",
        "ten_npc": "Chủ Tiệm Thuốc",
        "level": 10,
        "hp": 59,
        "sp": 83,
        "hpx": 4,
        "spx": 3,
        "int": 7,
        "atk": 1,
        "def": 2,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16008,
        "idmap": 12418,
        "thanh": "U Châu",
        "ten_npc": "Chủ Tiệm Thuốc",
        "level": 10,
        "hp": 59,
        "sp": 83,
        "hpx": 4,
        "spx": 3,
        "int": 7,
        "atk": 1,
        "def": 2,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16009,
        "idmap": 12418,
        "thanh": "U Châu",
        "ten_npc": "Chủ Lò Rèn",
        "level": 10,
        "hp": 59,
        "sp": 81,
        "hpx": 4,
        "spx": 2,
        "int": 1,
        "atk": 7,
        "def": 3,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16010,
        "idmap": 12418,
        "thanh": "U Châu",
        "ten_npc": "Chủ Lò Rèn",
        "level": 10,
        "hp": 59,
        "sp": 81,
        "hpx": 4,
        "spx": 2,
        "int": 1,
        "atk": 7,
        "def": 3,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16011,
        "idmap": 12418,
        "thanh": "U Châu",
        "ten_npc": "Chủ Tiền Trang",
        "level": 10,
        "hp": 59,
        "sp": 82,
        "hpx": 4,
        "spx": 2,
        "int": 2,
        "atk": 2,
        "def": 8,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16012,
        "idmap": 12418,
        "thanh": "U Châu",
        "ten_npc": "Chủ Tiền Trang",
        "level": 10,
        "hp": 59,
        "sp": 82,
        "hpx": 4,
        "spx": 2,
        "int": 2,
        "atk": 2,
        "def": 8,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16013,
        "idmap": 12418,
        "thanh": "U Châu",
        "ten_npc": "NhàBuôn TạpHóa",
        "level": 10,
        "hp": 59,
        "sp": 82,
        "hpx": 4,
        "spx": 3,
        "int": 4,
        "atk": 3,
        "def": 2,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16014,
        "idmap": 12418,
        "thanh": "U Châu",
        "ten_npc": "NhàBuôn TạpHóa",
        "level": 10,
        "hp": 59,
        "sp": 82,
        "hpx": 4,
        "spx": 3,
        "int": 4,
        "atk": 3,
        "def": 2,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16015,
        "idmap": 12418,
        "thanh": "U Châu",
        "ten_npc": "Tiểu Nhị",
        "level": 10,
        "hp": 59,
        "sp": 81,
        "hpx": 4,
        "spx": 2,
        "int": 1,
        "atk": 5,
        "def": 4,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16017,
        "idmap": 12418,
        "thanh": "U Châu",
        "ten_npc": "Ng.Bán Hoa Quả",
        "level": 10,
        "hp": 59,
        "sp": 82,
        "hpx": 4,
        "spx": 3,
        "int": 3,
        "atk": 2,
        "def": 6,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16019,
        "idmap": 12418,
        "thanh": "U Châu",
        "ten_npc": "NhàBuôn Xe Đẩy",
        "level": 10,
        "hp": 59,
        "sp": 82,
        "hpx": 4,
        "spx": 2,
        "int": 2,
        "atk": 4,
        "def": 5,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16020,
        "idmap": 12419,
        "thanh": "U Châu",
        "ten_npc": "NhàBuôn Xe Đẩy",
        "level": 10,
        "hp": 59,
        "sp": 82,
        "hpx": 4,
        "spx": 2,
        "int": 2,
        "atk": 5,
        "def": 4,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16021,
        "idmap": 12419,
        "thanh": "U Châu",
        "ten_npc": "Chủ Giáp Phục",
        "level": 10,
        "hp": 59,
        "sp": 81,
        "hpx": 4,
        "spx": 2,
        "int": 1,
        "atk": 5,
        "def": 6,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16037,
        "idmap": 12421,
        "ten_map": "Lo Long Khau",
        "thanh": "U Châu",
        "ten_npc": "Thương Nhân",
        "level": 10,
        "hp": 56,
        "sp": 82,
        "hpx": 3,
        "spx": 3,
        "int": 4,
        "atk": 3,
        "def": 5,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16045,
        "idmap": 12431,
        "ten_map": "Nha Quan",
        "thanh": "U Châu",
        "ten_npc": "TChiếnViên",
        "level": 10,
        "hp": 56,
        "sp": 82,
        "hpx": 3,
        "spx": 3,
        "int": 4,
        "atk": 3,
        "def": 5,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16054,
        "idmap": 12432,
        "ten_map": "Thong Do Nha Quan",
        "thanh": "U Châu",
        "ten_npc": "Thươngnhândmục",
        "level": 10,
        "hp": 59,
        "sp": 82,
        "hpx": 4,
        "spx": 2,
        "int": 2,
        "atk": 2,
        "def": 8,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16091,
        "idmap": 12511,
        "ten_map": "Mo Khoang Thuong Son 1",
        "thanh": "U Châu",
        "ten_npc": "Đồ tễ Nam Man",
        "level": 10,
        "hp": 63,
        "sp": 77,
        "hpx": 5,
        "spx": 1,
        "int": 2,
        "atk": 2,
        "def": 2,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17009,
        "idmap": 12512,
        "thanh": "U Châu",
        "ten_npc": "Lính trưởng",
        "level": 10,
        "hp": 59,
        "sp": 77,
        "hpx": 4,
        "spx": 1,
        "int": 3,
        "atk": 5,
        "def": 3,
        "agi": 4,
        "item1": 26098,
        "item2": 26098,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10060,
        "idmap": 10810,
        "ten_npc": "Binh Giáp Nhẹ",
        "level": 11,
        "hp": 58,
        "sp": 78,
        "hpx": 3,
        "spx": 1,
        "int": 1,
        "atk": 8,
        "def": 6,
        "agi": 3,
        "item1": 43032,
        "item2": 27035,
        "item3": 27035,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10112,
        "idmap": 10818,
        "ten_npc": "Vệ Sĩ Kh.Vàng",
        "level": 11,
        "hp": 64,
        "sp": 82,
        "hpx": 4,
        "spx": 2,
        "int": 1,
        "atk": 6,
        "def": 4,
        "agi": 5,
        "item1": 26002,
        "item2": 26058,
        "item3": 26058,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10195,
        "idmap": 10911,
        "ten_npc": "Võ Sĩ.Kh.Vàng",
        "level": 11,
        "hp": 55,
        "sp": 122,
        "hpx": 2,
        "spx": 2,
        "int": 2,
        "atk": 4,
        "def": 10,
        "agi": 4,
        "item1": 26065,
        "item2": 26065,
        "item3": 27036,
        "item4": 50001,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12170,
        "idmap": 11502,
        "ten_map": "Son Dong Thai Son 2",
        "thanh": "Thanh Châu",
        "ten_npc": "LưuBị bịthương",
        "level": 11,
        "hp": 55,
        "sp": 86,
        "hpx": 2,
        "spx": 1,
        "int": 5,
        "atk": 8,
        "def": 3,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14102,
        "idmap": 11583,
        "thanh": "Thanh Châu",
        "ten_npc": "Chu Cống",
        "level": 11,
        "hp": 80,
        "sp": 84,
        "hpx": 4,
        "spx": 3,
        "int": 2,
        "atk": 9,
        "def": 3,
        "agi": 3,
        "item1": 26082,
        "item2": 26083,
        "item3": 26055,
        "item4": 49001,
        "item5": 46034,
        "item6": 0
    },
    {
        "idnpc": 14159,
        "idmap": 11801,
        "ten_map": "Rung Dong Quan 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Sơn Đại Vương",
        "level": 11,
        "hp": 73,
        "sp": 78,
        "hpx": 7,
        "spx": 1,
        "int": 2,
        "atk": 2,
        "def": 4,
        "agi": 6,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14262,
        "idmap": 12000,
        "ten_map": "U Chau",
        "thanh": "U Châu",
        "ten_npc": "Đại Phu",
        "level": 11,
        "hp": 64,
        "sp": 84,
        "hpx": 4,
        "spx": 3,
        "int": 4,
        "atk": 4,
        "def": 4,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15011,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Tôn Nhị",
        "level": 11,
        "hp": 64,
        "sp": 84,
        "hpx": 4,
        "spx": 3,
        "int": 2,
        "atk": 6,
        "def": 4,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15012,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Con bác Vương",
        "level": 11,
        "hp": 58,
        "sp": 82,
        "hpx": 3,
        "spx": 2,
        "int": 3,
        "atk": 6,
        "def": 4,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15035,
        "idmap": 12071,
        "ten_map": "Thanh Dai Huyen 1",
        "thanh": "U Châu",
        "ten_npc": "Đại Mao",
        "level": 11,
        "hp": 51,
        "sp": 78,
        "hpx": 1,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15044,
        "idmap": 12071,
        "ten_map": "Thanh Dai Huyen 1",
        "thanh": "U Châu",
        "ten_npc": "A Vinh",
        "level": 11,
        "hp": 62,
        "sp": 82,
        "hpx": 4,
        "spx": 2,
        "int": 3,
        "atk": 6,
        "def": 4,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15054,
        "idmap": 12081,
        "ten_map": "Thanh Chan Dinh 1",
        "thanh": "U Châu",
        "ten_npc": "A Tòan",
        "level": 11,
        "hp": 58,
        "sp": 85,
        "hpx": 3,
        "spx": 3,
        "int": 2,
        "atk": 7,
        "def": 4,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15070,
        "idmap": 12081,
        "ten_map": "Thanh Chan Dinh 1",
        "thanh": "U Châu",
        "ten_npc": "A Đào",
        "level": 11,
        "hp": 64,
        "sp": 85,
        "hpx": 4,
        "spx": 3,
        "int": 6,
        "atk": 2,
        "def": 3,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15089,
        "idmap": 12091,
        "ten_map": "Dai Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Hòang Đại Thẩm",
        "level": 11,
        "hp": 64,
        "sp": 84,
        "hpx": 4,
        "spx": 3,
        "int": 2,
        "atk": 6,
        "def": 4,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15101,
        "idmap": 12093,
        "ten_map": "DaiDien Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "A Chí",
        "level": 11,
        "hp": 64,
        "sp": 85,
        "hpx": 4,
        "spx": 3,
        "int": 5,
        "atk": 6,
        "def": 2,
        "agi": 2,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15110,
        "idmap": 12093,
        "ten_map": "DaiDien Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Trương Ma Ma",
        "level": 11,
        "hp": 64,
        "sp": 84,
        "hpx": 4,
        "spx": 3,
        "int": 2,
        "atk": 6,
        "def": 4,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15121,
        "idmap": 12093,
        "ten_map": "DaiDien Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Đường Lữo Bác",
        "level": 11,
        "hp": 64,
        "sp": 84,
        "hpx": 4,
        "spx": 3,
        "int": 3,
        "atk": 5,
        "def": 4,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15131,
        "idmap": 12093,
        "ten_map": "DaiDien Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Ông Thợ Lữo",
        "level": 11,
        "hp": 64,
        "sp": 82,
        "hpx": 4,
        "spx": 2,
        "int": 3,
        "atk": 5,
        "def": 5,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15200,
        "idmap": 12131,
        "ten_map": "N.Duy Duy Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Thợ mộc Hứa",
        "level": 11,
        "hp": 66,
        "sp": 82,
        "hpx": 5,
        "spx": 2,
        "int": 5,
        "atk": 5,
        "def": 3,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15223,
        "idmap": 12139,
        "ten_map": "N.Quach Gia TQ",
        "thanh": "U Châu",
        "ten_npc": "Ng.Bắt Nghêu",
        "level": 11,
        "hp": 64,
        "sp": 84,
        "hpx": 4,
        "spx": 3,
        "int": 2,
        "atk": 3,
        "def": 6,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15262,
        "idmap": 12204,
        "ten_map": "Tiem Sat TQ",
        "thanh": "U Châu",
        "ten_npc": "Nhị Lữo Gia",
        "level": 11,
        "hp": 64,
        "sp": 89,
        "hpx": 4,
        "spx": 4,
        "int": 7,
        "atk": 3,
        "def": 4,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15295,
        "idmap": 12244,
        "ten_map": "Nha Tro Viet Lai",
        "thanh": "U Châu",
        "ten_npc": "Ô Tam Quế",
        "level": 11,
        "hp": 64,
        "sp": 89,
        "hpx": 4,
        "spx": 4,
        "int": 4,
        "atk": 3,
        "def": 4,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15326,
        "idmap": 12301,
        "ten_map": "Dai Dien Chu Hau",
        "thanh": "U Châu",
        "ten_npc": "Tô Nương Tử",
        "level": 11,
        "hp": 64,
        "sp": 84,
        "hpx": 4,
        "spx": 3,
        "int": 4,
        "atk": 3,
        "def": 3,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15356,
        "idmap": 12304,
        "ten_map": "Quan Phu TQ",
        "thanh": "U Châu",
        "ten_npc": "Châu Lệ",
        "level": 11,
        "hp": 64,
        "sp": 84,
        "hpx": 4,
        "spx": 3,
        "int": 4,
        "atk": 3,
        "def": 3,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15452,
        "idmap": 12409,
        "thanh": "U Châu",
        "ten_npc": "Vương tiểu ca",
        "level": 11,
        "hp": 102,
        "sp": 93,
        "hpx": 4,
        "spx": 3,
        "int": 9,
        "atk": 2,
        "def": 1,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16016,
        "idmap": 12418,
        "thanh": "U Châu",
        "ten_npc": "Tiểu Nhị",
        "level": 11,
        "hp": 64,
        "sp": 85,
        "hpx": 4,
        "spx": 3,
        "int": 5,
        "atk": 2,
        "def": 5,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16040,
        "idmap": 12431,
        "ten_map": "Nha Quan",
        "thanh": "U Châu",
        "ten_npc": "Hậu Tống Quan",
        "level": 11,
        "hp": 64,
        "sp": 89,
        "hpx": 4,
        "spx": 4,
        "int": 4,
        "atk": 1,
        "def": 7,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16088,
        "idmap": 12508,
        "thanh": "U Châu",
        "ten_npc": "Thương nhân",
        "level": 11,
        "hp": 64,
        "sp": 85,
        "hpx": 4,
        "spx": 3,
        "int": 6,
        "atk": 1,
        "def": 1,
        "agi": 8,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16094,
        "idmap": 12511,
        "thanh": "U Châu",
        "ten_npc": "Th.nhân du mục",
        "level": 11,
        "hp": 64,
        "sp": 84,
        "hpx": 4,
        "spx": 3,
        "int": 2,
        "atk": 6,
        "def": 1,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17047,
        "idmap": 12519,
        "thanh": "U Châu",
        "ten_npc": "Dịch Châu Binh",
        "level": 11,
        "hp": 64,
        "sp": 82,
        "hpx": 4,
        "spx": 2,
        "int": 1,
        "atk": 6,
        "def": 2,
        "agi": 7,
        "item1": 27017,
        "item2": 27034,
        "item3": 27022,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10113,
        "idmap": 10819,
        "ten_npc": "ThuậtSĩ.KhVàng",
        "level": 12,
        "hp": 57,
        "sp": 94,
        "hpx": 2,
        "spx": 5,
        "int": 7,
        "atk": 4,
        "def": 3,
        "agi": 3,
        "item1": 26002,
        "item2": 26002,
        "item3": 26115,
        "item4": 46018,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10196,
        "idmap": 10911,
        "ten_npc": "Đao Sĩ.Kh.Vàng",
        "level": 12,
        "hp": 61,
        "sp": 123,
        "hpx": 3,
        "spx": 2,
        "int": 2,
        "atk": 10,
        "def": 3,
        "agi": 6,
        "item1": 26077,
        "item2": 26077,
        "item3": 27036,
        "item4": 33021,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10213,
        "idmap": 10917,
        "ten_npc": "Lữnhđạo KhVàng",
        "level": 12,
        "hp": 128,
        "sp": 86,
        "hpx": 5,
        "spx": 3,
        "int": 2,
        "atk": 5,
        "def": 8,
        "agi": 3,
        "item1": 27030,
        "item2": 26001,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14100,
        "idmap": 11583,
        "thanh": "Thanh Châu",
        "ten_npc": "Vệ Chi",
        "level": 12,
        "hp": 86,
        "sp": 87,
        "hpx": 5,
        "spx": 3,
        "int": 7,
        "atk": 3,
        "def": 4,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15037,
        "idmap": 12071,
        "ten_map": "Thanh Dai Huyen 1",
        "thanh": "U Châu",
        "ten_npc": "Chú Điền",
        "level": 12,
        "hp": 54,
        "sp": 79,
        "hpx": 1,
        "spx": 1,
        "int": 1,
        "atk": 1,
        "def": 1,
        "agi": 1,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15062,
        "idmap": 12081,
        "ten_map": "Thanh Chan Dinh 1",
        "thanh": "U Châu",
        "ten_npc": "Ngô Tiểu Muội",
        "level": 12,
        "hp": 65,
        "sp": 87,
        "hpx": 4,
        "spx": 3,
        "int": 5,
        "atk": 4,
        "def": 3,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15064,
        "idmap": 12081,
        "ten_map": "Thanh Chan Dinh 1",
        "thanh": "U Châu",
        "ten_npc": "Nghiêm Quả Phụ",
        "level": 12,
        "hp": 65,
        "sp": 87,
        "hpx": 4,
        "spx": 3,
        "int": 4,
        "atk": 5,
        "def": 3,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15090,
        "idmap": 12091,
        "ten_map": "Dai Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Đầu To",
        "level": 12,
        "hp": 68,
        "sp": 86,
        "hpx": 5,
        "spx": 3,
        "int": 1,
        "atk": 9,
        "def": 2,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15096,
        "idmap": 12092,
        "ten_map": "ThongDo Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Tề Tiên Sinh",
        "level": 12,
        "hp": 68,
        "sp": 87,
        "hpx": 5,
        "spx": 3,
        "int": 6,
        "atk": 3,
        "def": 3,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15116,
        "idmap": 12093,
        "ten_map": "DaiDien Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Đại Nhân",
        "level": 12,
        "hp": 68,
        "sp": 87,
        "hpx": 5,
        "spx": 3,
        "int": 4,
        "atk": 6,
        "def": 2,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15129,
        "idmap": 12093,
        "ten_map": "DaiDien Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Quả Phụ Bình",
        "level": 12,
        "hp": 68,
        "sp": 86,
        "hpx": 5,
        "spx": 3,
        "int": 2,
        "atk": 4,
        "def": 6,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15180,
        "idmap": 12104,
        "ten_map": "Tao Phu Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Kiều Tiểu Muội",
        "level": 12,
        "hp": 68,
        "sp": 83,
        "hpx": 5,
        "spx": 2,
        "int": 3,
        "atk": 4,
        "def": 6,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15191,
        "idmap": 12106,
        "ten_map": "P.Tao Phi Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Từ Lữo Đầu",
        "level": 12,
        "hp": 68,
        "sp": 87,
        "hpx": 5,
        "spx": 3,
        "int": 5,
        "atk": 4,
        "def": 2,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15202,
        "idmap": 12132,
        "ten_map": "N.Phuc Co Nuong",
        "thanh": "U Châu",
        "ten_npc": "Bội Bội",
        "level": 12,
        "hp": 68,
        "sp": 87,
        "hpx": 5,
        "spx": 3,
        "int": 5,
        "atk": 4,
        "def": 2,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15236,
        "idmap": 12175,
        "ten_map": "N.Hua Thieu Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Quan Định",
        "level": 12,
        "hp": 68,
        "sp": 87,
        "hpx": 5,
        "spx": 3,
        "int": 4,
        "atk": 3,
        "def": 5,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15263,
        "idmap": 12205,
        "thanh": "U Châu",
        "ten_npc": "Thanh Vân",
        "level": 12,
        "hp": 57,
        "sp": 87,
        "hpx": 2,
        "spx": 3,
        "int": 7,
        "atk": 1,
        "def": 4,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15382,
        "idmap": 12401,
        "ten_map": "Tien Do Tu Vo Son",
        "thanh": "U Châu",
        "ten_npc": "Vợ Tưởng Tễ",
        "level": 12,
        "hp": 68,
        "sp": 94,
        "hpx": 5,
        "spx": 5,
        "int": 4,
        "atk": 2,
        "def": 3,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16018,
        "idmap": 12418,
        "thanh": "U Châu",
        "ten_npc": "Chủ Khách Sạn",
        "level": 12,
        "hp": 68,
        "sp": 87,
        "hpx": 5,
        "spx": 3,
        "int": 4,
        "atk": 4,
        "def": 4,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16038,
        "idmap": 12431,
        "ten_map": "Nha Quan",
        "thanh": "U Châu",
        "ten_npc": "CChiếnViên",
        "level": 12,
        "hp": 65,
        "sp": 83,
        "hpx": 4,
        "spx": 2,
        "int": 7,
        "atk": 4,
        "def": 2,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16039,
        "idmap": 12431,
        "ten_map": "Nha Quan",
        "thanh": "U Châu",
        "ten_npc": "Tín Vật Viên",
        "level": 12,
        "hp": 65,
        "sp": 83,
        "hpx": 4,
        "spx": 2,
        "int": 7,
        "atk": 4,
        "def": 2,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16052,
        "idmap": 12432,
        "ten_map": "Thong Do Nha Quan",
        "thanh": "U Châu",
        "ten_npc": "Thương Nhân",
        "level": 12,
        "hp": 68,
        "sp": 87,
        "hpx": 5,
        "spx": 3,
        "int": 4,
        "atk": 4,
        "def": 4,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16081,
        "idmap": 12508,
        "thanh": "U Châu",
        "ten_npc": "T.nhân tạp hóa",
        "level": 12,
        "hp": 68,
        "sp": 87,
        "hpx": 5,
        "spx": 3,
        "int": 4,
        "atk": 4,
        "def": 4,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16093,
        "idmap": 12511,
        "thanh": "U Châu",
        "ten_npc": "Quản Lý LôiĐài",
        "level": 12,
        "hp": 65,
        "sp": 83,
        "hpx": 4,
        "spx": 2,
        "int": 7,
        "atk": 4,
        "def": 2,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16098,
        "idmap": 12511,
        "thanh": "U Châu",
        "ten_npc": "Thợ rèn du mục",
        "level": 12,
        "hp": 72,
        "sp": 86,
        "hpx": 6,
        "spx": 3,
        "int": 1,
        "atk": 6,
        "def": 2,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17051,
        "idmap": 12519,
        "thanh": "U Châu",
        "ten_npc": "Cường Binh",
        "level": 12,
        "hp": 76,
        "sp": 79,
        "hpx": 7,
        "spx": 1,
        "int": 2,
        "atk": 6,
        "def": 4,
        "agi": 4,
        "item1": 26098,
        "item2": 26157,
        "item3": 27054,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10068,
        "idmap": 10811,
        "ten_npc": "Chính Võ Sĩ",
        "level": 13,
        "hp": 75,
        "sp": 89,
        "hpx": 6,
        "spx": 3,
        "int": 1,
        "atk": 7,
        "def": 4,
        "agi": 4,
        "item1": 26045,
        "item2": 26080,
        "item3": 26045,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10071,
        "idmap": 10812,
        "ten_npc": "H.Thiên Võ Sĩ",
        "level": 13,
        "hp": 72,
        "sp": 84,
        "hpx": 5,
        "spx": 2,
        "int": 1,
        "atk": 8,
        "def": 5,
        "agi": 5,
        "item1": 26045,
        "item2": 26080,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10198,
        "idmap": 10912,
        "ten_npc": "Quý Khăn Vàng",
        "level": 13,
        "hp": 64,
        "sp": 136,
        "hpx": 3,
        "spx": 5,
        "int": 10,
        "atk": 2,
        "def": 3,
        "agi": 5,
        "item1": 27034,
        "item2": 27034,
        "item3": 27036,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14097,
        "idmap": 11583,
        "thanh": "Thanh Châu",
        "ten_npc": "TrươngThếBình",
        "level": 13,
        "hp": 87,
        "sp": 90,
        "hpx": 4,
        "spx": 3,
        "int": 6,
        "atk": 5,
        "def": 4,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15003,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Trương Đại Ca",
        "level": 13,
        "hp": 98,
        "sp": 89,
        "hpx": 5,
        "spx": 3,
        "int": 4,
        "atk": 7,
        "def": 3,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15040,
        "idmap": 12071,
        "ten_map": "Thanh Dai Huyen 1",
        "thanh": "U Châu",
        "ten_npc": "Lưu Bình",
        "level": 13,
        "hp": 64,
        "sp": 84,
        "hpx": 3,
        "spx": 2,
        "int": 6,
        "atk": 4,
        "def": 6,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15060,
        "idmap": 12081,
        "ten_map": "Thanh Chan Dinh 1",
        "thanh": "U Châu",
        "ten_npc": "Chú Đai",
        "level": 13,
        "hp": 64,
        "sp": 89,
        "hpx": 3,
        "spx": 3,
        "int": 4,
        "atk": 7,
        "def": 4,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15066,
        "idmap": 12081,
        "ten_map": "Thanh Chan Dinh 1",
        "thanh": "U Châu",
        "ten_npc": "Lý Ma Ma",
        "level": 13,
        "hp": 56,
        "sp": 92,
        "hpx": 1,
        "spx": 4,
        "int": 8,
        "atk": 1,
        "def": 4,
        "agi": 8,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15073,
        "idmap": 12081,
        "ten_map": "Thanh Chan Dinh 1",
        "thanh": "U Châu",
        "ten_npc": "Lưu Tiểu Đệ",
        "level": 13,
        "hp": 68,
        "sp": 89,
        "hpx": 4,
        "spx": 3,
        "int": 3,
        "atk": 5,
        "def": 5,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15107,
        "idmap": 12093,
        "ten_map": "DaiDien Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Cơ Cô Nương",
        "level": 13,
        "hp": 68,
        "sp": 84,
        "hpx": 4,
        "spx": 2,
        "int": 6,
        "atk": 4,
        "def": 3,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15124,
        "idmap": 12093,
        "ten_map": "DaiDien Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Ông Lữo Hà",
        "level": 13,
        "hp": 72,
        "sp": 90,
        "hpx": 5,
        "spx": 3,
        "int": 6,
        "atk": 2,
        "def": 5,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15188,
        "idmap": 12105,
        "ten_map": "P.Tao Truc Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "A Nghĩa",
        "level": 13,
        "hp": 68,
        "sp": 92,
        "hpx": 4,
        "spx": 4,
        "int": 3,
        "atk": 3,
        "def": 6,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15237,
        "idmap": 12176,
        "ten_map": "N.Ngo Tieu Muoi",
        "thanh": "U Châu",
        "ten_npc": "PhỏngChứcNương",
        "level": 13,
        "hp": 72,
        "sp": 89,
        "hpx": 5,
        "spx": 3,
        "int": 4,
        "atk": 4,
        "def": 3,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15246,
        "idmap": 12180,
        "ten_map": "Song Bac",
        "thanh": "U Châu",
        "ten_npc": "Thư Đồng",
        "level": 13,
        "hp": 72,
        "sp": 90,
        "hpx": 5,
        "spx": 3,
        "int": 7,
        "atk": 3,
        "def": 2,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15255,
        "idmap": 12180,
        "ten_map": "Song Bac",
        "thanh": "U Châu",
        "ten_npc": "Lương Tam Lang",
        "level": 13,
        "hp": 60,
        "sp": 89,
        "hpx": 2,
        "spx": 3,
        "int": 3,
        "atk": 4,
        "def": 5,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15330,
        "idmap": 12301,
        "ten_map": "Dai Dien Chu Hau",
        "thanh": "U Châu",
        "ten_npc": "Cổ Đại Ma",
        "level": 13,
        "hp": 75,
        "sp": 92,
        "hpx": 6,
        "spx": 4,
        "int": 3,
        "atk": 4,
        "def": 3,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15349,
        "idmap": 12303,
        "ten_map": "Quan Phu BinhNguyen",
        "thanh": "U Châu",
        "ten_npc": "Giản thiếu phụ",
        "level": 13,
        "hp": 75,
        "sp": 96,
        "hpx": 6,
        "spx": 5,
        "int": 4,
        "atk": 3,
        "def": 2,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15355,
        "idmap": 12304,
        "ten_map": "Quan Phu TQ",
        "thanh": "U Châu",
        "ten_npc": "Cơ Đặc La",
        "level": 13,
        "hp": 72,
        "sp": 89,
        "hpx": 5,
        "spx": 3,
        "int": 4,
        "atk": 6,
        "def": 3,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15421,
        "idmap": 12408,
        "thanh": "U Châu",
        "ten_npc": "Noel trù sư",
        "level": 13,
        "hp": 75,
        "sp": 84,
        "hpx": 6,
        "spx": 2,
        "int": 1,
        "atk": 7,
        "def": 3,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16003,
        "idmap": 12413,
        "ten_map": "Thiet Thach Son Trang",
        "thanh": "U Châu",
        "ten_npc": "Chủ Lò Rèn",
        "level": 13,
        "hp": 72,
        "sp": 89,
        "hpx": 5,
        "spx": 3,
        "int": 4,
        "atk": 6,
        "def": 3,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16090,
        "idmap": 12508,
        "ten_map": "N.Dong Dai Hung Son 8",
        "thanh": "U Châu",
        "ten_npc": "Thợ rèn NamMan",
        "level": 13,
        "hp": 72,
        "sp": 89,
        "hpx": 5,
        "spx": 3,
        "int": 1,
        "atk": 10,
        "def": 1,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17027,
        "idmap": 12514,
        "thanh": "U Châu",
        "ten_npc": "Báo quân sĩ",
        "level": 13,
        "hp": 72,
        "sp": 84,
        "hpx": 5,
        "spx": 2,
        "int": 1,
        "atk": 7,
        "def": 4,
        "agi": 6,
        "item1": 27022,
        "item2": 27022,
        "item3": 27034,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10114,
        "idmap": 10819,
        "ten_npc": "Huỳnh Đại Sĩ",
        "level": 14,
        "hp": 77,
        "sp": 92,
        "hpx": 6,
        "spx": 4,
        "int": 6,
        "atk": 5,
        "def": 5,
        "agi": 3,
        "item1": 27036,
        "item2": 26115,
        "item3": 27046,
        "item4": 50001,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10197,
        "idmap": 10911,
        "ten_npc": "Hổ Sĩ.Kh.Vàng",
        "level": 14,
        "hp": 74,
        "sp": 126,
        "hpx": 5,
        "spx": 2,
        "int": 3,
        "atk": 7,
        "def": 3,
        "agi": 10,
        "item1": 26047,
        "item2": 26042,
        "item3": 26042,
        "item4": 43003,
        "item5": 43033,
        "item6": 0
    },
    {
        "idnpc": 14103,
        "idmap": 11583,
        "thanh": "Thanh Châu",
        "ten_npc": "Triệu Khiêm",
        "level": 14,
        "hp": 98,
        "sp": 91,
        "hpx": 6,
        "spx": 3,
        "int": 1,
        "atk": 5,
        "def": 5,
        "agi": 10,
        "item1": 26082,
        "item2": 26083,
        "item3": 26055,
        "item4": 49001,
        "item5": 46034,
        "item6": 0
    },
    {
        "idnpc": 15063,
        "idmap": 12081,
        "ten_map": "Thanh Chan Dinh 1",
        "thanh": "U Châu",
        "ten_npc": "Mạc Đại Ca",
        "level": 14,
        "hp": 77,
        "sp": 90,
        "hpx": 6,
        "spx": 3,
        "int": 4,
        "atk": 6,
        "def": 5,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15067,
        "idmap": 12081,
        "ten_map": "Thanh Chan Dinh 1",
        "thanh": "U Châu",
        "ten_npc": "Trịnh Lữo Đầu",
        "level": 14,
        "hp": 67,
        "sp": 86,
        "hpx": 3,
        "spx": 2,
        "int": 1,
        "atk": 9,
        "def": 6,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15078,
        "idmap": 12091,
        "ten_map": "Dai Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Bà Lữo Thẩm",
        "level": 14,
        "hp": 70,
        "sp": 86,
        "hpx": 4,
        "spx": 2,
        "int": 3,
        "atk": 6,
        "def": 3,
        "agi": 10,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15184,
        "idmap": 12104,
        "ten_map": "Tao Phu Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Tiểu Thi",
        "level": 14,
        "hp": 77,
        "sp": 92,
        "hpx": 6,
        "spx": 4,
        "int": 7,
        "atk": 6,
        "def": 3,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15201,
        "idmap": 12132,
        "ten_map": "N.Phuc Co Nuong",
        "thanh": "U Châu",
        "ten_npc": "Đại Hùng",
        "level": 14,
        "hp": 82,
        "sp": 86,
        "hpx": 7,
        "spx": 2,
        "int": 3,
        "atk": 6,
        "def": 6,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15206,
        "idmap": 12134,
        "ten_map": "N.Cong Ton Toan Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Phù Dung",
        "level": 14,
        "hp": 77,
        "sp": 92,
        "hpx": 6,
        "spx": 4,
        "int": 6,
        "atk": 5,
        "def": 3,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15222,
        "idmap": 12139,
        "ten_map": "N.Quach Gia TQ",
        "thanh": "U Châu",
        "ten_npc": "Băng Băng",
        "level": 14,
        "hp": 77,
        "sp": 92,
        "hpx": 6,
        "spx": 4,
        "int": 8,
        "atk": 5,
        "def": 3,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15227,
        "idmap": 12171,
        "ten_map": "N.Khuan Vac Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Châu Thị",
        "level": 14,
        "hp": 77,
        "sp": 91,
        "hpx": 6,
        "spx": 3,
        "int": 3,
        "atk": 4,
        "def": 6,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15264,
        "idmap": 12205,
        "thanh": "U Châu",
        "ten_npc": "Tú Nhi",
        "level": 14,
        "hp": 74,
        "sp": 90,
        "hpx": 5,
        "spx": 3,
        "int": 8,
        "atk": 5,
        "def": 4,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15299,
        "idmap": 12262,
        "ten_map": "Tien Trang Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Nmúa thải y",
        "level": 14,
        "hp": 82,
        "sp": 92,
        "hpx": 7,
        "spx": 4,
        "int": 4,
        "atk": 2,
        "def": 5,
        "agi": 8,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15328,
        "idmap": 12301,
        "ten_map": "Dai Dien Chu Hau",
        "thanh": "U Châu",
        "ten_npc": "Thái Bì Tượng",
        "level": 14,
        "hp": 77,
        "sp": 98,
        "hpx": 6,
        "spx": 5,
        "int": 7,
        "atk": 3,
        "def": 4,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15365,
        "idmap": 12307,
        "thanh": "U Châu",
        "ten_npc": "Ô Lan Đồ Nhã",
        "level": 14,
        "hp": 82,
        "sp": 98,
        "hpx": 7,
        "spx": 5,
        "int": 6,
        "atk": 4,
        "def": 3,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15389,
        "idmap": 12403,
        "ten_map": "Nha Tranh Tu Vo Son",
        "thanh": "U Châu",
        "ten_npc": "Kiến Tố",
        "level": 14,
        "hp": 74,
        "sp": 91,
        "hpx": 5,
        "spx": 3,
        "int": 3,
        "atk": 6,
        "def": 5,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15390,
        "idmap": 12403,
        "ten_map": "Nha Tranh Tu Vo Son",
        "thanh": "U Châu",
        "ten_npc": "Đô Mạt",
        "level": 14,
        "hp": 74,
        "sp": 91,
        "hpx": 5,
        "spx": 3,
        "int": 3,
        "atk": 6,
        "def": 5,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16028,
        "idmap": 12419,
        "thanh": "U Châu",
        "ten_npc": "NhàBuôn Dấu Ấn",
        "level": 14,
        "hp": 74,
        "sp": 91,
        "hpx": 5,
        "spx": 3,
        "int": 3,
        "atk": 6,
        "def": 5,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16029,
        "idmap": 12419,
        "thanh": "U Châu",
        "ten_npc": "NhàBuôn Dấu Ấn",
        "level": 14,
        "hp": 74,
        "sp": 91,
        "hpx": 5,
        "spx": 3,
        "int": 3,
        "atk": 6,
        "def": 5,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16055,
        "idmap": 12432,
        "ten_map": "Thong Do Nha Quan",
        "thanh": "U Châu",
        "ten_npc": "Tiềntrangdumục",
        "level": 14,
        "hp": 77,
        "sp": 98,
        "hpx": 6,
        "spx": 5,
        "int": 7,
        "atk": 3,
        "def": 4,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17052,
        "idmap": 12519,
        "thanh": "U Châu",
        "ten_npc": "Mãnh Binh",
        "level": 14,
        "hp": 82,
        "sp": 86,
        "hpx": 7,
        "spx": 2,
        "int": 2,
        "atk": 8,
        "def": 5,
        "agi": 5,
        "item1": 27029,
        "item2": 27029,
        "item3": 42001,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10072,
        "idmap": 10812,
        "ten_npc": "Th.Bình Võ Sĩ",
        "level": 15,
        "hp": 73,
        "sp": 94,
        "hpx": 4,
        "spx": 4,
        "int": 2,
        "atk": 8,
        "def": 5,
        "agi": 7,
        "item1": 26045,
        "item2": 26080,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10194,
        "idmap": 10910,
        "ten_npc": "Đào Thăng",
        "level": 15,
        "hp": 100,
        "sp": 131,
        "hpx": 5,
        "spx": 3,
        "int": 3,
        "atk": 12,
        "def": 5,
        "agi": 4,
        "item1": 26003,
        "item2": 26003,
        "item3": 27038,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11078,
        "idmap": 10991,
        "ten_map": "Loi Dai Doan P",
        "ten_npc": "A Mạn",
        "level": 15,
        "hp": 104,
        "sp": 95,
        "hpx": 6,
        "spx": 4,
        "int": 7,
        "atk": 6,
        "def": 3,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11154,
        "idmap": 11011,
        "ten_map": "Bac Hai Thanh Mon",
        "thanh": "Thanh Châu",
        "ten_npc": "TTháo pthương",
        "level": 15,
        "hp": 81,
        "sp": 87,
        "hpx": 6,
        "spx": 2,
        "int": 8,
        "atk": 5,
        "def": 3,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11177,
        "idmap": 11012,
        "ten_map": "Dai Do Bac Hai",
        "thanh": "Thanh Châu",
        "ten_npc": "TTháo trúngtên",
        "level": 15,
        "hp": 81,
        "sp": 87,
        "hpx": 6,
        "spx": 2,
        "int": 8,
        "atk": 5,
        "def": 3,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12129,
        "idmap": 11423,
        "ten_map": "Lang Ta Cung",
        "thanh": "Thanh Châu",
        "ten_npc": "BThốngtrúngtên",
        "level": 15,
        "hp": 65,
        "sp": 106,
        "hpx": 2,
        "spx": 4,
        "int": 10,
        "atk": 1,
        "def": 5,
        "agi": 8,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12134,
        "idmap": 11431,
        "ten_map": "Rung Thai Son",
        "thanh": "Thanh Châu",
        "ten_npc": "MSiêu bịthương",
        "level": 15,
        "hp": 81,
        "sp": 114,
        "hpx": 6,
        "spx": 6,
        "int": 2,
        "atk": 7,
        "def": 4,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15061,
        "idmap": 12081,
        "ten_map": "Thanh Chan Dinh 1",
        "thanh": "U Châu",
        "ten_npc": "Tô Thương",
        "level": 15,
        "hp": 81,
        "sp": 94,
        "hpx": 6,
        "spx": 4,
        "int": 5,
        "atk": 5,
        "def": 5,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15065,
        "idmap": 12081,
        "ten_map": "Thanh Chan Dinh 1",
        "thanh": "U Châu",
        "ten_npc": "Tà Lữo Bác",
        "level": 15,
        "hp": 81,
        "sp": 94,
        "hpx": 6,
        "spx": 4,
        "int": 6,
        "atk": 4,
        "def": 3,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15076,
        "idmap": 12083,
        "thanh": "U Châu",
        "ten_npc": "Triệu Lữo Bác",
        "level": 15,
        "hp": 81,
        "sp": 93,
        "hpx": 6,
        "spx": 4,
        "int": 1,
        "atk": 9,
        "def": 6,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15186,
        "idmap": 12104,
        "ten_map": "Tao Phu Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Tr.Thôn Trưởng",
        "level": 15,
        "hp": 85,
        "sp": 91,
        "hpx": 7,
        "spx": 3,
        "int": 6,
        "atk": 6,
        "def": 5,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15203,
        "idmap": 12133,
        "ten_map": "N.Luu Truong Quy Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Tiểu Mẩn",
        "level": 15,
        "hp": 89,
        "sp": 87,
        "hpx": 8,
        "spx": 2,
        "int": 4,
        "atk": 5,
        "def": 4,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15224,
        "idmap": 12139,
        "ten_map": "N.Quach Gia TQ",
        "thanh": "U Châu",
        "ten_npc": "Khùy Nương",
        "level": 15,
        "hp": 81,
        "sp": 94,
        "hpx": 6,
        "spx": 4,
        "int": 6,
        "atk": 6,
        "def": 5,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15254,
        "idmap": 12180,
        "ten_map": "Song Bac",
        "thanh": "U Châu",
        "ten_npc": "Lương Nhị Lang",
        "level": 15,
        "hp": 85,
        "sp": 94,
        "hpx": 7,
        "spx": 4,
        "int": 4,
        "atk": 7,
        "def": 3,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15293,
        "idmap": 12244,
        "ten_map": "Nha Tro Viet Lai",
        "thanh": "U Châu",
        "ten_npc": "Nghệ nhân g hồ",
        "level": 15,
        "hp": 85,
        "sp": 95,
        "hpx": 7,
        "spx": 4,
        "int": 8,
        "atk": 3,
        "def": 3,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15320,
        "idmap": 12301,
        "ten_map": "Dai Dien Chu Hau",
        "thanh": "U Châu",
        "ten_npc": "Ngọc Tượng",
        "level": 15,
        "hp": 89,
        "sp": 91,
        "hpx": 8,
        "spx": 3,
        "int": 5,
        "atk": 3,
        "def": 5,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15335,
        "idmap": 12302,
        "ten_map": "Quan Phu Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Triệu Đại Thúc",
        "level": 15,
        "hp": 81,
        "sp": 99,
        "hpx": 6,
        "spx": 5,
        "int": 5,
        "atk": 1,
        "def": 8,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15372,
        "idmap": 12310,
        "ten_map": "Lac Hai Quan Phu",
        "thanh": "U Châu",
        "ten_npc": "Bạch Phụ",
        "level": 15,
        "hp": 81,
        "sp": 95,
        "hpx": 6,
        "spx": 4,
        "int": 8,
        "atk": 3,
        "def": 5,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15380,
        "idmap": 12401,
        "ten_map": "Tien Do Tu Vo Son",
        "thanh": "U Châu",
        "ten_npc": "Tưởng tiểu đệ",
        "level": 15,
        "hp": 89,
        "sp": 91,
        "hpx": 8,
        "spx": 3,
        "int": 5,
        "atk": 3,
        "def": 5,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15387,
        "idmap": 12402,
        "ten_map": "Tu Vo Son",
        "thanh": "U Châu",
        "ten_npc": "Bất Cư Trưng",
        "level": 15,
        "hp": 81,
        "sp": 95,
        "hpx": 6,
        "spx": 4,
        "int": 8,
        "atk": 5,
        "def": 4,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16026,
        "idmap": 12419,
        "thanh": "U Châu",
        "ten_npc": "NhàBuôn ViênTử",
        "level": 15,
        "hp": 81,
        "sp": 95,
        "hpx": 6,
        "spx": 4,
        "int": 8,
        "atk": 5,
        "def": 4,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16053,
        "idmap": 12432,
        "ten_map": "Thong Do Nha Quan",
        "thanh": "U Châu",
        "ten_npc": "Thương Nhân",
        "level": 15,
        "hp": 81,
        "sp": 95,
        "hpx": 6,
        "spx": 4,
        "int": 8,
        "atk": 5,
        "def": 4,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16080,
        "idmap": 12508,
        "thanh": "U Châu",
        "ten_npc": "Chủ tiền trang",
        "level": 15,
        "hp": 81,
        "sp": 95,
        "hpx": 6,
        "spx": 4,
        "int": 8,
        "atk": 5,
        "def": 4,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16097,
        "idmap": 12511,
        "thanh": "U Châu",
        "ten_npc": "Thợ rèn du mục",
        "level": 15,
        "hp": 85,
        "sp": 94,
        "hpx": 7,
        "spx": 4,
        "int": 2,
        "atk": 8,
        "def": 2,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17014,
        "idmap": 12513,
        "thanh": "U Châu",
        "ten_npc": "Tráng Sĩ Binh",
        "level": 15,
        "hp": 97,
        "sp": 87,
        "hpx": 10,
        "spx": 2,
        "int": 1,
        "atk": 7,
        "def": 7,
        "agi": 3,
        "item1": 26047,
        "item2": 26082,
        "item3": 26082,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17227,
        "idmap": 12585,
        "thanh": "U Châu",
        "ten_npc": "Mộ Binh Trưởng",
        "level": 15,
        "hp": 81,
        "sp": 94,
        "hpx": 6,
        "spx": 4,
        "int": 6,
        "atk": 7,
        "def": 5,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10069,
        "idmap": 10811,
        "ten_npc": "Võ Vệ Sĩ",
        "level": 16,
        "hp": 86,
        "sp": 89,
        "hpx": 6,
        "spx": 2,
        "int": 2,
        "atk": 9,
        "def": 7,
        "agi": 5,
        "item1": 27035,
        "item2": 27002,
        "item3": 27002,
        "item4": 46016,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10193,
        "idmap": 10910,
        "ten_npc": "Đỗ Trưởng",
        "level": 16,
        "hp": 110,
        "sp": 129,
        "hpx": 6,
        "spx": 2,
        "int": 3,
        "atk": 11,
        "def": 6,
        "agi": 6,
        "item1": 26003,
        "item2": 27041,
        "item3": 27036,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10202,
        "idmap": 10913,
        "ten_npc": "Hắt Sơn Võ Sĩ",
        "level": 16,
        "hp": 116,
        "sp": 97,
        "hpx": 6,
        "spx": 4,
        "int": 5,
        "atk": 7,
        "def": 6,
        "agi": 4,
        "item1": 26083,
        "item2": 26084,
        "item3": 41013,
        "item4": 46018,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12051,
        "idmap": 11174,
        "ten_map": "N.Thai Su Tu Phung Lai Thon",
        "thanh": "Thanh Châu",
        "ten_npc": "Tiểu Gia Cát",
        "level": 16,
        "hp": 76,
        "sp": 93,
        "hpx": 4,
        "spx": 3,
        "int": 8,
        "atk": 4,
        "def": 7,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13083,
        "idmap": 11535,
        "ten_map": "Dong Quy Than 5",
        "thanh": "Thanh Châu",
        "ten_npc": "Từ Thị",
        "level": 16,
        "hp": 112,
        "sp": 93,
        "hpx": 7,
        "spx": 3,
        "int": 11,
        "atk": 7,
        "def": 3,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15068,
        "idmap": 12081,
        "ten_map": "Thanh Chan Dinh 1",
        "thanh": "U Châu",
        "ten_npc": "Cổ Tiều Phu",
        "level": 16,
        "hp": 86,
        "sp": 97,
        "hpx": 6,
        "spx": 4,
        "int": 6,
        "atk": 7,
        "def": 2,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15069,
        "idmap": 12081,
        "ten_map": "Thanh Chan Dinh 1",
        "thanh": "U Châu",
        "ten_npc": "Quách Sanh",
        "level": 16,
        "hp": 76,
        "sp": 93,
        "hpx": 4,
        "spx": 3,
        "int": 5,
        "atk": 10,
        "def": 4,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15098,
        "idmap": 12093,
        "ten_map": "DaiDien Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Má Trần",
        "level": 16,
        "hp": 86,
        "sp": 96,
        "hpx": 6,
        "spx": 4,
        "int": 3,
        "atk": 3,
        "def": 6,
        "agi": 9,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15137,
        "idmap": 12095,
        "ten_map": "Tang Cao Khong Tuoc Dai",
        "thanh": "U Châu",
        "ten_npc": "HồngThươngNhân",
        "level": 16,
        "hp": 92,
        "sp": 93,
        "hpx": 8,
        "spx": 3,
        "int": 8,
        "atk": 3,
        "def": 4,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15177,
        "idmap": 12104,
        "ten_map": "Tao Phu Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Nô Giai Đề",
        "level": 16,
        "hp": 86,
        "sp": 97,
        "hpx": 6,
        "spx": 4,
        "int": 5,
        "atk": 3,
        "def": 7,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15190,
        "idmap": 12106,
        "ten_map": "P.Tao Phi Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Lai Tiều Phu",
        "level": 16,
        "hp": 92,
        "sp": 89,
        "hpx": 8,
        "spx": 2,
        "int": 3,
        "atk": 5,
        "def": 6,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15204,
        "idmap": 12133,
        "ten_map": "N.Luu Truong Quy Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Âu học sĩ",
        "level": 16,
        "hp": 92,
        "sp": 89,
        "hpx": 8,
        "spx": 2,
        "int": 7,
        "atk": 4,
        "def": 4,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15210,
        "idmap": 12136,
        "ten_map": "N.Gian Ung TQ",
        "thanh": "U Châu",
        "ten_npc": "Tiểu Song",
        "level": 16,
        "hp": 86,
        "sp": 96,
        "hpx": 6,
        "spx": 4,
        "int": 3,
        "atk": 5,
        "def": 7,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15213,
        "idmap": 12138,
        "ten_map": "Pho Ho Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Châu Hiếu Liêm",
        "level": 16,
        "hp": 86,
        "sp": 97,
        "hpx": 6,
        "spx": 4,
        "int": 4,
        "atk": 6,
        "def": 4,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15232,
        "idmap": 12174,
        "ten_map": "N.Luu A Ma Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Hoa Đồng",
        "level": 16,
        "hp": 86,
        "sp": 96,
        "hpx": 6,
        "spx": 4,
        "int": 3,
        "atk": 7,
        "def": 6,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15252,
        "idmap": 12180,
        "ten_map": "Song Bac",
        "thanh": "U Châu",
        "ten_npc": "Mục nhị ca",
        "level": 16,
        "hp": 86,
        "sp": 93,
        "hpx": 6,
        "spx": 3,
        "int": 2,
        "atk": 6,
        "def": 8,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15265,
        "idmap": 12205,
        "thanh": "U Châu",
        "ten_npc": "Hương Nhi",
        "level": 16,
        "hp": 76,
        "sp": 89,
        "hpx": 4,
        "spx": 2,
        "int": 7,
        "atk": 8,
        "def": 4,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15294,
        "idmap": 12244,
        "ten_map": "Nha Tro Viet Lai",
        "thanh": "U Châu",
        "ten_npc": "Kim Đại Bằng",
        "level": 16,
        "hp": 86,
        "sp": 93,
        "hpx": 6,
        "spx": 3,
        "int": 2,
        "atk": 6,
        "def": 8,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15300,
        "idmap": 12263,
        "ten_map": "Tien Trang TQ",
        "thanh": "U Châu",
        "ten_npc": "Thương Binh",
        "level": 16,
        "hp": 86,
        "sp": 93,
        "hpx": 6,
        "spx": 3,
        "int": 2,
        "atk": 6,
        "def": 8,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15310,
        "idmap": 12281,
        "ten_map": "Quan Tra",
        "thanh": "U Châu",
        "ten_npc": "Thuần Tượng Sư",
        "level": 16,
        "hp": 86,
        "sp": 101,
        "hpx": 6,
        "spx": 5,
        "int": 3,
        "atk": 5,
        "def": 6,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15315,
        "idmap": 12282,
        "ten_map": "Quan Tra Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Sa Da",
        "level": 16,
        "hp": 86,
        "sp": 101,
        "hpx": 6,
        "spx": 5,
        "int": 7,
        "atk": 4,
        "def": 5,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15345,
        "idmap": 12303,
        "ten_map": "Quan Phu BinhNguyen",
        "thanh": "U Châu",
        "ten_npc": "Trưng Muội Tử",
        "level": 16,
        "hp": 86,
        "sp": 93,
        "hpx": 6,
        "spx": 3,
        "int": 6,
        "atk": 4,
        "def": 5,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15395,
        "idmap": 12404,
        "ten_map": "Nha Nho Tu Vo Son",
        "thanh": "U Châu",
        "ten_npc": "Nùng Kỳ",
        "level": 16,
        "hp": 86,
        "sp": 93,
        "hpx": 6,
        "spx": 3,
        "int": 3,
        "atk": 7,
        "def": 5,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15441,
        "idmap": 12409,
        "thanh": "U Châu",
        "ten_npc": "Tiểu quỷ vương",
        "level": 16,
        "hp": 86,
        "sp": 89,
        "hpx": 6,
        "spx": 2,
        "int": 1,
        "atk": 11,
        "def": 2,
        "agi": 8,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16024,
        "idmap": 12419,
        "thanh": "U Châu",
        "ten_npc": "Thươngnhân BDY",
        "level": 16,
        "hp": 86,
        "sp": 97,
        "hpx": 6,
        "spx": 4,
        "int": 4,
        "atk": 7,
        "def": 6,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16035,
        "idmap": 12421,
        "ten_map": "Lo Long Khau",
        "thanh": "U Châu",
        "ten_npc": "Thương Nhân",
        "level": 16,
        "hp": 86,
        "sp": 96,
        "hpx": 6,
        "spx": 4,
        "int": 3,
        "atk": 4,
        "def": 6,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16051,
        "idmap": 12432,
        "ten_map": "Thong Do Nha Quan",
        "thanh": "U Châu",
        "ten_npc": "Thương Nhân",
        "level": 16,
        "hp": 86,
        "sp": 96,
        "hpx": 6,
        "spx": 4,
        "int": 3,
        "atk": 4,
        "def": 6,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16056,
        "idmap": 12432,
        "ten_map": "Thong Do Nha Quan",
        "thanh": "U Châu",
        "ten_npc": "Vu Y",
        "level": 16,
        "hp": 86,
        "sp": 93,
        "hpx": 6,
        "spx": 3,
        "int": 2,
        "atk": 6,
        "def": 8,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16083,
        "idmap": 12508,
        "thanh": "U Châu",
        "ten_npc": "Chủ tiệm thuốc",
        "level": 16,
        "hp": 86,
        "sp": 96,
        "hpx": 6,
        "spx": 4,
        "int": 3,
        "atk": 4,
        "def": 6,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16085,
        "idmap": 12508,
        "thanh": "U Châu",
        "ten_npc": "Tiểu nhị",
        "level": 16,
        "hp": 86,
        "sp": 109,
        "hpx": 6,
        "spx": 7,
        "int": 3,
        "atk": 7,
        "def": 2,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10116,
        "idmap": 10819,
        "ten_npc": "Ch.Sĩ Kh.Vàng",
        "level": 17,
        "hp": 90,
        "sp": 90,
        "hpx": 7,
        "spx": 2,
        "int": 2,
        "atk": 10,
        "def": 9,
        "agi": 4,
        "item1": 26169,
        "item2": 26169,
        "item3": 27035,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10203,
        "idmap": 10913,
        "ten_npc": "HắtSơnGiápBinh",
        "level": 17,
        "hp": 120,
        "sp": 99,
        "hpx": 7,
        "spx": 4,
        "int": 4,
        "atk": 5,
        "def": 8,
        "agi": 6,
        "item1": 26084,
        "item2": 26083,
        "item3": 41002,
        "item4": 46016,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14134,
        "idmap": 11585,
        "thanh": "Thanh Châu",
        "ten_npc": "Tên Ăn Trộm",
        "level": 17,
        "hp": 75,
        "sp": 99,
        "hpx": 3,
        "spx": 4,
        "int": 2,
        "atk": 8,
        "def": 6,
        "agi": 11,
        "item1": 26039,
        "item2": 26039,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15072,
        "idmap": 12081,
        "ten_map": "Thanh Chan Dinh 1",
        "thanh": "U Châu",
        "ten_npc": "Lưu A Ma",
        "level": 17,
        "hp": 90,
        "sp": 100,
        "hpx": 7,
        "spx": 4,
        "int": 8,
        "atk": 1,
        "def": 4,
        "agi": 10,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15128,
        "idmap": 12093,
        "ten_map": "DaiDien Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Phục Cô Nương",
        "level": 17,
        "hp": 103,
        "sp": 102,
        "hpx": 10,
        "spx": 5,
        "int": 8,
        "atk": 7,
        "def": 1,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15159,
        "idmap": 12096,
        "ten_map": "Bac Thang Khong Tuoc2",
        "thanh": "U Châu",
        "ten_npc": "Vạn cô nương",
        "level": 17,
        "hp": 111,
        "sp": 106,
        "hpx": 12,
        "spx": 6,
        "int": 2,
        "atk": 7,
        "def": 2,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15162,
        "idmap": 12096,
        "ten_map": "Bac Thang Khong Tuoc2",
        "thanh": "U Châu",
        "ten_npc": "LôiĐàiTruyềnSư",
        "level": 17,
        "hp": 111,
        "sp": 106,
        "hpx": 12,
        "spx": 6,
        "int": 2,
        "atk": 7,
        "def": 2,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15182,
        "idmap": 12104,
        "ten_map": "Tao Phu Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Phụng Nghi",
        "level": 17,
        "hp": 90,
        "sp": 100,
        "hpx": 7,
        "spx": 4,
        "int": 7,
        "atk": 5,
        "def": 4,
        "agi": 8,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15198,
        "idmap": 12108,
        "ten_map": "P.An Phuc Dong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Lữ Quý Phụ",
        "level": 17,
        "hp": 91,
        "sp": 106,
        "hpx": 7,
        "spx": 6,
        "int": 6,
        "atk": 8,
        "def": 5,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15211,
        "idmap": 12137,
        "ten_map": "N.Co Tieu Phu TQ",
        "thanh": "U Châu",
        "ten_npc": "Ngô Liệp Hộ",
        "level": 17,
        "hp": 90,
        "sp": 99,
        "hpx": 7,
        "spx": 4,
        "int": 3,
        "atk": 8,
        "def": 6,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15220,
        "idmap": 12139,
        "ten_map": "N.Quach Gia TQ",
        "thanh": "U Châu",
        "ten_npc": "Lan Lan",
        "level": 17,
        "hp": 90,
        "sp": 99,
        "hpx": 7,
        "spx": 4,
        "int": 4,
        "atk": 6,
        "def": 5,
        "agi": 9,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15226,
        "idmap": 12140,
        "ten_map": "Diep Thanh Quan Dich",
        "thanh": "U Châu",
        "ten_npc": "Chủ LoạnĐấuĐài",
        "level": 17,
        "hp": 90,
        "sp": 99,
        "hpx": 7,
        "spx": 4,
        "int": 4,
        "atk": 6,
        "def": 5,
        "agi": 9,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15247,
        "idmap": 12180,
        "ten_map": "Song Bac",
        "thanh": "U Châu",
        "ten_npc": "Kim Phu Nhân",
        "level": 17,
        "hp": 87,
        "sp": 100,
        "hpx": 6,
        "spx": 4,
        "int": 8,
        "atk": 4,
        "def": 6,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15249,
        "idmap": 12180,
        "ten_map": "Song Bac",
        "thanh": "U Châu",
        "ten_npc": "Thư Nhị Gia",
        "level": 17,
        "hp": 95,
        "sp": 94,
        "hpx": 8,
        "spx": 3,
        "int": 9,
        "atk": 6,
        "def": 4,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15261,
        "idmap": 12204,
        "ten_map": "Tiem Sat TQ",
        "thanh": "U Châu",
        "ten_npc": "Dương Phong",
        "level": 17,
        "hp": 90,
        "sp": 100,
        "hpx": 7,
        "spx": 4,
        "int": 9,
        "atk": 5,
        "def": 4,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15266,
        "idmap": 12206,
        "thanh": "U Châu",
        "ten_npc": "Lục Lạc",
        "level": 17,
        "hp": 75,
        "sp": 99,
        "hpx": 3,
        "spx": 4,
        "int": 2,
        "atk": 10,
        "def": 6,
        "agi": 10,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15273,
        "idmap": 12225,
        "thanh": "U Châu",
        "ten_npc": "Bác Bánh Trưng",
        "level": 17,
        "hp": 90,
        "sp": 106,
        "hpx": 7,
        "spx": 6,
        "int": 5,
        "atk": 4,
        "def": 6,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15288,
        "idmap": 12243,
        "ten_map": "Nha Tro Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Tề phu nhân",
        "level": 17,
        "hp": 90,
        "sp": 100,
        "hpx": 7,
        "spx": 4,
        "int": 8,
        "atk": 5,
        "def": 6,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15375,
        "idmap": 12310,
        "ten_map": "Lac Hai Quan Phu",
        "thanh": "U Châu",
        "ten_npc": "Nạp Lí Tư",
        "level": 17,
        "hp": 90,
        "sp": 100,
        "hpx": 7,
        "spx": 4,
        "int": 7,
        "atk": 3,
        "def": 8,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16084,
        "idmap": 12508,
        "thanh": "U Châu",
        "ten_npc": "Chủ nhà trọ",
        "level": 17,
        "hp": 90,
        "sp": 94,
        "hpx": 7,
        "spx": 3,
        "int": 10,
        "atk": 2,
        "def": 5,
        "agi": 8,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10045,
        "idmap": 10807,
        "ten_npc": "Binh Du Kích",
        "level": 18,
        "hp": 102,
        "sp": 96,
        "hpx": 9,
        "spx": 3,
        "int": 2,
        "atk": 10,
        "def": 4,
        "agi": 8,
        "item1": 26100,
        "item2": 26165,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10204,
        "idmap": 10914,
        "ten_npc": "HắtSơnQuỷLính",
        "level": 18,
        "hp": 125,
        "sp": 102,
        "hpx": 7,
        "spx": 4,
        "int": 5,
        "atk": 5,
        "def": 8,
        "agi": 6,
        "item1": 26084,
        "item2": 26084,
        "item3": 26083,
        "item4": 0,
        "item5": 36004,
        "item6": 0
    },
    {
        "idnpc": 12086,
        "idmap": 11301,
        "ten_map": "Dong Quan Quan Phu",
        "thanh": "Thanh Châu",
        "ten_npc": "Gia Các Vân",
        "level": 18,
        "hp": 95,
        "sp": 103,
        "hpx": 7,
        "spx": 5,
        "int": 10,
        "atk": 2,
        "def": 7,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15075,
        "idmap": 12082,
        "thanh": "U Châu",
        "ten_npc": "Long Cô Cô",
        "level": 18,
        "hp": 95,
        "sp": 102,
        "hpx": 7,
        "spx": 5,
        "int": 8,
        "atk": 8,
        "def": 4,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15114,
        "idmap": 12093,
        "ten_map": "DaiDien Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Hòang Sư Phụ",
        "level": 18,
        "hp": 95,
        "sp": 92,
        "hpx": 7,
        "spx": 2,
        "int": 4,
        "atk": 10,
        "def": 4,
        "agi": 9,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15176,
        "idmap": 12104,
        "ten_map": "Tao Phu Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Di Mã Thăng",
        "level": 18,
        "hp": 95,
        "sp": 102,
        "hpx": 7,
        "spx": 5,
        "int": 7,
        "atk": 7,
        "def": 6,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15185,
        "idmap": 12104,
        "ten_map": "Tao Phu Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "A Trình",
        "level": 18,
        "hp": 102,
        "sp": 96,
        "hpx": 9,
        "spx": 3,
        "int": 3,
        "atk": 5,
        "def": 7,
        "agi": 9,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15208,
        "idmap": 12135,
        "ten_map": "Nha Trieu Van Thuong Son",
        "thanh": "U Châu",
        "ten_npc": "Châu Ngư Phủ",
        "level": 18,
        "hp": 95,
        "sp": 102,
        "hpx": 7,
        "spx": 4,
        "int": 4,
        "atk": 7,
        "def": 7,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15212,
        "idmap": 12138,
        "ten_map": "Pho Ho Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Người Mọi Lô",
        "level": 18,
        "hp": 95,
        "sp": 101,
        "hpx": 7,
        "spx": 4,
        "int": 2,
        "atk": 9,
        "def": 7,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15228,
        "idmap": 12171,
        "ten_map": "N.Khuan Vac Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "TruyềnTốngSư",
        "level": 18,
        "hp": 95,
        "sp": 101,
        "hpx": 7,
        "spx": 4,
        "int": 2,
        "atk": 9,
        "def": 7,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15245,
        "idmap": 12180,
        "ten_map": "Song Bac",
        "thanh": "U Châu",
        "ten_npc": "Huỳnh Tiểu Nhị",
        "level": 18,
        "hp": 95,
        "sp": 101,
        "hpx": 7,
        "spx": 4,
        "int": 3,
        "atk": 8,
        "def": 4,
        "agi": 9,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15250,
        "idmap": 12180,
        "ten_map": "Song Bac",
        "thanh": "U Châu",
        "ten_npc": "Ông Chủ Hầu",
        "level": 18,
        "hp": 95,
        "sp": 108,
        "hpx": 7,
        "spx": 6,
        "int": 10,
        "atk": 5,
        "def": 4,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15260,
        "idmap": 12203,
        "ten_map": "Lo Ren Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Tửu Thốt",
        "level": 18,
        "hp": 95,
        "sp": 102,
        "hpx": 7,
        "spx": 5,
        "int": 7,
        "atk": 6,
        "def": 3,
        "agi": 8,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15307,
        "idmap": 12281,
        "ten_map": "Quan Tra",
        "thanh": "U Châu",
        "ten_npc": "Khương Tiểu Đệ",
        "level": 18,
        "hp": 94,
        "sp": 92,
        "hpx": 7,
        "spx": 2,
        "int": 10,
        "atk": 12,
        "def": 2,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15312,
        "idmap": 12281,
        "ten_map": "Quan Tra",
        "thanh": "U Châu",
        "ten_npc": "Sa Thôn Trưởng",
        "level": 18,
        "hp": 106,
        "sp": 100,
        "hpx": 10,
        "spx": 4,
        "int": 8,
        "atk": 6,
        "def": 3,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15313,
        "idmap": 12281,
        "ten_map": "Quan Tra",
        "thanh": "U Châu",
        "ten_npc": "O Ni",
        "level": 18,
        "hp": 95,
        "sp": 108,
        "hpx": 7,
        "spx": 6,
        "int": 4,
        "atk": 2,
        "def": 7,
        "agi": 10,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15324,
        "idmap": 12301,
        "ten_map": "Dai Dien Chu Hau",
        "thanh": "U Châu",
        "ten_npc": "DLiệpNhân",
        "level": 18,
        "hp": 95,
        "sp": 101,
        "hpx": 7,
        "spx": 4,
        "int": 2,
        "atk": 9,
        "def": 7,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15332,
        "idmap": 12301,
        "ten_map": "Dai Dien Chu Hau",
        "thanh": "U Châu",
        "ten_npc": "Huyện Lệnh",
        "level": 18,
        "hp": 98,
        "sp": 102,
        "hpx": 8,
        "spx": 5,
        "int": 8,
        "atk": 5,
        "def": 4,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15344,
        "idmap": 12303,
        "ten_map": "Quan Phu BinhNguyen",
        "thanh": "U Châu",
        "ten_npc": "Triệu Tiểu Ca",
        "level": 18,
        "hp": 98,
        "sp": 104,
        "hpx": 8,
        "spx": 5,
        "int": 3,
        "atk": 6,
        "def": 5,
        "agi": 8,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15357,
        "idmap": 12304,
        "ten_map": "Quan Phu TQ",
        "thanh": "U Châu",
        "ten_npc": "Mạn Ni Văn",
        "level": 18,
        "hp": 95,
        "sp": 101,
        "hpx": 7,
        "spx": 4,
        "int": 2,
        "atk": 9,
        "def": 7,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15364,
        "idmap": 12306,
        "thanh": "U Châu",
        "ten_npc": "Ô Nhật Na",
        "level": 18,
        "hp": 94,
        "sp": 92,
        "hpx": 7,
        "spx": 2,
        "int": 10,
        "atk": 2,
        "def": 2,
        "agi": 13,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15383,
        "idmap": 12401,
        "ten_map": "Tien Do Tu Vo Son",
        "thanh": "U Châu",
        "ten_npc": "Nga Tần",
        "level": 18,
        "hp": 98,
        "sp": 100,
        "hpx": 8,
        "spx": 4,
        "int": 8,
        "atk": 3,
        "def": 7,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15394,
        "idmap": 12404,
        "ten_map": "Nha Nho Tu Vo Son",
        "thanh": "U Châu",
        "ten_npc": "Gia Đặc Nô",
        "level": 18,
        "hp": 98,
        "sp": 104,
        "hpx": 8,
        "spx": 5,
        "int": 4,
        "atk": 6,
        "def": 5,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15417,
        "idmap": 12407,
        "thanh": "U Châu",
        "ten_npc": "Nhiệt nố ba la",
        "level": 18,
        "hp": 95,
        "sp": 101,
        "hpx": 7,
        "spx": 4,
        "int": 1,
        "atk": 11,
        "def": 5,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15454,
        "idmap": 12409,
        "thanh": "U Châu",
        "ten_npc": "Xảo tam nương",
        "level": 18,
        "hp": 158,
        "sp": 115,
        "hpx": 7,
        "spx": 5,
        "int": 10,
        "atk": 2,
        "def": 3,
        "agi": 9,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16034,
        "idmap": 12421,
        "ten_map": "Lo Long Khau",
        "thanh": "U Châu",
        "ten_npc": "Thương Nhân",
        "level": 18,
        "hp": 95,
        "sp": 103,
        "hpx": 7,
        "spx": 5,
        "int": 9,
        "atk": 2,
        "def": 6,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16049,
        "idmap": 12431,
        "ten_map": "Nha Quan",
        "thanh": "U Châu",
        "ten_npc": "Tạp Hoá Thương",
        "level": 18,
        "hp": 98,
        "sp": 102,
        "hpx": 8,
        "spx": 5,
        "int": 6,
        "atk": 8,
        "def": 6,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16058,
        "idmap": 12432,
        "ten_map": "Thong Do Nha Quan",
        "thanh": "U Châu",
        "ten_npc": "Người qlý ụtàu",
        "level": 18,
        "hp": 102,
        "sp": 108,
        "hpx": 9,
        "spx": 6,
        "int": 3,
        "atk": 8,
        "def": 5,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16059,
        "idmap": 12432,
        "ten_map": "Thong Do Nha Quan",
        "thanh": "U Châu",
        "ten_npc": "Ngườilàmthuyền",
        "level": 18,
        "hp": 95,
        "sp": 104,
        "hpx": 7,
        "spx": 5,
        "int": 3,
        "atk": 5,
        "def": 8,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16060,
        "idmap": 12441,
        "ten_map": "Gioi Kieu",
        "thanh": "U Châu",
        "ten_npc": "Ngườibánthuyền",
        "level": 18,
        "hp": 98,
        "sp": 104,
        "hpx": 8,
        "spx": 5,
        "int": 8,
        "atk": 5,
        "def": 3,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16061,
        "idmap": 12441,
        "ten_map": "Gioi Kieu",
        "thanh": "U Châu",
        "ten_npc": "Thủy chiến đốc",
        "level": 18,
        "hp": 95,
        "sp": 112,
        "hpx": 7,
        "spx": 7,
        "int": 3,
        "atk": 9,
        "def": 6,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16074,
        "idmap": 12505,
        "thanh": "U Châu",
        "ten_npc": "Thợ thuyền nhỏ",
        "level": 18,
        "hp": 95,
        "sp": 104,
        "hpx": 7,
        "spx": 5,
        "int": 3,
        "atk": 5,
        "def": 8,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16075,
        "idmap": 12505,
        "thanh": "U Châu",
        "ten_npc": "Thợ thuyền vừa",
        "level": 18,
        "hp": 95,
        "sp": 104,
        "hpx": 7,
        "spx": 5,
        "int": 3,
        "atk": 5,
        "def": 8,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16076,
        "idmap": 12505,
        "thanh": "U Châu",
        "ten_npc": "Thợ thuyền to",
        "level": 18,
        "hp": 95,
        "sp": 104,
        "hpx": 7,
        "spx": 5,
        "int": 3,
        "atk": 5,
        "def": 8,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16077,
        "idmap": 12506,
        "thanh": "U Châu",
        "ten_npc": "Quan cấp đạn",
        "level": 18,
        "hp": 95,
        "sp": 104,
        "hpx": 7,
        "spx": 5,
        "int": 3,
        "atk": 5,
        "def": 8,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16078,
        "idmap": 12507,
        "thanh": "U Châu",
        "ten_npc": "Q.cấp thủybinh",
        "level": 18,
        "hp": 95,
        "sp": 104,
        "hpx": 7,
        "spx": 5,
        "int": 3,
        "atk": 5,
        "def": 8,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16079,
        "idmap": 12508,
        "thanh": "U Châu",
        "ten_npc": "Quân giới sĩ",
        "level": 18,
        "hp": 95,
        "sp": 104,
        "hpx": 7,
        "spx": 5,
        "int": 3,
        "atk": 5,
        "def": 8,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16099,
        "idmap": 12511,
        "thanh": "U Châu",
        "ten_npc": "N.nhân tuỳquân",
        "level": 18,
        "hp": 95,
        "sp": 104,
        "hpx": 7,
        "spx": 5,
        "int": 3,
        "atk": 5,
        "def": 8,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17173,
        "idmap": 12563,
        "thanh": "U Châu",
        "ten_npc": "Lan Hà Bá",
        "level": 18,
        "hp": 95,
        "sp": 101,
        "hpx": 7,
        "spx": 4,
        "int": 3,
        "atk": 9,
        "def": 7,
        "agi": 5,
        "item1": 26046,
        "item2": 26053,
        "item3": 27054,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10085,
        "idmap": 10814,
        "ten_npc": "Trưởng Lính Ma",
        "level": 19,
        "hp": 99,
        "sp": 101,
        "hpx": 8,
        "spx": 4,
        "int": 10,
        "atk": 6,
        "def": 3,
        "agi": 7,
        "item1": 27003,
        "item2": 27003,
        "item3": 27003,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14130,
        "idmap": 11584,
        "thanh": "Thanh Châu",
        "ten_npc": "Thâm Thủy Tặc",
        "level": 19,
        "hp": 97,
        "sp": 97,
        "hpx": 7,
        "spx": 3,
        "int": 3,
        "atk": 10,
        "def": 9,
        "agi": 6,
        "item1": 26039,
        "item2": 26039,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14135,
        "idmap": 11585,
        "thanh": "Thanh Châu",
        "ten_npc": "Hoàng Hà Đạo",
        "level": 19,
        "hp": 93,
        "sp": 97,
        "hpx": 6,
        "spx": 3,
        "int": 6,
        "atk": 10,
        "def": 7,
        "agi": 6,
        "item1": 26039,
        "item2": 26039,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14250,
        "idmap": 11841,
        "ten_map": "Rung Vi Son 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Dân Mộc Tộc",
        "level": 19,
        "hp": 97,
        "sp": 126,
        "hpx": 7,
        "spx": 10,
        "int": 12,
        "atk": 3,
        "def": 3,
        "agi": 3,
        "item1": 34011,
        "item2": 26010,
        "item3": 34003,
        "item4": 34004,
        "item5": 50001,
        "item6": 0
    },
    {
        "idnpc": 15007,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Lý Lữo Đầu",
        "level": 19,
        "hp": 99,
        "sp": 97,
        "hpx": 8,
        "spx": 3,
        "int": 11,
        "atk": 5,
        "def": 4,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15085,
        "idmap": 12091,
        "ten_map": "Dai Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Điền Thọ",
        "level": 19,
        "hp": 105,
        "sp": 97,
        "hpx": 9,
        "spx": 3,
        "int": 2,
        "atk": 9,
        "def": 9,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15179,
        "idmap": 12104,
        "ten_map": "Tao Phu Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Đổng Phụng",
        "level": 19,
        "hp": 99,
        "sp": 105,
        "hpx": 8,
        "spx": 5,
        "int": 9,
        "atk": 6,
        "def": 6,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15199,
        "idmap": 12108,
        "ten_map": "P.An Phuc Dong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Nghệ Nhi",
        "level": 19,
        "hp": 109,
        "sp": 97,
        "hpx": 10,
        "spx": 3,
        "int": 9,
        "atk": 6,
        "def": 4,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15233,
        "idmap": 12174,
        "ten_map": "N.Luu A Ma Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Chiêu Đãi",
        "level": 19,
        "hp": 99,
        "sp": 105,
        "hpx": 8,
        "spx": 5,
        "int": 8,
        "atk": 7,
        "def": 4,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15235,
        "idmap": 12175,
        "ten_map": "N.Hua Thieu Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Hồ Hoa",
        "level": 19,
        "hp": 99,
        "sp": 105,
        "hpx": 8,
        "spx": 5,
        "int": 9,
        "atk": 2,
        "def": 7,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15238,
        "idmap": 12176,
        "ten_map": "N.Ngo Tieu Muoi",
        "thanh": "U Châu",
        "ten_npc": "Hoa Đồng",
        "level": 19,
        "hp": 99,
        "sp": 105,
        "hpx": 8,
        "spx": 5,
        "int": 8,
        "atk": 7,
        "def": 4,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15253,
        "idmap": 12180,
        "ten_map": "Song Bac",
        "thanh": "U Châu",
        "ten_npc": "Lương Đại Lang",
        "level": 19,
        "hp": 97,
        "sp": 104,
        "hpx": 7,
        "spx": 5,
        "int": 2,
        "atk": 7,
        "def": 6,
        "agi": 10,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15267,
        "idmap": 12206,
        "thanh": "U Châu",
        "ten_npc": "Cô Công Tôn",
        "level": 19,
        "hp": 99,
        "sp": 105,
        "hpx": 8,
        "spx": 5,
        "int": 9,
        "atk": 6,
        "def": 2,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15286,
        "idmap": 12242,
        "ten_map": "Nha Tro Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Tầng Lương Y",
        "level": 19,
        "hp": 97,
        "sp": 106,
        "hpx": 7,
        "spx": 5,
        "int": 11,
        "atk": 3,
        "def": 5,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15301,
        "idmap": 12264,
        "thanh": "U Châu",
        "ten_npc": "Khâu Bỉ Đặc",
        "level": 19,
        "hp": 97,
        "sp": 104,
        "hpx": 7,
        "spx": 5,
        "int": 2,
        "atk": 7,
        "def": 6,
        "agi": 10,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15363,
        "idmap": 12306,
        "thanh": "U Châu",
        "ten_npc": "Thợ rẽn ngựa",
        "level": 19,
        "hp": 101,
        "sp": 104,
        "hpx": 8,
        "spx": 5,
        "int": 2,
        "atk": 11,
        "def": 6,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15366,
        "idmap": 12307,
        "thanh": "U Châu",
        "ten_npc": "A Cổ Đạt Mộc",
        "level": 19,
        "hp": 105,
        "sp": 104,
        "hpx": 9,
        "spx": 5,
        "int": 3,
        "atk": 6,
        "def": 8,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16046,
        "idmap": 12431,
        "ten_map": "Nha Quan",
        "thanh": "U Châu",
        "ten_npc": "Tiền Trang Chủ",
        "level": 19,
        "hp": 99,
        "sp": 110,
        "hpx": 8,
        "spx": 6,
        "int": 9,
        "atk": 3,
        "def": 6,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16050,
        "idmap": 12431,
        "ten_map": "Nha Quan",
        "thanh": "U Châu",
        "ten_npc": "Thương Nhân",
        "level": 19,
        "hp": 99,
        "sp": 110,
        "hpx": 8,
        "spx": 6,
        "int": 9,
        "atk": 3,
        "def": 6,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16082,
        "idmap": 12508,
        "thanh": "U Châu",
        "ten_npc": "Chủ tiệm trà",
        "level": 19,
        "hp": 99,
        "sp": 110,
        "hpx": 8,
        "spx": 6,
        "int": 9,
        "atk": 3,
        "def": 6,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10125,
        "idmap": 10851,
        "ten_npc": "Binh Lang",
        "level": 20,
        "hp": 108,
        "sp": 94,
        "hpx": 9,
        "spx": 2,
        "int": 1,
        "atk": 12,
        "def": 6,
        "agi": 10,
        "item1": 26165,
        "item2": 27066,
        "item3": 39013,
        "item4": 33003,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10130,
        "idmap": 10853,
        "ten_npc": "Tử Sĩ",
        "level": 20,
        "hp": 112,
        "sp": 94,
        "hpx": 10,
        "spx": 2,
        "int": 3,
        "atk": 10,
        "def": 12,
        "agi": 3,
        "item1": 26165,
        "item2": 27066,
        "item3": 26039,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12176,
        "idmap": 11502,
        "ten_map": "Son Dong Thai Son 2",
        "thanh": "Thanh Châu",
        "ten_npc": "HTrungtrúngtên",
        "level": 20,
        "hp": 134,
        "sp": 113,
        "hpx": 8,
        "spx": 3,
        "int": 2,
        "atk": 12,
        "def": 10,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13129,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Đại võ quan",
        "level": 20,
        "hp": 104,
        "sp": 94,
        "hpx": 8,
        "spx": 2,
        "int": 10,
        "atk": 10,
        "def": 3,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13144,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "C.Ninhbịthương",
        "level": 20,
        "hp": 134,
        "sp": 113,
        "hpx": 8,
        "spx": 3,
        "int": 2,
        "atk": 12,
        "def": 9,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14140,
        "idmap": 11585,
        "thanh": "Thanh Châu",
        "ten_npc": "Hải Tặc",
        "level": 20,
        "hp": 95,
        "sp": 99,
        "hpx": 6,
        "spx": 3,
        "int": 4,
        "atk": 11,
        "def": 8,
        "agi": 8,
        "item1": 26040,
        "item2": 26118,
        "item3": 26117,
        "item4": 0,
        "item5": 26129,
        "item6": 0
    },
    {
        "idnpc": 14251,
        "idmap": 11841,
        "ten_map": "Rung Vi Son 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Mộc Tộc Trưởng",
        "level": 20,
        "hp": 95,
        "sp": 124,
        "hpx": 6,
        "spx": 9,
        "int": 10,
        "atk": 4,
        "def": 5,
        "agi": 6,
        "item1": 34012,
        "item2": 26010,
        "item3": 34003,
        "item4": 34004,
        "item5": 50001,
        "item6": 0
    },
    {
        "idnpc": 15189,
        "idmap": 12106,
        "ten_map": "P.Tao Phi Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Anh Tú",
        "level": 20,
        "hp": 112,
        "sp": 103,
        "hpx": 10,
        "spx": 4,
        "int": 4,
        "atk": 8,
        "def": 9,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15230,
        "idmap": 12172,
        "ten_map": "N.Ta Lao Dau Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Tiểu Hổ",
        "level": 20,
        "hp": 104,
        "sp": 106,
        "hpx": 8,
        "spx": 5,
        "int": 2,
        "atk": 11,
        "def": 8,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15248,
        "idmap": 12180,
        "ten_map": "Song Bac",
        "thanh": "U Châu",
        "ten_npc": "Tiểu Thuần Ca",
        "level": 20,
        "hp": 95,
        "sp": 108,
        "hpx": 6,
        "spx": 5,
        "int": 10,
        "atk": 4,
        "def": 7,
        "agi": 9,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15257,
        "idmap": 12180,
        "ten_map": "Song Bac",
        "thanh": "U Châu",
        "ten_npc": "A Quốc",
        "level": 20,
        "hp": 95,
        "sp": 111,
        "hpx": 6,
        "spx": 6,
        "int": 5,
        "atk": 7,
        "def": 6,
        "agi": 11,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15298,
        "idmap": 12261,
        "ten_map": "Tien Trang",
        "thanh": "U Châu",
        "ten_npc": "Vi Lạc Sư",
        "level": 20,
        "hp": 112,
        "sp": 99,
        "hpx": 10,
        "spx": 3,
        "int": 12,
        "atk": 3,
        "def": 7,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15321,
        "idmap": 12301,
        "ten_map": "Dai Dien Chu Hau",
        "thanh": "U Châu",
        "ten_npc": "Nha Dịch Ca",
        "level": 20,
        "hp": 108,
        "sp": 103,
        "hpx": 9,
        "spx": 4,
        "int": 3,
        "atk": 10,
        "def": 7,
        "agi": 8,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15325,
        "idmap": 12301,
        "ten_map": "Dai Dien Chu Hau",
        "thanh": "U Châu",
        "ten_npc": "Hoa Liệp Nhân",
        "level": 20,
        "hp": 100,
        "sp": 107,
        "hpx": 7,
        "spx": 5,
        "int": 5,
        "atk": 10,
        "def": 7,
        "agi": 8,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15346,
        "idmap": 12303,
        "ten_map": "Quan Phu BinhNguyen",
        "thanh": "U Châu",
        "ten_npc": "Lữ cô nương",
        "level": 20,
        "hp": 108,
        "sp": 111,
        "hpx": 9,
        "spx": 6,
        "int": 6,
        "atk": 8,
        "def": 5,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15354,
        "idmap": 12304,
        "ten_map": "Quan Phu TQ",
        "thanh": "U Châu",
        "ten_npc": "Tra Khắc La",
        "level": 20,
        "hp": 108,
        "sp": 111,
        "hpx": 9,
        "spx": 6,
        "int": 6,
        "atk": 8,
        "def": 5,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15376,
        "idmap": 12310,
        "ten_map": "Lac Hai Quan Phu",
        "thanh": "U Châu",
        "ten_npc": "Phan Na Lai",
        "level": 20,
        "hp": 108,
        "sp": 107,
        "hpx": 9,
        "spx": 5,
        "int": 6,
        "atk": 8,
        "def": 5,
        "agi": 8,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15385,
        "idmap": 12401,
        "ten_map": "Tien Do Tu Vo Son",
        "thanh": "U Châu",
        "ten_npc": "Mẹ Khương Tự",
        "level": 20,
        "hp": 108,
        "sp": 111,
        "hpx": 9,
        "spx": 6,
        "int": 6,
        "atk": 8,
        "def": 5,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15396,
        "idmap": 12404,
        "ten_map": "Nha Nho Tu Vo Son",
        "thanh": "U Châu",
        "ten_npc": "Nguyên Mộng",
        "level": 20,
        "hp": 108,
        "sp": 120,
        "hpx": 9,
        "spx": 8,
        "int": 5,
        "atk": 6,
        "def": 5,
        "agi": 8,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15402,
        "idmap": 12406,
        "thanh": "U Châu",
        "ten_npc": "Đầu bếp",
        "level": 20,
        "hp": 91,
        "sp": 90,
        "hpx": 5,
        "spx": 1,
        "int": 3,
        "atk": 15,
        "def": 7,
        "agi": 9,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15407,
        "idmap": 12406,
        "thanh": "U Châu",
        "ten_npc": "Gia tháp",
        "level": 20,
        "hp": 117,
        "sp": 122,
        "hpx": 11,
        "spx": 5,
        "int": 13,
        "atk": 5,
        "def": 5,
        "agi": 13,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16036,
        "idmap": 12421,
        "ten_map": "Lo Long Khau",
        "thanh": "U Châu",
        "ten_npc": "Thương Nhân",
        "level": 20,
        "hp": 104,
        "sp": 108,
        "hpx": 8,
        "spx": 5,
        "int": 10,
        "atk": 6,
        "def": 4,
        "agi": 8,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16041,
        "idmap": 12431,
        "ten_map": "Nha Quan",
        "thanh": "U Châu",
        "ten_npc": "Hoàn Đại Thư",
        "level": 20,
        "hp": 112,
        "sp": 103,
        "hpx": 10,
        "spx": 4,
        "int": 10,
        "atk": 3,
        "def": 8,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16042,
        "idmap": 12431,
        "ten_map": "Nha Quan",
        "thanh": "U Châu",
        "ten_npc": "Hoàn Nhị Thư",
        "level": 20,
        "hp": 112,
        "sp": 103,
        "hpx": 10,
        "spx": 4,
        "int": 10,
        "atk": 3,
        "def": 8,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16043,
        "idmap": 12431,
        "ten_map": "Nha Quan",
        "thanh": "U Châu",
        "ten_npc": "Hoàn Tam Thư",
        "level": 20,
        "hp": 112,
        "sp": 103,
        "hpx": 10,
        "spx": 4,
        "int": 10,
        "atk": 3,
        "def": 8,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16044,
        "idmap": 12431,
        "ten_map": "Nha Quan",
        "thanh": "U Châu",
        "ten_npc": "Hoàn Tiểu Muội",
        "level": 20,
        "hp": 112,
        "sp": 103,
        "hpx": 10,
        "spx": 4,
        "int": 10,
        "atk": 3,
        "def": 8,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16057,
        "idmap": 12432,
        "ten_map": "Thong Do Nha Quan",
        "thanh": "U Châu",
        "ten_npc": "Thủychiến binh",
        "level": 20,
        "hp": 112,
        "sp": 111,
        "hpx": 10,
        "spx": 6,
        "int": 11,
        "atk": 4,
        "def": 6,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10118,
        "idmap": 10819,
        "ten_npc": "KiênBinh KVàng",
        "level": 21,
        "hp": 124,
        "sp": 92,
        "hpx": 12,
        "spx": 1,
        "int": 2,
        "atk": 12,
        "def": 8,
        "agi": 7,
        "item1": 27046,
        "item2": 27034,
        "item3": 0,
        "item4": 33003,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10150,
        "idmap": 10863,
        "ten_npc": "Võ Dũng Sĩ",
        "level": 21,
        "hp": 124,
        "sp": 109,
        "hpx": 12,
        "spx": 5,
        "int": 4,
        "atk": 8,
        "def": 6,
        "agi": 7,
        "item1": 26047,
        "item2": 43020,
        "item3": 26088,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13143,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "T.Hoànbịthương",
        "level": 21,
        "hp": 140,
        "sp": 115,
        "hpx": 8,
        "spx": 3,
        "int": 5,
        "atk": 18,
        "def": 2,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14095,
        "idmap": 11583,
        "thanh": "Thanh Châu",
        "ten_npc": "Lưu Vệ",
        "level": 21,
        "hp": 140,
        "sp": 109,
        "hpx": 8,
        "spx": 5,
        "int": 3,
        "atk": 16,
        "def": 5,
        "agi": 7,
        "item1": 27065,
        "item2": 27035,
        "item3": 26064,
        "item4": 0,
        "item5": 46035,
        "item6": 0
    },
    {
        "idnpc": 14152,
        "idmap": 11585,
        "thanh": "Thanh Châu",
        "ten_npc": "Thôi Nghị",
        "level": 21,
        "hp": 140,
        "sp": 149,
        "hpx": 8,
        "spx": 11,
        "int": 8,
        "atk": 4,
        "def": 6,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15117,
        "idmap": 12093,
        "ten_map": "DaiDien Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Tổng Tiêu Đầu",
        "level": 21,
        "hp": 108,
        "sp": 100,
        "hpx": 8,
        "spx": 3,
        "int": 4,
        "atk": 11,
        "def": 9,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15120,
        "idmap": 12093,
        "ten_map": "DaiDien Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Than Tử Thủ",
        "level": 21,
        "hp": 108,
        "sp": 109,
        "hpx": 8,
        "spx": 5,
        "int": 2,
        "atk": 10,
        "def": 10,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15140,
        "idmap": 12095,
        "ten_map": "Tang Cao Khong Tuoc Dai",
        "thanh": "U Châu",
        "ten_npc": "Dân Thô Bạo",
        "level": 21,
        "hp": 98,
        "sp": 109,
        "hpx": 6,
        "spx": 5,
        "int": 2,
        "atk": 11,
        "def": 8,
        "agi": 10,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15156,
        "idmap": 12096,
        "ten_map": "Bac Thang Khong Tuoc2",
        "thanh": "U Châu",
        "ten_npc": "Người Đạo Mộ",
        "level": 21,
        "hp": 108,
        "sp": 100,
        "hpx": 8,
        "spx": 3,
        "int": 6,
        "atk": 11,
        "def": 7,
        "agi": 7,
        "item1": 27061,
        "item2": 27061,
        "item3": 26053,
        "item4": 46016,
        "item5": 24004,
        "item6": 0
    },
    {
        "idnpc": 15157,
        "idmap": 12096,
        "ten_map": "Bac Thang Khong Tuoc2",
        "thanh": "U Châu",
        "ten_npc": "Luyện Xác Nhân",
        "level": 21,
        "hp": 108,
        "sp": 139,
        "hpx": 8,
        "spx": 12,
        "int": 12,
        "atk": 4,
        "def": 4,
        "agi": 8,
        "item1": 27030,
        "item2": 27030,
        "item3": 43069,
        "item4": 46018,
        "item5": 24004,
        "item6": 0
    },
    {
        "idnpc": 15163,
        "idmap": 12096,
        "ten_map": "Bac Thang Khong Tuoc2",
        "thanh": "U Châu",
        "ten_npc": "Chủ Lôi Đài",
        "level": 21,
        "hp": 108,
        "sp": 100,
        "hpx": 8,
        "spx": 3,
        "int": 6,
        "atk": 11,
        "def": 7,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15194,
        "idmap": 12106,
        "ten_map": "P.Tao Phi Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Hạo Nam",
        "level": 21,
        "hp": 115,
        "sp": 100,
        "hpx": 10,
        "spx": 3,
        "int": 3,
        "atk": 9,
        "def": 9,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15243,
        "idmap": 12179,
        "ten_map": "N.Cuu Soi Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Chủ Đoàn P",
        "level": 21,
        "hp": 108,
        "sp": 100,
        "hpx": 8,
        "spx": 3,
        "int": 6,
        "atk": 11,
        "def": 7,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15244,
        "idmap": 12180,
        "ten_map": "Song Bac",
        "thanh": "U Châu",
        "ten_npc": "Chủđài Kchiến",
        "level": 21,
        "hp": 108,
        "sp": 100,
        "hpx": 8,
        "spx": 3,
        "int": 6,
        "atk": 11,
        "def": 7,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15303,
        "idmap": 12266,
        "thanh": "U Châu",
        "ten_npc": "Quyết Na",
        "level": 21,
        "hp": 108,
        "sp": 110,
        "hpx": 8,
        "spx": 5,
        "int": 8,
        "atk": 4,
        "def": 7,
        "agi": 9,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15306,
        "idmap": 12281,
        "ten_map": "Quan Tra",
        "thanh": "U Châu",
        "ten_npc": "Phàn Thị",
        "level": 21,
        "hp": 108,
        "sp": 113,
        "hpx": 8,
        "spx": 6,
        "int": 6,
        "atk": 7,
        "def": 8,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15347,
        "idmap": 12303,
        "ten_map": "Quan Phu BinhNguyen",
        "thanh": "U Châu",
        "ten_npc": "Người nuội voi",
        "level": 21,
        "hp": 111,
        "sp": 110,
        "hpx": 9,
        "spx": 5,
        "int": 8,
        "atk": 4,
        "def": 8,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15361,
        "idmap": 12305,
        "ten_map": "Trai Giam Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Thái Tiểu Mai",
        "level": 21,
        "hp": 108,
        "sp": 113,
        "hpx": 8,
        "spx": 6,
        "int": 8,
        "atk": 7,
        "def": 6,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15368,
        "idmap": 12308,
        "thanh": "U Châu",
        "ten_npc": "Tháp La",
        "level": 21,
        "hp": 115,
        "sp": 113,
        "hpx": 10,
        "spx": 6,
        "int": 5,
        "atk": 8,
        "def": 4,
        "agi": 9,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15381,
        "idmap": 12401,
        "ten_map": "Tien Do Tu Vo Son",
        "thanh": "U Châu",
        "ten_npc": "Tôn A",
        "level": 21,
        "hp": 111,
        "sp": 109,
        "hpx": 9,
        "spx": 5,
        "int": 3,
        "atk": 9,
        "def": 10,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15388,
        "idmap": 12403,
        "ten_map": "Nha Tranh Tu Vo Son",
        "thanh": "U Châu",
        "ten_npc": "Tắc La",
        "level": 21,
        "hp": 108,
        "sp": 110,
        "hpx": 8,
        "spx": 5,
        "int": 7,
        "atk": 6,
        "def": 7,
        "agi": 8,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15397,
        "idmap": 12406,
        "thanh": "U Châu",
        "ten_npc": "Quân Đức",
        "level": 21,
        "hp": 107,
        "sp": 117,
        "hpx": 8,
        "spx": 7,
        "int": 6,
        "atk": 4,
        "def": 8,
        "agi": 8,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15408,
        "idmap": 12406,
        "thanh": "U Châu",
        "ten_npc": "Beo nha lang",
        "level": 21,
        "hp": 120,
        "sp": 128,
        "hpx": 11,
        "spx": 6,
        "int": 5,
        "atk": 13,
        "def": 5,
        "agi": 13,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15424,
        "idmap": 12408,
        "thanh": "U Châu",
        "ten_npc": "Ngô áp ngục",
        "level": 21,
        "hp": 102,
        "sp": 109,
        "hpx": 7,
        "spx": 5,
        "int": 2,
        "atk": 8,
        "def": 2,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15458,
        "idmap": 12409,
        "thanh": "U Châu",
        "ten_npc": "Hạ a Mang",
        "level": 21,
        "hp": 497,
        "sp": 119,
        "hpx": 8,
        "spx": 4,
        "int": 5,
        "atk": 10,
        "def": 4,
        "agi": 10,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16025,
        "idmap": 12419,
        "thanh": "U Châu",
        "ten_npc": "NgườiĐổiThưởng",
        "level": 21,
        "hp": 108,
        "sp": 110,
        "hpx": 8,
        "spx": 5,
        "int": 7,
        "atk": 6,
        "def": 7,
        "agi": 8,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16027,
        "idmap": 12419,
        "thanh": "U Châu",
        "ten_npc": "NgườiĐổiThưởng",
        "level": 21,
        "hp": 108,
        "sp": 110,
        "hpx": 8,
        "spx": 5,
        "int": 7,
        "atk": 6,
        "def": 7,
        "agi": 8,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16047,
        "idmap": 12431,
        "ten_map": "Nha Quan",
        "thanh": "U Châu",
        "ten_npc": "Quỷ Đại Phu",
        "level": 21,
        "hp": 111,
        "sp": 110,
        "hpx": 9,
        "spx": 5,
        "int": 8,
        "atk": 8,
        "def": 5,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17085,
        "idmap": 12523,
        "thanh": "U Châu",
        "ten_npc": "Phong Thủy Sư",
        "level": 21,
        "hp": 85,
        "sp": 139,
        "hpx": 3,
        "spx": 12,
        "int": 13,
        "atk": 3,
        "def": 3,
        "agi": 8,
        "item1": 27018,
        "item2": 27018,
        "item3": 26053,
        "item4": 46018,
        "item5": 24004,
        "item6": 0
    },
    {
        "idnpc": 12068,
        "idmap": 11242,
        "ten_map": "Nha Tro Bac Hai",
        "thanh": "Thanh Châu",
        "ten_npc": "Từ Lữo Ph.Nhân",
        "level": 22,
        "hp": 156,
        "sp": 106,
        "hpx": 11,
        "spx": 4,
        "int": 10,
        "atk": 7,
        "def": 5,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14142,
        "idmap": 11585,
        "thanh": "Thanh Châu",
        "ten_npc": "Trưởng Hải Tặc",
        "level": 22,
        "hp": 123,
        "sp": 110,
        "hpx": 11,
        "spx": 5,
        "int": 3,
        "atk": 8,
        "def": 6,
        "agi": 11,
        "item1": 26040,
        "item2": 26118,
        "item3": 26117,
        "item4": 46018,
        "item5": 26128,
        "item6": 0
    },
    {
        "idnpc": 14466,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Lưu Tông",
        "level": 22,
        "hp": 146,
        "sp": 127,
        "hpx": 9,
        "spx": 5,
        "int": 4,
        "atk": 8,
        "def": 10,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15192,
        "idmap": 12106,
        "ten_map": "P.Tao Phi Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Đại Tùng",
        "level": 22,
        "hp": 123,
        "sp": 102,
        "hpx": 11,
        "spx": 3,
        "int": 3,
        "atk": 9,
        "def": 8,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15218,
        "idmap": 12139,
        "ten_map": "N.Quach Gia TQ",
        "thanh": "U Châu",
        "ten_npc": "Tâu Mã Hầu",
        "level": 22,
        "hp": 113,
        "sp": 113,
        "hpx": 9,
        "spx": 6,
        "int": 9,
        "atk": 7,
        "def": 8,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15225,
        "idmap": 12139,
        "ten_map": "N.Quach Gia TQ",
        "thanh": "U Châu",
        "ten_npc": "Hiệp Nhị Ca",
        "level": 22,
        "hp": 113,
        "sp": 112,
        "hpx": 9,
        "spx": 5,
        "int": 4,
        "atk": 7,
        "def": 8,
        "agi": 9,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15251,
        "idmap": 12180,
        "ten_map": "Song Bac",
        "thanh": "U Châu",
        "ten_npc": "Chú Kha",
        "level": 22,
        "hp": 101,
        "sp": 128,
        "hpx": 6,
        "spx": 9,
        "int": 10,
        "atk": 8,
        "def": 7,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15270,
        "idmap": 12222,
        "ten_map": "Hieu Thuoc Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Hồng Anh",
        "level": 22,
        "hp": 105,
        "sp": 111,
        "hpx": 7,
        "spx": 5,
        "int": 2,
        "atk": 12,
        "def": 6,
        "agi": 10,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15280,
        "idmap": 12242,
        "ten_map": "Nha Tro Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "VGTruyềnTốngSư",
        "level": 22,
        "hp": 110,
        "sp": 119,
        "hpx": 8,
        "spx": 7,
        "int": 13,
        "atk": 7,
        "def": 3,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15281,
        "idmap": 12242,
        "ten_map": "Nha Tro Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Truyền Tống Sư",
        "level": 22,
        "hp": 105,
        "sp": 119,
        "hpx": 7,
        "spx": 7,
        "int": 12,
        "atk": 7,
        "def": 4,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15289,
        "idmap": 12243,
        "ten_map": "Nha Tro Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Chu Ngư Phu",
        "level": 22,
        "hp": 123,
        "sp": 102,
        "hpx": 11,
        "spx": 3,
        "int": 3,
        "atk": 7,
        "def": 10,
        "agi": 8,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15292,
        "idmap": 12243,
        "ten_map": "Nha Tro Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Phi Lang Trung",
        "level": 22,
        "hp": 101,
        "sp": 128,
        "hpx": 6,
        "spx": 9,
        "int": 10,
        "atk": 8,
        "def": 7,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15316,
        "idmap": 12282,
        "ten_map": "Quan Tra Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Mã Lữo Bá",
        "level": 22,
        "hp": 113,
        "sp": 119,
        "hpx": 9,
        "spx": 7,
        "int": 5,
        "atk": 11,
        "def": 3,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15317,
        "idmap": 12282,
        "ten_map": "Quan Tra Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Mã Nhị Thúc",
        "level": 22,
        "hp": 123,
        "sp": 110,
        "hpx": 11,
        "spx": 5,
        "int": 7,
        "atk": 8,
        "def": 5,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15391,
        "idmap": 12403,
        "ten_map": "Nha Tranh Tu Vo Son",
        "thanh": "U Châu",
        "ten_npc": "Tề Lê",
        "level": 22,
        "hp": 105,
        "sp": 119,
        "hpx": 7,
        "spx": 7,
        "int": 12,
        "atk": 7,
        "def": 4,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16030,
        "idmap": 12419,
        "thanh": "U Châu",
        "ten_npc": "Chủ Sòng Bạc",
        "level": 22,
        "hp": 105,
        "sp": 119,
        "hpx": 7,
        "spx": 7,
        "int": 12,
        "atk": 7,
        "def": 4,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16071,
        "idmap": 12504,
        "thanh": "U Châu",
        "ten_npc": "Công an úy",
        "level": 22,
        "hp": 118,
        "sp": 106,
        "hpx": 10,
        "spx": 4,
        "int": 4,
        "atk": 10,
        "def": 9,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16073,
        "idmap": 12504,
        "thanh": "U Châu",
        "ten_npc": "Ngưu Chử úy",
        "level": 22,
        "hp": 118,
        "sp": 106,
        "hpx": 10,
        "spx": 4,
        "int": 4,
        "atk": 10,
        "def": 9,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17015,
        "idmap": 12513,
        "thanh": "U Châu",
        "ten_npc": "Thiết.Gi.Quân",
        "level": 22,
        "hp": 123,
        "sp": 111,
        "hpx": 11,
        "spx": 5,
        "int": 2,
        "atk": 9,
        "def": 9,
        "agi": 7,
        "item1": 26042,
        "item2": 0,
        "item3": 26160,
        "item4": 46018,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17168,
        "idmap": 12562,
        "thanh": "U Châu",
        "ten_npc": "Đông Hải Tặc",
        "level": 22,
        "hp": 113,
        "sp": 111,
        "hpx": 9,
        "spx": 5,
        "int": 3,
        "atk": 10,
        "def": 13,
        "agi": 4,
        "item1": 26040,
        "item2": 26162,
        "item3": 26082,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17171,
        "idmap": 12562,
        "thanh": "U Châu",
        "ten_npc": "LươngGiangTặc",
        "level": 22,
        "hp": 113,
        "sp": 111,
        "hpx": 9,
        "spx": 5,
        "int": 2,
        "atk": 10,
        "def": 9,
        "agi": 9,
        "item1": 26040,
        "item2": 26053,
        "item3": 26016,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10086,
        "idmap": 10814,
        "ten_npc": "Tế Rượu Tào",
        "level": 23,
        "hp": 108,
        "sp": 129,
        "hpx": 7,
        "spx": 9,
        "int": 13,
        "atk": 4,
        "def": 3,
        "agi": 10,
        "item1": 27040,
        "item2": 26053,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10088,
        "idmap": 10814,
        "ten_npc": "Tế Rượu Thi",
        "level": 23,
        "hp": 113,
        "sp": 129,
        "hpx": 8,
        "spx": 9,
        "int": 14,
        "atk": 2,
        "def": 3,
        "agi": 10,
        "item1": 27065,
        "item2": 26064,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14083,
        "idmap": 11582,
        "thanh": "Thanh Châu",
        "ten_npc": "Thái Dấp",
        "level": 23,
        "hp": 152,
        "sp": 134,
        "hpx": 9,
        "spx": 7,
        "int": 22,
        "atk": 3,
        "def": 3,
        "agi": 4,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14094,
        "idmap": 11583,
        "thanh": "Thanh Châu",
        "ten_npc": "Quách Huân",
        "level": 23,
        "hp": 152,
        "sp": 114,
        "hpx": 9,
        "spx": 6,
        "int": 2,
        "atk": 19,
        "def": 5,
        "agi": 8,
        "item1": 27065,
        "item2": 27035,
        "item3": 26064,
        "item4": 0,
        "item5": 46034,
        "item6": 0
    },
    {
        "idnpc": 14145,
        "idmap": 11585,
        "thanh": "Thanh Châu",
        "ten_npc": "Hải Tặc Vương",
        "level": 23,
        "hp": 118,
        "sp": 112,
        "hpx": 9,
        "spx": 5,
        "int": 4,
        "atk": 9,
        "def": 8,
        "agi": 11,
        "item1": 26040,
        "item2": 26118,
        "item3": 26117,
        "item4": 50001,
        "item5": 26128,
        "item6": 0
    },
    {
        "idnpc": 15147,
        "idmap": 12095,
        "ten_map": "Tang Cao Khong Tuoc Dai",
        "thanh": "U Châu",
        "ten_npc": "Tây Sơn Binh",
        "level": 23,
        "hp": 121,
        "sp": 103,
        "hpx": 10,
        "spx": 3,
        "int": 3,
        "atk": 10,
        "def": 8,
        "agi": 12,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15148,
        "idmap": 12095,
        "ten_map": "Tang Cao Khong Tuoc Dai",
        "thanh": "U Châu",
        "ten_npc": "TâySơn ĐạoNhân",
        "level": 23,
        "hp": 118,
        "sp": 121,
        "hpx": 9,
        "spx": 7,
        "int": 12,
        "atk": 4,
        "def": 6,
        "agi": 8,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15164,
        "idmap": 12096,
        "ten_map": "Bac Thang Khong Tuoc2",
        "thanh": "U Châu",
        "ten_npc": "Đinh Ngư Phu",
        "level": 23,
        "hp": 118,
        "sp": 115,
        "hpx": 9,
        "spx": 6,
        "int": 7,
        "atk": 10,
        "def": 8,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15173,
        "idmap": 12101,
        "ten_map": "N.Ly Lang Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Y Chi Mã",
        "level": 23,
        "hp": 118,
        "sp": 114,
        "hpx": 9,
        "spx": 6,
        "int": 4,
        "atk": 11,
        "def": 9,
        "agi": 8,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15181,
        "idmap": 12104,
        "ten_map": "Tao Phu Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Hoàn Hoàn",
        "level": 23,
        "hp": 126,
        "sp": 107,
        "hpx": 11,
        "spx": 4,
        "int": 12,
        "atk": 4,
        "def": 9,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15259,
        "idmap": 12202,
        "ten_map": "Lo Ren Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Dân Buôn Bán",
        "level": 23,
        "hp": 126,
        "sp": 114,
        "hpx": 11,
        "spx": 6,
        "int": 2,
        "atk": 14,
        "def": 8,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15275,
        "idmap": 12241,
        "ten_map": "Nha Tro Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "NVthụlýtrậnđấu",
        "level": 23,
        "hp": 108,
        "sp": 116,
        "hpx": 7,
        "spx": 6,
        "int": 14,
        "atk": 8,
        "def": 5,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15276,
        "idmap": 12241,
        "ten_map": "Nha Tro Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "NgườiMảiNghệ",
        "level": 23,
        "hp": 126,
        "sp": 114,
        "hpx": 11,
        "spx": 6,
        "int": 4,
        "atk": 12,
        "def": 7,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15296,
        "idmap": 12245,
        "thanh": "U Châu",
        "ten_npc": "Lý Thiết Cân",
        "level": 23,
        "hp": 130,
        "sp": 103,
        "hpx": 12,
        "spx": 3,
        "int": 4,
        "atk": 8,
        "def": 11,
        "agi": 9,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15297,
        "idmap": 12246,
        "thanh": "U Châu",
        "ten_npc": "ThườngThiếtCân",
        "level": 23,
        "hp": 134,
        "sp": 99,
        "hpx": 13,
        "spx": 2,
        "int": 5,
        "atk": 10,
        "def": 9,
        "agi": 8,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15331,
        "idmap": 12301,
        "ten_map": "Dai Dien Chu Hau",
        "thanh": "U Châu",
        "ten_npc": "Thiên Thần Cô",
        "level": 23,
        "hp": 121,
        "sp": 121,
        "hpx": 10,
        "spx": 7,
        "int": 7,
        "atk": 8,
        "def": 5,
        "agi": 10,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15353,
        "idmap": 12304,
        "ten_map": "Quan Phu TQ",
        "thanh": "U Châu",
        "ten_npc": "Phổ Lâm",
        "level": 23,
        "hp": 134,
        "sp": 99,
        "hpx": 13,
        "spx": 2,
        "int": 5,
        "atk": 10,
        "def": 9,
        "agi": 8,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15371,
        "idmap": 12310,
        "ten_map": "Lac Hai Quan Phu",
        "thanh": "U Châu",
        "ten_npc": "Bạch Bình",
        "level": 23,
        "hp": 121,
        "sp": 116,
        "hpx": 10,
        "spx": 6,
        "int": 13,
        "atk": 2,
        "def": 7,
        "agi": 9,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15386,
        "idmap": 12401,
        "ten_map": "Tien Do Tu Vo Son",
        "thanh": "U Châu",
        "ten_npc": "Vợ Triệu Ngang",
        "level": 23,
        "hp": 121,
        "sp": 121,
        "hpx": 10,
        "spx": 7,
        "int": 7,
        "atk": 8,
        "def": 5,
        "agi": 10,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15392,
        "idmap": 12403,
        "ten_map": "Nha Tranh Tu Vo Son",
        "thanh": "U Châu",
        "ten_npc": "Hưu Mạc Bá",
        "level": 23,
        "hp": 118,
        "sp": 116,
        "hpx": 9,
        "spx": 6,
        "int": 12,
        "atk": 7,
        "def": 4,
        "agi": 9,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16031,
        "idmap": 12421,
        "ten_map": "Lo Long Khau",
        "thanh": "U Châu",
        "ten_npc": "Ông Chủ Cờ Bạc",
        "level": 23,
        "hp": 118,
        "sp": 116,
        "hpx": 9,
        "spx": 6,
        "int": 12,
        "atk": 7,
        "def": 4,
        "agi": 9,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16048,
        "idmap": 12431,
        "ten_map": "Nha Quan",
        "thanh": "U Châu",
        "ten_npc": "Đạo Sĩ",
        "level": 23,
        "hp": 126,
        "sp": 112,
        "hpx": 11,
        "spx": 5,
        "int": 11,
        "atk": 4,
        "def": 9,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17034,
        "idmap": 12515,
        "thanh": "U Châu",
        "ten_npc": "Báo Quân Quan",
        "level": 23,
        "hp": 118,
        "sp": 112,
        "hpx": 9,
        "spx": 5,
        "int": 3,
        "atk": 11,
        "def": 7,
        "agi": 11,
        "item1": 26035,
        "item2": 40025,
        "item3": 40025,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17038,
        "idmap": 12515,
        "thanh": "U Châu",
        "ten_npc": "Hổ Quân Trưởng",
        "level": 23,
        "hp": 118,
        "sp": 107,
        "hpx": 9,
        "spx": 4,
        "int": 4,
        "atk": 11,
        "def": 11,
        "agi": 7,
        "item1": 26166,
        "item2": 26042,
        "item3": 26166,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17169,
        "idmap": 12562,
        "thanh": "U Châu",
        "ten_npc": "Tr.Giang Đạo",
        "level": 23,
        "hp": 113,
        "sp": 107,
        "hpx": 8,
        "spx": 4,
        "int": 3,
        "atk": 11,
        "def": 10,
        "agi": 10,
        "item1": 26040,
        "item2": 26033,
        "item3": 27055,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10054,
        "idmap": 10809,
        "ten_npc": "Binh Trưởng",
        "level": 24,
        "hp": 129,
        "sp": 109,
        "hpx": 11,
        "spx": 4,
        "int": 5,
        "atk": 12,
        "def": 8,
        "agi": 8,
        "item1": 26002,
        "item2": 26090,
        "item3": 26090,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10074,
        "idmap": 10812,
        "ten_npc": "Tr.Võ Sĩ Ma",
        "level": 24,
        "hp": 115,
        "sp": 109,
        "hpx": 8,
        "spx": 4,
        "int": 4,
        "atk": 11,
        "def": 9,
        "agi": 12,
        "item1": 26041,
        "item2": 26078,
        "item3": 26084,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14209,
        "idmap": 11822,
        "thanh": "Thanh Châu",
        "ten_npc": "Man Di",
        "level": 24,
        "hp": 122,
        "sp": 116,
        "hpx": 10,
        "spx": 6,
        "int": 1,
        "atk": 12,
        "def": 10,
        "agi": 10,
        "item1": 26057,
        "item2": 26035,
        "item3": 26086,
        "item4": 0,
        "item5": 39012,
        "item6": 0
    },
    {
        "idnpc": 14570,
        "idmap": 12031,
        "ten_map": "Thon Lau Song",
        "thanh": "U Châu",
        "ten_npc": "Xuân Hương",
        "level": 24,
        "hp": 122,
        "sp": 122,
        "hpx": 10,
        "spx": 7,
        "int": 8,
        "atk": 4,
        "def": 12,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15001,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Ngô Phổ",
        "level": 24,
        "hp": 120,
        "sp": 113,
        "hpx": 9,
        "spx": 5,
        "int": 16,
        "atk": 8,
        "def": 3,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15175,
        "idmap": 12103,
        "ten_map": "Phu Ho Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Ngưu Lợi",
        "level": 24,
        "hp": 129,
        "sp": 113,
        "hpx": 11,
        "spx": 5,
        "int": 5,
        "atk": 8,
        "def": 11,
        "agi": 8,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15219,
        "idmap": 12139,
        "ten_map": "N.Quach Gia TQ",
        "thanh": "U Châu",
        "ten_npc": "Lỗ Tư",
        "level": 24,
        "hp": 122,
        "sp": 117,
        "hpx": 10,
        "spx": 6,
        "int": 3,
        "atk": 12,
        "def": 10,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15234,
        "idmap": 12175,
        "ten_map": "N.Hua Thieu Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Ng.Công Chứng",
        "level": 24,
        "hp": 122,
        "sp": 118,
        "hpx": 10,
        "spx": 6,
        "int": 8,
        "atk": 11,
        "def": 4,
        "agi": 9,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15256,
        "idmap": 12180,
        "ten_map": "Song Bac",
        "thanh": "U Châu",
        "ten_npc": "Huỳnh Tiểu Ca",
        "level": 24,
        "hp": 102,
        "sp": 118,
        "hpx": 5,
        "spx": 6,
        "int": 7,
        "atk": 11,
        "def": 11,
        "agi": 8,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15291,
        "idmap": 12243,
        "ten_map": "Nha Tro Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Chủ LĐài Stồn",
        "level": 24,
        "hp": 102,
        "sp": 119,
        "hpx": 5,
        "spx": 6,
        "int": 13,
        "atk": 7,
        "def": 11,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15309,
        "idmap": 12281,
        "ten_map": "Quan Tra",
        "thanh": "U Châu",
        "ten_npc": "Tạ Sư Phụ",
        "level": 24,
        "hp": 133,
        "sp": 119,
        "hpx": 12,
        "spx": 6,
        "int": 12,
        "atk": 8,
        "def": 3,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15314,
        "idmap": 12282,
        "ten_map": "Quan Tra Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Thản Ca",
        "level": 24,
        "hp": 151,
        "sp": 116,
        "hpx": 16,
        "spx": 6,
        "int": 2,
        "atk": 11,
        "def": 10,
        "agi": 3,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15318,
        "idmap": 12282,
        "ten_map": "Quan Tra Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Tiểu Truyền",
        "level": 24,
        "hp": 129,
        "sp": 117,
        "hpx": 11,
        "spx": 6,
        "int": 3,
        "atk": 6,
        "def": 13,
        "agi": 9,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15343,
        "idmap": 12303,
        "ten_map": "Quan Phu BinhNguyen",
        "thanh": "U Châu",
        "ten_npc": "Thôn Nhân",
        "level": 24,
        "hp": 129,
        "sp": 113,
        "hpx": 11,
        "spx": 5,
        "int": 2,
        "atk": 13,
        "def": 10,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15352,
        "idmap": 12304,
        "ten_map": "Quan Phu TQ",
        "thanh": "U Châu",
        "ten_npc": "Tô Lợi Á",
        "level": 24,
        "hp": 133,
        "sp": 119,
        "hpx": 12,
        "spx": 6,
        "int": 12,
        "atk": 8,
        "def": 3,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15362,
        "idmap": 12305,
        "ten_map": "Trai Giam Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Xảo Nương",
        "level": 24,
        "hp": 133,
        "sp": 119,
        "hpx": 12,
        "spx": 6,
        "int": 12,
        "atk": 6,
        "def": 4,
        "agi": 8,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15374,
        "idmap": 12310,
        "ten_map": "Lac Hai Quan Phu",
        "thanh": "U Châu",
        "ten_npc": "Sa Khắc",
        "level": 24,
        "hp": 129,
        "sp": 122,
        "hpx": 11,
        "spx": 7,
        "int": 11,
        "atk": 8,
        "def": 4,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15378,
        "idmap": 12310,
        "ten_map": "Lac Hai Quan Phu",
        "thanh": "U Châu",
        "ten_npc": "Kỷ đại phu",
        "level": 24,
        "hp": 124,
        "sp": 113,
        "hpx": 10,
        "spx": 5,
        "int": 13,
        "atk": 3,
        "def": 10,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15379,
        "idmap": 12401,
        "ten_map": "Tien Do Tu Vo Son",
        "thanh": "U Châu",
        "ten_npc": "Lục dật vũ giả",
        "level": 24,
        "hp": 129,
        "sp": 122,
        "hpx": 11,
        "spx": 7,
        "int": 13,
        "atk": 6,
        "def": 4,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15393,
        "idmap": 12403,
        "ten_map": "Nha Tranh Tu Vo Son",
        "thanh": "U Châu",
        "ten_npc": "Tịch Trí",
        "level": 24,
        "hp": 129,
        "sp": 113,
        "hpx": 11,
        "spx": 5,
        "int": 10,
        "atk": 5,
        "def": 10,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15449,
        "idmap": 12409,
        "thanh": "U Châu",
        "ten_npc": "Xảo nương",
        "level": 24,
        "hp": 133,
        "sp": 119,
        "hpx": 12,
        "spx": 6,
        "int": 12,
        "atk": 6,
        "def": 4,
        "agi": 8,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15450,
        "idmap": 12409,
        "thanh": "U Châu",
        "ten_npc": "Chu Phối nương",
        "level": 24,
        "hp": 138,
        "sp": 119,
        "hpx": 13,
        "spx": 6,
        "int": 12,
        "atk": 6,
        "def": 4,
        "agi": 8,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16032,
        "idmap": 12421,
        "ten_map": "Lo Long Khau",
        "thanh": "U Châu",
        "ten_npc": "Thương Nhân",
        "level": 24,
        "hp": 133,
        "sp": 109,
        "hpx": 12,
        "spx": 4,
        "int": 13,
        "atk": 7,
        "def": 3,
        "agi": 9,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17042,
        "idmap": 12519,
        "thanh": "U Châu",
        "ten_npc": "Hùng.Q.Trưởng",
        "level": 24,
        "hp": 122,
        "sp": 117,
        "hpx": 10,
        "spx": 6,
        "int": 3,
        "atk": 9,
        "def": 13,
        "agi": 8,
        "item1": 26158,
        "item2": 40002,
        "item3": 40012,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17070,
        "idmap": 12523,
        "thanh": "U Châu",
        "ten_npc": "ÔHằng Đao Binh",
        "level": 24,
        "hp": 132,
        "sp": 117,
        "hpx": 10,
        "spx": 6,
        "int": 3,
        "atk": 11,
        "def": 11,
        "agi": 8,
        "item1": 26041,
        "item2": 26040,
        "item3": 26053,
        "item4": 0,
        "item5": 39004,
        "item6": 0
    },
    {
        "idnpc": 17078,
        "idmap": 12523,
        "thanh": "U Châu",
        "ten_npc": "Lâm tặc",
        "level": 24,
        "hp": 120,
        "sp": 113,
        "hpx": 9,
        "spx": 5,
        "int": 4,
        "atk": 11,
        "def": 9,
        "agi": 10,
        "item1": 27004,
        "item2": 27004,
        "item3": 27035,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17172,
        "idmap": 12563,
        "thanh": "U Châu",
        "ten_npc": "Giang Hồ Phỉ",
        "level": 24,
        "hp": 122,
        "sp": 117,
        "hpx": 10,
        "spx": 6,
        "int": 3,
        "atk": 8,
        "def": 10,
        "agi": 12,
        "item1": 26040,
        "item2": 26051,
        "item3": 27046,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17192,
        "idmap": 12570,
        "thanh": "U Châu",
        "ten_npc": "DịchChâuTrưởng",
        "level": 24,
        "hp": 122,
        "sp": 118,
        "hpx": 10,
        "spx": 6,
        "int": 8,
        "atk": 6,
        "def": 8,
        "agi": 10,
        "item1": 27002,
        "item2": 26033,
        "item3": 26001,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17193,
        "idmap": 12570,
        "thanh": "U Châu",
        "ten_npc": "Th.Châu Trưởng",
        "level": 24,
        "hp": 122,
        "sp": 131,
        "hpx": 10,
        "spx": 9,
        "int": 11,
        "atk": 6,
        "def": 5,
        "agi": 7,
        "item1": 27023,
        "item2": 26157,
        "item3": 26001,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17279,
        "idmap": 12589,
        "ten_map": "Mo Nguyet Trieu Ca 9",
        "thanh": "U Châu",
        "ten_npc": "Th.Bình Binh",
        "level": 24,
        "hp": 129,
        "sp": 113,
        "hpx": 11,
        "spx": 5,
        "int": 3,
        "atk": 9,
        "def": 9,
        "agi": 11,
        "item1": 26165,
        "item2": 26165,
        "item3": 26111,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10189,
        "idmap": 10909,
        "ten_npc": "Binh Hắt Sơn",
        "level": 25,
        "hp": 154,
        "sp": 101,
        "hpx": 16,
        "spx": 2,
        "int": 3,
        "atk": 13,
        "def": 6,
        "agi": 10,
        "item1": 26169,
        "item2": 26003,
        "item3": 26034,
        "item4": 50001,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14146,
        "idmap": 11585,
        "thanh": "Thanh Châu",
        "ten_npc": "Hải Tặc Võ Sĩ",
        "level": 25,
        "hp": 127,
        "sp": 119,
        "hpx": 10,
        "spx": 6,
        "int": 4,
        "atk": 12,
        "def": 10,
        "agi": 8,
        "item1": 26040,
        "item2": 26118,
        "item3": 26117,
        "item4": 50001,
        "item5": 26129,
        "item6": 0
    },
    {
        "idnpc": 14443,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Bạch Kỳ",
        "level": 25,
        "hp": 177,
        "sp": 138,
        "hpx": 10,
        "spx": 6,
        "int": 7,
        "atk": 13,
        "def": 8,
        "agi": 9,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14477,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Tống Trung",
        "level": 25,
        "hp": 165,
        "sp": 138,
        "hpx": 10,
        "spx": 6,
        "int": 10,
        "atk": 6,
        "def": 9,
        "agi": 9,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15174,
        "idmap": 12102,
        "ten_map": "N.Chau Tinh TQ",
        "thanh": "U Châu",
        "ten_npc": "Nan Thăng Mi",
        "level": 25,
        "hp": 127,
        "sp": 121,
        "hpx": 10,
        "spx": 6,
        "int": 9,
        "atk": 10,
        "def": 6,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15311,
        "idmap": 12281,
        "ten_map": "Quan Tra",
        "thanh": "U Châu",
        "ten_npc": "Phạm Sư Phụ",
        "level": 25,
        "hp": 132,
        "sp": 119,
        "hpx": 11,
        "spx": 6,
        "int": 4,
        "atk": 9,
        "def": 7,
        "agi": 11,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15351,
        "idmap": 12304,
        "ten_map": "Quan Phu TQ",
        "thanh": "U Châu",
        "ten_npc": "Người dạy bơi",
        "level": 25,
        "hp": 132,
        "sp": 124,
        "hpx": 11,
        "spx": 7,
        "int": 2,
        "atk": 9,
        "def": 13,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16067,
        "idmap": 12502,
        "thanh": "U Châu",
        "ten_npc": "Sài Tang úy",
        "level": 25,
        "hp": 132,
        "sp": 110,
        "hpx": 11,
        "spx": 4,
        "int": 5,
        "atk": 12,
        "def": 11,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16069,
        "idmap": 12502,
        "thanh": "U Châu",
        "ten_npc": "Giang Lăng úy",
        "level": 25,
        "hp": 132,
        "sp": 110,
        "hpx": 11,
        "spx": 4,
        "int": 5,
        "atk": 12,
        "def": 11,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17071,
        "idmap": 12523,
        "thanh": "U Châu",
        "ten_npc": "Ô Hằng.D.Sĩ",
        "level": 25,
        "hp": 137,
        "sp": 119,
        "hpx": 10,
        "spx": 6,
        "int": 2,
        "atk": 12,
        "def": 10,
        "agi": 10,
        "item1": 26050,
        "item2": 26040,
        "item3": 26168,
        "item4": 0,
        "item5": 40003,
        "item6": 0
    },
    {
        "idnpc": 17079,
        "idmap": 12523,
        "thanh": "U Châu",
        "ten_npc": "Hộ Lâm Liệt Sĩ",
        "level": 25,
        "hp": 132,
        "sp": 106,
        "hpx": 11,
        "spx": 3,
        "int": 3,
        "atk": 12,
        "def": 13,
        "agi": 8,
        "item1": 27004,
        "item2": 27004,
        "item3": 27035,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17170,
        "idmap": 12562,
        "thanh": "U Châu",
        "ten_npc": "Đại Hải Đạo",
        "level": 25,
        "hp": 127,
        "sp": 119,
        "hpx": 10,
        "spx": 6,
        "int": 3,
        "atk": 11,
        "def": 10,
        "agi": 8,
        "item1": 26040,
        "item2": 26157,
        "item3": 27001,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17177,
        "idmap": 12563,
        "thanh": "U Châu",
        "ten_npc": "Bắc Hải Binh",
        "level": 25,
        "hp": 136,
        "sp": 119,
        "hpx": 12,
        "spx": 6,
        "int": 4,
        "atk": 8,
        "def": 11,
        "agi": 9,
        "item1": 26098,
        "item2": 27035,
        "item3": 27002,
        "item4": 46016,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17194,
        "idmap": 12570,
        "thanh": "U Châu",
        "ten_npc": "Từ Châu Trưởng",
        "level": 25,
        "hp": 135,
        "sp": 125,
        "hpx": 11,
        "spx": 7,
        "int": 6,
        "atk": 8,
        "def": 9,
        "agi": 11,
        "item1": 26082,
        "item2": 26157,
        "item3": 26042,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17195,
        "idmap": 12570,
        "thanh": "U Châu",
        "ten_npc": "DuyệtCh.Trưởng",
        "level": 25,
        "hp": 118,
        "sp": 133,
        "hpx": 8,
        "spx": 9,
        "int": 15,
        "atk": 5,
        "def": 6,
        "agi": 7,
        "item1": 27065,
        "item2": 27034,
        "item3": 27060,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17278,
        "idmap": 12589,
        "ten_map": "Mo Nguyet Trieu Ca 9",
        "thanh": "U Châu",
        "ten_npc": "Liêu Đông Quân",
        "level": 25,
        "hp": 136,
        "sp": 110,
        "hpx": 12,
        "spx": 4,
        "int": 4,
        "atk": 10,
        "def": 10,
        "agi": 8,
        "item1": 26002,
        "item2": 26041,
        "item3": 26111,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17280,
        "idmap": 12589,
        "ten_map": "Mo Nguyet Trieu Ca 9",
        "thanh": "U Châu",
        "ten_npc": "Ô Hằng Binh",
        "level": 25,
        "hp": 136,
        "sp": 110,
        "hpx": 12,
        "spx": 4,
        "int": 4,
        "atk": 11,
        "def": 9,
        "agi": 8,
        "item1": 27017,
        "item2": 27029,
        "item3": 27040,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10119,
        "idmap": 10820,
        "ten_npc": "Huỳnh Đại Binh",
        "level": 26,
        "hp": 142,
        "sp": 143,
        "hpx": 10,
        "spx": 11,
        "int": 6,
        "atk": 13,
        "def": 10,
        "agi": 5,
        "item1": 26053,
        "item2": 26053,
        "item3": 26053,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10190,
        "idmap": 10909,
        "ten_npc": "B.ThươngHắtSơn",
        "level": 26,
        "hp": 162,
        "sp": 103,
        "hpx": 17,
        "spx": 2,
        "int": 3,
        "atk": 14,
        "def": 7,
        "agi": 9,
        "item1": 26003,
        "item2": 26003,
        "item3": 26034,
        "item4": 46018,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14210,
        "idmap": 11822,
        "thanh": "Thanh Châu",
        "ten_npc": "Man Sĩ",
        "level": 26,
        "hp": 132,
        "sp": 124,
        "hpx": 10,
        "spx": 7,
        "int": 12,
        "atk": 2,
        "def": 10,
        "agi": 11,
        "item1": 26057,
        "item2": 26035,
        "item3": 26086,
        "item4": 27087,
        "item5": 45011,
        "item6": 0
    },
    {
        "idnpc": 14444,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Y Di Mô",
        "level": 26,
        "hp": 184,
        "sp": 142,
        "hpx": 10,
        "spx": 7,
        "int": 11,
        "atk": 14,
        "def": 9,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14698,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "MạnhƯu sayrượu",
        "level": 26,
        "hp": 274,
        "sp": 148,
        "hpx": 16,
        "spx": 8,
        "int": 9,
        "atk": 21,
        "def": 11,
        "agi": 13,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15165,
        "idmap": 12096,
        "ten_map": "Bac Thang Khong Tuoc2",
        "thanh": "U Châu",
        "ten_npc": "Hải Đại Ca",
        "level": 26,
        "hp": 132,
        "sp": 123,
        "hpx": 10,
        "spx": 7,
        "int": 10,
        "atk": 11,
        "def": 6,
        "agi": 9,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15178,
        "idmap": 12104,
        "ten_map": "Tao Phu Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Lục Vũ",
        "level": 26,
        "hp": 135,
        "sp": 121,
        "hpx": 11,
        "spx": 6,
        "int": 14,
        "atk": 10,
        "def": 8,
        "agi": 4,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15329,
        "idmap": 12301,
        "ten_map": "Dai Dien Chu Hau",
        "thanh": "U Châu",
        "ten_npc": "Cổ Ngẫu",
        "level": 26,
        "hp": 132,
        "sp": 134,
        "hpx": 10,
        "spx": 9,
        "int": 11,
        "atk": 7,
        "def": 10,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15334,
        "idmap": 12302,
        "ten_map": "Quan Phu Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Mạnh Đại Lữo",
        "level": 26,
        "hp": 132,
        "sp": 130,
        "hpx": 10,
        "spx": 8,
        "int": 13,
        "atk": 7,
        "def": 4,
        "agi": 10,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15367,
        "idmap": 12308,
        "thanh": "U Châu",
        "ten_npc": "Cáp Nhĩ Ba La",
        "level": 26,
        "hp": 132,
        "sp": 122,
        "hpx": 10,
        "spx": 6,
        "int": 3,
        "atk": 14,
        "def": 8,
        "agi": 11,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17073,
        "idmap": 12523,
        "thanh": "U Châu",
        "ten_npc": "Ô Hằng Ch.Sĩ",
        "level": 26,
        "hp": 142,
        "sp": 122,
        "hpx": 10,
        "spx": 6,
        "int": 4,
        "atk": 12,
        "def": 10,
        "agi": 9,
        "item1": 26040,
        "item2": 26045,
        "item3": 26050,
        "item4": 46016,
        "item5": 39004,
        "item6": 0
    },
    {
        "idnpc": 17308,
        "idmap": 12593,
        "ten_map": "Mo Khoang Trac Quan 3",
        "thanh": "U Châu",
        "ten_npc": "Ô Hằng Nữ",
        "level": 26,
        "hp": 135,
        "sp": 116,
        "hpx": 11,
        "spx": 5,
        "int": 5,
        "atk": 9,
        "def": 10,
        "agi": 12,
        "item1": 26111,
        "item2": 26111,
        "item3": 26165,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10027,
        "idmap": 10804,
        "ten_npc": "Hoàng Thiệu",
        "level": 27,
        "hp": 177,
        "sp": 145,
        "hpx": 11,
        "spx": 7,
        "int": 10,
        "atk": 13,
        "def": 6,
        "agi": 9,
        "item1": 26165,
        "item2": 27055,
        "item3": 27034,
        "item4": 0,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 10046,
        "idmap": 10807,
        "ten_npc": "Binh Dao Lẻ",
        "level": 27,
        "hp": 147,
        "sp": 127,
        "hpx": 13,
        "spx": 7,
        "int": 2,
        "atk": 12,
        "def": 11,
        "agi": 9,
        "item1": 27028,
        "item2": 27036,
        "item3": 27036,
        "item4": 50001,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10205,
        "idmap": 10914,
        "ten_npc": "Binh Kh.Vàng",
        "level": 27,
        "hp": 177,
        "sp": 127,
        "hpx": 13,
        "spx": 7,
        "int": 2,
        "atk": 12,
        "def": 11,
        "agi": 9,
        "item1": 27037,
        "item2": 27041,
        "item3": 27041,
        "item4": 46018,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14211,
        "idmap": 11822,
        "thanh": "Thanh Châu",
        "ten_npc": "Quái Ẩn Sĩ",
        "level": 27,
        "hp": 115,
        "sp": 154,
        "hpx": 6,
        "spx": 13,
        "int": 14,
        "atk": 9,
        "def": 9,
        "agi": 9,
        "item1": 32002,
        "item2": 26080,
        "item3": 26080,
        "item4": 46016,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14263,
        "idmap": 12000,
        "ten_map": "U Chau",
        "thanh": "U Châu",
        "ten_npc": "Gia Cát Huyền",
        "level": 27,
        "hp": 146,
        "sp": 128,
        "hpx": 4,
        "spx": 3,
        "int": 37,
        "atk": 3,
        "def": 2,
        "agi": 5,
        "item1": 27019,
        "item2": 26035,
        "item3": 26086,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15154,
        "idmap": 12096,
        "ten_map": "Bac Thang Khong Tuoc2",
        "thanh": "U Châu",
        "ten_npc": "Lang Đầu Đệ",
        "level": 27,
        "hp": 124,
        "sp": 118,
        "hpx": 8,
        "spx": 5,
        "int": 5,
        "atk": 15,
        "def": 10,
        "agi": 11,
        "item1": 26047,
        "item2": 26047,
        "item3": 26058,
        "item4": 46016,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15305,
        "idmap": 12281,
        "ten_map": "Quan Tra",
        "thanh": "U Châu",
        "ten_npc": "Dưỡng Mã Quan",
        "level": 27,
        "hp": 133,
        "sp": 118,
        "hpx": 10,
        "spx": 5,
        "int": 11,
        "atk": 10,
        "def": 7,
        "agi": 11,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15319,
        "idmap": 12301,
        "ten_map": "Dai Dien Chu Hau",
        "thanh": "U Châu",
        "ten_npc": "Hồ Nhị Bá",
        "level": 27,
        "hp": 137,
        "sp": 127,
        "hpx": 11,
        "spx": 7,
        "int": 2,
        "atk": 15,
        "def": 12,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15327,
        "idmap": 12301,
        "ten_map": "Dai Dien Chu Hau",
        "thanh": "U Châu",
        "ten_npc": "Bào Thành",
        "level": 27,
        "hp": 147,
        "sp": 118,
        "hpx": 13,
        "spx": 5,
        "int": 8,
        "atk": 4,
        "def": 13,
        "agi": 11,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15360,
        "idmap": 12305,
        "ten_map": "Trai Giam Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Thái Tiểu Hồng",
        "level": 27,
        "hp": 133,
        "sp": 118,
        "hpx": 10,
        "spx": 5,
        "int": 11,
        "atk": 10,
        "def": 7,
        "agi": 11,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15370,
        "idmap": 12309,
        "thanh": "U Châu",
        "ten_npc": "Lưu Bân",
        "level": 27,
        "hp": 147,
        "sp": 118,
        "hpx": 13,
        "spx": 5,
        "int": 4,
        "atk": 13,
        "def": 8,
        "agi": 11,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15373,
        "idmap": 12310,
        "ten_map": "Lac Hai Quan Phu",
        "thanh": "U Châu",
        "ten_npc": "Ác viên ngoại",
        "level": 27,
        "hp": 142,
        "sp": 132,
        "hpx": 12,
        "spx": 8,
        "int": 12,
        "atk": 5,
        "def": 7,
        "agi": 10,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17116,
        "idmap": 12536,
        "thanh": "U Châu",
        "ten_npc": "Thiên Võ Quân",
        "level": 27,
        "hp": 169,
        "sp": 122,
        "hpx": 12,
        "spx": 6,
        "int": 5,
        "atk": 12,
        "def": 9,
        "agi": 10,
        "item1": 27034,
        "item2": 26111,
        "item3": 26035,
        "item4": 50001,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17176,
        "idmap": 12563,
        "thanh": "U Châu",
        "ten_npc": "Hắt Phàm Tặc",
        "level": 27,
        "hp": 137,
        "sp": 113,
        "hpx": 11,
        "spx": 4,
        "int": 3,
        "atk": 12,
        "def": 11,
        "agi": 13,
        "item1": 26080,
        "item2": 26162,
        "item3": 26004,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17296,
        "idmap": 12591,
        "ten_map": "Mo Khoang Trac Quan 1",
        "thanh": "U Châu",
        "ten_npc": "Giác Nha Nữ",
        "level": 27,
        "hp": 137,
        "sp": 125,
        "hpx": 11,
        "spx": 7,
        "int": 3,
        "atk": 14,
        "def": 10,
        "agi": 9,
        "item1": 26100,
        "item2": 26075,
        "item3": 26074,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10055,
        "idmap": 10809,
        "ten_npc": "Binh Tinh Giáp",
        "level": 28,
        "hp": 154,
        "sp": 119,
        "hpx": 14,
        "spx": 5,
        "int": 7,
        "atk": 9,
        "def": 12,
        "agi": 9,
        "item1": 27029,
        "item2": 26045,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10199,
        "idmap": 10912,
        "ten_npc": "Lính Hắt Sơn",
        "level": 28,
        "hp": 172,
        "sp": 133,
        "hpx": 11,
        "spx": 8,
        "int": 10,
        "atk": 9,
        "def": 9,
        "agi": 9,
        "item1": 26169,
        "item2": 26169,
        "item3": 27035,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14141,
        "idmap": 11585,
        "thanh": "Thanh Châu",
        "ten_npc": "Trùm Hải Tặc",
        "level": 28,
        "hp": 141,
        "sp": 128,
        "hpx": 11,
        "spx": 7,
        "int": 6,
        "atk": 12,
        "def": 9,
        "agi": 11,
        "item1": 26040,
        "item2": 26118,
        "item3": 26117,
        "item4": 46016,
        "item5": 26130,
        "item6": 0
    },
    {
        "idnpc": 14214,
        "idmap": 11823,
        "thanh": "Thanh Châu",
        "ten_npc": "Nữ Sơn Tặc",
        "level": 28,
        "hp": 131,
        "sp": 115,
        "hpx": 9,
        "spx": 4,
        "int": 6,
        "atk": 12,
        "def": 9,
        "agi": 16,
        "item1": 26035,
        "item2": 26101,
        "item3": 26101,
        "item4": 46018,
        "item5": 24003,
        "item6": 0
    },
    {
        "idnpc": 15144,
        "idmap": 12095,
        "ten_map": "Tang Cao Khong Tuoc Dai",
        "thanh": "U Châu",
        "ten_npc": "Thuyền Hòa",
        "level": 28,
        "hp": 136,
        "sp": 124,
        "hpx": 10,
        "spx": 6,
        "int": 4,
        "atk": 13,
        "def": 9,
        "agi": 14,
        "item1": 25032,
        "item2": 32004,
        "item3": 32004,
        "item4": 50001,
        "item5": 24003,
        "item6": 0
    },
    {
        "idnpc": 15145,
        "idmap": 12095,
        "ten_map": "Tang Cao Khong Tuoc Dai",
        "thanh": "U Châu",
        "ten_npc": "Nữ Tặc",
        "level": 28,
        "hp": 136,
        "sp": 124,
        "hpx": 10,
        "spx": 6,
        "int": 3,
        "atk": 13,
        "def": 9,
        "agi": 15,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15155,
        "idmap": 12096,
        "ten_map": "Bac Thang Khong Tuoc2",
        "thanh": "U Châu",
        "ten_npc": "Lang Đầu Ca",
        "level": 28,
        "hp": 131,
        "sp": 124,
        "hpx": 9,
        "spx": 6,
        "int": 3,
        "atk": 16,
        "def": 11,
        "agi": 11,
        "item1": 26047,
        "item2": 26047,
        "item3": 26058,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15285,
        "idmap": 12242,
        "ten_map": "Nha Tro Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Ngưu Lang",
        "level": 28,
        "hp": 150,
        "sp": 119,
        "hpx": 13,
        "spx": 5,
        "int": 5,
        "atk": 9,
        "def": 15,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15369,
        "idmap": 12309,
        "thanh": "U Châu",
        "ten_npc": "Gia Cát Nguyên",
        "level": 28,
        "hp": 150,
        "sp": 129,
        "hpx": 13,
        "spx": 7,
        "int": 15,
        "atk": 7,
        "def": 5,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16063,
        "idmap": 12501,
        "ten_map": "N.Dong Dai Hung Son 1",
        "thanh": "U Châu",
        "ten_npc": "Vũ Xương Úy",
        "level": 28,
        "hp": 150,
        "sp": 119,
        "hpx": 13,
        "spx": 5,
        "int": 4,
        "atk": 13,
        "def": 11,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16065,
        "idmap": 12502,
        "thanh": "U Châu",
        "ten_npc": "Nho Tu úy",
        "level": 28,
        "hp": 150,
        "sp": 119,
        "hpx": 13,
        "spx": 5,
        "int": 4,
        "atk": 13,
        "def": 11,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17063,
        "idmap": 12521,
        "ten_map": "S.Dong KhVang 1",
        "thanh": "U Châu",
        "ten_npc": "Diêm Giáp Sĩ",
        "level": 28,
        "hp": 150,
        "sp": 119,
        "hpx": 13,
        "spx": 5,
        "int": 6,
        "atk": 12,
        "def": 10,
        "agi": 8,
        "item1": 27061,
        "item2": 27065,
        "item3": 27055,
        "item4": 25031,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17295,
        "idmap": 12591,
        "ten_map": "Mo Khoang Trac Quan 1",
        "thanh": "U Châu",
        "ten_npc": "Binh Da Sói",
        "level": 28,
        "hp": 154,
        "sp": 115,
        "hpx": 14,
        "spx": 4,
        "int": 3,
        "atk": 12,
        "def": 8,
        "agi": 10,
        "item1": 26166,
        "item2": 26165,
        "item3": 26157,
        "item4": 25001,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17297,
        "idmap": 12591,
        "ten_map": "Mo Khoang Trac Quan 1",
        "thanh": "U Châu",
        "ten_npc": "Binh Móng Sói",
        "level": 28,
        "hp": 150,
        "sp": 119,
        "hpx": 13,
        "spx": 5,
        "int": 3,
        "atk": 10,
        "def": 12,
        "agi": 8,
        "item1": 26048,
        "item2": 26040,
        "item3": 26039,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10200,
        "idmap": 10912,
        "ten_npc": "Quân Hắt Sơn",
        "level": 29,
        "hp": 176,
        "sp": 130,
        "hpx": 12,
        "spx": 7,
        "int": 11,
        "atk": 10,
        "def": 9,
        "agi": 10,
        "item1": 26064,
        "item2": 26169,
        "item3": 26064,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11129,
        "idmap": 10997,
        "ten_npc": "Lưu Phục",
        "level": 29,
        "hp": 139,
        "sp": 150,
        "hpx": 10,
        "spx": 7,
        "int": 20,
        "atk": 10,
        "def": 6,
        "agi": 12,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12052,
        "idmap": 11201,
        "ten_map": "Lo Ren Dong Quan",
        "thanh": "Thanh Châu",
        "ten_npc": "Thanh Lôi",
        "level": 29,
        "hp": 146,
        "sp": 126,
        "hpx": 12,
        "spx": 6,
        "int": 11,
        "atk": 12,
        "def": 8,
        "agi": 11,
        "item1": 25046,
        "item2": 26054,
        "item3": 26054,
        "item4": 0,
        "item5": 24003,
        "item6": 0
    },
    {
        "idnpc": 14132,
        "idmap": 11585,
        "thanh": "Thanh Châu",
        "ten_npc": "Phi Tặc",
        "level": 29,
        "hp": 146,
        "sp": 121,
        "hpx": 12,
        "spx": 5,
        "int": 6,
        "atk": 14,
        "def": 7,
        "agi": 16,
        "item1": 26039,
        "item2": 26165,
        "item3": 26057,
        "item4": 46016,
        "item5": 26115,
        "item6": 0
    },
    {
        "idnpc": 14136,
        "idmap": 11585,
        "thanh": "Thanh Châu",
        "ten_npc": "Ph.Lai Hải Tặc",
        "level": 29,
        "hp": 146,
        "sp": 116,
        "hpx": 12,
        "spx": 4,
        "int": 5,
        "atk": 14,
        "def": 10,
        "agi": 13,
        "item1": 26040,
        "item2": 26118,
        "item3": 26117,
        "item4": 46016,
        "item5": 24002,
        "item6": 0
    },
    {
        "idnpc": 15146,
        "idmap": 12095,
        "ten_map": "Tang Cao Khong Tuoc Dai",
        "thanh": "U Châu",
        "ten_npc": "Mao Hòan Cơ",
        "level": 29,
        "hp": 139,
        "sp": 158,
        "hpx": 10,
        "spx": 13,
        "int": 16,
        "atk": 3,
        "def": 5,
        "agi": 11,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15339,
        "idmap": 12302,
        "ten_map": "Quan Phu Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Triệu Phụ",
        "level": 29,
        "hp": 153,
        "sp": 164,
        "hpx": 13,
        "spx": 10,
        "int": 16,
        "atk": 3,
        "def": 10,
        "agi": 8,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15409,
        "idmap": 12406,
        "thanh": "U Châu",
        "ten_npc": "Hiên viên",
        "level": 29,
        "hp": 144,
        "sp": 155,
        "hpx": 11,
        "spx": 8,
        "int": 7,
        "atk": 14,
        "def": 6,
        "agi": 14,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17016,
        "idmap": 12513,
        "thanh": "U Châu",
        "ten_npc": "Hổ Náo Quân",
        "level": 29,
        "hp": 153,
        "sp": 126,
        "hpx": 13,
        "spx": 6,
        "int": 5,
        "atk": 15,
        "def": 10,
        "agi": 9,
        "item1": 27029,
        "item2": 27034,
        "item3": 27003,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17152,
        "idmap": 12552,
        "thanh": "U Châu",
        "ten_npc": "H.Kiếm Binh",
        "level": 29,
        "hp": 175,
        "sp": 130,
        "hpx": 12,
        "spx": 7,
        "int": 5,
        "atk": 10,
        "def": 11,
        "agi": 14,
        "item1": 27087,
        "item2": 26166,
        "item3": 26162,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17174,
        "idmap": 12563,
        "thanh": "U Châu",
        "ten_npc": "Đạo Tân Tặc",
        "level": 29,
        "hp": 146,
        "sp": 130,
        "hpx": 12,
        "spx": 7,
        "int": 3,
        "atk": 17,
        "def": 10,
        "agi": 10,
        "item1": 27002,
        "item2": 26164,
        "item3": 26040,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17298,
        "idmap": 12591,
        "ten_map": "Mo Khoang Trac Quan 1",
        "thanh": "U Châu",
        "ten_npc": "Huyết Dũng Sĩ",
        "level": 29,
        "hp": 162,
        "sp": 116,
        "hpx": 15,
        "spx": 4,
        "int": 3,
        "atk": 9,
        "def": 14,
        "agi": 14,
        "item1": 26027,
        "item2": 26165,
        "item3": 26026,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17299,
        "idmap": 12591,
        "ten_map": "Mo Khoang Trac Quan 1",
        "thanh": "U Châu",
        "ten_npc": "Cư Xà Mao",
        "level": 29,
        "hp": 146,
        "sp": 121,
        "hpx": 12,
        "spx": 5,
        "int": 5,
        "atk": 12,
        "def": 15,
        "agi": 10,
        "item1": 27024,
        "item2": 26165,
        "item3": 27016,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10089,
        "idmap": 10815,
        "ten_map": "Tiem net",
        "ten_npc": "Tế Rượu Vệ",
        "level": 30,
        "hp": 128,
        "sp": 155,
        "hpx": 7,
        "spx": 12,
        "int": 18,
        "atk": 4,
        "def": 6,
        "agi": 13,
        "item1": 26162,
        "item2": 27054,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10121,
        "idmap": 10820,
        "ten_npc": "Quỷ Vệ Binh",
        "level": 30,
        "hp": 151,
        "sp": 118,
        "hpx": 12,
        "spx": 4,
        "int": 6,
        "atk": 18,
        "def": 12,
        "agi": 8,
        "item1": 26051,
        "item2": 27055,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10156,
        "idmap": 10866,
        "ten_npc": "Thiên.Ph.Tướng",
        "level": 30,
        "hp": 142,
        "sp": 135,
        "hpx": 10,
        "spx": 8,
        "int": 12,
        "atk": 12,
        "def": 8,
        "agi": 10,
        "item1": 26033,
        "item2": 26051,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10166,
        "idmap": 10901,
        "ten_npc": "Đại.Ph.Tướng",
        "level": 30,
        "hp": 151,
        "sp": 134,
        "hpx": 12,
        "spx": 7,
        "int": 8,
        "atk": 12,
        "def": 12,
        "agi": 10,
        "item1": 26016,
        "item2": 26053,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10175,
        "idmap": 10904,
        "ten_npc": "Binh ThanhChâu",
        "level": 30,
        "hp": 161,
        "sp": 127,
        "hpx": 14,
        "spx": 6,
        "int": 7,
        "atk": 12,
        "def": 11,
        "agi": 10,
        "item1": 27024,
        "item2": 27024,
        "item3": 27026,
        "item4": 46016,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10201,
        "idmap": 10913,
        "ten_npc": "H.Sơn Chiến Sĩ",
        "level": 30,
        "hp": 181,
        "sp": 134,
        "hpx": 12,
        "spx": 8,
        "int": 9,
        "atk": 12,
        "def": 11,
        "agi": 9,
        "item1": 27004,
        "item2": 27037,
        "item3": 27004,
        "item4": 50001,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14113,
        "idmap": 11584,
        "thanh": "Thanh Châu",
        "ten_npc": "Vương Doãn",
        "level": 30,
        "hp": 187,
        "sp": 159,
        "hpx": 10,
        "spx": 8,
        "int": 27,
        "atk": 2,
        "def": 10,
        "agi": 5,
        "item1": 27065,
        "item2": 27035,
        "item3": 26064,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14137,
        "idmap": 11585,
        "thanh": "Thanh Châu",
        "ten_npc": "Tiểu Hải Tặc",
        "level": 30,
        "hp": 151,
        "sp": 133,
        "hpx": 12,
        "spx": 7,
        "int": 5,
        "atk": 12,
        "def": 10,
        "agi": 14,
        "item1": 26117,
        "item2": 26120,
        "item3": 26119,
        "item4": 46018,
        "item5": 24002,
        "item6": 0
    },
    {
        "idnpc": 14143,
        "idmap": 11585,
        "thanh": "Thanh Châu",
        "ten_npc": "Đại Hải Tặc",
        "level": 30,
        "hp": 146,
        "sp": 122,
        "hpx": 11,
        "spx": 5,
        "int": 3,
        "atk": 13,
        "def": 11,
        "agi": 17,
        "item1": 26117,
        "item2": 26120,
        "item3": 26119,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14506,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Triệu Thương",
        "level": 30,
        "hp": 146,
        "sp": 162,
        "hpx": 11,
        "spx": 9,
        "int": 16,
        "atk": 8,
        "def": 9,
        "agi": 12,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15138,
        "idmap": 12095,
        "ten_map": "Tang Cao Khong Tuoc Dai",
        "thanh": "U Châu",
        "ten_npc": "Vân Du Đạo Sĩ",
        "level": 30,
        "hp": 142,
        "sp": 122,
        "hpx": 10,
        "spx": 5,
        "int": 18,
        "atk": 4,
        "def": 10,
        "agi": 13,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15141,
        "idmap": 12095,
        "ten_map": "Tang Cao Khong Tuoc Dai",
        "thanh": "U Châu",
        "ten_npc": "Tiểu Hoa Tinh",
        "level": 30,
        "hp": 123,
        "sp": 146,
        "hpx": 6,
        "spx": 10,
        "int": 16,
        "atk": 9,
        "def": 9,
        "agi": 10,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15149,
        "idmap": 12096,
        "ten_map": "Bac Thang Khong Tuoc2",
        "thanh": "U Châu",
        "ten_npc": "Nữ Khoáng Tặc",
        "level": 30,
        "hp": 151,
        "sp": 122,
        "hpx": 12,
        "spx": 5,
        "int": 5,
        "atk": 11,
        "def": 9,
        "agi": 18,
        "item1": 27038,
        "item2": 27042,
        "item3": 27042,
        "item4": 0,
        "item5": 26116,
        "item6": 0
    },
    {
        "idnpc": 15283,
        "idmap": 12242,
        "ten_map": "Nha Tro Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "ChủLôi Đài",
        "level": 30,
        "hp": 180,
        "sp": 122,
        "hpx": 15,
        "spx": 5,
        "int": 15,
        "atk": 10,
        "def": 7,
        "agi": 8,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15290,
        "idmap": 12243,
        "ten_map": "Nha Tro Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Ngô Trưởng Lữo",
        "level": 30,
        "hp": 180,
        "sp": 122,
        "hpx": 15,
        "spx": 5,
        "int": 15,
        "atk": 10,
        "def": 7,
        "agi": 8,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16070,
        "idmap": 12504,
        "thanh": "U Châu",
        "ten_npc": "Công An đốc",
        "level": 30,
        "hp": 165,
        "sp": 122,
        "hpx": 15,
        "spx": 5,
        "int": 6,
        "atk": 14,
        "def": 13,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16072,
        "idmap": 12504,
        "thanh": "U Châu",
        "ten_npc": "Ngưu Chử đốc",
        "level": 30,
        "hp": 165,
        "sp": 122,
        "hpx": 15,
        "spx": 5,
        "int": 6,
        "atk": 14,
        "def": 13,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17153,
        "idmap": 12554,
        "thanh": "U Châu",
        "ten_npc": "Tr.Hộ Quân",
        "level": 30,
        "hp": 191,
        "sp": 122,
        "hpx": 14,
        "spx": 5,
        "int": 5,
        "atk": 15,
        "def": 11,
        "agi": 9,
        "item1": 27008,
        "item2": 26042,
        "item3": 26075,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17178,
        "idmap": 12563,
        "thanh": "U Châu",
        "ten_npc": "Đông Quận Binh",
        "level": 30,
        "hp": 156,
        "sp": 122,
        "hpx": 13,
        "spx": 5,
        "int": 5,
        "atk": 14,
        "def": 10,
        "agi": 13,
        "item1": 26034,
        "item2": 27018,
        "item3": 26002,
        "item4": 46016,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12163,
        "idmap": 11502,
        "ten_map": "Son Dong Thai Son 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Lưu Lý",
        "level": 31,
        "hp": 267,
        "sp": 157,
        "hpx": 13,
        "spx": 7,
        "int": 4,
        "atk": 19,
        "def": 9,
        "agi": 12,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14138,
        "idmap": 11585,
        "thanh": "Thanh Châu",
        "ten_npc": "Hải Tặc Nhị Ca",
        "level": 31,
        "hp": 156,
        "sp": 136,
        "hpx": 12,
        "spx": 8,
        "int": 5,
        "atk": 13,
        "def": 11,
        "agi": 14,
        "item1": 26117,
        "item2": 26120,
        "item3": 26119,
        "item4": 46016,
        "item5": 24002,
        "item6": 0
    },
    {
        "idnpc": 14446,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Quỷ Chảo",
        "level": 31,
        "hp": 156,
        "sp": 135,
        "hpx": 12,
        "spx": 7,
        "int": 3,
        "atk": 18,
        "def": 13,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14705,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "ChDungbịthương",
        "level": 31,
        "hp": 298,
        "sp": 174,
        "hpx": 13,
        "spx": 11,
        "int": 8,
        "atk": 21,
        "def": 9,
        "agi": 16,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15150,
        "idmap": 12096,
        "ten_map": "Bac Thang Khong Tuoc2",
        "thanh": "U Châu",
        "ten_npc": "Đạo Khoáng Ca",
        "level": 31,
        "hp": 156,
        "sp": 136,
        "hpx": 12,
        "spx": 8,
        "int": 6,
        "atk": 17,
        "def": 14,
        "agi": 11,
        "item1": 27041,
        "item2": 27038,
        "item3": 27038,
        "item4": 0,
        "item5": 24001,
        "item6": 0
    },
    {
        "idnpc": 15410,
        "idmap": 12407,
        "thanh": "U Châu",
        "ten_npc": "Hồ độ long",
        "level": 31,
        "hp": 135,
        "sp": 174,
        "hpx": 8,
        "spx": 11,
        "int": 7,
        "atk": 16,
        "def": 6,
        "agi": 14,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17175,
        "idmap": 12563,
        "thanh": "U Châu",
        "ten_npc": "Xướng Hải Đạo",
        "level": 31,
        "hp": 159,
        "sp": 135,
        "hpx": 13,
        "spx": 7,
        "int": 3,
        "atk": 16,
        "def": 10,
        "agi": 12,
        "item1": 27017,
        "item2": 26051,
        "item3": 26040,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10173,
        "idmap": 10903,
        "ten_npc": "Thanh Châu Sĩ",
        "level": 32,
        "hp": 152,
        "sp": 159,
        "hpx": 11,
        "spx": 12,
        "int": 20,
        "atk": 7,
        "def": 3,
        "agi": 11,
        "item1": 26036,
        "item2": 27031,
        "item3": 27029,
        "item4": 46016,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14139,
        "idmap": 11585,
        "thanh": "Thanh Châu",
        "ten_npc": "Hải Tặc Đại Ca",
        "level": 32,
        "hp": 161,
        "sp": 139,
        "hpx": 13,
        "spx": 8,
        "int": 6,
        "atk": 15,
        "def": 11,
        "agi": 12,
        "item1": 26117,
        "item2": 26120,
        "item3": 26119,
        "item4": 50001,
        "item5": 26128,
        "item6": 0
    },
    {
        "idnpc": 14161,
        "idmap": 11801,
        "ten_map": "Rung Dong Quan 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Man Tộc",
        "level": 32,
        "hp": 152,
        "sp": 116,
        "hpx": 11,
        "spx": 3,
        "int": 4,
        "atk": 20,
        "def": 15,
        "agi": 11,
        "item1": 26057,
        "item2": 26035,
        "item3": 26086,
        "item4": 46018,
        "item5": 39004,
        "item6": 0
    },
    {
        "idnpc": 14176,
        "idmap": 11802,
        "ten_map": "Rung Dong Quan 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Công Tôn Độ",
        "level": 32,
        "hp": 245,
        "sp": 164,
        "hpx": 12,
        "spx": 9,
        "int": 20,
        "atk": 7,
        "def": 8,
        "agi": 11,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14266,
        "idmap": 12000,
        "ten_map": "U Chau",
        "thanh": "U Châu",
        "ten_npc": "Quản Ninh",
        "level": 32,
        "hp": 253,
        "sp": 163,
        "hpx": 13,
        "spx": 8,
        "int": 14,
        "atk": 4,
        "def": 12,
        "agi": 15,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47282
    },
    {
        "idnpc": 14301,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Mao Huy",
        "level": 32,
        "hp": 225,
        "sp": 164,
        "hpx": 13,
        "spx": 9,
        "int": 20,
        "atk": 9,
        "def": 4,
        "agi": 15,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14384,
        "idmap": 12009,
        "ten_map": "N.Quach Dich",
        "thanh": "U Châu",
        "ten_npc": "Công Tôn Cung",
        "level": 32,
        "hp": 221,
        "sp": 161,
        "hpx": 12,
        "spx": 8,
        "int": 7,
        "atk": 16,
        "def": 16,
        "agi": 11,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14432,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Ngô Tử Lan",
        "level": 32,
        "hp": 216,
        "sp": 181,
        "hpx": 11,
        "spx": 12,
        "int": 22,
        "atk": 8,
        "def": 4,
        "agi": 12,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15151,
        "idmap": 12096,
        "ten_map": "Bac Thang Khong Tuoc2",
        "thanh": "U Châu",
        "ten_npc": "ĐạoKhoáng Lĩnh",
        "level": 32,
        "hp": 161,
        "sp": 121,
        "hpx": 13,
        "spx": 4,
        "int": 6,
        "atk": 17,
        "def": 14,
        "agi": 11,
        "item1": 27041,
        "item2": 27038,
        "item3": 27042,
        "item4": 0,
        "item5": 24001,
        "item6": 0
    },
    {
        "idnpc": 15152,
        "idmap": 12096,
        "ten_map": "Bac Thang Khong Tuoc2",
        "thanh": "U Châu",
        "ten_npc": "Binh Bồng Bồng",
        "level": 32,
        "hp": 148,
        "sp": 125,
        "hpx": 10,
        "spx": 5,
        "int": 6,
        "atk": 17,
        "def": 15,
        "agi": 11,
        "item1": 34017,
        "item2": 35001,
        "item3": 0,
        "item4": 46016,
        "item5": 39006,
        "item6": 0
    },
    {
        "idnpc": 16066,
        "idmap": 12502,
        "thanh": "U Châu",
        "ten_npc": "Sài Tang đốc",
        "level": 32,
        "hp": 161,
        "sp": 130,
        "hpx": 13,
        "spx": 6,
        "int": 6,
        "atk": 16,
        "def": 16,
        "agi": 8,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16068,
        "idmap": 12502,
        "thanh": "U Châu",
        "ten_npc": "Giang Lăng đốc",
        "level": 32,
        "hp": 161,
        "sp": 130,
        "hpx": 13,
        "spx": 6,
        "int": 6,
        "atk": 16,
        "def": 16,
        "agi": 8,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17179,
        "idmap": 12563,
        "thanh": "U Châu",
        "ten_npc": "Hạ Ti Binh",
        "level": 32,
        "hp": 167,
        "sp": 138,
        "hpx": 14,
        "spx": 8,
        "int": 4,
        "atk": 18,
        "def": 12,
        "agi": 8,
        "item1": 27055,
        "item2": 26042,
        "item3": 26051,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11094,
        "idmap": 10993,
        "ten_map": "Vung dat bang gia 2",
        "ten_npc": "Tào Thiệu",
        "level": 33,
        "hp": 215,
        "sp": 165,
        "hpx": 13,
        "spx": 8,
        "int": 8,
        "atk": 17,
        "def": 14,
        "agi": 11,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12162,
        "idmap": 11502,
        "ten_map": "Son Dong Thai Son 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Lưu Vĩnh",
        "level": 33,
        "hp": 290,
        "sp": 168,
        "hpx": 15,
        "spx": 9,
        "int": 20,
        "atk": 5,
        "def": 10,
        "agi": 12,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13051,
        "idmap": 11519,
        "ten_map": "Son Dong Thai Son 17",
        "thanh": "Thanh Châu",
        "ten_npc": "Ngô Cảnh",
        "level": 33,
        "hp": 215,
        "sp": 168,
        "hpx": 13,
        "spx": 9,
        "int": 21,
        "atk": 6,
        "def": 9,
        "agi": 13,
        "item1": 26098,
        "item2": 26099,
        "item3": 26082,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13084,
        "idmap": 11535,
        "ten_map": "Dong Quy Than 5",
        "thanh": "Thanh Châu",
        "ten_npc": "Kiều Quốc Lữo",
        "level": 33,
        "hp": 282,
        "sp": 144,
        "hpx": 10,
        "spx": 9,
        "int": 19,
        "atk": 4,
        "def": 11,
        "agi": 18,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14072,
        "idmap": 11582,
        "thanh": "Thanh Châu",
        "ten_npc": "Lưu Nhiên",
        "level": 33,
        "hp": 215,
        "sp": 167,
        "hpx": 13,
        "spx": 9,
        "int": 16,
        "atk": 10,
        "def": 9,
        "agi": 11,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14178,
        "idmap": 11802,
        "ten_map": "Rung Dong Quan 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Công Tôn Tục",
        "level": 33,
        "hp": 255,
        "sp": 166,
        "hpx": 13,
        "spx": 8,
        "int": 13,
        "atk": 18,
        "def": 9,
        "agi": 6,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14294,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Hứa Đảm",
        "level": 33,
        "hp": 232,
        "sp": 167,
        "hpx": 13,
        "spx": 9,
        "int": 17,
        "atk": 9,
        "def": 11,
        "agi": 12,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14418,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Bỉnh Nguyên",
        "level": 33,
        "hp": 219,
        "sp": 160,
        "hpx": 14,
        "spx": 7,
        "int": 15,
        "atk": 7,
        "def": 15,
        "agi": 13,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47356
    },
    {
        "idnpc": 14433,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Trồng Tấp",
        "level": 33,
        "hp": 232,
        "sp": 165,
        "hpx": 13,
        "spx": 8,
        "int": 7,
        "atk": 18,
        "def": 9,
        "agi": 16,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15153,
        "idmap": 12096,
        "ten_map": "Bac Thang Khong Tuoc2",
        "thanh": "U Châu",
        "ten_npc": "BồngLaiTTrưởng",
        "level": 33,
        "hp": 155,
        "sp": 156,
        "hpx": 11,
        "spx": 11,
        "int": 16,
        "atk": 8,
        "def": 9,
        "agi": 11,
        "item1": 35003,
        "item2": 35012,
        "item3": 0,
        "item4": 46016,
        "item5": 39008,
        "item6": 0
    },
    {
        "idnpc": 15167,
        "idmap": 12101,
        "ten_map": "N.Ly Lang Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Cô Dệt Vải",
        "level": 33,
        "hp": 166,
        "sp": 144,
        "hpx": 13,
        "spx": 9,
        "int": 18,
        "atk": 6,
        "def": 8,
        "agi": 12,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15170,
        "idmap": 12101,
        "ten_map": "N.Ly Lang Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Lữo Tiều Phu",
        "level": 33,
        "hp": 198,
        "sp": 122,
        "hpx": 20,
        "spx": 4,
        "int": 6,
        "atk": 7,
        "def": 14,
        "agi": 15,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16022,
        "idmap": 12419,
        "thanh": "U Châu",
        "ten_npc": "Thương Nhân A",
        "level": 33,
        "hp": 166,
        "sp": 142,
        "hpx": 13,
        "spx": 8,
        "int": 9,
        "atk": 10,
        "def": 12,
        "agi": 14,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16023,
        "idmap": 12419,
        "thanh": "U Châu",
        "ten_npc": "Tiền Trang",
        "level": 33,
        "hp": 166,
        "sp": 142,
        "hpx": 13,
        "spx": 8,
        "int": 9,
        "atk": 10,
        "def": 12,
        "agi": 14,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17049,
        "idmap": 12519,
        "thanh": "U Châu",
        "ten_npc": "Võ Duệ Binh",
        "level": 33,
        "hp": 155,
        "sp": 136,
        "hpx": 11,
        "spx": 7,
        "int": 5,
        "atk": 19,
        "def": 12,
        "agi": 12,
        "item1": 26035,
        "item2": 27006,
        "item3": 26065,
        "item4": 50001,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17180,
        "idmap": 12564,
        "thanh": "U Châu",
        "ten_npc": "Như Đài Binh",
        "level": 33,
        "hp": 155,
        "sp": 132,
        "hpx": 11,
        "spx": 6,
        "int": 4,
        "atk": 17,
        "def": 16,
        "agi": 12,
        "item1": 26056,
        "item2": 26163,
        "item3": 27025,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17198,
        "idmap": 12571,
        "ten_map": "S.Dong KhVang 51",
        "thanh": "U Châu",
        "ten_npc": "Hải Nô",
        "level": 33,
        "hp": 166,
        "sp": 142,
        "hpx": 13,
        "spx": 8,
        "int": 9,
        "atk": 18,
        "def": 11,
        "agi": 7,
        "item1": 27065,
        "item2": 26166,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10174,
        "idmap": 10904,
        "ten_npc": "Quân ThanhChâu",
        "level": 34,
        "hp": 187,
        "sp": 143,
        "hpx": 17,
        "spx": 8,
        "int": 10,
        "atk": 12,
        "def": 10,
        "agi": 11,
        "item1": 27031,
        "item2": 27029,
        "item3": 27037,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14162,
        "idmap": 11801,
        "ten_map": "Rung Dong Quan 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Người Mọi",
        "level": 34,
        "hp": 171,
        "sp": 138,
        "hpx": 14,
        "spx": 7,
        "int": 5,
        "atk": 16,
        "def": 21,
        "agi": 5,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14206,
        "idmap": 11821,
        "thanh": "Thanh Châu",
        "ten_npc": "TrùmTướngCướp",
        "level": 34,
        "hp": 158,
        "sp": 124,
        "hpx": 11,
        "spx": 4,
        "int": 5,
        "atk": 20,
        "def": 15,
        "agi": 13,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14212,
        "idmap": 11822,
        "thanh": "Thanh Châu",
        "ten_npc": "Mã Tặc Vương",
        "level": 34,
        "hp": 153,
        "sp": 114,
        "hpx": 10,
        "spx": 2,
        "int": 7,
        "atk": 28,
        "def": 4,
        "agi": 17,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14213,
        "idmap": 11822,
        "thanh": "Thanh Châu",
        "ten_npc": "Hồ Phỉ",
        "level": 34,
        "hp": 171,
        "sp": 114,
        "hpx": 14,
        "spx": 2,
        "int": 2,
        "atk": 18,
        "def": 26,
        "agi": 6,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14215,
        "idmap": 11823,
        "thanh": "Thanh Châu",
        "ten_npc": "HiệpSĩTrẻ",
        "level": 34,
        "hp": 153,
        "sp": 114,
        "hpx": 10,
        "spx": 2,
        "int": 7,
        "atk": 28,
        "def": 4,
        "agi": 17,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14216,
        "idmap": 11823,
        "thanh": "Thanh Châu",
        "ten_npc": "Vương Hòanh",
        "level": 34,
        "hp": 254,
        "sp": 174,
        "hpx": 12,
        "spx": 9,
        "int": 30,
        "atk": 4,
        "def": 7,
        "agi": 8,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14228,
        "idmap": 11831,
        "ten_map": "Lang Ta Phi Lam 1",
        "thanh": "Thanh Châu",
        "ten_npc": "TặcHoàngHàA",
        "level": 34,
        "hp": 171,
        "sp": 133,
        "hpx": 14,
        "spx": 6,
        "int": 5,
        "atk": 19,
        "def": 12,
        "agi": 12,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17017,
        "idmap": 12513,
        "thanh": "U Châu",
        "ten_npc": "Phi Hùng Quân",
        "level": 34,
        "hp": 177,
        "sp": 144,
        "hpx": 15,
        "spx": 8,
        "int": 6,
        "atk": 17,
        "def": 11,
        "agi": 11,
        "item1": 26004,
        "item2": 27030,
        "item3": 26083,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17108,
        "idmap": 12532,
        "thanh": "U Châu",
        "ten_npc": "Hùng Võ Quân",
        "level": 34,
        "hp": 226,
        "sp": 143,
        "hpx": 18,
        "spx": 8,
        "int": 2,
        "atk": 16,
        "def": 11,
        "agi": 11,
        "item1": 26120,
        "item2": 32020,
        "item3": 26117,
        "item4": 25016,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17117,
        "idmap": 12536,
        "thanh": "U Châu",
        "ten_npc": "Quang Võ Quân",
        "level": 34,
        "hp": 216,
        "sp": 138,
        "hpx": 16,
        "spx": 7,
        "int": 5,
        "atk": 16,
        "def": 12,
        "agi": 11,
        "item1": 27019,
        "item2": 27017,
        "item3": 27018,
        "item4": 0,
        "item5": 45001,
        "item6": 0
    },
    {
        "idnpc": 17181,
        "idmap": 12564,
        "thanh": "U Châu",
        "ten_npc": "Thọ Xuân Binh",
        "level": 34,
        "hp": 177,
        "sp": 143,
        "hpx": 15,
        "spx": 8,
        "int": 4,
        "atk": 18,
        "def": 13,
        "agi": 10,
        "item1": 26156,
        "item2": 27036,
        "item3": 26085,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17199,
        "idmap": 12571,
        "ten_map": "S.Dong KhVang 51",
        "thanh": "U Châu",
        "ten_npc": "Thâm Hải Tặc",
        "level": 34,
        "hp": 171,
        "sp": 145,
        "hpx": 14,
        "spx": 8,
        "int": 9,
        "atk": 7,
        "def": 13,
        "agi": 18,
        "item1": 26098,
        "item2": 26054,
        "item3": 26041,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17222,
        "idmap": 12584,
        "thanh": "U Châu",
        "ten_npc": "Đại Mạc Binh",
        "level": 34,
        "hp": 199,
        "sp": 145,
        "hpx": 16,
        "spx": 9,
        "int": 11,
        "atk": 10,
        "def": 13,
        "agi": 11,
        "item1": 46018,
        "item2": 27038,
        "item3": 26156,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17224,
        "idmap": 12585,
        "thanh": "U Châu",
        "ten_npc": "Đại Mạc Lính",
        "level": 34,
        "hp": 188,
        "sp": 145,
        "hpx": 14,
        "spx": 8,
        "int": 10,
        "atk": 9,
        "def": 17,
        "agi": 12,
        "item1": 46016,
        "item2": 26079,
        "item3": 26167,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17229,
        "idmap": 12585,
        "thanh": "U Châu",
        "ten_npc": "Kiến.Ng.Binh",
        "level": 34,
        "hp": 171,
        "sp": 143,
        "hpx": 14,
        "spx": 8,
        "int": 4,
        "atk": 10,
        "def": 18,
        "agi": 12,
        "item1": 26027,
        "item2": 26055,
        "item3": 26042,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17230,
        "idmap": 12585,
        "thanh": "U Châu",
        "ten_npc": "Ngô Quận Binh",
        "level": 34,
        "hp": 171,
        "sp": 143,
        "hpx": 14,
        "spx": 8,
        "int": 4,
        "atk": 12,
        "def": 16,
        "agi": 12,
        "item1": 26017,
        "item2": 26044,
        "item3": 26077,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17301,
        "idmap": 12592,
        "ten_map": "Mo Khoang Trac Quan 2",
        "thanh": "U Châu",
        "ten_npc": "Báo Cơ",
        "level": 34,
        "hp": 187,
        "sp": 128,
        "hpx": 17,
        "spx": 5,
        "int": 4,
        "atk": 11,
        "def": 13,
        "agi": 16,
        "item1": 26170,
        "item2": 26035,
        "item3": 26111,
        "item4": 25018,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10026,
        "idmap": 10804,
        "ten_npc": "Triệu Chỉ",
        "level": 35,
        "hp": 228,
        "sp": 171,
        "hpx": 14,
        "spx": 9,
        "int": 6,
        "atk": 19,
        "def": 12,
        "agi": 13,
        "item1": 26078,
        "item2": 26003,
        "item3": 26101,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10029,
        "idmap": 10805,
        "ten_npc": "Đỗ Viễn",
        "level": 35,
        "hp": 228,
        "sp": 169,
        "hpx": 14,
        "spx": 8,
        "int": 9,
        "atk": 17,
        "def": 12,
        "agi": 12,
        "item1": 26078,
        "item2": 26003,
        "item3": 26101,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10050,
        "idmap": 10808,
        "ten_npc": "Trường.Th.Binh",
        "level": 35,
        "hp": 217,
        "sp": 115,
        "hpx": 19,
        "spx": 2,
        "int": 7,
        "atk": 16,
        "def": 14,
        "agi": 12,
        "item1": 26054,
        "item2": 26169,
        "item3": 26043,
        "item4": 46018,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10176,
        "idmap": 10904,
        "ten_npc": "Trưởng.Th.Châu",
        "level": 35,
        "hp": 185,
        "sp": 144,
        "hpx": 16,
        "spx": 8,
        "int": 13,
        "atk": 10,
        "def": 12,
        "agi": 12,
        "item1": 27029,
        "item2": 27031,
        "item3": 26036,
        "item4": 46016,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10182,
        "idmap": 10906,
        "ten_npc": "Lính Dao Lớn",
        "level": 35,
        "hp": 176,
        "sp": 154,
        "hpx": 14,
        "spx": 10,
        "int": 6,
        "atk": 16,
        "def": 17,
        "agi": 7,
        "item1": 26162,
        "item2": 27041,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10184,
        "idmap": 10907,
        "ten_npc": "Khiết.Th.Quân",
        "level": 35,
        "hp": 176,
        "sp": 154,
        "hpx": 14,
        "spx": 10,
        "int": 6,
        "atk": 16,
        "def": 17,
        "agi": 7,
        "item1": 26065,
        "item2": 26081,
        "item3": 27019,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10185,
        "idmap": 10907,
        "ten_npc": "Tử Thân Quân",
        "level": 35,
        "hp": 176,
        "sp": 154,
        "hpx": 14,
        "spx": 10,
        "int": 6,
        "atk": 16,
        "def": 17,
        "agi": 7,
        "item1": 26065,
        "item2": 26081,
        "item3": 27019,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10187,
        "idmap": 10908,
        "ten_npc": "Lục Thân Quân",
        "level": 35,
        "hp": 176,
        "sp": 154,
        "hpx": 14,
        "spx": 10,
        "int": 6,
        "atk": 16,
        "def": 17,
        "agi": 7,
        "item1": 26065,
        "item2": 26081,
        "item3": 27019,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14071,
        "idmap": 11582,
        "thanh": "Thanh Châu",
        "ten_npc": "Lưu Biểu",
        "level": 35,
        "hp": 228,
        "sp": 175,
        "hpx": 14,
        "spx": 9,
        "int": 21,
        "atk": 8,
        "def": 10,
        "agi": 13,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14098,
        "idmap": 11583,
        "thanh": "Thanh Châu",
        "ten_npc": "Châu Tịnh",
        "level": 35,
        "hp": 228,
        "sp": 173,
        "hpx": 14,
        "spx": 9,
        "int": 14,
        "atk": 9,
        "def": 10,
        "agi": 16,
        "item1": 27065,
        "item2": 27035,
        "item3": 26064,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14171,
        "idmap": 11802,
        "ten_map": "Rung Dong Quan 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Trịnh Huyền",
        "level": 35,
        "hp": 244,
        "sp": 177,
        "hpx": 9,
        "spx": 10,
        "int": 29,
        "atk": 4,
        "def": 10,
        "agi": 10,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14268,
        "idmap": 12000,
        "ten_map": "U Chau",
        "thanh": "U Châu",
        "ten_npc": "Phạm Phương",
        "level": 35,
        "hp": 246,
        "sp": 171,
        "hpx": 14,
        "spx": 9,
        "int": 7,
        "atk": 14,
        "def": 12,
        "agi": 16,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14291,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Lưu Chính",
        "level": 35,
        "hp": 246,
        "sp": 171,
        "hpx": 14,
        "spx": 9,
        "int": 6,
        "atk": 20,
        "def": 13,
        "agi": 14,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14497,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Y Gia",
        "level": 35,
        "hp": 228,
        "sp": 175,
        "hpx": 14,
        "spx": 9,
        "int": 21,
        "atk": 11,
        "def": 6,
        "agi": 15,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16062,
        "idmap": 12441,
        "ten_map": "Gioi Kieu",
        "thanh": "U Châu",
        "ten_npc": "Vũ Xương đốc",
        "level": 35,
        "hp": 185,
        "sp": 140,
        "hpx": 16,
        "spx": 7,
        "int": 4,
        "atk": 19,
        "def": 16,
        "agi": 9,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 16064,
        "idmap": 12502,
        "thanh": "U Châu",
        "ten_npc": "Nho Tu đốc",
        "level": 35,
        "hp": 185,
        "sp": 140,
        "hpx": 16,
        "spx": 7,
        "int": 4,
        "atk": 19,
        "def": 16,
        "agi": 9,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17060,
        "idmap": 12521,
        "ten_map": "S.Dong KhVang 1",
        "thanh": "U Châu",
        "ten_npc": "PhongTrận Binh",
        "level": 35,
        "hp": 176,
        "sp": 147,
        "hpx": 14,
        "spx": 9,
        "int": 8,
        "atk": 15,
        "def": 13,
        "agi": 11,
        "item1": 26158,
        "item2": 27004,
        "item3": 26066,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17061,
        "idmap": 12521,
        "ten_map": "S.Dong KhVang 1",
        "thanh": "U Châu",
        "ten_npc": "Mãnh Giáp Sĩ",
        "level": 35,
        "hp": 176,
        "sp": 146,
        "hpx": 14,
        "spx": 8,
        "int": 2,
        "atk": 14,
        "def": 20,
        "agi": 12,
        "item1": 26119,
        "item2": 26117,
        "item3": 26112,
        "item4": 33004,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17083,
        "idmap": 12523,
        "thanh": "U Châu",
        "ten_npc": "Lục Võ Sĩ",
        "level": 35,
        "hp": 190,
        "sp": 130,
        "hpx": 17,
        "spx": 5,
        "int": 6,
        "atk": 16,
        "def": 15,
        "agi": 11,
        "item1": 27031,
        "item2": 27023,
        "item3": 27030,
        "item4": 25048,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17109,
        "idmap": 12533,
        "thanh": "U Châu",
        "ten_npc": "Dương Võ Quân",
        "level": 35,
        "hp": 201,
        "sp": 130,
        "hpx": 12,
        "spx": 5,
        "int": 2,
        "atk": 16,
        "def": 13,
        "agi": 22,
        "item1": 26120,
        "item2": 26120,
        "item3": 26101,
        "item4": 34005,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17143,
        "idmap": 12546,
        "thanh": "U Châu",
        "ten_npc": "Quét Đao Binh",
        "level": 35,
        "hp": 215,
        "sp": 130,
        "hpx": 15,
        "spx": 5,
        "int": 3,
        "atk": 20,
        "def": 15,
        "agi": 13,
        "item1": 26120,
        "item2": 26118,
        "item3": 32031,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17182,
        "idmap": 12564,
        "thanh": "U Châu",
        "ten_npc": "Lô Giang Binh",
        "level": 35,
        "hp": 185,
        "sp": 146,
        "hpx": 16,
        "spx": 8,
        "int": 4,
        "atk": 16,
        "def": 12,
        "agi": 14,
        "item1": 26169,
        "item2": 26056,
        "item3": 26077,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17200,
        "idmap": 12581,
        "ten_map": "Mo Nguyet Trieu Ca 1",
        "thanh": "U Châu",
        "ten_npc": "Hải Tặc Dao",
        "level": 35,
        "hp": 180,
        "sp": 140,
        "hpx": 15,
        "spx": 7,
        "int": 6,
        "atk": 18,
        "def": 13,
        "agi": 12,
        "item1": 26101,
        "item2": 27034,
        "item3": 26081,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17223,
        "idmap": 12584,
        "thanh": "U Châu",
        "ten_npc": "Đại Mạc Quân",
        "level": 35,
        "hp": 203,
        "sp": 148,
        "hpx": 16,
        "spx": 9,
        "int": 12,
        "atk": 11,
        "def": 11,
        "agi": 12,
        "item1": 27032,
        "item2": 27038,
        "item3": 26156,
        "item4": 50001,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17300,
        "idmap": 12591,
        "ten_map": "Mo Khoang Trac Quan 1",
        "thanh": "U Châu",
        "ten_npc": "Bạch Sói Hầu",
        "level": 35,
        "hp": 195,
        "sp": 125,
        "hpx": 18,
        "spx": 4,
        "int": 4,
        "atk": 16,
        "def": 15,
        "agi": 13,
        "item1": 26005,
        "item2": 27041,
        "item3": 27040,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17309,
        "idmap": 12593,
        "ten_map": "Mo Khoang Trac Quan 3",
        "thanh": "U Châu",
        "ten_npc": "Việt Lính",
        "level": 35,
        "hp": 219,
        "sp": 146,
        "hpx": 23,
        "spx": 8,
        "int": 3,
        "atk": 16,
        "def": 9,
        "agi": 11,
        "item1": 26085,
        "item2": 26101,
        "item3": 26158,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10065,
        "idmap": 10811,
        "ten_npc": "Võ Sĩ Ma",
        "level": 36,
        "hp": 188,
        "sp": 166,
        "hpx": 16,
        "spx": 12,
        "int": 16,
        "atk": 6,
        "def": 10,
        "agi": 12,
        "item1": 26101,
        "item2": 26081,
        "item3": 26165,
        "item4": 46016,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11002,
        "idmap": 10963,
        "ten_npc": "Khổng Dung",
        "level": 36,
        "hp": 198,
        "sp": 191,
        "hpx": 7,
        "spx": 12,
        "int": 18,
        "atk": 6,
        "def": 9,
        "agi": 22,
        "item1": 27060,
        "item2": 27036,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11054,
        "idmap": 10982,
        "ten_npc": "Trần Giai",
        "level": 36,
        "hp": 213,
        "sp": 210,
        "hpx": 10,
        "spx": 16,
        "int": 21,
        "atk": 6,
        "def": 9,
        "agi": 12,
        "item1": 27003,
        "item2": 27061,
        "item3": 27031,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13058,
        "idmap": 11521,
        "ten_map": "Son Dong Thai Son 19",
        "thanh": "Thanh Châu",
        "ten_npc": "Tần Tùng",
        "level": 36,
        "hp": 235,
        "sp": 179,
        "hpx": 14,
        "spx": 10,
        "int": 22,
        "atk": 9,
        "def": 10,
        "agi": 12,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14267,
        "idmap": 12000,
        "ten_map": "U Chau",
        "thanh": "U Châu",
        "ten_npc": "Đơn Kinh",
        "level": 36,
        "hp": 246,
        "sp": 174,
        "hpx": 14,
        "spx": 9,
        "int": 5,
        "atk": 15,
        "def": 14,
        "agi": 17,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14292,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Biên Nhượng",
        "level": 36,
        "hp": 216,
        "sp": 191,
        "hpx": 7,
        "spx": 12,
        "int": 6,
        "atk": 20,
        "def": 9,
        "agi": 23,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14392,
        "idmap": 12011,
        "ten_map": "Thanh Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Ngô Thạc",
        "level": 36,
        "hp": 253,
        "sp": 177,
        "hpx": 14,
        "spx": 9,
        "int": 15,
        "atk": 8,
        "def": 16,
        "agi": 14,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14442,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Bác Cố",
        "level": 36,
        "hp": 253,
        "sp": 178,
        "hpx": 14,
        "spx": 9,
        "int": 21,
        "atk": 14,
        "def": 8,
        "agi": 10,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14445,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Vương Liệt",
        "level": 36,
        "hp": 253,
        "sp": 179,
        "hpx": 14,
        "spx": 10,
        "int": 22,
        "atk": 13,
        "def": 9,
        "agi": 9,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14465,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Lưu Kỳ",
        "level": 36,
        "hp": 235,
        "sp": 176,
        "hpx": 14,
        "spx": 9,
        "int": 10,
        "atk": 12,
        "def": 17,
        "agi": 14,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15139,
        "idmap": 12095,
        "ten_map": "Tang Cao Khong Tuoc Dai",
        "thanh": "U Châu",
        "ten_npc": "Tử Tiểu Binh",
        "level": 36,
        "hp": 183,
        "sp": 146,
        "hpx": 15,
        "spx": 8,
        "int": 6,
        "atk": 16,
        "def": 11,
        "agi": 16,
        "item1": 27041,
        "item2": 27038,
        "item3": 27041,
        "item4": 46016,
        "item5": 43080,
        "item6": 0
    },
    {
        "idnpc": 16033,
        "idmap": 12421,
        "ten_map": "Lo Long Khau",
        "thanh": "U Châu",
        "ten_npc": "Thương Nhân",
        "level": 36,
        "hp": 208,
        "sp": 121,
        "hpx": 20,
        "spx": 3,
        "int": 4,
        "atk": 21,
        "def": 17,
        "agi": 6,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17037,
        "idmap": 12515,
        "thanh": "U Châu",
        "ten_npc": "Hổ Quân Vệ",
        "level": 36,
        "hp": 169,
        "sp": 141,
        "hpx": 12,
        "spx": 7,
        "int": 6,
        "atk": 18,
        "def": 17,
        "agi": 12,
        "item1": 26042,
        "item2": 26064,
        "item3": 26056,
        "item4": 50001,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17062,
        "idmap": 12521,
        "ten_map": "S.Dong KhVang 1",
        "thanh": "U Châu",
        "ten_npc": "Cường Giáp Sĩ",
        "level": 36,
        "hp": 169,
        "sp": 131,
        "hpx": 12,
        "spx": 5,
        "int": 2,
        "atk": 17,
        "def": 15,
        "agi": 20,
        "item1": 26101,
        "item2": 27003,
        "item3": 32003,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17140,
        "idmap": 12544,
        "thanh": "U Châu",
        "ten_npc": "NgânThuẫnBinh",
        "level": 36,
        "hp": 215,
        "sp": 151,
        "hpx": 14,
        "spx": 9,
        "int": 6,
        "atk": 16,
        "def": 13,
        "agi": 13,
        "item1": 26120,
        "item2": 26118,
        "item3": 26081,
        "item4": 33014,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17159,
        "idmap": 12556,
        "thanh": "U Châu",
        "ten_npc": "Trường Vệ Quân",
        "level": 36,
        "hp": 205,
        "sp": 131,
        "hpx": 12,
        "spx": 5,
        "int": 4,
        "atk": 21,
        "def": 17,
        "agi": 12,
        "item1": 26119,
        "item2": 27018,
        "item3": 26032,
        "item4": 40005,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17228,
        "idmap": 12585,
        "thanh": "U Châu",
        "ten_npc": "D.Châu Trưởng",
        "level": 36,
        "hp": 183,
        "sp": 151,
        "hpx": 15,
        "spx": 9,
        "int": 4,
        "atk": 10,
        "def": 21,
        "agi": 12,
        "item1": 26003,
        "item2": 26004,
        "item3": 26027,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17247,
        "idmap": 12587,
        "thanh": "U Châu",
        "ten_npc": "Hoàng Phàm Tặc",
        "level": 36,
        "hp": 183,
        "sp": 146,
        "hpx": 15,
        "spx": 8,
        "int": 6,
        "atk": 16,
        "def": 13,
        "agi": 13,
        "item1": 26167,
        "item2": 26166,
        "item3": 27003,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17310,
        "idmap": 12701,
        "ten_map": "Doanh Trai Huong Dung",
        "thanh": "U Châu",
        "ten_npc": "Việt Sĩ",
        "level": 36,
        "hp": 218,
        "sp": 149,
        "hpx": 22,
        "spx": 9,
        "int": 3,
        "atk": 17,
        "def": 8,
        "agi": 12,
        "item1": 26166,
        "item2": 26032,
        "item3": 26043,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10011,
        "idmap": 10802,
        "ten_npc": "Bo Tài",
        "level": 37,
        "hp": 241,
        "sp": 159,
        "hpx": 15,
        "spx": 5,
        "int": 9,
        "atk": 20,
        "def": 13,
        "agi": 14,
        "item1": 26008,
        "item2": 27003,
        "item3": 27036,
        "item4": 40020,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 11212,
        "idmap": 11031,
        "thanh": "Thanh Châu",
        "ten_npc": "Lý Phục",
        "level": 37,
        "hp": 215,
        "sp": 182,
        "hpx": 17,
        "spx": 10,
        "int": 22,
        "atk": 7,
        "def": 2,
        "agi": 13,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12037,
        "idmap": 11131,
        "ten_map": "N.Thuong Binh",
        "thanh": "Thanh Châu",
        "ten_npc": "Ma Xiêu",
        "level": 37,
        "hp": 222,
        "sp": 203,
        "hpx": 11,
        "spx": 14,
        "int": 19,
        "atk": 11,
        "def": 8,
        "agi": 13,
        "item1": 27036,
        "item2": 27066,
        "item3": 27061,
        "item4": 0,
        "item5": 49001,
        "item6": 47012
    },
    {
        "idnpc": 13040,
        "idmap": 11509,
        "ten_map": "Son Dong Thai Son 9",
        "thanh": "Thanh Châu",
        "ten_npc": "Phản Hồng Lan",
        "level": 37,
        "hp": 186,
        "sp": 157,
        "hpx": 15,
        "spx": 10,
        "int": 13,
        "atk": 12,
        "def": 11,
        "agi": 13,
        "item1": 43020,
        "item2": 27038,
        "item3": 27041,
        "item4": 46018,
        "item5": 43011,
        "item6": 0
    },
    {
        "idnpc": 13041,
        "idmap": 11509,
        "ten_map": "Son Dong Thai Son 9",
        "thanh": "Thanh Châu",
        "ten_npc": "Tương Binh",
        "level": 37,
        "hp": 196,
        "sp": 148,
        "hpx": 17,
        "spx": 8,
        "int": 8,
        "atk": 17,
        "def": 12,
        "agi": 12,
        "item1": 26006,
        "item2": 26119,
        "item3": 26120,
        "item4": 46018,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14177,
        "idmap": 11802,
        "ten_map": "Rung Dong Quan 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Công Tôn Khang",
        "level": 37,
        "hp": 281,
        "sp": 179,
        "hpx": 15,
        "spx": 9,
        "int": 8,
        "atk": 20,
        "def": 10,
        "agi": 12,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14189,
        "idmap": 11811,
        "ten_map": "Rung Don Khuu",
        "thanh": "Thanh Châu",
        "ten_npc": "Công Tôn Phạm",
        "level": 37,
        "hp": 272,
        "sp": 178,
        "hpx": 13,
        "spx": 9,
        "int": 5,
        "atk": 26,
        "def": 11,
        "agi": 12,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14199,
        "idmap": 11811,
        "ten_map": "Rung Don Khuu",
        "thanh": "Thanh Châu",
        "ten_npc": "Đạo Mã Tặc",
        "level": 37,
        "hp": 177,
        "sp": 152,
        "hpx": 13,
        "spx": 9,
        "int": 7,
        "atk": 11,
        "def": 14,
        "agi": 20,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14322,
        "idmap": 12002,
        "ten_map": "Dai Lo Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Tần Nghi Lộc",
        "level": 37,
        "hp": 241,
        "sp": 203,
        "hpx": 11,
        "spx": 14,
        "int": 22,
        "atk": 10,
        "def": 8,
        "agi": 14,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14568,
        "idmap": 12031,
        "ten_map": "Thon Lau Song",
        "thanh": "U Châu",
        "ten_npc": "Hoàng Khôi",
        "level": 37,
        "hp": 211,
        "sp": 148,
        "hpx": 20,
        "spx": 8,
        "int": 24,
        "atk": 6,
        "def": 12,
        "agi": 8,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15302,
        "idmap": 12265,
        "thanh": "U Châu",
        "ten_npc": "Cam Khởi",
        "level": 37,
        "hp": 177,
        "sp": 128,
        "hpx": 13,
        "spx": 4,
        "int": 27,
        "atk": 4,
        "def": 18,
        "agi": 12,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15442,
        "idmap": 12409,
        "thanh": "U Châu",
        "ten_npc": "Tà mã vương",
        "level": 37,
        "hp": 186,
        "sp": 148,
        "hpx": 15,
        "spx": 8,
        "int": 20,
        "atk": 17,
        "def": 2,
        "agi": 10,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17113,
        "idmap": 12534,
        "thanh": "U Châu",
        "ten_npc": "Diêm Võ Quân",
        "level": 37,
        "hp": 219,
        "sp": 148,
        "hpx": 14,
        "spx": 8,
        "int": 9,
        "atk": 11,
        "def": 15,
        "agi": 15,
        "item1": 26037,
        "item2": 26054,
        "item3": 26055,
        "item4": 25033,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17114,
        "idmap": 12535,
        "thanh": "U Châu",
        "ten_npc": "Định Viễn Quân",
        "level": 37,
        "hp": 228,
        "sp": 152,
        "hpx": 16,
        "spx": 9,
        "int": 7,
        "atk": 15,
        "def": 15,
        "agi": 11,
        "item1": 26038,
        "item2": 26027,
        "item3": 26055,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17145,
        "idmap": 12546,
        "thanh": "U Châu",
        "ten_npc": "Tr.Phụ Quân",
        "level": 37,
        "hp": 223,
        "sp": 152,
        "hpx": 15,
        "spx": 9,
        "int": 6,
        "atk": 17,
        "def": 14,
        "agi": 11,
        "item1": 26119,
        "item2": 26066,
        "item3": 26100,
        "item4": 25032,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17233,
        "idmap": 12585,
        "thanh": "U Châu",
        "ten_npc": "Bạch Phàm Tặc",
        "level": 37,
        "hp": 191,
        "sp": 148,
        "hpx": 16,
        "spx": 8,
        "int": 4,
        "atk": 14,
        "def": 12,
        "agi": 18,
        "item1": 26101,
        "item2": 26112,
        "item3": 26117,
        "item4": 25048,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17235,
        "idmap": 12586,
        "thanh": "U Châu",
        "ten_npc": "Hồng Phàm Tặc",
        "level": 37,
        "hp": 191,
        "sp": 148,
        "hpx": 16,
        "spx": 8,
        "int": 4,
        "atk": 14,
        "def": 18,
        "agi": 12,
        "item1": 26101,
        "item2": 26112,
        "item3": 26117,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17238,
        "idmap": 12586,
        "thanh": "U Châu",
        "ten_npc": "Thanh Ngạc Nha",
        "level": 37,
        "hp": 186,
        "sp": 148,
        "hpx": 15,
        "spx": 8,
        "int": 6,
        "atk": 13,
        "def": 15,
        "agi": 15,
        "item1": 27019,
        "item2": 27025,
        "item3": 26112,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17286,
        "idmap": 12590,
        "thanh": "U Châu",
        "ten_npc": "Quán Nô Sĩ",
        "level": 37,
        "hp": 201,
        "sp": 138,
        "hpx": 18,
        "spx": 6,
        "int": 5,
        "atk": 17,
        "def": 14,
        "agi": 12,
        "item1": 26036,
        "item2": 26035,
        "item3": 26056,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10092,
        "idmap": 10815,
        "ten_map": "Tiem net",
        "ten_npc": "Yêu Thuật Sĩ",
        "level": 38,
        "hp": 165,
        "sp": 189,
        "hpx": 10,
        "spx": 16,
        "int": 23,
        "atk": 3,
        "def": 10,
        "agi": 14,
        "item1": 27037,
        "item2": 26035,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10127,
        "idmap": 10852,
        "ten_npc": "Binh Lang Nha",
        "level": 38,
        "hp": 204,
        "sp": 139,
        "hpx": 18,
        "spx": 6,
        "int": 3,
        "atk": 18,
        "def": 10,
        "agi": 21,
        "item1": 26036,
        "item2": 26101,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10270,
        "idmap": 10961,
        "ten_npc": "Tr.Lương bị",
        "level": 38,
        "hp": 316,
        "sp": 176,
        "hpx": 6,
        "spx": 8,
        "int": 31,
        "atk": 8,
        "def": 14,
        "agi": 17,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12148,
        "idmap": 11501,
        "ten_map": "Son Dong Thai Son 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Lưu Thiền",
        "level": 38,
        "hp": 392,
        "sp": 176,
        "hpx": 29,
        "spx": 8,
        "int": 6,
        "atk": 5,
        "def": 18,
        "agi": 10,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13042,
        "idmap": 11509,
        "ten_map": "Son Dong Thai Son 9",
        "thanh": "Thanh Châu",
        "ten_npc": "Đà Thủ",
        "level": 38,
        "hp": 209,
        "sp": 159,
        "hpx": 19,
        "spx": 10,
        "int": 10,
        "atk": 11,
        "def": 13,
        "agi": 13,
        "item1": 26006,
        "item2": 26119,
        "item3": 26120,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13080,
        "idmap": 11533,
        "ten_map": "Dong Quy Than 3",
        "thanh": "Thanh Châu",
        "ten_npc": "Biên Hùng",
        "level": 38,
        "hp": 246,
        "sp": 201,
        "hpx": 11,
        "spx": 13,
        "int": 5,
        "atk": 13,
        "def": 20,
        "agi": 19,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14687,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Phạm Cương",
        "level": 38,
        "hp": 517,
        "sp": 181,
        "hpx": 16,
        "spx": 9,
        "int": 3,
        "atk": 25,
        "def": 10,
        "agi": 18,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15130,
        "idmap": 12093,
        "ten_map": "DaiDien Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Tiểu Hồng",
        "level": 38,
        "hp": 180,
        "sp": 157,
        "hpx": 13,
        "spx": 10,
        "int": 15,
        "atk": 9,
        "def": 12,
        "agi": 17,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15413,
        "idmap": 12407,
        "thanh": "U Châu",
        "ten_npc": "Mạnh Khôi",
        "level": 38,
        "hp": 160,
        "sp": 196,
        "hpx": 9,
        "spx": 12,
        "int": 17,
        "atk": 8,
        "def": 7,
        "agi": 15,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17080,
        "idmap": 12523,
        "thanh": "U Châu",
        "ten_npc": "Tử Võ Sĩ",
        "level": 38,
        "hp": 199,
        "sp": 144,
        "hpx": 17,
        "spx": 7,
        "int": 5,
        "atk": 18,
        "def": 14,
        "agi": 15,
        "item1": 26049,
        "item2": 27018,
        "item3": 26004,
        "item4": 50001,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17081,
        "idmap": 12523,
        "thanh": "U Châu",
        "ten_npc": "Hắt Võ sĩ",
        "level": 38,
        "hp": 209,
        "sp": 134,
        "hpx": 19,
        "spx": 5,
        "int": 5,
        "atk": 19,
        "def": 15,
        "agi": 13,
        "item1": 26050,
        "item2": 26042,
        "item3": 26047,
        "item4": 33015,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17211,
        "idmap": 12582,
        "thanh": "U Châu",
        "ten_npc": "Ng.Mọi Sơn Lâm",
        "level": 38,
        "hp": 190,
        "sp": 124,
        "hpx": 15,
        "spx": 3,
        "int": 3,
        "atk": 28,
        "def": 17,
        "agi": 10,
        "item1": 26065,
        "item2": 26035,
        "item3": 26077,
        "item4": 46016,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17213,
        "idmap": 12582,
        "thanh": "U Châu",
        "ten_npc": "Kỹ Sư",
        "level": 38,
        "hp": 190,
        "sp": 159,
        "hpx": 15,
        "spx": 10,
        "int": 22,
        "atk": 3,
        "def": 12,
        "agi": 14,
        "item1": 26158,
        "item2": 26100,
        "item3": 26035,
        "item4": 46018,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17234,
        "idmap": 12585,
        "thanh": "U Châu",
        "ten_npc": "Lục Hổ Chảo",
        "level": 38,
        "hp": 185,
        "sp": 149,
        "hpx": 14,
        "spx": 8,
        "int": 5,
        "atk": 16,
        "def": 14,
        "agi": 19,
        "item1": 26113,
        "item2": 27004,
        "item3": 27036,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17236,
        "idmap": 12586,
        "thanh": "U Châu",
        "ten_npc": "Hắt Giao Long",
        "level": 38,
        "hp": 204,
        "sp": 139,
        "hpx": 18,
        "spx": 6,
        "int": 3,
        "atk": 19,
        "def": 14,
        "agi": 16,
        "item1": 26113,
        "item2": 27004,
        "item3": 27036,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17237,
        "idmap": 12586,
        "thanh": "U Châu",
        "ten_npc": "Tử Phàm Tặc",
        "level": 38,
        "hp": 194,
        "sp": 149,
        "hpx": 16,
        "spx": 8,
        "int": 5,
        "atk": 19,
        "def": 15,
        "agi": 13,
        "item1": 32003,
        "item2": 27036,
        "item3": 27003,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17239,
        "idmap": 12586,
        "thanh": "U Châu",
        "ten_npc": "Đức Vương Binh",
        "level": 38,
        "hp": 190,
        "sp": 155,
        "hpx": 15,
        "spx": 9,
        "int": 6,
        "atk": 15,
        "def": 17,
        "agi": 14,
        "item1": 26122,
        "item2": 26118,
        "item3": 26120,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17285,
        "idmap": 12590,
        "thanh": "U Châu",
        "ten_npc": "Phu Dư Man",
        "level": 38,
        "hp": 214,
        "sp": 129,
        "hpx": 20,
        "spx": 4,
        "int": 6,
        "atk": 18,
        "def": 14,
        "agi": 14,
        "item1": 27004,
        "item2": 26003,
        "item3": 27025,
        "item4": 50001,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17288,
        "idmap": 12590,
        "thanh": "U Châu",
        "ten_npc": "Tiêu Nô Binh",
        "level": 38,
        "hp": 199,
        "sp": 144,
        "hpx": 17,
        "spx": 7,
        "int": 6,
        "atk": 18,
        "def": 13,
        "agi": 15,
        "item1": 26167,
        "item2": 26166,
        "item3": 26056,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10051,
        "idmap": 10808,
        "ten_npc": "Binh Lao Dài",
        "level": 39,
        "hp": 247,
        "sp": 141,
        "hpx": 26,
        "spx": 6,
        "int": 7,
        "atk": 21,
        "def": 8,
        "agi": 10,
        "item1": 27003,
        "item2": 27037,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13043,
        "idmap": 11509,
        "ten_map": "Son Dong Thai Son 9",
        "thanh": "Thanh Châu",
        "ten_npc": "Tr.Phàm Binh",
        "level": 39,
        "hp": 212,
        "sp": 156,
        "hpx": 19,
        "spx": 9,
        "int": 8,
        "atk": 16,
        "def": 13,
        "agi": 13,
        "item1": 26006,
        "item2": 26119,
        "item3": 26120,
        "item4": 46016,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14086,
        "idmap": 11582,
        "thanh": "Thanh Châu",
        "ten_npc": "Bào Tín",
        "level": 39,
        "hp": 254,
        "sp": 186,
        "hpx": 16,
        "spx": 10,
        "int": 11,
        "atk": 18,
        "def": 14,
        "agi": 12,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14244,
        "idmap": 11841,
        "ten_map": "Rung Vi Son 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Quận Đô Úy",
        "level": 39,
        "hp": 187,
        "sp": 146,
        "hpx": 14,
        "spx": 7,
        "int": 9,
        "atk": 20,
        "def": 7,
        "agi": 21,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14373,
        "idmap": 12004,
        "ten_map": "Nha Truong Phi",
        "thanh": "U Châu",
        "ten_npc": "Vương Lang",
        "level": 39,
        "hp": 225,
        "sp": 208,
        "hpx": 6,
        "spx": 14,
        "int": 32,
        "atk": 13,
        "def": 7,
        "agi": 11,
        "item1": 26052,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17240,
        "idmap": 12586,
        "thanh": "U Châu",
        "ten_npc": "Dũng Tử Sĩ",
        "level": 39,
        "hp": 207,
        "sp": 146,
        "hpx": 18,
        "spx": 7,
        "int": 3,
        "atk": 19,
        "def": 14,
        "agi": 16,
        "item1": 26102,
        "item2": 27018,
        "item3": 26023,
        "item4": 50001,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17242,
        "idmap": 12586,
        "thanh": "U Châu",
        "ten_npc": "Tuyệt Trạch Ma",
        "level": 39,
        "hp": 192,
        "sp": 158,
        "hpx": 15,
        "spx": 9,
        "int": 6,
        "atk": 14,
        "def": 16,
        "agi": 17,
        "item1": 26124,
        "item2": 26118,
        "item3": 26120,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17248,
        "idmap": 12587,
        "thanh": "U Châu",
        "ten_npc": "Xích Xà Lân",
        "level": 39,
        "hp": 192,
        "sp": 157,
        "hpx": 15,
        "spx": 9,
        "int": 4,
        "atk": 17,
        "def": 13,
        "agi": 19,
        "item1": 27019,
        "item2": 27031,
        "item3": 26120,
        "item4": 41023,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17287,
        "idmap": 12590,
        "thanh": "U Châu",
        "ten_npc": "Ấp Lâu Man",
        "level": 39,
        "hp": 207,
        "sp": 146,
        "hpx": 18,
        "spx": 7,
        "int": 6,
        "atk": 12,
        "def": 18,
        "agi": 16,
        "item1": 26169,
        "item2": 26079,
        "item3": 27004,
        "item4": 43023,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17304,
        "idmap": 12593,
        "ten_map": "Mo Khoang Trac Quan 3",
        "thanh": "U Châu",
        "ten_npc": "Đội Mao Nữ",
        "level": 39,
        "hp": 212,
        "sp": 141,
        "hpx": 19,
        "spx": 6,
        "int": 6,
        "atk": 13,
        "def": 15,
        "agi": 18,
        "item1": 26036,
        "item2": 26035,
        "item3": 26003,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17305,
        "idmap": 12593,
        "ten_map": "Mo Khoang Trac Quan 3",
        "thanh": "U Châu",
        "ten_npc": "BinhLangVương",
        "level": 39,
        "hp": 212,
        "sp": 141,
        "hpx": 19,
        "spx": 6,
        "int": 4,
        "atk": 19,
        "def": 15,
        "agi": 14,
        "item1": 27005,
        "item2": 26042,
        "item3": 26027,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10012,
        "idmap": 10802,
        "ten_npc": "Tôn Trọng",
        "level": 40,
        "hp": 260,
        "sp": 189,
        "hpx": 16,
        "spx": 10,
        "int": 10,
        "atk": 19,
        "def": 12,
        "agi": 15,
        "item1": 26008,
        "item2": 27055,
        "item3": 27036,
        "item4": 40021,
        "item5": 49001,
        "item6": 47015
    },
    {
        "idnpc": 10132,
        "idmap": 10854,
        "ten_npc": "Sĩ Bóng Tử",
        "level": 40,
        "hp": 221,
        "sp": 137,
        "hpx": 20,
        "spx": 5,
        "int": 4,
        "atk": 21,
        "def": 18,
        "agi": 12,
        "item1": 27066,
        "item2": 27066,
        "item3": 27066,
        "item4": 46016,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10157,
        "idmap": 10867,
        "ten_npc": "Thiên.T.Tướng",
        "level": 40,
        "hp": 231,
        "sp": 132,
        "hpx": 22,
        "spx": 4,
        "int": 5,
        "atk": 14,
        "def": 22,
        "agi": 13,
        "item1": 27041,
        "item2": 26035,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13044,
        "idmap": 11509,
        "ten_map": "Son Dong Thai Son 9",
        "thanh": "Thanh Châu",
        "ten_npc": "Gi.Đông Binh",
        "level": 40,
        "hp": 211,
        "sp": 167,
        "hpx": 18,
        "spx": 11,
        "int": 12,
        "atk": 14,
        "def": 10,
        "agi": 15,
        "item1": 26160,
        "item2": 32003,
        "item3": 27031,
        "item4": 46016,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14151,
        "idmap": 11585,
        "thanh": "Thanh Châu",
        "ten_npc": "Lô Trực",
        "level": 40,
        "hp": 280,
        "sp": 194,
        "hpx": 16,
        "spx": 11,
        "int": 29,
        "atk": 5,
        "def": 8,
        "agi": 13,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 46035,
        "item6": 0
    },
    {
        "idnpc": 14226,
        "idmap": 11831,
        "ten_map": "Lang Ta Phi Lam 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Vương Chính",
        "level": 40,
        "hp": 245,
        "sp": 180,
        "hpx": 13,
        "spx": 8,
        "int": 8,
        "atk": 26,
        "def": 13,
        "agi": 14,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14246,
        "idmap": 11841,
        "ten_map": "Rung Vi Son 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Giang Tặc Thủ",
        "level": 40,
        "hp": 140,
        "sp": 142,
        "hpx": 4,
        "spx": 6,
        "int": 10,
        "atk": 36,
        "def": 11,
        "agi": 13,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14372,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Vương Thành",
        "level": 40,
        "hp": 280,
        "sp": 190,
        "hpx": 16,
        "spx": 10,
        "int": 11,
        "atk": 21,
        "def": 12,
        "agi": 15,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17241,
        "idmap": 12586,
        "thanh": "U Châu",
        "ten_npc": "Bà Triệu Quỷ",
        "level": 40,
        "hp": 211,
        "sp": 152,
        "hpx": 18,
        "spx": 8,
        "int": 5,
        "atk": 14,
        "def": 15,
        "agi": 18,
        "item1": 26121,
        "item2": 26117,
        "item3": 26119,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17303,
        "idmap": 12593,
        "ten_map": "Mo Khoang Trac Quan 3",
        "thanh": "U Châu",
        "ten_npc": "Hồ Nam",
        "level": 40,
        "hp": 221,
        "sp": 142,
        "hpx": 20,
        "spx": 6,
        "int": 4,
        "atk": 20,
        "def": 14,
        "agi": 14,
        "item1": 26050,
        "item2": 27003,
        "item3": 27018,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17306,
        "idmap": 12593,
        "ten_map": "Mo Khoang Trac Quan 3",
        "thanh": "U Châu",
        "ten_npc": "Đơn Dư Tử Sĩ",
        "level": 40,
        "hp": 226,
        "sp": 137,
        "hpx": 21,
        "spx": 5,
        "int": 4,
        "atk": 20,
        "def": 15,
        "agi": 13,
        "item1": 26037,
        "item2": 27036,
        "item3": 26100,
        "item4": 36021,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17368,
        "idmap": 12801,
        "ten_map": "Phi Chi Lam",
        "thanh": "U Châu",
        "ten_npc": "Hà Thương Binh",
        "level": 40,
        "hp": 200,
        "sp": 160,
        "hpx": 16,
        "spx": 10,
        "int": 4,
        "atk": 18,
        "def": 16,
        "agi": 14,
        "item1": 26042,
        "item2": 26042,
        "item3": 26056,
        "item4": 35004,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10033,
        "idmap": 10805,
        "ten_npc": "Bù Kỷ",
        "level": 41,
        "hp": 267,
        "sp": 177,
        "hpx": 16,
        "spx": 7,
        "int": 9,
        "atk": 24,
        "def": 18,
        "agi": 10,
        "item1": 27005,
        "item2": 26004,
        "item3": 26078,
        "item4": 0,
        "item5": 49002,
        "item6": 0
    },
    {
        "idnpc": 10093,
        "idmap": 10815,
        "ten_map": "Tiem net",
        "ten_npc": "Yêu Thuật Sư",
        "level": 41,
        "hp": 188,
        "sp": 167,
        "hpx": 13,
        "spx": 11,
        "int": 20,
        "atk": 16,
        "def": 9,
        "agi": 14,
        "item1": 26158,
        "item2": 26084,
        "item3": 26084,
        "item4": 46018,
        "item5": 42006,
        "item6": 0
    },
    {
        "idnpc": 11062,
        "idmap": 10984,
        "ten_npc": "Lý Tiến",
        "level": 41,
        "hp": 296,
        "sp": 198,
        "hpx": 14,
        "spx": 11,
        "int": 9,
        "atk": 23,
        "def": 19,
        "agi": 11,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11213,
        "idmap": 11031,
        "thanh": "Thanh Châu",
        "ten_npc": "Hứa Chi",
        "level": 41,
        "hp": 244,
        "sp": 197,
        "hpx": 20,
        "spx": 11,
        "int": 27,
        "atk": 4,
        "def": 10,
        "agi": 11,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12039,
        "idmap": 11131,
        "ten_map": "N.Thuong Binh",
        "thanh": "Thanh Châu",
        "ten_npc": "Giãn Dung",
        "level": 41,
        "hp": 250,
        "sp": 172,
        "hpx": 13,
        "spx": 6,
        "int": 28,
        "atk": 18,
        "def": 9,
        "agi": 10,
        "item1": 27003,
        "item2": 27061,
        "item3": 27005,
        "item4": 0,
        "item5": 49001,
        "item6": 47014
    },
    {
        "idnpc": 13059,
        "idmap": 11521,
        "ten_map": "Son Dong Thai Son 19",
        "thanh": "Thanh Châu",
        "ten_npc": "Trần Đoan",
        "level": 41,
        "hp": 267,
        "sp": 196,
        "hpx": 16,
        "spx": 11,
        "int": 24,
        "atk": 17,
        "def": 9,
        "agi": 10,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14182,
        "idmap": 11811,
        "ten_map": "Rung Don Khuu",
        "thanh": "Thanh Châu",
        "ten_npc": "Nghiêm Cương",
        "level": 41,
        "hp": 307,
        "sp": 182,
        "hpx": 16,
        "spx": 8,
        "int": 3,
        "atk": 27,
        "def": 19,
        "agi": 11,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14277,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Lưu Dư",
        "level": 41,
        "hp": 267,
        "sp": 196,
        "hpx": 16,
        "spx": 11,
        "int": 21,
        "atk": 9,
        "def": 14,
        "agi": 13,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14295,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Ưng Thiệu",
        "level": 41,
        "hp": 287,
        "sp": 177,
        "hpx": 16,
        "spx": 7,
        "int": 24,
        "atk": 10,
        "def": 15,
        "agi": 15,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14302,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Từ Hấp",
        "level": 41,
        "hp": 280,
        "sp": 196,
        "hpx": 15,
        "spx": 11,
        "int": 23,
        "atk": 10,
        "def": 15,
        "agi": 13,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14346,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Lưu Giai",
        "level": 41,
        "hp": 287,
        "sp": 177,
        "hpx": 16,
        "spx": 7,
        "int": 12,
        "atk": 23,
        "def": 18,
        "agi": 11,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15414,
        "idmap": 12407,
        "thanh": "U Châu",
        "ten_npc": "Lam hổ nhi",
        "level": 41,
        "hp": 168,
        "sp": 198,
        "hpx": 9,
        "spx": 11,
        "int": 10,
        "atk": 28,
        "def": 8,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17041,
        "idmap": 12518,
        "thanh": "U Châu",
        "ten_npc": "Hùng Quân Vệ",
        "level": 41,
        "hp": 205,
        "sp": 165,
        "hpx": 16,
        "spx": 10,
        "int": 12,
        "atk": 14,
        "def": 18,
        "agi": 12,
        "item1": 26003,
        "item2": 26036,
        "item3": 26043,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17148,
        "idmap": 12547,
        "thanh": "U Châu",
        "ten_npc": "Binh Kích Gai",
        "level": 41,
        "hp": 255,
        "sp": 154,
        "hpx": 18,
        "spx": 8,
        "int": 6,
        "atk": 18,
        "def": 16,
        "agi": 16,
        "item1": 26124,
        "item2": 26119,
        "item3": 26056,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17232,
        "idmap": 12585,
        "thanh": "U Châu",
        "ten_npc": "Việt Nữ Nhi",
        "level": 41,
        "hp": 205,
        "sp": 163,
        "hpx": 16,
        "spx": 10,
        "int": 5,
        "atk": 18,
        "def": 10,
        "agi": 23,
        "item1": 26122,
        "item2": 27041,
        "item3": 27031,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17282,
        "idmap": 12589,
        "ten_map": "Mo Nguyet Trieu Ca 9",
        "thanh": "U Châu",
        "ten_npc": "BắcThươngBinh",
        "level": 41,
        "hp": 219,
        "sp": 149,
        "hpx": 19,
        "spx": 7,
        "int": 6,
        "atk": 20,
        "def": 15,
        "agi": 15,
        "item1": 26029,
        "item2": 26055,
        "item3": 26166,
        "item4": 26389,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17302,
        "idmap": 12593,
        "ten_map": "Mo Khoang Trac Quan 3",
        "thanh": "U Châu",
        "ten_npc": "Sư Hao Sĩ",
        "level": 41,
        "hp": 234,
        "sp": 134,
        "hpx": 22,
        "spx": 4,
        "int": 4,
        "atk": 20,
        "def": 16,
        "agi": 16,
        "item1": 26038,
        "item2": 26166,
        "item3": 27036,
        "item4": 38004,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17307,
        "idmap": 12593,
        "ten_map": "Mo Khoang Trac Quan 3",
        "thanh": "U Châu",
        "ten_npc": "Tướng Sói Xám",
        "level": 41,
        "hp": 234,
        "sp": 134,
        "hpx": 22,
        "spx": 4,
        "int": 5,
        "atk": 17,
        "def": 14,
        "agi": 20,
        "item1": 27006,
        "item2": 27041,
        "item3": 26166,
        "item4": 40004,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17390,
        "idmap": 12803,
        "ten_map": "Rung Cay ngoai o 2",
        "thanh": "U Châu",
        "ten_npc": "Lính Bình Châu",
        "level": 41,
        "hp": 205,
        "sp": 162,
        "hpx": 16,
        "spx": 10,
        "int": 3,
        "atk": 20,
        "def": 18,
        "agi": 15,
        "item1": 26048,
        "item2": 26055,
        "item3": 26055,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11097,
        "idmap": 10993,
        "ten_map": "Vung dat bang gia 2",
        "ten_npc": "Cận Doãn",
        "level": 42,
        "hp": 259,
        "sp": 195,
        "hpx": 14,
        "spx": 10,
        "int": 5,
        "atk": 29,
        "def": 18,
        "agi": 13,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11145,
        "idmap": 11003,
        "ten_map": "Cân Giao Dông Quan",
        "thanh": "Thanh Châu",
        "ten_npc": "vệ kí",
        "level": 42,
        "hp": 300,
        "sp": 185,
        "hpx": 22,
        "spx": 8,
        "int": 24,
        "atk": 15,
        "def": 6,
        "agi": 14,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14170,
        "idmap": 11802,
        "ten_map": "Rung Dong Quan 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Mạnh Công Uy",
        "level": 42,
        "hp": 299,
        "sp": 195,
        "hpx": 14,
        "spx": 10,
        "int": 26,
        "atk": 10,
        "def": 13,
        "agi": 12,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47246
    },
    {
        "idnpc": 14391,
        "idmap": 12011,
        "ten_map": "Thanh Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Vương Tử Phục",
        "level": 42,
        "hp": 280,
        "sp": 195,
        "hpx": 14,
        "spx": 10,
        "int": 13,
        "atk": 22,
        "def": 18,
        "agi": 12,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14569,
        "idmap": 12031,
        "ten_map": "Thon Lau Song",
        "thanh": "U Châu",
        "ten_npc": "Miêu Trạch",
        "level": 42,
        "hp": 186,
        "sp": 150,
        "hpx": 12,
        "spx": 7,
        "int": 27,
        "atk": 6,
        "def": 13,
        "agi": 19,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14688,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Trương Đạt",
        "level": 42,
        "hp": 568,
        "sp": 195,
        "hpx": 17,
        "spx": 10,
        "int": 6,
        "atk": 25,
        "def": 5,
        "agi": 26,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17018,
        "idmap": 12513,
        "thanh": "U Châu",
        "ten_npc": "Ngự Lâm Quân",
        "level": 42,
        "hp": 211,
        "sp": 169,
        "hpx": 17,
        "spx": 11,
        "int": 17,
        "atk": 14,
        "def": 11,
        "agi": 15,
        "item1": 26004,
        "item2": 27030,
        "item3": 26083,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17050,
        "idmap": 12519,
        "thanh": "U Châu",
        "ten_npc": "Phong Duệ Binh",
        "level": 42,
        "hp": 222,
        "sp": 160,
        "hpx": 19,
        "spx": 9,
        "int": 18,
        "atk": 16,
        "def": 12,
        "agi": 10,
        "item1": 27030,
        "item2": 26056,
        "item3": 26083,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17053,
        "idmap": 12519,
        "thanh": "U Châu",
        "ten_npc": "Cường Đao Binh",
        "level": 42,
        "hp": 206,
        "sp": 160,
        "hpx": 16,
        "spx": 9,
        "int": 14,
        "atk": 18,
        "def": 16,
        "agi": 11,
        "item1": 26004,
        "item2": 26004,
        "item3": 26158,
        "item4": 0,
        "item5": 42006,
        "item6": 0
    },
    {
        "idnpc": 17149,
        "idmap": 12547,
        "thanh": "U Châu",
        "ten_npc": "Tr.Tham Quân",
        "level": 42,
        "hp": 243,
        "sp": 155,
        "hpx": 15,
        "spx": 8,
        "int": 9,
        "atk": 18,
        "def": 15,
        "agi": 19,
        "item1": 26123,
        "item2": 26113,
        "item3": 26119,
        "item4": 43007,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17231,
        "idmap": 12585,
        "thanh": "U Châu",
        "ten_npc": "Sơn Việt Binh",
        "level": 42,
        "hp": 211,
        "sp": 166,
        "hpx": 17,
        "spx": 10,
        "int": 4,
        "atk": 24,
        "def": 18,
        "agi": 10,
        "item1": 26006,
        "item2": 26023,
        "item3": 26120,
        "item4": 39016,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17250,
        "idmap": 12588,
        "thanh": "U Châu",
        "ten_npc": "Tra Thục Binh",
        "level": 42,
        "hp": 222,
        "sp": 155,
        "hpx": 19,
        "spx": 8,
        "int": 7,
        "atk": 20,
        "def": 15,
        "agi": 14,
        "item1": 26122,
        "item2": 26118,
        "item3": 26156,
        "item4": 25003,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17252,
        "idmap": 12588,
        "thanh": "U Châu",
        "ten_npc": "Phi Nữ Lính",
        "level": 42,
        "hp": 211,
        "sp": 166,
        "hpx": 17,
        "spx": 10,
        "int": 5,
        "atk": 14,
        "def": 18,
        "agi": 19,
        "item1": 26170,
        "item2": 26101,
        "item3": 26158,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17281,
        "idmap": 12589,
        "ten_map": "Mo Nguyet Trieu Ca 9",
        "thanh": "U Châu",
        "ten_npc": "Liêu Tuyết Sĩ",
        "level": 42,
        "hp": 222,
        "sp": 155,
        "hpx": 19,
        "spx": 8,
        "int": 7,
        "atk": 15,
        "def": 19,
        "agi": 15,
        "item1": 27085,
        "item2": 27056,
        "item3": 27018,
        "item4": 26389,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17290,
        "idmap": 12590,
        "thanh": "U Châu",
        "ten_npc": "Tuyệt Nô Lính",
        "level": 42,
        "hp": 237,
        "sp": 140,
        "hpx": 22,
        "spx": 5,
        "int": 6,
        "atk": 15,
        "def": 16,
        "agi": 19,
        "item1": 27006,
        "item2": 27018,
        "item3": 27056,
        "item4": 0,
        "item5": 45012,
        "item6": 0
    },
    {
        "idnpc": 17369,
        "idmap": 12801,
        "ten_map": "Phi Chi Lam",
        "thanh": "U Châu",
        "ten_npc": "Quan Tiểu Hiệu",
        "level": 42,
        "hp": 211,
        "sp": 166,
        "hpx": 17,
        "spx": 10,
        "int": 4,
        "atk": 19,
        "def": 15,
        "agi": 18,
        "item1": 26056,
        "item2": 26056,
        "item3": 26042,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10211,
        "idmap": 10916,
        "ten_npc": "Quân Sĩ KhVàng",
        "level": 43,
        "hp": 216,
        "sp": 171,
        "hpx": 17,
        "spx": 11,
        "int": 13,
        "atk": 16,
        "def": 16,
        "agi": 13,
        "item1": 26084,
        "item2": 26084,
        "item3": 40014,
        "item4": 46016,
        "item5": 40006,
        "item6": 0
    },
    {
        "idnpc": 11086,
        "idmap": 10992,
        "ten_map": "Vung dat bang gia 1",
        "ten_npc": "Tào An Dân",
        "level": 43,
        "hp": 280,
        "sp": 205,
        "hpx": 17,
        "spx": 12,
        "int": 31,
        "atk": 17,
        "def": 5,
        "agi": 9,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14087,
        "idmap": 11582,
        "thanh": "Thanh Châu",
        "ten_npc": "Bào Trung",
        "level": 43,
        "hp": 280,
        "sp": 199,
        "hpx": 17,
        "spx": 10,
        "int": 7,
        "atk": 27,
        "def": 14,
        "agi": 12,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14121,
        "idmap": 11584,
        "thanh": "Thanh Châu",
        "ten_npc": "Trương Thuần",
        "level": 43,
        "hp": 280,
        "sp": 192,
        "hpx": 17,
        "spx": 9,
        "int": 8,
        "atk": 23,
        "def": 12,
        "agi": 19,
        "item1": 26016,
        "item2": 27019,
        "item3": 26035,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14249,
        "idmap": 11841,
        "ten_map": "Rung Vi Son 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Âu Lực Cư",
        "level": 43,
        "hp": 274,
        "sp": 187,
        "hpx": 16,
        "spx": 8,
        "int": 3,
        "atk": 42,
        "def": 11,
        "agi": 8,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 47021
    },
    {
        "idnpc": 14264,
        "idmap": 12000,
        "ten_map": "U Chau",
        "thanh": "U Châu",
        "ten_npc": "Chu Hạo",
        "level": 43,
        "hp": 253,
        "sp": 172,
        "hpx": 12,
        "spx": 5,
        "int": 5,
        "atk": 14,
        "def": 14,
        "agi": 38,
        "item1": 27019,
        "item2": 26035,
        "item3": 26086,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14265,
        "idmap": 12000,
        "ten_map": "U Chau",
        "thanh": "U Châu",
        "ten_npc": "Chu Hạo",
        "level": 43,
        "hp": 253,
        "sp": 172,
        "hpx": 12,
        "spx": 5,
        "int": 5,
        "atk": 14,
        "def": 14,
        "agi": 38,
        "item1": 27019,
        "item2": 26035,
        "item3": 26086,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14270,
        "idmap": 12000,
        "ten_map": "U Chau",
        "thanh": "U Châu",
        "ten_npc": "Ch.Nam ẩn sĩ",
        "level": 43,
        "hp": 189,
        "sp": 142,
        "hpx": 12,
        "spx": 5,
        "int": 5,
        "atk": 14,
        "def": 14,
        "agi": 38,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14275,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Lữ Công",
        "level": 43,
        "hp": 280,
        "sp": 200,
        "hpx": 17,
        "spx": 11,
        "int": 11,
        "atk": 18,
        "def": 17,
        "agi": 14,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14371,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Tiền Đồng",
        "level": 43,
        "hp": 302,
        "sp": 199,
        "hpx": 17,
        "spx": 10,
        "int": 7,
        "atk": 29,
        "def": 15,
        "agi": 12,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14377,
        "idmap": 12005,
        "ten_map": "Vuon Dao",
        "thanh": "U Châu",
        "ten_npc": "Lữ Hiệp",
        "level": 43,
        "hp": 302,
        "sp": 200,
        "hpx": 17,
        "spx": 11,
        "int": 11,
        "atk": 25,
        "def": 9,
        "agi": 18,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15425,
        "idmap": 12408,
        "thanh": "U Châu",
        "ten_npc": "Ngọc trụ",
        "level": 43,
        "hp": 423,
        "sp": 213,
        "hpx": 20,
        "spx": 13,
        "int": 12,
        "atk": 27,
        "def": 21,
        "agi": 13,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15426,
        "idmap": 12408,
        "thanh": "U Châu",
        "ten_npc": "Kim phong",
        "level": 43,
        "hp": 423,
        "sp": 213,
        "hpx": 20,
        "spx": 13,
        "int": 27,
        "atk": 12,
        "def": 13,
        "agi": 21,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17055,
        "idmap": 12519,
        "ten_map": "Mo Khoang Thuong Son 9",
        "thanh": "U Châu",
        "ten_npc": "Thiết VệTrưởng",
        "level": 43,
        "hp": 216,
        "sp": 162,
        "hpx": 17,
        "spx": 9,
        "int": 8,
        "atk": 17,
        "def": 28,
        "agi": 7,
        "item1": 26035,
        "item2": 26004,
        "item3": 26086,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17249,
        "idmap": 12588,
        "thanh": "U Châu",
        "ten_npc": "Hội Khi Binh",
        "level": 43,
        "hp": 209,
        "sp": 170,
        "hpx": 16,
        "spx": 10,
        "int": 9,
        "atk": 15,
        "def": 15,
        "agi": 18,
        "item1": 26121,
        "item2": 26066,
        "item3": 26117,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17251,
        "idmap": 12588,
        "thanh": "U Châu",
        "ten_npc": "Sơn Việt Sĩ",
        "level": 43,
        "hp": 225,
        "sp": 157,
        "hpx": 19,
        "spx": 8,
        "int": 4,
        "atk": 19,
        "def": 18,
        "agi": 15,
        "item1": 26087,
        "item2": 26077,
        "item3": 26083,
        "item4": 39017,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17277,
        "idmap": 12589,
        "ten_map": "Mo Nguyet Trieu Ca 9",
        "thanh": "U Châu",
        "ten_npc": "Báo Vân Nữ",
        "level": 43,
        "hp": 230,
        "sp": 152,
        "hpx": 20,
        "spx": 7,
        "int": 7,
        "atk": 14,
        "def": 15,
        "agi": 20,
        "item1": 26088,
        "item2": 26047,
        "item3": 26086,
        "item4": 43022,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17289,
        "idmap": 12590,
        "thanh": "U Châu",
        "ten_npc": "Ốc Tữ Man",
        "level": 43,
        "hp": 240,
        "sp": 142,
        "hpx": 22,
        "spx": 5,
        "int": 4,
        "atk": 20,
        "def": 17,
        "agi": 15,
        "item1": 26037,
        "item2": 26166,
        "item3": 26055,
        "item4": 46018,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17294,
        "idmap": 12591,
        "ten_map": "Mo Khoang Trac Quan 1",
        "thanh": "U Châu",
        "ten_npc": "Tuyết Tộc nữ",
        "level": 43,
        "hp": 240,
        "sp": 147,
        "hpx": 22,
        "spx": 6,
        "int": 5,
        "atk": 14,
        "def": 20,
        "agi": 17,
        "item1": 27021,
        "item2": 27004,
        "item3": 26004,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17391,
        "idmap": 12804,
        "ten_map": "D.Ham Phi Chi Lam",
        "thanh": "U Châu",
        "ten_npc": "Sĩ Bình Châu",
        "level": 43,
        "hp": 216,
        "sp": 168,
        "hpx": 17,
        "spx": 10,
        "int": 4,
        "atk": 20,
        "def": 16,
        "agi": 16,
        "item1": 26055,
        "item2": 26048,
        "item3": 26048,
        "item4": 43023,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10031,
        "idmap": 10805,
        "ten_npc": "Bành Thoát",
        "level": 44,
        "hp": 309,
        "sp": 184,
        "hpx": 22,
        "spx": 7,
        "int": 8,
        "atk": 16,
        "def": 19,
        "agi": 18,
        "item1": 26035,
        "item2": 26004,
        "item3": 26078,
        "item4": 0,
        "item5": 49002,
        "item6": 0
    },
    {
        "idnpc": 10128,
        "idmap": 10852,
        "ten_npc": "Lang Sư",
        "level": 44,
        "hp": 212,
        "sp": 138,
        "hpx": 16,
        "spx": 4,
        "int": 6,
        "atk": 21,
        "def": 9,
        "agi": 32,
        "item1": 26084,
        "item2": 26084,
        "item3": 40014,
        "item4": 0,
        "item5": 40006,
        "item6": 0
    },
    {
        "idnpc": 10212,
        "idmap": 10916,
        "ten_npc": "BinhSói KhVàng",
        "level": 44,
        "hp": 202,
        "sp": 148,
        "hpx": 14,
        "spx": 6,
        "int": 9,
        "atk": 18,
        "def": 9,
        "agi": 32,
        "item1": 26084,
        "item2": 26084,
        "item3": 40014,
        "item4": 0,
        "item5": 40006,
        "item6": 0
    },
    {
        "idnpc": 10269,
        "idmap": 10961,
        "ten_npc": "Tr.Bảo bị hại",
        "level": 44,
        "hp": 176,
        "sp": 169,
        "hpx": 9,
        "spx": 10,
        "int": 28,
        "atk": 12,
        "def": 14,
        "agi": 16,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11051,
        "idmap": 10982,
        "ten_npc": "Tảo Chỉ",
        "level": 44,
        "hp": 289,
        "sp": 225,
        "hpx": 18,
        "spx": 15,
        "int": 26,
        "atk": 8,
        "def": 9,
        "agi": 14,
        "item1": 27035,
        "item2": 0,
        "item3": 27056,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11117,
        "idmap": 10996,
        "ten_npc": "Tr.Tử Khiêm",
        "level": 44,
        "hp": 253,
        "sp": 241,
        "hpx": 11,
        "spx": 18,
        "int": 25,
        "atk": 6,
        "def": 16,
        "agi": 23,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12038,
        "idmap": 11131,
        "ten_map": "N.Thuong Binh",
        "thanh": "Thanh Châu",
        "ten_npc": "Ma Phương",
        "level": 44,
        "hp": 287,
        "sp": 210,
        "hpx": 18,
        "spx": 12,
        "int": 11,
        "atk": 25,
        "def": 13,
        "agi": 12,
        "item1": 27031,
        "item2": 27036,
        "item3": 27027,
        "item4": 0,
        "item5": 49001,
        "item6": 47013
    },
    {
        "idnpc": 14088,
        "idmap": 11582,
        "thanh": "Thanh Châu",
        "ten_npc": "Hoàng Bửu Lệ",
        "level": 44,
        "hp": 287,
        "sp": 209,
        "hpx": 18,
        "spx": 12,
        "int": 30,
        "atk": 6,
        "def": 10,
        "agi": 15,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14347,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Lưu Uy",
        "level": 44,
        "hp": 331,
        "sp": 184,
        "hpx": 22,
        "spx": 7,
        "int": 11,
        "atk": 15,
        "def": 17,
        "agi": 21,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14545,
        "idmap": 12023,
        "ten_map": "Diem Binh Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Trương Tiêu",
        "level": 44,
        "hp": 294,
        "sp": 207,
        "hpx": 19,
        "spx": 11,
        "int": 22,
        "atk": 15,
        "def": 10,
        "agi": 17,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15142,
        "idmap": 12095,
        "ten_map": "Tang Cao Khong Tuoc Dai",
        "thanh": "U Châu",
        "ten_npc": "Tặc Hang Động",
        "level": 44,
        "hp": 221,
        "sp": 153,
        "hpx": 18,
        "spx": 7,
        "int": 5,
        "atk": 19,
        "def": 23,
        "agi": 16,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17056,
        "idmap": 12521,
        "ten_map": "S.Dong KhVang 1",
        "thanh": "U Châu",
        "ten_npc": "Hương Dũng",
        "level": 44,
        "hp": 221,
        "sp": 171,
        "hpx": 18,
        "spx": 10,
        "int": 4,
        "atk": 28,
        "def": 10,
        "agi": 18,
        "item1": 27056,
        "item2": 27036,
        "item3": 27061,
        "item4": 39006,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17243,
        "idmap": 12587,
        "thanh": "U Châu",
        "ten_npc": "Phù Diệp Binh",
        "level": 44,
        "hp": 243,
        "sp": 153,
        "hpx": 22,
        "spx": 7,
        "int": 5,
        "atk": 20,
        "def": 19,
        "agi": 16,
        "item1": 26127,
        "item2": 26117,
        "item3": 26119,
        "item4": 50002,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17253,
        "idmap": 12588,
        "thanh": "U Châu",
        "ten_npc": "ThượngDao Binh",
        "level": 44,
        "hp": 221,
        "sp": 173,
        "hpx": 18,
        "spx": 11,
        "int": 12,
        "atk": 19,
        "def": 13,
        "agi": 16,
        "item1": 26050,
        "item2": 26017,
        "item3": 26042,
        "item4": 36017,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17276,
        "idmap": 12589,
        "ten_map": "Mo Nguyet Trieu Ca 9",
        "thanh": "U Châu",
        "ten_npc": "Việt Cừ Sóai",
        "level": 44,
        "hp": 238,
        "sp": 158,
        "hpx": 21,
        "spx": 8,
        "int": 7,
        "atk": 19,
        "def": 20,
        "agi": 14,
        "item1": 26159,
        "item2": 26112,
        "item3": 26113,
        "item4": 42005,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17293,
        "idmap": 12591,
        "ten_map": "Mo Khoang Trac Quan 1",
        "thanh": "U Châu",
        "ten_npc": "Tuyết Tộc Nam",
        "level": 44,
        "hp": 238,
        "sp": 158,
        "hpx": 21,
        "spx": 8,
        "int": 5,
        "atk": 21,
        "def": 19,
        "agi": 15,
        "item1": 26037,
        "item2": 26113,
        "item3": 26056,
        "item4": 41022,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10143,
        "idmap": 10860,
        "ten_npc": "Dã Hổ Quân",
        "level": 45,
        "hp": 226,
        "sp": 176,
        "hpx": 18,
        "spx": 11,
        "int": 13,
        "atk": 18,
        "def": 18,
        "agi": 12,
        "item1": 27047,
        "item2": 27036,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10180,
        "idmap": 10906,
        "ten_npc": "Giang Tặc",
        "level": 45,
        "hp": 252,
        "sp": 175,
        "hpx": 23,
        "spx": 11,
        "int": 10,
        "atk": 20,
        "def": 10,
        "agi": 16,
        "item1": 26002,
        "item2": 27055,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10256,
        "idmap": 10961,
        "ten_npc": "Hải Ba Tài",
        "level": 45,
        "hp": 291,
        "sp": 166,
        "hpx": 22,
        "spx": 3,
        "int": 7,
        "atk": 24,
        "def": 28,
        "agi": 10,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11004,
        "idmap": 10963,
        "ten_npc": "Mao Giới",
        "level": 45,
        "hp": 338,
        "sp": 211,
        "hpx": 18,
        "spx": 12,
        "int": 25,
        "atk": 8,
        "def": 13,
        "agi": 16,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11055,
        "idmap": 10982,
        "ten_npc": "Trần Lâm",
        "level": 45,
        "hp": 293,
        "sp": 212,
        "hpx": 18,
        "spx": 12,
        "int": 31,
        "atk": 7,
        "def": 9,
        "agi": 15,
        "item1": 27037,
        "item2": 27061,
        "item3": 27006,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14057,
        "idmap": 11581,
        "thanh": "Thanh Châu",
        "ten_npc": "Đào Khiêm",
        "level": 45,
        "hp": 293,
        "sp": 210,
        "hpx": 18,
        "spx": 12,
        "int": 21,
        "atk": 8,
        "def": 18,
        "agi": 15,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14120,
        "idmap": 11584,
        "thanh": "Thanh Châu",
        "ten_npc": "Trương Cử",
        "level": 45,
        "hp": 293,
        "sp": 191,
        "hpx": 18,
        "spx": 8,
        "int": 4,
        "atk": 26,
        "def": 13,
        "agi": 23,
        "item1": 26016,
        "item2": 27019,
        "item3": 26035,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14229,
        "idmap": 11831,
        "ten_map": "Lang Ta Phi Lam 1",
        "thanh": "Thanh Châu",
        "ten_npc": "TặcHoàngHàB",
        "level": 45,
        "hp": 226,
        "sp": 155,
        "hpx": 18,
        "spx": 7,
        "int": 6,
        "atk": 25,
        "def": 19,
        "agi": 15,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14387,
        "idmap": 12009,
        "ten_map": "N.Quach Dich",
        "thanh": "U Châu",
        "ten_npc": "Dương Nghi",
        "level": 45,
        "hp": 316,
        "sp": 207,
        "hpx": 18,
        "spx": 11,
        "int": 9,
        "atk": 31,
        "def": 11,
        "agi": 15,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14420,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Trương Tản",
        "level": 45,
        "hp": 342,
        "sp": 181,
        "hpx": 23,
        "spx": 6,
        "int": 11,
        "atk": 12,
        "def": 16,
        "agi": 27,
        "item1": 26002,
        "item2": 26002,
        "item3": 26002,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17244,
        "idmap": 12587,
        "thanh": "U Châu",
        "ten_npc": "H.Cường Nhân",
        "level": 45,
        "hp": 236,
        "sp": 165,
        "hpx": 20,
        "spx": 9,
        "int": 5,
        "atk": 21,
        "def": 19,
        "agi": 15,
        "item1": 26038,
        "item2": 26158,
        "item3": 26167,
        "item4": 35005,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17270,
        "idmap": 12589,
        "ten_map": "Mo Nguyet Trieu Ca 9",
        "thanh": "U Châu",
        "ten_npc": "Việt Sơn Lan",
        "level": 45,
        "hp": 236,
        "sp": 165,
        "hpx": 20,
        "spx": 9,
        "int": 5,
        "atk": 18,
        "def": 15,
        "agi": 22,
        "item1": 32004,
        "item2": 32020,
        "item3": 32003,
        "item4": 40033,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17292,
        "idmap": 12590,
        "thanh": "U Châu",
        "ten_npc": "Người Săn Cọp",
        "level": 45,
        "hp": 246,
        "sp": 155,
        "hpx": 22,
        "spx": 7,
        "int": 8,
        "atk": 14,
        "def": 19,
        "agi": 19,
        "item1": 26038,
        "item2": 26166,
        "item3": 26036,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10181,
        "idmap": 10906,
        "ten_npc": "Lính Kích Ngắn",
        "level": 46,
        "hp": 255,
        "sp": 178,
        "hpx": 23,
        "spx": 11,
        "int": 9,
        "atk": 20,
        "def": 15,
        "agi": 14,
        "item1": 27018,
        "item2": 26101,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10191,
        "idmap": 10909,
        "ten_npc": "Tr.Binh HắtSơn",
        "level": 46,
        "hp": 234,
        "sp": 181,
        "hpx": 19,
        "spx": 12,
        "int": 20,
        "atk": 16,
        "def": 12,
        "agi": 13,
        "item1": 27024,
        "item2": 26056,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12014,
        "idmap": 11041,
        "thanh": "Thanh Châu",
        "ten_npc": "Tôn Cang",
        "level": 46,
        "hp": 287,
        "sp": 209,
        "hpx": 16,
        "spx": 11,
        "int": 32,
        "atk": 13,
        "def": 12,
        "agi": 10,
        "item1": 27025,
        "item2": 27036,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 47011
    },
    {
        "idnpc": 13112,
        "idmap": 11556,
        "thanh": "Thanh Châu",
        "ten_npc": "Cốc Lợi",
        "level": 46,
        "hp": 334,
        "sp": 209,
        "hpx": 25,
        "spx": 11,
        "int": 29,
        "atk": 15,
        "def": 8,
        "agi": 7,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14118,
        "idmap": 11584,
        "thanh": "Thanh Châu",
        "ten_npc": "Công Tôn Việt",
        "level": 46,
        "hp": 300,
        "sp": 210,
        "hpx": 18,
        "spx": 11,
        "int": 7,
        "atk": 15,
        "def": 13,
        "agi": 29,
        "item1": 26016,
        "item2": 27019,
        "item3": 26035,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14419,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Công Tôn Mô",
        "level": 46,
        "hp": 342,
        "sp": 199,
        "hpx": 22,
        "spx": 9,
        "int": 8,
        "atk": 11,
        "def": 16,
        "agi": 31,
        "item1": 26033,
        "item2": 26033,
        "item3": 26033,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15168,
        "idmap": 12101,
        "ten_map": "N.Ly Lang Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "NhàBuônQuáiLạ",
        "level": 46,
        "hp": 231,
        "sp": 180,
        "hpx": 18,
        "spx": 11,
        "int": 15,
        "atk": 21,
        "def": 16,
        "agi": 10,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15338,
        "idmap": 12302,
        "ten_map": "Quan Phu Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Triệu Nhan",
        "level": 46,
        "hp": 280,
        "sp": 194,
        "hpx": 19,
        "spx": 8,
        "int": 28,
        "atk": 4,
        "def": 16,
        "agi": 20,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17030,
        "idmap": 12514,
        "thanh": "U Châu",
        "ten_npc": "Cẩm tử quân",
        "level": 46,
        "hp": 231,
        "sp": 177,
        "hpx": 18,
        "spx": 11,
        "int": 4,
        "atk": 21,
        "def": 15,
        "agi": 22,
        "item1": 26047,
        "item2": 26035,
        "item3": 26079,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17115,
        "idmap": 12535,
        "thanh": "U Châu",
        "ten_npc": "Tịnh Võ Quân",
        "level": 46,
        "hp": 285,
        "sp": 167,
        "hpx": 20,
        "spx": 9,
        "int": 7,
        "atk": 16,
        "def": 19,
        "agi": 18,
        "item1": 26125,
        "item2": 26167,
        "item3": 26120,
        "item4": 38005,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17284,
        "idmap": 12589,
        "ten_map": "Mo Nguyet Trieu Ca 9",
        "thanh": "U Châu",
        "ten_npc": "Tinh Kích Binh",
        "level": 46,
        "hp": 250,
        "sp": 156,
        "hpx": 22,
        "spx": 7,
        "int": 7,
        "atk": 18,
        "def": 20,
        "agi": 15,
        "item1": 26028,
        "item2": 26055,
        "item3": 26056,
        "item4": 50002,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17291,
        "idmap": 12590,
        "thanh": "U Châu",
        "ten_npc": "Người Săn Gấu",
        "level": 46,
        "hp": 244,
        "sp": 161,
        "hpx": 21,
        "spx": 8,
        "int": 9,
        "atk": 17,
        "def": 19,
        "agi": 15,
        "item1": 27021,
        "item2": 26003,
        "item3": 27004,
        "item4": 0,
        "item5": 45015,
        "item6": 0
    },
    {
        "idnpc": 10008,
        "idmap": 10801,
        "ten_npc": "Quách Đại",
        "level": 47,
        "hp": 306,
        "sp": 206,
        "hpx": 19,
        "spx": 10,
        "int": 6,
        "atk": 24,
        "def": 18,
        "agi": 19,
        "item1": 26005,
        "item2": 27061,
        "item3": 0,
        "item4": 49001,
        "item5": 46034,
        "item6": 47016
    },
    {
        "idnpc": 10047,
        "idmap": 10808,
        "ten_npc": "Dân Binh",
        "level": 47,
        "hp": 300,
        "sp": 142,
        "hpx": 31,
        "spx": 4,
        "int": 3,
        "atk": 31,
        "def": 16,
        "agi": 11,
        "item1": 27047,
        "item2": 26050,
        "item3": 26050,
        "item4": 46016,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11056,
        "idmap": 10983,
        "ten_npc": "Trần Đăng",
        "level": 47,
        "hp": 297,
        "sp": 206,
        "hpx": 17,
        "spx": 10,
        "int": 30,
        "atk": 13,
        "def": 11,
        "agi": 15,
        "item1": 27025,
        "item2": 27061,
        "item3": 27026,
        "item4": 0,
        "item5": 0,
        "item6": 47243
    },
    {
        "idnpc": 11119,
        "idmap": 10997,
        "ten_npc": "Vương Xán",
        "level": 47,
        "hp": 306,
        "sp": 219,
        "hpx": 19,
        "spx": 12,
        "int": 29,
        "atk": 16,
        "def": 14,
        "agi": 13,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47366
    },
    {
        "idnpc": 14172,
        "idmap": 11802,
        "ten_map": "Rung Dong Quan 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Kiều Huyền",
        "level": 47,
        "hp": 332,
        "sp": 220,
        "hpx": 16,
        "spx": 13,
        "int": 33,
        "atk": 16,
        "def": 11,
        "agi": 8,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14253,
        "idmap": 11841,
        "ten_map": "Rung Vi Son 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Âm Khuê",
        "level": 47,
        "hp": 281,
        "sp": 196,
        "hpx": 14,
        "spx": 8,
        "int": 34,
        "atk": 16,
        "def": 5,
        "agi": 24,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14376,
        "idmap": 12005,
        "ten_map": "Vuon Dao",
        "thanh": "U Châu",
        "ten_npc": "Châu Ngẩu",
        "level": 47,
        "hp": 341,
        "sp": 175,
        "hpx": 21,
        "spx": 4,
        "int": 13,
        "atk": 34,
        "def": 16,
        "agi": 11,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14386,
        "idmap": 12009,
        "ten_map": "N.Quach Dich",
        "thanh": "U Châu",
        "ten_npc": "Liễu Nghị",
        "level": 47,
        "hp": 321,
        "sp": 206,
        "hpx": 17,
        "spx": 10,
        "int": 14,
        "atk": 29,
        "def": 14,
        "agi": 15,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17082,
        "idmap": 12523,
        "thanh": "U Châu",
        "ten_npc": "Lam Võ Sĩ",
        "level": 47,
        "hp": 247,
        "sp": 168,
        "hpx": 21,
        "spx": 9,
        "int": 7,
        "atk": 21,
        "def": 19,
        "agi": 17,
        "item1": 26126,
        "item2": 26101,
        "item3": 26119,
        "item4": 41006,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17283,
        "idmap": 12589,
        "ten_map": "Mo Nguyet Trieu Ca 9",
        "thanh": "U Châu",
        "ten_npc": "Liêu Giáp Quân",
        "level": 47,
        "hp": 258,
        "sp": 158,
        "hpx": 23,
        "spx": 7,
        "int": 7,
        "atk": 21,
        "def": 19,
        "agi": 17,
        "item1": 26102,
        "item2": 27031,
        "item3": 27004,
        "item4": 39010,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10009,
        "idmap": 10801,
        "ten_npc": "Cao Thăng",
        "level": 48,
        "hp": 313,
        "sp": 217,
        "hpx": 19,
        "spx": 12,
        "int": 9,
        "atk": 26,
        "def": 16,
        "agi": 16,
        "item1": 26005,
        "item2": 0,
        "item3": 0,
        "item4": 49001,
        "item5": 46034,
        "item6": 0
    },
    {
        "idnpc": 10126,
        "idmap": 10851,
        "ten_npc": "Lính Lang",
        "level": 48,
        "hp": 313,
        "sp": 182,
        "hpx": 19,
        "spx": 11,
        "int": 3,
        "atk": 28,
        "def": 14,
        "agi": 19,
        "item1": 26034,
        "item2": 27046,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10188,
        "idmap": 10908,
        "ten_npc": "Dân Đói",
        "level": 48,
        "hp": 177,
        "sp": 165,
        "hpx": 7,
        "spx": 8,
        "int": 10,
        "atk": 32,
        "def": 11,
        "agi": 30,
        "item1": 27025,
        "item2": 27024,
        "item3": 46016,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13052,
        "idmap": 11520,
        "ten_map": "Son Dong Thai Son 18",
        "thanh": "Thanh Châu",
        "ten_npc": "Từ Bi",
        "level": 48,
        "hp": 313,
        "sp": 217,
        "hpx": 19,
        "spx": 12,
        "int": 6,
        "atk": 26,
        "def": 16,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14076,
        "idmap": 11582,
        "thanh": "Thanh Châu",
        "ten_npc": "Hàn Phúc",
        "level": 48,
        "hp": 313,
        "sp": 218,
        "hpx": 19,
        "spx": 12,
        "int": 12,
        "atk": 14,
        "def": 25,
        "agi": 16,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14276,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Bàng Lương",
        "level": 48,
        "hp": 313,
        "sp": 221,
        "hpx": 19,
        "spx": 12,
        "int": 24,
        "atk": 10,
        "def": 15,
        "agi": 17,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14282,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Đổng Thừa",
        "level": 48,
        "hp": 461,
        "sp": 290,
        "hpx": 19,
        "spx": 13,
        "int": 31,
        "atk": 10,
        "def": 12,
        "agi": 17,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14290,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Lạc Tuấn",
        "level": 48,
        "hp": 461,
        "sp": 290,
        "hpx": 19,
        "spx": 12,
        "int": 7,
        "atk": 31,
        "def": 12,
        "agi": 20,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14378,
        "idmap": 12005,
        "ten_map": "Vuon Dao",
        "thanh": "U Châu",
        "ten_npc": "Tần Sói",
        "level": 48,
        "hp": 337,
        "sp": 219,
        "hpx": 19,
        "spx": 12,
        "int": 14,
        "atk": 27,
        "def": 13,
        "agi": 16,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14383,
        "idmap": 12009,
        "ten_map": "N.Quach Dich",
        "thanh": "U Châu",
        "ten_npc": "Điền Thọ",
        "level": 48,
        "hp": 337,
        "sp": 223,
        "hpx": 19,
        "spx": 13,
        "int": 30,
        "atk": 10,
        "def": 13,
        "agi": 16,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14447,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Thẩm Vinh",
        "level": 48,
        "hp": 301,
        "sp": 230,
        "hpx": 17,
        "spx": 14,
        "int": 25,
        "atk": 16,
        "def": 10,
        "agi": 18,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14537,
        "idmap": 12023,
        "ten_map": "Diem Binh Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Lưu Chương",
        "level": 48,
        "hp": 333,
        "sp": 198,
        "hpx": 23,
        "spx": 8,
        "int": 21,
        "atk": 11,
        "def": 23,
        "agi": 14,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14552,
        "idmap": 12023,
        "ten_map": "Diem Binh Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Am Bạc",
        "level": 48,
        "hp": 354,
        "sp": 177,
        "hpx": 27,
        "spx": 4,
        "int": 24,
        "atk": 16,
        "def": 11,
        "agi": 18,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17019,
        "idmap": 12514,
        "thanh": "U Châu",
        "ten_npc": "ThânVệĐTrưởng",
        "level": 48,
        "hp": 241,
        "sp": 184,
        "hpx": 19,
        "spx": 12,
        "int": 10,
        "atk": 20,
        "def": 19,
        "agi": 16,
        "item1": 27041,
        "item2": 27030,
        "item3": 26056,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10005,
        "idmap": 10801,
        "ten_npc": "Đặng Mậu",
        "level": 49,
        "hp": 354,
        "sp": 174,
        "hpx": 26,
        "spx": 3,
        "int": 4,
        "atk": 28,
        "def": 22,
        "agi": 17,
        "item1": 26156,
        "item2": 26158,
        "item3": 27038,
        "item4": 0,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 10062,
        "idmap": 10810,
        "ten_npc": "Binh Phi Kích",
        "level": 49,
        "hp": 280,
        "sp": 187,
        "hpx": 26,
        "spx": 12,
        "int": 10,
        "atk": 14,
        "def": 26,
        "agi": 10,
        "item1": 27056,
        "item2": 27061,
        "item3": 26054,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10073,
        "idmap": 10812,
        "ten_npc": "Th.Công Võ Sĩ",
        "level": 49,
        "hp": 195,
        "sp": 166,
        "hpx": 10,
        "spx": 8,
        "int": 16,
        "atk": 20,
        "def": 17,
        "agi": 16,
        "item1": 27056,
        "item2": 27061,
        "item3": 26054,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10087,
        "idmap": 10814,
        "ten_npc": "Tế Rượu Hầu",
        "level": 49,
        "hp": 174,
        "sp": 182,
        "hpx": 6,
        "spx": 11,
        "int": 32,
        "atk": 10,
        "def": 20,
        "agi": 19,
        "item1": 27030,
        "item2": 27024,
        "item3": 27035,
        "item4": 50001,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10155,
        "idmap": 10866,
        "ten_npc": "Thiên Công Vệ",
        "level": 49,
        "hp": 246,
        "sp": 190,
        "hpx": 20,
        "spx": 12,
        "int": 21,
        "atk": 6,
        "def": 23,
        "agi": 16,
        "item1": 27016,
        "item2": 26054,
        "item3": 27037,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10178,
        "idmap": 10905,
        "ten_npc": "Lính Ma Thương",
        "level": 49,
        "hp": 246,
        "sp": 189,
        "hpx": 20,
        "spx": 12,
        "int": 18,
        "atk": 18,
        "def": 15,
        "agi": 15,
        "item1": 26002,
        "item2": 27055,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10186,
        "idmap": 10908,
        "ten_npc": "Hồng Thân Quân",
        "level": 49,
        "hp": 259,
        "sp": 186,
        "hpx": 22,
        "spx": 12,
        "int": 7,
        "atk": 17,
        "def": 10,
        "agi": 32,
        "item1": 26065,
        "item2": 26081,
        "item3": 27019,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10218,
        "idmap": 10918,
        "ten_npc": "Trương Bác",
        "level": 49,
        "hp": 246,
        "sp": 220,
        "hpx": 20,
        "spx": 12,
        "int": 6,
        "atk": 26,
        "def": 21,
        "agi": 16,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11017,
        "idmap": 10965,
        "ten_npc": "Thân Bỉ",
        "level": 49,
        "hp": 279,
        "sp": 221,
        "hpx": 12,
        "spx": 12,
        "int": 31,
        "atk": 9,
        "def": 19,
        "agi": 17,
        "item1": 27065,
        "item2": 27034,
        "item3": 27003,
        "item4": 0,
        "item5": 0,
        "item6": 47010
    },
    {
        "idnpc": 14070,
        "idmap": 11581,
        "thanh": "Thanh Châu",
        "ten_npc": "Bàng Vịêt",
        "level": 49,
        "hp": 301,
        "sp": 229,
        "hpx": 16,
        "spx": 13,
        "int": 38,
        "atk": 7,
        "def": 12,
        "agi": 14,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14150,
        "idmap": 11585,
        "thanh": "Thanh Châu",
        "ten_npc": "Lý Vĩnh",
        "level": 49,
        "hp": 321,
        "sp": 226,
        "hpx": 16,
        "spx": 13,
        "int": 30,
        "atk": 12,
        "def": 12,
        "agi": 17,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14331,
        "idmap": 12002,
        "ten_map": "Dai Lo Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Lý Nghiệp",
        "level": 49,
        "hp": 378,
        "sp": 179,
        "hpx": 26,
        "spx": 4,
        "int": 28,
        "atk": 10,
        "def": 18,
        "agi": 17,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14426,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Uyển Du",
        "level": 49,
        "hp": 344,
        "sp": 226,
        "hpx": 20,
        "spx": 13,
        "int": 27,
        "atk": 12,
        "def": 18,
        "agi": 14,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14519,
        "idmap": 12018,
        "ten_map": "Quan Tra TQ",
        "thanh": "U Châu",
        "ten_npc": "Đại Võ Quan",
        "level": 49,
        "hp": 332,
        "sp": 206,
        "hpx": 22,
        "spx": 9,
        "int": 31,
        "atk": 6,
        "def": 23,
        "agi": 11,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14647,
        "idmap": 12063,
        "ten_map": "Hoang Cung Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Ẩn giả Vạn An",
        "level": 49,
        "hp": 473,
        "sp": 306,
        "hpx": 30,
        "spx": 28,
        "int": 49,
        "atk": 9,
        "def": 18,
        "agi": 30,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14699,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Mạnh Tiết",
        "level": 49,
        "hp": 473,
        "sp": 306,
        "hpx": 30,
        "spx": 28,
        "int": 49,
        "atk": 9,
        "def": 18,
        "agi": 30,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17089,
        "idmap": 12526,
        "thanh": "U Châu",
        "ten_npc": "Chấn Võ Binh",
        "level": 49,
        "hp": 246,
        "sp": 187,
        "hpx": 20,
        "spx": 12,
        "int": 12,
        "atk": 20,
        "def": 17,
        "agi": 17,
        "item1": 26086,
        "item2": 26089,
        "item3": 26086,
        "item4": 42004,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17201,
        "idmap": 12581,
        "ten_map": "Mo Nguyet Trieu Ca 1",
        "thanh": "U Châu",
        "ten_npc": "Hải Tặc.Th.Thủ",
        "level": 49,
        "hp": 248,
        "sp": 171,
        "hpx": 20,
        "spx": 9,
        "int": 12,
        "atk": 26,
        "def": 13,
        "agi": 17,
        "item1": 27024,
        "item2": 26033,
        "item3": 27004,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17246,
        "idmap": 12587,
        "thanh": "U Châu",
        "ten_npc": "Quân Thiết Tặc",
        "level": 49,
        "hp": 254,
        "sp": 186,
        "hpx": 21,
        "spx": 12,
        "int": 7,
        "atk": 19,
        "def": 18,
        "agi": 19,
        "item1": 27049,
        "item2": 27030,
        "item3": 27031,
        "item4": 43079,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10024,
        "idmap": 10804,
        "ten_npc": "Vạn Tỉnh",
        "level": 50,
        "hp": 305,
        "sp": 228,
        "hpx": 16,
        "spx": 13,
        "int": 23,
        "atk": 19,
        "def": 13,
        "agi": 18,
        "item1": 26035,
        "item2": 27039,
        "item3": 26003,
        "item4": 0,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 10133,
        "idmap": 10855,
        "ten_npc": "Linh Sĩ",
        "level": 50,
        "hp": 241,
        "sp": 193,
        "hpx": 18,
        "spx": 13,
        "int": 21,
        "atk": 21,
        "def": 12,
        "agi": 17,
        "item1": 26055,
        "item2": 26100,
        "item3": 26100,
        "item4": 0,
        "item5": 24028,
        "item6": 0
    },
    {
        "idnpc": 10138,
        "idmap": 10857,
        "ten_npc": "Sư Nộ Giáp",
        "level": 50,
        "hp": 276,
        "sp": 189,
        "hpx": 20,
        "spx": 12,
        "int": 7,
        "atk": 24,
        "def": 28,
        "agi": 15,
        "item1": 27019,
        "item2": 27019,
        "item3": 26167,
        "item4": 34005,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10148,
        "idmap": 10862,
        "ten_npc": "Phong Sư Quân",
        "level": 50,
        "hp": 241,
        "sp": 194,
        "hpx": 18,
        "spx": 13,
        "int": 26,
        "atk": 13,
        "def": 13,
        "agi": 17,
        "item1": 27018,
        "item2": 26166,
        "item3": 26166,
        "item4": 0,
        "item5": 24028,
        "item6": 0
    },
    {
        "idnpc": 10153,
        "idmap": 10865,
        "ten_map": "Thon nguoi moi",
        "ten_npc": "Võ Vệ Sư",
        "level": 50,
        "hp": 291,
        "sp": 190,
        "hpx": 20,
        "spx": 12,
        "int": 11,
        "atk": 20,
        "def": 33,
        "agi": 10,
        "item1": 46018,
        "item2": 27031,
        "item3": 27036,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10168,
        "idmap": 10902,
        "ten_npc": "Đại Võ Tướng",
        "level": 50,
        "hp": 251,
        "sp": 191,
        "hpx": 20,
        "spx": 13,
        "int": 17,
        "atk": 26,
        "def": 12,
        "agi": 13,
        "item1": 27041,
        "item2": 27056,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14278,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Ngũ Phú",
        "level": 50,
        "hp": 351,
        "sp": 225,
        "hpx": 20,
        "spx": 12,
        "int": 12,
        "atk": 26,
        "def": 17,
        "agi": 15,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14297,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Lôi Tự",
        "level": 50,
        "hp": 351,
        "sp": 225,
        "hpx": 20,
        "spx": 12,
        "int": 11,
        "atk": 34,
        "def": 20,
        "agi": 18,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14428,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Ngô Ngũ",
        "level": 50,
        "hp": 383,
        "sp": 192,
        "hpx": 26,
        "spx": 6,
        "int": 8,
        "atk": 31,
        "def": 19,
        "agi": 15,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17184,
        "idmap": 12565,
        "thanh": "U Châu",
        "ten_npc": "HứaXươngBinh",
        "level": 50,
        "hp": 262,
        "sp": 193,
        "hpx": 22,
        "spx": 13,
        "int": 23,
        "atk": 17,
        "def": 10,
        "agi": 16,
        "item1": 26100,
        "item2": 26158,
        "item3": 26083,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17202,
        "idmap": 12581,
        "ten_map": "Mo Nguyet Trieu Ca 1",
        "thanh": "U Châu",
        "ten_npc": "Tướng Hải Tặc",
        "level": 50,
        "hp": 257,
        "sp": 178,
        "hpx": 21,
        "spx": 10,
        "int": 10,
        "atk": 27,
        "def": 14,
        "agi": 17,
        "item1": 26003,
        "item2": 27036,
        "item3": 26019,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17245,
        "idmap": 12587,
        "thanh": "U Châu",
        "ten_npc": "Đội Đức Vương",
        "level": 50,
        "hp": 683,
        "sp": 152,
        "hpx": 26,
        "spx": 5,
        "int": 4,
        "atk": 33,
        "def": 22,
        "agi": 10,
        "item1": 26126,
        "item2": 26120,
        "item3": 26120,
        "item4": 39018,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10049,
        "idmap": 10808,
        "ten_npc": "Binh Dao Ngắn",
        "level": 51,
        "hp": 282,
        "sp": 148,
        "hpx": 20,
        "spx": 4,
        "int": 5,
        "atk": 26,
        "def": 13,
        "agi": 34,
        "item1": 27056,
        "item2": 27064,
        "item3": 26056,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10083,
        "idmap": 10814,
        "ten_npc": "Phần Nữ Tặc",
        "level": 51,
        "hp": 257,
        "sp": 191,
        "hpx": 20,
        "spx": 12,
        "int": 4,
        "atk": 23,
        "def": 22,
        "agi": 19,
        "item1": 26167,
        "item2": 26170,
        "item3": 26167,
        "item4": 0,
        "item5": 24026,
        "item6": 0
    },
    {
        "idnpc": 11082,
        "idmap": 10992,
        "ten_map": "Vung dat bang gia 1",
        "ten_npc": "Hứa Định",
        "level": 51,
        "hp": 333,
        "sp": 227,
        "hpx": 20,
        "spx": 12,
        "int": 4,
        "atk": 36,
        "def": 21,
        "agi": 13,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12161,
        "idmap": 11502,
        "ten_map": "Son Dong Thai Son 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Mạnh Quang",
        "level": 51,
        "hp": 449,
        "sp": 215,
        "hpx": 23,
        "spx": 10,
        "int": 32,
        "atk": 7,
        "def": 7,
        "agi": 27,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14107,
        "idmap": 11583,
        "thanh": "Thanh Châu",
        "ten_npc": "Tần Cát",
        "level": 51,
        "hp": 353,
        "sp": 228,
        "hpx": 20,
        "spx": 12,
        "int": 9,
        "atk": 17,
        "def": 28,
        "agi": 17,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14235,
        "idmap": 11832,
        "ten_map": "Lang Ta Phi Lam 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Tiểu Đạo",
        "level": 51,
        "hp": 217,
        "sp": 158,
        "hpx": 13,
        "spx": 6,
        "int": 17,
        "atk": 11,
        "def": 12,
        "agi": 43,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14236,
        "idmap": 11832,
        "ten_map": "Lang Ta Phi Lam 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Lang Trung",
        "level": 51,
        "hp": 222,
        "sp": 217,
        "hpx": 14,
        "spx": 17,
        "int": 52,
        "atk": 8,
        "def": 6,
        "agi": 5,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14360,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Tiết Lễ",
        "level": 51,
        "hp": 359,
        "sp": 234,
        "hpx": 20,
        "spx": 13,
        "int": 32,
        "atk": 8,
        "def": 21,
        "agi": 12,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14427,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Ngô Miễn",
        "level": 51,
        "hp": 383,
        "sp": 199,
        "hpx": 25,
        "spx": 7,
        "int": 7,
        "atk": 34,
        "def": 21,
        "agi": 13,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14467,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Hàn Cao",
        "level": 51,
        "hp": 333,
        "sp": 234,
        "hpx": 20,
        "spx": 13,
        "int": 31,
        "atk": 15,
        "def": 8,
        "agi": 19,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14588,
        "idmap": 12042,
        "ten_map": "Rung Thuong Son",
        "thanh": "U Châu",
        "ten_npc": "Thần Bốc",
        "level": 51,
        "hp": 347,
        "sp": 221,
        "hpx": 23,
        "spx": 11,
        "int": 41,
        "atk": 5,
        "def": 12,
        "agi": 17,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15217,
        "idmap": 12139,
        "ten_map": "N.Quach Gia TQ",
        "thanh": "U Châu",
        "ten_npc": "Na Ly",
        "level": 51,
        "hp": 333,
        "sp": 192,
        "hpx": 20,
        "spx": 12,
        "int": 8,
        "atk": 21,
        "def": 18,
        "agi": 26,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47006
    },
    {
        "idnpc": 15333,
        "idmap": 12302,
        "ten_map": "Quan Phu Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Đạo Trưởng",
        "level": 51,
        "hp": 249,
        "sp": 169,
        "hpx": 19,
        "spx": 8,
        "int": 28,
        "atk": 9,
        "def": 20,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17088,
        "idmap": 12526,
        "thanh": "U Châu",
        "ten_npc": "Diệu Võ Binh",
        "level": 51,
        "hp": 257,
        "sp": 193,
        "hpx": 20,
        "spx": 13,
        "int": 13,
        "atk": 24,
        "def": 16,
        "agi": 16,
        "item1": 26029,
        "item2": 26159,
        "item3": 26159,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17090,
        "idmap": 12526,
        "thanh": "U Châu",
        "ten_npc": "Uy Võ Binh",
        "level": 51,
        "hp": 257,
        "sp": 194,
        "hpx": 20,
        "spx": 13,
        "int": 16,
        "atk": 20,
        "def": 15,
        "agi": 18,
        "item1": 26007,
        "item2": 27006,
        "item3": 27006,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17135,
        "idmap": 12540,
        "thanh": "U Châu",
        "ten_npc": "Phá Đao Binh",
        "level": 51,
        "hp": 359,
        "sp": 137,
        "hpx": 20,
        "spx": 2,
        "int": 5,
        "atk": 27,
        "def": 26,
        "agi": 20,
        "item1": 26125,
        "item2": 26160,
        "item3": 26123,
        "item4": 25018,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17203,
        "idmap": 12581,
        "ten_map": "Mo Nguyet Trieu Ca 1",
        "thanh": "U Châu",
        "ten_npc": "Đại Ca Hải Đạo",
        "level": 51,
        "hp": 276,
        "sp": 185,
        "hpx": 24,
        "spx": 11,
        "int": 8,
        "atk": 23,
        "def": 17,
        "agi": 19,
        "item1": 27025,
        "item2": 27037,
        "item3": 26065,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17370,
        "idmap": 12801,
        "ten_map": "Phi Chi Lam",
        "thanh": "U Châu",
        "ten_npc": "Quân Hà Đông",
        "level": 51,
        "hp": 257,
        "sp": 192,
        "hpx": 20,
        "spx": 12,
        "int": 8,
        "atk": 21,
        "def": 21,
        "agi": 18,
        "item1": 26036,
        "item2": 26030,
        "item3": 26036,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10010,
        "idmap": 10801,
        "ten_npc": "Bùi.N.Thiệu",
        "level": 52,
        "hp": 340,
        "sp": 212,
        "hpx": 21,
        "spx": 9,
        "int": 13,
        "atk": 28,
        "def": 17,
        "agi": 18,
        "item1": 27061,
        "item2": 26158,
        "item3": 26035,
        "item4": 0,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 10019,
        "idmap": 10803,
        "ten_npc": "Hà Hỷ",
        "level": 52,
        "hp": 340,
        "sp": 231,
        "hpx": 21,
        "spx": 12,
        "int": 7,
        "atk": 31,
        "def": 15,
        "agi": 19,
        "item1": 27007,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 10025,
        "idmap": 10804,
        "ten_npc": "Mã Tương",
        "level": 52,
        "hp": 340,
        "sp": 237,
        "hpx": 21,
        "spx": 14,
        "int": 31,
        "atk": 9,
        "def": 15,
        "agi": 17,
        "item1": 27060,
        "item2": 26033,
        "item3": 27061,
        "item4": 0,
        "item5": 49002,
        "item6": 47100
    },
    {
        "idnpc": 10137,
        "idmap": 10857,
        "ten_npc": "LangThốtTrưởng",
        "level": 52,
        "hp": 352,
        "sp": 181,
        "hpx": 23,
        "spx": 10,
        "int": 3,
        "atk": 32,
        "def": 13,
        "agi": 20,
        "item1": 27004,
        "item2": 27036,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11046,
        "idmap": 10966,
        "ten_npc": "Tào Hùng",
        "level": 52,
        "hp": 307,
        "sp": 223,
        "hpx": 11,
        "spx": 11,
        "int": 37,
        "atk": 4,
        "def": 28,
        "agi": 17,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13061,
        "idmap": 11522,
        "ten_map": "Son Dong Thai Son 20",
        "thanh": "Thanh Châu",
        "ten_npc": "Ngô Quốc Thái",
        "level": 52,
        "hp": 315,
        "sp": 236,
        "hpx": 21,
        "spx": 13,
        "int": 27,
        "atk": 20,
        "def": 14,
        "agi": 14,
        "item1": 26157,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14203,
        "idmap": 11811,
        "ten_map": "Rung Don Khuu",
        "thanh": "Thanh Châu",
        "ten_npc": "Quản Thống",
        "level": 52,
        "hp": 340,
        "sp": 237,
        "hpx": 21,
        "spx": 14,
        "int": 31,
        "atk": 9,
        "def": 20,
        "agi": 13,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14298,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Trương Tiên",
        "level": 52,
        "hp": 366,
        "sp": 231,
        "hpx": 21,
        "spx": 12,
        "int": 5,
        "atk": 26,
        "def": 20,
        "agi": 25,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14342,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Hứa Cang",
        "level": 52,
        "hp": 366,
        "sp": 231,
        "hpx": 21,
        "spx": 12,
        "int": 7,
        "atk": 31,
        "def": 15,
        "agi": 19,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14365,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Phan Năng",
        "level": 52,
        "hp": 366,
        "sp": 233,
        "hpx": 21,
        "spx": 13,
        "int": 16,
        "atk": 24,
        "def": 19,
        "agi": 16,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14368,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Hoàng Long La",
        "level": 52,
        "hp": 340,
        "sp": 233,
        "hpx": 16,
        "spx": 13,
        "int": 13,
        "atk": 33,
        "def": 15,
        "agi": 19,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14453,
        "idmap": 12013,
        "ten_map": "Dia Nguyet KhVang",
        "thanh": "U Châu",
        "ten_npc": "Xuân Khanh",
        "level": 52,
        "hp": 340,
        "sp": 237,
        "hpx": 21,
        "spx": 14,
        "int": 30,
        "atk": 8,
        "def": 21,
        "agi": 15,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14508,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Lưu Độ",
        "level": 52,
        "hp": 362,
        "sp": 212,
        "hpx": 25,
        "spx": 9,
        "int": 25,
        "atk": 7,
        "def": 27,
        "agi": 16,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17059,
        "idmap": 12521,
        "ten_map": "S.Dong KhVang 1",
        "thanh": "U Châu",
        "ten_npc": "Thiết Giáp Bin",
        "level": 52,
        "hp": 321,
        "sp": 187,
        "hpx": 27,
        "spx": 11,
        "int": 14,
        "atk": 17,
        "def": 33,
        "agi": 8,
        "item1": 46016,
        "item2": 27041,
        "item3": 27036,
        "item4": 40005,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17138,
        "idmap": 12543,
        "thanh": "U Châu",
        "ten_npc": "QuyềnGiápSĩ",
        "level": 52,
        "hp": 314,
        "sp": 138,
        "hpx": 21,
        "spx": 2,
        "int": 5,
        "atk": 28,
        "def": 26,
        "agi": 20,
        "item1": 26126,
        "item2": 26089,
        "item3": 26122,
        "item4": 34025,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17371,
        "idmap": 12801,
        "ten_map": "Phi Chi Lam",
        "thanh": "U Châu",
        "ten_npc": "Lính Tuần La",
        "level": 52,
        "hp": 262,
        "sp": 195,
        "hpx": 21,
        "spx": 12,
        "int": 7,
        "atk": 22,
        "def": 21,
        "agi": 18,
        "item1": 26004,
        "item2": 26246,
        "item3": 26004,
        "item4": 50002,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10004,
        "idmap": 10701,
        "ten_map": "Nha Lao",
        "ten_npc": "Trình Viễn Trí",
        "level": 53,
        "hp": 335,
        "sp": 215,
        "hpx": 19,
        "spx": 9,
        "int": 7,
        "atk": 31,
        "def": 21,
        "agi": 21,
        "item1": 27061,
        "item2": 26156,
        "item3": 26042,
        "item4": 0,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 10021,
        "idmap": 10803,
        "ten_npc": "Từ Hòa",
        "level": 53,
        "hp": 346,
        "sp": 238,
        "hpx": 21,
        "spx": 13,
        "int": 18,
        "atk": 22,
        "def": 18,
        "agi": 16,
        "item1": 27061,
        "item2": 27066,
        "item3": 27025,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10084,
        "idmap": 10814,
        "ten_npc": "Thanh Diện Tặc",
        "level": 53,
        "hp": 267,
        "sp": 197,
        "hpx": 21,
        "spx": 13,
        "int": 4,
        "atk": 25,
        "def": 23,
        "agi": 20,
        "item1": 26101,
        "item2": 26102,
        "item3": 26101,
        "item4": 0,
        "item5": 24026,
        "item6": 0
    },
    {
        "idnpc": 10091,
        "idmap": 10815,
        "ten_map": "Tiem net",
        "ten_npc": "Sư Bói",
        "level": 53,
        "hp": 260,
        "sp": 205,
        "hpx": 20,
        "spx": 14,
        "int": 34,
        "atk": 9,
        "def": 15,
        "agi": 14,
        "item1": 27037,
        "item2": 26035,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11043,
        "idmap": 10966,
        "ten_npc": "Tào Báo",
        "level": 53,
        "hp": 382,
        "sp": 236,
        "hpx": 24,
        "spx": 13,
        "int": 11,
        "atk": 31,
        "def": 14,
        "agi": 15,
        "item1": 27061,
        "item2": 27005,
        "item3": 26170,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11090,
        "idmap": 10992,
        "ten_map": "Vung dat bang gia 1",
        "ten_npc": "Vương Trung",
        "level": 53,
        "hp": 346,
        "sp": 234,
        "hpx": 21,
        "spx": 13,
        "int": 5,
        "atk": 34,
        "def": 23,
        "agi": 15,
        "item1": 26051,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11112,
        "idmap": 10995,
        "ten_npc": "Phùng Lễ",
        "level": 53,
        "hp": 373,
        "sp": 235,
        "hpx": 21,
        "spx": 13,
        "int": 7,
        "atk": 32,
        "def": 20,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12151,
        "idmap": 11501,
        "ten_map": "Son Dong Thai Son 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Thượng Cử",
        "level": 53,
        "hp": 462,
        "sp": 247,
        "hpx": 23,
        "spx": 15,
        "int": 11,
        "atk": 34,
        "def": 16,
        "agi": 24,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13056,
        "idmap": 11521,
        "ten_map": "Son Dong Thai Son 19",
        "thanh": "Thanh Châu",
        "ten_npc": "Tôn Hà",
        "level": 53,
        "hp": 346,
        "sp": 236,
        "hpx": 21,
        "spx": 13,
        "int": 11,
        "atk": 22,
        "def": 26,
        "agi": 18,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13130,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Lục Thương",
        "level": 53,
        "hp": 382,
        "sp": 268,
        "hpx": 18,
        "spx": 9,
        "int": 31,
        "atk": 8,
        "def": 18,
        "agi": 17,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14108,
        "idmap": 11583,
        "thanh": "Thanh Châu",
        "ten_npc": "Từ Miêu",
        "level": 53,
        "hp": 344,
        "sp": 204,
        "hpx": 17,
        "spx": 7,
        "int": 31,
        "atk": 11,
        "def": 18,
        "agi": 24,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14232,
        "idmap": 11831,
        "ten_map": "Lang Ta Phi Lam 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Du Côn",
        "level": 53,
        "hp": 196,
        "sp": 140,
        "hpx": 8,
        "spx": 2,
        "int": 4,
        "atk": 26,
        "def": 26,
        "agi": 40,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14299,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Lưu Dực",
        "level": 53,
        "hp": 373,
        "sp": 235,
        "hpx": 21,
        "spx": 13,
        "int": 10,
        "atk": 33,
        "def": 18,
        "agi": 16,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14357,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Vạn Diễn",
        "level": 53,
        "hp": 361,
        "sp": 215,
        "hpx": 19,
        "spx": 9,
        "int": 11,
        "atk": 30,
        "def": 21,
        "agi": 21,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14430,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Châu Lâm",
        "level": 53,
        "hp": 426,
        "sp": 182,
        "hpx": 31,
        "spx": 3,
        "int": 4,
        "atk": 35,
        "def": 29,
        "agi": 9,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14476,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Phó Tốn",
        "level": 53,
        "hp": 346,
        "sp": 240,
        "hpx": 21,
        "spx": 14,
        "int": 27,
        "atk": 14,
        "def": 21,
        "agi": 15,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15431,
        "idmap": 12409,
        "thanh": "U Châu",
        "ten_npc": "Vu Cân",
        "level": 53,
        "hp": 505,
        "sp": 242,
        "hpx": 21,
        "spx": 14,
        "int": 35,
        "atk": 8,
        "def": 16,
        "agi": 18,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17091,
        "idmap": 12526,
        "thanh": "U Châu",
        "ten_npc": "Hùng Võ Binh",
        "level": 53,
        "hp": 267,
        "sp": 210,
        "hpx": 21,
        "spx": 15,
        "int": 24,
        "atk": 16,
        "def": 15,
        "agi": 15,
        "item1": 26047,
        "item2": 26036,
        "item3": 26066,
        "item4": 50001,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17572,
        "idmap": 12826,
        "thanh": "U Châu",
        "ten_npc": "Giao Châu Binh",
        "level": 53,
        "hp": 304,
        "sp": 161,
        "hpx": 28,
        "spx": 6,
        "int": 12,
        "atk": 26,
        "def": 24,
        "agi": 10,
        "item1": 26006,
        "item2": 26006,
        "item3": 26038,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10030,
        "idmap": 10805,
        "ten_npc": "Cung Đô",
        "level": 54,
        "hp": 353,
        "sp": 228,
        "hpx": 22,
        "spx": 11,
        "int": 12,
        "atk": 33,
        "def": 15,
        "agi": 17,
        "item1": 27061,
        "item2": 26004,
        "item3": 26003,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10052,
        "idmap": 10808,
        "ten_npc": "Binh Dao Lớn",
        "level": 54,
        "hp": 334,
        "sp": 174,
        "hpx": 28,
        "spx": 8,
        "int": 6,
        "atk": 34,
        "def": 16,
        "agi": 16,
        "item1": 26047,
        "item2": 26055,
        "item3": 26050,
        "item4": 0,
        "item5": 24025,
        "item6": 0
    },
    {
        "idnpc": 10217,
        "idmap": 10918,
        "ten_npc": "Hòanh Thương",
        "level": 54,
        "hp": 272,
        "sp": 238,
        "hpx": 22,
        "spx": 13,
        "int": 8,
        "atk": 27,
        "def": 24,
        "agi": 18,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11098,
        "idmap": 10993,
        "ten_map": "Vung dat bang gia 2",
        "ten_npc": "Nhâm Tuấn",
        "level": 54,
        "hp": 353,
        "sp": 245,
        "hpx": 22,
        "spx": 14,
        "int": 33,
        "atk": 16,
        "def": 9,
        "agi": 19,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13079,
        "idmap": 11533,
        "ten_map": "Dong Quy Than 3",
        "thanh": "Thanh Châu",
        "ten_npc": "Đới Viên",
        "level": 54,
        "hp": 344,
        "sp": 237,
        "hpx": 20,
        "spx": 13,
        "int": 4,
        "atk": 26,
        "def": 18,
        "agi": 31,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14231,
        "idmap": 11831,
        "ten_map": "Lang Ta Phi Lam 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Du Côn Đầu Hẻm",
        "level": 54,
        "hp": 209,
        "sp": 141,
        "hpx": 10,
        "spx": 2,
        "int": 8,
        "atk": 22,
        "def": 28,
        "agi": 38,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14353,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Hòang Kỳ",
        "level": 54,
        "hp": 380,
        "sp": 228,
        "hpx": 22,
        "spx": 11,
        "int": 12,
        "atk": 36,
        "def": 13,
        "agi": 19,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14370,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Châu Đà",
        "level": 54,
        "hp": 380,
        "sp": 228,
        "hpx": 22,
        "spx": 11,
        "int": 8,
        "atk": 36,
        "def": 19,
        "agi": 17,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14496,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Bùi Tiềm",
        "level": 54,
        "hp": 353,
        "sp": 244,
        "hpx": 22,
        "spx": 14,
        "int": 30,
        "atk": 18,
        "def": 14,
        "agi": 15,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17092,
        "idmap": 12526,
        "thanh": "U Châu",
        "ten_npc": "Liệt Võ Binh",
        "level": 54,
        "hp": 296,
        "sp": 201,
        "hpx": 26,
        "spx": 13,
        "int": 10,
        "atk": 25,
        "def": 16,
        "agi": 18,
        "item1": 26004,
        "item2": 27037,
        "item3": 26066,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17093,
        "idmap": 12526,
        "thanh": "U Châu",
        "ten_npc": "Dương Võ Binh",
        "level": 54,
        "hp": 272,
        "sp": 201,
        "hpx": 22,
        "spx": 13,
        "int": 10,
        "atk": 15,
        "def": 20,
        "agi": 28,
        "item1": 27006,
        "item2": 27017,
        "item3": 26067,
        "item4": 46016,
        "item5": 24027,
        "item6": 0
    },
    {
        "idnpc": 10016,
        "idmap": 10802,
        "ten_npc": "Hàn Trung",
        "level": 55,
        "hp": 360,
        "sp": 242,
        "hpx": 22,
        "spx": 13,
        "int": 7,
        "atk": 32,
        "def": 24,
        "agi": 14,
        "item1": 26100,
        "item2": 27005,
        "item3": 27036,
        "item4": 0,
        "item5": 49001,
        "item6": 47017
    },
    {
        "idnpc": 10020,
        "idmap": 10803,
        "ten_npc": "Lưu Bích",
        "level": 55,
        "hp": 349,
        "sp": 242,
        "hpx": 20,
        "spx": 13,
        "int": 7,
        "atk": 30,
        "def": 21,
        "agi": 20,
        "item1": 26003,
        "item2": 27062,
        "item3": 27037,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10075,
        "idmap": 10812,
        "ten_npc": "Diêm Chính",
        "level": 55,
        "hp": 338,
        "sp": 230,
        "hpx": 18,
        "spx": 11,
        "int": 6,
        "atk": 33,
        "def": 20,
        "agi": 21,
        "item1": 26059,
        "item2": 27067,
        "item3": 27061,
        "item4": 0,
        "item5": 49002,
        "item6": 0
    },
    {
        "idnpc": 10094,
        "idmap": 10815,
        "ten_map": "Tiem net",
        "ten_npc": "Hầu Bói",
        "level": 55,
        "hp": 266,
        "sp": 210,
        "hpx": 20,
        "spx": 14,
        "int": 30,
        "atk": 9,
        "def": 20,
        "agi": 19,
        "item1": 26077,
        "item2": 27066,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10223,
        "idmap": 10920,
        "ten_npc": "Trương Hiểu",
        "level": 55,
        "hp": 277,
        "sp": 247,
        "hpx": 22,
        "spx": 14,
        "int": 27,
        "atk": 19,
        "def": 18,
        "agi": 13,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11109,
        "idmap": 10994,
        "ten_map": "Va Dai Dâu Va",
        "ten_npc": "Mãnh Thản",
        "level": 55,
        "hp": 371,
        "sp": 235,
        "hpx": 24,
        "spx": 12,
        "int": 5,
        "atk": 34,
        "def": 31,
        "agi": 12,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12156,
        "idmap": 11501,
        "ten_map": "Son Dong Thai Son 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Lại Trung",
        "level": 55,
        "hp": 492,
        "sp": 224,
        "hpx": 26,
        "spx": 10,
        "int": 38,
        "atk": 18,
        "def": 8,
        "agi": 18,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13055,
        "idmap": 11521,
        "ten_map": "Son Dong Thai Son 19",
        "thanh": "Thanh Châu",
        "ten_npc": "Châu Chương",
        "level": 55,
        "hp": 360,
        "sp": 242,
        "hpx": 22,
        "spx": 13,
        "int": 9,
        "atk": 33,
        "def": 14,
        "agi": 24,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14223,
        "idmap": 11831,
        "ten_map": "Lang Ta Phi Lam 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Châu Thận",
        "level": 55,
        "hp": 380,
        "sp": 243,
        "hpx": 22,
        "spx": 13,
        "int": 11,
        "atk": 13,
        "def": 24,
        "agi": 29,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14300,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Chiêm Chi",
        "level": 55,
        "hp": 387,
        "sp": 242,
        "hpx": 22,
        "spx": 13,
        "int": 7,
        "atk": 35,
        "def": 20,
        "agi": 18,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14358,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Tiêu Kỷ",
        "level": 55,
        "hp": 376,
        "sp": 242,
        "hpx": 20,
        "spx": 13,
        "int": 7,
        "atk": 34,
        "def": 21,
        "agi": 20,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14402,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Hoa Âm",
        "level": 55,
        "hp": 409,
        "sp": 224,
        "hpx": 26,
        "spx": 10,
        "int": 39,
        "atk": 18,
        "def": 8,
        "agi": 14,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14403,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Đổng Chi",
        "level": 55,
        "hp": 376,
        "sp": 252,
        "hpx": 20,
        "spx": 15,
        "int": 15,
        "atk": 39,
        "def": 12,
        "agi": 14,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14434,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Vương Uy",
        "level": 55,
        "hp": 409,
        "sp": 224,
        "hpx": 26,
        "spx": 10,
        "int": 39,
        "atk": 16,
        "def": 10,
        "agi": 14,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15430,
        "idmap": 12409,
        "thanh": "U Châu",
        "ten_npc": "Triết đơn",
        "level": 55,
        "hp": 525,
        "sp": 242,
        "hpx": 22,
        "spx": 13,
        "int": 8,
        "atk": 33,
        "def": 13,
        "agi": 23,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17024,
        "idmap": 12514,
        "thanh": "U Châu",
        "ten_npc": "Ô Hằng T.Binh",
        "level": 55,
        "hp": 277,
        "sp": 203,
        "hpx": 22,
        "spx": 13,
        "int": 5,
        "atk": 25,
        "def": 24,
        "agi": 18,
        "item1": 26008,
        "item2": 26063,
        "item3": 26115,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17057,
        "idmap": 12521,
        "ten_map": "S.Dong KhVang 1",
        "thanh": "U Châu",
        "ten_npc": "Đồng Giáp Binh",
        "level": 55,
        "hp": 332,
        "sp": 204,
        "hpx": 32,
        "spx": 13,
        "int": 11,
        "atk": 16,
        "def": 28,
        "agi": 10,
        "item1": 26042,
        "item2": 26166,
        "item3": 26098,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17094,
        "idmap": 12526,
        "thanh": "U Châu",
        "ten_npc": "Phấn Võ Binh",
        "level": 55,
        "hp": 332,
        "sp": 203,
        "hpx": 32,
        "spx": 13,
        "int": 6,
        "atk": 24,
        "def": 21,
        "agi": 14,
        "item1": 26067,
        "item2": 27006,
        "item3": 27017,
        "item4": 46018,
        "item5": 24027,
        "item6": 0
    },
    {
        "idnpc": 17095,
        "idmap": 12526,
        "thanh": "U Châu",
        "ten_npc": "Trấn Viễn Binh",
        "level": 55,
        "hp": 277,
        "sp": 204,
        "hpx": 22,
        "spx": 13,
        "int": 11,
        "atk": 16,
        "def": 32,
        "agi": 15,
        "item1": 27039,
        "item2": 27038,
        "item3": 26079,
        "item4": 46018,
        "item5": 34007,
        "item6": 0
    },
    {
        "idnpc": 17573,
        "idmap": 12826,
        "thanh": "U Châu",
        "ten_npc": "Giao Châu Binh",
        "level": 55,
        "hp": 315,
        "sp": 164,
        "hpx": 29,
        "spx": 6,
        "int": 14,
        "atk": 26,
        "def": 21,
        "agi": 12,
        "item1": 26038,
        "item2": 26038,
        "item3": 26006,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10053,
        "idmap": 10809,
        "ten_npc": "Thánh Binh",
        "level": 56,
        "hp": 297,
        "sp": 188,
        "hpx": 20,
        "spx": 10,
        "int": 22,
        "atk": 22,
        "def": 20,
        "agi": 20,
        "item1": 26085,
        "item2": 26092,
        "item3": 26092,
        "item4": 0,
        "item5": 24025,
        "item6": 0
    },
    {
        "idnpc": 10080,
        "idmap": 10813,
        "ten_npc": "Vương Độ",
        "level": 56,
        "hp": 342,
        "sp": 254,
        "hpx": 18,
        "spx": 15,
        "int": 38,
        "atk": 7,
        "def": 20,
        "agi": 16,
        "item1": 27027,
        "item2": 26084,
        "item3": 26167,
        "item4": 0,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 10090,
        "idmap": 10815,
        "ten_map": "Tiem net",
        "ten_npc": "Tế Rượu Sư",
        "level": 56,
        "hp": 264,
        "sp": 212,
        "hpx": 19,
        "spx": 15,
        "int": 30,
        "atk": 10,
        "def": 21,
        "agi": 18,
        "item1": 26158,
        "item2": 26042,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10102,
        "idmap": 10817,
        "ten_npc": "Tàn Binh",
        "level": 56,
        "hp": 283,
        "sp": 206,
        "hpx": 22,
        "spx": 13,
        "int": 5,
        "atk": 23,
        "def": 26,
        "agi": 22,
        "item1": 27039,
        "item2": 26229,
        "item3": 27039,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11092,
        "idmap": 10992,
        "ten_map": "Vung dat bang gia 1",
        "ten_npc": "Trương Phấn",
        "level": 56,
        "hp": 367,
        "sp": 246,
        "hpx": 22,
        "spx": 14,
        "int": 9,
        "atk": 16,
        "def": 35,
        "agi": 21,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12104,
        "idmap": 11307,
        "ten_map": "Dong Quan Quan Phu",
        "thanh": "Thanh Châu",
        "ten_npc": "Mã Tế",
        "level": 56,
        "hp": 386,
        "sp": 227,
        "hpx": 26,
        "spx": 10,
        "int": 33,
        "atk": 20,
        "def": 7,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13065,
        "idmap": 11525,
        "ten_map": "Son Dong Thai Son 23",
        "thanh": "Thanh Châu",
        "ten_npc": "Nai Lương",
        "level": 56,
        "hp": 367,
        "sp": 253,
        "hpx": 22,
        "spx": 15,
        "int": 36,
        "atk": 8,
        "def": 30,
        "agi": 12,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14085,
        "idmap": 11582,
        "thanh": "Thanh Châu",
        "ten_npc": "Hạ Chiêu",
        "level": 56,
        "hp": 373,
        "sp": 248,
        "hpx": 20,
        "spx": 14,
        "int": 18,
        "atk": 22,
        "def": 21,
        "agi": 19,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14208,
        "idmap": 11821,
        "thanh": "Thanh Châu",
        "ten_npc": "Lưu Tuấn",
        "level": 56,
        "hp": 395,
        "sp": 245,
        "hpx": 22,
        "spx": 13,
        "int": 6,
        "atk": 32,
        "def": 29,
        "agi": 17,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14343,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Trương Đa",
        "level": 56,
        "hp": 370,
        "sp": 248,
        "hpx": 18,
        "spx": 14,
        "int": 17,
        "atk": 28,
        "def": 16,
        "agi": 20,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14429,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Hoa Đang",
        "level": 56,
        "hp": 381,
        "sp": 260,
        "hpx": 20,
        "spx": 16,
        "int": 29,
        "atk": 13,
        "def": 20,
        "agi": 19,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14509,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Lưu Hiền",
        "level": 56,
        "hp": 359,
        "sp": 232,
        "hpx": 21,
        "spx": 11,
        "int": 30,
        "atk": 8,
        "def": 29,
        "agi": 19,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14542,
        "idmap": 12023,
        "ten_map": "Diem Binh Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Lưu Tuần",
        "level": 56,
        "hp": 403,
        "sp": 216,
        "hpx": 29,
        "spx": 8,
        "int": 30,
        "atk": 21,
        "def": 13,
        "agi": 17,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15087,
        "idmap": 12091,
        "ten_map": "Dai Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Tiên Đồng",
        "level": 56,
        "hp": 258,
        "sp": 215,
        "hpx": 18,
        "spx": 15,
        "int": 40,
        "atk": 8,
        "def": 10,
        "agi": 19,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15111,
        "idmap": 12093,
        "ten_map": "DaiDien Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Đoán Mệnh Tiên",
        "level": 56,
        "hp": 258,
        "sp": 212,
        "hpx": 18,
        "spx": 14,
        "int": 27,
        "atk": 13,
        "def": 19,
        "agi": 21,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17025,
        "idmap": 12514,
        "thanh": "U Châu",
        "ten_npc": "Ô Hằng T.Lính",
        "level": 56,
        "hp": 283,
        "sp": 206,
        "hpx": 22,
        "spx": 13,
        "int": 5,
        "atk": 27,
        "def": 25,
        "agi": 19,
        "item1": 26115,
        "item2": 32006,
        "item3": 26008,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17096,
        "idmap": 12526,
        "thanh": "U Châu",
        "ten_npc": "Thần Võ Binh",
        "level": 56,
        "hp": 297,
        "sp": 206,
        "hpx": 25,
        "spx": 13,
        "int": 8,
        "atk": 20,
        "def": 26,
        "agi": 20,
        "item1": 27039,
        "item2": 27038,
        "item3": 26079,
        "item4": 46016,
        "item5": 38005,
        "item6": 0
    },
    {
        "idnpc": 17097,
        "idmap": 12527,
        "thanh": "U Châu",
        "ten_npc": "Tinh Võ Binh",
        "level": 56,
        "hp": 313,
        "sp": 188,
        "hpx": 28,
        "spx": 10,
        "int": 4,
        "atk": 38,
        "def": 24,
        "agi": 10,
        "item1": 26008,
        "item2": 26156,
        "item3": 26158,
        "item4": 50001,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17098,
        "idmap": 12527,
        "thanh": "U Châu",
        "ten_npc": "Định Viễn Binh",
        "level": 56,
        "hp": 283,
        "sp": 207,
        "hpx": 22,
        "spx": 14,
        "int": 11,
        "atk": 19,
        "def": 21,
        "agi": 25,
        "item1": 26008,
        "item2": 26156,
        "item3": 26158,
        "item4": 46016,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17147,
        "idmap": 12546,
        "thanh": "U Châu",
        "ten_npc": "Binh Kích Móc",
        "level": 56,
        "hp": 339,
        "sp": 155,
        "hpx": 22,
        "spx": 4,
        "int": 5,
        "atk": 31,
        "def": 22,
        "agi": 22,
        "item1": 26124,
        "item2": 27032,
        "item3": 26122,
        "item4": 35006,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17372,
        "idmap": 12801,
        "ten_map": "Phi Chi Lam",
        "thanh": "U Châu",
        "ten_npc": "Viêm Võ Sĩ",
        "level": 56,
        "hp": 283,
        "sp": 211,
        "hpx": 22,
        "spx": 14,
        "int": 24,
        "atk": 20,
        "def": 21,
        "agi": 11,
        "item1": 26114,
        "item2": 26229,
        "item3": 26114,
        "item4": 46018,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10117,
        "idmap": 10819,
        "ten_npc": "Hãn Đấu Binh",
        "level": 57,
        "hp": 288,
        "sp": 209,
        "hpx": 23,
        "spx": 14,
        "int": 5,
        "atk": 26,
        "def": 24,
        "agi": 21,
        "item1": 27042,
        "item2": 27043,
        "item3": 27042,
        "item4": 40006,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10242,
        "idmap": 10951,
        "ten_npc": "Đại Võ Quan",
        "level": 57,
        "hp": 344,
        "sp": 156,
        "hpx": 33,
        "spx": 4,
        "int": 9,
        "atk": 27,
        "def": 23,
        "agi": 17,
        "item1": 26088,
        "item2": 26088,
        "item3": 26160,
        "item4": 50001,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10268,
        "idmap": 10961,
        "ten_npc": "Tay sai hảitặc",
        "level": 57,
        "hp": 327,
        "sp": 156,
        "hpx": 30,
        "spx": 4,
        "int": 12,
        "atk": 28,
        "def": 20,
        "agi": 19,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11073,
        "idmap": 10988,
        "ten_map": "Van Thanh Me Cung 2",
        "ten_npc": "Tưởng Cang",
        "level": 57,
        "hp": 402,
        "sp": 258,
        "hpx": 23,
        "spx": 15,
        "int": 41,
        "atk": 5,
        "def": 18,
        "agi": 22,
        "item1": 27034,
        "item2": 27029,
        "item3": 27024,
        "item4": 0,
        "item5": 0,
        "item6": 47106
    },
    {
        "idnpc": 11081,
        "idmap": 10991,
        "ten_map": "Loi Dai Doan P",
        "ten_npc": "Tuấn Diễn",
        "level": 57,
        "hp": 373,
        "sp": 256,
        "hpx": 23,
        "spx": 15,
        "int": 32,
        "atk": 19,
        "def": 16,
        "agi": 14,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12154,
        "idmap": 11501,
        "ten_map": "Son Dong Thai Son 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Đổ Quỳnh",
        "level": 57,
        "hp": 487,
        "sp": 257,
        "hpx": 23,
        "spx": 15,
        "int": 36,
        "atk": 7,
        "def": 10,
        "agi": 28,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13068,
        "idmap": 11528,
        "ten_map": "Son Dong Thai Son 26",
        "thanh": "Thanh Châu",
        "ten_npc": "Trương Dị",
        "level": 57,
        "hp": 358,
        "sp": 273,
        "hpx": 20,
        "spx": 18,
        "int": 30,
        "atk": 11,
        "def": 21,
        "agi": 24,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13078,
        "idmap": 11532,
        "ten_map": "Dong Quy Than 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Nghị Lữm",
        "level": 57,
        "hp": 358,
        "sp": 262,
        "hpx": 20,
        "spx": 16,
        "int": 3,
        "atk": 35,
        "def": 29,
        "agi": 14,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14186,
        "idmap": 11811,
        "ten_map": "Rung Don Khuu",
        "thanh": "Thanh Châu",
        "ten_npc": "Mạch Nghĩa",
        "level": 57,
        "hp": 413,
        "sp": 240,
        "hpx": 23,
        "spx": 12,
        "int": 9,
        "atk": 26,
        "def": 24,
        "agi": 22,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14188,
        "idmap": 11811,
        "ten_map": "Rung Don Khuu",
        "thanh": "Thanh Châu",
        "ten_npc": "Đặng Thăng",
        "level": 57,
        "hp": 413,
        "sp": 250,
        "hpx": 23,
        "spx": 14,
        "int": 10,
        "atk": 39,
        "def": 20,
        "agi": 10,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14288,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Lục Tuấn",
        "level": 57,
        "hp": 413,
        "sp": 240,
        "hpx": 23,
        "spx": 12,
        "int": 33,
        "atk": 9,
        "def": 22,
        "agi": 17,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14327,
        "idmap": 12002,
        "ten_map": "Dai Lo Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Xương Hy",
        "level": 57,
        "hp": 402,
        "sp": 240,
        "hpx": 23,
        "spx": 12,
        "int": 15,
        "atk": 29,
        "def": 18,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14404,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Hứa Cung",
        "level": 57,
        "hp": 1088,
        "sp": 273,
        "hpx": 23,
        "spx": 7,
        "int": 31,
        "atk": 17,
        "def": 26,
        "agi": 15,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14471,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Thái Huân",
        "level": 57,
        "hp": 373,
        "sp": 249,
        "hpx": 23,
        "spx": 14,
        "int": 7,
        "atk": 29,
        "def": 19,
        "agi": 31,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14558,
        "idmap": 12023,
        "ten_map": "Diem Binh Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Từ Ấp",
        "level": 57,
        "hp": 429,
        "sp": 201,
        "hpx": 33,
        "spx": 5,
        "int": 31,
        "atk": 18,
        "def": 24,
        "agi": 8,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17099,
        "idmap": 12527,
        "thanh": "U Châu",
        "ten_npc": "Tịnh Võ Binh",
        "level": 57,
        "hp": 288,
        "sp": 200,
        "hpx": 23,
        "spx": 12,
        "int": 7,
        "atk": 24,
        "def": 26,
        "agi": 22,
        "item1": 26067,
        "item2": 26170,
        "item3": 26030,
        "item4": 46016,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17146,
        "idmap": 12546,
        "thanh": "U Châu",
        "ten_npc": "Sư Giáp Sĩ",
        "level": 57,
        "hp": 345,
        "sp": 156,
        "hpx": 23,
        "spx": 4,
        "int": 5,
        "atk": 32,
        "def": 20,
        "agi": 23,
        "item1": 27026,
        "item2": 27067,
        "item3": 26124,
        "item4": 46018,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17271,
        "idmap": 12589,
        "ten_map": "Mo Nguyet Trieu Ca 9",
        "thanh": "U Châu",
        "ten_npc": "Dã Hầu Quân",
        "level": 57,
        "hp": 316,
        "sp": 184,
        "hpx": 28,
        "spx": 9,
        "int": 7,
        "atk": 23,
        "def": 20,
        "agi": 26,
        "item1": 27008,
        "item2": 26102,
        "item3": 27005,
        "item4": 33005,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10014,
        "idmap": 10802,
        "ten_npc": "Hà Nghi",
        "level": 58,
        "hp": 368,
        "sp": 248,
        "hpx": 21,
        "spx": 13,
        "int": 11,
        "atk": 31,
        "def": 23,
        "agi": 19,
        "item1": 26168,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 10219,
        "idmap": 10919,
        "ten_npc": "Lục Lương",
        "level": 58,
        "hp": 293,
        "sp": 253,
        "hpx": 23,
        "spx": 14,
        "int": 9,
        "atk": 30,
        "def": 19,
        "agi": 23,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10243,
        "idmap": 10951,
        "ten_npc": "Đại Võ Quan",
        "level": 58,
        "hp": 347,
        "sp": 163,
        "hpx": 33,
        "spx": 5,
        "int": 10,
        "atk": 28,
        "def": 23,
        "agi": 15,
        "item1": 26160,
        "item2": 26160,
        "item3": 26088,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11095,
        "idmap": 10993,
        "ten_map": "Vung dat bang gia 2",
        "ten_npc": "Lý Cang",
        "level": 58,
        "hp": 380,
        "sp": 253,
        "hpx": 23,
        "spx": 14,
        "int": 10,
        "atk": 30,
        "def": 19,
        "agi": 17,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11106,
        "idmap": 10994,
        "ten_map": "Va Dai Dâu Va",
        "ten_npc": "Vương Diêm",
        "level": 58,
        "hp": 346,
        "sp": 248,
        "hpx": 17,
        "spx": 13,
        "int": 7,
        "atk": 37,
        "def": 22,
        "agi": 17,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12030,
        "idmap": 11041,
        "thanh": "Thanh Châu",
        "ten_npc": "Hồ Ban",
        "level": 58,
        "hp": 396,
        "sp": 242,
        "hpx": 26,
        "spx": 12,
        "int": 7,
        "atk": 34,
        "def": 24,
        "agi": 21,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13064,
        "idmap": 11525,
        "ten_map": "Son Dong Thai Son 23",
        "thanh": "Thanh Châu",
        "ten_npc": "Trần Bửu",
        "level": 58,
        "hp": 390,
        "sp": 259,
        "hpx": 25,
        "spx": 15,
        "int": 6,
        "atk": 29,
        "def": 37,
        "agi": 14,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13082,
        "idmap": 11535,
        "ten_map": "Dong Quy Than 5",
        "thanh": "Thanh Châu",
        "ten_npc": "Phù Ưng",
        "level": 58,
        "hp": 324,
        "sp": 253,
        "hpx": 13,
        "spx": 14,
        "int": 40,
        "atk": 7,
        "def": 19,
        "agi": 31,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13113,
        "idmap": 11560,
        "thanh": "Thanh Châu",
        "ten_npc": "Cố Thiệu",
        "level": 58,
        "hp": 440,
        "sp": 204,
        "hpx": 34,
        "spx": 5,
        "int": 35,
        "atk": 6,
        "def": 18,
        "agi": 21,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14168,
        "idmap": 11802,
        "ten_map": "Rung Dong Quan 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Trình Cầu",
        "level": 58,
        "hp": 420,
        "sp": 254,
        "hpx": 23,
        "spx": 14,
        "int": 11,
        "atk": 17,
        "def": 21,
        "agi": 32,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 46034,
        "item6": 0
    },
    {
        "idnpc": 14329,
        "idmap": 12002,
        "ten_map": "Dai Lo Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Viên Trị",
        "level": 58,
        "hp": 397,
        "sp": 248,
        "hpx": 21,
        "spx": 13,
        "int": 31,
        "atk": 14,
        "def": 23,
        "agi": 19,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14364,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Như Ma",
        "level": 58,
        "hp": 397,
        "sp": 248,
        "hpx": 21,
        "spx": 13,
        "int": 14,
        "atk": 28,
        "def": 26,
        "agi": 19,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14390,
        "idmap": 12011,
        "ten_map": "Thanh Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Miêu Thượng",
        "level": 58,
        "hp": 409,
        "sp": 253,
        "hpx": 23,
        "spx": 14,
        "int": 9,
        "atk": 37,
        "def": 18,
        "agi": 20,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14414,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "H.Quỷ Chảo",
        "level": 58,
        "hp": 293,
        "sp": 211,
        "hpx": 23,
        "spx": 14,
        "int": 4,
        "atk": 36,
        "def": 19,
        "agi": 17,
        "item1": 26007,
        "item2": 27039,
        "item3": 27057,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14417,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Lục Quỷ Chảo",
        "level": 58,
        "hp": 293,
        "sp": 211,
        "hpx": 23,
        "spx": 14,
        "int": 4,
        "atk": 35,
        "def": 19,
        "agi": 18,
        "item1": 26115,
        "item2": 26102,
        "item3": 32032,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17100,
        "idmap": 12527,
        "thanh": "U Châu",
        "ten_npc": "Thiên Võ Binh",
        "level": 58,
        "hp": 281,
        "sp": 202,
        "hpx": 21,
        "spx": 12,
        "int": 10,
        "atk": 18,
        "def": 35,
        "agi": 20,
        "item1": 26067,
        "item2": 26170,
        "item3": 26030,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17101,
        "idmap": 12529,
        "thanh": "U Châu",
        "ten_npc": "Quang Võ Binh",
        "level": 58,
        "hp": 293,
        "sp": 196,
        "hpx": 23,
        "spx": 11,
        "int": 24,
        "atk": 15,
        "def": 27,
        "agi": 16,
        "item1": 26004,
        "item2": 26164,
        "item3": 26083,
        "item4": 33015,
        "item5": 50001,
        "item6": 0
    },
    {
        "idnpc": 17272,
        "idmap": 12589,
        "ten_map": "Mo Nguyet Trieu Ca 9",
        "thanh": "U Châu",
        "ten_npc": "Phan Sơn Nữ",
        "level": 58,
        "hp": 303,
        "sp": 202,
        "hpx": 25,
        "spx": 12,
        "int": 8,
        "atk": 20,
        "def": 20,
        "agi": 28,
        "item1": 26005,
        "item2": 26090,
        "item3": 26091,
        "item4": 42006,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17373,
        "idmap": 12801,
        "ten_map": "Phi Chi Lam",
        "thanh": "U Châu",
        "ten_npc": "Khải Giáp Binh",
        "level": 58,
        "hp": 293,
        "sp": 215,
        "hpx": 23,
        "spx": 14,
        "int": 19,
        "atk": 24,
        "def": 23,
        "agi": 10,
        "item1": 26102,
        "item2": 26243,
        "item3": 26102,
        "item4": 39016,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10015,
        "idmap": 10802,
        "ten_npc": "Triệu Hoành",
        "level": 59,
        "hp": 400,
        "sp": 256,
        "hpx": 26,
        "spx": 14,
        "int": 5,
        "atk": 30,
        "def": 25,
        "agi": 20,
        "item1": 27005,
        "item2": 27066,
        "item3": 27032,
        "item4": 0,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 10028,
        "idmap": 10804,
        "ten_npc": "Hà Mạn",
        "level": 59,
        "hp": 387,
        "sp": 259,
        "hpx": 24,
        "spx": 15,
        "int": 16,
        "atk": 24,
        "def": 22,
        "agi": 20,
        "item1": 26168,
        "item2": 26050,
        "item3": 27039,
        "item4": 34015,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 10101,
        "idmap": 10817,
        "ten_npc": "Dường Chu",
        "level": 59,
        "hp": 387,
        "sp": 245,
        "hpx": 24,
        "spx": 12,
        "int": 16,
        "atk": 26,
        "def": 23,
        "agi": 20,
        "item1": 27020,
        "item2": 27037,
        "item3": 27042,
        "item4": 0,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 10183,
        "idmap": 10907,
        "ten_npc": "Họ Trúc",
        "level": 59,
        "hp": 298,
        "sp": 209,
        "hpx": 24,
        "spx": 13,
        "int": 9,
        "atk": 20,
        "def": 23,
        "agi": 29,
        "item1": 26065,
        "item2": 26081,
        "item3": 27019,
        "item4": 46016,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11069,
        "idmap": 10986,
        "ten_map": "Nghia Trang Van Thang",
        "ten_npc": "Lưu Đại",
        "level": 59,
        "hp": 416,
        "sp": 259,
        "hpx": 24,
        "spx": 15,
        "int": 16,
        "atk": 29,
        "def": 16,
        "agi": 24,
        "item1": 27030,
        "item2": 26083,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11084,
        "idmap": 10992,
        "ten_map": "Vung dat bang gia 1",
        "ten_npc": "Tiết Đệ",
        "level": 59,
        "hp": 387,
        "sp": 245,
        "hpx": 24,
        "spx": 12,
        "int": 36,
        "atk": 18,
        "def": 23,
        "agi": 10,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12159,
        "idmap": 11501,
        "ten_map": "Son Dong Thai Son 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Vương Mưu",
        "level": 59,
        "hp": 557,
        "sp": 250,
        "hpx": 33,
        "spx": 13,
        "int": 44,
        "atk": 10,
        "def": 12,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12169,
        "idmap": 11502,
        "ten_map": "Son Dong Thai Son 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Lưu Ninh",
        "level": 59,
        "hp": 387,
        "sp": 225,
        "hpx": 24,
        "spx": 16,
        "int": 42,
        "atk": 11,
        "def": 8,
        "agi": 20,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14032,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Viên Hy",
        "level": 59,
        "hp": 407,
        "sp": 239,
        "hpx": 24,
        "spx": 11,
        "int": 13,
        "atk": 30,
        "def": 22,
        "agi": 20,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14077,
        "idmap": 11582,
        "thanh": "Thanh Châu",
        "ten_npc": "Kiều Mao",
        "level": 59,
        "hp": 407,
        "sp": 259,
        "hpx": 24,
        "spx": 15,
        "int": 18,
        "atk": 19,
        "def": 21,
        "agi": 24,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14224,
        "idmap": 11831,
        "ten_map": "Lang Ta Phi Lam 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Bào Hồng",
        "level": 59,
        "hp": 392,
        "sp": 256,
        "hpx": 21,
        "spx": 14,
        "int": 20,
        "atk": 21,
        "def": 24,
        "agi": 20,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 49001,
        "item5": 46035,
        "item6": 0
    },
    {
        "idnpc": 14248,
        "idmap": 11841,
        "ten_map": "Rung Vi Son 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Binh Kim Giáp",
        "level": 59,
        "hp": 298,
        "sp": 164,
        "hpx": 24,
        "spx": 5,
        "int": 11,
        "atk": 19,
        "def": 38,
        "agi": 21,
        "item1": 27025,
        "item2": 26056,
        "item3": 26077,
        "item4": 46018,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14341,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Trịnh Bửu",
        "level": 59,
        "hp": 407,
        "sp": 256,
        "hpx": 22,
        "spx": 14,
        "int": 5,
        "atk": 30,
        "def": 25,
        "agi": 24,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14415,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Lam Quỷ Chảo",
        "level": 59,
        "hp": 298,
        "sp": 214,
        "hpx": 24,
        "spx": 14,
        "int": 5,
        "atk": 35,
        "def": 18,
        "agi": 22,
        "item1": 26115,
        "item2": 26102,
        "item3": 32032,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15010,
        "idmap": 12064,
        "ten_map": "Quang Truong Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Tà Lữo Đầu",
        "level": 59,
        "hp": 278,
        "sp": 220,
        "hpx": 20,
        "spx": 15,
        "int": 24,
        "atk": 12,
        "def": 21,
        "agi": 26,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15057,
        "idmap": 12081,
        "ten_map": "Thanh Chan Dinh 1",
        "thanh": "U Châu",
        "ten_npc": "Lữo Học Cứu",
        "level": 59,
        "hp": 298,
        "sp": 221,
        "hpx": 24,
        "spx": 15,
        "int": 28,
        "atk": 12,
        "def": 20,
        "agi": 20,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15112,
        "idmap": 12093,
        "ten_map": "DaiDien Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Chủ LôiĐàiNgầm",
        "level": 59,
        "hp": 298,
        "sp": 192,
        "hpx": 24,
        "spx": 10,
        "int": 12,
        "atk": 24,
        "def": 26,
        "agi": 23,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17273,
        "idmap": 12589,
        "ten_map": "Mo Nguyet Trieu Ca 9",
        "thanh": "U Châu",
        "ten_npc": "Huân Quỷ Mao",
        "level": 59,
        "hp": 298,
        "sp": 192,
        "hpx": 24,
        "spx": 10,
        "int": 23,
        "atk": 18,
        "def": 23,
        "agi": 21,
        "item1": 26115,
        "item2": 27038,
        "item3": 27039,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17274,
        "idmap": 12589,
        "ten_map": "Mo Nguyet Trieu Ca 9",
        "thanh": "U Châu",
        "ten_npc": "Việt Tráng Sĩ",
        "level": 59,
        "hp": 328,
        "sp": 187,
        "hpx": 29,
        "spx": 9,
        "int": 8,
        "atk": 27,
        "def": 24,
        "agi": 21,
        "item1": 26115,
        "item2": 26037,
        "item3": 26038,
        "item4": 34027,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10013,
        "idmap": 10802,
        "ten_npc": "Trương Hỷ",
        "level": 60,
        "hp": 371,
        "sp": 241,
        "hpx": 20,
        "spx": 11,
        "int": 13,
        "atk": 34,
        "def": 27,
        "agi": 30,
        "item1": 26047,
        "item2": 27003,
        "item3": 27036,
        "item4": 0,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 10115,
        "idmap": 10819,
        "ten_npc": "Tướng Kh.Vàng",
        "level": 60,
        "hp": 364,
        "sp": 219,
        "hpx": 24,
        "spx": 15,
        "int": 10,
        "atk": 35,
        "def": 29,
        "agi": 12,
        "item1": 26065,
        "item2": 26081,
        "item3": 27019,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10124,
        "idmap": 10820,
        "ten_npc": "Linh Ma Tím",
        "level": 60,
        "hp": 364,
        "sp": 223,
        "hpx": 24,
        "spx": 15,
        "int": 26,
        "atk": 9,
        "def": 28,
        "agi": 23,
        "item1": 26079,
        "item2": 27018,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10129,
        "idmap": 10853,
        "ten_npc": "Quân Lang Nha",
        "level": 60,
        "hp": 364,
        "sp": 218,
        "hpx": 24,
        "spx": 14,
        "int": 9,
        "atk": 27,
        "def": 22,
        "agi": 29,
        "item1": 26081,
        "item2": 27047,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10134,
        "idmap": 10855,
        "ten_npc": "Binh Linh Nha",
        "level": 60,
        "hp": 364,
        "sp": 225,
        "hpx": 24,
        "spx": 16,
        "int": 34,
        "atk": 6,
        "def": 24,
        "agi": 21,
        "item1": 26083,
        "item2": 27025,
        "item3": 27025,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10144,
        "idmap": 10860,
        "ten_npc": "Dã Võ Thi",
        "level": 60,
        "hp": 364,
        "sp": 219,
        "hpx": 24,
        "spx": 15,
        "int": 11,
        "atk": 30,
        "def": 24,
        "agi": 21,
        "item1": 26084,
        "item2": 27003,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10149,
        "idmap": 10863,
        "ten_npc": "Quân Rượu Tế",
        "level": 60,
        "hp": 364,
        "sp": 224,
        "hpx": 24,
        "spx": 15,
        "int": 28,
        "atk": 10,
        "def": 22,
        "agi": 26,
        "item1": 26086,
        "item2": 27048,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10154,
        "idmap": 10865,
        "ten_map": "Thon nguoi moi",
        "ten_npc": "Võ Vệ Tướng",
        "level": 60,
        "hp": 386,
        "sp": 194,
        "hpx": 28,
        "spx": 10,
        "int": 7,
        "atk": 27,
        "def": 33,
        "agi": 20,
        "item1": 26043,
        "item2": 26170,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10246,
        "idmap": 10951,
        "ten_npc": "Đại Võ Quan",
        "level": 60,
        "hp": 364,
        "sp": 208,
        "hpx": 24,
        "spx": 5,
        "int": 9,
        "atk": 35,
        "def": 31,
        "agi": 15,
        "item1": 26229,
        "item2": 26229,
        "item3": 27008,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11079,
        "idmap": 10991,
        "ten_map": "Loi Dai Doan P",
        "ten_npc": "Quan Ngụy",
        "level": 60,
        "hp": 338,
        "sp": 224,
        "hpx": 14,
        "spx": 15,
        "int": 28,
        "atk": 21,
        "def": 31,
        "agi": 26,
        "item1": 26047,
        "item2": 26078,
        "item3": 26158,
        "item4": 50001,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11110,
        "idmap": 10994,
        "ten_map": "Va Dai Dâu Va",
        "ten_npc": "Vương Trực",
        "level": 60,
        "hp": 421,
        "sp": 236,
        "hpx": 29,
        "spx": 10,
        "int": 37,
        "atk": 16,
        "def": 23,
        "agi": 19,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12157,
        "idmap": 11501,
        "ten_map": "Son Dong Thai Son 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Hà Tằng",
        "level": 60,
        "hp": 541,
        "sp": 252,
        "hpx": 29,
        "spx": 13,
        "int": 43,
        "atk": 11,
        "def": 14,
        "agi": 25,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13045,
        "idmap": 11509,
        "ten_map": "Son Dong Thai Son 9",
        "thanh": "Thanh Châu",
        "ten_npc": "Trường.T.Binh",
        "level": 60,
        "hp": 364,
        "sp": 216,
        "hpx": 24,
        "spx": 14,
        "int": 2,
        "atk": 38,
        "def": 30,
        "agi": 15,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14179,
        "idmap": 11802,
        "ten_map": "Rung Dong Quan 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Trương Nam",
        "level": 60,
        "hp": 388,
        "sp": 236,
        "hpx": 23,
        "spx": 10,
        "int": 12,
        "atk": 31,
        "def": 32,
        "agi": 27,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14311,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Cao Nhã",
        "level": 60,
        "hp": 424,
        "sp": 263,
        "hpx": 24,
        "spx": 15,
        "int": 18,
        "atk": 23,
        "def": 30,
        "agi": 25,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14416,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Hồng Quỷ Chảo",
        "level": 60,
        "hp": 304,
        "sp": 217,
        "hpx": 24,
        "spx": 14,
        "int": 5,
        "atk": 36,
        "def": 21,
        "agi": 18,
        "item1": 26007,
        "item2": 27039,
        "item3": 27057,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15158,
        "idmap": 12096,
        "ten_map": "Bac Thang Khong Tuoc2",
        "thanh": "U Châu",
        "ten_npc": "Bà Gọi Hồn",
        "level": 60,
        "hp": 394,
        "sp": 223,
        "hpx": 24,
        "spx": 15,
        "int": 24,
        "atk": 16,
        "def": 20,
        "agi": 26,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15160,
        "idmap": 12096,
        "ten_map": "Bac Thang Khong Tuoc2",
        "thanh": "U Châu",
        "ten_npc": "SơnTrạiPhuNhân",
        "level": 60,
        "hp": 304,
        "sp": 221,
        "hpx": 24,
        "spx": 15,
        "int": 18,
        "atk": 22,
        "def": 19,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15405,
        "idmap": 12406,
        "thanh": "U Châu",
        "ten_npc": "Quỷ bốc",
        "level": 60,
        "hp": 394,
        "sp": 225,
        "hpx": 24,
        "spx": 16,
        "int": 34,
        "atk": 16,
        "def": 10,
        "agi": 26,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17226,
        "idmap": 12585,
        "thanh": "U Châu",
        "ten_npc": "Lục Lâm SơnTặc",
        "level": 60,
        "hp": 364,
        "sp": 219,
        "hpx": 24,
        "spx": 15,
        "int": 12,
        "atk": 24,
        "def": 24,
        "agi": 26,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17275,
        "idmap": 12589,
        "ten_map": "Mo Nguyet Trieu Ca 9",
        "thanh": "U Châu",
        "ten_npc": "HùngTộc Trưởng",
        "level": 60,
        "hp": 759,
        "sp": 160,
        "hpx": 34,
        "spx": 4,
        "int": 6,
        "atk": 29,
        "def": 25,
        "agi": 20,
        "item1": 26058,
        "item2": 26022,
        "item3": 26050,
        "item4": 25049,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17311,
        "idmap": 12701,
        "ten_map": "Doanh Trai Huong Dung",
        "thanh": "U Châu",
        "ten_npc": "Di Châu Binh",
        "level": 60,
        "hp": 759,
        "sp": 160,
        "hpx": 34,
        "spx": 4,
        "int": 6,
        "atk": 32,
        "def": 20,
        "agi": 22,
        "item1": 26085,
        "item2": 26101,
        "item3": 26158,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10023,
        "idmap": 10804,
        "ten_npc": "Trần Bại",
        "level": 61,
        "hp": 400,
        "sp": 232,
        "hpx": 24,
        "spx": 9,
        "int": 11,
        "atk": 42,
        "def": 26,
        "agi": 25,
        "item1": 27048,
        "item2": 26022,
        "item3": 26158,
        "item4": 0,
        "item5": 49001,
        "item6": 47101
    },
    {
        "idnpc": 10221,
        "idmap": 10919,
        "ten_npc": "Trương Tu",
        "level": 61,
        "hp": 309,
        "sp": 272,
        "hpx": 24,
        "spx": 16,
        "int": 36,
        "atk": 17,
        "def": 20,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47102
    },
    {
        "idnpc": 10244,
        "idmap": 10951,
        "ten_npc": "Đại Võ Quan",
        "level": 61,
        "hp": 424,
        "sp": 210,
        "hpx": 34,
        "spx": 5,
        "int": 8,
        "atk": 32,
        "def": 24,
        "agi": 16,
        "item1": 26244,
        "item2": 26244,
        "item3": 26230,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11019,
        "idmap": 10965,
        "ten_npc": "Lưu Nhiên",
        "level": 61,
        "hp": 400,
        "sp": 264,
        "hpx": 24,
        "spx": 15,
        "int": 8,
        "atk": 39,
        "def": 22,
        "agi": 26,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11190,
        "idmap": 11013,
        "ten_map": "Rung Bac Hai",
        "thanh": "Thanh Châu",
        "ten_npc": "Lưu Trinh",
        "level": 61,
        "hp": 342,
        "sp": 299,
        "hpx": 14,
        "spx": 21,
        "int": 46,
        "atk": 16,
        "def": 8,
        "agi": 31,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11214,
        "idmap": 11031,
        "thanh": "Thanh Châu",
        "ten_npc": "Hình Trinh",
        "level": 61,
        "hp": 301,
        "sp": 274,
        "hpx": 23,
        "spx": 17,
        "int": 46,
        "atk": 10,
        "def": 14,
        "agi": 26,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12057,
        "idmap": 11203,
        "thanh": "Thanh Châu",
        "ten_npc": "Trần Chấn",
        "level": 61,
        "hp": 461,
        "sp": 272,
        "hpx": 24,
        "spx": 16,
        "int": 38,
        "atk": 19,
        "def": 16,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47361
    },
    {
        "idnpc": 13081,
        "idmap": 11533,
        "ten_map": "Dong Quy Than 3",
        "thanh": "Thanh Châu",
        "ten_npc": "Tô Cao",
        "level": 61,
        "hp": 365,
        "sp": 243,
        "hpx": 18,
        "spx": 11,
        "int": 6,
        "atk": 41,
        "def": 20,
        "agi": 38,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14238,
        "idmap": 11832,
        "ten_map": "Lang Ta Phi Lam 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Chu Hán",
        "level": 61,
        "hp": 462,
        "sp": 294,
        "hpx": 30,
        "spx": 20,
        "int": 5,
        "atk": 25,
        "def": 26,
        "agi": 21,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14317,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Lưu Hà",
        "level": 61,
        "hp": 431,
        "sp": 265,
        "hpx": 24,
        "spx": 15,
        "int": 11,
        "atk": 35,
        "def": 27,
        "agi": 25,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14369,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Châu Bộc",
        "level": 61,
        "hp": 431,
        "sp": 265,
        "hpx": 24,
        "spx": 15,
        "int": 11,
        "atk": 40,
        "def": 22,
        "agi": 25,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14389,
        "idmap": 12011,
        "ten_map": "Thanh Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Tiết Hồng",
        "level": 61,
        "hp": 431,
        "sp": 264,
        "hpx": 24,
        "spx": 15,
        "int": 9,
        "atk": 40,
        "def": 31,
        "agi": 18,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14399,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Trương Hổ",
        "level": 61,
        "hp": 431,
        "sp": 265,
        "hpx": 24,
        "spx": 15,
        "int": 11,
        "atk": 43,
        "def": 20,
        "agi": 24,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14469,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Thái Trung",
        "level": 61,
        "hp": 400,
        "sp": 265,
        "hpx": 24,
        "spx": 15,
        "int": 11,
        "atk": 33,
        "def": 22,
        "agi": 29,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14470,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Thái Hòa",
        "level": 61,
        "hp": 400,
        "sp": 264,
        "hpx": 24,
        "spx": 15,
        "int": 10,
        "atk": 34,
        "def": 21,
        "agi": 30,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15400,
        "idmap": 12406,
        "thanh": "U Châu",
        "ten_npc": "người làng",
        "level": 61,
        "hp": 318,
        "sp": 173,
        "hpx": 26,
        "spx": 6,
        "int": 13,
        "atk": 35,
        "def": 12,
        "agi": 27,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15406,
        "idmap": 12406,
        "thanh": "U Châu",
        "ten_npc": "Quỷ dược",
        "level": 61,
        "hp": 309,
        "sp": 264,
        "hpx": 24,
        "spx": 15,
        "int": 10,
        "atk": 30,
        "def": 15,
        "agi": 25,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15415,
        "idmap": 12407,
        "thanh": "U Châu",
        "ten_npc": "A Thi Mã",
        "level": 61,
        "hp": 598,
        "sp": 230,
        "hpx": 27,
        "spx": 16,
        "int": 41,
        "atk": 9,
        "def": 13,
        "agi": 29,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17110,
        "idmap": 12533,
        "thanh": "U Châu",
        "ten_npc": "PhongPhàm Binh",
        "level": 61,
        "hp": 373,
        "sp": 221,
        "hpx": 25,
        "spx": 15,
        "int": 7,
        "atk": 29,
        "def": 20,
        "agi": 23,
        "item1": 27039,
        "item2": 32032,
        "item3": 27006,
        "item4": 37972,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17112,
        "idmap": 12534,
        "thanh": "U Châu",
        "ten_npc": "Chiến.Th.Binh",
        "level": 61,
        "hp": 390,
        "sp": 190,
        "hpx": 28,
        "spx": 9,
        "int": 8,
        "atk": 20,
        "def": 26,
        "agi": 24,
        "item1": 32007,
        "item2": 26124,
        "item3": 27038,
        "item4": 41028,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17130,
        "idmap": 12539,
        "thanh": "U Châu",
        "ten_npc": "Võ Sư",
        "level": 61,
        "hp": 373,
        "sp": 224,
        "hpx": 25,
        "spx": 15,
        "int": 17,
        "atk": 15,
        "def": 40,
        "agi": 15,
        "item1": 27042,
        "item2": 27042,
        "item3": 27045,
        "item4": 46016,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17136,
        "idmap": 12541,
        "thanh": "U Châu",
        "ten_npc": "Võ Binh",
        "level": 61,
        "hp": 370,
        "sp": 222,
        "hpx": 24,
        "spx": 15,
        "int": 10,
        "atk": 33,
        "def": 24,
        "agi": 21,
        "item1": 27042,
        "item2": 27042,
        "item3": 27045,
        "item4": 39018,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10022,
        "idmap": 10803,
        "ten_npc": "Thi Mã Cư",
        "level": 62,
        "hp": 363,
        "sp": 274,
        "hpx": 17,
        "spx": 16,
        "int": 32,
        "atk": 16,
        "def": 29,
        "agi": 29,
        "item1": 27061,
        "item2": 26047,
        "item3": 26054,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10106,
        "idmap": 10817,
        "ten_npc": "La Thị",
        "level": 62,
        "hp": 391,
        "sp": 268,
        "hpx": 22,
        "spx": 15,
        "int": 25,
        "atk": 26,
        "def": 24,
        "agi": 27,
        "item1": 27027,
        "item2": 26085,
        "item3": 26008,
        "item4": 0,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 10169,
        "idmap": 10902,
        "ten_npc": "Đ.Thành Tướng",
        "level": 62,
        "hp": 376,
        "sp": 253,
        "hpx": 25,
        "spx": 20,
        "int": 6,
        "atk": 31,
        "def": 25,
        "agi": 17,
        "item1": 26017,
        "item2": 27027,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10245,
        "idmap": 10951,
        "ten_npc": "Đại Võ Quan",
        "level": 62,
        "hp": 433,
        "sp": 218,
        "hpx": 35,
        "spx": 6,
        "int": 7,
        "atk": 34,
        "def": 27,
        "agi": 16,
        "item1": 26230,
        "item2": 26230,
        "item3": 26244,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10247,
        "idmap": 10951,
        "ten_npc": "Lê Tinh Binh",
        "level": 62,
        "hp": 389,
        "sp": 218,
        "hpx": 27,
        "spx": 6,
        "int": 8,
        "atk": 34,
        "def": 32,
        "agi": 17,
        "item1": 27008,
        "item2": 27008,
        "item3": 26229,
        "item4": 50002,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11061,
        "idmap": 10984,
        "ten_npc": "Hạ Hầu Bác",
        "level": 62,
        "hp": 436,
        "sp": 240,
        "hpx": 30,
        "spx": 10,
        "int": 12,
        "atk": 36,
        "def": 25,
        "agi": 26,
        "item1": 27008,
        "item2": 26115,
        "item3": 27044,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11196,
        "idmap": 11013,
        "ten_map": "Rung Bac Hai",
        "thanh": "Thanh Châu",
        "ten_npc": "Phạm Lăng",
        "level": 62,
        "hp": 352,
        "sp": 268,
        "hpx": 15,
        "spx": 15,
        "int": 47,
        "atk": 37,
        "def": 13,
        "agi": 14,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12070,
        "idmap": 11242,
        "ten_map": "Nha Tro Bac Hai",
        "thanh": "Thanh Châu",
        "ten_npc": "Bác Học Nhân",
        "level": 62,
        "hp": 493,
        "sp": 246,
        "hpx": 29,
        "spx": 11,
        "int": 5,
        "atk": 44,
        "def": 17,
        "agi": 33,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12105,
        "idmap": 11307,
        "ten_map": "Dong Quan Quan Phu",
        "thanh": "Thanh Châu",
        "ten_npc": "Mã Huân",
        "level": 62,
        "hp": 487,
        "sp": 251,
        "hpx": 28,
        "spx": 12,
        "int": 6,
        "atk": 36,
        "def": 34,
        "agi": 23,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13134,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Tạ Tinh",
        "level": 62,
        "hp": 531,
        "sp": 226,
        "hpx": 25,
        "spx": 15,
        "int": 16,
        "atk": 46,
        "def": 11,
        "agi": 26,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14013,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Phan Phụng",
        "level": 62,
        "hp": 391,
        "sp": 246,
        "hpx": 22,
        "spx": 11,
        "int": 12,
        "atk": 36,
        "def": 28,
        "agi": 30,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14081,
        "idmap": 11582,
        "thanh": "Thanh Châu",
        "ten_npc": "Trương Dương",
        "level": 62,
        "hp": 479,
        "sp": 274,
        "hpx": 21,
        "spx": 16,
        "int": 32,
        "atk": 11,
        "def": 33,
        "agi": 26,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14181,
        "idmap": 11811,
        "ten_map": "Rung Don Khuu",
        "thanh": "Thanh Châu",
        "ten_npc": "Tiêu Xúc",
        "level": 62,
        "hp": 407,
        "sp": 246,
        "hpx": 25,
        "spx": 11,
        "int": 15,
        "atk": 33,
        "def": 28,
        "agi": 27,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14304,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Vương giai",
        "level": 62,
        "hp": 411,
        "sp": 278,
        "hpx": 20,
        "spx": 17,
        "int": 47,
        "atk": 11,
        "def": 17,
        "agi": 27,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14375,
        "idmap": 12005,
        "ten_map": "Vuon Dao",
        "thanh": "U Châu",
        "ten_npc": "Châu Ngạn",
        "level": 62,
        "hp": 438,
        "sp": 269,
        "hpx": 25,
        "spx": 15,
        "int": 13,
        "atk": 32,
        "def": 22,
        "agi": 31,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14448,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Hàn Hoành",
        "level": 62,
        "hp": 352,
        "sp": 330,
        "hpx": 15,
        "spx": 26,
        "int": 40,
        "atk": 14,
        "def": 20,
        "agi": 25,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14464,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Thẩm Thành",
        "level": 62,
        "hp": 407,
        "sp": 267,
        "hpx": 25,
        "spx": 15,
        "int": 6,
        "atk": 38,
        "def": 22,
        "agi": 33,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14479,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Lưu Tiên",
        "level": 62,
        "hp": 403,
        "sp": 279,
        "hpx": 24,
        "spx": 17,
        "int": 11,
        "atk": 35,
        "def": 21,
        "agi": 32,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14621,
        "idmap": 12061,
        "ten_map": "Diep Thanh Thanh Mon",
        "thanh": "U Châu",
        "ten_npc": "Chiêu Động Hải",
        "level": 62,
        "hp": 380,
        "sp": 291,
        "hpx": 20,
        "spx": 19,
        "int": 7,
        "atk": 46,
        "def": 25,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17065,
        "idmap": 12521,
        "ten_map": "S.Dong KhVang 1",
        "thanh": "U Châu",
        "ten_npc": "Đấu Hạm Sĩ",
        "level": 62,
        "hp": 411,
        "sp": 191,
        "hpx": 31,
        "spx": 9,
        "int": 8,
        "atk": 21,
        "def": 31,
        "agi": 24,
        "item1": 26132,
        "item2": 26088,
        "item3": 26123,
        "item4": 43024,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17076,
        "idmap": 12523,
        "thanh": "U Châu",
        "ten_npc": "Mông Xong Quân",
        "level": 62,
        "hp": 386,
        "sp": 224,
        "hpx": 25,
        "spx": 15,
        "int": 8,
        "atk": 30,
        "def": 21,
        "agi": 24,
        "item1": 26123,
        "item2": 26102,
        "item3": 32032,
        "item4": 34006,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17111,
        "idmap": 12533,
        "thanh": "U Châu",
        "ten_npc": "Hỏa.Th.Binh",
        "level": 62,
        "hp": 376,
        "sp": 223,
        "hpx": 25,
        "spx": 15,
        "int": 6,
        "atk": 22,
        "def": 25,
        "agi": 29,
        "item1": 26128,
        "item2": 26006,
        "item3": 26067,
        "item4": 35006,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17137,
        "idmap": 12542,
        "thanh": "U Châu",
        "ten_npc": "Nô Lệ",
        "level": 62,
        "hp": 376,
        "sp": 225,
        "hpx": 25,
        "spx": 15,
        "int": 12,
        "atk": 30,
        "def": 24,
        "agi": 23,
        "item1": 26030,
        "item2": 26021,
        "item3": 26010,
        "item4": 34006,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17374,
        "idmap": 12801,
        "ten_map": "Phi Chi Lam",
        "thanh": "U Châu",
        "ten_npc": "Đốn Điền Binh",
        "level": 62,
        "hp": 376,
        "sp": 223,
        "hpx": 25,
        "spx": 15,
        "int": 5,
        "atk": 26,
        "def": 28,
        "agi": 25,
        "item1": 26244,
        "item2": 26234,
        "item3": 26230,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17376,
        "idmap": 12801,
        "ten_map": "Phi Chi Lam",
        "thanh": "U Châu",
        "ten_npc": "Phản Đao Thủ",
        "level": 62,
        "hp": 376,
        "sp": 223,
        "hpx": 25,
        "spx": 15,
        "int": 5,
        "atk": 27,
        "def": 28,
        "agi": 24,
        "item1": 26115,
        "item2": 26061,
        "item3": 26115,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10097,
        "idmap": 10816,
        "ten_npc": "Từ Phụng",
        "level": 63,
        "hp": 373,
        "sp": 231,
        "hpx": 18,
        "spx": 8,
        "int": 17,
        "atk": 43,
        "def": 30,
        "agi": 25,
        "item1": 26050,
        "item2": 26067,
        "item3": 26007,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12155,
        "idmap": 11501,
        "ten_map": "Son Dong Thai Son 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Trương Sảng",
        "level": 63,
        "hp": 578,
        "sp": 242,
        "hpx": 32,
        "spx": 10,
        "int": 46,
        "atk": 11,
        "def": 9,
        "agi": 32,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13046,
        "idmap": 11515,
        "ten_map": "Son Dong Thai Son 13",
        "thanh": "Thanh Châu",
        "ten_npc": "Linh Tháo",
        "level": 63,
        "hp": 414,
        "sp": 272,
        "hpx": 25,
        "spx": 15,
        "int": 10,
        "atk": 37,
        "def": 30,
        "agi": 31,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13066,
        "idmap": 11526,
        "ten_map": "Son Dong Thai Son 24",
        "thanh": "Thanh Châu",
        "ten_npc": "Ngô Phấn",
        "level": 63,
        "hp": 435,
        "sp": 254,
        "hpx": 29,
        "spx": 12,
        "int": 6,
        "atk": 36,
        "def": 25,
        "agi": 32,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13138,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Hạ Tuân",
        "level": 63,
        "hp": 567,
        "sp": 228,
        "hpx": 30,
        "spx": 15,
        "int": 11,
        "atk": 46,
        "def": 16,
        "agi": 21,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14044,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Cung Cảnh",
        "level": 63,
        "hp": 414,
        "sp": 274,
        "hpx": 25,
        "spx": 16,
        "int": 18,
        "atk": 24,
        "def": 26,
        "agi": 32,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 46035,
        "item6": 0
    },
    {
        "idnpc": 14222,
        "idmap": 11823,
        "ten_map": "Rung Phung Lai 3",
        "thanh": "Thanh Châu",
        "ten_npc": "Dương Chung",
        "level": 63,
        "hp": 414,
        "sp": 273,
        "hpx": 25,
        "spx": 15,
        "int": 15,
        "atk": 23,
        "def": 30,
        "agi": 32,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 49001,
        "item5": 46035,
        "item6": 0
    },
    {
        "idnpc": 14356,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Trần Mục",
        "level": 63,
        "hp": 445,
        "sp": 274,
        "hpx": 25,
        "spx": 16,
        "int": 17,
        "atk": 39,
        "def": 30,
        "agi": 25,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14398,
        "idmap": 12011,
        "ten_map": "Thanh Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Trần Sanh",
        "level": 63,
        "hp": 445,
        "sp": 273,
        "hpx": 25,
        "spx": 16,
        "int": 16,
        "atk": 41,
        "def": 23,
        "agi": 19,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15416,
        "idmap": 12407,
        "thanh": "U Châu",
        "ten_npc": "A Hắc",
        "level": 63,
        "hp": 630,
        "sp": 198,
        "hpx": 30,
        "spx": 10,
        "int": 11,
        "atk": 41,
        "def": 16,
        "agi": 31,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15433,
        "idmap": 12409,
        "thanh": "U Châu",
        "ten_npc": "Thạch Thành",
        "level": 63,
        "hp": 603,
        "sp": 282,
        "hpx": 25,
        "spx": 17,
        "int": 46,
        "atk": 16,
        "def": 11,
        "agi": 21,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17074,
        "idmap": 12523,
        "thanh": "U Châu",
        "ten_npc": "QuânLầuThuyền",
        "level": 63,
        "hp": 408,
        "sp": 210,
        "hpx": 28,
        "spx": 12,
        "int": 10,
        "atk": 21,
        "def": 24,
        "agi": 29,
        "item1": 26129,
        "item2": 32004,
        "item3": 26121,
        "item4": 25006,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17131,
        "idmap": 12539,
        "thanh": "U Châu",
        "ten_npc": "Binh Lao Sắt",
        "level": 63,
        "hp": 376,
        "sp": 233,
        "hpx": 24,
        "spx": 16,
        "int": 28,
        "atk": 14,
        "def": 27,
        "agi": 22,
        "item1": 26030,
        "item2": 26021,
        "item3": 26010,
        "item4": 46018,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17186,
        "idmap": 12568,
        "thanh": "U Châu",
        "ten_npc": "LạcDươngBinh",
        "level": 63,
        "hp": 382,
        "sp": 229,
        "hpx": 25,
        "spx": 15,
        "int": 14,
        "atk": 15,
        "def": 25,
        "agi": 36,
        "item1": 27005,
        "item2": 26168,
        "item3": 27026,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17204,
        "idmap": 12581,
        "ten_map": "Mo Nguyet Trieu Ca 1",
        "thanh": "U Châu",
        "ten_npc": "Hải Đạo Vương",
        "level": 63,
        "hp": 410,
        "sp": 164,
        "hpx": 30,
        "spx": 4,
        "int": 3,
        "atk": 36,
        "def": 28,
        "agi": 30,
        "item1": 27020,
        "item2": 26160,
        "item3": 27032,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17375,
        "idmap": 12801,
        "ten_map": "Phi Chi Lam",
        "thanh": "U Châu",
        "ten_npc": "Lam Võ Sĩ",
        "level": 63,
        "hp": 382,
        "sp": 226,
        "hpx": 25,
        "spx": 15,
        "int": 6,
        "atk": 28,
        "def": 27,
        "agi": 23,
        "item1": 26230,
        "item2": 26248,
        "item3": 26244,
        "item4": 41005,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17377,
        "idmap": 12802,
        "ten_map": "Rung Cay ngoai o 1",
        "thanh": "U Châu",
        "ten_npc": "Trấn Quân Hiệu",
        "level": 63,
        "hp": 382,
        "sp": 226,
        "hpx": 25,
        "spx": 15,
        "int": 6,
        "atk": 29,
        "def": 26,
        "agi": 23,
        "item1": 26058,
        "item2": 26116,
        "item3": 26058,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10107,
        "idmap": 10818,
        "ten_npc": "Lôi Công",
        "level": 64,
        "hp": 389,
        "sp": 262,
        "hpx": 20,
        "spx": 13,
        "int": 7,
        "atk": 49,
        "def": 28,
        "agi": 26,
        "item1": 26159,
        "item2": 27027,
        "item3": 27033,
        "item4": 0,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 12065,
        "idmap": 11242,
        "ten_map": "Nha Tro Bac Hai",
        "thanh": "Thanh Châu",
        "ten_npc": "Hướng Thông",
        "level": 64,
        "hp": 485,
        "sp": 276,
        "hpx": 26,
        "spx": 16,
        "int": 12,
        "atk": 39,
        "def": 22,
        "agi": 28,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13035,
        "idmap": 11509,
        "ten_map": "Son Dong Thai Son 9",
        "thanh": "Thanh Châu",
        "ten_npc": "Cố Dung",
        "level": 64,
        "hp": 372,
        "sp": 284,
        "hpx": 17,
        "spx": 17,
        "int": 40,
        "atk": 12,
        "def": 29,
        "agi": 28,
        "item1": 27005,
        "item2": 26160,
        "item3": 26018,
        "item4": 0,
        "item5": 0,
        "item6": 47232
    },
    {
        "idnpc": 13054,
        "idmap": 11521,
        "ten_map": "Son Dong Thai Son 19",
        "thanh": "Thanh Châu",
        "ten_npc": "Hàn An",
        "level": 64,
        "hp": 421,
        "sp": 256,
        "hpx": 26,
        "spx": 12,
        "int": 14,
        "atk": 37,
        "def": 30,
        "agi": 22,
        "item1": 26098,
        "item2": 26099,
        "item3": 26082,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13069,
        "idmap": 11529,
        "ten_map": "Son Dong Thai Son 27",
        "thanh": "Thanh Châu",
        "ten_npc": "Trần Cần",
        "level": 64,
        "hp": 478,
        "sp": 256,
        "hpx": 30,
        "spx": 12,
        "int": 7,
        "atk": 39,
        "def": 35,
        "agi": 21,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14307,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Kỷ Nghi",
        "level": 64,
        "hp": 410,
        "sp": 276,
        "hpx": 18,
        "spx": 16,
        "int": 12,
        "atk": 34,
        "def": 32,
        "agi": 32,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14454,
        "idmap": 12013,
        "ten_map": "Dia Nguyet KhVang",
        "thanh": "U Châu",
        "ten_npc": "Triệu Độc",
        "level": 64,
        "hp": 400,
        "sp": 301,
        "hpx": 22,
        "spx": 20,
        "int": 8,
        "atk": 36,
        "def": 23,
        "agi": 32,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14478,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Đặng Nghĩa",
        "level": 64,
        "hp": 440,
        "sp": 262,
        "hpx": 29,
        "spx": 13,
        "int": 10,
        "atk": 36,
        "def": 22,
        "agi": 31,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17118,
        "idmap": 12536,
        "thanh": "U Châu",
        "ten_npc": "Độc Tướng",
        "level": 64,
        "hp": 402,
        "sp": 268,
        "hpx": 28,
        "spx": 22,
        "int": 24,
        "atk": 16,
        "def": 26,
        "agi": 17,
        "item1": 26029,
        "item2": 27021,
        "item3": 26115,
        "item4": 36006,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17119,
        "idmap": 12536,
        "thanh": "U Châu",
        "ten_npc": "Sư Nha",
        "level": 64,
        "hp": 368,
        "sp": 217,
        "hpx": 22,
        "spx": 13,
        "int": 14,
        "atk": 31,
        "def": 24,
        "agi": 29,
        "item1": 26029,
        "item2": 27021,
        "item3": 27006,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10096,
        "idmap": 10816,
        "ten_npc": "Phong Thế",
        "level": 65,
        "hp": 394,
        "sp": 283,
        "hpx": 20,
        "spx": 16,
        "int": 24,
        "atk": 36,
        "def": 23,
        "agi": 26,
        "item1": 27032,
        "item2": 26085,
        "item3": 26170,
        "item4": 0,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 10100,
        "idmap": 10816,
        "ten_npc": "Giai Cố",
        "level": 65,
        "hp": 405,
        "sp": 247,
        "hpx": 22,
        "spx": 10,
        "int": 11,
        "atk": 41,
        "def": 32,
        "agi": 29,
        "item1": 27042,
        "item2": 26086,
        "item3": 26168,
        "item4": 0,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 10220,
        "idmap": 10919,
        "ten_npc": "Lai Đạt",
        "level": 65,
        "hp": 330,
        "sp": 280,
        "hpx": 26,
        "spx": 16,
        "int": 12,
        "atk": 37,
        "def": 32,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10222,
        "idmap": 10920,
        "ten_npc": "Quan Hạo",
        "level": 65,
        "hp": 330,
        "sp": 279,
        "hpx": 26,
        "spx": 16,
        "int": 11,
        "atk": 37,
        "def": 30,
        "agi": 25,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10237,
        "idmap": 10951,
        "ten_npc": "Dương Bá",
        "level": 65,
        "hp": 411,
        "sp": 258,
        "hpx": 23,
        "spx": 12,
        "int": 40,
        "atk": 22,
        "def": 16,
        "agi": 33,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11198,
        "idmap": 11021,
        "ten_map": "Thon Phung Lai",
        "thanh": "Thanh Châu",
        "ten_npc": "Đại võ quan",
        "level": 65,
        "hp": 370,
        "sp": 304,
        "hpx": 33,
        "spx": 20,
        "int": 42,
        "atk": 10,
        "def": 16,
        "agi": 15,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12036,
        "idmap": 11101,
        "ten_map": "N.Chiem Cuong Bac Hai",
        "thanh": "Thanh Châu",
        "ten_npc": "Củng Chí",
        "level": 65,
        "hp": 416,
        "sp": 224,
        "hpx": 24,
        "spx": 6,
        "int": 14,
        "atk": 47,
        "def": 22,
        "agi": 32,
        "item1": 27041,
        "item2": 27061,
        "item3": 27005,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13033,
        "idmap": 11509,
        "ten_map": "Son Dong Thai Son 9",
        "thanh": "Thanh Châu",
        "ten_npc": "Nghiêm Tuấn",
        "level": 65,
        "hp": 460,
        "sp": 289,
        "hpx": 26,
        "spx": 17,
        "int": 44,
        "atk": 17,
        "def": 21,
        "agi": 24,
        "item1": 27065,
        "item2": 27034,
        "item3": 26086,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13137,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Thuần Vu Đơn",
        "level": 65,
        "hp": 518,
        "sp": 234,
        "hpx": 19,
        "spx": 16,
        "int": 12,
        "atk": 54,
        "def": 17,
        "agi": 27,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14001,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "CôngTônToản",
        "level": 65,
        "hp": 382,
        "sp": 247,
        "hpx": 18,
        "spx": 10,
        "int": 25,
        "atk": 37,
        "def": 28,
        "agi": 27,
        "item1": 32004,
        "item2": 26082,
        "item3": 26003,
        "item4": 0,
        "item5": 0,
        "item6": 47019
    },
    {
        "idnpc": 14033,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Viên Đàm",
        "level": 65,
        "hp": 428,
        "sp": 264,
        "hpx": 26,
        "spx": 13,
        "int": 16,
        "atk": 32,
        "def": 27,
        "agi": 31,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14089,
        "idmap": 11582,
        "thanh": "Thanh Châu",
        "ten_npc": "HoàngBửu.K.Thọ",
        "level": 65,
        "hp": 428,
        "sp": 280,
        "hpx": 26,
        "spx": 16,
        "int": 12,
        "atk": 31,
        "def": 35,
        "agi": 25,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14361,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Cha Dung",
        "level": 65,
        "hp": 460,
        "sp": 288,
        "hpx": 26,
        "spx": 17,
        "int": 41,
        "atk": 11,
        "def": 25,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14452,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Lương Kỳ",
        "level": 65,
        "hp": 416,
        "sp": 292,
        "hpx": 24,
        "spx": 18,
        "int": 6,
        "atk": 40,
        "def": 38,
        "agi": 19,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14462,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Giang Cung",
        "level": 65,
        "hp": 394,
        "sp": 315,
        "hpx": 20,
        "spx": 22,
        "int": 40,
        "atk": 21,
        "def": 14,
        "agi": 28,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14553,
        "idmap": 12023,
        "ten_map": "Diem Binh Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Tập Thiêu",
        "level": 65,
        "hp": 439,
        "sp": 264,
        "hpx": 28,
        "spx": 13,
        "int": 6,
        "atk": 40,
        "def": 34,
        "agi": 24,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17086,
        "idmap": 12524,
        "thanh": "U Châu",
        "ten_npc": "Huyền Võ Binh",
        "level": 65,
        "hp": 395,
        "sp": 224,
        "hpx": 26,
        "spx": 14,
        "int": 12,
        "atk": 32,
        "def": 26,
        "agi": 25,
        "item1": 27020,
        "item2": 26168,
        "item3": 26067,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17087,
        "idmap": 12525,
        "thanh": "U Châu",
        "ten_npc": "Kiện Võ Binh",
        "level": 65,
        "hp": 395,
        "sp": 218,
        "hpx": 26,
        "spx": 13,
        "int": 10,
        "atk": 24,
        "def": 32,
        "agi": 30,
        "item1": 26168,
        "item2": 26067,
        "item3": 27020,
        "item4": 41006,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17120,
        "idmap": 12536,
        "thanh": "U Châu",
        "ten_npc": "Khúc Nha Nữ",
        "level": 65,
        "hp": 395,
        "sp": 315,
        "hpx": 26,
        "spx": 30,
        "int": 15,
        "atk": 18,
        "def": 26,
        "agi": 20,
        "item1": 26029,
        "item2": 27021,
        "item3": 27008,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17121,
        "idmap": 12536,
        "thanh": "U Châu",
        "ten_npc": "Thiên.H.Sư",
        "level": 65,
        "hp": 548,
        "sp": 231,
        "hpx": 53,
        "spx": 15,
        "int": 3,
        "atk": 22,
        "def": 23,
        "agi": 19,
        "item1": 27033,
        "item2": 27039,
        "item3": 26094,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10043,
        "idmap": 10807,
        "ten_npc": "Du Hồn Thốt",
        "level": 66,
        "hp": 401,
        "sp": 235,
        "hpx": 26,
        "spx": 16,
        "int": 6,
        "atk": 27,
        "def": 25,
        "agi": 30,
        "item1": 26229,
        "item2": 26234,
        "item3": 26229,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10103,
        "idmap": 10817,
        "ten_npc": "Lý Đại Mục",
        "level": 66,
        "hp": 421,
        "sp": 281,
        "hpx": 24,
        "spx": 16,
        "int": 5,
        "atk": 55,
        "def": 24,
        "agi": 23,
        "item1": 26018,
        "item2": 26060,
        "item3": 27033,
        "item4": 0,
        "item5": 49001,
        "item6": 47008
    },
    {
        "idnpc": 10172,
        "idmap": 10903,
        "ten_npc": "Khổ Nhu",
        "level": 66,
        "hp": 434,
        "sp": 283,
        "hpx": 26,
        "spx": 16,
        "int": 22,
        "atk": 24,
        "def": 32,
        "agi": 27,
        "item1": 26067,
        "item2": 26059,
        "item3": 27042,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11096,
        "idmap": 10993,
        "ten_map": "Vung dat bang gia 2",
        "ten_npc": "Lý Chỉnh",
        "level": 66,
        "hp": 434,
        "sp": 283,
        "hpx": 26,
        "spx": 16,
        "int": 10,
        "atk": 45,
        "def": 22,
        "agi": 28,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11161,
        "idmap": 11011,
        "ten_map": "Bac Hai Thanh Mon",
        "thanh": "Thanh Châu",
        "ten_npc": "Ưng Dương",
        "level": 66,
        "hp": 438,
        "sp": 255,
        "hpx": 27,
        "spx": 11,
        "int": 47,
        "atk": 6,
        "def": 34,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12074,
        "idmap": 11262,
        "thanh": "Thanh Châu",
        "ten_npc": "Phan Đốc",
        "level": 66,
        "hp": 500,
        "sp": 238,
        "hpx": 26,
        "spx": 8,
        "int": 51,
        "atk": 9,
        "def": 22,
        "agi": 36,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12095,
        "idmap": 11306,
        "thanh": "Thanh Châu",
        "ten_npc": "Trác Ưng",
        "level": 66,
        "hp": 521,
        "sp": 255,
        "hpx": 30,
        "spx": 11,
        "int": 8,
        "atk": 31,
        "def": 36,
        "agi": 14,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12100,
        "idmap": 11307,
        "ten_map": "Dong Quan Quan Phu",
        "thanh": "Thanh Châu",
        "ten_npc": "Cung Kham",
        "level": 66,
        "hp": 521,
        "sp": 260,
        "hpx": 30,
        "spx": 12,
        "int": 9,
        "atk": 40,
        "def": 37,
        "agi": 17,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12146,
        "idmap": 11501,
        "ten_map": "Son Dong Thai Son 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Huân Quyết",
        "level": 66,
        "hp": 660,
        "sp": 278,
        "hpx": 37,
        "spx": 15,
        "int": 62,
        "atk": 18,
        "def": 19,
        "agi": 40,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12160,
        "idmap": 11501,
        "ten_map": "Son Dong Thai Son 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Hứa Từ",
        "level": 66,
        "hp": 566,
        "sp": 260,
        "hpx": 26,
        "spx": 12,
        "int": 57,
        "atk": 5,
        "def": 17,
        "agi": 29,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13086,
        "idmap": 11536,
        "ten_map": "Dong Quy Than 6",
        "thanh": "Thanh Châu",
        "ten_npc": "Tống Khiêm",
        "level": 66,
        "hp": 448,
        "sp": 283,
        "hpx": 23,
        "spx": 16,
        "int": 11,
        "atk": 48,
        "def": 22,
        "agi": 28,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13087,
        "idmap": 11536,
        "ten_map": "Dong Quy Than 6",
        "thanh": "Thanh Châu",
        "ten_npc": "Giả Hòa",
        "level": 66,
        "hp": 437,
        "sp": 282,
        "hpx": 21,
        "spx": 16,
        "int": 7,
        "atk": 51,
        "def": 28,
        "agi": 25,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13100,
        "idmap": 11542,
        "ten_map": "Dong Quy Than 12",
        "thanh": "Thanh Châu",
        "ten_npc": "Duẫn Dị",
        "level": 66,
        "hp": 477,
        "sp": 255,
        "hpx": 28,
        "spx": 11,
        "int": 40,
        "atk": 8,
        "def": 23,
        "agi": 36,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13120,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Trương Huyền",
        "level": 66,
        "hp": 448,
        "sp": 255,
        "hpx": 23,
        "spx": 11,
        "int": 41,
        "atk": 36,
        "def": 10,
        "agi": 25,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13139,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Sử Tích",
        "level": 66,
        "hp": 536,
        "sp": 240,
        "hpx": 21,
        "spx": 17,
        "int": 22,
        "atk": 52,
        "def": 12,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14055,
        "idmap": 11581,
        "thanh": "Thanh Châu",
        "ten_npc": "Khổng Hữu",
        "level": 66,
        "hp": 434,
        "sp": 285,
        "hpx": 26,
        "spx": 16,
        "int": 19,
        "atk": 26,
        "def": 30,
        "agi": 29,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14160,
        "idmap": 11801,
        "ten_map": "Rung Dong Quan 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Vương Môn",
        "level": 66,
        "hp": 454,
        "sp": 300,
        "hpx": 24,
        "spx": 19,
        "int": 9,
        "atk": 40,
        "def": 23,
        "agi": 34,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14193,
        "idmap": 11811,
        "ten_map": "Rung Don Khuu",
        "thanh": "Thanh Châu",
        "ten_npc": "Dương Phụng",
        "level": 66,
        "hp": 434,
        "sp": 281,
        "hpx": 26,
        "spx": 16,
        "int": 6,
        "atk": 36,
        "def": 27,
        "agi": 36,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14198,
        "idmap": 11811,
        "ten_map": "Rung Don Khuu",
        "thanh": "Thanh Châu",
        "ten_npc": "Ân Cử",
        "level": 66,
        "hp": 434,
        "sp": 283,
        "hpx": 26,
        "spx": 16,
        "int": 11,
        "atk": 21,
        "def": 37,
        "agi": 36,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14217,
        "idmap": 11823,
        "thanh": "Thanh Châu",
        "ten_npc": "Cửu Khâm",
        "level": 66,
        "hp": 434,
        "sp": 291,
        "hpx": 26,
        "spx": 17,
        "int": 40,
        "atk": 10,
        "def": 27,
        "agi": 26,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14287,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Lục Khang",
        "level": 66,
        "hp": 434,
        "sp": 291,
        "hpx": 26,
        "spx": 17,
        "int": 38,
        "atk": 11,
        "def": 26,
        "agi": 28,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14303,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Hứa Kỷ",
        "level": 66,
        "hp": 467,
        "sp": 291,
        "hpx": 26,
        "spx": 17,
        "int": 41,
        "atk": 19,
        "def": 14,
        "agi": 29,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14319,
        "idmap": 12002,
        "ten_map": "Dai Lo Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Lý Châu",
        "level": 66,
        "hp": 467,
        "sp": 284,
        "hpx": 26,
        "spx": 16,
        "int": 16,
        "atk": 40,
        "def": 22,
        "agi": 26,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14325,
        "idmap": 12002,
        "ten_map": "Dai Lo Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Ngô Đôn",
        "level": 66,
        "hp": 467,
        "sp": 283,
        "hpx": 26,
        "spx": 16,
        "int": 15,
        "atk": 24,
        "def": 39,
        "agi": 27,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14355,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Trần Tín",
        "level": 66,
        "hp": 454,
        "sp": 286,
        "hpx": 24,
        "spx": 17,
        "int": 22,
        "atk": 38,
        "def": 24,
        "agi": 23,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14451,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Hàn Phạm",
        "level": 66,
        "hp": 434,
        "sp": 282,
        "hpx": 26,
        "spx": 16,
        "int": 7,
        "atk": 36,
        "def": 40,
        "agi": 20,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14463,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Trương Xích",
        "level": 66,
        "hp": 375,
        "sp": 346,
        "hpx": 16,
        "spx": 27,
        "int": 9,
        "atk": 40,
        "def": 22,
        "agi": 32,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14475,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Đặng Long",
        "level": 66,
        "hp": 404,
        "sp": 317,
        "hpx": 21,
        "spx": 22,
        "int": 8,
        "atk": 37,
        "def": 33,
        "agi": 25,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14518,
        "idmap": 12018,
        "ten_map": "Quan Tra TQ",
        "thanh": "U Châu",
        "ten_npc": "Đại Võ Quan",
        "level": 66,
        "hp": 404,
        "sp": 226,
        "hpx": 21,
        "spx": 14,
        "int": 44,
        "atk": 8,
        "def": 38,
        "agi": 21,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14565,
        "idmap": 12031,
        "ten_map": "Thon Lau Song",
        "thanh": "U Châu",
        "ten_npc": "Trương Mân",
        "level": 66,
        "hp": 455,
        "sp": 272,
        "hpx": 30,
        "spx": 14,
        "int": 46,
        "atk": 7,
        "def": 22,
        "agi": 28,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17141,
        "idmap": 12544,
        "thanh": "U Châu",
        "ten_npc": "Tr.Trợ Quân",
        "level": 66,
        "hp": 456,
        "sp": 231,
        "hpx": 36,
        "spx": 15,
        "int": 16,
        "atk": 22,
        "def": 24,
        "agi": 24,
        "item1": 26158,
        "item2": 27004,
        "item3": 26066,
        "item4": 0,
        "item5": 24029,
        "item6": 0
    },
    {
        "idnpc": 17142,
        "idmap": 12544,
        "thanh": "U Châu",
        "ten_npc": "Bàng Giáp Sĩ",
        "level": 66,
        "hp": 394,
        "sp": 220,
        "hpx": 25,
        "spx": 13,
        "int": 14,
        "atk": 22,
        "def": 25,
        "agi": 38,
        "item1": 26158,
        "item2": 27004,
        "item3": 26066,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10104,
        "idmap": 10817,
        "ten_npc": "Tả Tượng Bát",
        "level": 67,
        "hp": 441,
        "sp": 217,
        "hpx": 27,
        "spx": 4,
        "int": 6,
        "atk": 50,
        "def": 32,
        "agi": 30,
        "item1": 26066,
        "item2": 26068,
        "item3": 27026,
        "item4": 0,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 10105,
        "idmap": 10817,
        "ten_npc": "Lưu Thạch",
        "level": 67,
        "hp": 441,
        "sp": 286,
        "hpx": 27,
        "spx": 16,
        "int": 20,
        "atk": 32,
        "def": 26,
        "agi": 28,
        "item1": 26067,
        "item2": 26091,
        "item3": 27021,
        "item4": 0,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 11033,
        "idmap": 10966,
        "ten_npc": "Hạ Hầu Ân",
        "level": 67,
        "hp": 391,
        "sp": 268,
        "hpx": 18,
        "spx": 13,
        "int": 13,
        "atk": 42,
        "def": 33,
        "agi": 30,
        "item1": 27027,
        "item2": 26022,
        "item3": 26008,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11039,
        "idmap": 10966,
        "ten_npc": "Tào Ngạn",
        "level": 67,
        "hp": 460,
        "sp": 245,
        "hpx": 30,
        "spx": 9,
        "int": 15,
        "atk": 36,
        "def": 31,
        "agi": 28,
        "item1": 27032,
        "item2": 26018,
        "item3": 26058,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11100,
        "idmap": 10993,
        "ten_map": "Vung dat bang gia 2",
        "ten_npc": "Giả Tín",
        "level": 67,
        "hp": 441,
        "sp": 286,
        "hpx": 27,
        "spx": 16,
        "int": 8,
        "atk": 45,
        "def": 27,
        "agi": 26,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11124,
        "idmap": 10997,
        "ten_npc": "Chuân Du đạo",
        "level": 67,
        "hp": 419,
        "sp": 286,
        "hpx": 17,
        "spx": 16,
        "int": 9,
        "atk": 52,
        "def": 36,
        "agi": 21,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11143,
        "idmap": 11002,
        "ten_map": "Dai Do Dong Quan",
        "thanh": "Thanh Châu",
        "ten_npc": "hỗ chất",
        "level": 67,
        "hp": 487,
        "sp": 240,
        "hpx": 29,
        "spx": 8,
        "int": 13,
        "atk": 41,
        "def": 38,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11149,
        "idmap": 11011,
        "ten_map": "Bac Hai Thanh Mon",
        "thanh": "Thanh Châu",
        "ten_npc": "An Thự",
        "level": 67,
        "hp": 448,
        "sp": 280,
        "hpx": 28,
        "spx": 15,
        "int": 11,
        "atk": 42,
        "def": 31,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12075,
        "idmap": 11263,
        "thanh": "Thanh Châu",
        "ten_npc": "Vương Sĩ",
        "level": 67,
        "hp": 498,
        "sp": 263,
        "hpx": 25,
        "spx": 12,
        "int": 7,
        "atk": 48,
        "def": 35,
        "agi": 24,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12099,
        "idmap": 11307,
        "ten_map": "Dong Quan Quan Phu",
        "thanh": "Thanh Châu",
        "ten_npc": "Triệu Tạc",
        "level": 67,
        "hp": 538,
        "sp": 257,
        "hpx": 32,
        "spx": 11,
        "int": 10,
        "atk": 39,
        "def": 36,
        "agi": 18,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12153,
        "idmap": 11501,
        "ten_map": "Son Dong Thai Son 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Triệu Tộ",
        "level": 67,
        "hp": 575,
        "sp": 298,
        "hpx": 27,
        "spx": 18,
        "int": 50,
        "atk": 9,
        "def": 21,
        "agi": 23,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13133,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Lý Dị",
        "level": 67,
        "hp": 575,
        "sp": 240,
        "hpx": 27,
        "spx": 16,
        "int": 12,
        "atk": 52,
        "def": 17,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14192,
        "idmap": 11811,
        "ten_map": "Rung Don Khuu",
        "thanh": "Thanh Châu",
        "ten_npc": "Hoàng Long",
        "level": 67,
        "hp": 441,
        "sp": 257,
        "hpx": 27,
        "spx": 11,
        "int": 11,
        "atk": 38,
        "def": 29,
        "agi": 33,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14205,
        "idmap": 11821,
        "thanh": "Thanh Châu",
        "ten_npc": "Sầm Bích",
        "level": 67,
        "hp": 493,
        "sp": 274,
        "hpx": 30,
        "spx": 14,
        "int": 9,
        "atk": 38,
        "def": 35,
        "agi": 21,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14227,
        "idmap": 11831,
        "ten_map": "Lang Ta Phi Lam 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Chung Bửu",
        "level": 67,
        "hp": 441,
        "sp": 257,
        "hpx": 27,
        "spx": 11,
        "int": 10,
        "atk": 39,
        "def": 36,
        "agi": 26,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 47020
    },
    {
        "idnpc": 14269,
        "idmap": 12000,
        "ten_map": "U Chau",
        "thanh": "U Châu",
        "ten_npc": "Thôi Cự Nghiệp",
        "level": 67,
        "hp": 441,
        "sp": 288,
        "hpx": 27,
        "spx": 16,
        "int": 17,
        "atk": 32,
        "def": 28,
        "agi": 29,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14289,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Lưu Sủng",
        "level": 67,
        "hp": 441,
        "sp": 294,
        "hpx": 27,
        "spx": 17,
        "int": 36,
        "atk": 17,
        "def": 28,
        "agi": 25,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14381,
        "idmap": 12007,
        "ten_map": "K.San Vui Lai",
        "thanh": "U Châu",
        "ten_npc": "Chiêm Cường",
        "level": 67,
        "hp": 475,
        "sp": 287,
        "hpx": 27,
        "spx": 16,
        "int": 14,
        "atk": 41,
        "def": 21,
        "agi": 30,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14382,
        "idmap": 12009,
        "ten_map": "N.Quach Dich",
        "thanh": "U Châu",
        "ten_npc": "Hà Hùng",
        "level": 67,
        "hp": 475,
        "sp": 286,
        "hpx": 27,
        "spx": 16,
        "int": 10,
        "atk": 42,
        "def": 26,
        "agi": 28,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14400,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Đặng tế",
        "level": 67,
        "hp": 493,
        "sp": 286,
        "hpx": 30,
        "spx": 16,
        "int": 12,
        "atk": 40,
        "def": 20,
        "agi": 31,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14401,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Trương Thạc",
        "level": 67,
        "hp": 527,
        "sp": 286,
        "hpx": 36,
        "spx": 16,
        "int": 10,
        "atk": 39,
        "def": 24,
        "agi": 24,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14431,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Chu Thượng",
        "level": 67,
        "hp": 475,
        "sp": 245,
        "hpx": 27,
        "spx": 9,
        "int": 43,
        "atk": 24,
        "def": 24,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14458,
        "idmap": 12013,
        "ten_map": "Dia Nguyet KhVang",
        "thanh": "U Châu",
        "ten_npc": "Phạm Tiên",
        "level": 67,
        "hp": 425,
        "sp": 308,
        "hpx": 24,
        "spx": 20,
        "int": 5,
        "atk": 41,
        "def": 22,
        "agi": 34,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14460,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Trương Diêm",
        "level": 67,
        "hp": 402,
        "sp": 274,
        "hpx": 20,
        "spx": 14,
        "int": 47,
        "atk": 6,
        "def": 21,
        "agi": 38,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14511,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Triệu Phạm",
        "level": 67,
        "hp": 431,
        "sp": 274,
        "hpx": 25,
        "spx": 14,
        "int": 47,
        "atk": 22,
        "def": 15,
        "agi": 26,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17139,
        "idmap": 12544,
        "thanh": "U Châu",
        "ten_npc": "Hàn Thuẫn Binh",
        "level": 67,
        "hp": 438,
        "sp": 210,
        "hpx": 32,
        "spx": 11,
        "int": 5,
        "atk": 34,
        "def": 22,
        "agi": 27,
        "item1": 26126,
        "item2": 26160,
        "item3": 26006,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17378,
        "idmap": 12802,
        "ten_map": "Rung Cay ngoai o 1",
        "thanh": "U Châu",
        "ten_npc": "Vũ Sơn Nữ",
        "level": 67,
        "hp": 408,
        "sp": 238,
        "hpx": 27,
        "spx": 16,
        "int": 6,
        "atk": 30,
        "def": 25,
        "agi": 27,
        "item1": 26060,
        "item2": 26010,
        "item3": 26060,
        "item4": 34027,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10044,
        "idmap": 10807,
        "ten_npc": "Tế Quỷ Thốt",
        "level": 68,
        "hp": 414,
        "sp": 241,
        "hpx": 27,
        "spx": 16,
        "int": 5,
        "atk": 30,
        "def": 26,
        "agi": 31,
        "item1": 26229,
        "item2": 26234,
        "item3": 26229,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10171,
        "idmap": 10903,
        "ten_npc": "Triệu Từ",
        "level": 68,
        "hp": 441,
        "sp": 259,
        "hpx": 26,
        "spx": 11,
        "int": 19,
        "atk": 36,
        "def": 29,
        "agi": 30,
        "item1": 27027,
        "item2": 27067,
        "item3": 27038,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11080,
        "idmap": 10991,
        "ten_map": "Loi Dai Doan P",
        "ten_npc": "Tư Mã Lang",
        "level": 68,
        "hp": 482,
        "sp": 299,
        "hpx": 27,
        "spx": 18,
        "int": 41,
        "atk": 24,
        "def": 19,
        "agi": 23,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47270
    },
    {
        "idnpc": 11099,
        "idmap": 10993,
        "ten_map": "Vung dat bang gia 2",
        "ten_npc": "Phùng Giai",
        "level": 68,
        "hp": 448,
        "sp": 265,
        "hpx": 27,
        "spx": 12,
        "int": 11,
        "atk": 46,
        "def": 27,
        "agi": 28,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11108,
        "idmap": 10994,
        "ten_map": "Va Dai Dâu Va",
        "ten_npc": "Hàn Phúc",
        "level": 68,
        "hp": 453,
        "sp": 282,
        "hpx": 28,
        "spx": 15,
        "int": 5,
        "atk": 42,
        "def": 41,
        "agi": 19,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11113,
        "idmap": 10995,
        "ten_npc": "Mã Diên",
        "level": 68,
        "hp": 482,
        "sp": 289,
        "hpx": 27,
        "spx": 16,
        "int": 7,
        "atk": 40,
        "def": 37,
        "agi": 23,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11132,
        "idmap": 11000,
        "ten_map": "Thanh Chau",
        "thanh": "Thanh Châu",
        "ten_npc": "Tống Hào",
        "level": 68,
        "hp": 366,
        "sp": 289,
        "hpx": 7,
        "spx": 16,
        "int": 6,
        "atk": 60,
        "def": 45,
        "agi": 21,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11189,
        "idmap": 11013,
        "ten_map": "Rung Bac Hai",
        "thanh": "Thanh Châu",
        "ten_npc": "Nguyễn Ngu",
        "level": 68,
        "hp": 545,
        "sp": 271,
        "hpx": 44,
        "spx": 13,
        "int": 47,
        "atk": 5,
        "def": 17,
        "agi": 26,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12067,
        "idmap": 11242,
        "ten_map": "Nha Tro Bac Hai",
        "thanh": "Thanh Châu",
        "ten_npc": "Hạ Hầu Lâm",
        "level": 68,
        "hp": 504,
        "sp": 253,
        "hpx": 25,
        "spx": 10,
        "int": 7,
        "atk": 47,
        "def": 18,
        "agi": 43,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12096,
        "idmap": 11306,
        "thanh": "Thanh Châu",
        "ten_npc": "Phí Thư",
        "level": 68,
        "hp": 538,
        "sp": 271,
        "hpx": 31,
        "spx": 13,
        "int": 42,
        "atk": 9,
        "def": 37,
        "agi": 20,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12145,
        "idmap": 11501,
        "ten_map": "Son Dong Thai Son 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Phiền Kiến",
        "level": 68,
        "hp": 732,
        "sp": 294,
        "hpx": 47,
        "spx": 17,
        "int": 50,
        "atk": 20,
        "def": 16,
        "agi": 46,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12168,
        "idmap": 11502,
        "ten_map": "Son Dong Thai Son 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Đổ Lộ",
        "level": 68,
        "hp": 448,
        "sp": 253,
        "hpx": 27,
        "spx": 18,
        "int": 46,
        "atk": 8,
        "def": 15,
        "agi": 23,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13006,
        "idmap": 11502,
        "ten_map": "Son Dong Thai Son 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Lữ Phạm",
        "level": 68,
        "hp": 436,
        "sp": 297,
        "hpx": 25,
        "spx": 18,
        "int": 33,
        "atk": 15,
        "def": 28,
        "agi": 32,
        "item1": 26156,
        "item2": 26162,
        "item3": 26047,
        "item4": 0,
        "item5": 0,
        "item6": 47370
    },
    {
        "idnpc": 13022,
        "idmap": 11503,
        "ten_map": "Son Dong Thai Son 3",
        "thanh": "Thanh Châu",
        "ten_npc": "Lục Tích",
        "level": 68,
        "hp": 418,
        "sp": 282,
        "hpx": 22,
        "spx": 15,
        "int": 48,
        "atk": 22,
        "def": 23,
        "agi": 21,
        "item1": 27032,
        "item2": 26168,
        "item3": 26066,
        "item4": 0,
        "item5": 0,
        "item6": 47240
    },
    {
        "idnpc": 13031,
        "idmap": 11508,
        "ten_map": "Son Dong Thai Son 8",
        "thanh": "Thanh Châu",
        "ten_npc": "Tiết Chung",
        "level": 68,
        "hp": 482,
        "sp": 299,
        "hpx": 27,
        "spx": 18,
        "int": 42,
        "atk": 25,
        "def": 17,
        "agi": 23,
        "item1": 27040,
        "item2": 27034,
        "item3": 27003,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13060,
        "idmap": 11522,
        "ten_map": "Son Dong Thai Son 20",
        "thanh": "Thanh Châu",
        "ten_npc": "Tòan Nhu",
        "level": 68,
        "hp": 448,
        "sp": 301,
        "hpx": 27,
        "spx": 18,
        "int": 47,
        "atk": 15,
        "def": 22,
        "agi": 23,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47353
    },
    {
        "idnpc": 13077,
        "idmap": 11532,
        "ten_map": "Dong Quy Than 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Lưu Cơ",
        "level": 68,
        "hp": 418,
        "sp": 276,
        "hpx": 22,
        "spx": 14,
        "int": 55,
        "atk": 21,
        "def": 11,
        "agi": 29,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13125,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Lưu Thước",
        "level": 68,
        "hp": 567,
        "sp": 282,
        "hpx": 42,
        "spx": 15,
        "int": 12,
        "atk": 45,
        "def": 15,
        "agi": 23,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13126,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Lưu Thượng",
        "level": 68,
        "hp": 556,
        "sp": 322,
        "hpx": 40,
        "spx": 22,
        "int": 44,
        "atk": 7,
        "def": 19,
        "agi": 20,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14149,
        "idmap": 11585,
        "thanh": "Thanh Châu",
        "ten_npc": "Hàn Cự Tử",
        "level": 68,
        "hp": 482,
        "sp": 290,
        "hpx": 27,
        "spx": 16,
        "int": 9,
        "atk": 44,
        "def": 24,
        "agi": 35,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14306,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Quách Cung",
        "level": 68,
        "hp": 441,
        "sp": 276,
        "hpx": 20,
        "spx": 14,
        "int": 16,
        "atk": 36,
        "def": 35,
        "agi": 30,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14544,
        "idmap": 12023,
        "ten_map": "Diem Binh Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Vương Di",
        "level": 70,
        "hp": 509,
        "sp": 333,
        "hpx": 24,
        "spx": 23,
        "int": 49,
        "atk": 12,
        "def": 22,
        "agi": 34,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15401,
        "idmap": 12406,
        "thanh": "U Châu",
        "ten_npc": "Liễu Thụ Xuân",
        "level": 70,
        "hp": 357,
        "sp": 226,
        "hpx": 28,
        "spx": 13,
        "int": 18,
        "atk": 44,
        "def": 10,
        "agi": 25,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17103,
        "idmap": 12531,
        "thanh": "U Châu",
        "ten_npc": "Kiện Võ Quân",
        "level": 70,
        "hp": 462,
        "sp": 251,
        "hpx": 28,
        "spx": 17,
        "int": 19,
        "atk": 27,
        "def": 34,
        "agi": 20,
        "item1": 26086,
        "item2": 26083,
        "item3": 39018,
        "item4": 0,
        "item5": 50001,
        "item6": 0
    },
    {
        "idnpc": 17106,
        "idmap": 12532,
        "thanh": "U Châu",
        "ten_npc": "Uy Võ Quân",
        "level": 70,
        "hp": 462,
        "sp": 252,
        "hpx": 28,
        "spx": 17,
        "int": 21,
        "atk": 29,
        "def": 29,
        "agi": 21,
        "item1": 27031,
        "item2": 26166,
        "item3": 42006,
        "item4": 0,
        "item5": 50001,
        "item6": 0
    },
    {
        "idnpc": 17160,
        "idmap": 12556,
        "thanh": "U Châu",
        "ten_npc": "Huyền Sơn Tử",
        "level": 70,
        "hp": 514,
        "sp": 251,
        "hpx": 37,
        "spx": 17,
        "int": 18,
        "atk": 18,
        "def": 25,
        "agi": 30,
        "item1": 32006,
        "item2": 26094,
        "item3": 26069,
        "item4": 34007,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17161,
        "idmap": 12557,
        "thanh": "U Châu",
        "ten_npc": "Đạo Linh Tử",
        "level": 70,
        "hp": 543,
        "sp": 253,
        "hpx": 42,
        "spx": 18,
        "int": 26,
        "atk": 15,
        "def": 27,
        "agi": 17,
        "item1": 26069,
        "item2": 32006,
        "item3": 26116,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17189,
        "idmap": 12569,
        "thanh": "U Châu",
        "ten_npc": "Bắc Địa Binh",
        "level": 70,
        "hp": 543,
        "sp": 168,
        "hpx": 42,
        "spx": 3,
        "int": 2,
        "atk": 37,
        "def": 32,
        "agi": 29,
        "item1": 27048,
        "item2": 26058,
        "item3": 27038,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17215,
        "idmap": 12582,
        "thanh": "U Châu",
        "ten_npc": "Mưu Sư",
        "level": 70,
        "hp": 462,
        "sp": 257,
        "hpx": 28,
        "spx": 18,
        "int": 40,
        "atk": 9,
        "def": 21,
        "agi": 29,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17216,
        "idmap": 12582,
        "thanh": "U Châu",
        "ten_npc": "Mưu Sư",
        "level": 70,
        "hp": 462,
        "sp": 257,
        "hpx": 28,
        "spx": 18,
        "int": 40,
        "atk": 9,
        "def": 21,
        "agi": 29,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17217,
        "idmap": 12582,
        "thanh": "U Châu",
        "ten_npc": "Sách Sư",
        "level": 70,
        "hp": 462,
        "sp": 256,
        "hpx": 28,
        "spx": 18,
        "int": 36,
        "atk": 14,
        "def": 23,
        "agi": 26,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17218,
        "idmap": 12582,
        "thanh": "U Châu",
        "ten_npc": "Sách Sư",
        "level": 70,
        "hp": 462,
        "sp": 256,
        "hpx": 28,
        "spx": 18,
        "int": 36,
        "atk": 14,
        "def": 23,
        "agi": 26,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17565,
        "idmap": 12825,
        "ten_map": "Rung Lau Song 1",
        "thanh": "U Châu",
        "ten_npc": "Phi Trạo Binh",
        "level": 70,
        "hp": 549,
        "sp": 240,
        "hpx": 31,
        "spx": 7,
        "int": 11,
        "atk": 38,
        "def": 36,
        "agi": 14,
        "item1": 26132,
        "item2": 26132,
        "item3": 26131,
        "item4": 33016,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17566,
        "idmap": 12825,
        "ten_map": "Rung Lau Song 1",
        "thanh": "U Châu",
        "ten_npc": "Thuyền QLương",
        "level": 70,
        "hp": 590,
        "sp": 240,
        "hpx": 38,
        "spx": 7,
        "int": 10,
        "atk": 36,
        "def": 38,
        "agi": 8,
        "item1": 26131,
        "item2": 26131,
        "item3": 26132,
        "item4": 50002,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10209,
        "idmap": 10915,
        "ten_map": "Khach san nguoi moi",
        "ten_npc": "Quân Sư KhVàng",
        "level": 71,
        "hp": 469,
        "sp": 268,
        "hpx": 28,
        "spx": 20,
        "int": 36,
        "atk": 11,
        "def": 26,
        "agi": 26,
        "item1": 26055,
        "item2": 26055,
        "item3": 27003,
        "item4": 50001,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11121,
        "idmap": 10997,
        "ten_npc": "Lưu Phóng",
        "level": 71,
        "hp": 540,
        "sp": 312,
        "hpx": 28,
        "spx": 19,
        "int": 46,
        "atk": 24,
        "def": 28,
        "agi": 23,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12062,
        "idmap": 11241,
        "ten_map": "Nha Tro Dong Quan",
        "thanh": "Thanh Châu",
        "ten_npc": "Đỗ Phổ",
        "level": 71,
        "hp": 532,
        "sp": 312,
        "hpx": 27,
        "spx": 19,
        "int": 9,
        "atk": 47,
        "def": 28,
        "agi": 37,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12106,
        "idmap": 11307,
        "ten_map": "Dong Quan Quan Phu",
        "thanh": "Thanh Châu",
        "ten_npc": "Tiếu Chu",
        "level": 71,
        "hp": 601,
        "sp": 254,
        "hpx": 39,
        "spx": 9,
        "int": 48,
        "atk": 24,
        "def": 7,
        "agi": 41,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12113,
        "idmap": 11401,
        "ten_map": "Dinh Mat Vi Son",
        "thanh": "Thanh Châu",
        "ten_npc": "Viên Long",
        "level": 71,
        "hp": 555,
        "sp": 243,
        "hpx": 31,
        "spx": 7,
        "int": 52,
        "atk": 20,
        "def": 27,
        "agi": 31,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13106,
        "idmap": 11544,
        "ten_map": "Dong Quy Than 14",
        "thanh": "Thanh Châu",
        "ten_npc": "Đại Võ Quan",
        "level": 71,
        "hp": 549,
        "sp": 289,
        "hpx": 30,
        "spx": 15,
        "int": 17,
        "atk": 47,
        "def": 24,
        "agi": 33,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14049,
        "idmap": 11564,
        "thanh": "Thanh Châu",
        "ten_npc": "Diền Giai",
        "level": 71,
        "hp": 549,
        "sp": 289,
        "hpx": 30,
        "spx": 15,
        "int": 8,
        "atk": 50,
        "def": 22,
        "agi": 41,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14219,
        "idmap": 11823,
        "thanh": "Thanh Châu",
        "ten_npc": "Hà Miêu",
        "level": 71,
        "hp": 540,
        "sp": 304,
        "hpx": 28,
        "spx": 18,
        "int": 21,
        "atk": 32,
        "def": 37,
        "agi": 32,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14279,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Dương Bưu",
        "level": 71,
        "hp": 540,
        "sp": 305,
        "hpx": 28,
        "spx": 18,
        "int": 24,
        "atk": 42,
        "def": 28,
        "agi": 27,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14312,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Hầu Giai",
        "level": 71,
        "hp": 540,
        "sp": 304,
        "hpx": 28,
        "spx": 18,
        "int": 21,
        "atk": 35,
        "def": 21,
        "agi": 44,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14315,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Từ Tuyên",
        "level": 71,
        "hp": 540,
        "sp": 304,
        "hpx": 28,
        "spx": 18,
        "int": 21,
        "atk": 46,
        "def": 29,
        "agi": 25,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14333,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Tư Trọng Ứng",
        "level": 71,
        "hp": 540,
        "sp": 312,
        "hpx": 28,
        "spx": 19,
        "int": 48,
        "atk": 20,
        "def": 22,
        "agi": 30,
        "item1": 26016,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14385,
        "idmap": 12009,
        "ten_map": "N.Quach Dich",
        "thanh": "U Châu",
        "ten_npc": "Lương Mậu",
        "level": 71,
        "hp": 540,
        "sp": 313,
        "hpx": 28,
        "spx": 19,
        "int": 51,
        "atk": 20,
        "def": 27,
        "agi": 21,
        "item1": 26045,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14501,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Tô Đại",
        "level": 71,
        "hp": 540,
        "sp": 300,
        "hpx": 28,
        "spx": 17,
        "int": 6,
        "atk": 50,
        "def": 41,
        "agi": 24,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15432,
        "idmap": 12409,
        "thanh": "U Châu",
        "ten_npc": "Trạch Ý",
        "level": 71,
        "hp": 709,
        "sp": 305,
        "hpx": 33,
        "spx": 18,
        "int": 22,
        "atk": 46,
        "def": 14,
        "agi": 34,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17127,
        "idmap": 12538,
        "thanh": "U Châu",
        "ten_npc": "Đạo Đồng",
        "level": 71,
        "hp": 469,
        "sp": 258,
        "hpx": 28,
        "spx": 18,
        "int": 32,
        "atk": 15,
        "def": 27,
        "agi": 26,
        "item1": 27068,
        "item2": 32007,
        "item3": 32005,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10006,
        "idmap": 10801,
        "ten_npc": "Trương.M.Thành",
        "level": 72,
        "hp": 484,
        "sp": 256,
        "hpx": 24,
        "spx": 9,
        "int": 11,
        "atk": 52,
        "def": 38,
        "agi": 35,
        "item1": 26033,
        "item2": 26035,
        "item3": 26170,
        "item4": 0,
        "item5": 49001,
        "item6": 47098
    },
    {
        "idnpc": 10122,
        "idmap": 10820,
        "ten_npc": "Hổ Bình Dương",
        "level": 72,
        "hp": 548,
        "sp": 303,
        "hpx": 29,
        "spx": 17,
        "int": 4,
        "atk": 39,
        "def": 35,
        "agi": 18,
        "item1": 27075,
        "item2": 27075,
        "item3": 26234,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11015,
        "idmap": 10965,
        "ten_npc": "Lữ Tường",
        "level": 72,
        "hp": 548,
        "sp": 307,
        "hpx": 29,
        "spx": 18,
        "int": 16,
        "atk": 45,
        "def": 31,
        "agi": 30,
        "item1": 27042,
        "item2": 26115,
        "item3": 26159,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11030,
        "idmap": 10966,
        "ten_npc": "Lữ Khoáng",
        "level": 72,
        "hp": 549,
        "sp": 286,
        "hpx": 29,
        "spx": 14,
        "int": 19,
        "atk": 44,
        "def": 34,
        "agi": 29,
        "item1": 27032,
        "item2": 26029,
        "item3": 26091,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11075,
        "idmap": 10991,
        "ten_map": "Loi Dai Doan P",
        "ten_npc": "Chung Tiến",
        "level": 72,
        "hp": 508,
        "sp": 297,
        "hpx": 22,
        "spx": 16,
        "int": 17,
        "atk": 48,
        "def": 28,
        "agi": 38,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11107,
        "idmap": 10994,
        "ten_map": "Va Dai Dâu Va",
        "ten_npc": "Khổng Tú",
        "level": 72,
        "hp": 548,
        "sp": 303,
        "hpx": 29,
        "spx": 17,
        "int": 4,
        "atk": 52,
        "def": 43,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11126,
        "idmap": 10997,
        "ten_npc": "Chung Tấn",
        "level": 72,
        "hp": 531,
        "sp": 274,
        "hpx": 26,
        "spx": 12,
        "int": 10,
        "atk": 55,
        "def": 24,
        "agi": 40,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11141,
        "idmap": 11001,
        "ten_map": "Thanh Môn Dong Quan",
        "thanh": "Thanh Châu",
        "ten_npc": "Lý Lập",
        "level": 72,
        "hp": 572,
        "sp": 291,
        "hpx": 33,
        "spx": 15,
        "int": 47,
        "atk": 16,
        "def": 36,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11170,
        "idmap": 11011,
        "ten_map": "Bac Hai Thanh Mon",
        "thanh": "Thanh Châu",
        "ten_npc": "Vương Đồ",
        "level": 72,
        "hp": 607,
        "sp": 280,
        "hpx": 39,
        "spx": 13,
        "int": 56,
        "atk": 14,
        "def": 24,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12063,
        "idmap": 11241,
        "ten_map": "Nha Tro Dong Quan",
        "thanh": "Thanh Châu",
        "ten_npc": "Lương Đại",
        "level": 72,
        "hp": 526,
        "sp": 332,
        "hpx": 25,
        "spx": 22,
        "int": 9,
        "atk": 48,
        "def": 39,
        "agi": 25,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12119,
        "idmap": 11411,
        "ten_map": "Khuc Trac Khong Mieu",
        "thanh": "Thanh Châu",
        "ten_npc": "Trương Chú",
        "level": 72,
        "hp": 485,
        "sp": 308,
        "hpx": 18,
        "spx": 18,
        "int": 21,
        "atk": 47,
        "def": 24,
        "agi": 40,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13007,
        "idmap": 11502,
        "ten_map": "Son Dong Thai Son 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Bộ Mã",
        "level": 72,
        "hp": 548,
        "sp": 317,
        "hpx": 29,
        "spx": 19,
        "int": 53,
        "atk": 25,
        "def": 23,
        "agi": 20,
        "item1": 26055,
        "item2": 27031,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 47334
    },
    {
        "idnpc": 13091,
        "idmap": 11538,
        "ten_map": "Dong Quy Than 8",
        "thanh": "Thanh Châu",
        "ten_npc": "Châu Kỷ",
        "level": 72,
        "hp": 514,
        "sp": 309,
        "hpx": 23,
        "spx": 18,
        "int": 17,
        "atk": 56,
        "def": 34,
        "agi": 20,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13108,
        "idmap": 11552,
        "thanh": "Thanh Châu",
        "ten_npc": "Đại Võ Quan",
        "level": 72,
        "hp": 561,
        "sp": 251,
        "hpx": 31,
        "spx": 8,
        "int": 11,
        "atk": 31,
        "def": 40,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13142,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Triệu Tư",
        "level": 72,
        "hp": 692,
        "sp": 291,
        "hpx": 29,
        "spx": 15,
        "int": 60,
        "atk": 13,
        "def": 20,
        "agi": 28,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14046,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Lưu Tường",
        "level": 72,
        "hp": 548,
        "sp": 316,
        "hpx": 29,
        "spx": 19,
        "int": 50,
        "atk": 16,
        "def": 29,
        "agi": 26,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14115,
        "idmap": 11584,
        "thanh": "Thanh Châu",
        "ten_npc": "Hà Mậu",
        "level": 72,
        "hp": 520,
        "sp": 338,
        "hpx": 24,
        "spx": 23,
        "int": 10,
        "atk": 50,
        "def": 28,
        "agi": 33,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14155,
        "idmap": 11801,
        "ten_map": "Rung Dong Quan 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Hồ Ngọc",
        "level": 72,
        "hp": 548,
        "sp": 216,
        "hpx": 29,
        "spx": 2,
        "int": 15,
        "atk": 51,
        "def": 32,
        "agi": 40,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14204,
        "idmap": 11821,
        "ten_map": "Rung Phung Lai 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Vương Thông",
        "level": 72,
        "hp": 497,
        "sp": 361,
        "hpx": 20,
        "spx": 27,
        "int": 49,
        "atk": 11,
        "def": 38,
        "agi": 23,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14207,
        "idmap": 11821,
        "thanh": "Thanh Châu",
        "ten_npc": "Bàng An",
        "level": 72,
        "hp": 548,
        "sp": 305,
        "hpx": 29,
        "spx": 17,
        "int": 11,
        "atk": 49,
        "def": 37,
        "agi": 24,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14363,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Trần Hòanh",
        "level": 72,
        "hp": 548,
        "sp": 306,
        "hpx": 29,
        "spx": 18,
        "int": 15,
        "atk": 46,
        "def": 31,
        "agi": 31,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14473,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Tô Phi",
        "level": 72,
        "hp": 548,
        "sp": 308,
        "hpx": 29,
        "spx": 18,
        "int": 21,
        "atk": 46,
        "def": 29,
        "agi": 25,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14492,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Trương Võ",
        "level": 72,
        "hp": 508,
        "sp": 350,
        "hpx": 22,
        "spx": 25,
        "int": 8,
        "atk": 49,
        "def": 24,
        "agi": 40,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15436,
        "idmap": 12409,
        "thanh": "U Châu",
        "ten_npc": "Vũ Văn Cảnh",
        "level": 72,
        "hp": 722,
        "sp": 318,
        "hpx": 34,
        "spx": 19,
        "int": 54,
        "atk": 14,
        "def": 22,
        "agi": 26,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15448,
        "idmap": 12409,
        "thanh": "U Châu",
        "ten_npc": "A Bảo",
        "level": 72,
        "hp": 1700,
        "sp": 424,
        "hpx": 29,
        "spx": 13,
        "int": 36,
        "atk": 34,
        "def": 7,
        "agi": 24,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17128,
        "idmap": 12539,
        "thanh": "U Châu",
        "ten_npc": "HoaSơn ĐạoNhân",
        "level": 72,
        "hp": 430,
        "sp": 261,
        "hpx": 21,
        "spx": 18,
        "int": 34,
        "atk": 20,
        "def": 29,
        "agi": 26,
        "item1": 27068,
        "item2": 32007,
        "item3": 32005,
        "item4": 42007,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17219,
        "idmap": 12582,
        "thanh": "U Châu",
        "ten_npc": "Phiến Tướng",
        "level": 72,
        "hp": 476,
        "sp": 255,
        "hpx": 29,
        "spx": 17,
        "int": 11,
        "atk": 38,
        "def": 26,
        "agi": 28,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17220,
        "idmap": 12584,
        "thanh": "U Châu",
        "ten_npc": "Phiến Tướng",
        "level": 72,
        "hp": 476,
        "sp": 255,
        "hpx": 29,
        "spx": 17,
        "int": 11,
        "atk": 38,
        "def": 26,
        "agi": 28,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17221,
        "idmap": 12584,
        "thanh": "U Châu",
        "ten_npc": "Phiến Tướng",
        "level": 72,
        "hp": 476,
        "sp": 255,
        "hpx": 29,
        "spx": 17,
        "int": 11,
        "atk": 38,
        "def": 26,
        "agi": 28,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17382,
        "idmap": 12802,
        "ten_map": "Rung Cay ngoai o 1",
        "thanh": "U Châu",
        "ten_npc": "Binh Hung Nô",
        "level": 72,
        "hp": 548,
        "sp": 303,
        "hpx": 29,
        "spx": 17,
        "int": 4,
        "atk": 39,
        "def": 21,
        "agi": 33,
        "item1": 26069,
        "item2": 26011,
        "item3": 26069,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10076,
        "idmap": 10812,
        "ten_npc": "Tô Mã",
        "level": 73,
        "hp": 519,
        "sp": 310,
        "hpx": 29,
        "spx": 18,
        "int": 16,
        "atk": 40,
        "def": 37,
        "agi": 31,
        "item1": 26086,
        "item2": 26005,
        "item3": 26044,
        "item4": 0,
        "item5": 49002,
        "item6": 0
    },
    {
        "idnpc": 10123,
        "idmap": 10820,
        "ten_npc": "Quỷ Sư",
        "level": 73,
        "hp": 556,
        "sp": 314,
        "hpx": 29,
        "spx": 18,
        "int": 28,
        "atk": 30,
        "def": 19,
        "agi": 19,
        "item1": 26234,
        "item2": 26234,
        "item3": 27075,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11068,
        "idmap": 10985,
        "ten_npc": "Thái Dương",
        "level": 73,
        "hp": 556,
        "sp": 311,
        "hpx": 29,
        "spx": 18,
        "int": 19,
        "atk": 42,
        "def": 33,
        "agi": 30,
        "item1": 27020,
        "item2": 26115,
        "item3": 26091,
        "item4": 0,
        "item5": 0,
        "item6": 47322
    },
    {
        "idnpc": 11087,
        "idmap": 10992,
        "ten_map": "Vung dat bang gia 1",
        "ten_npc": "Thi Hoan",
        "level": 73,
        "hp": 556,
        "sp": 300,
        "hpx": 29,
        "spx": 16,
        "int": 10,
        "atk": 50,
        "def": 23,
        "agi": 40,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47339
    },
    {
        "idnpc": 11104,
        "idmap": 10994,
        "ten_map": "Va Dai Dâu Va",
        "ten_npc": "Diêm Nhu",
        "level": 73,
        "hp": 556,
        "sp": 319,
        "hpx": 29,
        "spx": 19,
        "int": 47,
        "atk": 18,
        "def": 30,
        "agi": 26,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47145
    },
    {
        "idnpc": 11127,
        "idmap": 10997,
        "ten_npc": "Chung Thân",
        "level": 73,
        "hp": 525,
        "sp": 288,
        "hpx": 24,
        "spx": 14,
        "int": 11,
        "atk": 55,
        "def": 24,
        "agi": 40,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12077,
        "idmap": 11265,
        "thanh": "Thanh Châu",
        "ten_npc": "Hân Quan",
        "level": 73,
        "hp": 508,
        "sp": 247,
        "hpx": 21,
        "spx": 7,
        "int": 55,
        "atk": 16,
        "def": 46,
        "agi": 24,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12111,
        "idmap": 11401,
        "ten_map": "Dinh Mat Vi Son",
        "thanh": "Thanh Châu",
        "ten_npc": "Trương Kiều",
        "level": 73,
        "hp": 595,
        "sp": 253,
        "hpx": 36,
        "spx": 8,
        "int": 51,
        "atk": 33,
        "def": 17,
        "agi": 24,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13072,
        "idmap": 11529,
        "ten_map": "Son Dong Thai Son 27",
        "thanh": "Thanh Châu",
        "ten_npc": "Trương Ôn",
        "level": 73,
        "hp": 556,
        "sp": 319,
        "hpx": 29,
        "spx": 19,
        "int": 46,
        "atk": 18,
        "def": 23,
        "agi": 34,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47199
    },
    {
        "idnpc": 13076,
        "idmap": 11532,
        "ten_map": "Dong Quy Than 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Từ Tộ",
        "level": 73,
        "hp": 531,
        "sp": 335,
        "hpx": 25,
        "spx": 22,
        "int": 8,
        "atk": 51,
        "def": 23,
        "agi": 39,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13090,
        "idmap": 11537,
        "ten_map": "Dong Quy Than 7",
        "thanh": "Thanh Châu",
        "ten_npc": "Châu Tài",
        "level": 73,
        "hp": 537,
        "sp": 282,
        "hpx": 26,
        "spx": 13,
        "int": 16,
        "atk": 53,
        "def": 39,
        "agi": 21,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13103,
        "idmap": 11543,
        "ten_map": "Dong Quy Than 13",
        "thanh": "Thanh Châu",
        "ten_npc": "Qua định",
        "level": 73,
        "hp": 537,
        "sp": 212,
        "hpx": 26,
        "spx": 1,
        "int": 42,
        "atk": 51,
        "def": 16,
        "agi": 17,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13107,
        "idmap": 11551,
        "ten_map": "Dong Hai Tac Phung Lai 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Đại Võ Quan",
        "level": 73,
        "hp": 625,
        "sp": 241,
        "hpx": 41,
        "spx": 6,
        "int": 34,
        "atk": 49,
        "def": 22,
        "agi": 17,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14156,
        "idmap": 11801,
        "ten_map": "Rung Dong Quan 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Chúc Tý",
        "level": 73,
        "hp": 531,
        "sp": 335,
        "hpx": 25,
        "spx": 22,
        "int": 11,
        "atk": 50,
        "def": 37,
        "agi": 23,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14328,
        "idmap": 12002,
        "ten_map": "Dai Lo Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Hàn Nhân",
        "level": 73,
        "hp": 590,
        "sp": 322,
        "hpx": 35,
        "spx": 20,
        "int": 55,
        "atk": 19,
        "def": 19,
        "agi": 25,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14354,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Tổ Lang",
        "level": 73,
        "hp": 556,
        "sp": 313,
        "hpx": 29,
        "spx": 18,
        "int": 25,
        "atk": 41,
        "def": 27,
        "agi": 31,
        "item1": 26057,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14425,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Hồng Tiến",
        "level": 73,
        "hp": 619,
        "sp": 276,
        "hpx": 40,
        "spx": 12,
        "int": 19,
        "atk": 49,
        "def": 29,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14491,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Trần Tôn",
        "level": 73,
        "hp": 537,
        "sp": 329,
        "hpx": 26,
        "spx": 21,
        "int": 9,
        "atk": 48,
        "def": 28,
        "agi": 36,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14502,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Bối Vũ",
        "level": 73,
        "hp": 556,
        "sp": 322,
        "hpx": 29,
        "spx": 20,
        "int": 55,
        "atk": 24,
        "def": 11,
        "agi": 31,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14656,
        "idmap": 12063,
        "ten_map": "Hoang Cung Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "MangNhaTrưởng",
        "level": 73,
        "hp": 734,
        "sp": 282,
        "hpx": 41,
        "spx": 13,
        "int": 14,
        "atk": 47,
        "def": 11,
        "agi": 27,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15279,
        "idmap": 12241,
        "ten_map": "Nha Tro Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Bào Lữo Gia",
        "level": 73,
        "hp": 584,
        "sp": 276,
        "hpx": 34,
        "spx": 12,
        "int": 51,
        "atk": 26,
        "def": 21,
        "agi": 25,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17084,
        "idmap": 12523,
        "thanh": "U Châu",
        "ten_npc": "Kinh Sư Binh",
        "level": 73,
        "hp": 483,
        "sp": 301,
        "hpx": 29,
        "spx": 25,
        "int": 7,
        "atk": 34,
        "def": 31,
        "agi": 25,
        "item1": 27049,
        "item2": 27049,
        "item3": 27076,
        "item4": 39019,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17380,
        "idmap": 12802,
        "ten_map": "Rung Cay ngoai o 1",
        "thanh": "U Châu",
        "ten_npc": "LínhBiênPhòng",
        "level": 73,
        "hp": 556,
        "sp": 307,
        "hpx": 29,
        "spx": 17,
        "int": 4,
        "atk": 34,
        "def": 33,
        "agi": 25,
        "item1": 27045,
        "item2": 26069,
        "item3": 26069,
        "item4": 43056,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11009,
        "idmap": 10964,
        "ten_npc": "Thi Mã Sư",
        "level": 74,
        "hp": 636,
        "sp": 308,
        "hpx": 42,
        "spx": 17,
        "int": 16,
        "atk": 46,
        "def": 34,
        "agi": 18,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11088,
        "idmap": 10992,
        "ten_map": "Vung dat bang gia 1",
        "ten_npc": "Lộ Chiêu",
        "level": 74,
        "hp": 564,
        "sp": 315,
        "hpx": 30,
        "spx": 18,
        "int": 18,
        "atk": 47,
        "def": 35,
        "agi": 25,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47275
    },
    {
        "idnpc": 11134,
        "idmap": 11001,
        "ten_map": "Thanh Môn Dong Quan",
        "thanh": "Thanh Châu",
        "ten_npc": "Lý Tụ",
        "level": 74,
        "hp": 525,
        "sp": 314,
        "hpx": 23,
        "spx": 18,
        "int": 15,
        "atk": 53,
        "def": 39,
        "agi": 25,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11135,
        "idmap": 11001,
        "ten_map": "Thanh Môn Dong Quan",
        "thanh": "Thanh Châu",
        "ten_npc": "Lý Cơ",
        "level": 74,
        "hp": 525,
        "sp": 314,
        "hpx": 23,
        "spx": 18,
        "int": 15,
        "atk": 53,
        "def": 39,
        "agi": 25,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11168,
        "idmap": 11011,
        "ten_map": "Bac Hai Thanh Mon",
        "thanh": "Thanh Châu",
        "ten_npc": "Cao Tác",
        "level": 74,
        "hp": 584,
        "sp": 302,
        "hpx": 33,
        "spx": 16,
        "int": 13,
        "atk": 57,
        "def": 30,
        "agi": 19,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11169,
        "idmap": 11011,
        "ten_map": "Bac Hai Thanh Mon",
        "thanh": "Thanh Châu",
        "ten_npc": "Giải Phiếu",
        "level": 74,
        "hp": 589,
        "sp": 284,
        "hpx": 34,
        "spx": 13,
        "int": 11,
        "atk": 56,
        "def": 32,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12003,
        "idmap": 11031,
        "thanh": "Thanh Châu",
        "ten_npc": "Vương Bổ",
        "level": 74,
        "hp": 548,
        "sp": 261,
        "hpx": 27,
        "spx": 9,
        "int": 15,
        "atk": 61,
        "def": 40,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47371
    },
    {
        "idnpc": 12108,
        "idmap": 11401,
        "ten_map": "Dinh Mat Vi Son",
        "thanh": "Thanh Châu",
        "ten_npc": "Lữu Nhi",
        "level": 74,
        "hp": 601,
        "sp": 278,
        "hpx": 36,
        "spx": 12,
        "int": 22,
        "atk": 45,
        "def": 51,
        "agi": 8,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47363
    },
    {
        "idnpc": 14082,
        "idmap": 11582,
        "thanh": "Thanh Châu",
        "ten_npc": "Mộc Thuận",
        "level": 74,
        "hp": 564,
        "sp": 315,
        "hpx": 30,
        "spx": 18,
        "int": 18,
        "atk": 39,
        "def": 33,
        "agi": 35,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14116,
        "idmap": 11584,
        "thanh": "Thanh Châu",
        "ten_npc": "Vương Ma",
        "level": 74,
        "hp": 584,
        "sp": 302,
        "hpx": 33,
        "spx": 16,
        "int": 12,
        "atk": 51,
        "def": 25,
        "agi": 37,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14147,
        "idmap": 11585,
        "thanh": "Thanh Châu",
        "ten_npc": "Lữ Uy Hoàng",
        "level": 74,
        "hp": 527,
        "sp": 313,
        "hpx": 30,
        "spx": 18,
        "int": 11,
        "atk": 51,
        "def": 32,
        "agi": 31,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14173,
        "idmap": 11802,
        "ten_map": "Rung Dong Quan 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Âm Tuần",
        "level": 74,
        "hp": 564,
        "sp": 290,
        "hpx": 30,
        "spx": 14,
        "int": 18,
        "atk": 17,
        "def": 33,
        "agi": 32,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14185,
        "idmap": 11811,
        "ten_map": "Rung Don Khuu",
        "thanh": "Thanh Châu",
        "ten_npc": "Cao Cang",
        "level": 74,
        "hp": 554,
        "sp": 296,
        "hpx": 28,
        "spx": 15,
        "int": 13,
        "atk": 41,
        "def": 42,
        "agi": 34,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 47365
    },
    {
        "idnpc": 14233,
        "idmap": 11831,
        "ten_map": "Lang Ta Phi Lam 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Quách Tổ",
        "level": 74,
        "hp": 564,
        "sp": 312,
        "hpx": 30,
        "spx": 18,
        "int": 9,
        "atk": 48,
        "def": 41,
        "agi": 27,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14286,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Điền Âu Nghị",
        "level": 74,
        "hp": 625,
        "sp": 378,
        "hpx": 40,
        "spx": 29,
        "int": 25,
        "atk": 28,
        "def": 23,
        "agi": 28,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14318,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Triệu Cam",
        "level": 74,
        "hp": 564,
        "sp": 315,
        "hpx": 30,
        "spx": 18,
        "int": 20,
        "atk": 45,
        "def": 27,
        "agi": 33,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14456,
        "idmap": 12013,
        "ten_map": "Dia Nguyet KhVang",
        "thanh": "U Châu",
        "ten_npc": "Chúc Áo",
        "level": 74,
        "hp": 564,
        "sp": 331,
        "hpx": 30,
        "spx": 21,
        "int": 57,
        "atk": 18,
        "def": 24,
        "agi": 25,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14514,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Kim Tuyền",
        "level": 74,
        "hp": 501,
        "sp": 314,
        "hpx": 19,
        "spx": 18,
        "int": 61,
        "atk": 28,
        "def": 22,
        "agi": 27,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17164,
        "idmap": 12560,
        "thanh": "U Châu",
        "ten_npc": "Sơn Vệ Sĩ",
        "level": 74,
        "hp": 504,
        "sp": 244,
        "hpx": 32,
        "spx": 15,
        "int": 11,
        "atk": 30,
        "def": 29,
        "agi": 36,
        "item1": 27058,
        "item2": 26095,
        "item3": 26095,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17167,
        "idmap": 12562,
        "thanh": "U Châu",
        "ten_npc": "Kinh.Th.Binh",
        "level": 74,
        "hp": 510,
        "sp": 260,
        "hpx": 33,
        "spx": 18,
        "int": 8,
        "atk": 36,
        "def": 37,
        "agi": 20,
        "item1": 32006,
        "item2": 32006,
        "item3": 26011,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17383,
        "idmap": 12802,
        "ten_map": "Rung Cay ngoai o 1",
        "thanh": "U Châu",
        "ten_npc": "ThươngBinhHN",
        "level": 74,
        "hp": 564,
        "sp": 310,
        "hpx": 30,
        "spx": 17,
        "int": 4,
        "atk": 41,
        "def": 23,
        "agi": 33,
        "item1": 26010,
        "item2": 26070,
        "item3": 26010,
        "item4": 50001,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11111,
        "idmap": 10994,
        "ten_map": "Va Dai Dâu Va",
        "ten_npc": "Hạ Hầu Lan",
        "level": 75,
        "hp": 589,
        "sp": 304,
        "hpx": 33,
        "spx": 16,
        "int": 10,
        "atk": 48,
        "def": 27,
        "agi": 40,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12060,
        "idmap": 11222,
        "thanh": "Thanh Châu",
        "ten_npc": "Hướng Lang",
        "level": 75,
        "hp": 583,
        "sp": 310,
        "hpx": 32,
        "spx": 17,
        "int": 57,
        "atk": 27,
        "def": 23,
        "agi": 18,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47297
    },
    {
        "idnpc": 12088,
        "idmap": 11302,
        "thanh": "Thanh Châu",
        "ten_npc": "Lưu Diễm",
        "level": 75,
        "hp": 542,
        "sp": 263,
        "hpx": 25,
        "spx": 9,
        "int": 52,
        "atk": 50,
        "def": 12,
        "agi": 27,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12131,
        "idmap": 11423,
        "ten_map": "Lang Ta Cung",
        "thanh": "Thanh Châu",
        "ten_npc": "Lưu Cán",
        "level": 75,
        "hp": 672,
        "sp": 322,
        "hpx": 47,
        "spx": 19,
        "int": 55,
        "atk": 6,
        "def": 23,
        "agi": 25,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12132,
        "idmap": 11424,
        "ten_map": "N.Dinh Cat",
        "thanh": "Thanh Châu",
        "ten_npc": "Đỗ Kỳ",
        "level": 75,
        "hp": 577,
        "sp": 392,
        "hpx": 31,
        "spx": 31,
        "int": 60,
        "atk": 11,
        "def": 18,
        "agi": 24,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12144,
        "idmap": 11501,
        "ten_map": "Son Dong Thai Son 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Dương Lực",
        "level": 75,
        "hp": 625,
        "sp": 322,
        "hpx": 20,
        "spx": 19,
        "int": 60,
        "atk": 65,
        "def": 15,
        "agi": 40,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13073,
        "idmap": 11529,
        "ten_map": "Son Dong Thai Son 27",
        "thanh": "Thanh Châu",
        "ten_npc": "Lạc Thống",
        "level": 75,
        "hp": 542,
        "sp": 351,
        "hpx": 25,
        "spx": 24,
        "int": 48,
        "atk": 24,
        "def": 21,
        "agi": 32,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47208
    },
    {
        "idnpc": 13075,
        "idmap": 11532,
        "ten_map": "Dong Quy Than 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Từ Kiều",
        "level": 75,
        "hp": 548,
        "sp": 263,
        "hpx": 26,
        "spx": 9,
        "int": 47,
        "atk": 51,
        "def": 17,
        "agi": 24,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13097,
        "idmap": 11542,
        "ten_map": "Dong Quy Than 12",
        "thanh": "Thanh Châu",
        "ten_npc": "Thành Đương",
        "level": 75,
        "hp": 589,
        "sp": 310,
        "hpx": 33,
        "spx": 17,
        "int": 8,
        "atk": 54,
        "def": 21,
        "agi": 41,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14011,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Hồ Phong",
        "level": 75,
        "hp": 572,
        "sp": 317,
        "hpx": 30,
        "spx": 18,
        "int": 13,
        "atk": 28,
        "def": 27,
        "agi": 31,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14243,
        "idmap": 11841,
        "ten_map": "Rung Vi Son 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Vương Doanh",
        "level": 75,
        "hp": 554,
        "sp": 339,
        "hpx": 27,
        "spx": 22,
        "int": 7,
        "atk": 50,
        "def": 27,
        "agi": 41,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14316,
        "idmap": 12001,
        "ten_map": "Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Đai Cang",
        "level": 75,
        "hp": 572,
        "sp": 320,
        "hpx": 30,
        "spx": 19,
        "int": 23,
        "atk": 48,
        "def": 25,
        "agi": 30,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14335,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Diêm Tượng",
        "level": 75,
        "hp": 577,
        "sp": 322,
        "hpx": 31,
        "spx": 19,
        "int": 49,
        "atk": 15,
        "def": 35,
        "agi": 26,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47272
    },
    {
        "idnpc": 14405,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Hòang Uyển",
        "level": 75,
        "hp": 589,
        "sp": 328,
        "hpx": 33,
        "spx": 20,
        "int": 14,
        "atk": 55,
        "def": 21,
        "agi": 32,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14513,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Bào Long",
        "level": 75,
        "hp": 572,
        "sp": 281,
        "hpx": 30,
        "spx": 12,
        "int": 8,
        "atk": 61,
        "def": 20,
        "agi": 42,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14619,
        "idmap": 12061,
        "ten_map": "Diep Thanh Thanh Mon",
        "thanh": "U Châu",
        "ten_npc": "KhổngTướcnữthị",
        "level": 75,
        "hp": 491,
        "sp": 304,
        "hpx": 29,
        "spx": 16,
        "int": 42,
        "atk": 9,
        "def": 25,
        "agi": 29,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14651,
        "idmap": 12063,
        "ten_map": "Hoang Cung Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Chu Bao",
        "level": 75,
        "hp": 668,
        "sp": 275,
        "hpx": 40,
        "spx": 11,
        "int": 20,
        "atk": 55,
        "def": 20,
        "agi": 33,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15127,
        "idmap": 12093,
        "ten_map": "DaiDien Khong Tuoc",
        "thanh": "U Châu",
        "ten_npc": "Khuyết Tuyên",
        "level": 75,
        "hp": 534,
        "sp": 269,
        "hpx": 30,
        "spx": 19,
        "int": 29,
        "atk": 35,
        "def": 31,
        "agi": 31,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15221,
        "idmap": 12139,
        "ten_map": "N.Quach Gia TQ",
        "thanh": "U Châu",
        "ten_npc": "Thổ Khố Lý",
        "level": 75,
        "hp": 534,
        "sp": 262,
        "hpx": 30,
        "spx": 18,
        "int": 4,
        "atk": 51,
        "def": 44,
        "agi": 26,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17151,
        "idmap": 12549,
        "thanh": "U Châu",
        "ten_npc": "Tr.Đao Binh",
        "level": 75,
        "hp": 572,
        "sp": 314,
        "hpx": 30,
        "spx": 18,
        "int": 5,
        "atk": 38,
        "def": 32,
        "agi": 25,
        "item1": 32007,
        "item2": 26093,
        "item3": 32007,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17165,
        "idmap": 12561,
        "thanh": "U Châu",
        "ten_npc": "Sơn Nữ",
        "level": 75,
        "hp": 485,
        "sp": 270,
        "hpx": 28,
        "spx": 19,
        "int": 32,
        "atk": 8,
        "def": 30,
        "agi": 38,
        "item1": 27058,
        "item2": 43043,
        "item3": 43059,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17166,
        "idmap": 12562,
        "thanh": "U Châu",
        "ten_npc": "Lữo Ẩn Sĩ",
        "level": 75,
        "hp": 497,
        "sp": 269,
        "hpx": 30,
        "spx": 19,
        "int": 30,
        "atk": 11,
        "def": 29,
        "agi": 36,
        "item1": 27058,
        "item2": 43043,
        "item3": 26095,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17330,
        "idmap": 12703,
        "ten_map": "QuanTruong Huong Dung",
        "thanh": "U Châu",
        "ten_npc": "Binh.Gi.lăng",
        "level": 75,
        "hp": 572,
        "sp": 262,
        "hpx": 30,
        "spx": 18,
        "int": 5,
        "atk": 37,
        "def": 35,
        "agi": 23,
        "item1": 26247,
        "item2": 26233,
        "item3": 26233,
        "item4": 34008,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17364,
        "idmap": 12801,
        "ten_map": "Phi Chi Lam",
        "thanh": "U Châu",
        "ten_npc": "Kinh Châu Binh",
        "level": 75,
        "hp": 572,
        "sp": 315,
        "hpx": 30,
        "spx": 18,
        "int": 7,
        "atk": 34,
        "def": 33,
        "agi": 26,
        "item1": 27075,
        "item2": 27075,
        "item3": 27075,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17381,
        "idmap": 12802,
        "ten_map": "Rung Cay ngoai o 1",
        "thanh": "U Châu",
        "ten_npc": "ChủBiênPhòng",
        "level": 75,
        "hp": 572,
        "sp": 314,
        "hpx": 30,
        "spx": 18,
        "int": 5,
        "atk": 37,
        "def": 34,
        "agi": 24,
        "item1": 26069,
        "item2": 27045,
        "item3": 27045,
        "item4": 39018,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10032,
        "idmap": 10805,
        "ten_npc": "Ngô Bá",
        "level": 76,
        "hp": 542,
        "sp": 265,
        "hpx": 30,
        "spx": 9,
        "int": 6,
        "atk": 66,
        "def": 37,
        "agi": 29,
        "item1": 26035,
        "item2": 27049,
        "item3": 26090,
        "item4": 0,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 10077,
        "idmap": 10813,
        "ten_npc": "Quách Thạch",
        "level": 76,
        "hp": 533,
        "sp": 301,
        "hpx": 29,
        "spx": 15,
        "int": 17,
        "atk": 47,
        "def": 36,
        "agi": 33,
        "item1": 27057,
        "item2": 26018,
        "item3": 26035,
        "item4": 0,
        "item5": 49002,
        "item6": 0
    },
    {
        "idnpc": 11076,
        "idmap": 10991,
        "ten_map": "Loi Dai Doan P",
        "ten_npc": "Chung Diều",
        "level": 76,
        "hp": 580,
        "sp": 332,
        "hpx": 30,
        "spx": 20,
        "int": 53,
        "atk": 22,
        "def": 29,
        "agi": 21,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47242
    },
    {
        "idnpc": 11122,
        "idmap": 10997,
        "ten_npc": "Vương Tùng",
        "level": 76,
        "hp": 580,
        "sp": 320,
        "hpx": 30,
        "spx": 18,
        "int": 10,
        "atk": 57,
        "def": 26,
        "agi": 32,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11131,
        "idmap": 11000,
        "ten_map": "Thanh Chau",
        "thanh": "Thanh Châu",
        "ten_npc": "Tạ Kỳ",
        "level": 76,
        "hp": 542,
        "sp": 265,
        "hpx": 24,
        "spx": 9,
        "int": 9,
        "atk": 65,
        "def": 43,
        "agi": 23,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11148,
        "idmap": 11003,
        "ten_map": "Cân Giao Dông Quan",
        "thanh": "Thanh Châu",
        "ten_npc": "Đinh Phỉ",
        "level": 76,
        "hp": 524,
        "sp": 360,
        "hpx": 21,
        "spx": 25,
        "int": 57,
        "atk": 10,
        "def": 31,
        "agi": 32,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11150,
        "idmap": 11011,
        "ten_map": "Bac Hai Thanh Mon",
        "thanh": "Thanh Châu",
        "ten_npc": "Tào Vinh",
        "level": 76,
        "hp": 580,
        "sp": 312,
        "hpx": 30,
        "spx": 17,
        "int": 13,
        "atk": 56,
        "def": 21,
        "agi": 37,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12142,
        "idmap": 11432,
        "ten_map": "Dinh nui Thai Son",
        "thanh": "Thanh Châu",
        "ten_npc": "quan Đại Võ",
        "level": 76,
        "hp": 632,
        "sp": 324,
        "hpx": 20,
        "spx": 19,
        "int": 14,
        "atk": 55,
        "def": 56,
        "agi": 35,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13001,
        "idmap": 11502,
        "ten_map": "Son Dong Thai Son 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Đinh Phụng",
        "level": 76,
        "hp": 580,
        "sp": 277,
        "hpx": 30,
        "spx": 11,
        "int": 20,
        "atk": 54,
        "def": 25,
        "agi": 37,
        "item1": 27007,
        "item2": 26029,
        "item3": 27044,
        "item4": 0,
        "item5": 0,
        "item6": 47104
    },
    {
        "idnpc": 13028,
        "idmap": 11505,
        "ten_map": "Son Dong Thai Son 5",
        "thanh": "Thanh Châu",
        "ten_npc": "Tưởng Khăm",
        "level": 76,
        "hp": 580,
        "sp": 277,
        "hpx": 30,
        "spx": 11,
        "int": 26,
        "atk": 56,
        "def": 35,
        "agi": 19,
        "item1": 27048,
        "item2": 26029,
        "item3": 27038,
        "item4": 0,
        "item5": 0,
        "item6": 47077
    },
    {
        "idnpc": 13049,
        "idmap": 11517,
        "ten_map": "Son Dong Thai Son 15",
        "thanh": "Thanh Châu",
        "ten_npc": "Tôn Phấn",
        "level": 76,
        "hp": 528,
        "sp": 322,
        "hpx": 30,
        "spx": 19,
        "int": 18,
        "atk": 47,
        "def": 30,
        "agi": 33,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13109,
        "idmap": 11552,
        "thanh": "Thanh Châu",
        "ten_npc": "Đại Võ Quan",
        "level": 76,
        "hp": 636,
        "sp": 306,
        "hpx": 40,
        "spx": 16,
        "int": 48,
        "atk": 25,
        "def": 15,
        "agi": 31,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47326
    },
    {
        "idnpc": 13110,
        "idmap": 11554,
        "thanh": "Thanh Châu",
        "ten_npc": "Đại Võ Quan",
        "level": 76,
        "hp": 607,
        "sp": 283,
        "hpx": 35,
        "spx": 12,
        "int": 50,
        "atk": 19,
        "def": 45,
        "agi": 24,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47327
    },
    {
        "idnpc": 14006,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Chu Tiến",
        "level": 76,
        "hp": 524,
        "sp": 295,
        "hpx": 21,
        "spx": 14,
        "int": 28,
        "atk": 42,
        "def": 37,
        "agi": 35,
        "item1": 26051,
        "item2": 26082,
        "item3": 26003,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14026,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Cửu Chính",
        "level": 76,
        "hp": 565,
        "sp": 306,
        "hpx": 28,
        "spx": 16,
        "int": 39,
        "atk": 41,
        "def": 29,
        "agi": 24,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14154,
        "idmap": 11587,
        "ten_map": "Mo Khoang Dong Quan 7",
        "thanh": "Thanh Châu",
        "ten_npc": "Địch đại bác",
        "level": 76,
        "hp": 542,
        "sp": 360,
        "hpx": 24,
        "spx": 25,
        "int": 48,
        "atk": 17,
        "def": 39,
        "agi": 21,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14165,
        "idmap": 11801,
        "ten_map": "Rung Dong Quan 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Vương Hoành",
        "level": 76,
        "hp": 580,
        "sp": 320,
        "hpx": 30,
        "spx": 18,
        "int": 10,
        "atk": 23,
        "def": 36,
        "agi": 34,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14174,
        "idmap": 11802,
        "ten_map": "Rung Dong Quan 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Ngô Tu",
        "level": 76,
        "hp": 542,
        "sp": 324,
        "hpx": 24,
        "spx": 19,
        "int": 25,
        "atk": 26,
        "def": 27,
        "agi": 25,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14218,
        "idmap": 11823,
        "thanh": "Thanh Châu",
        "ten_npc": "Hà Tiến",
        "level": 76,
        "hp": 580,
        "sp": 323,
        "hpx": 30,
        "spx": 19,
        "int": 22,
        "atk": 36,
        "def": 38,
        "agi": 32,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14242,
        "idmap": 11841,
        "ten_map": "Rung Vi Son 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Tùng Tiền",
        "level": 76,
        "hp": 589,
        "sp": 312,
        "hpx": 32,
        "spx": 17,
        "int": 8,
        "atk": 52,
        "def": 28,
        "agi": 37,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14271,
        "idmap": 12000,
        "ten_map": "U Chau",
        "thanh": "U Châu",
        "ten_npc": "Tữ Tông",
        "level": 76,
        "hp": 580,
        "sp": 332,
        "hpx": 30,
        "spx": 20,
        "int": 52,
        "atk": 17,
        "def": 28,
        "agi": 29,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47318
    },
    {
        "idnpc": 14395,
        "idmap": 12011,
        "ten_map": "Thanh Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Lưu Hổ",
        "level": 76,
        "hp": 668,
        "sp": 289,
        "hpx": 39,
        "spx": 13,
        "int": 16,
        "atk": 26,
        "def": 43,
        "agi": 40,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14457,
        "idmap": 12013,
        "ten_map": "Dia Nguyet KhVang",
        "thanh": "U Châu",
        "ten_npc": "Vệ Cố",
        "level": 76,
        "hp": 565,
        "sp": 336,
        "hpx": 28,
        "spx": 21,
        "int": 16,
        "atk": 51,
        "def": 34,
        "agi": 24,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14556,
        "idmap": 12023,
        "ten_map": "Diem Binh Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Trương Mẫn",
        "level": 76,
        "hp": 580,
        "sp": 253,
        "hpx": 30,
        "spx": 7,
        "int": 54,
        "atk": 41,
        "def": 21,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14586,
        "idmap": 12042,
        "ten_map": "Rung Thuong Son",
        "thanh": "U Châu",
        "ten_npc": "Lưu Hùng",
        "level": 76,
        "hp": 583,
        "sp": 295,
        "hpx": 31,
        "spx": 14,
        "int": 8,
        "atk": 40,
        "def": 55,
        "agi": 25,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14649,
        "idmap": 12063,
        "ten_map": "Hoang Cung Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Ngạc Hoán",
        "level": 76,
        "hp": 692,
        "sp": 318,
        "hpx": 43,
        "spx": 18,
        "int": 18,
        "atk": 53,
        "def": 22,
        "agi": 43,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17123,
        "idmap": 12537,
        "thanh": "U Châu",
        "ten_npc": "Duyệt.Ch.Binh",
        "level": 76,
        "hp": 504,
        "sp": 269,
        "hpx": 30,
        "spx": 19,
        "int": 20,
        "atk": 40,
        "def": 23,
        "agi": 25,
        "item1": 27009,
        "item2": 27009,
        "item3": 26116,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17124,
        "idmap": 12537,
        "thanh": "U Châu",
        "ten_npc": "Quan.Tr.Binh",
        "level": 76,
        "hp": 507,
        "sp": 266,
        "hpx": 31,
        "spx": 18,
        "int": 8,
        "atk": 41,
        "def": 27,
        "agi": 32,
        "item1": 27009,
        "item2": 27009,
        "item3": 26116,
        "item4": 41008,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17150,
        "idmap": 12549,
        "thanh": "U Châu",
        "ten_npc": "Mộc Giáp Sĩ",
        "level": 76,
        "hp": 580,
        "sp": 318,
        "hpx": 30,
        "spx": 18,
        "int": 6,
        "atk": 39,
        "def": 31,
        "agi": 24,
        "item1": 26093,
        "item2": 32007,
        "item3": 26093,
        "item4": 33017,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17331,
        "idmap": 12704,
        "ten_map": "Binh Truong Huong Dung",
        "thanh": "U Châu",
        "ten_npc": "Binh Nam Quận",
        "level": 76,
        "hp": 580,
        "sp": 318,
        "hpx": 30,
        "spx": 18,
        "int": 4,
        "atk": 38,
        "def": 33,
        "agi": 25,
        "item1": 26233,
        "item2": 26247,
        "item3": 26247,
        "item4": 40008,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17365,
        "idmap": 12801,
        "ten_map": "Phi Chi Lam",
        "thanh": "U Châu",
        "ten_npc": "Th.Dương Binh",
        "level": 76,
        "hp": 580,
        "sp": 318,
        "hpx": 30,
        "spx": 18,
        "int": 5,
        "atk": 34,
        "def": 35,
        "agi": 26,
        "item1": 32024,
        "item2": 32024,
        "item3": 32024,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17428,
        "idmap": 12807,
        "ten_map": "Rung Cay ngoai O 3",
        "thanh": "U Châu",
        "ten_npc": "Thủy Binh",
        "level": 76,
        "hp": 601,
        "sp": 295,
        "hpx": 34,
        "spx": 14,
        "int": 5,
        "atk": 46,
        "def": 22,
        "agi": 27,
        "item1": 26233,
        "item2": 26233,
        "item3": 26233,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10078,
        "idmap": 10813,
        "ten_npc": "Châu Triều",
        "level": 77,
        "hp": 549,
        "sp": 325,
        "hpx": 31,
        "spx": 19,
        "int": 14,
        "atk": 48,
        "def": 35,
        "agi": 32,
        "item1": 26159,
        "item2": 26081,
        "item3": 26007,
        "item4": 0,
        "item5": 49002,
        "item6": 0
    },
    {
        "idnpc": 10248,
        "idmap": 10951,
        "ten_npc": "Cự Sa Dũng Phu",
        "level": 77,
        "hp": 660,
        "sp": 250,
        "hpx": 43,
        "spx": 6,
        "int": 5,
        "atk": 47,
        "def": 46,
        "agi": 6,
        "item1": 26128,
        "item2": 26128,
        "item3": 26200,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11008,
        "idmap": 10964,
        "ten_npc": "Thi Mã Chiêu",
        "level": 77,
        "hp": 648,
        "sp": 273,
        "hpx": 41,
        "spx": 10,
        "int": 60,
        "atk": 7,
        "def": 27,
        "agi": 36,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11146,
        "idmap": 11003,
        "ten_map": "Cân Giao Dông Quan",
        "thanh": "Thanh Châu",
        "ten_npc": "Đổng kỷ",
        "level": 77,
        "hp": 648,
        "sp": 255,
        "hpx": 41,
        "spx": 7,
        "int": 23,
        "atk": 52,
        "def": 34,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11156,
        "idmap": 11011,
        "ten_map": "Bac Hai Thanh Mon",
        "thanh": "Thanh Châu",
        "ten_npc": "Vi Khang",
        "level": 77,
        "hp": 559,
        "sp": 315,
        "hpx": 26,
        "spx": 17,
        "int": 57,
        "atk": 17,
        "def": 31,
        "agi": 31,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47357
    },
    {
        "idnpc": 11194,
        "idmap": 11013,
        "ten_map": "Rung Bac Hai",
        "thanh": "Thanh Châu",
        "ten_npc": "Đinh Uy",
        "level": 77,
        "hp": 547,
        "sp": 339,
        "hpx": 24,
        "spx": 21,
        "int": 63,
        "atk": 15,
        "def": 23,
        "agi": 35,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12004,
        "idmap": 11031,
        "thanh": "Thanh Châu",
        "ten_npc": "Y Tịch",
        "level": 77,
        "hp": 565,
        "sp": 350,
        "hpx": 27,
        "spx": 23,
        "int": 55,
        "atk": 20,
        "def": 24,
        "agi": 30,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47201
    },
    {
        "idnpc": 12032,
        "idmap": 11041,
        "thanh": "Thanh Châu",
        "ten_npc": "Triệu Lôi",
        "level": 77,
        "hp": 515,
        "sp": 327,
        "hpx": 25,
        "spx": 19,
        "int": 58,
        "atk": 18,
        "def": 35,
        "agi": 26,
        "item1": 27036,
        "item2": 27056,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 47278
    },
    {
        "idnpc": 12061,
        "idmap": 11223,
        "thanh": "Thanh Châu",
        "ten_npc": "Tô Phi",
        "level": 77,
        "hp": 587,
        "sp": 324,
        "hpx": 31,
        "spx": 19,
        "int": 12,
        "atk": 52,
        "def": 31,
        "agi": 35,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12101,
        "idmap": 11307,
        "ten_map": "Dong Quan Quan Phu",
        "thanh": "Thanh Châu",
        "ten_npc": "Tuần Mật",
        "level": 77,
        "hp": 565,
        "sp": 315,
        "hpx": 27,
        "spx": 17,
        "int": 59,
        "atk": 8,
        "def": 26,
        "agi": 44,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47335
    },
    {
        "idnpc": 12120,
        "idmap": 11411,
        "ten_map": "Khuc Trac Khong Mieu",
        "thanh": "Thanh Châu",
        "ten_npc": "Kích Lộc",
        "level": 77,
        "hp": 518,
        "sp": 338,
        "hpx": 19,
        "spx": 21,
        "int": 26,
        "atk": 46,
        "def": 44,
        "agi": 23,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12143,
        "idmap": 11501,
        "ten_map": "Son Dong Thai Son 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Dương Kình",
        "level": 77,
        "hp": 811,
        "sp": 321,
        "hpx": 49,
        "spx": 18,
        "int": 59,
        "atk": 10,
        "def": 16,
        "agi": 62,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13050,
        "idmap": 11518,
        "ten_map": "Son Dong Thai Son 16",
        "thanh": "Thanh Châu",
        "ten_npc": "Tôn Phụ",
        "level": 77,
        "hp": 595,
        "sp": 326,
        "hpx": 31,
        "spx": 19,
        "int": 19,
        "atk": 46,
        "def": 29,
        "agi": 35,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14191,
        "idmap": 11811,
        "ten_map": "Rung Don Khuu",
        "thanh": "Thanh Châu",
        "ten_npc": "Dư Thị Cân",
        "level": 77,
        "hp": 587,
        "sp": 321,
        "hpx": 31,
        "spx": 18,
        "int": 3,
        "atk": 59,
        "def": 36,
        "agi": 32,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14245,
        "idmap": 11841,
        "ten_map": "Rung Vi Son 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Y Khải",
        "level": 77,
        "hp": 612,
        "sp": 303,
        "hpx": 35,
        "spx": 15,
        "int": 53,
        "atk": 18,
        "def": 23,
        "agi": 34,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47364
    },
    {
        "idnpc": 14339,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Tần Dực",
        "level": 77,
        "hp": 612,
        "sp": 303,
        "hpx": 35,
        "spx": 15,
        "int": 11,
        "atk": 54,
        "def": 32,
        "agi": 32,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14345,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Tôn Hương",
        "level": 77,
        "hp": 636,
        "sp": 291,
        "hpx": 39,
        "spx": 13,
        "int": 10,
        "atk": 54,
        "def": 28,
        "agi": 35,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14394,
        "idmap": 12011,
        "ten_map": "Thanh Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Hòang Xạ",
        "level": 77,
        "hp": 657,
        "sp": 297,
        "hpx": 36,
        "spx": 14,
        "int": 11,
        "atk": 43,
        "def": 26,
        "agi": 49,
        "item1": 26034,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14407,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Lầu Ban",
        "level": 77,
        "hp": 686,
        "sp": 297,
        "hpx": 41,
        "spx": 14,
        "int": 19,
        "atk": 51,
        "def": 25,
        "agi": 29,
        "item1": 26002,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14512,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Trần Ứng",
        "level": 77,
        "hp": 559,
        "sp": 322,
        "hpx": 26,
        "spx": 18,
        "int": 6,
        "atk": 61,
        "def": 21,
        "agi": 46,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14587,
        "idmap": 12042,
        "ten_map": "Rung Thuong Son",
        "thanh": "U Châu",
        "ten_npc": "Cận Phú",
        "level": 77,
        "hp": 595,
        "sp": 315,
        "hpx": 32,
        "spx": 17,
        "int": 10,
        "atk": 42,
        "def": 53,
        "agi": 24,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17066,
        "idmap": 12521,
        "ten_map": "S.Dong KhVang 1",
        "thanh": "U Châu",
        "ten_npc": "TâyLĐao Binh",
        "level": 77,
        "hp": 510,
        "sp": 275,
        "hpx": 31,
        "spx": 19,
        "int": 28,
        "atk": 30,
        "def": 26,
        "agi": 25,
        "item1": 26158,
        "item2": 27004,
        "item3": 26066,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17162,
        "idmap": 12558,
        "thanh": "U Châu",
        "ten_npc": "Sơn.Tr.Cư Sĩ",
        "level": 77,
        "hp": 510,
        "sp": 275,
        "hpx": 31,
        "spx": 19,
        "int": 28,
        "atk": 22,
        "def": 34,
        "agi": 25,
        "item1": 27009,
        "item2": 27009,
        "item3": 26116,
        "item4": 40008,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17214,
        "idmap": 12582,
        "thanh": "U Châu",
        "ten_npc": "Quân Sư",
        "level": 77,
        "hp": 510,
        "sp": 269,
        "hpx": 31,
        "spx": 18,
        "int": 9,
        "atk": 51,
        "def": 26,
        "agi": 43,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17367,
        "idmap": 12801,
        "ten_map": "Phi Chi Lam",
        "thanh": "U Châu",
        "ten_npc": "Khoái.Th.Binh",
        "level": 77,
        "hp": 587,
        "sp": 323,
        "hpx": 31,
        "spx": 18,
        "int": 7,
        "atk": 36,
        "def": 35,
        "agi": 26,
        "item1": 26131,
        "item2": 26131,
        "item3": 26131,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10017,
        "idmap": 10803,
        "ten_npc": "Quản Hợi",
        "level": 78,
        "hp": 526,
        "sp": 334,
        "hpx": 26,
        "spx": 20,
        "int": 32,
        "atk": 38,
        "def": 35,
        "agi": 30,
        "item1": 27038,
        "item2": 27058,
        "item3": 26068,
        "item4": 0,
        "item5": 0,
        "item6": 47351
    },
    {
        "idnpc": 10249,
        "idmap": 10951,
        "ten_npc": "Cự Sa Binh",
        "level": 78,
        "hp": 595,
        "sp": 264,
        "hpx": 31,
        "spx": 8,
        "int": 11,
        "atk": 45,
        "def": 14,
        "agi": 45,
        "item1": 26200,
        "item2": 26200,
        "item3": 26128,
        "item4": 38007,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11115,
        "idmap": 10995,
        "ten_npc": "Lý Phù",
        "level": 78,
        "hp": 595,
        "sp": 340,
        "hpx": 31,
        "spx": 21,
        "int": 52,
        "atk": 13,
        "def": 25,
        "agi": 41,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47237
    },
    {
        "idnpc": 11153,
        "idmap": 11011,
        "ten_map": "Bac Hai Thanh Mon",
        "thanh": "Thanh Châu",
        "ten_npc": "Trịnh Hồn",
        "level": 78,
        "hp": 595,
        "sp": 335,
        "hpx": 31,
        "spx": 20,
        "int": 53,
        "atk": 14,
        "def": 23,
        "agi": 42,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47293
    },
    {
        "idnpc": 11193,
        "idmap": 11013,
        "ten_map": "Rung Bac Hai",
        "thanh": "Thanh Châu",
        "ten_npc": "Đinh Dị",
        "level": 78,
        "hp": 559,
        "sp": 335,
        "hpx": 25,
        "spx": 20,
        "int": 62,
        "atk": 14,
        "def": 25,
        "agi": 35,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12091,
        "idmap": 11304,
        "thanh": "Thanh Châu",
        "ten_npc": "Tôn Dự",
        "level": 78,
        "hp": 624,
        "sp": 311,
        "hpx": 36,
        "spx": 16,
        "int": 54,
        "atk": 18,
        "def": 36,
        "agi": 21,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47342
    },
    {
        "idnpc": 12107,
        "idmap": 11401,
        "ten_map": "Dinh Mat Vi Son",
        "thanh": "Thanh Châu",
        "ten_npc": "Vương Liên",
        "level": 78,
        "hp": 606,
        "sp": 287,
        "hpx": 33,
        "spx": 12,
        "int": 58,
        "atk": 14,
        "def": 32,
        "agi": 32,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47368
    },
    {
        "idnpc": 12127,
        "idmap": 11423,
        "ten_map": "Lang Ta Cung",
        "thanh": "Thanh Châu",
        "ten_npc": "Thiệu Vệ",
        "level": 78,
        "hp": 636,
        "sp": 293,
        "hpx": 38,
        "spx": 13,
        "int": 56,
        "atk": 12,
        "def": 24,
        "agi": 38,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12140,
        "idmap": 11432,
        "ten_map": "Dinh nui Thai Son",
        "thanh": "Thanh Châu",
        "ten_npc": "Dương Phát",
        "level": 78,
        "hp": 765,
        "sp": 305,
        "hpx": 40,
        "spx": 15,
        "int": 30,
        "atk": 56,
        "def": 37,
        "agi": 36,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12141,
        "idmap": 11432,
        "ten_map": "Dinh nui Thai Son",
        "thanh": "Thanh Châu",
        "ten_npc": "quan Đại Võ",
        "level": 78,
        "hp": 949,
        "sp": 335,
        "hpx": 71,
        "spx": 20,
        "int": 71,
        "atk": 16,
        "def": 11,
        "agi": 36,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13074,
        "idmap": 11531,
        "ten_map": "Dong Quy Than 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Ngô Xán",
        "level": 78,
        "hp": 582,
        "sp": 353,
        "hpx": 29,
        "spx": 23,
        "int": 52,
        "atk": 25,
        "def": 19,
        "agi": 33,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47193
    },
    {
        "idnpc": 13119,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Hạ Cảnh",
        "level": 78,
        "hp": 535,
        "sp": 281,
        "hpx": 21,
        "spx": 11,
        "int": 19,
        "atk": 58,
        "def": 49,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13140,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Chu Bình",
        "level": 78,
        "hp": 760,
        "sp": 274,
        "hpx": 26,
        "spx": 19,
        "int": 16,
        "atk": 67,
        "def": 20,
        "agi": 31,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14047,
        "idmap": 11563,
        "thanh": "Thanh Châu",
        "ten_npc": "Du Thiệp",
        "level": 78,
        "hp": 595,
        "sp": 328,
        "hpx": 31,
        "spx": 19,
        "int": 13,
        "atk": 37,
        "def": 47,
        "agi": 34,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14078,
        "idmap": 11582,
        "thanh": "Thanh Châu",
        "ten_npc": "Trường Nô",
        "level": 78,
        "hp": 595,
        "sp": 329,
        "hpx": 31,
        "spx": 19,
        "int": 16,
        "atk": 51,
        "def": 35,
        "agi": 29,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14101,
        "idmap": 11583,
        "thanh": "Thanh Châu",
        "ten_npc": "Thái Văn Cơ",
        "level": 78,
        "hp": 595,
        "sp": 339,
        "hpx": 31,
        "spx": 21,
        "int": 51,
        "atk": 13,
        "def": 30,
        "agi": 35,
        "item1": 27065,
        "item2": 27035,
        "item3": 26064,
        "item4": 48047,
        "item5": 0,
        "item6": 47041
    },
    {
        "idnpc": 14166,
        "idmap": 11801,
        "ten_map": "Rung Dong Quan 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Vương Thương",
        "level": 78,
        "hp": 595,
        "sp": 287,
        "hpx": 31,
        "spx": 12,
        "int": 12,
        "atk": 34,
        "def": 31,
        "agi": 33,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14184,
        "idmap": 11811,
        "ten_map": "Rung Don Khuu",
        "thanh": "Thanh Châu",
        "ten_npc": "Viên Thượng",
        "level": 78,
        "hp": 565,
        "sp": 323,
        "hpx": 26,
        "spx": 18,
        "int": 28,
        "atk": 41,
        "def": 33,
        "agi": 35,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 47269
    },
    {
        "idnpc": 14326,
        "idmap": 12002,
        "ten_map": "Dai Lo Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Y Lễ",
        "level": 78,
        "hp": 582,
        "sp": 335,
        "hpx": 29,
        "spx": 20,
        "int": 25,
        "atk": 46,
        "def": 28,
        "agi": 33,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14340,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Xích Ký",
        "level": 78,
        "hp": 624,
        "sp": 299,
        "hpx": 36,
        "spx": 14,
        "int": 12,
        "atk": 59,
        "def": 29,
        "agi": 31,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14344,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Huệ Cừ",
        "level": 78,
        "hp": 624,
        "sp": 299,
        "hpx": 36,
        "spx": 14,
        "int": 12,
        "atk": 55,
        "def": 30,
        "agi": 34,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14352,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Viên Diệu",
        "level": 78,
        "hp": 588,
        "sp": 335,
        "hpx": 30,
        "spx": 20,
        "int": 20,
        "atk": 45,
        "def": 32,
        "agi": 34,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14408,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Ô Nhiên",
        "level": 78,
        "hp": 693,
        "sp": 311,
        "hpx": 41,
        "spx": 16,
        "int": 17,
        "atk": 53,
        "def": 28,
        "agi": 26,
        "item1": 26002,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14424,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Hồng Minh",
        "level": 78,
        "hp": 636,
        "sp": 329,
        "hpx": 38,
        "spx": 19,
        "int": 19,
        "atk": 53,
        "def": 23,
        "agi": 29,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15215,
        "idmap": 12138,
        "ten_map": "Pho Ho Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Đạo Sĩ Ác",
        "level": 78,
        "hp": 556,
        "sp": 281,
        "hpx": 31,
        "spx": 20,
        "int": 40,
        "atk": 33,
        "def": 29,
        "agi": 27,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15342,
        "idmap": 12303,
        "ten_map": "Quan Phu BinhNguyen",
        "thanh": "U Châu",
        "ten_npc": "Thôn Nhân",
        "level": 78,
        "hp": 576,
        "sp": 299,
        "hpx": 28,
        "spx": 14,
        "int": 57,
        "atk": 14,
        "def": 25,
        "agi": 43,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17067,
        "idmap": 12523,
        "thanh": "U Châu",
        "ten_npc": "TâyLThuẫn Binh",
        "level": 78,
        "hp": 517,
        "sp": 276,
        "hpx": 31,
        "spx": 19,
        "int": 22,
        "atk": 38,
        "def": 24,
        "agi": 26,
        "item1": 26158,
        "item2": 27004,
        "item3": 26066,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17163,
        "idmap": 12559,
        "thanh": "U Châu",
        "ten_npc": "Sơn Trại Vương",
        "level": 78,
        "hp": 582,
        "sp": 221,
        "hpx": 42,
        "spx": 10,
        "int": 11,
        "atk": 42,
        "def": 31,
        "agi": 25,
        "item1": 27009,
        "item2": 27009,
        "item3": 26116,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17320,
        "idmap": 12702,
        "ten_map": "DaiTruong Huong Dung",
        "thanh": "U Châu",
        "ten_npc": "Binh Sấu Dân",
        "level": 78,
        "hp": 595,
        "sp": 326,
        "hpx": 31,
        "spx": 18,
        "int": 5,
        "atk": 34,
        "def": 33,
        "agi": 32,
        "item1": 27045,
        "item2": 26093,
        "item3": 27045,
        "item4": 43028,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17384,
        "idmap": 12803,
        "ten_map": "Rung Cay ngoai o 2",
        "thanh": "U Châu",
        "ten_npc": "Khiết Dũng Sĩ",
        "level": 78,
        "hp": 595,
        "sp": 326,
        "hpx": 31,
        "spx": 19,
        "int": 6,
        "atk": 37,
        "def": 39,
        "agi": 22,
        "item1": 26248,
        "item2": 26250,
        "item3": 26248,
        "item4": 39020,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11116,
        "idmap": 10996,
        "ten_npc": "Khiên Chiêu",
        "level": 79,
        "hp": 564,
        "sp": 379,
        "hpx": 25,
        "spx": 27,
        "int": 58,
        "atk": 14,
        "def": 32,
        "agi": 25,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47248
    },
    {
        "idnpc": 12079,
        "idmap": 11301,
        "ten_map": "Dong Quan Quan Phu",
        "thanh": "Thanh Châu",
        "ten_npc": "Lưu Ấp",
        "level": 79,
        "hp": 528,
        "sp": 325,
        "hpx": 19,
        "spx": 18,
        "int": 18,
        "atk": 52,
        "def": 26,
        "agi": 47,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12123,
        "idmap": 11411,
        "ten_map": "Khuc Trac Khong Mieu",
        "thanh": "Thanh Châu",
        "ten_npc": "Tập Trinh",
        "level": 79,
        "hp": 671,
        "sp": 355,
        "hpx": 43,
        "spx": 23,
        "int": 52,
        "atk": 14,
        "def": 24,
        "agi": 26,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47319
    },
    {
        "idnpc": 14073,
        "idmap": 11582,
        "thanh": "Thanh Châu",
        "ten_npc": "Võ An Quốc",
        "level": 79,
        "hp": 603,
        "sp": 290,
        "hpx": 32,
        "spx": 12,
        "int": 21,
        "atk": 51,
        "def": 36,
        "agi": 31,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 47090
    },
    {
        "idnpc": 14164,
        "idmap": 11801,
        "ten_map": "Rung Dong Quan 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Tống Dịch",
        "level": 79,
        "hp": 603,
        "sp": 331,
        "hpx": 32,
        "spx": 19,
        "int": 9,
        "atk": 35,
        "def": 37,
        "agi": 28,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14379,
        "idmap": 12007,
        "ten_map": "K.San Vui Lai",
        "thanh": "U Châu",
        "ten_npc": "Thương Thăng",
        "level": 79,
        "hp": 603,
        "sp": 336,
        "hpx": 32,
        "spx": 20,
        "int": 26,
        "atk": 49,
        "def": 35,
        "agi": 22,
        "item1": 26040,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47085
    },
    {
        "idnpc": 14396,
        "idmap": 12011,
        "ten_map": "Thanh Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Hàn Hy",
        "level": 79,
        "hp": 687,
        "sp": 301,
        "hpx": 39,
        "spx": 14,
        "int": 21,
        "atk": 56,
        "def": 27,
        "agi": 26,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14582,
        "idmap": 12041,
        "ten_map": "Thon Thuong Son",
        "thanh": "U Châu",
        "ten_npc": "Lữ Tịnh",
        "level": 79,
        "hp": 576,
        "sp": 272,
        "hpx": 27,
        "spx": 9,
        "int": 11,
        "atk": 41,
        "def": 51,
        "agi": 41,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14641,
        "idmap": 12062,
        "ten_map": "Dai Do Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Bạch Chí",
        "level": 79,
        "hp": 497,
        "sp": 343,
        "hpx": 27,
        "spx": 21,
        "int": 11,
        "atk": 61,
        "def": 42,
        "agi": 21,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14655,
        "idmap": 12063,
        "ten_map": "Hoang Cung Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "A Khoái Nôm",
        "level": 79,
        "hp": 790,
        "sp": 325,
        "hpx": 43,
        "spx": 18,
        "int": 50,
        "atk": 21,
        "def": 16,
        "agi": 36,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17068,
        "idmap": 12523,
        "thanh": "U Châu",
        "ten_npc": "TâyLThân Binh",
        "level": 79,
        "hp": 524,
        "sp": 279,
        "hpx": 32,
        "spx": 20,
        "int": 23,
        "atk": 30,
        "def": 25,
        "agi": 34,
        "item1": 26158,
        "item2": 27004,
        "item3": 26066,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17126,
        "idmap": 12537,
        "thanh": "U Châu",
        "ten_npc": "Phụ Viễn Binh",
        "level": 79,
        "hp": 524,
        "sp": 277,
        "hpx": 32,
        "spx": 19,
        "int": 14,
        "atk": 38,
        "def": 24,
        "agi": 36,
        "item1": 27011,
        "item2": 26030,
        "item3": 26030,
        "item4": 0,
        "item5": 24029,
        "item6": 0
    },
    {
        "idnpc": 17129,
        "idmap": 12539,
        "thanh": "U Châu",
        "ten_npc": "Bút Đao Tướng",
        "level": 79,
        "hp": 524,
        "sp": 282,
        "hpx": 32,
        "spx": 20,
        "int": 8,
        "atk": 42,
        "def": 27,
        "agi": 34,
        "item1": 27011,
        "item2": 26030,
        "item3": 26030,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17321,
        "idmap": 12702,
        "ten_map": "DaiTruong Huong Dung",
        "thanh": "U Châu",
        "ten_npc": "Binh Nhược Dân",
        "level": 79,
        "hp": 603,
        "sp": 330,
        "hpx": 32,
        "spx": 19,
        "int": 6,
        "atk": 36,
        "def": 32,
        "agi": 30,
        "item1": 32007,
        "item2": 26093,
        "item3": 32007,
        "item4": 50002,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17366,
        "idmap": 12801,
        "ten_map": "Phi Chi Lam",
        "thanh": "U Châu",
        "ten_npc": "Tuần Giang Sử",
        "level": 79,
        "hp": 603,
        "sp": 330,
        "hpx": 32,
        "spx": 19,
        "int": 6,
        "atk": 37,
        "def": 34,
        "agi": 27,
        "item1": 26198,
        "item2": 26198,
        "item3": 26198,
        "item4": 35008,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17386,
        "idmap": 12803,
        "ten_map": "Rung Cay ngoai o 2",
        "thanh": "U Châu",
        "ten_npc": "ĐaoBinhHungNô",
        "level": 79,
        "hp": 603,
        "sp": 330,
        "hpx": 32,
        "spx": 19,
        "int": 6,
        "atk": 38,
        "def": 37,
        "agi": 23,
        "item1": 26248,
        "item2": 26250,
        "item3": 26248,
        "item4": 39019,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10041,
        "idmap": 10807,
        "ten_npc": "Lính Phục Kích",
        "level": 80,
        "hp": 611,
        "sp": 333,
        "hpx": 32,
        "spx": 19,
        "int": 4,
        "atk": 32,
        "def": 31,
        "agi": 41,
        "item1": 27072,
        "item2": 26106,
        "item3": 26106,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10095,
        "idmap": 10816,
        "ten_npc": "Đại Yêu Sư",
        "level": 80,
        "hp": 506,
        "sp": 291,
        "hpx": 21,
        "spx": 21,
        "int": 50,
        "atk": 21,
        "def": 30,
        "agi": 27,
        "item1": 26029,
        "item2": 26115,
        "item3": 26007,
        "item4": 36010,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10161,
        "idmap": 10869,
        "ten_npc": "Ngưu Giác",
        "level": 80,
        "hp": 611,
        "sp": 292,
        "hpx": 32,
        "spx": 12,
        "int": 15,
        "atk": 54,
        "def": 40,
        "agi": 42,
        "item1": 27067,
        "item2": 27068,
        "item3": 26088,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10250,
        "idmap": 10951,
        "ten_npc": "Thủy Đạo Ma",
        "level": 80,
        "hp": 611,
        "sp": 268,
        "hpx": 32,
        "spx": 8,
        "int": 51,
        "atk": 7,
        "def": 28,
        "agi": 34,
        "item1": 26136,
        "item2": 26136,
        "item3": 26201,
        "item4": 35030,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11174,
        "idmap": 11011,
        "ten_map": "Bac Hai Thanh Mon",
        "thanh": "Thanh Châu",
        "ten_npc": "Giải Yên",
        "level": 80,
        "hp": 623,
        "sp": 298,
        "hpx": 34,
        "spx": 13,
        "int": 39,
        "atk": 58,
        "def": 17,
        "agi": 29,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13070,
        "idmap": 11529,
        "ten_map": "Son Dong Thai Son 27",
        "thanh": "Thanh Châu",
        "ten_npc": "Trình Tư",
        "level": 80,
        "hp": 789,
        "sp": 322,
        "hpx": 35,
        "spx": 17,
        "int": 17,
        "atk": 49,
        "def": 26,
        "agi": 46,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47271
    },
    {
        "idnpc": 13152,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Trần Thời",
        "level": 80,
        "hp": 585,
        "sp": 298,
        "hpx": 41,
        "spx": 13,
        "int": 17,
        "atk": 60,
        "def": 15,
        "agi": 39,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14059,
        "idmap": 11581,
        "thanh": "Thanh Châu",
        "ten_npc": "Lưu Ngai",
        "level": 80,
        "hp": 611,
        "sp": 346,
        "hpx": 32,
        "spx": 21,
        "int": 48,
        "atk": 19,
        "def": 36,
        "agi": 39,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 14144,
        "idmap": 11585,
        "thanh": "Thanh Châu",
        "ten_npc": "Tuy.Ng.Tiến",
        "level": 80,
        "hp": 552,
        "sp": 399,
        "hpx": 22,
        "spx": 30,
        "int": 12,
        "atk": 57,
        "def": 34,
        "agi": 35,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14167,
        "idmap": 11802,
        "ten_map": "Rung Dong Quan 2",
        "thanh": "Thanh Châu",
        "ten_npc": "Hồ Mẫu Ban",
        "level": 80,
        "hp": 611,
        "sp": 280,
        "hpx": 32,
        "spx": 10,
        "int": 13,
        "atk": 49,
        "def": 31,
        "agi": 34,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14196,
        "idmap": 11811,
        "ten_map": "Rung Don Khuu",
        "thanh": "Thanh Châu",
        "ten_npc": "Trương yến",
        "level": 80,
        "hp": 611,
        "sp": 337,
        "hpx": 32,
        "spx": 20,
        "int": 17,
        "atk": 30,
        "def": 32,
        "agi": 59,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14330,
        "idmap": 12002,
        "ten_map": "Dai Lo Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Trịnh Thái",
        "level": 80,
        "hp": 571,
        "sp": 351,
        "hpx": 32,
        "spx": 22,
        "int": 65,
        "atk": 19,
        "def": 19,
        "agi": 33,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14550,
        "idmap": 12023,
        "ten_map": "Diem Binh Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Cao Phối",
        "level": 80,
        "hp": 651,
        "sp": 286,
        "hpx": 32,
        "spx": 11,
        "int": 21,
        "atk": 58,
        "def": 41,
        "agi": 27,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17069,
        "idmap": 12523,
        "thanh": "U Châu",
        "ten_npc": "TâyLTinh Binh",
        "level": 80,
        "hp": 571,
        "sp": 282,
        "hpx": 32,
        "spx": 20,
        "int": 20,
        "atk": 34,
        "def": 29,
        "agi": 35,
        "item1": 26158,
        "item2": 27004,
        "item3": 26066,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17132,
        "idmap": 12539,
        "thanh": "U Châu",
        "ten_npc": "Tịnh Viễn Binh",
        "level": 80,
        "hp": 531,
        "sp": 278,
        "hpx": 32,
        "spx": 19,
        "int": 9,
        "atk": 38,
        "def": 35,
        "agi": 26,
        "item1": 26030,
        "item2": 27011,
        "item3": 27011,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17183,
        "idmap": 12565,
        "thanh": "U Châu",
        "ten_npc": "Trần Lưu Binh",
        "level": 80,
        "hp": 611,
        "sp": 328,
        "hpx": 32,
        "spx": 18,
        "int": 6,
        "atk": 42,
        "def": 39,
        "agi": 22,
        "item1": 26247,
        "item2": 26247,
        "item3": 26234,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17196,
        "idmap": 12571,
        "ten_map": "S.Dong KhVang 51",
        "thanh": "U Châu",
        "ten_npc": "Thi Nô Trưởng",
        "level": 80,
        "hp": 617,
        "sp": 316,
        "hpx": 33,
        "spx": 16,
        "int": 8,
        "atk": 43,
        "def": 41,
        "agi": 18,
        "item1": 27045,
        "item2": 27045,
        "item3": 32007,
        "item4": 50002,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17255,
        "idmap": 12588,
        "thanh": "U Châu",
        "ten_npc": "Trường Sử",
        "level": 80,
        "hp": 683,
        "sp": 298,
        "hpx": 44,
        "spx": 13,
        "int": 11,
        "atk": 48,
        "def": 35,
        "agi": 11,
        "item1": 27070,
        "item2": 27070,
        "item3": 32024,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17256,
        "idmap": 12588,
        "thanh": "U Châu",
        "ten_npc": "Quân Sư",
        "level": 80,
        "hp": 671,
        "sp": 334,
        "hpx": 42,
        "spx": 19,
        "int": 7,
        "atk": 47,
        "def": 32,
        "agi": 15,
        "item1": 26010,
        "item2": 26010,
        "item3": 26069,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17385,
        "idmap": 12803,
        "ten_map": "Rung Cay ngoai o 2",
        "thanh": "U Châu",
        "ten_npc": "Khiết Trán Sĩ",
        "level": 80,
        "hp": 611,
        "sp": 333,
        "hpx": 32,
        "spx": 19,
        "int": 5,
        "atk": 42,
        "def": 41,
        "agi": 20,
        "item1": 26234,
        "item2": 26235,
        "item3": 26234,
        "item4": 36023,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17387,
        "idmap": 12803,
        "ten_map": "Rung Cay ngoai o 2",
        "thanh": "U Châu",
        "ten_npc": "ThủKíchHungNô",
        "level": 80,
        "hp": 611,
        "sp": 333,
        "hpx": 32,
        "spx": 19,
        "int": 5,
        "atk": 38,
        "def": 40,
        "agi": 25,
        "item1": 26234,
        "item2": 26235,
        "item3": 26234,
        "item4": 37971,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10162,
        "idmap": 10869,
        "ten_npc": "Bạch Chim",
        "level": 81,
        "hp": 619,
        "sp": 300,
        "hpx": 32,
        "spx": 13,
        "int": 27,
        "atk": 42,
        "def": 43,
        "agi": 40,
        "item1": 27067,
        "item2": 27058,
        "item3": 27068,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10251,
        "idmap": 10951,
        "ten_npc": "Hải Tặc Vu",
        "level": 81,
        "hp": 629,
        "sp": 276,
        "hpx": 34,
        "spx": 9,
        "int": 49,
        "atk": 7,
        "def": 27,
        "agi": 35,
        "item1": 26201,
        "item2": 26201,
        "item3": 26136,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11032,
        "idmap": 10966,
        "ten_npc": "Trương Vệ",
        "level": 81,
        "hp": 619,
        "sp": 346,
        "hpx": 32,
        "spx": 21,
        "int": 36,
        "atk": 51,
        "def": 30,
        "agi": 27,
        "item1": 26081,
        "item2": 27036,
        "item3": 27042,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11183,
        "idmap": 11012,
        "ten_map": "Dai Do Bac Hai",
        "thanh": "Thanh Châu",
        "ten_npc": "Thiệu Kình",
        "level": 81,
        "hp": 493,
        "sp": 348,
        "hpx": 45,
        "spx": 21,
        "int": 20,
        "atk": 66,
        "def": 20,
        "agi": 20,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12072,
        "idmap": 11244,
        "thanh": "Thanh Châu",
        "ten_npc": "Trương Nam",
        "level": 81,
        "hp": 700,
        "sp": 306,
        "hpx": 32,
        "spx": 14,
        "int": 10,
        "atk": 63,
        "def": 27,
        "agi": 44,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47299
    },
    {
        "idnpc": 12133,
        "idmap": 11424,
        "ten_map": "N.Dinh Cat",
        "thanh": "Thanh Châu",
        "ten_npc": "Nhiệm Dưỡng",
        "level": 81,
        "hp": 646,
        "sp": 402,
        "hpx": 30,
        "spx": 30,
        "int": 7,
        "atk": 70,
        "def": 19,
        "agi": 36,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13048,
        "idmap": 11516,
        "ten_map": "Son Dong Thai Son 14",
        "thanh": "Thanh Châu",
        "ten_npc": "Hạ Tề",
        "level": 81,
        "hp": 855,
        "sp": 277,
        "hpx": 32,
        "spx": 20,
        "int": 16,
        "atk": 54,
        "def": 39,
        "agi": 31,
        "item1": 26099,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47182
    },
    {
        "idnpc": 13141,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Bộ Hoàng",
        "level": 81,
        "hp": 814,
        "sp": 301,
        "hpx": 31,
        "spx": 23,
        "int": 75,
        "atk": 17,
        "def": 15,
        "agi": 32,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13153,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Đới Lương",
        "level": 81,
        "hp": 538,
        "sp": 336,
        "hpx": 32,
        "spx": 19,
        "int": 23,
        "atk": 62,
        "def": 20,
        "agi": 34,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14060,
        "idmap": 11581,
        "thanh": "Thanh Châu",
        "ten_npc": "Vương Xương",
        "level": 81,
        "hp": 619,
        "sp": 341,
        "hpx": 32,
        "spx": 20,
        "int": 20,
        "atk": 33,
        "def": 38,
        "agi": 30,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14221,
        "idmap": 11823,
        "thanh": "Thanh Châu",
        "ten_npc": "Trương Siêu",
        "level": 81,
        "hp": 619,
        "sp": 339,
        "hpx": 32,
        "spx": 20,
        "int": 12,
        "atk": 55,
        "def": 37,
        "agi": 41,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14247,
        "idmap": 11841,
        "ten_map": "Rung Vi Son 1",
        "thanh": "Thanh Châu",
        "ten_npc": "Tữ Hộc",
        "level": 81,
        "hp": 592,
        "sp": 408,
        "hpx": 21,
        "spx": 31,
        "int": 54,
        "atk": 24,
        "def": 28,
        "agi": 32,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47285
    },
    {
        "idnpc": 14324,
        "idmap": 12002,
        "ten_map": "Dai Lo Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Tôn Quan",
        "level": 81,
        "hp": 547,
        "sp": 342,
        "hpx": 27,
        "spx": 20,
        "int": 21,
        "atk": 51,
        "def": 35,
        "agi": 38,
        "item1": 26075,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14510,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Hình Đạo Vinh",
        "level": 81,
        "hp": 580,
        "sp": 354,
        "hpx": 19,
        "spx": 22,
        "int": 6,
        "atk": 71,
        "def": 48,
        "agi": 23,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47338
    },
    {
        "idnpc": 14516,
        "idmap": 12015,
        "ten_map": "N.Quan Vu TQ",
        "thanh": "U Châu",
        "ten_npc": "Dương Linh",
        "level": 81,
        "hp": 628,
        "sp": 336,
        "hpx": 27,
        "spx": 19,
        "int": 21,
        "atk": 58,
        "def": 39,
        "agi": 26,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14639,
        "idmap": 12062,
        "ten_map": "Dai Do Diep Thanh",
        "thanh": "U Châu",
        "ten_npc": "Định Hưng",
        "level": 81,
        "hp": 688,
        "sp": 318,
        "hpx": 37,
        "spx": 16,
        "int": 16,
        "atk": 72,
        "def": 31,
        "agi": 20,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17185,
        "idmap": 12567,
        "thanh": "U Châu",
        "ten_npc": "UyểnThànhBinh",
        "level": 81,
        "hp": 629,
        "sp": 294,
        "hpx": 34,
        "spx": 12,
        "int": 7,
        "atk": 47,
        "def": 35,
        "agi": 24,
        "item1": 26248,
        "item2": 26248,
        "item3": 32024,
        "item4": 33032,
        "item5": 49001,
        "item6": 0
    },
    {
        "idnpc": 17254,
        "idmap": 12588,
        "thanh": "U Châu",
        "ten_npc": "Tham Mưu",
        "level": 81,
        "hp": 641,
        "sp": 306,
        "hpx": 36,
        "spx": 14,
        "int": 9,
        "atk": 43,
        "def": 40,
        "agi": 18,
        "item1": 27075,
        "item2": 27075,
        "item3": 26233,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10163,
        "idmap": 10870,
        "ten_npc": "Tôn Khanh",
        "level": 82,
        "hp": 556,
        "sp": 314,
        "hpx": 21,
        "spx": 15,
        "int": 29,
        "atk": 49,
        "def": 39,
        "agi": 46,
        "item1": 26058,
        "item2": 26029,
        "item3": 26115,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11012,
        "idmap": 10965,
        "ten_npc": "Thân Nghi",
        "level": 82,
        "hp": 816,
        "sp": 368,
        "hpx": 30,
        "spx": 24,
        "int": 47,
        "atk": 12,
        "def": 25,
        "agi": 54,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11058,
        "idmap": 10983,
        "ten_npc": "Trần Kiều",
        "level": 82,
        "hp": 627,
        "sp": 359,
        "hpx": 33,
        "spx": 22,
        "int": 65,
        "atk": 17,
        "def": 26,
        "agi": 36,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47226
    },
    {
        "idnpc": 11083,
        "idmap": 10992,
        "ten_map": "Vung dat bang gia 1",
        "ten_npc": "Lý Thông",
        "level": 82,
        "hp": 605,
        "sp": 314,
        "hpx": 29,
        "spx": 15,
        "int": 25,
        "atk": 51,
        "def": 39,
        "agi": 38,
        "item1": 26039,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47144
    },
    {
        "idnpc": 11102,
        "idmap": 10994,
        "ten_map": "Va Dai Dâu Va",
        "ten_npc": "Tiên Du Phụ",
        "level": 82,
        "hp": 665,
        "sp": 314,
        "hpx": 39,
        "spx": 15,
        "int": 20,
        "atk": 57,
        "def": 36,
        "agi": 26,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47210
    },
    {
        "idnpc": 11195,
        "idmap": 11013,
        "ten_map": "Rung Bac Hai",
        "thanh": "Thanh Châu",
        "ten_npc": "Vô Khâu Hưng",
        "level": 82,
        "hp": 375,
        "sp": 350,
        "hpx": 25,
        "spx": 21,
        "int": 47,
        "atk": 62,
        "def": 14,
        "agi": 23,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12007,
        "idmap": 11031,
        "thanh": "Thanh Châu",
        "ten_npc": "Ngô Lan",
        "level": 82,
        "hp": 617,
        "sp": 326,
        "hpx": 31,
        "spx": 17,
        "int": 27,
        "atk": 54,
        "def": 12,
        "agi": 51,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47362
    },
    {
        "idnpc": 12013,
        "idmap": 11041,
        "thanh": "Thanh Châu",
        "ten_npc": "Khương Vĩ",
        "level": 82,
        "hp": 586,
        "sp": 326,
        "hpx": 26,
        "spx": 17,
        "int": 19,
        "atk": 61,
        "def": 17,
        "agi": 52,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47346
    },
    {
        "idnpc": 12035,
        "idmap": 11044,
        "thanh": "Thanh Châu",
        "ten_npc": "Đặng Chi",
        "level": 82,
        "hp": 682,
        "sp": 326,
        "hpx": 35,
        "spx": 17,
        "int": 58,
        "atk": 13,
        "def": 40,
        "agi": 30,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47317
    },
    {
        "idnpc": 12071,
        "idmap": 11243,
        "thanh": "Thanh Châu",
        "ten_npc": "Phùng Tập",
        "level": 82,
        "hp": 709,
        "sp": 302,
        "hpx": 33,
        "spx": 13,
        "int": 12,
        "atk": 62,
        "def": 29,
        "agi": 42,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47277
    },
    {
        "idnpc": 12083,
        "idmap": 11301,
        "ten_map": "Dong Quan Quan Phu",
        "thanh": "Thanh Châu",
        "ten_npc": "Tinh Phu",
        "level": 82,
        "hp": 646,
        "sp": 338,
        "hpx": 29,
        "spx": 19,
        "int": 8,
        "atk": 60,
        "def": 20,
        "agi": 55,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 12121,
        "idmap": 11411,
        "ten_map": "Khuc Trac Khong Mieu",
        "thanh": "Thanh Châu",
        "ten_npc": "Mễ Uy",
        "level": 82,
        "hp": 633,
        "sp": 332,
        "hpx": 27,
        "spx": 18,
        "int": 20,
        "atk": 72,
        "def": 27,
        "agi": 27,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 13089,
        "idmap": 11536,
        "ten_map": "Dong Quy Than 6",
        "thanh": "Thanh Châu",
        "ten_npc": "Châu Tuấn",
        "level": 82,
        "hp": 597,
        "sp": 354,
        "hpx": 21,
        "spx": 22,
        "int": 48,
        "atk": 60,
        "def": 15,
        "agi": 27,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47291
    },
    {
        "idnpc": 13105,
        "idmap": 11544,
        "ten_map": "Dong Quy Than 14",
        "thanh": "Thanh Châu",
        "ten_npc": "Đại Võ Quan",
        "level": 82,
        "hp": 629,
        "sp": 290,
        "hpx": 33,
        "spx": 11,
        "int": 48,
        "atk": 58,
        "def": 15,
        "agi": 27,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47330
    },
    {
        "idnpc": 14012,
        "idmap": 11561,
        "thanh": "Thanh Châu",
        "ten_npc": "Tống Hiến",
        "level": 82,
        "hp": 627,
        "sp": 344,
        "hpx": 33,
        "spx": 20,
        "int": 16,
        "atk": 61,
        "def": 36,
        "agi": 33,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14056,
        "idmap": 11581,
        "thanh": "Thanh Châu",
        "ten_npc": "Trần Lan",
        "level": 82,
        "hp": 627,
        "sp": 342,
        "hpx": 33,
        "spx": 20,
        "int": 8,
        "atk": 70,
        "def": 39,
        "agi": 30,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14068,
        "idmap": 11581,
        "thanh": "Thanh Châu",
        "ten_npc": "Vương Phương",
        "level": 82,
        "hp": 627,
        "sp": 345,
        "hpx": 33,
        "spx": 20,
        "int": 20,
        "atk": 38,
        "def": 31,
        "agi": 33,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14090,
        "idmap": 11583,
        "thanh": "Thanh Châu",
        "ten_npc": "Hàn Xiêm",
        "level": 82,
        "hp": 745,
        "sp": 342,
        "hpx": 33,
        "spx": 20,
        "int": 8,
        "atk": 56,
        "def": 36,
        "agi": 42,
        "item1": 26056,
        "item2": 27003,
        "item3": 27061,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14187,
        "idmap": 11811,
        "ten_map": "Rung Don Khuu",
        "thanh": "Thanh Châu",
        "ten_npc": "Phùng Kỷ",
        "level": 82,
        "hp": 627,
        "sp": 360,
        "hpx": 33,
        "spx": 23,
        "int": 70,
        "atk": 11,
        "def": 32,
        "agi": 31,
        "item1": 27022,
        "item2": 26033,
        "item3": 26078,
        "item4": 0,
        "item5": 0,
        "item6": 47121
    },
    {
        "idnpc": 14194,
        "idmap": 11811,
        "ten_map": "Rung Don Khuu",
        "thanh": "Thanh Châu",
        "ten_npc": "Uông Siêu",
        "level": 82,
        "hp": 586,
        "sp": 393,
        "hpx": 26,
        "spx": 28,
        "int": 14,
        "atk": 55,
        "def": 29,
        "agi": 40,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14323,
        "idmap": 12002,
        "ten_map": "Dai Lo Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Tôn Khang",
        "level": 82,
        "hp": 527,
        "sp": 347,
        "hpx": 23,
        "spx": 20,
        "int": 26,
        "atk": 49,
        "def": 38,
        "agi": 38,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14337,
        "idmap": 12003,
        "ten_map": "Quang Truong Trac Quan",
        "thanh": "U Châu",
        "ten_npc": "Lương Cang",
        "level": 82,
        "hp": 692,
        "sp": 320,
        "hpx": 37,
        "spx": 16,
        "int": 19,
        "atk": 59,
        "def": 41,
        "agi": 22,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14374,
        "idmap": 12004,
        "ten_map": "Nha Truong Phi",
        "thanh": "U Châu",
        "ten_npc": "Châu Triết",
        "level": 82,
        "hp": 586,
        "sp": 332,
        "hpx": 33,
        "spx": 18,
        "int": 12,
        "atk": 64,
        "def": 31,
        "agi": 35,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14397,
        "idmap": 12011,
        "ten_map": "Thanh Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Lưu Bàn",
        "level": 82,
        "hp": 742,
        "sp": 290,
        "hpx": 45,
        "spx": 11,
        "int": 17,
        "atk": 64,
        "def": 30,
        "agi": 27,
        "item1": 26041,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14450,
        "idmap": 12012,
        "ten_map": "Te Dan Cu Loc",
        "thanh": "U Châu",
        "ten_npc": "Nghiêm Kính",
        "level": 82,
        "hp": 641,
        "sp": 338,
        "hpx": 35,
        "spx": 19,
        "int": 10,
        "atk": 58,
        "def": 29,
        "agi": 41,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 14503,
        "idmap": 12014,
        "thanh": "U Châu",
        "ten_npc": "Các Bình",
        "level": 82,
        "hp": 646,
        "sp": 380,
        "hpx": 29,
        "spx": 26,
        "int": 58,
        "atk": 12,
        "def": 28,
        "agi": 40,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47289
    },
    {
        "idnpc": 14555,
        "idmap": 12023,
        "ten_map": "Diem Binh Binh Nguyen",
        "thanh": "U Châu",
        "ten_npc": "Hướng Tồn",
        "level": 82,
        "hp": 694,
        "sp": 332,
        "hpx": 37,
        "spx": 18,
        "int": 57,
        "atk": 25,
        "def": 23,
        "agi": 33,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 15143,
        "idmap": 12095,
        "ten_map": "Tang Cao Khong Tuoc Dai",
        "thanh": "U Châu",
        "ten_npc": "Tà Thôn Dân",
        "level": 82,
        "hp": 1880,
        "sp": 366,
        "hpx": 23,
        "spx": 10,
        "int": 5,
        "atk": 56,
        "def": 18,
        "agi": 48,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 47374
    },
    {
        "idnpc": 17133,
        "idmap": 12539,
        "thanh": "U Châu",
        "ten_npc": "Bố Lỗ",
        "level": 82,
        "hp": 586,
        "sp": 287,
        "hpx": 33,
        "spx": 20,
        "int": 17,
        "atk": 35,
        "def": 33,
        "agi": 36,
        "item1": 26094,
        "item2": 26094,
        "item3": 27010,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17257,
        "idmap": 12588,
        "thanh": "U Châu",
        "ten_npc": "Tham Quân",
        "level": 82,
        "hp": 641,
        "sp": 290,
        "hpx": 35,
        "spx": 11,
        "int": 9,
        "atk": 44,
        "def": 45,
        "agi": 17,
        "item1": 26131,
        "item2": 26131,
        "item3": 26198,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17326,
        "idmap": 12703,
        "ten_map": "QuanTruong Huong Dung",
        "thanh": "U Châu",
        "ten_npc": "Th.Lâu Ẩn Sĩ",
        "level": 82,
        "hp": 627,
        "sp": 350,
        "hpx": 33,
        "spx": 21,
        "int": 35,
        "atk": 25,
        "def": 23,
        "agi": 25,
        "item1": 32008,
        "item2": 27011,
        "item3": 32008,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 17567,
        "idmap": 12825,
        "ten_map": "Rung Lau Song 1",
        "thanh": "U Châu",
        "ten_npc": "Tinh Đấu Sĩ",
        "level": 82,
        "hp": 653,
        "sp": 326,
        "hpx": 37,
        "spx": 17,
        "int": 10,
        "atk": 45,
        "def": 39,
        "agi": 26,
        "item1": 26069,
        "item2": 26104,
        "item3": 26069,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10040,
        "idmap": 10806,
        "ten_npc": "Tiêu Võ Sĩ",
        "level": 83,
        "hp": 635,
        "sp": 344,
        "hpx": 33,
        "spx": 20,
        "int": 3,
        "atk": 47,
        "def": 36,
        "agi": 26,
        "item1": 26106,
        "item2": 27072,
        "item3": 27072,
        "item4": 36009,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 10164,
        "idmap": 10870,
        "ten_npc": "Vương Đang",
        "level": 83,
        "hp": 635,
        "sp": 317,
        "hpx": 33,
        "spx": 15,
        "int": 19,
        "atk": 53,
        "def": 49,
        "agi": 32,
        "item1": 26090,
        "item2": 26068,
        "item3": 27008,
        "item4": 0,
        "item5": 0,
        "item6": 0
    },
    {
        "idnpc": 11011,
        "idmap": 10965,
        "ten_npc": "Thân Thẩm",
        "level": 83,
        "hp": 816,
        "sp": 335,
        "hpx": 32,
        "spx": 18,
        "int": 18,
        "atk": 52,
        "def": 23,
        "agi": 54,
        "item1": 0,
        "item2": 0,
        "item3": 0,
        "item4": 0,
        "item5": 0,
        "item6": 0
    }
]