<div class="breadcrumb-area">
    <h1>Dashboard</h1>

    <ol class="breadcrumb">
        <li class="item"><a routerLink="/admin-dashboard"><i class='bx bx-home-alt'></i></a></li>

        <li class="item">Dashboard</li>

        <li class="item">Reviews</li>
    </ol>
</div>

<div class="card visitor-reviews-box mb-30">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3>Visitor Reviews</h3>

        <div class="dropdown">
            <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class='bx bx-dots-horizontal-rounded' ></i>
            </button>
            <div class="dropdown-menu">
                <a class="dropdown-item d-flex align-items-center" href="#">
                    <i class='bx bx-show'></i> View
                </a>
                <a class="dropdown-item d-flex align-items-center" href="#">
                    <i class='bx bx-edit-alt'></i> Edit
                </a>
                <a class="dropdown-item d-flex align-items-center" href="#">
                    <i class='bx bx-trash'></i> Delete
                </a>
                <a class="dropdown-item d-flex align-items-center" href="#">
                    <i class='bx bx-printer'></i> Print
                </a>
                <a class="dropdown-item d-flex align-items-center" href="#">
                    <i class='bx bx-download'></i> Download
                </a>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="user-review" *ngFor="let item of items">
            <div class="btn-box">
                <button type="button" class="btn btn-sm btn-primary">Reply</button>
                <button type="button" class="btn btn-sm btn-danger ml-3">Delete</button>
            </div>

            <img src="assets/img/default/admin.jpg" alt="image">
            
            <div class="review-rating">
                <div class="review-stars">
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                </div>

                <span class="d-inline-block">{{item.user.fullname}}</span>
            </div>

            <span class="d-block sub-comment">{{item.status}}</span>
            <p>{{item.details}}</p>
        </div>
    </div>
</div>