import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LISTING } from 'src/app/data/listing.data';
import { RealEstateService } from 'src/app/services/realestate.service';

declare let $: any;

@Component({
  selector: 'app-admin-listing-detail',
  templateUrl: './admin-listing-detail.component.html',
  styleUrls: ['./admin-listing-detail.component.scss']
})
export class AdminListingDetailComponent implements OnInit {

  isNewRecord = false
  isLoading = false
  isSubmit = false
  messageSuccess: any
  messageError: any
  data = LISTING
  SUBCATEGORY = LISTING.SubCategory
  STATUS = LISTING.Status
  CITY = LISTING.City
  relatedList: any
  item: any
  id: string;
  detailForm: any

  constructor(private route: ActivatedRoute, private titleService: Title, private api: RealEstateService) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.SUBCATEGORY = this.data.SubCategory;
    this.STATUS = this.data.Status;
    this.CITY = this.data.City;
    if(this.id == "-1"){
      this.isNewRecord = true;
      this.item = {};
      this.titleService.setTitle("MYLIFE | ADMIN | NEW");
      this.initForm();
    }
    else{
      this.fetch();
    }
  }

  fetch() {
    this.isLoading = true;
    this.api.SEARCH({ _id: this.id }).subscribe((res: any) => {
      if (res.success && res.results && res.results.length > 0) {
        this.item = res.results[0];
        this.titleService.setTitle("MYLIFE | ADMIN | " + this.item.title);
      }
      else {
        this.isNewRecord = true;
        this.item = {};
        this.titleService.setTitle("MYLIFE | ADMIN | NEW");
      }
      this.initForm();
      this.isLoading = false;
    }, err => {
      this.isNewRecord = true;
      this.item = {};
      this.titleService.setTitle("MYLIFE | ADMIN | NEW");
      this.initForm();
      this.isLoading = false;
    })

  }

  initForm() {
    this.detailForm = new FormGroup(
      {
        title: new FormControl(this.item.title, Validators.required),
        category: new FormControl('realestate'),
        subCategory: new FormControl(this.item.subCategory ? this.item.subCategory.toLowerCase() : ''),
        keywords: new FormControl(this.item.keywords),
        latitude: new FormControl(this.item.latitude),
        longitude: new FormControl(this.item.longitude),
        city: new FormControl(this.item.city),
        address: new FormControl(this.item.address),
        email: new FormControl(this.item.email),
        phone: new FormControl(this.item.phone),
        website: new FormControl(this.item.website),
        status: new FormControl(this.item.status),

        sidebarWidgetsInstagram: new FormControl(this.item.sidebarWidgetsInstagram),
        sidebarWidgetsPriceRange: new FormControl(this.item.sidebarWidgetsPriceRange),
        sidebarWidgetsBookingForm: new FormControl(this.item.sidebarWidgetsBookingForm),

        headerMedia: new FormControl(this.item.headerMedia ? this.item.headerMedi : "background-image"),
        headerMediaBGImage: new FormControl(this.item.headerMediaBGImage ? this.item.headerMediaBGImage : "/assets/img/listing/img1.jpg"),
        headerMediaCIImage: new FormControl(this.item.headerMediaCIImage ? this.item.headerMediaCIImage : "/assets/img/listing/img1.jpg"),
        headerMediaVideoYoutube: new FormControl(this.item.headerMediaVideoYoutube),
        headerMediaVideoVimeo: new FormControl(this.item.headerMediaVideoVimeo),

        detail: new FormControl(this.item.detail),
        price: new FormControl(this.item.price),
        priceRange: new FormControl(this.item.priceRange),

        facilitiesFreeWiFi: new FormControl(this.item.facilitiesFreeWiFi),
        facilitiesParking: new FormControl(this.item.facilitiesParking),
        facilitiesAirConditioning: new FormControl(this.item.facilitiesAirConditioning),
        facilitiesAirportShuttle: new FormControl(this.item.facilitiesAirportShuttle),
        facilitiesEvents: new FormControl(this.item.facilitiesEvents),
        facilitiesFriendlyWorkspace: new FormControl(this.item.facilitiesFriendlyWorkspace),
        facilitiesNonSmokingRooms: new FormControl(this.item.facilitiesNonSmokingRooms),
        facilitiesFitnessCenter: new FormControl(this.item.facilitiesFitnessCenter),

        contentWidgetsPromovideo: new FormControl(this.item.contentWidgetsPromovideo),
        contentWidgetsPromovideoYoutube: new FormControl(this.item.contentWidgetsPromovideoYoutube),
        contentWidgetsPromovideoVimeo: new FormControl(this.item.contentWidgetsPromovideoVimeo),
        contentWidgetsGalleryThumbnails: new FormControl(this.item.contentWidgetsGalleryThumbnails),
        contentWidgetsGalleryThumbnailsImage: new FormControl(this.item.contentWidgetsGalleryThumbnailsImage ? this.item.contentWidgetsGalleryThumbnailsImage : "/assets/img/listing/img1.jpg"),
        contentWidgetsSlider: new FormControl(this.item.contentWidgetsSlider),
        contentWidgetsSliderImage: new FormControl(this.item.contentWidgetsSliderImage ? this.item.contentWidgetsSliderImage : "/assets/img/listing/img1.jpg"),

        yourSocialsFacebook: new FormControl(this.item.yourSocialsFacebook),
        yourSocialsTwitter: new FormControl(this.item.yourSocialsTwitter),
        yourSocialsLinkedin: new FormControl(this.item.yourSocialsLinkedin),
        yourSocialsInstagram: new FormControl(this.item.yourSocialsInstagram),

        apiKey: new FormControl(this.item.apiKey),

        maps: new FormControl(this.item.maps)
      }
    );
  }

  onSubmit() {
    if(!this.detailForm.valid){
      this.messageError = "Title is required";
      return;
    }
    var val = this.detailForm.value;
    this.isSubmit = true;
    this.messageError = "";
    this.messageSuccess = "";
    var username = localStorage.getItem("username")
    if(this.isNewRecord){
      if(username)
        val.createdBy = username;
      this.api.CREATE(val).subscribe((res: any) => {
        this.isSubmit = false;
        this.messageSuccess = res.message;
      }, err => {
        this.isSubmit = false;
        this.messageError = err.error.error;
      })
    }
    else{
      if(username)
        val.modifiedBy = username;
      this.api.UPDATE(val, this.id).subscribe((res: any) => {
        this.isSubmit = false;
        this.messageSuccess = res.message;
      }, err => {
        this.isSubmit = false;
        this.messageError = err.error.error;
      })
    }
  }

  setMaps(){
    this.item.maps = this.detailForm.value.maps;
  }
}
