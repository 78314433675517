import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminComponent } from './admin.component';
import { AdminRoutingModule } from './admin-routing.module';
import { WebAnalyticsComponent } from './components/charts/web-analytics/web-analytics.component';
import { AdminFooterComponent } from './components/layouts/footer/footer.component';
import { AdminHeaderComponent } from './components/layouts/header/header.component';
import { SidebarComponent } from './components/layouts/sidebar/sidebar.component';
import { AppCalendarComponent } from './components/pages/app-calendar/app-calendar.component';
import { AppEmailComposeComponent } from './components/pages/app-email-compose/app-email-compose.component';
import { AppEmailReadComponent } from './components/pages/app-email-read/app-email-read.component';
import { AppEmailComponent } from './components/pages/app-email/app-email.component';
import { AppMessageComponent } from './components/pages/app-message/app-message.component';
import { AppTodoComponent } from './components/pages/app-todo/app-todo.component';
import { BookingsComponent } from './components/pages/bookings/bookings.component';
import { BookmarksComponent } from './components/pages/bookmarks/bookmarks.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { InvoiceComponent } from './components/pages/invoice/invoice.component';
import { MyListingComponent } from './components/pages/my-listing/my-listing.component';
import { MyProfileComponent } from './components/pages/my-profile/my-profile.component';
import { ReviewsComponent } from './components/pages/reviews/reviews.component';
import { SingleItemComponent } from '../single-item/single-item.component';
import { AdminListingComponent } from './admin-listing/admin-listing.component';
import { AdminListingDetailComponent } from './admin-listing-detail/admin-listing-detail.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SafePipe } from '../pipes/safe.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { AdminBookingComponent } from './admin-booking/admin-booking.component';
import { AdminReviewComponent } from './admin-review/admin-review.component';
import { AdminInvoiceComponent } from './admin-invoice/admin-invoice.component';
import { AdminBookmarkComponent } from './admin-bookmark/admin-bookmark.component';
import { AdminGamesComponent } from './admin-games/admin-games.component';
import { AdminGamesDetailComponent } from './admin-games-detail/admin-games-detail.component';
import { GameSingleItemComponent } from '../game-single-item/game-single-item.component';

@NgModule({
  declarations: [
    AdminComponent,
    AdminFooterComponent,
    AdminHeaderComponent,
    SidebarComponent,
    DashboardComponent,
    AppMessageComponent,
    AppEmailComponent,
    AppEmailReadComponent,
    AppEmailComposeComponent,
    AppTodoComponent,
    AppCalendarComponent,
    MyProfileComponent,
    InvoiceComponent,
    ReviewsComponent,
    MyListingComponent,
    BookmarksComponent,
    BookingsComponent,
    WebAnalyticsComponent,
    SingleItemComponent,
    GameSingleItemComponent,
    AdminListingComponent,
    AdminListingDetailComponent,
    SafePipe,
    AdminBookingComponent,
    AdminReviewComponent,
    AdminInvoiceComponent,
    AdminBookmarkComponent,
    AdminGamesComponent,
    AdminGamesDetailComponent
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule
  ],
  exports: [
    SingleItemComponent,
    GameSingleItemComponent,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule
  ],
  providers: [],
  bootstrap: [AdminComponent]
})
export class AdminModule { }
