import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { LISTING } from 'src/app/data/listing.data';
import { JwtHelperService } from '@auth0/angular-jwt';
import { RealEstateService } from 'src/app/services/realestate.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  settings = LISTING.Settings
  isSubmit = false;
  loginForm: FormGroup;
  errorMessage: any

  get f() { return this.loginForm.controls; }

  constructor(private fb: FormBuilder, private _location: Location, public jwtHelper: JwtHelperService, private api: RealEstateService) {
    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      username: [''],
      password: ['', Validators.required]
    });
  }
  ngOnInit(): void {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("username");
  }

  login() {
    this.errorMessage = "";
    if (this.loginForm.invalid) {
      this.errorMessage = "email or password is invalid"
      return;
    }
    var val = this.loginForm.value;
    val.username = val.email;
    this.isSubmit = true;
    this.api.getAuth(val).subscribe((res: any) => {
      this.isSubmit = false;
      var result = res.result;
      localStorage.setItem("token", result.token)
      localStorage.setItem("email", result.email)
      localStorage.setItem("username", result.username)
      this._location.back();
    }, error => {
      this.isSubmit = false;
      this.errorMessage = error.error.message;
    })
  }
}
