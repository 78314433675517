import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trial-of-ascension',
  templateUrl: './trial-of-ascension.component.html',
  styleUrls: ['./trial-of-ascension.component.scss']
})
export class TrialOfAscensionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
