<div class="dot-elastic" *ngIf="type == 'elastic'"></div>
<div class="dot-pulse" *ngIf="type == 'elastic'"></div>
<div class="dot-flashing" *ngIf="type == 'flashing'"></div>
<div class="dot-collision" *ngIf="type == 'collision'"></div>
<div class="dot-revolution" *ngIf="type == 'revolution'"></div>
<div class="dot-carousel" *ngIf="type == 'carousel'"></div>
<div class="dot-typing" *ngIf="type == 'typing'"></div>
<div class="dot-windmill" *ngIf="type == 'windmill'"></div>
<div class="dot-bricks" *ngIf="type == 'bricks'"></div>
<div class="dot-floating" *ngIf="type == 'floating'"></div>
<div class="dot-fire" *ngIf="type == 'fire'"></div>
<div class="dot-spin" *ngIf="type == 'spin'"></div>
<div class="dot-falling" *ngIf="type == 'falling'"></div>
<div class="dot-stretching" *ngIf="type == 'stretching'"></div>
<div class="dot-gathering" *ngIf="type == 'gathering'"></div>
<div class="dot-hourglass" *ngIf="type == 'hourglass'"></div>
<div class="dot-overtaking" *ngIf="type == 'overtaking'"></div>
<div class="dot-shuttle" *ngIf="type == 'shuttle'"></div>
<div class="dot-bouncing" *ngIf="type == 'bouncing'"></div>
<div class="dot-rolling" *ngIf="type == 'rolling'"></div>
