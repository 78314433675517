<footer class="admin-footer-area">
    <div class="row align-items-center">
        <div class="col-lg-6 col-sm-6 col-md-6">
            <p>© {{settings.name}} is Proudly Owned by <a [href]="settings.website" target="_blank">{{settings.owner}}</a></p>
        </div>

        <div class="col-lg-6 col-sm-6 col-md-6 text-end">
            <p>Designed by ❤️ <a [href]="settings.website" target="_blank">{{settings.owner}}</a></p>
        </div>
    </div>
</footer>