<div class="welcome-area">
    <div class="row m-0 align-items-center">
        <div class="col-lg-5 col-md-12 p-0">
            <div class="welcome-content">
                <h1 class="mb-2">Hi, Welcome Admin!</h1>
                <p class="mb-0">Just Do Somethings Better</p>
            </div>
        </div>

        <div class="col-lg-7 col-md-12 p-0">
            <div class="welcome-img">
                <img src="assets/img/welcome-img.png" alt="image">
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-lg-3 col-md-6">
        <div class="stats-card-box">
            <div class="icon-box">
                <i class='bx bx-map-alt'></i>
            </div>
            <span class="sub-title">Active Listing</span>
            <h3>25</h3>
        </div>
    </div>

    <div class="col-lg-3 col-md-6">
        <div class="stats-card-box">
            <div class="icon-box">
               <i class='bx bx-bar-chart-square'></i>
            </div>
            <span class="sub-title">Listing Views</span>
            <h3>1025</h3>
        </div>
    </div>

    <div class="col-lg-3 col-md-6">
        <div class="stats-card-box">
            <div class="icon-box">
                <i class='bx bx-comment-dots'></i>
            </div>
            <span class="sub-title">Total Reviews</span>
            <h3>88</h3>
        </div>
    </div>

    <div class="col-lg-3 col-md-6">
        <div class="stats-card-box">
            <div class="icon-box">
                <i class='bx bx-heart'></i>
            </div>
            <span class="sub-title">Total Bookmarked</span>
            <h3>700</h3>
        </div>
    </div>
</div>

<div class="card mb-30">
    <div class="card-body">
        <app-web-analytics></app-web-analytics>
    </div>
</div>

<div class="card recent-orders-box mb-30">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3>Recent Booking</h3>

        <div class="dropdown">
            <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class='bx bx-dots-horizontal-rounded' ></i>
            </button>
            <div class="dropdown-menu">
                <a class="dropdown-item d-flex align-items-center" href="#">
                    <i class='bx bx-show'></i> View
                </a>
                <a class="dropdown-item d-flex align-items-center" href="#">
                    <i class='bx bx-edit-alt'></i> Edit
                </a>
                <a class="dropdown-item d-flex align-items-center" href="#">
                    <i class='bx bx-trash'></i> Delete
                </a>
                <a class="dropdown-item d-flex align-items-center" href="#">
                    <i class='bx bx-printer'></i> Print
                </a>
                <a class="dropdown-item d-flex align-items-center" href="#">
                    <i class='bx bx-download'></i> Download
                </a>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Customer</th>
                        <th>Booking Date</th>
                        <th>Payment Type</th>
                        <th>Status</th>
                        <th>View Invoice</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>1</td>
                        <td class="name"><img src="assets/img/default/admin.jpg" alt="image"> Atony Rony</td>
                        <td>12 April 2020</td>
                        <td>Paypal</td>
                        <td><span class="badge badge-primary">Booked</span></td>
                        <td><button type="submit">View</button></td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td class="name"><img src="assets/img/default/admin.jpg" alt="image"> Tom Hardy</td>
                        <td>11 April 2020</td>
                        <td>Credit</td>
                        <td><span class="badge badge-success">Pending</span></td>
                        <td><button type="submit">View</button></td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td class="name"><img src="assets/img/default/admin.jpg" alt="image"> Colin Firth</td>
                        <td>10 April 2020</td>
                        <td>Paypal</td>
                        <td><span class="badge badge-primary">Booked</span></td>
                        <td><button type="submit">View</button></td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td class="name"><img src="assets/img/default/admin.jpg" alt="image"> Jude Law</td>
                        <td>09 April 2020</td>
                        <td>Paypal</td>
                        <td><span class="badge badge-success">Pending</span></td>
                        <td><button type="submit">View</button></td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td class="name"><img src="assets/img/default/admin.jpg" alt="image"> Idris Elba</td>
                        <td>08 April 2020</td>
                        <td>Credit</td>
                        <td><span class="badge badge-primary">Booked</span></td>
                        <td><button type="submit">View</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div class="card mb-30">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3>Recent Activities</h3>

        <div class="dropdown">
            <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class='bx bx-dots-horizontal-rounded'></i>
            </button>
            <div class="dropdown-menu">
                <a class="dropdown-item d-flex align-items-center" href="#">
                    <i class='bx bx-show'></i> View
                </a>
                <a class="dropdown-item d-flex align-items-center" href="#">
                    <i class='bx bx-edit-alt'></i> Edit
                </a>
                <a class="dropdown-item d-flex align-items-center" href="#">
                    <i class='bx bx-trash'></i> Delete
                </a>
                <a class="dropdown-item d-flex align-items-center" href="#">
                    <i class='bx bx-printer'></i> Print
                </a>
                <a class="dropdown-item d-flex align-items-center" href="#">
                    <i class='bx bx-download'></i> Download
                </a>
            </div>
        </div>
    </div>

    <div class="card-body widget-todo-list">
        <ul>
            <li>
                <div class="icon">
                    <i class='bx bx-map-alt'></i>
                </div>

                <div class="todo-item-title">
                    <h3>Your listing <a routerLink="">Andro Smith</a> has been approved!</h3>
                </div>

                <div class="todo-item-action">
                    <p><i class='bx bx-calendar'></i> 28 April 2020</p>
                    <a routerLink="" class="edit"><i class='bx bx-edit-alt'></i></a>
                    <a routerLink="" class="delete"><i class='bx bx-trash'></i></a>
                </div>
            </li>

            <li>
                <div class="icon">
                    <i class='bx bx-heart'></i>
                </div>

                <div class="todo-item-title">
                    <h3>Someone bookmarked your <a routerLink="">Louise Home</a> listing!</h3>
                </div>

                <div class="todo-item-action">
                    <p><i class='bx bx-calendar'></i> 27 April 2020</p>
                    <a routerLink="" class="edit"><i class='bx bx-edit-alt'></i></a>
                    <a routerLink="" class="delete"><i class='bx bx-trash'></i></a>
                </div>
            </li>

            <li>
                <div class="icon">
                    <i class='bx bx-map-alt'></i>
                </div>

                <div class="todo-item-title">
                    <h3>Your listing <a routerLink="">Louise Home</a> has been approved!</h3>
                </div>

                <div class="todo-item-action">
                    <p><i class='bx bx-calendar'></i> 16 April 2020</p>
                    <a routerLink="" class="edit"><i class='bx bx-edit-alt'></i></a>
                    <a routerLink="" class="delete"><i class='bx bx-trash'></i></a>
                </div>
            </li>

            <li>
                <div class="icon">
                    <i class='bx bx-heart'></i>
                </div>

                <div class="todo-item-title">
                    <h3>Someone bookmarked your <a routerLink="">Louise Moonlight</a> listing!</h3>
                </div>

                <div class="todo-item-action">
                    <p><i class='bx bx-calendar'></i> 24 April 2020</p>
                    <a routerLink="" class="edit"><i class='bx bx-edit-alt'></i></a>
                    <a routerLink="" class="delete"><i class='bx bx-trash'></i></a>
                </div>
            </li>

            <li>
                <div class="icon">
                    <i class='bx bx-star'></i>
                </div>

                <div class="todo-item-title">
                    <h3><a routerLink="">Alina</a> left a review 5 Star on <a routerLink="">Louise Home</a></h3>
                </div>

                <div class="todo-item-action">
                    <p><i class='bx bx-calendar'></i> 10 April 2020</p>
                    <a routerLink="" class="edit"><i class='bx bx-edit-alt'></i></a>
                    <a routerLink="" class="delete"><i class='bx bx-trash'></i></a>
                </div>
            </li>

            <li>
                <div class="icon">
                    <i class='bx bx-check-double'></i>
                </div>

                <div class="todo-item-title">
                    <h3>Your listing <a routerLink="">Andy Nguyễn</a> has been approved!</h3>
                </div>

                <div class="todo-item-action">
                    <p><i class='bx bx-calendar'></i> 09 April 2020</p>
                    <a routerLink="" class="edit"><i class='bx bx-edit-alt'></i></a>
                    <a routerLink="" class="delete"><i class='bx bx-trash'></i></a>
                </div>
            </li>
        </ul>
    </div>
</div>