<app-header-one></app-header-one>

<div class="page-title-area page-title-bg2">
    <div class="container">
        <div class="page-title-content">
            <h2>Listing</h2>
        </div>
    </div>
</div>

<section class="listing-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="listing-widget-area">
                    <div class="listing-widget filter-list-widget">
                        <h3 class="listing-widget-title">Current Selection</h3>

                        <div class="selected-filters-wrap-list">
                            <ul>
                                <li><a routerLink="/"><i class='bx bx-x'></i> Free WiFi</a></li>
                                <li><a routerLink="/"><i class='bx bx-x'></i> Shops</a></li>
                                <li><a routerLink="/"><i class='bx bx-x'></i> Hospital</a></li>
                                <li><a routerLink="/"><i class='bx bx-x'></i> Free Parking</a></li>
                            </ul>

                            <a routerLink="/" class="delete-selected-filters"><i class='bx bx-trash'></i> <span>Clear All</span></a>
                        </div>
                    </div>

                    <div class="listing-widget facilities-list-widget">
                        <h3 class="listing-widget-title">Facilities</h3>

                        <ul class="facilities-list-row">
                            <li><a routerLink="/">Free WiFi</a></li>
                            <li class="active"><a routerLink="/" class="active">Free Parking</a></li>
                            <li><a routerLink="/">Wireless Internet</a></li>
                            <li><a routerLink="/">Instant Book</a></li>
                            <li><a routerLink="/">Smoking Allowed</a></li>
                        </ul>
                    </div>

                    <div class="listing-widget price-list-widget">
                        <h3 class="listing-widget-title">Price</h3>

                        <div class="collection-filter-by-price">
                            <input class="js-range-of-price" type="text" data-min="0" data-max="1055" name="filter_by_price" data-step="10">
                        </div>
                    </div>

                    <div class="listing-widget categories-list-widget">
                        <h3 class="listing-widget-title">Categories</h3>

                        <ul class="categories-list-row">
                            <li><a routerLink="/">Shops</a></li>
                            <li><a routerLink="/">Hotels</a></li>
                            <li><a routerLink="/">Restaurants</a></li>
                            <li class="active"><a routerLink="/">Fitness</a></li>
                            <li><a routerLink="/">Events</a></li>
                            <li><a routerLink="/">Services</a></li>
                            <li><a routerLink="/">Hospital</a></li>
                            <li><a routerLink="/">Others</a></li>
                        </ul>
                    </div>

                    <div class="listing-widget aside-trending-widget">
                        <div class="aside-trending-products">
                            <img src="assets/img/listing/img1.jpg" alt="image">

                            <div class="category">
                                <h3>Top Listing</h3>
                                <span>Spring/Summer 2020 Collection</span>
                            </div>
                            <a routerLink="/listing-one" class="link-btn"></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="listing-filter-options">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <p>Showing 1 – 18 of 100</p>
                        </div>

                        <div class="col-lg-5">
                            <div class="listing-ordering-list">
                                <select>
                                    <option>Sort by Price: Low to High</option>
                                    <option>Default Sorting</option>
                                    <option>Sort by Popularity</option>
                                    <option>Sort by Average Rating</option>
                                    <option>Sort by Latest</option>
                                    <option>Sort by Price: High to Low</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-6 col-sm-12 col-md-6">
                        <div class="single-listing-item">
                            <div class="listing-image">
                                <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img5.jpg" alt="image"></a>

                                <div class="listing-tag">
                                    <a routerLink="/" class="d-block">Restaurant</a>
                                </div>

                                <div class="listing-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>

                                    <div class="rating-total">
                                        5.0 (1 reviews)
                                    </div>
                                </div>
                            </div>

                            <div class="listing-content">
                                <div class="listing-author d-flex align-items-center">
                                    <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Steven Smith</span>
                                </div>

                                <h3><a routerLink="/listing-details" class="d-inline-block">Farmis Garden Hotel & Restaurant</a></h3>

                                <span class="location"><i class="bx bx-map"></i> 40 Journal Square, NG USA</span>
                            </div>

                            <div class="listing-box-footer">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="price">
                                        <span data-bs-toggle="tooltip" data-placement="top" title="Ultra Hight">
                                            $1500 - $2000
                                        </span>
                                    </div>

                                    <div class="listing-option-list">
                                        <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                        <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                        <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                                    </div>
                                </div>
                            </div>

                            <div class="listing-badge">Open!</div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-12 col-md-6">
                        <div class="single-listing-item">
                            <div class="listing-image">
                                <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img6.jpg" alt="image"></a>

                                <div class="listing-tag">
                                    <a routerLink="/" class="d-block">Shopping</a>
                                </div>

                                <div class="listing-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>

                                    <div class="rating-total">
                                        5.0 (1 reviews)
                                    </div>
                                </div>
                            </div>

                            <div class="listing-content">
                                <div class="listing-author d-flex align-items-center">
                                    <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Sarah Taylor</span>
                                </div>

                                <h3><a routerLink="/listing-details" class="d-inline-block">Skyview Shopping Complex Center</a></h3>

                                <span class="location"><i class="bx bx-map"></i> 55 County Laois, Ireland</span>
                            </div>

                            <div class="listing-box-footer">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="price">
                                        <span data-bs-toggle="tooltip" data-placement="top" title="Pricey">
                                            $100 - $200
                                        </span>
                                    </div>

                                    <div class="listing-option-list">
                                        <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                        <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                        <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                                    </div>
                                </div>
                            </div>

                            <div class="listing-badge">Open!</div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-12 col-md-6">
                        <div class="single-listing-item">
                            <div class="listing-image">
                                <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img7.jpg" alt="image"></a>

                                <div class="listing-tag">
                                    <a routerLink="/" class="d-block">Gym</a>
                                </div>

                                <div class="listing-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>

                                    <div class="rating-total">
                                        4.5 (2 reviews)
                                    </div>
                                </div>
                            </div>

                            <div class="listing-content">
                                <div class="listing-author d-flex align-items-center">
                                    <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>James Andy</span>
                                </div>

                                <h3><a routerLink="/listing-details" class="d-inline-block">Power House Gym Training Center</a></h3>

                                <span class="location"><i class="bx bx-map"></i> Tilt Tilbury, United Kingdom</span>
                            </div>

                            <div class="listing-box-footer">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="price">
                                        <span data-bs-toggle="tooltip" data-placement="top" title="Moderate">
                                            $240 - $250
                                        </span>
                                    </div>

                                    <div class="listing-option-list">
                                        <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                        <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                        <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                                    </div>
                                </div>
                            </div>

                            <div class="listing-badge">Open!</div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-12 col-md-6">
                        <div class="single-listing-item">
                            <div class="listing-image">
                                <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img8.jpg" alt="image"></a>

                                <div class="listing-tag">
                                    <a routerLink="/" class="d-block">Restaurant</a>
                                </div>

                                <div class="listing-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>

                                    <div class="rating-total">
                                        5.0 (1 reviews)
                                    </div>
                                </div>
                            </div>

                            <div class="listing-content">
                                <div class="listing-author d-flex align-items-center">
                                    <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Steven Smith</span>
                                </div>

                                <h3><a routerLink="/listing-details" class="d-inline-block">The Magician Restaurant in USA</a></h3>

                                <span class="location"><i class="bx bx-map"></i> 40 Journal Square, NG USA</span>
                            </div>

                            <div class="listing-box-footer">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="price">
                                        <span data-bs-toggle="tooltip" data-placement="top" title="Ultra Hight">
                                            $1500 - $2000
                                        </span>
                                    </div>

                                    <div class="listing-option-list">
                                        <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                        <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                        <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                                    </div>
                                </div>
                            </div>

                            <div class="listing-badge">Open!</div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-12 col-md-6">
                        <div class="single-listing-item">
                            <div class="listing-image">
                                <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img9.jpg" alt="image"></a>

                                <div class="listing-tag">
                                    <a routerLink="/" class="d-block">Shopping</a>
                                </div>

                                <div class="listing-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>

                                    <div class="rating-total">
                                        5.0 (1 reviews)
                                    </div>
                                </div>
                            </div>

                            <div class="listing-content">
                                <div class="listing-author d-flex align-items-center">
                                    <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Sarah Taylor</span>
                                </div>

                                <h3><a routerLink="/listing-details" class="d-inline-block">New Oriental Shopping Center</a></h3>

                                <span class="location"><i class="bx bx-map"></i> 55 County Laois, Ireland</span>
                            </div>

                            <div class="listing-box-footer">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="price">
                                        <span data-bs-toggle="tooltip" data-placement="top" title="Pricey">
                                            $100 - $200
                                        </span>
                                    </div>

                                    <div class="listing-option-list">
                                        <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                        <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                        <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                                    </div>
                                </div>
                            </div>

                            <div class="listing-badge closed">Closed!</div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-12 col-md-6">
                        <div class="single-listing-item">
                            <div class="listing-image">
                                <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img10.jpg" alt="image"></a>

                                <div class="listing-tag">
                                    <a routerLink="/" class="d-block">Gym</a>
                                </div>

                                <div class="listing-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>

                                    <div class="rating-total">
                                        4.5 (2 reviews)
                                    </div>
                                </div>
                            </div>

                            <div class="listing-content">
                                <div class="listing-author d-flex align-items-center">
                                    <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>James Andy</span>
                                </div>

                                <h3><a routerLink="/listing-details" class="d-inline-block">Looks Women's Fitness Center</a></h3>

                                <span class="location"><i class="bx bx-map"></i> Tilt Tilbury, United Kingdom</span>
                            </div>

                            <div class="listing-box-footer">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="price">
                                        <span data-bs-toggle="tooltip" data-placement="top" title="Moderate">
                                            $240 - $250
                                        </span>
                                    </div>

                                    <div class="listing-option-list">
                                        <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                        <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                        <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                                    </div>
                                </div>
                            </div>

                            <div class="listing-badge">Open!</div>
                            
                            <span class="listing-discount">
                                <span>-15% OFF</span>
                            </span>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="pagination-area text-center">
                            <a routerLink="/listing-two" class="prev page-numbers"><i class='bx bx-chevron-left'></i></a>
                            <span class="page-numbers current" aria-current="page">1</span>
                            <a routerLink="/listing-two" class="page-numbers">2</a>
                            <a routerLink="/listing-two" class="page-numbers">3</a>
                            <a routerLink="/listing-two" class="page-numbers">4</a>
                            <a routerLink="/listing-two" class="page-numbers">5</a>
                            <a routerLink="/listing-two" class="next page-numbers"><i class='bx bx-chevron-right'></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>