import { Component, OnInit } from '@angular/core';
import { LISTING } from '../data/listing.data';
import { RealEstateService } from '../services/realestate.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  data: any = LISTING
  constructor(private api: RealEstateService) { }

  ngOnInit(): void {
    this.fetch();
  }

  fetch() {
    this.api.SEARCH({}).subscribe((res: any) => {
      this.data.MostPopularPlaces = res.results;
    }, err => {})
  }

  createFile(obj) {
    var a = document.createElement('a');
    a.setAttribute('href', 'data:text/plain;charset=utf-u,' + JSON.stringify(obj));
    a.setAttribute('download', '/assets/text.json');
    a.click();
  }
}
