export const AFKARENA = {
    CAUDONHACPOPTHOCA: [
        {
            "title": "Veithael dùng 'Tẩy sạch ác ý' sẽ khiến địch bị giảm?",
            "result": "Nhìn thấu và tinh thông",
            "day": 3
        },
        {
            "title": "Trong cốt truyện Mula, Thương nhân Taya thuộc tộc?",
            "result": "Người Hổ",
            "day": 3
        },
        {
            "title": "Tên cũ của tướng Salaki",
            "result": "Sivi",
            "day": 3
        },
        {
            "title": "Kỹ năng 'Lời trăn trối' của Lucretia sẽ theo đuổi?",
            "result": "Kẻ địch có lực tấn công cao nhất",
            "day": 3
        },
        {
            "title": "Trong liên mình Cây & Hoa, ám chỉ anh hùng nào?",
            "result": "Tasi - Ulmus",
            "day": 3
        },
        {
            "title": "A-Bel sẽ buff cho đồng mình nếu được đồng mình buff?",
            "result": "Phục hồi năng lượng",
            "day": 2
        },
        {
            "title": "Theo cốt truyện Canisa, Pháp sư loài người đá cố biến loài người thành?",
            "result": "Con người",
            "day": 2
        },
        {
            "title": "Ông nội của Rowan là?",
            "result": "Jarle",
            "day": 2
        },
        {
            "title": "Người đã hướng Palmer đi trên con đường phục vụ ánh sáng?",
            "result": "Giovanni",
            "day": 2
        },
        {
            "title": "Khi đầu chiến đấu, Kren đặt bao nhiêu quả mìn?",
            "result": "5",
            "day": 2
        },
        {
            "title": "'Đường mòn lối sống' từ 'Hoa hồi xuân' của Pét Líu Ríu giúp?",
            "result": "Nhận trị liệu",
            "day": 1
        },
        {
            "title": "Tướng không phải giáo sĩ đền thờ?",
            "result": "Oscar",
            "day": 1
        },
        {
            "title": "Danh tính của Veithael trước khi làm thần thánh?",
            "result": "Binh lính",
            "day": 1
        },
        {
            "title": "Trong cốt truyện của A-Ezizh, dân làng đã phá tượng của ai?",
            "result": "Tasi",
            "day": 1
        },
        {
            "title": "Avatar của nhân vật nào có mắt mở to nhất?",
            "result": "Kelthur",
            "day": 1
        },
        {
            "title": "Mối quan hệ giữa Canisa & Ruke - Ma Thú Thịnh Nộ là gì trước khi họ trở thành người địa ngục?",
            "result": "Địch thủ",
            "day": 8
        },
        {
            "title": "Kỹ năng 'Lửa Đèn Lồng' của Astar - Ngọn lửa chói lóa có thể tạo ra tối đa bao nhiêu Cầu Lửa?",
            "result": "7",
            "day": 8
        },
        {
            "title": "Thực thể nào sẽ bị mất HP trong vùng dung nham do Thú Phun lửa tạo ra bằng kỹ năng 'Dung Nham Phun Trào' (cấp cao nhất)?",
            "result": "Toàn Bộ Thực Thể",
            "day": 8
        },
        {
            "title": "Tướng nào sau đây không đeo kính?",
            "result": "Hendrik - Lính Phòng Thủ",
            "day": 8
        },
        {
            "title": "Tướng nào sau đây không dùng cung làm vũ khí?",
            "result": "Kaz - Bàn Tay Gỗ",
            "day": 8
        },
        {
            "title": "Ai là vị tướng tạo nên liên minh 'Hành Trình Đích Thực' với Pippa - Phù Thủy Ngốc?",
            "result": "Ruka - Tiểu Tinh Quái",
            "day": 7
        },
        {
            "title": "Sau khi sử dụng kỹ năng tối thượng 'Lễ Hội Linh Hồn', khi khiên vẫn tồn tại, Daimond sẽ nhận bao nhiêu sát thương cho đồng minh của mình?",
            "result": "35%",
            "day": 7
        },
        {
            "title": "Solise - Tiên Hoa có bao nhiêu đuôi?",
            "result": "9",
            "day": 7
        },
        {
            "title": "Nevanthi - Công Nương Xanh sẽ sử dụng Nguyên Tố Gỗ ban một nhánh cây cho đồng minh có HP thấp nhất bằng kỹ năng nào?",
            "result": "Quà tặng tự nhiên",
            "day": 7
        },
        {
            "title": "Bức tượng của Hộ Vệ Nguyên Tố nào ở Thành Phố Ngàn Cánh Buồm?",
            "result": "Respen - Phong Nhi",
            "day": 7
        },
        {
            "title": "Khi Oku - Nấm Đấm Sắt sử dụng kỹ năng 'Đập Bọ', bao nhiêu con bọ sẽ sử dụng để tấn công?",
            "result": "2",
            "day": 6
        },
        {
            "title": "Vật phẩm nào để nâng cấp Phù Điêu?",
            "result": "Lõi Nguyên Tố",
            "day": 6
        },
        {
            "title": "Sử Tử Bay là quái vật nào?",
            "result": "Gia Cầm",
            "day": 6
        },
        {
            "title": "Tarnos - Kẻ Chuộc Tội sẽ di chuyển bản thân tới Trụ Đại Tội nào khi sử dụng kỹ năng 'Cú Đánh Mang Thề'?",
            "result": "Trụ xa anh ấy nhất",
            "day": 6
        },
        {
            "title": "Tên của trang bị chuyên của Desira - Mỹ Nhân Ngư là gì?",
            "result": "Khiên Treason",
            "day": 6
        },
        {
            "title": "Bạn sẽ nhận bao nhiêu điểm cộng thuộc tính tấn công khi sử dụng 4  Tướng cùng hệ trong đội hình?",
            "result": "15%",
            "day": 5
        },
        {
            "title": "Rowan - Người Lữ Hành từng nhận một món quà là?",
            "result": "Aix",
            "day": 5
        },
        {
            "title": "Mắt của Theowyn - Góa Phụ U Buồn có màu gì?",
            "result": "Tím",
            "day": 5
        },
        {
            "title": "Huy Hiệu Nguyên Tố nào đã cho Solise - Người Diễn Hoạt Nước sức mạnh để bảo vệ khu rừng của cô ấy?",
            "result": "Huy Hiệu Nước",
            "day": 5
        },
        {
            "title": "Khi nào Canisa & Ruke - Ma Thú Thịnh Nộ sẽ di chuyển tới trước khi họ chủ động sử dụng kỹ năng 'Khóa Tia Chớp'?",
            "result": "Khu vực có nhiều kẻ địch tập trung nhất",
            "day": 5
        },
        {
            "title": "Mối quan hệ giữa Gorvo - Anh Hùng Bất Khuất và Serius - Vị Cứu Tinh của Biển là gì?",
            "result": "Bạn Bè",
            "day": 4
        },
        {
            "title": "Rosaline - Nữ Hầu KHÔNG sử dụng vật nào sau đây để tấn công kẻ địch?",
            "result": "Vải Lau Chùi",
            "day": 4
        },
        {
            "title": "Có thể cộng dòn bao nhiêu lớp hiệu ứng Trọng Tâm lên một tướng đồng minh khi Rồng Đất Lưng Kiếm sử dụng kỹ năng 'Chiến Xa Đột Kích' (cấp cao nhất)",
            "result": "3",
            "day": 4
        },
        {
            "title": "Mô tả nào sau đây về cơ chế kỹ năng của Baden - Hiệp Sĩ Tàn Rụi là sai khi tất cả các kỹ năng của anh ấy đều đạt cấp tối đa?",
            "result": "Nhiều bản sao gỗ có thể tồn tại cùng một lúc",
            "day": 4
        },
        {
            "title": "Nevanthi - Công Nương Xanh là Hộ Về Nguyên Tố nào?",
            "result": "Nguyên Tố Gỗ",
            "day": 4
        },
        {
            "title": "Khi sử dụng kỹ năng 'Phân Thân Trong Mây', Ngộ Không - Hầu Vương có thể tạo tối đa bao nhiêu phân thân?",
            "result": "3",
            "day": 3
        },
        {
            "title": "Nội thất độc quyền dư có thể làm gì?",
            "result": "Cường Hóa Nội Thất",
            "day": 3
        },
        {
            "title": "Tướng nào sau đây không thuộc về 'Liên Minh Phép Thuật'?",
            "result": "Mirael - Đèn Cầy",
            "day": 3
        },
        {
            "title": "Hiệu ứng Hoạt Ảnh Linh Hồn của Solise - Người Diễn Hoạt Nước áp lên mục tiêu sẽ không có tác dụng nếu mục tiêu đang nhận hiệu ứng nào sau đây?",
            "result": "Cấm Trị Liệu",
            "day": 3
        },
        {
            "title": "Thủ đô của Người Mang Ánh Sáng nằm ở thành phố nào?",
            "result": "Ranhorn",
            "day": 3
        },
        {
            "title": "Kẻ địch xung quanh phải nhận hiệu ứng nào sau đây khi Trụ Đại Tội của Tarnos - Kẻ Chuộc Tội tan vỡ?",
            "result": "Bị Choáng",
            "day": 2
        },
        {
            "title": "Ai sau đây là thợ săn tiền thưởng?",
            "result": "Fawkes - Đánh bại cái chết",
            "day": 2
        },
        {
            "title": "Estrilda - Hiệp Sĩ Dũng Cảm trở thành hiệp sĩ vào năm mấy tuổi?",
            "result": "16",
            "day": 2
        },
        {
            "title": "Kỹ năng 'Nhanh Cây Giao Hòa' của Nevanthi - Công Nương Xanh đạt cấp 2, nó sẽ áp đặt cùng lúc hiệu ứng nào lên kẻ địch không phải BOSS của hiệu ứng phòng thử cao nhất?",
            "result": "Giảm Phòng Thủ",
            "day": 2
        },
        {
            "title": "Sử Tử Bay là quái vật bay trong giống loại động vật nào?",
            "result": "Mèo con",
            "day": 2
        },
        {
            "title": "Sau khi mở khóa 9 mảnh kỹ năng nội thất độc quyền, đòn tấn công của Canisa & Ruke sẽ có hiệu ứng gì?",
            "result": "Giảm năng lượng của kẻ địch",
            "day": 1
        },
        {
            "title": "Mắt của Audrae - Ngôi sao kỳ lạ có màu gì?",
            "result": "Màu Lam",
            "day": 1
        },
        {
            "title": "Trong tình huống nào, Quái Vùng Cực sẽ nhận thêm Năng Lượng Quái Vật?",
            "result": "Khi tướng địch bị khống chế",
            "day": 1
        },
        {
            "title": "Là kẻ thì thầm với hoa, Solise - Người Diễn Hoạt Nước có sở thích gì?",
            "result": "Thực Vật Học",
            "day": 1
        },
        {
            "title": "Tướng nào sau đây không dùng kiếm làm vũ khí?",
            "result": "Eorin - Nốt Đốt Rừng Thẳm",
            "day": 1
        },
    ],
    monsters: []
}