<section class="feedback-area ptb-100">
    <div class="container">
        <div class="feedback-slides owl-carousel owl-theme">
            <div class="single-feedback-item" *ngFor="let item of feedback">
                <p>“Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.”</p>

                <div class="info">
                    <h3>Andy Nguyễn</h3>
                    <span>Switzerland</span>
                    <img src="assets/img/default/admin.jpg" class="shadow rounded-circle" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>