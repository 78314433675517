<div class="breadcrumb-area">
    <h1>Dashboard</h1>

    <ol class="breadcrumb">
        <li class="item"><a routerLink="/admin-dashboard"><i class='bx bx-home-alt'></i></a></li>

        <li class="item">Dashboard</li>

        <li class="item">My Profile</li>
    </ol>
</div>

<div class="row">
    <div class="col-lg-6 col-md-12">
        <div class="my-profile-box mb-30">
            <h3>Profile Details</h3>

            <form>
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group profile-box">
                            <div class="d-flex align-items-center">
                                <img src="assets/img/default/admin.jpg" alt="image">

                                <div class="btn-box">
                                    <div class="file-upload">
                                        <input type="file" name="file" id="file" class="inputfile" />
                                        <label for="file">Choose a file</label>
                                    </div>
                                    <a routerLink="my-profile" class="delete-btn">Delete</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>First Name</label>
                            <input type="text" class="form-control" value="Andro">
                        </div>
                    </div>
                    
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Last Name</label>
                            <input type="text" class="form-control" value="Smith">
                        </div>
                    </div>
                    
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Email Address</label>
                            <input type="email" class="form-control" value="hello@androsmith.com">
                        </div>
                    </div>
                    
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Phone</label>
                            <input type="text" class="form-control" value="+88 (123) 123456">
                        </div>
                    </div>
                    
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Address</label>
                            <input type="text" class="form-control" value="Wonder Street, USA, New York">
                        </div>
                    </div>
                    
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Website</label>
                            <input type="text" class="form-control" value="EnvyTheme.com">
                        </div>
                    </div>
                    
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <label>Bio</label>
                            <textarea cols="30" rows="6" placeholder="Short description about you..." class="form-control"></textarea>
                        </div>
                    </div>
                </div>

                <h3>Your Socials</h3>

                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Facebook URL</label>
                            <input type="text" class="form-control" placeholder="https://www.facebook.com/">
                        </div>
                    </div>
                    
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Twitter URL</label>
                            <input type="text" class="form-control" placeholder="https://twitter.com/">
                        </div>
                    </div>
                    
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Linkedin URL</label>
                            <input type="text" class="form-control" placeholder="https://www.linkedin.com/">
                        </div>
                    </div>
                    
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Instagram URL</label>
                            <input type="text" class="form-control" placeholder="https://instagram.com/">
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <button type="submit">Save Change</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="col-lg-6 col-md-12">
        <div class="my-profile-box mb-30">
            <h3>Change Password</h3>

            <form>
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Current Password</label>
                            <input type="password" class="form-control" value="123456">
                        </div>
                    </div>
                    
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>New Password</label>
                            <input type="password" class="form-control">
                        </div>
                    </div>
                    
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <label>Confirm New Password</label>
                            <input type="password" class="form-control">
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <button type="submit">Change Password</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>