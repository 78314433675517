<app-header></app-header>

<div class="page-title-area page-title-bg2">
    <div class="container">
        <div class="page-title-content">
            <h2>TEST RUNES</h2>
        </div>
    </div>
</div>

<section class="checkout-area ptb-100">
    <div class="container">
        <form>
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="billing-details">
                        <h3 class="title">Details</h3>

                        <div class="row">
                            <form>
                                <div class="cart-table table-responsive">
                                    <table class="table table-bordered">
                                        <tbody>
                                            <tr *ngFor="let r of runes">
                                                <td>Slot {{r.slot}}</td>
                                                <td class="product-thumbnail">
                                                    <a><img src="assets/img/products/img1.jpg" alt="item"></a>
                                                </td>
                                                <td class="product-name">
                                                    {{r.name}}
                                                </td>
                                                <td class="product-quantity">
                                                    <div class="input-counter">
                                                        <span class="minus-btn"><i class='bx bx-minus'></i></span>
                                                        <input type="text" min="1" max="15" [value]="r.level">
                                                        <span class="plus-btn"><i class='bx bx-plus'></i></span>
                                                    </div>
                                                </td>
                                                <td class="product-subtotal">
                                                    <div class="subtotal-amount" *ngFor="let op of r.options">
                                                        {{op.mainName}}: 
                                                        <span *ngIf="op.unit == 'percent'">
                                                            {{(op.mainValue) | percent}}
                                                        </span>
                                                        <span *ngIf="op.unit == 'number'">
                                                            {{(op.mainValue)}}
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="order-details">
                        <h3 class="title">Status</h3>

                        <div class="order-table table-responsive">
                            <table class="table table-bordered">
                                <tbody>
                                    <tr *ngFor="let h of heroes">
                                        <td class="product-name">
                                            {{h.name}}
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount" *ngIf="h.unit == 'percent'">
                                                {{(h.value) | percent}}
                                            </span>
                                            <span class="subtotal-amount subtotal-amount-addition" *ngIf="h.unit == 'percent' && h.addition > 0">
                                                +{{h.addition | percent}}
                                            </span>
                                            <span class="subtotal-amount" *ngIf="h.unit == 'number'">
                                                {{h.value}}
                                            </span>
                                            <span class="subtotal-amount subtotal-amount-addition" *ngIf="h.unit == 'number' && h.addition > 0">
                                                +{{h.addition}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>