<app-header-one></app-header-one>

<div class="page-title-area page-title-bg2">
    <div class="container">
        <div class="page-title-content">
            <h2>Blog Grid (3 in Row)</h2>
        </div>
    </div>
</div>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details-two">
                            <img src="assets/img/blog/img1.jpg" alt="image">
                        </a>
                        <div class="date">
                            <span>January 29, 2020</span>
                        </div>
                    </div>

                    <div class="post-content">
                        <span class="category">Ideas</span>
                        <h3><a routerLink="/blog-details-two">Top 50 places to eat in Milwaukee</a></h3>
                        <a routerLink="/blog-details-two" class="details-btn">Read Story</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details-two">
                            <img src="assets/img/blog/img2.jpg" alt="image">
                        </a>
                        <div class="date">
                            <span>January 29, 2020</span>
                        </div>
                    </div>

                    <div class="post-content">
                        <span class="category">Advice</span>
                        <h3><a routerLink="/blog-details-two">28 places to visit in the USA</a></h3>
                        <a routerLink="/blog-details-two" class="details-btn">Read Story</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details-two">
                            <img src="assets/img/blog/img3.jpg" alt="image">
                        </a>
                        <div class="date">
                            <span>January 29, 2020</span>
                        </div>
                    </div>

                    <div class="post-content">
                        <span class="category">Social</span>
                        <h3><a routerLink="/blog-details-two">08 things to remember in Morning</a></h3>
                        <a routerLink="/blog-details-two" class="details-btn">Read Story</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details-two">
                            <img src="assets/img/blog/img4.jpg" alt="image">
                        </a>
                        <div class="date">
                            <span>January 29, 2020</span>
                        </div>
                    </div>

                    <div class="post-content">
                        <span class="category">Boy</span>
                        <h3><a routerLink="/blog-details-two">The best coffee shops in Bangkok</a></h3>
                        <a routerLink="/blog-details-two" class="details-btn">Read Story</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details-two">
                            <img src="assets/img/blog/img5.jpg" alt="image">
                        </a>
                        <div class="date">
                            <span>January 29, 2020</span>
                        </div>
                    </div>

                    <div class="post-content">
                        <span class="category">Platform</span>
                        <h3><a routerLink="/blog-details-two">Top 10 business location in USA</a></h3>
                        <a routerLink="/blog-details-two" class="details-btn">Read Story</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details-two">
                            <img src="assets/img/blog/img6.jpg" alt="image">
                        </a>
                        <div class="date">
                            <span>January 29, 2020</span>
                        </div>
                    </div>

                    <div class="post-content">
                        <span class="category">Shipping</span>
                        <h3><a routerLink="/blog-details-two">16 sweetest summer sky in London</a></h3>
                        <a routerLink="/blog-details-two" class="details-btn">Read Story</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="pagination-area text-center">
                    <a routerLink="/blog-two" class="prev page-numbers"><i class='bx bx-chevron-left'></i></a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/blog-two" class="page-numbers">2</a>
                    <a routerLink="/blog-two" class="page-numbers">3</a>
                    <a routerLink="/blog-two" class="page-numbers">4</a>
                    <a routerLink="/blog-two" class="page-numbers">5</a>
                    <a routerLink="/blog-two" class="next page-numbers"><i class='bx bx-chevron-right'></i></a>
                </div>
            </div>
        </div>
    </div>
</section>