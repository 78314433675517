<div class="single-listing-item">
    <div class="listing-image">
        <a [routerLink]="[edit ? item._id : ('/listing/1')]" class="d-block">
            <img [src]="item.headerMedia == 'background-image' ? item.headerMediaBGImage : item.headerMediaCIImage" [alt]="item.name">
        </a>

        <div class="listing-tag">
            <a routerLink="/listing" [queryParams]="{'subcategories': item.subCategory}" class="d-block">{{item.subCategory}}</a>
        </div>

        <div class="listing-rating">
            <div class="review-stars-rated">
                <i class='bx bxs-star'></i>
                <i class='bx bxs-star'></i>
                <i class='bx bxs-star'></i>
                <i class='bx bxs-star'></i>
                <i class='bx bxs-star'></i>
            </div>

            <div class="rating-total">
                {{item.rate}} (1 reviews)
            </div>
        </div>
    </div>

    <div class="listing-content">
        <div class="listing-author d-flex align-items-center">
            <img src="assets/img/default/admin.jpg" class="rounded-circle mr-2" [alt]="item.title">
            <span>{{item.createdBy}}</span>
        </div>

        <h3><a [routerLink]="[item._id]" class="d-inline-block">{{item.title}}</a></h3>

        <span class="location"><i class="bx bx-map"></i> {{item.address ? item.address : "VN"}}</span>
    </div>

    <div class="listing-box-footer">
        <div class="d-flex align-items-center justify-content-between">
            <div class="price">
                <span data-bs-toggle="tooltip" data-placement="top" title="Ultra Hight">
                    {{item.sidebarWidgetsPriceRange == true ? item.priceRange : item.price}}
                </span>
            </div>

            <div class="listing-option-list">
                <a [href]="item.maps" data-bs-toggle="tooltip" data-placement="top" title="Find Directions"><i
                        class='bx bx-directions'></i></a>
                <a data-bs-toggle="tooltip" data-placement="top" title="Save"><i
                        class='bx bx-heart'></i></a>
                <a [href]="item.maps" data-bs-toggle="tooltip" data-placement="top" title="On the Map"><i
                        class='bx bx-map'></i></a>
            </div>
        </div>
    </div>

    <div class="listing-badge">{{item.status}}!</div>

    <div class="listing-edit-delete-option" *ngIf="edit">
        <div class="d-flex justify-content-between align-items-center">
            <a [routerLink]="[item._id]" class="btn btn-md btn-primary">Edit Listing</a>
            <a href="#" data-bs-toggle="modal" attr.data-bs-target="#delete{{item._id}}" class="btn btn-md btn-danger ml-3">Delete Listing</a>
        </div>
    </div>
</div>

<!-- Modal -->
<div #closeModal class="modal fade" id="delete{{item._id}}" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{item.title}}</h5>
                <a type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><i class='bx bx-x'></i></span>
                </a>
            </div>
            <div class="modal-body">
                Do you want to delete?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" (click)="deleteItem(item._id)">Delete</button>
            </div>
            <div class="alert alert-success" role="alert" *ngIf="messageSuccess">
                {{messageSuccess}}
            </div>
            <div class="alert alert-danger" role="alert" *ngIf="messageError">
                {{messageError}}
            </div>
        </div>
    </div>
</div>