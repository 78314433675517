import { Component, OnInit } from '@angular/core';
import { LISTING } from 'src/app/data/listing.data';
import { RealEstateService } from 'src/app/services/realestate.service';

@Component({
  selector: 'app-admin-listing',
  templateUrl: './admin-listing.component.html',
  styleUrls: ['./admin-listing.component.scss']
})
export class AdminListingComponent implements OnInit {

  currentIndex = 0
  startIndex = 0
  limit = 6
  isLoading = false;
  STATUS = LISTING.Status
  items: any = {}
  constructor(private api: RealEstateService) { }

  ngOnInit(): void {
    this.fetch();
  }

  fetch() {
    this.isLoading = true;
    this.api.GETALL().subscribe((res: any) => {
      this.items.all = res.results;
      for (var i = 0; i < this.STATUS.length; i++) {
        var statusValue = this.STATUS[i].value;
        this.items[statusValue] = this.items.all.filter(c => c.status == statusValue);
      }
      this.isLoading = false;
    }, err => {
      this.items = {};
      this.isLoading = false;
    });
  }

  getPaging(arr: any){
    var pages = [];
    var count = 1;
    for(var i = 0; i < arr.length; i+=this.limit){
      pages.push(count);
      count++;
    }
    return pages;
  }

  setPage(n: any, max = null){
    if(n < 0) return;
    if(max != null && n >= Math.ceil(max/this.limit)) return;
    this.currentIndex = n;
    this.startIndex = this.limit*n;
  }

  resetPage(){
    this.startIndex = 0;
    this.currentIndex = 0;
  }
}
