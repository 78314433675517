<app-header></app-header>

<div class="page-title-area" style="background-image: url(assets/img/tsonline/ts-online-mobile_ntnr.jpg);">
    <div class="container">
        <div class="page-title-content">
            <h2>TSOnline</h2>
        </div>
    </div>
</div>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6" *ngFor="let item of items | slice:startIndex:startIndex+MAX">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a >
                                    <img [src]="item.backgroundImages[0]" [alt]="item.name" (error)="handleMissingImage($event)">
                                </a>
                                <div class="date">
                                    <span>{{(item.created ? item.created.$date : currentDate) | date:'medium'}}</span>
                                </div>
                            </div>

                            <div class="post-content">
                                <span class="category">{{item.thanh ? item.thanh : "???"}}</span>
                                <h3><a >{{item.ten_npc ? item.ten_npc : "???"}}</a></h3>
                                <a  class="details-btn">chi tiết</a>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="pagination-area text-center">
                            <a routerLink="/blog-four" class="prev page-numbers"><i class='bx bx-chevron-left'></i></a>
                            <span class="page-numbers current" aria-current="page">1</span>
                            <a routerLink="/blog-four" class="page-numbers">2</a>
                            <a routerLink="/blog-four" class="page-numbers">3</a>
                            <a routerLink="/blog-four" class="page-numbers">4</a>
                            <a routerLink="/blog-four" class="page-numbers">5</a>
                            <a routerLink="/blog-four" class="next page-numbers"><i class='bx bx-chevron-right'></i></a>
                        </div>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <section class="widget widget_search">
                        <form class="search-form" (submit)="onSearch()">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field" placeholder="Search..." [(ngModel)]="nameSearch" [ngModelOptions]="{standalone: true}">
                            </label>
                            <button type="submit"><i class="bx bx-search-alt"></i></button>
                        </form>
                    </section>

                    <section class="widget widget_louise_posts_thumb">
                        <h3 class="widget-title">Popular Posts</h3>

                        <article class="item" *ngFor="let item of itemsPopular">
                            <a  class="thumb">
                                <span class="fullimage cover bg1" role="img"></span>
                            </a>
                            <div class="info">
                                <span>{{(item.created ? item.created.$date : currentDate) | date:'medium'}}</span>
                                <h4 class="title usmall"><a >{{item.name}}</a></h4>
                            </div>

                            <div class="clear"></div>
                        </article>
                    </section>

                    <section class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>

                        <ul>
                            <li *ngFor="let cate of categories">
                                <a (click)="nameSearch=cate.name; onSearch();">
                                    {{cate.name}} <span class="post-count">({{cate.total}})</span>
                                </a>
                            </li>
                        </ul>
                    </section>

                    <section class="widget widget_tag_cloud">
                        <h3 class="widget-title">Braike Tags</h3>

                        <div class="tagcloud" >
                            <a *ngFor="let t of tags" (click)="nameSearch=t.name; onSearch();">
                                {{t.name}} <span class="tag-link-count"> ({{t.total}})</span>
                            </a>
                        </div>
                    </section>

                    <section class="widget widget_instagram">
                        <h3 class="widget-title">Instagram</h3>

                        <ul>
                            <li>
                                <a href="#" class="d-block">
                                    <img src="assets/img/blog/img1.jpg" alt="image">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="d-block">
                                    <img src="assets/img/blog/img2.jpg" alt="image">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="d-block">
                                    <img src="assets/img/blog/img3.jpg" alt="image">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="d-block">
                                    <img src="assets/img/blog/img4.jpg" alt="image">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="d-block">
                                    <img src="assets/img/blog/img5.jpg" alt="image">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="d-block">
                                    <img src="assets/img/blog/img6.jpg" alt="image">
                                </a>
                            </li>
                        </ul>
                    </section>

                    <section class="widget widget_contact">
                        <div class="text">
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <span>Emergency</span>
                            <a href="tel:+0839390597">0839390597</a>
                        </div>
                    </section>
                </aside>
            </div>
        </div>
    </div>
</section>