import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LISTING } from 'src/app/data/listing.data';
import { ImageService } from 'src/app/services/image.service';

@Component({
  selector: 'app-admin-games-detail',
  templateUrl: './admin-games-detail.component.html',
  styleUrls: ['./admin-games-detail.component.scss']
})
export class AdminGamesDetailComponent implements OnInit {

  isNewRecord = false
  isLoading = false
  isSubmit = false
  messageSuccess: any
  messageError: any
  data = LISTING
  SUBCATEGORY: any
  CATEGORY: any
  SPECIES: any
  TYPE: any
  ELEMENT: any
  STATUS = LISTING.Status
  relatedList: any
  item: any
  id: string;
  detailForm: any

  constructor(private route: ActivatedRoute, private titleService: Title, private api: ImageService) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.SUBCATEGORY = this.data.GameSubCategories;
    this.CATEGORY = this.data.GameCategories;
    this.SPECIES = this.data.GameSpecies;
    this.TYPE = this.data.GameType;
    this.ELEMENT = this.data.GameElement;
    this.STATUS = this.data.GameStatus;
    if (this.id == "-1") {
      this.isNewRecord = true;
      this.item = {};
      this.titleService.setTitle("MYLIFE | ADMIN | NEW");
      this.initForm();
    }
    else {
      this.fetch();
    }
  }

  fetch() {
    this.isLoading = true;
    this.api.SEARCH({ _id: this.id }, 1).subscribe((res: any) => {
      if (res.success && res.results && res.results.length > 0) {
        this.item = res.results[0];
        this.titleService.setTitle("MYLIFE | ADMIN | " + this.item.name);
      }
      else {
        this.isNewRecord = true;
        this.item = {};
        this.titleService.setTitle("MYLIFE | ADMIN | NEW");
      }
      this.initForm();
      this.isLoading = false;
    }, err => {
      this.isNewRecord = true;
      this.item = {};
      this.titleService.setTitle("MYLIFE | ADMIN | NEW");
      this.initForm();
      this.isLoading = false;
    })

  }

  initForm() {
    this.detailForm = new FormGroup(
      {
        name: new FormControl(this.item.name, Validators.required),
        category: new FormControl(this.item.category ? this.item.category.toLowerCase() : ''),
        subCategory: new FormControl(this.item.subCategory ? this.item.subCategory.toLowerCase() : ''),
        keywords: new FormControl(this.item.keywords),
        latitude: new FormControl(this.item.latitude),
        longitude: new FormControl(this.item.longitude),
        city: new FormControl(this.item.city),
        address: new FormControl(this.item.address),
        email: new FormControl(this.item.email),
        phone: new FormControl(this.item.phone),
        website: new FormControl(this.item.website),
        status: new FormControl(this.item.status),

        species: new FormControl(this.item.species),
        type: new FormControl(this.item.type),
        element: new FormControl(this.item.element),

        detail: new FormControl(this.item.detail),
        grade: new FormControl(this.item.grade),
        rate: new FormControl(this.item.rate),

        skill1: new FormControl(this.item.skill1),
        skill2: new FormControl(this.item.skill2),
        skill3: new FormControl(this.item.skill3),
        skill4: new FormControl(this.item.skill4),

        slug: new FormControl(this.item.slug),
        created: new FormControl(this.item.created),
        createdBy: new FormControl(this.item.createdBy),
        modified: new FormControl(this.item.modified),
        modifiedBy: new FormControl(this.item.modifiedBy),

        leaderSkill: new FormControl(this.item.leaderSkill),

        maps: new FormControl(this.item.maps)
      }
    );
  }

  onSubmit() {
    if (!this.detailForm.valid) {
      this.messageError = "Name is required";
      return;
    }
    var val = this.detailForm.value;
    this.isSubmit = true;
    this.messageError = "";
    this.messageSuccess = "";
    var username = localStorage.getItem("username")
    if (this.isNewRecord) {
      if (username)
        val.createdBy = username;
      this.api.CREATE(val).subscribe((res: any) => {
        this.isSubmit = false;
        this.messageSuccess = res.message;
      }, err => {
        this.isSubmit = false;
        this.messageError = err.error.error;
      })
    }
    else {
      if (username)
        val.modifiedBy = username;
      this.api.UPDATE(val, this.id).subscribe((res: any) => {
        this.isSubmit = false;
        this.messageSuccess = res.message;
      }, err => {
        this.isSubmit = false;
        this.messageError = err.error.error;
      })
    }
  }

  setMaps() {
    this.item.maps = this.detailForm.value.maps;
  }

}
