<header class="header-area">
    <div class="top-header">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-12">
                    <ul class="header-contact-info">
                        <li>Welcome to {{settings.name}}</li>
                        <li>Call: <a href="tel:{{settings.phone}}">{{settings.phone}}</a></li>
                        <li>
                            <div class="dropdown language-switcher d-inline-block">
                                <button class="dropdown-toggle" type="button" (click)="toggleClass()">
                                    <img src="assets/img/us-flag.jpg" alt="image">
                                    <span>Eng <i class='bx bx-chevron-down'></i></span>
                                </button>
                                <div class="dropdown-menu" [class.active]="classApplied">
                                    <a href="#" class="dropdown-item d-flex align-items-center"
                                        *ngFor="let item of settings.language">
                                        <img [src]="item.flag" class="shadow-sm" alt="flag">
                                        <span>{{item.name}}</span>
                                    </a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="top-header-discount-info">
                        <p><strong>50% OFF</strong> all new directory! <a href="listing-1.html">Discover Now!</a></p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <ul class="header-top-menu">
                        <li><a routerLink="/admin-dashboard/my-profile"><i class='bx bxs-user'></i> My Account</a></li>
                        <li><a routerLink="/admin-dashboard/listing/-1"><i class='bx bx-plus-circle'></i> Add Listing</a>
                        </li>
                        <li *ngIf="!isLogin"><a routerLink="/register"><i class='bx bx-log-in-circle'></i> Register</a></li>
                        <li *ngIf="username">
                            <span class="name">Hi! {{username}}</span>
                            <img src="assets/img/default/admin.jpg" width="35" class="rounded-circle" alt="image">
                        </li>
                        <li>
                            <a routerLink="/login"  *ngIf="!isLogin"><i class='bx bx-log-in'></i> Login</a>
                            <a routerLink="/login" *ngIf="isLogin"><i class='bx bx-log-out'></i> Logout</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="navbar-area">
        <div class="louise-responsive-nav">
            <div class="container">
                <div class="louise-responsive-menu">
                    <div class="logo">
                        <a routerLink="/">
                            <img [src]="settings.logo" alt="logo">
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="louise-nav">
            <div class="container-fluid">
                <nav class="navbar navbar-expand-md navbar-light">
                    <a class="navbar-brand" routerLink="/">
                        <img [src]="settings.logo" alt="logo">
                    </a>

                    <div class="collapse navbar-collapse mean-menu">
                        <ul class="navbar-nav">
                            <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Home</a></li>

                            <li class="nav-item"><a routerLink="/listing" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Listing</a></li>

                            <li class="nav-item megamenu"><a href="javascript:void(0)" class="nav-link">Pages <i
                                        class='bx bx-chevron-down'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col">
                                                    <h6 class="submenu-title">Pages</h6>

                                                    <ul class="megamenu-submenu">
                                                        <li><a routerLink="/about" routerLinkActive="active"
                                                                [routerLinkActiveOptions]="{exact: true}">About Us</a>
                                                        </li>

                                                        <li><a routerLink="/team" routerLinkActive="active"
                                                                [routerLinkActiveOptions]="{exact: true}">Team</a></li>

                                                        <li><a routerLink="/how-it-works" routerLinkActive="active"
                                                                [routerLinkActiveOptions]="{exact: true}">How It
                                                                Works</a></li>

                                                        <li><a routerLink="/faqs" routerLinkActive="active"
                                                                [routerLinkActiveOptions]="{exact: true}">FAQ's</a></li>
                                                    </ul> 
                                                </div>

                                                <div class="col">
                                                    <h6 class="submenu-title">Categories</h6>

                                                    <ul class="megamenu-submenu">
                                                        <li><a routerLink="/categories" routerLinkActive="active"
                                                                [routerLinkActiveOptions]="{exact: true}">Categories</a></li>
                                                    </ul>

                                                    <h6 class="submenu-title">My Account</h6>
    
                                                    <ul class="megamenu-submenu">
                                                        <li><a routerLink="/cart">Cart</a></li>
                                                        <li><a routerLink="/checkout">Checkout</a></li>
                                                    </ul>

                                                    <h6 class="submenu-title">Blog</h6>
    
                                                    <ul class="megamenu-submenu">
                                                        <li><a routerLink="/blog">Blogs</a></li>
                                                    </ul>
                                                </div>

                                                <div class="col">
                                                    <h6 class="submenu-title">Shop</h6>
    
                                                    <ul class="megamenu-submenu">
                                                        <li><a routerLink="/product-list">Product List</a></li>
    
                                                        <li><a routerLink="/cart">Cart</a></li>
    
                                                        <li><a routerLink="/checkout">Checkout</a></li>
    
                                                        <li><a routerLink="/products-details">Product Details</a></li>
                                                    </ul>
                                                </div>

                                                <div class="col">
                                                    <h6 class="submenu-title">Games</h6>
    
                                                    <ul class="megamenu-submenu">
                                                        <li><a routerLink="/afkarena">AFKARENA</a></li>
                                                        <li><a routerLink="/summonerswar">SummonersWar</a></li>
                                                        <li><a routerLink="/onepiece">OnePiece</a></li>
                                                        <li><a routerLink="/warcraft">WarCraft</a></li>
                                                        <li><a routerLink="/ts">TSOnline</a></li>
                                                        <li><a routerLink="/vuaphapthuat">Vua Pháp Thuật</a></li>
                                                        <li><a routerLink="/cotuong">Cờ Tướng</a></li>
                                                        <li><a routerLink="/coup">Cờ Úp</a></li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div class="partner-slides owl-carousel owl-theme">
                                                <div class="partner-item">
                                                    <a href="#"><img src="assets/img/partner/img1.png" alt="image"></a>
                                                </div>

                                                <div class="partner-item">
                                                    <a href="#"><img src="assets/img/partner/img2.png" alt="image"></a>
                                                </div>

                                                <div class="partner-item">
                                                    <a href="#"><img src="assets/img/partner/img3.png" alt="image"></a>
                                                </div>

                                                <div class="partner-item">
                                                    <a href="#"><img src="assets/img/partner/img4.png" alt="image"></a>
                                                </div>

                                                <div class="partner-item">
                                                    <a href="#"><img src="assets/img/partner/img5.png" alt="image"></a>
                                                </div>

                                                <div class="partner-item">
                                                    <a href="#"><img src="assets/img/partner/img6.png" alt="image"></a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>

                            <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                            <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                        </ul>

                        <div class="others-option">
                            <div class="d-flex align-items-center">
                                <div class="option-item">
                                    <div class="search-btn-box">
                                        <i class="search-btn bx bx-search-alt"></i>
                                    </div>
                                </div>

                                <div class="option-item">
                                    <div class="cart-btn">
                                        <a href="#" data-bs-toggle="modal" data-bs-target="#shoppingCartModal"><i
                                                class='bx bx-shopping-bag'></i><span>0</span></a>
                                    </div>
                                </div>

                                <div class="option-item">
                                    <a href="/admin-dashboard/listing/-1" class="default-btn"><i
                                            class="bx bx-plus"></i> Add Listing</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</header>